<div class="card">
  <div class="card-tabs">
    <ul id="tabs-swipe-demo" class="tabs" materialize="tabs">
      <li class="tab col s6">
        <a class="active white-text" href="#twitter">
          {{ 'SocialNetworkComponent-TITLE_TWITTER' | translate }}
        </a>
      </li>
      <li class="tab col s6">
        <a href="#google">
          {{ 'SocialNetworkComponent-TITLE_NEWS_FEED' | translate }}
        </a>
      </li>
    </ul>
  </div>

  <div id="twitter" class="card-content">
    <ul>
      <li *ngFor="let tweet of tweets$ | async">
        <div class="tweetAuthor">
          <a href="https://twitter.com/{{ tweet.user.screenName }}" target="_blank">
            <span class="twitterName">{{ tweet.user.name }}</span>
            <span class="twitterHandle">@{{ tweet.user.screenName }}</span>
          </a>
        </div>
        <div class="tweetContent">
          {{ tweet.text }}
        </div>
        <div class="tweetTime">
          <a href="https://twitter.com/{{ tweet.user.screenName }}/status/{{ tweet.idStr }}" target="_blank">
            {{ tweet.formattedTime }}
          </a>
        </div>
      </li>
      <li *ngIf="(tweets$ | async)?.length == 0">
        <div class="tweetContent">
          {{ 'SocialNetworkComponent-NO_TWITTER_FEED' | translate }}
        </div>
      </li>
    </ul>
  </div>
  <div id="google" class="card-content">
    <ul>
      <li *ngFor="let article of news$ | async">
        <a href="{{ article.newsSourceUrl }}" target="_blank" class="articleHeadline">
          {{ article.headLineTitle }}
        </a>
        <div class="articleSubheader">
          <span class="articleSource">{{ article.headLineSource }}</span>
          <span class="articleTime"> - {{ article.formattedTime }}</span>
        </div>
        <div class="articleContent">
          {{ article.summary }}
        </div>
      </li>
      <li *ngIf="(news$ | async)?.length == 0">
        <div class="tweetContent">
          {{ 'SocialNetworkComponent-NO_NEWS_FEED' | translate }}
        </div>
      </li>
    </ul>
  </div>
</div>
