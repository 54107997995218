import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../../common/config';
import { AuthenticationService } from '../../../../login/login.service';
import { contentHeaders } from '../../../../common/headers';
import { SurveyUpdateRequest } from 'app/models/SurveyUpdateRequest';
import {
  Country,
  Disaster,
  DisasterSRResponse,
  DisasterSpecificSurveyInput,
  SRResponse,
  SurveyErrorCode,
  User,
} from '../../../../models';
import { CurrencyCodes } from 'app/models/CurrencyCodes';

const DSS_URL = AppSettings.API_SERVER + '/smart-response/survey/dss';
const REQUEST_DSS_SURVEY_UPDATE_URL = AppSettings.API_SERVER + '/smart-response/survey/dss/request_info';
const RETRIEVAL_ENDPOINT_CURRENCY_CODE_URL = AppSettings.API_SERVER + '/smart-response/admin/currency';

const EXAMPLE_USER: User = {
  email: 'sr_admin@smartresponse.org',
  firstName: 'SmartResponse',
  lastName: 'Admin',
  organizationId: null,
  organizationAdmin: false,
  userType: 1,
  lastLoginTime: new Date(1485277006000),
};

const EXAMPLE_COUNTRY: Country = {
  countryId: 1,
  name: 'United States',
  countryCode: '',
  abbreviation: 'USA',
  background: '',
  longAbbreviation: 'USA',
};

const EXAMPLE_DISASTER: Disaster = {
  disasterId: 1,
  name: 'Test one two three four five six seven ',
  summary:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ' +
    'Suspendisse neque purus, posuere id lobortis ac, suscipit vel tellus. Ut at urna quis arcu faucibus bibendum.',
  dateOfDisaster: '2016-10-06',
  disasterType: 'Earthquake',
  addedDate: '2016-01-01',
  priority: 100,
  notificationTrack: 'VENTI',
  logoURI: '/smart-response/404.png',
  services: [
    {
      name: 'Blood Donation',
      summary: 'Blood will be donated',
      serviceId: 1,
    },
    {
      name: 'Carpentry',
      summary: 'Volunteers will be assisting with building',
      serviceId: 2,
    },
    {
      name: 'Clean-Up',
      summary: 'Volunteers will be assisting with Clean-Up',
      serviceId: 3,
    },
    {
      name: 'Food',
      summary: 'Volunteers will be assisting with Food',
      serviceId: 4,
    },
  ],
  searchTerms: ['item1', 'item2', 'item3'],
  locations: [
    {
      country: {
        name: 'United States',
        countryId: 1,
        countryCode: '42',
        abbreviation: '422',
        longAbbreviation: '1234',
        background: '124',
      },
      geoLat: '34',
      geoLon: '68',
      radius: 50,
      radiusUnits: 'km',
    },
    {
      country: {
        name: 'Albania',
        countryId: 3,
        countryCode: '42',
        abbreviation: '422',
        longAbbreviation: '1234',
        background: '124',
      },
      geoLat: '-14',
      geoLon: '-170',
      radius: 50,
      radiusUnits: 'km',
    },
  ],
};

// This is 'exported' to be used with unit tests ONLY
export const EXAMPLE_SURVEY: DisasterSpecificSurveyInput = {
  /* meta data */

  lastUpdatedDate: 1485277006000,
  lastUpdatedUser: EXAMPLE_USER,

  organizationId: 1,
  organizationName: 'Organization 1',

  disaster: EXAMPLE_DISASTER,

  surveySubmissionId: 1,

  outdated: false,

  organizationRespondingToDisaster: false,

  /* responses */

  /* step 1 */

  leadContact: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      email: 'email@email.com',
      firstName: 'firstName',
      lastName: 'lastName',
      phone1: 'phone1',
      title: 'title',
    },
  },

  /* step 2 */

  hqAddress: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      address1: 'address1',
      address2: 'address2',
      city: 'city',
      country: EXAMPLE_COUNTRY,
      state: 'state',
      zip: 'zip',
    },
  },

  /* step 3 */
  reliefRecoveryService: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      isCurrentlyProvidingService: true,
      isNotProvidingService: true,
      startServiceDate: '2016-01-01',
      stopServiceDate: '2016-12-01',
    },
  },

  activityHistory: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: 'history of activity',
  },

  disasterStageOfWork: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      mitigationStage: true,
      preparedStage: false,
      recoveryStage: null,
      reliefStage: true,
      responseStage: true,
    },
  },

  stakeholder: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: 'my stakeholders are',
  },

  orgResponseVideoUrl: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: 'Video url of org response',
  },

  /* step 4 */

  solicitDonation: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      isCurrentlySolicitingDonation: true,
      isNotSolicitingDonation: false,
      startSolicitDonationDate: '2015-01-02',
      stopSolicitDonationDate: '2015-01-03',
    },
  },

  raisedFund: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasValue: true,
      fund: 234,
      currency: 'USD',
    },
  },

  spentFund: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasValue: true,
      fund: 24,
      currency: 'USD',
    },
  },

  earnedInterest: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasValue: true,
      fund: 2,
      currency: 'USD',
    },
  },

  earnedInterestSpent: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: 'howInterestSpent - this is how',
  },

  transferredFund: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasValue: true,
      fileId: null,
      fundTransferList: [
        {
          fund: 100,
          receivingOrganization: 'Receiving Org one',
          currency: 'USD',
        },
        {
          fund: 100,
          receivingOrganization: 'Receiving Org two',
          currency: 'USD',
        },
      ],
    },
  },

  /* step 5 */
  serviceList: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: [
      {
        directProportion: 34,
        indirectProportion: 0,
        serviceProvided: {
          serviceId: 1,
          name: 'some service 1',
        },
      },
      {
        directProportion: 33,
        indirectProportion: 0,
        serviceProvided: {
          serviceId: 2,
          name: 'some service 2',
        },
      },
      {
        directProportion: 33,
        indirectProportion: 0,
        serviceProvided: {
          serviceId: 3,
          name: 'some service 3',
        },
      },
    ],
  },

  /* step 6 */

  localStaff: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      mgmtStaffCount: 4,
      professionalStaffCount: 5,
      supportStaffCount: 6,
    },
  },

  nonLocalStaff: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      mgmtStaffCount: 7,
      professionalStaffCount: 8,
      supportStaffCount: 9,
    },
  },

  volunteerStaff: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      mgmtStaffCount: 10,
      professionalStaffCount: 11,
      supportStaffCount: 12,
    },
  },

  activityReport: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasValue: true,
      reportFrequencyId: 1,
      reportFrequencyOther: '',
      webLink: 'http://www.somelink.com',
      fileId: 23,
    },
  },

  engagementList: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: ['partner 1', 'partner 2', 'partner 3'],
  },

  additionalInfo: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: 'additionalComments',
  },

  organizationDto: {
    country: 'UNITED STATES: Virgin Islands',
  },

  locationList: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: [
      {
        geoLatitude: 2.344334,
        geoLongitude: 3.34224,
        radius: 50,
      },
    ],
  },

  donateButtonDisabled: true,
};

const dssErrorCodeList: SurveyErrorCode[] = [
  { errCode: 'organizationRespondingToDisaster', stepNum: 0, questionNum: 0, questionCount: 0 },
  { errCode: 'reliefRecoveryService', stepNum: 1, questionNum: 1, questionCount: 5 },
  { errCode: 'activityHistory', stepNum: 1, questionNum: 2, questionCount: 5 },
  { errCode: 'disasterStageOfWork', stepNum: 1, questionNum: 3, questionCount: 5 },
  { errCode: 'stakeholder', stepNum: 1, questionNum: 4, questionCount: 5 },
  { errCode: 'orgResponseVideoUrl', stepNum: 1, questionNum: 5, questionCount: 5 },
  { errCode: 'hqAddress', stepNum: 2, questionNum: 1, questionCount: 1 },
  { errCode: 'leadContact', stepNum: 3, questionNum: 1, questionCount: 1 },
  { errCode: 'solicitDonation', stepNum: 4, questionNum: 1, questionCount: 6 },
  { errCode: 'raisedFund', stepNum: 4, questionNum: 2, questionCount: 6 },
  { errCode: 'spentFund', stepNum: 4, questionNum: 3, questionCount: 6 },
  { errCode: 'earnedInterest', stepNum: 4, questionNum: 4, questionCount: 6 },
  { errCode: 'earnedInterestSpent', stepNum: 4, questionNum: 5, questionCount: 6 },
  { errCode: 'transferredFund', stepNum: 4, questionNum: 6, questionCount: 6 },
  { errCode: 'serviceList', stepNum: 5, questionNum: 1, questionCount: 1 },
  { errCode: 'localStaff', stepNum: 6, questionNum: 1, questionCount: 5 },
  { errCode: 'nonLocalStaff', stepNum: 6, questionNum: 2, questionCount: 5 },
  { errCode: 'volunteerStaff', stepNum: 6, questionNum: 3, questionCount: 5 },
  { errCode: 'activityReport', stepNum: 6, questionNum: 4, questionCount: 5 },
  { errCode: 'engagementList', stepNum: 6, questionNum: 5, questionCount: 5 },
  { errCode: 'additionalInfo', stepNum: 6, questionNum: 5, questionCount: 5 },
  { errCode: 'locationList', stepNum: 7, questionNum: 1, questionCount: 1 },
];

@Injectable()
export class DisasterSpecificSurveyService {
  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getMostRecentSurvey(organizationId: number, disasterId: number): Observable<DisasterSpecificSurveyInput> {
    return this.http
      .get<DisasterSRResponse>(`${DSS_URL}/${disasterId}/${organizationId}`)
      .pipe(map((res) => res.responseData.survey));
  }

  getMostRecentSurveyForEdit(organizationId: number, disasterId: number): Observable<DisasterSpecificSurveyInput> {
    return this.http
      .get<DisasterSRResponse>(`${DSS_URL}/edit/${disasterId}/${organizationId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.survey));
  }

  getSurveyById(surveyId: number): Observable<DisasterSpecificSurveyInput> {
    return this.http.get<DisasterSRResponse>(`${DSS_URL}/${surveyId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      }).pipe(map((res) => res.responseData.survey));
  }

  getSurveysByDisaster(disasterId: number): Observable<DisasterSpecificSurveyInput[]> {
    return this.http
      .get<SRResponse>(`${DSS_URL}/organizations/${disasterId}`)
      .pipe(map((res) => res.responseData.dss_table_list));
  }

  getCurrencyCodes(): Observable<CurrencyCodes> {
    return this.http
      .get<CurrencyCodes>(`${RETRIEVAL_ENDPOINT_CURRENCY_CODE_URL}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp as CurrencyCodes));
  }

  submitSurvey(organizationId: number, disasterId: number, survey: any): Observable<string> {
    return this.http.post<string>(`${DSS_URL}/submit/${disasterId}/${organizationId}`, JSON.stringify(survey), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  requestDSSSurveyUpdate(disasterId: number, organizationId: number): Observable<SurveyUpdateRequest> {
    return this.http
      .post<SRResponse>(`${REQUEST_DSS_SURVEY_UPDATE_URL}/${disasterId}/${organizationId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res));
  }

  getErrorCodeList(): SurveyErrorCode[] {
    return dssErrorCodeList;
  }
}
