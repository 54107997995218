import { Component } from '@angular/core';

@Component({
  selector: 'dap-donation-thanks',
  templateUrl: './dap-donation-thanks.component.html',
  styleUrls: ['./dap-donation-thanks.component.css'],
})
export class DapDonationThanksComponent {
  constructor() {}
}
