import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { contentHeaders } from '../common/headers';

import { AppSettings } from '../common/config';
import { AuthenticationService } from '../login/login.service';

const SUBMIT_MESSAGE_URL = AppSettings.API_SERVER + '/smart-response/admin/message/create';

@Injectable()
export class SendMessageService {
  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  submitMessage(subject: string, message: string): Observable<object> {
    const body = JSON.stringify({
      subject,
      message,
    });

    return this.http.post(SUBMIT_MESSAGE_URL, body, { headers: this.authService.getAuthHeader(contentHeaders) });
  }
}
