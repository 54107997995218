import { Component, OnInit, EventEmitter } from '@angular/core';
import { AppSettings } from 'app/common/config';
import { GlobalLoaderFacade as SlimLoadingBarService } from 'app/sharedServices/globalLoaderFacade/global-loader-facade.service';
import { MaterializeAction } from 'angular2-materialize';
import { SurveyNotificationsService } from './surveyNotifications.service';
import { handleErrors } from 'app/common/error';
import { AuthenticationService } from 'app/login/login.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { SurveyNotification } from 'app/models/SurveyNotification';
import { forkJoin } from 'rxjs';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'surveyNotifications',
  templateUrl: './surveyNotifications.component.html',
  styleUrls: ['./surveyNotifications.component.css'],
  providers: [SurveyNotificationsService],
})
export class SurveyNotificationsComponent implements OnInit {
  modalActions = new EventEmitter<string | MaterializeAction>();
  newSurveys: SurveyNotification[];
  outdatedSurveys: SurveyNotification[];
  allServices: SurveyNotification[];
  outdatedList: string[];

  headElements = [
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_SURVEY_NAME', sortName: 'surveyName' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_SURVEY_TYPE', sortName: 'surveyType' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_STEP', sortName: 'stepNumber' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_QUESTION', sortName: 'questionNumber' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_STATUS', sortName: 'status' },
  ];

  filterArg: string;
  itemsPerPageArg: number = 10;
  selectedPage: number = 0;
  sortField: string = 'name';
  sortDescending: boolean = true;
  isRetrieving: boolean = false;
  AppSettings = AppSettings;
  loading: boolean;

  constructor(
    private surveyNotificationsService: SurveyNotificationsService,
    public authService: AuthenticationService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.updateMessages();
  }

  updateMessages(): void {
    if (this.isRetrieving) {
      return;
    }

    this.isRetrieving = true;

    this.loaderService.start();
    this.loading = true;

    this.newSurveys = null;
    this.outdatedSurveys = null;

    const messageNewSurveys = this.surveyNotificationsService.getNewSurveys();
    const messageOutdatedSurveys = this.surveyNotificationsService.getOutdatedSurveys();

    this.surveyNotificationsService;
    forkJoin([messageNewSurveys, messageOutdatedSurveys]).subscribe(
      (success) => {
        this.newSurveys = success[0];
        this.outdatedSurveys = success[1];
        this.allServices = success[0].concat(success[1]);

        this.isRetrieving = false;
        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'DashboardComponent-SURVEY-NOTIFICATIONS'
        );
        this.isRetrieving = false;
        this.loaderService.complete();
        this.loading = false;
      }
    );
  }

  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }
}
