<div class="modal-header">
  <survey-step-progress-bar [totalSteps]="7" [currentStepIndex]="5" (stepTabClicked)="onSelectStep($event)">
  </survey-step-progress-bar>
  <h4 class="modal-step-title">
    {{
      'DisasterSpecificSurveyComponent-STEP_5_TITLE'
        | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
    }}
  </h4>
</div>
<div class="modal-content">
  <div class="divider title-divider"></div>

  <div id="ques51" class="red-text question-count"></div>
  <div class="row margin">
    <div class="input-field col s12" id="disaster_table_div">
      <table class="grey lighten-3 table-wrapper centered striped">
        <thead>
          <tr>
            <th class="hide-on-small-only">&nbsp;</th>
            <th>
              {{ 'SurveyCommon-Q_SP_SERVICES' | translate }}
            </th>
            <th>
              {{ 'SurveyCommon-Q_SP_DIRECT' | translate }}
            </th>
            <th>
              {{ 'SurveyCommon-Q_SP_INDIRECT' | translate }}
              <br />
              <span class="hide-on-med-and-down">{{ 'SurveyCommon-Q_SP_FUND_GRANT' | translate }}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="4">
              <div class="red-text">
                {{ 'SurveyCommon-Q_SP_Total' | translate: { percent: calculateTotalPercentServicesProvided() } }}
              </div>
              <div class="red-text" *ngIf="calculateTotalPercentServicesProvided() > 100">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PERCENTAGE_OVER_100' | translate }}
              </div>
            </td>
          </tr>

          <tr *ngFor="let formGroup of serviceListArray; index as i">
            <td class="hide-on-small-only">
              <img width="64" height="64" src="{{ formGroup.get('serviceProvided').get('fileURI').value }}" />
            </td>
            <td>
              <p class="column1">
                {{ formGroup.get('serviceProvided').get('name').value }}
              </p>
            </td>
            <td>
              <div
                class="input-field col s12"
                [ngClass]="{
                  'sr-has-error':
                    !formGroup.get('directProportion').valid &&
                    (formGroup.get('directProportion').touched || invalidOnInit?.arrays?.serviceListDirect[i]),
                  'hide-input': formGroup.get('serviceProvided').get('name').value == 'Grant-making / Re-granting'
                }"
              >
                <input
                  class="column2"
                  type="text"
                  placeholder="%"
                  [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                  [formControl]="formGroup.get('directProportion')"
                />

                <div
                  class="sr-error-area"
                  *ngIf="
                    !formGroup.get('directProportion').valid &&
                    (formGroup.get('directProportion').touched || invalidOnInit?.arrays?.serviceListDirect[i])
                  "
                >
                  <div class="percentage_error" *ngIf="formGroup.get('directProportion').hasError('pattern')">
                    {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PERCENTAGE' | translate }}
                  </div>
                </div>
              </div>
            </td>

            <td>
              <div
                class="input-field col s12"
                [ngClass]="{
                  'sr-has-error':
                    !formGroup.get('indirectProportion').valid &&
                    (formGroup.get('indirectProportion').touched || invalidOnInit?.arrays?.serviceListIndirect[i])
                }"
              >
                <input
                  class="column3"
                  type="text"
                  placeholder="%"
                  [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                  [formControl]="formGroup.get('indirectProportion')"
                />
                <div
                  class="sr-error-area"
                  *ngIf="
                    !formGroup.get('indirectProportion').valid &&
                    (formGroup.get('indirectProportion').touched || invalidOnInit?.arrays?.serviceListIndirect[i])
                  "
                >
                  <div class="percentage_error" *ngIf="formGroup.get('indirectProportion').hasError('pattern')">
                    {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PERCENTAGE' | translate }}
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="4">
              <div class="red-text">
                {{ 'SurveyCommon-Q_SP_Total' | translate: { percent: calculateTotalPercentServicesProvided() } }}
              </div>
              <div class="red-text" *ngIf="calculateTotalPercentServicesProvided() > 100">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PERCENTAGE_OVER_100' | translate }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <last-edited-metadata
        [formElement]="serviceListForm"
        [isDisabled]="organizationRespondingToDisaster ? '' : null"
      ></last-edited-metadata>
    </div>
  </div>
</div>

<div class="modal-footer">
  <survey-footer
    [currentStepIndex]="4"
    [totalSteps]="totalSteps"
    [isValid]="isValid"
    (next)="onNext()"
    (prev)="onPrev()"
    (submit)="onSubmit()"
    (cancel)="onCancel()"
    (error)="onError()"
  >
  </survey-footer>
</div>
