import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { MaterializeAction } from 'angular2-materialize';
import { ReportModalService } from './report-modal.service';
import { ReportInputModel, ReportSurveyTypes } from './report.model';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { handleErrors } from '../../common/error';
import { RecaptchaComponent } from 'ng-recaptcha';
import { AppSettings } from '../../common/config';
import { EmailValidator } from '../../validators/emailValidator';
import { LeadingTrailingWhiteSpaceValidator } from '../../validators/leadingTrailingWhiteSpaceValidator';

/** Example Reports **/
const exampleReportWithQuestionKey: ReportInputModel = {
  orgName: 'Smart Response',

  surveyId: 1,

  surveyType: ReportSurveyTypes.GOB,

  surveyQuestionKey: 'govIdOrEin',
  surveyQuestionTranslationKey: 'CommonGOBSurvey-Q_ORG_ID',
};

const exampleReportEntireSurvey: ReportInputModel = {
  orgName: 'Smart Response',

  surveyId: 1,

  surveyType: ReportSurveyTypes.GOB,
};

@Component({
  selector: 'report-modal',
  providers: [ReportModalService],
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.css', '../common-search.css'],
})
export class ReportModalComponent {
  @ViewChild(RecaptchaComponent, { static: false }) captcha: RecaptchaComponent;

  @Input()
  public set reportActions(eventEmitter: EventEmitter<ReportInputModel>) {
    if (isNullOrUndefined(eventEmitter)) {
      return;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = eventEmitter.subscribe((next) => {
      if (isNullOrUndefined(next)) {
        return;
      }

      this.reportInputModel = next;

      this.resetForm();

      this.modalActions.emit({ action: 'modal', params: ['open'] });
    });
  }

  public modalActions = new EventEmitter<string | MaterializeAction>();
  public reportModalForm: FormGroup;
  public isSubmitting: boolean = false;

  private subscription: Subscription;
  public reportInputModel: ReportInputModel;

  public AppSettings = AppSettings;

  constructor(
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private reportModalService: ReportModalService
  ) {
    this.resetForm();
  }

  resetForm() {
    if (this.captcha) {
      this.captcha.reset();
    }
    if (this.reportInputModel) {
      const formGroup = {
        captcha: new FormControl('', Validators.required),
        reason: new FormControl('', [Validators.required, Validators.maxLength(256)]),
        surveyId: new FormControl(this.reportInputModel.surveyId, Validators.required),
        surveyType: new FormControl(this.reportInputModel.surveyType, Validators.required),
        redFlagReportRespondentEmail: new FormControl('', [
          Validators.maxLength(64),
          EmailValidator.mailFormat,
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ]),
        redFlagReportRespondentName: new FormControl('', [
          Validators.maxLength(32),
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ]),
        redFlagReportRespondentPhone: new FormControl('', [
          Validators.maxLength(32),
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ]),
        redFlagReportRespondentRole: new FormControl(),
        redFlagReportRespondentComment: new FormControl('', [
          Validators.maxLength(256),
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ]),
        reportDate: new FormControl(),
      };

      if (this.reportInputModel.surveyQuestionKey) {
        formGroup['surveyQuestionKey'] = new FormControl(this.reportInputModel.surveyQuestionKey);
      }

      this.reportModalForm = new FormGroup(formGroup);

      this.reportInputModel.surveyTypeTranslationKey = ReportSurveyTypes.toTranslationKey(
        this.reportInputModel.surveyType
      );
    } else {
      this.reportModalForm = new FormGroup({
        captcha: new FormControl('', Validators.required),
        reason: new FormControl('', Validators.required),
        surveyId: new FormControl('', Validators.required),
        redFlagReportRespondentEmail: new FormControl(''),
        redFlagReportRespondentName: new FormControl(''),
        redFlagReportRespondentPhone: new FormControl(''),
        redFlagReportRespondentRole: new FormControl(),
        redFlagReportRespondentComment: new FormControl('', [
          Validators.maxLength(256),
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ]),
        reportDate: new FormControl(),
      });
    }
  }

  onValidCaptchaResponse(captcha: string) {
    this.reportModalForm.controls['captcha'].setValue(captcha);
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    const date = new Date().getTime();
    this.reportModalForm.patchValue({ reportDate: date });
    this.reportModalService.submitReport(this.reportModalForm.value).subscribe(
      (success) => {
        this.isSubmitting = false;

        this.notificationsService.success(
          this.translateService.instant('ReportModalComponent-TITLE'),
          this.translateService.instant('ReportModalComponent-SUBMIT_SUCCESS')
        );

        this.modalActions.emit({ action: 'modal', params: ['close'] });

        this.reportInputModel = null;

        this.resetForm();
      },
      (error) => {
        this.isSubmitting = false;

        handleErrors(error, this.translateService, this.notificationsService, 'ReportModalComponent-TITLE');
      }
    );
  }
}
