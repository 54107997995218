import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyCodeObject } from 'app/models';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(amount: number, obj: CurrencyCodeObject): string | number {
    // Set up number formatting object to control decimal digits
    const formatOptions = {
      minimumFractionDigits: obj ? obj.decimal_digits : 2,
      maximumFractionDigits: obj ? obj.decimal_digits : 2,
    };

    // Format currency number
    const amountFormat = new Intl.NumberFormat([], formatOptions).format(amount);

    // Add currency symbol
    if (obj && obj.symbol) {
      return obj.symbol + amountFormat;
    }

    return amountFormat;
  }
}
