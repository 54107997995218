import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CountryService } from '../country.service';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboardCreateCountry',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css', '../../../common-create.css'],
  providers: [],
})
export class DashboardCountryCreateComponent implements OnInit {
  isSubmitting: boolean;

  editCountryForm: FormGroup;
  loading: boolean = false;

  constructor(
    private router: Router,
    private countryService: CountryService,
    private translateService: TranslateService,
    private globalLoaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {
    this.isSubmitting = false;
  }

  ngOnInit() {
    this.editCountryForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(128)]),
      countryCode: new FormControl('', Validators.maxLength(8)),
      abbreviation: new FormControl('', [Validators.required, Validators.maxLength(8)]),
      background: new FormControl(),
      countryHashtag: new FormControl('', Validators.maxLength(128)),
      metadata: new FormControl('', Validators.maxLength(40)),
      longAbbreviation: new FormControl('', Validators.maxLength(8)),
    });
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    const newCountry = this.editCountryForm.value;

    this.globalLoaderService.start();
    this.loading = true;

    this.countryService.addCountry(newCountry).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.globalLoaderService.complete();
        this.loading = false;

        this.notificationService.success(
          this.translateService.instant('COUNTRY_COMMON-TITLE'),
          this.translateService.instant('DashboardCountryCreateComponent-SUCCESS_CREATE_MESSAGE', {
            countryName: newCountry.name,
          })
        );

        this.router.navigate(['dashboard/countries']);
      },
      (error) => {
        this.isSubmitting = false;
        this.globalLoaderService.complete();
        this.loading = false;

        handleErrors(error, this.translateService, this.notificationService, 'COUNTRY_COMMON-TITLE');
      }
    );
  }

  onCancel() {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      this.router.navigate(['dashboard/countries']);
    }
  }
}
