import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from '../../common/config';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

const VIMEO_EMBED_BASE_URL: string = 'https://player.vimeo.com/video/';
const YOUTUBE_EMBED_BASE_URL: string = 'https://www.youtube.com/embed/';
const AUTOPLAY_ENABLED: string = 'autoplay=1&mute=1';
const VIDEO_ID: string = 'videoId';

@Component({
  selector: 'parse-video-url',
  templateUrl: './parse-video-url.component.html',
  styleUrls: ['./parse-video-url.component.css'],
})
export class ParseVideoUrlComponent implements OnInit {
  @Input() public source: string;
  @Input() public autoplay: boolean = false;
  @Input() public videoId: string = '';

  public trustedUrl: SafeResourceUrl = '';
  public AppSettings = AppSettings;

  constructor(
    private sanitizer: DomSanitizer,
    private clipboardService: ClipboardService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.trustedUrl = this.parseUrl();
  }

  public copyShareUrlToClipboard() {
    const href = window.location.href;
    let shareUrl = '';

    if (href.indexOf(this.videoId) > -1) {
      shareUrl = href;
    } else {
      shareUrl = this.removeURLParameter(href, VIDEO_ID);

      const symbol = shareUrl.indexOf('?') > -1 ? '&' : '?';
      shareUrl = `${shareUrl}${symbol}${VIDEO_ID}=${this.videoId}`;
    }

    this.clipboardService.copyFromContent(shareUrl);

    this.notificationsService.success(
      this.translateService.instant('UNIVERSAL-COPY-SUCCESSFUL'),
      this.translateService.instant('UNIVERSAL-SHARE_LINK_COPY_SUCCESSFUL')
    );
  }

  private parseUrl() {
    let url: string = '';
    let matches;

    if (this.source.match(AppSettings.VIMEO_URL_REGEX_PATTERN)) {
      matches = AppSettings.VIMEO_URL_REGEX_PATTERN.exec(this.source);
      url = matches && VIMEO_EMBED_BASE_URL + matches[1];
    } else if (this.source.match(AppSettings.YOUTUBE_URL_REGEX_PATTERN)) {
      matches = AppSettings.YOUTUBE_URL_REGEX_PATTERN.exec(this.source);
      url = matches && YOUTUBE_EMBED_BASE_URL + matches[1];
    }

    if (this.autoplay) {
      const symbol = url.indexOf('?') > -1 ? '&' : '?';
      url += symbol + AUTOPLAY_ENABLED;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private removeURLParameter(url: string, parameter: string) {
    const urlParts = url.split('?');

    if (urlParts.length >= 2) {
      const prefix = encodeURIComponent(parameter) + '=';
      const parts = urlParts[1].split(/[&;]/g);

      for (let i = parts.length - 1; i >= 0; i -= 1) {
        if (parts[i].lastIndexOf(prefix, 0) !== -1) {
          parts.splice(i, 1);
        }
      }

      return urlParts[0] + (parts.length > 0 ? '?' + parts.join('&') : '');
    }
    return url;
  }
}
