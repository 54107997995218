<div id="header">
  <nav>
    <div class="nav-wrapper accent-1">
      <div class="left-align logo-img-wrapper">
        <a *ngIf="!homeLinkDisable" [routerLink]="['/']" class="brand-logo">
          <img src="assets/images/SR_Logo_2.png" />
        </a>
        <span *ngIf="homeLinkDisable" class="brand-logo">
          <img src="assets/images/SR_Logo_2.png" />
        </span>
        <a class="right header-right-text" target="_blank" href="http://disasteraccountability.org/">
          {{ 'HeaderComponent-INITIATIVE_OF_DAP' | translate }}
        </a>
      </div>

      <a
        materialize="sideNav"
        [materializeParams]="[{ menuWidth: 250 }]"
        href="#"
        data-activates="dashboard-nav"
        class="button-collapse"
      >
        <i class="material-icons">menu</i>
      </a>
    </div>
  </nav>
</div>

<ul class="side-nav fixed grey lighten-4" id="dashboard-nav">
  <ng-template [ngIf]="!authService.isLoggedIn">
    <a [routerLink]="['/dap-donation']" class="donate-button btn yellow black-text">
      {{ 'SideNavComponent-DONATE_BUTTON' | translate }}
    </a>

    <a class="subscribe-button btn yellow black-text" [routerLink]="['/subscribe']">
      {{ 'HeaderComponent-SUBSCRIBE_LINK' | translate }}
    </a>

    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="no-padding" [routerLink]="['']">
        <i class="header-icon material-icons icon-padding left-align">home</i>
        {{ 'HeaderComponent-HOME_LINK' | translate }}
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="no-padding" [routerLink]="['/intro']">
        <i class="header-icon material-icons icon-padding left-align">lightbulb_outline</i>
        {{ 'HeaderComponent-WHY_SR' | translate }}
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="no-padding" [routerLink]="['/global-org-map']">
        <i class="header-icon material-icons icon-padding left-align">map</i>
        {{ 'HeaderComponent-ORG_MAP' | translate }}
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="no-padding" href="https://smartresponse-updates.blogspot.com/" target="_blank">
        <i class="header-icon material-icons icon-padding left-align">vertical_split</i>
        {{ 'HeaderComponent-SR_UPDATES' | translate }}
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="no-padding" [routerLink]="['/login']">
        <i class="header-icon material-icons icon-padding left-align">account_circle</i>
        {{ 'HeaderComponent-LOGIN_LINK' | translate }}
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="no-padding" [routerLink]="['/registration']">
        <i class="header-icon material-icons icon-padding left-align">add</i>
        {{ 'HeaderComponent-ADD_ORGANIZATION_LINK' | translate }}
      </a>
    </li>

    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="no-padding" [routerLink]="['/nominate']">
        <i class="header-icon material-icons icon-padding left-align">email</i>
        {{ 'HeaderComponent-NOMINATE_ORGANIZATION_LINK' | translate }}
      </a>
    </li>
    <li>
      <div class="divider"></div>
    </li>

    <div>
      <ul>
        <li class="icon-padding sr-bold">{{ 'SideNavComponent-FAQS_FOR_TITLE' | translate }}:</li>

        <li>
          <a routerLink="/help/faq/donor" class="no-padding"
            ><i class="header-icon material-icons icon-padding left-align">payments</i>
            {{ 'SideNavComponent-DONOR_LINK' | translate }}</a
          >
        </li>

        <li>
          <a routerLink="/help/faq/media" class="no-padding"
            ><i class="header-icon material-icons icon-padding left-align">settings_input_antenna</i>
            {{ 'SideNavComponent-MEDIA_LINK' | translate }}</a
          >
        </li>

        <li>
          <a routerLink="/help/faq/survivor" class="no-padding"
            ><i class="header-icon material-icons icon-padding left-align">person</i>
            {{ 'SideNavComponent-DISASATER_SURVIVOR_LINK' | translate }}</a
          >
        </li>

        <li>
          <a routerLink="/help/faq/org" class="no-padding"
            ><i class="header-icon material-icons icon-padding left-align">local_hospital</i>
            {{ 'SideNavComponent-AID_ORGANIZATION_LINK' | translate }}</a
          >
        </li>

        <li>
          <a routerLink="/help/faq/gov" class="no-padding"
            ><i class="header-icon material-icons icon-padding left-align">account_balance</i>
            {{ 'SideNavComponent-GOVERNMENT_LINK' | translate }}</a
          >
        </li>
      </ul>
    </div>
  </ng-template>

  <ng-template [ngIf]="authService.isLoggedIn">
    <li>
      <div class="black-text center username sr-bold">
        {{ authService.getProfile().firstName }} {{ authService.getProfile().lastName }}
      </div>

      <div class="black-text center lastLogin-text">
        <div class="sr-bold last-login-field">{{ 'DashboardComponent-LAST-LOGIN-TITLE' | translate }}</div>

        <div class="last-login-field">
          {{ authService.getProfile().lastLoginTime | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }}
        </div>
      </div>
    </li>

    <li>
      <div class="divider"></div>
    </li>
    <li>
      <a
        class="no-padding"
        [routerLink]="['']"
        routerLinkActive="grey lighten-2"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="header-icon material-icons icon-padding left-align">home</i>
        {{ 'HeaderComponent-HOME_LINK' | translate }}
      </a>
    </li>

    <li>
      <a class="hover-effect no-padding" (click)="authService.logout()">
        <i class="header-icon material-icons icon-padding left-align">lock_open</i>
        {{ 'HeaderComponent-LOGOUT_LINK' | translate }}
      </a>
    </li>

    <li>
      <div class="divider"></div>
    </li>

    <li>
      <a
        class="waves-effect waves-teal no-padding"
        [routerLink]="['/dashboard']"
        routerLinkActive="grey lighten-2"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="header-icon material-icons icon-padding left-align">dashboard</i>
        <span class="navBtn">{{ 'DashboardComponent-DASHBOARD-NAVIGATION' | translate }}</span>
      </a>
    </li>
    <li>
      <a
        class="waves-effect waves-teal no-padding"
        href="https://www.youtube.com/playlist?list=PLAC2Bb_b3RZhX9p4kW-Pa0I9y3ENgsS_v"
        target="_blank"
      >
        <i class="header-icon material-icons icon-padding left-align">video_library</i>
        <span class="navBtn">{{ 'DashboardComponent-TUTORIALS-NAVIGATION' | translate }}</span>
      </a>
    </li>
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="no-padding" [routerLink]="['/global-org-map']">
        <i class="header-icon material-icons icon-padding left-align">map</i>
        {{ 'HeaderComponent-ORG_MAP' | translate }}
      </a>
    </li>
    <li *ngIf="!authService.isSuper()">
      <a
        class="waves-effect waves-teal no-padding"
        [routerLink]="['/dashboard/disasters/nominate']"
        routerLinkActive="grey lighten-2"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <i class="header-icon material-icons icon-padding left-align">whatshot</i>
        <span class="navBtn">{{ 'DashboardComponent-DISASTER-SUGGEST' | translate }}</span>
      </a>
    </li>

    <ng-template [ngIf]="authService.isSuper()">
      <li>
        <a
          class="waves-effect waves-teal no-padding"
          [routerLink]="['/dashboard/organizations']"
          routerLinkActive="grey lighten-2"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="header-icon material-icons icon-padding left-align">device_hub</i>
          <span class="navBtn">{{ 'DashboardComponent-ORG-NAVIGATION' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          class="waves-effect waves-teal no-padding"
          [routerLink]="['/dashboard/disasters']"
          routerLinkActive="grey lighten-2"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="header-icon material-icons icon-padding left-align">whatshot</i>
          <span class="navBtn">{{ 'DashboardComponent-DISASTER-NAVIGATION' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          class="waves-effect waves-teal no-padding"
          [routerLink]="['/dashboard/services']"
          routerLinkActive="grey lighten-2"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="header-icon material-icons icon-padding left-align">airline_seat_individual_suite</i>
          <span class="navBtn">{{ 'DashboardComponent-SERVICES-NAVIGATION' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          class="waves-effect waves-teal no-padding"
          [routerLink]="['/dashboard/countries']"
          routerLinkActive="grey lighten-2"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="header-icon material-icons icon-padding left-align">flag</i>
          <span class="navBtn">{{ 'DashboardComponent-COUNTRY-NAVIGATION' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          class="waves-effect waves-teal no-padding"
          [routerLink]="['/dashboard/configure-lists']"
          routerLinkActive="grey lighten-2"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="header-icon material-icons icon-padding left-align">list</i>
          <span class="navBtn">{{ 'DashboardComponent-LISTS-NAVIGATION' | translate }}</span>
        </a>
      </li>
      <li>
        <a
          class="waves-effect waves-teal no-padding"
          [routerLink]="['/dashboard/messages']"
          routerLinkActive="grey lighten-2"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="header-icon material-icons icon-padding left-align">textsms</i>
          <span class="navBtn">{{ 'DashboardComponent-MESSAGES' | translate }}</span>
          <unread-messages [count]="unreadMessagesService.getCount()"></unread-messages>
        </a>
      </li>
    </ng-template>
    <ng-template [ngIf]="authService.isOrgGeneral() || authService.isSuper()">
      <li>
        <a
          class="waves-effect waves-teal no-padding"
          [routerLink]="['/dashboard/users']"
          routerLinkActive="grey lighten-2"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <i class="header-icon material-icons icon-padding left-align">group</i>
          <span class="navBtn">{{ 'DashboardComponent-USER-MGMT-NAVIGATION' | translate }}</span>
        </a>
      </li>
    </ng-template>
    <li *ngIf="authService.isOrgGeneral() || authService.isLocation()">
      <a
        class="waves-effect waves-teal no-padding"
        [routerLink]="['/dashboard/surveyNotifications']"
        routerLinkActive="grey lighten-2"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <i class="header-icon material-icons icon-padding left-align">notifications</i>
        <span class="navBtn">{{ 'DashboardComponent-SURVEY-NOTIFICATIONS' | translate }}</span>
      </a>
    </li>
    <li>
      <a
        class="waves-effect waves-teal no-padding"
        [routerLink]="['/dashboard/change-password']"
        routerLinkActive="grey lighten-2"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <i class="header-icon material-icons icon-padding left-align">perm_identity</i>
        <span class="navBtn">{{ 'DashboardComponent-CHANGE_PASSWORD' | translate }}</span>
      </a>
    </li>
    <li *ngIf="authService.isOrgGeneral() || authService.isLocation()">
      <a class="waves-effect waves-teal no-padding" (click)="onSendMessageClick()">
        <i class="header-icon material-icons icon-padding left-align">message</i>
        <span class="navBtn">{{ 'HeaderComponent-SEND_MESSAGE_TO_ADMIN' | translate }}</span>
      </a>
    </li>
  </ng-template>
</ul>

<picker-menu></picker-menu>
