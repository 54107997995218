import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Dictionary } from 'app/models';

@Injectable()
export class DashboardRedirectService {
  constructor(private router: Router) {}
  /**
   * An RxJS operator that responds to unauthorized error responses with a silent redirect to the dashboard.
   *
   * @param extraErrorCodes additional error codes that overlap with unauthorized requests
   * @returns the original error response, or no response if unauthorized
   */
  public redirectUnauthorized(extraErrorCodes?: number[]): (src: Observable<any>) => Observable<any> {
    const errorCodes: Dictionary<boolean> = {
      401: true,
      403: true,
    };
    if (extraErrorCodes && extraErrorCodes.length) {
      for (const code of extraErrorCodes) {
        errorCodes[code] = true;
      }
    }
    return (src: Observable<any>) =>
      src.pipe(
        catchError((resp) => {
          if (resp.status && errorCodes[resp.status]) {
            this.router.navigate(['/dashboard']);
            return EMPTY;
          }
          return throwError(resp);
        })
      );
  }
}
