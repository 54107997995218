import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicesProvidedService } from '../service.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AppSettings } from '../../../../common/config';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { Service } from '../../../../models/Service';

@Component({
  selector: 'dashboardCreateService',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css', '../../../common-create.css'],
  providers: [],
})
export class DashboardServiceCreateComponent implements OnInit {
  isSubmitting: boolean;
  error: string;

  editServiceForm: FormGroup;
  logoFile: File;
  loading: boolean;

  constructor(
    private router: Router,
    private servicesProvided: ServicesProvidedService,
    private builder: FormBuilder,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {
    this.isSubmitting = false;
  }

  ngOnInit() {
    this.editServiceForm = this.builder.group({
      name: new FormControl('', Validators.required),
      summary: new FormControl('', Validators.required),
    });
  }

  onLogoSelected(event: any) {
    const targetFile = event.target.files[0];

    if (undefined === targetFile) {
      this.logoFile = null;
    }

    if (!(targetFile instanceof File)) {
      return;
    }

    if (targetFile.size > AppSettings.MAXIMUM_IMAGE_UPLOAD_SIZE) {
      this.notificationService.error(
        this.translateService.instant('DashboardServiceCreateComponent-ADD_SERVICE_TITLE'),
        this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    if (AppSettings.DEFAULT_ALLOWED_IMAGE_TYPES.indexOf(targetFile.type) === -1) {
      this.notificationService.error(
        this.translateService.instant('DashboardServiceCreateComponent-ADD_SERVICE_TITLE'),
        this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    this.logoFile = targetFile;
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.loaderService.start();
    this.loading = true;

    const newService: Service = {
      name: this.editServiceForm.value.name,
      summary: this.editServiceForm.value.summary,
      logo: this.logoFile,
    };

    this.servicesProvided.addService(newService).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.loaderService.complete();
        this.loading = false;

        this.notificationService.success(
          this.translateService.instant('SERVICE_COMMON_TITLE'),
          this.translateService.instant('DashboardServiceCreateComponent-SUCCESS_CREATE_MESSAGE', {
            serviceName: newService.name,
          })
        );
        this.router.navigate(['dashboard/services']);
      },
      (error) => {
        this.isSubmitting = false;
        this.loaderService.complete();
        this.loading = false;

        handleErrors(error, this.translateService, this.notificationService, 'SERVICE_COMMON_TITLE');
      }
    );
  }

  onCancel() {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      this.router.navigate(['dashboard/services']);
    }
  }
}
