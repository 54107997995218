import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoaderModule } from '../common/loader/loader.module';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: LoginComponent }])],
  exports: [RouterModule],
})
export class LoginRoutingModule {}

@NgModule({
  imports: [CommonModule, LoginRoutingModule, TranslateModule, FormsModule, ReactiveFormsModule, LoaderModule],
  declarations: [LoginComponent],
  providers: [],
})
export class LoginFeatureModule {}
