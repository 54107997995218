<div>
  <div class="pb-row">
    <div *ngFor="let step of steps" class="col pb-col center-align">
      <div class="pb-bar"></div>
      <div
        [class.pb-current]="step == currentStepIndex - 1"
        [class.pb-tbd]="step != currentStepIndex - 1"
        class="step-circle valign-wrapper modal-action"
        [class.modal-close]="step != currentStepIndex - 1"
        (click)="step != currentStepIndex - 1 ? onSelectStep(step) : null"
      >
        {{ step + 1 }}
      </div>
      <p class="hide-on-small-only step-text">Step {{ step + 1 }}</p>
    </div>
  </div>
</div>
