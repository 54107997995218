<div class="card">
  <app-loader [loading]="loading"></app-loader>
  <div class="card-content">
    <div class="card-title sr-bold">{{ 'DashboardServiceCreateComponent-ADD_SERVICE_TITLE' | translate }}</div>
    <div class="divider"></div>
    <div class="sr-required-notif">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>
    <br />
    <div class="row">
      <div class="service-logo input-field file-field col s12">
        <div class="btn cyan">
          <span>{{ 'UNIVERSAL-FILE' | translate }}</span>
          <input type="file" (change)="onLogoSelected($event)" />
        </div>
        <div class="file-path-wrapper">
          <input
            class="file-path validate"
            id="service-logo-upload-button"
            type="text"
            placeholder="Upload logo file"
          />
        </div>
        <p class="sr-img-guidelines">
          {{ 'LogoUploadComponent-MAXIMUM_IMAGE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

          {{ 'LogoUploadComponent-RESIZE-IMG-WARNING' | translate }}<br />

          {{ 'LogoUploadComponent-DEFAULT_ALLOWED_IMAGE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
        </p>
      </div>
    </div>

    <div class="row">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !editServiceForm.controls['name'].valid && editServiceForm.controls['name'].touched
        }"
      >
        <input id="name" type="text" [formControl]="editServiceForm.controls['name']" />
        <label for="name" class="black-text">
          {{ 'SERVICE_COMMON-NAME_LABEL' | translate }}
          <span class="sr-required-mark">*</span>
        </label>
        <div
          class="sr-error-area"
          *ngIf="!editServiceForm.controls['name'].valid && editServiceForm.controls['name'].touched"
        >
          <div *ngIf="editServiceForm.controls['name'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !editServiceForm.controls['summary'].valid && editServiceForm.controls['summary'].touched
        }"
      >
        <textarea id="summary" class="materialize-textarea" [formControl]="editServiceForm.controls['summary']">
        </textarea>
        <label for="summary" class="black-text">
          {{ 'SERVICE_COMMON-SUMMARY_LABEL' | translate }} <span class="sr-required-mark">*</span>
        </label>
        <div
          class="sr-error-area"
          *ngIf="!editServiceForm.controls['summary'].valid && editServiceForm.controls['summary'].touched"
        >
          <div *ngIf="editServiceForm.controls['summary'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12">
        <div class="my_btns right">
          <button
            class="btn waves-effect waves-light cyan"
            [disabled]="isSubmitting || !editServiceForm.valid"
            (click)="onSubmit()"
          >
            {{ 'UNIVERSAL-NAVIGATION_ADD' | translate }}
          </button>
          <a class="btn cyan" (click)="onCancel()">
            {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
