<div>
  <div class="modal-header">
    <survey-step-progress-bar [totalSteps]="7" [currentStepIndex]="1" (stepTabClicked)="onSelectStep($event)">
    </survey-step-progress-bar>
    <h4 class="modal-step-title">
      {{
        'DisasterSpecificSurveyComponent-STEP_1_TITLE'
          | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
      }}
    </h4>
  </div>
  <div class="modal-content">
    <div class="divider title-divider"></div>

    <div class="row margin">
      <div class="spacing-div col s12">
        <div class="input-field spacing col s12">
          <input
            id="s4_is_responding_to_disaster"
            class="with-gap"
            name="s4_is_responding_to_disaster"
            type="checkbox"
            [formControl]="organizationRespondingToDisasterForm.get('value')"
          />

          <label for="s4_is_responding_to_disaster">
            {{
              'DisasterSpecificSurveyComponent-STEP_1_Q_EVER_PROVIDED'
                | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
            }}
          </label>
        </div>
      </div>

      <div>
        <!-- relief recoverery services -->
        <div id="ques11" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 5 } }}
        </div>
        <div class="spacing-div col s12">
          <div
            *ngIf="
              reliefRecoveryServiceForm.get('value').get('isNotProvidingService') &&
              !reliefRecoveryServiceForm.get('value').get('isNotProvidingService').value
            "
          >
            <div class="input-field spacing col s12">
              <p>
                {{
                  'DisasterSpecificSurveyComponent-STEP_1_Q_CURRENTLY_PROVIDING'
                    | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
                }}
              </p>

              <input
                id="s1_currently_providing_yes_rb"
                type="radio"
                class="with-gap"
                name="s1_currently_providing"
                [value]="true"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="reliefRecoveryServiceForm.get('value').get('isCurrentlyProvidingService')"
              />

              <label for="s1_currently_providing_yes_rb">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>
              <br />
              <input
                id="s1_currently_providing_no_rb"
                type="radio"
                class="with-gap"
                name="s1_currently_providing"
                [value]="false"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="reliefRecoveryServiceForm.get('value').get('isCurrentlyProvidingService')"
              />

              <label for="s1_currently_providing_no_rb">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
              <div class="sr-error-area" *ngIf="!reliefRecoveryServiceForm.get('value').valid">
                <div *ngIf="reliefRecoveryServiceForm.get('value').hasError('isProvidingService')">
                  {{ 'SurveyCommon-SELECT_YES_OR_NO' | translate }}
                </div>
              </div>
            </div>

            <div class="spacing-div col s12">
              <p class="col s12">
                {{
                  'DisasterSpecificSurveyComponent-STEP_1_Q_WHEN_BEGAN_PROVIDING'
                    | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
                }}
              </p>
              <div
                class="input-field col s12"
                [ngClass]="{
                  'sr-has-error':
                    !reliefRecoveryServiceForm.get('value').get('startServiceDate').valid &&
                    reliefRecoveryServiceForm.get('value').get('startServiceDate').value
                }"
              >
                <input
                  type="date"
                  min="1000-01-01"
                  max="3000-01-01"
                  placeholder="{{ 'UNIVERSAL-START_DATE_LABEL' | translate }}"
                  id="relief_start_date"
                  [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                  [formControl]="reliefRecoveryServiceForm.get('value').get('startServiceDate')"
                />

                <div class="sr-error-area" *ngIf="!reliefRecoveryServiceForm.get('value').valid">
                  <div *ngIf="reliefRecoveryServiceForm.get('value').get('startServiceDate').hasError('dateFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
                  </div>
                  <div *ngIf="reliefRecoveryServiceForm.get('value').get('startServiceDate').hasError('pastDate')">
                    {{ 'UNIVERSAL-FORM_ERRORS_PAST_DATE' | translate }}
                  </div>
                  <div *ngIf="reliefRecoveryServiceForm.get('value').hasError('mustHaveStartDate')">
                    {{ 'SurveyCommon-ENTER_START_DATE' | translate }}
                  </div>
                  <div *ngIf="reliefRecoveryServiceForm.get('value').hasError('startDateCanNotBeAfterStop')">
                    {{ 'SurveyCommon-START_DATE_CANNOT_BE_AFTER_END_DATE' | translate }}
                  </div>
                  <div *ngIf="reliefRecoveryServiceForm.get('value').hasError('mustHaveBothDates')">
                    {{ 'SurveyCommon-BOTH_START_DATE_END_DATE' | translate }}
                  </div>
                  <div *ngIf="reliefRecoveryServiceForm.get('value').get('startServiceDate').hasError('inRangeYear')">
                    {{ 'UNIVERSAL-FORM_ERRORS_YEAR_RANGE' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="spacing-div col s12"
              *ngIf="
                reliefRecoveryServiceForm.get('value').get('isCurrentlyProvidingService') &&
                !reliefRecoveryServiceForm.get('value').get('isCurrentlyProvidingService').value
              "
            >
              <p class="col s12">
                {{
                  'DisasterSpecificSurveyComponent-STEP_1_Q_WHEN_STOPPED_PROVIDING'
                    | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
                }}
              </p>
              <div
                class="input-field col s12"
                [ngClass]="{
                  'sr-has-error':
                    !reliefRecoveryServiceForm.get('value').get('stopServiceDate').valid &&
                    reliefRecoveryServiceForm.get('value').get('stopServiceDate').value
                }"
              >
                <input
                  type="date"
                  min="1000-01-01"
                  max="3000-01-01"
                  placeholder="{{ 'UNIVERSAL-END_DATE_LABEL' | translate }}"
                  id="relief_end_date"
                  [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                  [formControl]="reliefRecoveryServiceForm.get('value').get('stopServiceDate')"
                />

                <div class="sr-error-area" *ngIf="!reliefRecoveryServiceForm.get('value').valid">
                  <div *ngIf="reliefRecoveryServiceForm.get('value').get('stopServiceDate').hasError('dateFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
                  </div>
                  <div *ngIf="reliefRecoveryServiceForm.get('value').get('stopServiceDate').hasError('pastDate')">
                    {{ 'UNIVERSAL-FORM_ERRORS_PAST_DATE' | translate }}
                  </div>
                  <div *ngIf="reliefRecoveryServiceForm.get('value').hasError('canNotHaveStopDate')">
                    {{ 'SurveyCommon-DONOT_ENTER_END_DATE' | translate }}
                  </div>
                  <div *ngIf="reliefRecoveryServiceForm.get('value').get('stopServiceDate').hasError('inRangeYear')">
                    {{ 'UNIVERSAL-FORM_ERRORS_YEAR_RANGE' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <last-edited-metadata
              class="no-left-spacing"
              [formElement]="reliefRecoveryServiceForm"
              [isDisabled]="organizationRespondingToDisaster ? '' : null"
            ></last-edited-metadata>
          </div>
        </div>

        <!-- history of activity -->
        <div id="ques12" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 5 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="input-field col s12">
            <p>
              {{
                'DisasterSpecificSurveyComponent-STEP_1_Q_HISTORY_ACTIVITY'
                  | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
              }}
              ( {{ 'UNIVERSAL-MAX_CHARACTERS' | translate: { number: 600 } }} )
            </p>
            <textarea
              id="s1_history_activity"
              class="materialize-textarea"
              materialize="characterCounter"
              length="600"
              maxlength="600"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              [formControl]="activityHistoryForm.get('value')"
            >
            </textarea>
          </div>
          <div>
            <last-edited-metadata
              class="no-left-spacing"
              [formElement]="activityHistoryForm"
              [isDisabled]="organizationRespondingToDisaster ? '' : null"
            ></last-edited-metadata>
          </div>
        </div>

        <!-- stages of disaster -->
        <div id="ques13" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 5 } }}
        </div>
        <div class="spacing-div col s12">
          <p>
            {{
              'DisasterSpecificSurveyComponent-STEP_1_Q_DISASTER_STAGES'
                | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
            }}
          </p>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_PREP' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [value]="true"
                name="preparedness_checkbox"
                type="radio"
                id="preparedness_checkbox_yes"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('preparedStage')"
              />
              <label for="preparedness_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>

              <input
                [value]="false"
                name="preparedness_checkbox"
                type="radio"
                id="preparedness_checkbox_no"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('preparedStage')"
              />
              <label for="preparedness_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_MITI' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [value]="true"
                name="mitigationStage_checkbox"
                type="radio"
                id="mitigationStage_checkbox_yes"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('mitigationStage')"
              />
              <label for="mitigationStage_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>

              <input
                [value]="false"
                name="mitigationStage_checkbox"
                type="radio"
                id="mitigationStage_checkbox_no"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('mitigationStage')"
              />
              <label for="mitigationStage_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_RELI' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [value]="true"
                name="reliefStage_checkbox"
                type="radio"
                id="reliefStage_checkbox_yes"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('reliefStage')"
              />
              <label for="reliefStage_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>

              <input
                [value]="false"
                name="reliefStage_checkbox"
                type="radio"
                id="reliefStage_checkbox_no"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('reliefStage')"
              />
              <label for="reliefStage_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_RESP' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [value]="true"
                name="responseStage_checkbox"
                type="radio"
                id="responseStage_checkbox_yes"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('responseStage')"
              />
              <label for="responseStage_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>

              <input
                [value]="false"
                name="responseStage_checkbox"
                type="radio"
                id="responseStage_checkbox_no"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('responseStage')"
              />
              <label for="responseStage_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_RECO' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [value]="true"
                name="recoveryStage_checkbox"
                type="radio"
                id="recoveryStage_checkbox_yes"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('recoveryStage')"
              />
              <label for="recoveryStage_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>

              <input
                [value]="false"
                name="recoveryStage_checkbox"
                type="radio"
                id="recoveryStage_checkbox_no"
                class="with-gap"
                [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                [formControl]="disasterStageOfWorkForm.get('value').get('recoveryStage')"
              />
              <label for="recoveryStage_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>
          <div>
            <last-edited-metadata
              class="no-left-spacing"
              [formElement]="disasterStageOfWorkForm"
              [isDisabled]="organizationRespondingToDisaster ? '' : null"
            ></last-edited-metadata>
          </div>
        </div>

        <!-- stakeholders, constituents -->
        <div id="ques14" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: 5 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="input-field col s12">
            <p>
              {{
                'DisasterSpecificSurveyComponent-STEP_1_Q_STAKEHOLDERS_CONSTITUENTS'
                  | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
              }}
              ( {{ 'UNIVERSAL-MAX_CHARACTERS' | translate: { number: 1200 } }} )
            </p>
            <textarea
              id="s1_stakeholders_constituents"
              class="materialize-textarea"
              materialize="characterCounter"
              length="1200"
              maxlength="1200"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              [formControl]="stakeholderForm.get('value')"
            >
            </textarea>
          </div>
          <div>
            <last-edited-metadata
              class="no-left-spacing"
              [formElement]="stakeholderForm"
              [isDisabled]="organizationRespondingToDisaster ? '' : null"
            ></last-edited-metadata>
          </div>
        </div>

        <!-- video URL -->
        <div id="ques15" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 5, QuestionCount: 5 } }}
        </div>
        <div class="spacing-div col s12">
          <p for="org_response_video_url">
            {{
              'DisasterSpecificSurveyComponent-STEP_1_Q_ORG_RESPONSE_VIDEO_URL'
                | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
            }}
          </p>

          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !orgResponseVideoUrlForm.get('value').valid &&
                (orgResponseVideoUrlForm.get('value').touched || invalidOnInit?.singles?.orgResponseVideoUrl)
            }"
          >
            <i class="material-icons prefix">link</i>
            <input
              id="org_response_video_url"
              materialize="characterCounter"
              length="256"
              maxlength="256"
              type="url"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              [formControl]="orgResponseVideoUrlForm.get('value')"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !orgResponseVideoUrlForm.get('value').valid &&
                (orgResponseVideoUrlForm.get('value').touched || invalidOnInit?.singles?.orgResponseVideoUrl)
              "
            >
              <div *ngIf="orgResponseVideoUrlForm.get('value').errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
              </div>
              <div *ngIf="orgResponseVideoUrlForm.get('value').hasError('vimeoYoutubeUrlFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_VIMEO_YOUTUBE_URL' | translate }}
              </div>
            </div>
          </div>

          <div>
            <last-edited-metadata
              class="no-left-spacing"
              [isDisabled]="organizationRespondingToDisaster ? '' : null"
              [formElement]="orgResponseVideoUrlForm"
            ></last-edited-metadata>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer survey-modal-footer">
    <survey-footer
      [isFirstStep]="true"
      [currentStepIndex]="0"
      [totalSteps]="totalSteps"
      [isValid]="isValid"
      (next)="onNext()"
      (prev)="onPrev()"
      (submit)="onSubmit()"
      (cancel)="onCancel()"
      (error)="onError()"
    >
    </survey-footer>
  </div>
</div>
