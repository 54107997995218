<blocking-loader [blockingLoaderActions]="blockingLoaderEventEmitter"></blocking-loader>
<app-loader [loading]="loading"></app-loader>
<div class="col s12">
  <div class="card">
    <div *ngIf="editDisasterForm" class="card-content black-text">
      <h4>
        {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
        <span class="sr-italic">{{ currentDisaster.name }} </span>
        {{ 'UNIVERSAL-INFORMATION_TEXT' | translate }}
      </h4>

      <div class="divider"></div>

      <div class="sr-required-notif">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>

      <div class="row margin">
        <div class="input-field col s12">
          <input
            id="inactive"
            type="checkbox"
            ng-true-value="1"
            ng-false-value="0"
            [formControl]="editDisasterForm.controls['inactive']"
          />
          <label for="inactive" class="black-text">
            {{ 'DISASTER_COMMON-INACTIVE_LABEL' | translate }}
          </label>
        </div>
      </div>

      <div class="row margin">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error': !editDisasterForm.controls['name'].valid && editDisasterForm.controls['name'].touched
          }"
        >
          <input
            id="name"
            type="text"
            materialize="characterCounter"
            length="64"
            maxlength="64"
            [formControl]="editDisasterForm.controls['name']"
          />
          <label for="name">
            {{ 'DISASTER_COMMON-NAME_LABEL' | translate }}
            <span class="sr-required-mark">*</span>
          </label>

          <div
            class="sr-error-area"
            *ngIf="!editDisasterForm.controls['name'].valid && editDisasterForm.controls['name'].touched"
          >
            <div *ngIf="editDisasterForm.controls['name'].hasError('required')">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['name'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 64 } }}
            </div>
            <div *ngIf="editDisasterForm.controls['name'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div class="input-field col s12">
          <div class="grey-text">
            {{ 'DISASTER_COMMON-DISASTER_LOGO_LABEL' | translate }}
          </div>
          <div class="file-field input-field">
            <div class="btn cyan">
              <span>
                {{ 'UNIVERSAL-FILE' | translate }}
              </span>
              <input (change)="onFileSelected($event)" id="logo" type="file" />
            </div>
            <div class="file-path-wrapper">
              <input class="file-path" type="text" />
            </div>
          </div>
          <p class="sr-img-guidelines">
            {{ 'LogoUploadComponent-MAXIMUM_IMAGE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

            {{ 'LogoUploadComponent-RESIZE-IMG-WARNING' | translate }}<br />

            {{ 'LogoUploadComponent-DEFAULT_ALLOWED_IMAGE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
          </p>

          <div *ngIf="editDisasterForm.controls['logo'].value" class="disaster-logo-preview">
            <p>
              {{ 'DISASTER_COMMON-LOGO_PREVIOUSLY_SET' | translate }}
            </p>

            <img class="responsive-img" src="{{ currentDisasterLogoURI }}" />

            <p>
              <button class="btn" (click)="editDisasterForm.controls['logo'].setValue(null)">
                {{ 'DISASTER_COMMON-LOGO_RESET' | translate }}
              </button>
            </p>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div class="rich-text-input-label">
          {{ 'DISASTER_COMMON-SUMMARY_LABEL' | translate }}
          ({{ 'UNIVERSAL-FORMATTED_MAX_CHARACTERS' | translate: { number: 1024 } }})
          <span class="sr-required-mark">*</span>
        </div>
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error': !editDisasterForm.controls['summary'].valid && editDisasterForm.controls['summary'].touched
          }"
        >
          <rich-text-editor id="summary" [form]="editDisasterForm.controls['summary']"> </rich-text-editor>

          <div
            class="sr-error-area"
            *ngIf="!editDisasterForm.controls['summary'].valid && editDisasterForm.controls['summary'].touched"
          >
            <div *ngIf="editDisasterForm.controls['summary'].hasError('required')">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['summary'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 1024 } }}
            </div>
            <div *ngIf="editDisasterForm.controls['summary'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error':
              !editDisasterForm.controls['disasterHashtag'].valid &&
              editDisasterForm.controls['disasterHashtag'].touched
          }"
        >
          <input
            id="disasterHashtag"
            type="text"
            materialize="characterCounter"
            length="128"
            maxlength="128"
            [formControl]="editDisasterForm.controls['disasterHashtag']"
          />
          <label for="disasterHashtag">
            {{ 'DISASTER_COMMON-HASHTAG_LABEL' | translate }}
            <span class="sr-required-mark">*</span>
          </label>

          <div
            class="sr-error-area"
            *ngIf="
              !editDisasterForm.controls['disasterHashtag'].valid &&
              editDisasterForm.controls['disasterHashtag'].touched
            "
          >
            <div *ngIf="editDisasterForm.controls['disasterHashtag'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 128 } }}
            </div>
          </div>
          <p class="chips-notif blue-text text-darken-2">
            {{ 'UNIVERSAL-INSTRUCTIONS_HASHTAG' | translate }}
          </p>
        </div>
      </div>

      <div class="row margin">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error':
              !editDisasterForm.controls['metadata'].valid && editDisasterForm.controls['metadata'].touched
          }"
        >
          <input
            id="metadata"
            type="text"
            materialize="characterCounter"
            length="40"
            maxlength="40"
            [formControl]="editDisasterForm.controls['metadata']"
          />
          <label for="metadata">
            {{ 'DISASTER_COMMON-METADATA_LABEL' | translate }}
          </label>

          <div
            class="sr-error-area"
            *ngIf="!editDisasterForm.controls['metadata'].valid && editDisasterForm.controls['metadata'].touched"
          >
            <div *ngIf="editDisasterForm.controls['metadata'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 40 } }}
            </div>
          </div>

          <div>
            <p class="chips-notif blue-text text-darken-2">
              {{ 'UNIVERSAL-INSTRUCTIONS_METADATA' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error': !editDisasterForm.controls['priority'].valid && editDisasterForm.controls['priority'].value
          }"
        >
          <input id="priority" type="number" [formControl]="editDisasterForm.controls['priority']" />
          <label for="priority">
            {{ 'DISASTER_COMMON-PRIORITY_LABEL' | translate }}
          </label>

          <div
            class="sr-error-area"
            *ngIf="!editDisasterForm.controls['priority'].valid && editDisasterForm.controls['priority'].value"
          >
            <div *ngIf="editDisasterForm.controls['priority'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 64 } }}
            </div>
            <div *ngIf="editDisasterForm.controls['priority'].hasError('pattern')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
            </div>
          </div>

          <p class="chips-notif blue-text text-darken-2">
            {{ 'DISASTER_COMMON-PRIORITY_DESCRIPTION' | translate }}
          </p>
        </div>
      </div>

      <div class="row margin">
        <div class="input-field">
          <div class="col s12 m4 label-text grey-text">
            {{ 'DISASTER_COMMON-DISASTER_TYPE_LABEL' | translate }}
            <span class="sr-required-mark">*</span>
          </div>
          <div
            class="col s12 m6 l4"
            [ngClass]="{
              'sr-has-error':
                !editDisasterForm.controls['disasterType'].valid && editDisasterForm.controls['disasterType'].touched
            }"
          >
            <select
              id="disasterType"
              [formControl]="editDisasterForm.controls['disasterType']"
              class="browser-default my_select"
            >
              <option value="" disabled>
                {{ 'UNIVERSAL-FORM_CHOOSE_OPTION' | translate }}
              </option>

              <option *ngFor="let disasterType of disasterTypes" [value]="disasterType">
                {{ disasterType }}
              </option>
            </select>

            <div
              class="sr-error-area"
              *ngIf="
                !editDisasterForm.controls['disasterType'].valid && editDisasterForm.controls['disasterType'].touched
              "
            >
              <div *ngIf="editDisasterForm.controls['disasterType'].hasError('required')">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div class="input-field">
          <div class="col s12 m4 label-text grey-text">
            {{ 'DISASTER_COMMON-NOTIFICATION_TRACK_LABEL' | translate }}
            <span class="sr-required-mark">*</span>
          </div>
          <div
            class="col s12 m6 l4"
            [ngClass]="{
              'sr-has-error':
                !editDisasterForm.controls['notificationTrack'].valid &&
                editDisasterForm.controls['notificationTrack'].touched
            }"
          >
            <select
              id="notificationTrack"
              [formControl]="editDisasterForm.controls['notificationTrack']"
              class="browser-default my_select"
            >
              <option value="" disabled>
                {{ 'UNIVERSAL-FORM_CHOOSE_OPTION' | translate }}
              </option>

              <option *ngFor="let track of notificationTracks" [value]="track">
                {{ track }}
              </option>
            </select>

            <div
              class="sr-error-area"
              *ngIf="
                !editDisasterForm.controls['notificationTrack'].valid &&
                editDisasterForm.controls['notificationTrack'].touched
              "
            >
              <div *ngIf="editDisasterForm.controls['notificationTrack'].hasError('required')">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <ul class="collection">
          <li class="collection-item blue-text">
            {{ 'DISASTER_COMMON-TRACK_TALL_DESCRIPTION' | translate }}
          </li>
          <li class="collection-item blue-text">
            {{ 'DISASTER_COMMON-TRACK_GRANDE_DESCRIPTION' | translate }}
          </li>
          <li class="collection-item blue-text">
            {{ 'DISASTER_COMMON-TRACK_VENTI_DESCRIPTION' | translate }}
          </li>
        </ul>
      </div>

      <div class="row margin">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error':
              !editDisasterForm.controls['dateOfDisaster'].valid &&
              (editDisasterForm.controls['dateOfDisaster'].touched || editDisasterForm.controls['dateOfDisaster'].value)
          }"
        >
          <input
            id="dateOfDisaster"
            type="date"
            min="1000-01-01"
            max="3000-01-01"
            [formControl]="editDisasterForm.controls['dateOfDisaster']"
            class="datepicker"
          />
          <label for="dateOfDisaster" class="active">
            {{ 'DISASTER_COMMON-DISASTER_DATE_LABEL' | translate }}
            <span class="sr-required-mark">*</span>
          </label>

          <div
            class="sr-error-area"
            *ngIf="
              !editDisasterForm.controls['dateOfDisaster'].valid &&
              (editDisasterForm.controls['dateOfDisaster'].touched || editDisasterForm.controls['dateOfDisaster'].value)
            "
          >
            <div *ngIf="editDisasterForm.controls['dateOfDisaster'].hasError('required')">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['dateOfDisaster'].hasError('pastDate')">
              {{ 'UNIVERSAL-FORM_ERRORS_PAST_DATE' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['dateOfDisaster'].hasError('dateFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['dateOfDisaster'].hasError('minimumYear')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_YEAR_TOO_LOW' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div class="input-field">
          <div class="col s12 m2 l2 label-text grey-text">
            {{ 'SERVICE_COMMON_TITLE' | translate }}
          </div>

          <div class="col s12 m6 l5">
            <select
              [formControl]="editDisasterForm.controls['servicesProvided']"
              multiple
              size="20"
              id="servicesProvided"
              class="my_select browser-default"
            >
              <option *ngFor="let serviceProvided of servicesProvided" [value]="serviceProvided.serviceId">
                {{ serviceProvided.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div class="input-field col s12">
          <div
            [ngClass]="{ 'chips-initial': chipsInit['searchTerms'].data.length > 0 }"
            id="search_terms_chips_disaster_edit"
            class="chips"
            materialize="material_chip"
            [materializeParams]="[chipsInit['searchTerms']]"
          ></div>

          <label>
            {{ 'DISASTER_COMMON-SEARCH_TERMS_LABEL' | translate }}
          </label>

          <p class="chips-notif blue-text text-darken-2">
            {{ 'UNIVERSAL_INSTRUCTIONS-CHIPS_FIELD' | translate }}
          </p>
        </div>
      </div>

      <!-- displayDss field -->
      <div class="row margin">
        <div class="input-field radio-button-spacing col s12">
          <p class="label-text grey-text">
            {{ 'DISASTER_COMMON-DISPLAY-DSS' | translate }}
          </p>

          <input
            id="displayDss_yes"
            class="with-gap"
            type="radio"
            [value]="true"
            [formControl]="editDisasterForm.controls['displayDss']"
          />
          <label for="displayDss_yes">
            {{ 'UNIVERSAL-YES' | translate }}
          </label>
          <br />

          <input
            id="displayDss_no"
            class="with-gap"
            type="radio"
            [value]="false"
            [formControl]="editDisasterForm.controls['displayDss']"
          />
          <label for="displayDss_no">
            {{ 'UNIVERSAL-NO' | translate }}
          </label>
        </div>
      </div>

      <div class="row margin">
        <div class="input-field radio-button-spacing col s12">
          <p class="label-text grey-text">
            {{ 'DISASTER_COMMON-GLOBAL-DISASTER' | translate }}
          </p>

          <input
            id="global_yes"
            name="global"
            class="with-gap"
            type="radio"
            [value]="true"
            [formControl]="editDisasterForm.controls['global']"
          />
          <label for="global_yes">
            {{ 'UNIVERSAL-YES' | translate }}
          </label>
          <br />

          <input
            id="global_no"
            name="global"
            class="with-gap"
            type="radio"
            [value]="false"
            [formControl]="editDisasterForm.controls['global']"
          />
          <label for="global_no">
            {{ 'UNIVERSAL-NO' | translate }}
          </label>
        </div>
      </div>

      <!-- Country Multi Select Accordion Table -->
      <location-map-list
        [countryMapLocations]="countryMapLocations"
        [editDisasterForm]="this.editDisasterForm"
        (editDisasterFormEvent)="setCountryList($event)"
      ></location-map-list>
      <div class="row">
        <div class="right my_btns">
          <button
            (click)="onSubmit()"
            [disabled]="isSubmitting || !editDisasterForm.valid"
            type="submit"
            class="btn cyan"
            [ngClass]="{ disabled: isSubmitting || !editDisasterForm.valid }"
          >
            {{ 'UNIVERSAL-NAVIGATION_SAVE' | translate }}
          </button>

          <a class="btn cyan" (click)="onCancel()">
            {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
