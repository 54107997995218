import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterializeModule } from 'angular2-materialize';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NewsLetterSubscriptionComponent } from './newsletter-subscribe.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: NewsLetterSubscriptionComponent }])],
  exports: [RouterModule],
})
export class NewsLetterSubscribeRoutingModule {}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterializeModule,
    ReactiveFormsModule,
    FormsModule,
    NewsLetterSubscribeRoutingModule,
  ],
  declarations: [NewsLetterSubscriptionComponent],
  providers: [],
})
export class NewsLetterSubscribeModule {}
