import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MaterializeAction } from 'angular2-materialize';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, Subscription, Subject } from 'rxjs';
import { zip, takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { AppSettings } from '../../../../common/config';
import { handleErrors } from '../../../../common/error';
import { AuthenticationService } from '../../../../login/login.service';
import {
  Country,
  DisasterSpecificSurveyInput,
  SurveyErrorCode,
  SurveyModalActions,
  UserType,
} from '../../../../models';
import { CountryService } from '../../country/country.service';
import { DashboardRedirectService } from '../../shared/dashboard-redirect.service';
import { LocationSpecificTableService } from '../locationSpecificTable/location-specific-table.service';
import { errSort } from '../shared/survey-error-helpers';
import { SurveyComponent } from '../shared/survey/survey.component';
import { getFloatFromInput, REPORT_SUBMISSION_FREQUENCY } from '../survey-helpers';
import { DSSSurveyInputInterface } from '../survey-input-interface.model';
import { DisasterSpecificSurveyManager } from './disaster-specific-survey-manager';
import { DisasterSpecificSurveyService } from './disaster-specific-survey.service';

const MAP_STEP_INDEX = 6;

@Component({
  selector: 'disaster-specific-survey',
  templateUrl: './disaster-specific-survey.component.html',
  styleUrls: ['./disaster-specific-survey.component.css', '../survey-table-common.css'],
  providers: [],
})
export class DisasterSpecificSurveyComponent extends SurveyComponent implements OnInit, OnDestroy {
  countries: Country[];
  countryNamesCommaSeparated: String[];
  countryLocations;
  formErrors: string[] = [];

  @Input()
  set surveyInterface(value) {
    this.dSSSurveyInterface.next(value);
  }

  get surveyInterface() {
    return this.dSSSurveyInterface.getValue();
  }

  @Output()
  closeDSS: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  allowClosing: boolean;

  triggerMapResize: boolean = false;

  surveyDataInput: DisasterSpecificSurveyInput = null;
  dSSSurveyInterface = new BehaviorSubject<DSSSurveyInputInterface>(null);

  modalActions: SurveyModalActions;

  isSubmitting: boolean = false;
  currentStepIndex: number = 0;
  prevStepIndex: number = 0;
  readonly TOTAL_STEPS: number = 7;
  isValid: boolean = false;
  previousSurveyId: number = null;
  organizationRespondingToDisaster: boolean;
  noErrorResponse: boolean = true;
  errStepDetails: SurveyErrorCode[] = [];

  AppSettings = AppSettings;
  UserType = UserType;
  reportFrequencyTypes = REPORT_SUBMISSION_FREQUENCY;

  progressBarsubscription: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** the form **/
  private dataToSubmit;

  organizationDisasterLocations: any;
  /** ref to dataToSubmit **/
  private refToDataToSubmit;
  private lengthOfLocListInRefToDataToSubmit;

  constructor(
    private fb: FormBuilder,
    protected translateService: TranslateService,
    protected notificationsService: NotificationsService,
    private surveyManager: DisasterSpecificSurveyManager,
    private surveyService: DisasterSpecificSurveyService,
    private locationSurveyService: LocationSpecificTableService,
    private dashRedirectService: DashboardRedirectService,
    public authService: AuthenticationService,
    private countryService: CountryService
  ) {
    super(translateService, notificationsService);
    this.surveyManager.mainForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      // console.log('Form Updated at DSS level!', event);
      this.dataToSubmit = event;

      if (this.surveyManager.mainForm.invalid) {
        this.getFormErrors();
      }
    });

    this.surveyManager.mainForm.controls['organizationRespondingToDisaster'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.organizationRespondingToDisaster = !event.value;
      });
  }

  ngOnInit() {
    this.dSSSurveyInterface.subscribe((x) => {
      this.resetModalActions();
      this.refToDataToSubmit = null;
      this.lengthOfLocListInRefToDataToSubmit = 0;
      this.surveyManager.mainForm.reset();
      this.getSurveyData();

      this.modalActions.steps[MAP_STEP_INDEX].subscribe((event) => {
        if (event.params[0] === 'open') {
          this.triggerMapResize = true;
        }
      });
    });
    const countryObservable = this.countryService.getCountries();
    countryObservable.subscribe((success) => {
      this.countries = success;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getSurveyData(): void {
    // nullify if there is data, so that loading indicator is shown
    this.surveyDataInput = null;

    // Get the survey data
    let surveyObservable: Observable<DisasterSpecificSurveyInput>;

    const locationSurveysObservable = this.locationSurveyService.getLocationsAndContacts(
      this.authService.getProfile().organizationId
    );
    this.countryNamesCommaSeparated =
      this.surveyInterface.countryNamesCommaSeparated && this.surveyInterface.countryNamesCommaSeparated.split(',');

    if (this.surveyInterface.surveyId) {
      surveyObservable = this.surveyService.getSurveyById(this.surveyInterface.surveyId);
    } else if (this.surveyInterface.organizationId && this.surveyInterface.disasterId) {
      surveyObservable = this.surveyService.getMostRecentSurveyForEdit(
        this.surveyInterface.organizationId,
        this.surveyInterface.disasterId
      );
    } else {
      console.log('Bad survey input!', this.surveyInterface);
      return;
    }

    surveyObservable
      .pipe(
        this.dashRedirectService.redirectUnauthorized([400]),
        zip(locationSurveysObservable, (data: any, locationSurveys: any) => ({
          data,
          locationSurveys,
        })),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (success) => {
          // Fresh surveys should default presume that org is responding
          if (!success.data.surveySubmissionId) {
            success.data.organizationRespondingToDisaster = true;
          }

          this.surveyDataInput = success.data;
          this.organizationDisasterLocations = this.getOrganizationDisasterLocationsList(success);
          this.previousSurveyId = success.data.surveySubmissionId;
          this.organizationRespondingToDisaster = success.data.organizationRespondingToDisaster === true;
          this.isValid = this.surveyManager.mainForm.valid;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationsService, 'UNIVERSAL-SURVEY_DSS');
          this.noErrorResponse = false;
        }
      );
  }

  getOrganizationDisasterLocationsList(data) {
    data.locationSurveys.forEach((survey) => {
      if (survey.user1) {
        if (survey.user1.userId === this.authService.getProfile().userId) {
          survey.ownSurvey = true;
        } else {
          survey.ownSurvey = false;
        }
      }

      if (survey.user2) {
        if (survey.user2.userId === this.authService.getProfile().userId) {
          survey.ownSurvey = true;
        } else {
          survey.ownSurvey = false;
        }
      }
    });

    const locations = data.locationSurveys.filter(
      (survey) => this.authService.isOrgGeneral() || (this.authService.isLocation() && survey.ownSurvey)
    );
    const disasterLocations = data.data.disaster.locations;
    const result = [];
    for (const location of disasterLocations) {
      const loc = locations.find((userLocations) => userLocations.countryName === location.country.name);
      if (loc) {
        result.push(location);
      }
    }
    if (result.length > 0) {
      return result;
    }
    this.countryLocations = [];
    this.countryNamesCommaSeparated.forEach((countryName: string) => {
      const country = this.countries.find((country) => country.name === countryName.trim());
      if (!(country == null || country === undefined)) {
        this.countryLocations.push({
          country,
        });
      }
    });
    return this.countryLocations;
  }

  canSaveAndExit(): boolean {
    return true;
  }

  convertToUSDAmounts(): void {
    const dataToSubmit = this.dataToSubmit;
    const forms = ['raisedFund', 'spentFund', 'earnedInterest'];

    forms.forEach((name) => {
      const theForm = dataToSubmit[name];
      theForm.value.fund = getFloatFromInput(theForm.value.fund);
      theForm.value.hasValue = theForm.value.fund !== undefined && theForm.value.fund !== null;
    });

    const fundTransferArr = dataToSubmit.transferredFund.value.fundTransferList;
    if (fundTransferArr) {
      for (const eachTransfer of fundTransferArr) {
        eachTransfer.fund = getFloatFromInput(eachTransfer.fund);
      }
    }
  }

  private resetModalActions(): void {
    this.modalActions = {
      steps: [
        new EventEmitter<string | MaterializeAction>(),
        new EventEmitter<string | MaterializeAction>(),
        new EventEmitter<string | MaterializeAction>(),
        new EventEmitter<string | MaterializeAction>(),
        new EventEmitter<string | MaterializeAction>(),
        new EventEmitter<string | MaterializeAction>(),
        new EventEmitter<string | MaterializeAction>(),
      ],
      error: new EventEmitter<string | MaterializeAction>(),
      processing: false,
    };
  }

  onSubmit(): void {
    if (this.isSubmitting) {
      return;
    }

    // Assign previous survey Id
    this.dataToSubmit['previousSurveyId'] = this.previousSurveyId;
    this.dataToSubmit['organizationRespondingToDisaster'] = !this.dataToSubmit.organizationRespondingToDisaster.value;

    for (const formStep in this.dataToSubmit) {
      if (this.dataToSubmit[formStep] !== null && typeof this.dataToSubmit[formStep].value === 'object') {
        // Handle nested object responses
        for (const formField in this.dataToSubmit[formStep].value) {
          // Values that are strings have their whitespaces removed and checked if they are empty
          if (
            typeof this.dataToSubmit[formStep].value[formField] === 'string' &&
            this.dataToSubmit[formStep].value[formField].replace(/\s/g, '') === ''
          ) {
            // Setting the field value to null makes it a "no response", instead of a blank response
            this.dataToSubmit[formStep].value[formField] = null;
          }
        }
        if (Array.isArray(this.dataToSubmit[formStep].value)) {
          this.dataToSubmit[formStep].value = this.dataToSubmit[formStep].value.filter(
            (item) => item !== null && item !== undefined
          );
        }
      } else if (
        this.dataToSubmit[formStep] !== null &&
        typeof this.dataToSubmit[formStep].value === 'string' &&
        this.dataToSubmit[formStep].value.replace(/\s/g, '') === ''
      ) {
        // Handle string responses
        this.dataToSubmit[formStep].value = null;
      }
    }

    // Remove white spaces in phone number
    const phone1 = this.dataToSubmit['leadContact'].value.phone1;
    if (phone1 != null && phone1.replace(/\s/g, '').length > 0) {
      this.dataToSubmit['leadContact'].value.phone1 = phone1.replace(/\s/g, '');
    }

    // Convert input to number for USD amounts
    this.convertToUSDAmounts();

    this.dataToSubmit.locationList.value = this.getLocationListToSubmit();

    this.prepareTransferFundDataForSubmission();

    // set null on all sections if org is not responding
    this.emptyInputsOnOrgNotResponding();

    this.surveyService
      .submitSurvey(this.surveyDataInput.organizationId, this.surveyDataInput.disaster.disasterId, this.dataToSubmit)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (success) => {
          this.notificationsService.success(
            this.translateService.instant('UNIVERSAL-SURVEY_DSS'),
            this.translateService.instant('UNIVERSAL-TOAST_SUCCESS_SAVED_SURVEY')
          );

          this.modalActions.steps[this.currentStepIndex].emit({ action: 'modal', params: ['close'] });
          this.triggerMapResize = false;

          this.isSubmitting = false;
          this.currentStepIndex = 0;
          this.resetModalActions();
          this.hideOverlay();

          this.refToDataToSubmit = null;
          this.lengthOfLocListInRefToDataToSubmit = 0;

          this.surveyManager.mainForm.reset();
          this.getSurveyData();
        },
        (error) => {
          if (this.dataToSubmit.transferredFund.value.fundTransferList[0].receivingOrganization === null) {
            error.error.errors[0].errorCode = 'SURVEY_INPUT_MISSING_RECIEVING_ORGANIZATION_VALUE';
            error.error.errors[0].errorMessage = 'RECIEVING_ORGANIZATION_NAME';

            handleErrors(error, this.translateService, this.notificationsService, 'UNIVERSAL-SURVEY_DSS');
          } else {
            handleErrors(error, this.translateService, this.notificationsService, 'UNIVERSAL-SURVEY_DSS');
          }

          this.isSubmitting = false;
        }
      );
  }

  onCloseDSS(): void {
    this.closeDSS.emit(true);
  }

  getLocationListToSubmit() {
    const locations = [];
    const locationList = <FormGroup>this.surveyManager.mainForm.controls['locationList'];
    if (locationList.controls['value'] && (<FormArray>locationList.controls['value']).length) {
      for (const loc of (<FormArray>locationList.controls['value']).value) {
        locations.push({
          geoLatitude: loc.geoLatitude,
          geoLongitude: loc.geoLongitude,
          radius: loc.radius,
        });
      }
    }
    return locations;
  }

  onPrev(): void {
    this.currentStepIndex = this.openPrevModal(this.currentStepIndex, this.prevStepIndex);
  }

  onNext(): void {
    this.currentStepIndex = this.openNextModal(this.currentStepIndex);
    if (this.currentStepIndex === MAP_STEP_INDEX) {
      this.triggerMapResize = true;
    }
  }

  selectStep(stepIndex: number): void {
    if (stepIndex !== undefined && stepIndex !== null) {
      if (isNullOrUndefined(this.refToDataToSubmit)) {
        this.refToDataToSubmit = this.dataToSubmit;
        this.refToDataToSubmit.locationList.value = this.getLocationListToSubmit();
        this.lengthOfLocListInRefToDataToSubmit = this.refToDataToSubmit.locationList.value.length;
      }
      this.currentStepIndex = stepIndex;
      if (stepIndex === MAP_STEP_INDEX) {
        this.triggerMapResize = true;
      }
      this.modalActions.steps[this.currentStepIndex].emit({ action: 'modal', params: ['open'] });
    }
  }

  onCancel(): void {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      if (this.currentStepIndex !== -1) {
        this.modalActions.steps[this.currentStepIndex].emit({ action: 'modal', params: ['close'] });
      } else {
        this.closeErrorModal();
      }
      this.triggerMapResize = false;

      // reset the locationList's 'value' FormArray manually as FormArray length does not reset by form.reset()
      const locationListForm = <FormGroup>this.surveyManager.mainForm.controls['locationList'];
      const length = (<FormArray>locationListForm.controls['value']).length;
      const control = <FormArray>locationListForm.controls['value'];
      if (length !== this.lengthOfLocListInRefToDataToSubmit) {
        if (length > 0) {
          for (let i = length - 1; i >= 0; i = i - 1) {
            control.removeAt(i);
          }
        }
        if (this.lengthOfLocListInRefToDataToSubmit > 0) {
          for (let i = 0; i <= this.lengthOfLocListInRefToDataToSubmit - 1; i = i + 1) {
            control.push(
              new FormGroup({
                geoLatitude: new FormControl(null),
                geoLongitude: new FormControl(null),
                radius: new FormControl(null),
                label: new FormControl(null),
              })
            );
          }
        }
      }
      this.surveyManager.mainForm.reset();
      this.surveyManager.mainForm.patchValue(this.refToDataToSubmit);
      this.hideOverlay();
    }
  }

  showStep(event) {
    this.selectStep(event);
    this.showOverlay();
  }

  // opens the selected modal and scrolls to the selected question
  public onSelectQuestion(error: SurveyErrorCode) {
    const stepNum: number = error.stepNum ? error.stepNum : 1;
    const questionNum: number = error.questionNum ? error.questionNum : 1;
    this.currentStepIndex = stepNum - 1;
    this.closeErrorModal();
    this.scrollToQuestion(stepNum, questionNum);
  }

  getFormErrors() {
    this.formErrors = [];
    for (const controlName in this.surveyManager.mainForm.controls) {
      const control: FormControl = this.surveyManager.mainForm.controls[controlName] as FormControl;
      this.formErrors = this.updateErrors(control, controlName, this.formErrors);
    }
  }

  errorButtonClicked(): void {
    this.modalActions.steps[this.currentStepIndex].emit({ action: 'modal', params: ['close'] });
    this.prevStepIndex = this.currentStepIndex;
    this.currentStepIndex = -1;
    this.modalActions.error.emit({ action: 'modal', params: ['open'] });
    this.errStepDetails = [];

    this.formErrors.forEach((error) => {
      const errDetail: SurveyErrorCode = this.surveyService.getErrorCodeList().find((err) => err.errCode === error);

      if (errDetail && !this.errStepDetails.includes(errDetail)) {
        this.errStepDetails.push(errDetail);
      }
    });
    this.errStepDetails.sort(errSort);
  }

  prepareTransferFundDataForSubmission() {
    if (this.dataToSubmit.transferredFund.value.hasValue) {
      if (this.dataToSubmit.transferredFund.value.fileId) {
        // Remove any data from fund transfer list if file id is specified
        // back end DSS API returns an error otherwise
        this.dataToSubmit.transferredFund.value.fundTransferList = [];
      }
    } else {
      this.dataToSubmit.transferredFund.value.fileId = null;
      this.dataToSubmit.transferredFund.value.fundTransferList = [];
    }
  }

  emptyInputsOnOrgNotResponding() {
    if (!this.dataToSubmit['organizationRespondingToDisaster']) {
      for (const f in this.dataToSubmit) {
        if (f !== 'organizationRespondingToDisaster' && f !== 'previousSurveyId') {
          this.dataToSubmit[f] = null;
        }
      }
    }
  }
}
