import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usaStateName',
  pure: true,
})
export class UsaStateNamePipe implements PipeTransform {
  transform(name: string): string {
    const USA_STATE_NAME_REGEXP = /UNITED STATES:/;
    if (USA_STATE_NAME_REGEXP.test(name)) {
      return name.match(/: [A-Za-z ]+/)[0].slice(2);
    }
    return name;
  }
}
