import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GOBSurveyInputInterface } from '../../surveys/survey-input-interface.model';

@Component({
  selector: 'dashboardCreateOrganization',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css', '../../../common-edit.css'],
  providers: [],
})
export class DashboardOrganizationEditComponent implements OnInit {
  organizationId: number;

  surveyInterface: GOBSurveyInputInterface;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.organizationId = +params['id'];

      this.surveyInterface = {
        organizationId: this.organizationId,
      };
    });
  }
}
