import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { switchMap, map } from 'rxjs/operators';
import { PendingTasksService } from './pendingTasks.service';
import { GOBSurveyInputInterface, LSSSurveyInputInterface } from '../surveys/survey-input-interface.model';
import { OrganizationService } from '../organizations/organization.service';
import { LocationSpecificTableService } from '../surveys/locationSpecificTable/location-specific-table.service';
import { Dictionary, LocationAndContact } from '../../../models';
import { AuthenticationService } from '../../../login/login.service';
import { handleErrors } from '../../../common/error';

declare const $: any;

@Component({
  selector: 'pending-tasks',
  templateUrl: './pendingTasks.component.html',
  styleUrls: ['./pendingTasks.component.css'],
})
export class PendingTasksComponent implements OnInit {
  gOBSurveyInterface: GOBSurveyInputInterface;
  lssSurveyInputInterface: LSSSurveyInputInterface;
  surveys: LocationAndContact[];
  gobPending: boolean = false;
  private organizationName: String;
  constructor(
    private pendingTasksService: PendingTasksService,
    private organizationService: OrganizationService,
    private notificationsService: NotificationsService,
    private locationSurveyService: LocationSpecificTableService,
    public authService: AuthenticationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.loadTasks();
  }

  loadTasks() {
    let locations: LocationAndContact[] = null;
    this.gobPending = false;
    this.surveys = null;
    if (this.authService.isOrgGeneral() || this.authService.isLocation()) {
      // Using organization service to fetch the organization name
      this.organizationService.getOrganization(this.authService.getProfile().organizationId).subscribe((response) => {
        this.organizationName = response.name;
      });
    }
    this.locationSurveyService
      .getLocationsAndContacts(this.authService.getProfile().organizationId)
      .pipe(
        map((success: LocationAndContact[]) => {
          locations = success;
          locations.forEach((survey: LocationAndContact) => {
            if (survey.user1) {
              if (survey.user1.userId === this.authService.getProfile().userId) {
                survey.user1 = this.translateService.instant('SurveyTablesCommon-OWN_SURVEY_NAME');

                survey.ownSurvey = true;
              } else {
                survey.user1 = this.translateService.instant('SurveyTablesCommon-PERSON_NAME', {
                  firstName: survey.user1.firstName,
                  lastName: survey.user1.lastName,
                });
              }
            }

            if (survey.user2) {
              if (survey.user2.userId === this.authService.getProfile().userId) {
                survey.user2 = this.translateService.instant('SurveyTablesCommon-OWN_SURVEY_NAME');

                survey.ownSurvey = true;
              } else {
                survey.user2 = this.translateService.instant('SurveyTablesCommon-PERSON_NAME', {
                  firstName: survey.user2.firstName,
                  lastName: survey.user2.lastName,
                });
              }
            }
          });
          locations = locations.filter(
            (survey) => this.authService.isOrgGeneral() || (this.authService.isLocation() && survey.ownSurvey)
          );
          return locations.map((location: LocationAndContact) => location.countryId);
        }),
        switchMap((countryIds: number[]) => this.pendingTasksService.getPendingSurveys(countryIds))
      )
      .subscribe(
        ([isGobPending, areLssPending]: [boolean, Dictionary<boolean>]) => {
          this.gobPending = isGobPending;
          locations = locations.filter((survey) => survey.countryId && areLssPending[survey.countryId]);
          this.surveys = locations;
        },
        (error) => {
          handleErrors(
            error,
            this.translateService,
            this.notificationsService,
            'LocationSpecificSurveysTableComponent-TITLE'
          );
        }
      );
  }
}
