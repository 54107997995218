import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ResetComponent } from './reset.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { LoaderModule } from '../common/loader/loader.module';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: ResetComponent }]), LoaderModule],
  exports: [RouterModule, LoaderModule],
})
export class ResetRoutingModule {}

@NgModule({
  imports: [
    CommonModule,
    ResetRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule,
    SlimLoadingBarModule,
  ],
  declarations: [ResetComponent],
  providers: [],
})
export class ResetFeatureModule {}
