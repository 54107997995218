<div class="ts-container">
  <iframe
    [src]="trustedUrl"
    width="100%"
    height="315"
    frameborder="0"
    rel="0"
    allow="autoplay"
    allowfullscreen
  ></iframe>
  <div>
    <i class="material-icons share-icon" (click)="copyShareUrlToClipboard()">share</i>
  </div>
</div>
