<ng-template [ngIf]="myDisaster">
  <app-loader [loading]="loading"></app-loader>
  <div class="card">
    <div class="card-content">
      <div class="card-title sr-bold">
        {{ 'UNIVERSAL-VIEW_TEXT' | translate }}
        <span class="sr-italic">{{ myDisaster.name }}</span>
        {{ 'UNIVERSAL-INFORMATION_TEXT' | translate }}
      </div>
      <div class="divider"></div>
      <br />
      <div class="collection">
        <div class="collection-item">
          <div class="row">
            <div class="col s12 m4 l2 img-radius-parent">
              <img src="{{ myDisaster?.logoURI }}" alt="disaster logo" class="col s12 m12 responsive-img img-radius" />
            </div>
            <div class="col s12 m8 l10 disaster-details">
              <div class="row">
                <div class="input-field col s12 m6 l4">
                  <div class="col s6 m6 sr-bold">
                    {{ 'DashboardDisasterViewComponent-DISASTER_TYPE_LABEL' | translate }}
                  </div>
                  <div class="col s6 m6">{{ myDisaster.disasterType }}</div>
                </div>
                <div class="input-field col s12 m6 l4">
                  <div class="col s6 m6 sr-bold">
                    {{ 'DashboardDisasterViewComponent-DISASTER_DATE_LABEL' | translate }}
                  </div>
                  <div class="col s6 m6">
                    {{ myDisaster.dateOfDisaster | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}
                  </div>
                </div>
                <div class="input-field col s12 m6 l4">
                  <div class="col s6 m6 sr-bold">{{ 'DashboardDisasterViewComponent-ADD_DATE_LABEL' | translate }}</div>
                  <div class="col s6 m6">
                    {{ 'UNIVERSAL-COMING_SOON' | translate }}
                    <!-- {{ myDisaster.addedDate | date:AppSettings.DEFAULT_ANGULAR_DATE_FORMAT}} -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12 m6 l4">
                  <div class="col s6 m6 sr-bold">
                    {{ 'DashboardDisasterViewComponent-NOTIFICATION_TRACK_LABEL' | translate }}
                  </div>
                  <div class="col s6 m6">{{ myDisaster.notificationTrack }}</div>
                </div>
                <div class="input-field col s12 m6 l4">
                  <div class="col s6 m6 sr-bold">{{ 'DashboardDisasterViewComponent-PRIORITY_LABEL' | translate }}</div>
                  <div class="col s6 m6">{{ myDisaster.priority }}</div>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12 m12">
                  <div class="sr-bold left col s12 m12">{{ 'DISASTER_COMMON-SEARCH_TERMS_LABEL' | translate }}</div>
                  <div class="col s12 m12">
                    {{ searchTermsValues }}
                  </div>
                </div>
              </div>

              <div class="row">
                <div *ngFor="let disasterCountry of myDisaster.locations" class="input-field col s12 m12">
                  <div class="input-field col s12 m12">
                    <div class="sr-bold">
                      {{ disasterCountry.country.name }}
                    </div>
                  </div>

                  <div class="divider col s12 m12"></div>
                  <div class="input-field col s12 m6 details">
                    <div class="col s6 m6 sr-bold">{{ 'DISASTER_COMMON-RADIUS_LABEL' | translate }}</div>
                    <div class="col s6 m6">
                      {{ disasterCountry.radius }}
                      {{ disasterCountry.radiusUnits }}
                    </div>
                  </div>
                  <div class="input-field col s12 m6 details">
                    <div class="col s6 m6 sr-bold">{{ 'DISASTER_COMMON-LATITUDE_LABEL' | translate }}</div>
                    <div class="col s6 m6">{{ disasterCountry.geoLat }}&deg;</div>
                  </div>
                  <div class="input-field col s12 m6 details">
                    <div class="col s6 m6 sr-bold">{{ 'DISASTER_COMMON-LONGITUDE_LABEL' | translate }}</div>
                    <div class="col s6 m6">{{ disasterCountry.geoLon }}&deg;</div>
                  </div>
                  <div class="input-field col s12 m6 details">
                    <div class="col s6 m6 sr-bold">{{ 'DISASTER_COMMON-ENTIRE_COUNTRY_LABEL' | translate }}</div>
                    <div class="col s6 m6">{{ disasterCountry.entireCountry }}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12 m12">
                  <div class="col s4 m4 sr-bold input-field">{{ 'DISASTER_COMMON-SUMMARY_LABEL' | translate }}</div>
                  <div class="divider col s12 m12"></div>
                  <div class="col s12 m12 sr-justify input-field" [innerHtml]="myDisaster.summary"></div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12 m12">
                  <div class="col s4 m4 sr-bold input-field">{{ 'DISASTER_COMMON-HASHTAG_LABEL' | translate }}</div>
                  <div class="divider col s12 m12"></div>
                  <div class="col s12 m12 sr-justify input-field">
                    {{ myDisaster.disasterHashtag }}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12 m12">
                  <div class="col s4 m4 sr-bold input-field">{{ 'DISASTER_COMMON-METADATA_LABEL' | translate }}</div>
                  <div class="divider col s12 m12"></div>
                  <div class="col s12 m12 sr-justify input-field">
                    {{ myDisaster.metadata }}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12 m12">
                  <div
                    *ngIf="myDisaster.services && myDisaster.services.length > 0"
                    class="col s12 m12 sr-bold input-field"
                  >
                    {{ 'SERVICE_COMMON_TITLE' | translate }}
                  </div>
                  <div class="divider col s12 m12"></div>
                  <div *ngFor="let serviceProvided of servicesProvided" class="input-field col s12 m12">
                    <div class="input-field col s12 m12">
                      <div class="sr-bold">
                        <img
                          *ngIf="
                            (serviceProvided.file && serviceProvided.file.uri) || (serviceProvided.name | serviceIcons)
                          "
                          class="services-provided-icon-xs"
                          src="{{
                            serviceProvided.file && serviceProvided.file.uri
                              ? serviceProvided.file.uri
                              : (serviceProvided.name | serviceIcons)
                          }}"
                        />
                        {{ serviceProvided.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12 m12">
              <div *ngIf="viewDisasterType == 'viewnominated'" class="input-field left">
                <button class="waves-effect waves-light btn cyan" (click)="onApprove('approve')">
                  <i class="material-icons left">done</i>
                  {{ 'UNIVERSAL-APPROVE_TEXT' | translate }}
                </button>
                <button class="waves-effect waves-light btn cyan" (click)="onApprove('reject')">
                  <i class="material-icons left">clear</i>
                  {{ 'UNIVERSAL-REJECT_TEXT' | translate }}
                </button>
              </div>
              <div class="input-field right">
                <a
                  *ngIf="viewDisasterType == 'view'"
                  class="waves-effect waves-light btn cyan"
                  [routerLink]="['/dashboard/disasters/edit', myDisaster.disasterId]"
                >
                  {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
                </a>
                <a
                  *ngIf="viewDisasterType == 'viewnominated'"
                  class="waves-effect waves-light btn cyan"
                  [routerLink]="['/dashboard/disasters/editnominated', myDisaster.disasterId]"
                >
                  {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
                </a>
                <a class="waves-effect waves-light btn cyan" [routerLink]="['..']">
                  <i class="material-icons left">skip_previous</i>
                  {{ 'UNIVERSAL-NAVIGATION_BACK' | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
