<div class="row">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12 my_btns">
    <a [routerLink]="['create']" class="waves-effect waves-light btn cyan" role="button">
      <i class="material-icons left">add</i>
      {{ 'DashboardOrganizationBaseComponent-ADD_ORGANIZATION_BUTTON' | translate }}
    </a>
    <div class="right">
      <a class="waves-effect waves-light btn cyan">{{ 'UNIVERSAL-NAVIGATION_EXPORT' | translate }}</a>
      <a class="waves-effect waves-light btn cyan">{{ 'UNIVERSAL-NAVIGATION_PRINT' | translate }}</a>
    </div>
  </div>
</div>
<div class="row">
  <div class="col s12 m12 sr-filter-row">
    <div>
      <div class="col s3 m3 left valign-wrapper sr-filter-row">
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-SHOW_TEXT' | translate }}</label>
        <select (change)="onItemsPerPageChange($event.target.value)" name="entries" id="entries" class="my_select2">
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
        </select>
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}</label>
      </div>
      <div class="col s4 m2 right valign-wrapper">
        <div class="input-field search-input">
          <input
            [ngModel]="filterArg"
            (ngModelChange)="onFilterChange($event)"
            class="validate"
            id="search"
            type="text"
          />
          <label class="grey-text" for="search">{{ 'UNIVERSAL-SEARCH' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col s12 m12">
  <div class="card">
    <div class="card-content black-text">
      <table class="grey lighten-3 table-wrapper striped sr-custom-table-row fixed-layout-table">
        <thead>
          <tr class="sr-base-table-row">
            <th data-field="logo" class="header-cell logos hide-on-small-only">
              {{ 'Organization_Common-LOGO' | translate }}
            </th>
            <th data-field="name" class="header-cell names sr-link" (click)="setSortedField('name')">
              {{ 'Organization_Common-NAME' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              data-field="city"
              class="header-cell cities hide-on-small-only sr-link"
              (click)="setSortedField('city')"
            >
              {{ 'Organization_Common-CITY' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              data-field="state"
              class="header-cell states hide-on-small-only sr-link"
              (click)="setSortedField('state')"
            >
              {{ 'Organization_Common-STATE' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              data-field="country"
              class="header-cell countries hide-on-small-only sr-link"
              (click)="setSortedField('country')"
            >
              {{ 'Organization_Common-COUNTRY' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              data-field="addedDate"
              class="header-cell dates hide-on-small-only sr-link"
              (click)="setSortedField('addedDate')"
            >
              {{ 'Organization_Common-DATE_ADDED' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th colspan="3" data-field="action" class="header-cell actions action-header-cell">
              {{ 'UNIVERSAL-TABLE_COLUMN_ACTIONS' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let organization of organizations
                | genericSearchFilterPipe: filterArg:['country', 'name', 'city', 'state']
                | genericSortPipe: sortField:sortDescending
                | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage, totalItems: totalItems }
            "
            class="sr-base-table-row"
          >
            <td class="cell-value logos hide-on-small-only">
              <logo-upload
                [logoUri]="organization.logoURI"
                [organizationId]="organization.organizationId"
              ></logo-upload>
            </td>
            <td class="cell-value">{{ organization.name }}</td>
            <td class="cell-value hide-on-small-only">{{ organization.city }}</td>
            <td class="cell-value hide-on-small-only">{{ organization.state }}</td>
            <td class="cell-value hide-on-small-only">{{ organization.country }}</td>
            <td class="cell-value hide-on-small-only">
              {{ organization.addedDate | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}
            </td>
            <td class="cell-action-icon">
              <a
                [routerLink]="['view', organization.organizationId]"
                class="waves-effect waves-light btn btn-floating cyan"
                ><i class="material-icons left">visibility</i></a
              >
            </td>
            <td class="cell-action-icon">
              <a
                [routerLink]="['edit', organization.organizationId]"
                class="waves-effect waves-light btn btn-floating cyan"
                ><i class="material-icons left">mode_edit</i></a
              >
            </td>
            <td class="cell-action-icon">
              <a (click)="onDelete(organization)" class="waves-effect waves-light btn btn-floating cyan"
                ><i class="material-icons left">delete</i></a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col s12 m12">
      <pagination-controls (pageChange)="getPage($event)"></pagination-controls>
    </div>
  </div>
</div>
