<div class="modal-header">
  <survey-step-progress-bar [totalSteps]="7" [currentStepIndex]="7" (stepTabClicked)="onSelectStep($event)">
  </survey-step-progress-bar>
  <h4 class="modal-step-title">
    {{
      'DisasterSpecificSurveyComponent-STEP_7_TITLE'
        | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
    }}
  </h4>
</div>
<div class="modal-content">
  <div class="divider title-divider"></div>
  <div class="row margin">
    <div id="ques71" class="red-text question-count"></div>
    <div class="spacing-div col s12">
      <select
        id="countrySelect"
        [(ngModel)]="currentCountry"
        (change)="setMapCountry()"
        class="browser-default country-select"
      >
        <option *ngFor="let country of countriesToBeShown" [ngValue]="country.country.name">
          {{ country.country.name }}
        </option>
      </select>
      <agm-map
        style="height: 40vh"
        [latitude]="mapLocationLat"
        [longitude]="mapLocationLng"
        (mapClick)="mapClicked($event)"
        [zoom]="6"
      >
        <!--Need i=index in ngFor to pass it in the deleteMarker function below.-->
        <agm-marker
          *ngFor="let formGroup of locationListForm.get('value')['controls']; let i = index"
          [latitude]="formGroup.get('geoLatitude').value"
          [longitude]="formGroup.get('geoLongitude').value"
          [label]="formGroup.get('label').value"
          (markerClick)="markerClicked($event)"
        >
          <agm-info-window [isOpen]="false" [disableAutoPan]="true">
            <label class="sr-bold">{{ 'LocationSpecificSurveyComponent-STEP_7_RADIUS' | translate }}</label>
            <select id="radiusSelect" class="browser-default" [formControl]="formGroup.controls['radius']">
              <option *ngFor="let radius of radii" [ngValue]="radius.value ? radius.value : null">
                {{ radius.display }}
              </option>
            </select>
            <div
              class="sr-error-area"
              *ngIf="!formGroup.controls['radius'].valid && formGroup.controls['radius'].touched"
            >
              <div *ngIf="formGroup.controls['radius'].errors.required">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
            </div>
            <a class="waves-effect waves-light btn" (click)="onMarkerDelete(i)">
              {{ 'LocationSpecificSurveyComponent-STEP_7_REMOVE_PIN' | translate }}
            </a>
          </agm-info-window>
        </agm-marker>
        <agm-circle
          *ngFor="let formGroup of locationListForm.get('value')['controls']"
          [latitude]="formGroup.get('geoLatitude').value"
          [longitude]="formGroup.get('geoLongitude').value"
          [radius]="formGroup.get('radius').value * 1000"
          fillColor="red"
        >
        </agm-circle>
      </agm-map>
    </div>
  </div>
</div>

<div class="modal-footer survey-modal-footer">
  <survey-footer
    [isFirstStep]="false"
    [isLastStep]="true"
    [currentStepIndex]="6"
    [totalSteps]="totalSteps"
    [isValid]="isValid"
    (next)="onNext()"
    (prev)="onPrev()"
    (submit)="onSubmit()"
    (cancel)="onCancel()"
    (error)="onError()"
  >
  </survey-footer>
</div>
