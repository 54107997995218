<div class="contries-controllers">
  <div class="col s12 blue-text text-darken-2">
    <p>{{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_SELECT_LOCATION' | translate }}</p>
    <ol class="container">
      <li>{{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_SELECT_LOCATION_PART_2' | translate }}</li>
      <li>{{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_SELECT_LOCATION_PART_3' | translate }}</li>
    </ol>
  </div>
</div>
<input type="search" placeholder="Search for Country or State" [formControl]="term" />
<div class="countries-list">
  <span class="countries-list__header">{{
    'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_ALL_LOCATIONS' | translate
  }}</span>
  <ul>
    <li *ngFor="let country of filteredCountries" (click)="selectCountry(country)">
      {{ country.name }}
      <i class="material-icons right add-country-icon">add</i>
    </li>
  </ul>
</div>

<div class="red-text" *ngIf="selectedCountryhasNoUser">
  {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_ERROR_COMPLETE_EMPTY_FIELDS' | translate }}
</div>
<div class="row selected-locations-details">
  <div>
    <ul
      materialize="collapsible"
      class="collapsible"
      data-collapsible="expandable"
      [materializeActions]="collapsibleActions"
    >
      <li *ngFor="let location of selectedList">
        <div class="collapsible-header cyan-text" [class.active]="location === addedLocation">
          <div
            [ngSwitch]="
              (gobFormStep6[location.country.abbreviation]?.user1 ||
                gobFormStep6[location.country.abbreviation]?.user2) &&
              gobFormStep6[location.country.abbreviation]?.user1?.userId !==
                gobFormStep6[location.country.abbreviation]?.user2?.userId
            "
          >
            <i *ngSwitchCase="true" class="material-icons">done</i>
            <i *ngSwitchDefault class="material-icons red-text">error</i>
          </div>
          <span>{{ gobFormStep6[location.country.abbreviation].country.name }}</span>
          <i class="material-icons right sr-link col s2 m1 hide-on-closed">keyboard_arrow_down</i>
          <i class="material-icons right sr-link col s2 m1 hide-on-opened">keyboard_arrow_up</i>
          <i
            *ngIf="
              authService.isSuper() ||
              (!gobFormStep6[location.country.abbreviation]?.user1 &&
                !gobFormStep6[location.country.abbreviation]?.user2 &&
                !savedList.includes(location))
            "
            class="material-icons right sr-link col s2 m1"
            (click)="onDeleteCountryOfOperations(gobFormStep6[location.country.abbreviation].country.abbreviation)"
            >delete</i
          >
        </div>

        <!-- Error messages -->
        <div class="col s12 red-text">
          <!-- Error: no contact selected -->
          <p id="ques71" *ngIf="!gobFormStep6[location.country.abbreviation]?.user1">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_ERROR_NO_CONTACT_SELECTED' | translate }}
          </p>
          <!-- Error: both contacts are the same user -->
          <p
            id="ques71"
            *ngIf="
              gobFormStep6[location.country.abbreviation]?.user1 &&
              gobFormStep6[location.country.abbreviation]?.user2 &&
              gobFormStep6[location.country.abbreviation]?.user1?.userId ===
                gobFormStep6[location.country.abbreviation]?.user2?.userId
            "
          >
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_ERROR_IDENTICAL_CONTACTS_SELECTED' | translate }}
          </p>
        </div>

        <div class="collapsible-body">
          <!-- First contact -->
          <div class="cyan-text">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_USER_ONE' | translate }}
          </div>
          <div class="row">
            <div class="input-field col s12">
              <select
                #selectPrimaryContact
                id="select-contacts"
                class="browser-default s12"
                (change)="selectContact($event, location.country.abbreviation, 'user1')"
              >
                <option [ngValue]="null">
                  {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_NO_SELECTED_USER' | translate }}
                </option>
                <option
                  *ngFor="let user of userManagementService.users"
                  [selected]="
                    gobFormStep6[location.country.abbreviation]?.user1 &&
                    user.userId == gobFormStep6[location.country.abbreviation]?.user1.userId
                  "
                  [value]="user.userId"
                >
                  {{ user.firstName }} {{ user.lastName }}, {{ user.email }}
                </option>
              </select>
            </div>
          </div>

          <!-- Second contact -->
          <div class="cyan-text">
            {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_OPTIONAL_USER_TWO' | translate }}
          </div>
          <div class="row">
            <div class="input-field col s12">
              <select
                id="select-contacts"
                class="browser-default s12"
                [disabled]="!gobFormStep6[location.country.abbreviation]?.user1"
                (change)="selectContact($event, location.country.abbreviation, 'user2')"
              >
                <option [ngValue]="null">
                  {{ 'GeneralOrganizationalBackgroundSurveyComponent-STEP_7_NO_SELECTED_USER' | translate }}
                </option>
                <option
                  *ngFor="let user of userManagementService.users"
                  [selected]="
                    gobFormStep6[location.country.abbreviation]?.user2 &&
                    user.userId == gobFormStep6[location.country.abbreviation]?.user2.userId
                  "
                  [value]="user.userId"
                >
                  {{ user.firstName }} {{ user.lastName }} - {{ user.email }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
