import { Component, Input, OnInit } from '@angular/core';

import { AppSettings } from '../../common/config';

@Component({
  selector: 'survey-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.css'],
})
export class SurveyResponseComponent implements OnInit {
  @Input()
  value: any;

  constructor() {}

  ngOnInit() {}
}
