<div *ngIf="hasChangedPassword" class="my_font center my_container">
  {{ 'ResetPasswordComponent-SUCCESS_PASSWORD_CHANGE_MESSAGE' | translate }}.
  <br />
  <a [routerLink]="['/login']">
    {{ 'ResetPasswordComponent-CLICK_HERE_LINK' | translate }}
  </a>
  {{ 'ResetPasswordComponent-CONTINUE_TO_LOGIN' | translate }}.
</div>

<div *ngIf="isTokenValid && !hasChangedPassword">
  <div class="valign-wrapper container">
    <div class="row valign">
      <div class="col s12 card-panel hoverable registration-card card">
        <div class="row">
          <div class="input-field col s12 center">
            <h5 class="center text">
              {{ 'ResetPasswordComponent-CHANGE_PASSWORD_HEADING' | translate }}
            </h5>
          </div>

          <div class="row margin">
            <div
              class="input-field col s12"
              [ngClass]="{
                'sr-has-error':
                  !resetPasswordForm.controls['password'].valid &&
                  (resetPasswordForm.controls['password'].touched || resetPasswordForm.controls['password'].value)
              }"
            >
              <i class="material-icons prefix">lock_open</i>

              <input
                id="password"
                type="password"
                materialize="characterCounter"
                length="45"
                [formControl]="resetPasswordForm.controls['password']"
              />

              <label for="password"> {{ 'ResetPasswordComponent-PASSWORD_LABEL' | translate }}* </label>

              <div
                class="sr-error-area sr-padding-required-text"
                *ngIf="
                  !resetPasswordForm.controls['password'].valid &&
                  (resetPasswordForm.controls['password'].touched || resetPasswordForm.controls['password'].value)
                "
              >
                <div *ngIf="resetPasswordForm.controls['password'].hasError('required')">
                  {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                </div>

                <div *ngIf="resetPasswordForm.controls['password'].hasError('minlength')">
                  {{ 'UNIVERSAL-FORM_ERRORS_MIN_8_CHARACTERS' | translate }}
                </div>

                <div *ngIf="resetPasswordForm.controls['password'].hasError('maxlength')">
                  {{ 'UNIVERSAL-FORM_ERRORS_MAX_45_CHARACTERS' | translate }}
                </div>
              </div>
            </div>

            <div
              class="input-field col s12"
              [ngClass]="{ 'sr-has-error': resetPasswordForm.hasError('passwordMismatch') }"
            >
              <i class="material-icons prefix">lock_open</i>

              <input
                id="passwordConfirmation"
                type="password"
                [formControl]="resetPasswordForm.controls['passwordConfirmation']"
              />

              <label for="passwordConfirmation"> {{ 'UNIVERSAL-CONFIRM_PASSWORD_LABEL' | translate }}* </label>

              <div
                class="sr-error-area sr-padding-required-text"
                *ngIf="resetPasswordForm.hasError('passwordMismatch')"
              >
                {{ 'UNIVERSAL-FORM_ERRORS_PASSWORDS_MUST_MATCH' | translate }}
              </div>
            </div>

            <div class="input-field col s8">
              <button
                (click)="onSubmit()"
                type="submit"
                class="btn waves-effect waves-light col s6 right cyan"
                [disabled]="!resetPasswordForm.valid || isLoading"
              >
                {{ 'ResetPasswordComponent-CHANGE_PASSWORD_BUTTON' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
