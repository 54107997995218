import { Injectable } from '@angular/core';
import { DisasterSpecificSurveyInput, LocationSpecificSurveyInput } from '../../models';

@Injectable()
export class DonationHelperService {
  constructor() {}

  isOrgSolicitingDonationsForDisaster(dss: DisasterSpecificSurveyInput): boolean {
    const isCurrentlySolicitingDonation =
      dss &&
      dss.solicitDonation &&
      dss.solicitDonation.value &&
      dss.solicitDonation.value.isCurrentlySolicitingDonation;
    const isNotSolicitingDonation =
      dss && dss.solicitDonation && dss.solicitDonation.value && dss.solicitDonation.value.isNotSolicitingDonation;
    if (isCurrentlySolicitingDonation === undefined && isNotSolicitingDonation === undefined) {
      // Both values being undefined means no response has been given in the DSS survey Step 4 Question 1
      return false;
    }
    if (isCurrentlySolicitingDonation !== undefined) {
      // This not being undefined means at some point they have answered yes or no, which is enough for us to validate
      return isCurrentlySolicitingDonation;
    }
    if (isNotSolicitingDonation !== undefined) {
      // This means they have checked the checkbox at the top of DSS step 4, question 1. The value being true means
      // they are NOT soliciting donation, so we return the inverse
      return !isNotSolicitingDonation;
    }
  }
}
