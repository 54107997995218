<div class="col s12 divider"></div>

<div class="page-container">
  <div class="center">
    <div class="row hgroup">
      <h1 class="site-title">
        <a href="http://disasteraccountability.org/"
          ><span class="site-title-yellow">Disaster</span> Accountability Project</a
        >
        <img src="assets/images/logo-icons.png" class="site-title-logo" />
      </h1>
    </div>
  </div>
  <div class="row">
    <div class="card">
      <div class="card-content">
        <div class="row">
          <div class="sr-bold med-text">{{ 'Dap_Donation-SUB_TEXT' | translate }}</div>
        </div>
        <div class="row">
          <div class="col m12">
            <label for="method" class="sr-label small-text">{{ 'Dap_Donation-SELECT_TEXT' | translate }}</label>
            <select class="browser-default" (change)="donateMethod($event)">
              <option value="online">{{ 'Dap_Donation-ONLINE_DONATE' | translate }}</option>
              <option value="phone">{{ 'Dap_Donation-PHONE_DONATE' | translate }}</option>
              <option value="check">{{ 'Dap_Donation-CHECK_DONATE' | translate }}</option>
            </select>
          </div>
          <div class="col m12">
            <p class="small-text grey-text">{{ 'Dap_Donation-NOTE_TEXT' | translate }}</p>
          </div>
        </div>

        <div *ngIf="online">
          <givelively-donation></givelively-donation>
        </div>
        <div *ngIf="check" [innerHTML]="'Dap_Donation-CHECK-METHOD-DETAIL' | translate"></div>
        <div *ngIf="phone" [innerHTML]="'Dap_Donation-PHONE-METHOD-DETAIL' | translate"></div>
      </div>
    </div>
  </div>
</div>
