import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NominateSuccessComponent } from './nominate-success.component';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: NominateSuccessComponent }])],
  exports: [RouterModule],
})
export class NominateSuccessRoutingModule {}

@NgModule({
  imports: [CommonModule, NominateSuccessRoutingModule, TranslateModule],
  declarations: [NominateSuccessComponent],
  providers: [],
})
export class NominateSuccessFeatureModule {}
