import { Component, Input, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { Country, DisasterSpecificSurveyInput } from '../../../../../models';
import { CountryService } from '../../../country/country.service';

import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';
import { DSSStepComponent } from '../dss-step/dss-step.component';

@Component({
  selector: 'dss-step2',
  templateUrl: './dss-step2.component.html',
  styleUrls: ['./dss-step2.component.css', '../dss-steps-common.css', '../../survey-table-common.css'],
  providers: [],
})
export class DSSStep2Component extends DSSStepComponent {
  hqAddressForm: FormGroup;
  countries: Country[];

  constructor(
    fb: FormBuilder,
    private surveyManager: DisasterSpecificSurveyManager,
    private countryService: CountryService
  ) {
    super(surveyManager);

    this.hqAddressForm = <FormGroup>this.surveyManager.mainForm.controls['hqAddress'];

    // Get countries
    this.countryService.getCountries().subscribe((data) => {
      this.countries = data;
      this.assignDataToForm();
    });
  }

  assignDataToForm(): void {
    if (!this.surveyData || !this.countries) {
      return;
    }

    if (this.surveyData.hqAddress) {
      this.surveyManager.mainForm.controls['hqAddress'].patchValue(this.surveyData.hqAddress, { emitEvent: false });

      // Set country if it is selected
      if (this.surveyData.hqAddress.value.country) {
        const valueGroup = <FormGroup>this.hqAddressForm.controls['value'];
        const countryControl = <FormControl>valueGroup.controls['country'];
        countryControl.setValue(
          this.countries.find((val) => val.countryId === this.surveyData.hqAddress.value.country.countryId)
        );
      }
    } else {
      this.surveyManager.mainForm.controls['hqAddress'].reset();
    }
  }

  matchesUsaState(event: any): void {
    const inputStateName: string = event.target.value && event.target.value.toLowerCase();
    for (const country of this.countries) {
      const name: string = country.name.slice(15).toLowerCase();
      const abbreviation: string = country.longAbbreviation.slice(2).toLowerCase();
      if (country.name.match(/UNITED STATES:/) && (inputStateName === name || inputStateName === abbreviation)) {
        const valueGroup = <FormGroup>this.hqAddressForm.controls['value'];
        const countryControl = <FormControl>valueGroup.controls['country'];
        countryControl.setValue(country);
        break;
      }
    }
  }
}
