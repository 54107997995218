import { zip } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DisasterAdminService } from '../disaster.service';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Disaster, DisasterCountry } from '../../../../models/Disaster';
import { Country } from '../../../../models/Country';
import { CountryService } from '../../country/country.service';
import { AppSettings } from '../../../../common/config';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { ServicesProvidedService } from '../../service/service.service';
import { Service } from '../../../../models/Service';
import { isNullOrUndefined } from 'util';
import { checkLocationrequiredIfDisasterNotGlobal, getDisasterForm } from '../disaster.component';
import { BlockingLoaderState } from '../../blockingLoader/blocking-loader.model';
import { RadiusOption, radiusOptions } from '../radiusOptions';
import { MapLocation } from '../../../../common/locationMap/location-map.model';
import { LocationMapComponent } from '../../../../common/locationMap/location-map.component';

declare const $: any;

@Component({
  selector: 'dashboardCreateDisaster',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css', '../../../common-edit.css'],
  providers: [],
})
export class DashboardDisasterEditComponent implements OnInit {
  isSubmitting: boolean = false;
  disasterEditType: string;
  disasterTypes: string[];
  notificationTracks: string[];
  disableAddButton: boolean = true;

  countries: Country[];
  servicesProvided: Service[];

  editDisasterForm: FormGroup;
  currentDisaster: Disaster;

  chipsInit: { [key: string]: { data: { tag: string }[] } };

  currentDisasterLogoURI: string;

  blockingLoaderEventEmitter = new EventEmitter<BlockingLoaderState>();

  radii: RadiusOption[] = radiusOptions;

  mapLocations: MapLocation[] = [];

  countryMapLocations: { disasterCountry: DisasterCountry; entireCountry: boolean; mapLocations: MapLocation[] }[] = [];

  loading: boolean;

  params = [
    {
      onOpen: () => {
        this.locationMaps.forEach((map) => map.resize());
      },
    },
  ];

  @ViewChildren(LocationMapComponent) locationMaps: QueryList<LocationMapComponent>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private disasterAdminService: DisasterAdminService,
    private countryService: CountryService,
    private servicesProvidedService: ServicesProvidedService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.loaderService.start();
      this.loading = true;
      this.disasterEditType = this.route.snapshot.url[0].path;
      const disasterId = +params['id'];

      const countryObservable = this.countryService.getCountries();
      const disasterTypesObservable = this.disasterAdminService.getDisasterTypes();
      const disasterObservable =
        this.disasterEditType === 'editnominated'
          ? this.disasterAdminService.getNominatedDisaster(disasterId)
          : this.disasterAdminService.getDisaster(disasterId);
      const servicesProvidedObservable = this.servicesProvidedService.getServices();
      const notificationTracksObservable = this.disasterAdminService.getDisasterNotificationTracks();

      disasterObservable
        .pipe(
          zip(
            disasterTypesObservable,
            countryObservable,
            servicesProvidedObservable,
            notificationTracksObservable,
            (disaster, disasterTypes, countries, servicesProvided, notificationTracks) => ({
              disaster,
              disasterTypes,
              countries,
              servicesProvided,
              notificationTracks,
            })
          )
        )
        .subscribe(
          (success) => {
            this.countries = success.countries;
            this.disasterTypes = success.disasterTypes;
            this.servicesProvided = success.servicesProvided;
            this.notificationTracks = success.notificationTracks;
            this.currentDisaster = success.disaster;
            const countryLocationsMap: { [countryId: number]: DisasterCountry[] } = {};
            this.currentDisaster.locations.map((loc: DisasterCountry) => {
              countryLocationsMap[loc.country.countryId] = countryLocationsMap[loc.country.countryId]
                ? countryLocationsMap[loc.country.countryId]
                : [];
              countryLocationsMap[loc.country.countryId].push(loc);
            });
            const locations = Object.keys(countryLocationsMap).map((countryId) => ({
              country: this.countries.find((country) => country.countryId === parseInt(countryId, 10)),
              entireCountry: countryLocationsMap[countryId][0].entireCountry,
              locations: countryLocationsMap[countryId].map((loc: DisasterCountry) => ({
                country: this.countries.find((val) => val.countryId === loc.country.countryId),
                geoLat: loc.geoLat,
                geoLon: loc.geoLon,
                radius: loc.radius,
                radiusUnits: loc.radiusUnits,
              })),
            }));
            this.editDisasterForm = getDisasterForm(this.fb);
            this.editDisasterForm.patchValue({
              ...this.currentDisaster,
              servicesProvided: this.currentDisaster.services.map((sp) => sp.serviceId),
              countryLocations: [],
              logo: this.currentDisaster.logo ? this.currentDisaster.logo.fileId : null,
            });

            (<FormArray>this.editDisasterForm.controls['countryLocations']).clear();
            locations.forEach((loc) => {
              (<FormArray>this.editDisasterForm.controls['countryLocations']).push(
                this.fb.group({
                  country: [loc['country']],
                  entireCountry: [loc['entireCountry']],
                  locations: this.fb.array([
                    this.fb.group({
                      country: [loc['locations'][0]['country']['name']],
                      geoLat: [loc['locations'][0]['geoLat']],
                      geoLon: [loc['locations'][0]['geoLon']],
                      radius: [loc['locations'][0]['radius']],
                      radiusUnits: [loc['locations'][0]['radiusUnits']],
                    }),
                  ]),
                })
              );
            });

            this.mapLocations = this.currentDisaster.locations.map((loc: any) => {
              return <MapLocation>{
                geoLatitude: loc.geoLat,
                geoLongitude: loc.geoLon,
                radius: loc.radius,
              };
            });
            const countryLocationsMapKeys = Object.keys(countryLocationsMap);
            if (countryLocationsMapKeys.length === 0) {
              this.countryMapLocations = [];
            } else {
              this.countryMapLocations = countryLocationsMapKeys.map((countryId) => ({
                disasterCountry: countryLocationsMap[countryId][0],
                entireCountry: countryLocationsMap[countryId][0]['entireCountry'],
                mapLocations: countryLocationsMap[countryId].map((loc) => ({
                  geoLatitude: loc.geoLat,
                  geoLongitude: loc.geoLon,
                  radius: loc.radius,
                })),
              }));
            }

            this.chipsInit = {
              searchTerms: {
                data: !isNullOrUndefined(this.currentDisaster.searchTerms)
                  ? this.currentDisaster.searchTerms.map((val) => ({ tag: val }))
                  : [],
              },
            };

            this.currentDisasterLogoURI = this.currentDisaster.logo ? this.currentDisaster.logo.uri : null;
            this.loaderService.complete();
            this.loading = false;
          },
          (error) => {
            handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');

            this.loaderService.complete();
            this.loading = false;
          }
        );
    });
  }

  setCountryList(countryList: any) {
    // console.log(countryList);
    this.countryMapLocations = countryList;
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this.loaderService.start();
    this.loading = false;
    const modifiedLocations: DisasterCountry[] =
      this.countryMapLocations.length > 0
        ? this.countryMapLocations
            .map((countryMapLocation) =>
              countryMapLocation.mapLocations.length > 0
                ? countryMapLocation.mapLocations.map(
                    (mapLocation) =>
                      ({
                        country: countryMapLocation.disasterCountry.country,
                        entireCountry: countryMapLocation.entireCountry,
                        geoLat: `${mapLocation.geoLatitude}`,
                        geoLon: `${mapLocation.geoLongitude}`,
                        radius: mapLocation.radius,
                      } as DisasterCountry)
                  )
                : ({
                    country: countryMapLocation.disasterCountry.country,
                    entireCountry: countryMapLocation.entireCountry,
                    geoLat: '',
                    geoLon: '',
                    radius: 0,
                  } as DisasterCountry)
            )
            // @ts-ignore
            .reduce((pre, cur) => pre.concat(cur), [])
            .filter((loc) => !isNullOrUndefined(loc.country) && !isNullOrUndefined(loc.country.countryId))
        : [];

    const updatedDisaster: Disaster = this.editDisasterForm.value;
    updatedDisaster['disasterId'] = this.currentDisaster.disasterId;
    updatedDisaster['locations'] = modifiedLocations;
    updatedDisaster['searchTerms'] = $('#search_terms_chips_disaster_edit')
      .material_chip('data')
      .map((chip) => chip['tag']);
    this.currentDisaster.createDate != null ? (updatedDisaster['createDate'] = this.currentDisaster.createDate) : null;
    updatedDisaster['services'] = !isNullOrUndefined(this.editDisasterForm.value.servicesProvided)
      ? this.editDisasterForm.value.servicesProvided.map((sp) => ({ serviceId: sp }))
      : [];
    updatedDisaster['logo'] = { fileId: this.editDisasterForm.value.logo ? this.editDisasterForm.value.logo : null };
    delete updatedDisaster['countryLocations'];

    if (this.disasterEditType === 'editnominated') {
      this.disasterAdminService.updateNominatedDisaster(updatedDisaster).subscribe(
        (response) => {
          this.successEdit(updatedDisaster);
        },
        (error) => {
          this.errorEdit(error);
        }
      );
    } else {
      this.disasterAdminService.updateDisaster(updatedDisaster).subscribe(
        (response) => {
          this.successEdit(updatedDisaster);
        },
        (error) => {
          this.errorEdit(error);
        }
      );
    }
  }

  successEdit(updatedDisaster: Disaster): void {
    this.isSubmitting = false;
    this.loaderService.complete();
    this.loading = false;

    this.notificationService.success(
      this.translateService.instant('DISASTER_COMMON-TITLE'),
      this.translateService.instant('DashboardDisasterEditComponent-SUCCESS_EDIT_MESSAGE', {
        disasterName: updatedDisaster.name,
      })
    );

    this.router.navigate(['dashboard/disasters']);
  }

  errorEdit(error: any) {
    this.isSubmitting = false;
    this.loaderService.complete();
    this.loading = false;

    handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');
  }

  onCancel(): void {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      this.router.navigate(['dashboard/disasters']);
    }
  }

  onFileSelected(event: any): void {
    const targetFile = event.target.files[0];

    if (!(targetFile instanceof File)) {
      return;
    }

    if (targetFile.size > AppSettings.MAXIMUM_IMAGE_UPLOAD_SIZE) {
      this.notificationService.error(
        this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
        this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    if (AppSettings.DEFAULT_ALLOWED_IMAGE_TYPES.indexOf(targetFile.type) === -1) {
      this.notificationService.error(
        this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
        this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    this.blockingLoaderEventEmitter.emit({
      display: true,
      translation_key: 'UNIVERSAL-LOADING_MESSAGE',
    });

    this.disasterAdminService.uploadDisasterLogo(targetFile).subscribe(
      (success) => {
        this.blockingLoaderEventEmitter.emit({
          display: false,
        });

        this.currentDisasterLogoURI = `${success.uri}`;

        this.editDisasterForm.controls['logo'].setValue(success.fileId);

        this.notificationService.success(
          this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
          this.translateService.instant('UNIVERSAL-SUCCESSFUL_UPLOAD')
        );
      },
      (error) => {
        this.blockingLoaderEventEmitter.emit({
          display: false,
        });

        handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');
      }
    );
  }
}
