import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LocationSpecificTableService } from './location-specific-table.service';
import { AuthenticationService } from '../../../../login/login.service';
import { LocationAndContact } from '../../../../models/LocationAndContact';
import { handleErrors } from '../../../../common/error';
import { AppSettings } from '../../../../common/config';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { LSSSurveyInputInterface } from '../survey-input-interface.model';

@Component({
  selector: 'location-specific-surveys-table',
  providers: [],
  templateUrl: './location-specific-surveys.component.html',
  styleUrls: ['../survey-table-common.css', './location-specific-surveys.component.css'],
})
export class LocationSpecificSurveysTableComponent {
  surveys: LocationAndContact[];

  @Output()
  selectedCountry: EventEmitter<LSSSurveyInputInterface> = new EventEmitter<LSSSurveyInputInterface>();

  filterArg: string;

  itemsPerPageArg: number = AppSettings.LSS_LIST_ITEMS_PER_PAGE;

  selectedPage: number = 0;

  AppSettings = AppSettings;

  constructor(
    public router: Router,
    private notificationsService: NotificationsService,
    private locationSurveyService: LocationSpecificTableService,
    public authService: AuthenticationService,
    private translateService: TranslateService
  ) {}

  loadSurveys() {
    this.surveys = null;

    this.locationSurveyService.getLocationsAndContacts(this.authService.getProfile().organizationId).subscribe(
      (success) => {
        this.surveys = success;

        this.surveys.forEach((survey: LocationAndContact) => {
          if (survey.user1) {
            if (survey.user1.userId === this.authService.getProfile().userId) {
              survey.user1 = this.translateService.instant('SurveyTablesCommon-OWN_SURVEY_NAME');

              survey.ownSurvey = true;
            } else {
              survey.user1 = this.translateService.instant('SurveyTablesCommon-PERSON_NAME', {
                firstName: survey.user1.firstName,
                lastName: survey.user1.lastName,
              });
            }
          }

          if (survey.user2) {
            if (survey.user2.userId === this.authService.getProfile().userId) {
              survey.user2 = this.translateService.instant('SurveyTablesCommon-OWN_SURVEY_NAME');

              survey.ownSurvey = true;
            } else {
              survey.user2 = this.translateService.instant('SurveyTablesCommon-PERSON_NAME', {
                firstName: survey.user2.firstName,
                lastName: survey.user2.lastName,
              });
            }
          }
        });

        this.surveys = this.surveys.filter(
          (survey) => this.authService.isOrgGeneral() || (this.authService.isLocation() && survey.ownSurvey)
        );
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'LocationSpecificSurveysTableComponent-TITLE'
        );
      }
    );
  }

  ngOnInit() {
    this.loadSurveys();
  }

  onCountrySelect(selected: LocationAndContact): void {
    // when user clicks on same country twice, hide LSS display
    this.selectedCountry.emit(selected);
  }
}
