import { AfterContentChecked, AfterContentInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Country, DisasterCountry } from '../../../../models';
import { MapLocation } from '../../../../common/locationMap/location-map.model';
import { CountryService } from '../../country/country.service';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { MatDialog } from '@angular/material/dialog';
import {
  EntireCountryConfirmDialogComponent,
  RemoveEntireCountryDialogResponse,
} from './entire-country-confirm-dialog/entire-country-confirm-dialog.component';

@Component({
  selector: 'location-map-list',
  templateUrl: './locationMapList.component.html',
  styleUrls: ['./locationMapList.component.css'],
})
export class LocationMapListComponent implements OnInit, AfterContentChecked {
  constructor(
    private countryService: CountryService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.countryService.getCountries().subscribe(
      (countries: Country[]) => {
        this.countries = countries;
        this.loading = false;
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');

        this.loaderService.complete();
        this.loading = false;
      }
    );
  }

  loading: boolean = false;
  @Input()
  countryMapLocations: { disasterCountry: DisasterCountry; entireCountry: boolean; mapLocations: MapLocation[] }[] = [
    {
      disasterCountry: {},
      entireCountry: false,
      mapLocations: [],
    },
  ];
  @Input()
  editDisasterForm: FormGroup;
  @Output()
  editDisasterFormEvent: EventEmitter<any> = new EventEmitter<any>();
  disableAddButton: boolean = true;

  step: number = 0;

  setStep(index: number) {
    this.step = index;
  }

  countries: Country[];

  onDeleteCountry(index: number) {
    // can't delete last item
    this.disableAddButton = false;
    if ((<FormArray>this.editDisasterForm.controls['countryLocations']).length === 1) {
      return;
    }

    (<FormArray>this.editDisasterForm.controls['countryLocations']).removeAt(index);
    this.countryMapLocations.splice(index, 1);
  }

  onChange(index: number) {
    const country: Country = this.editDisasterForm.controls['countryLocations'].value[index].country;
    this.countryMapLocations[index].disasterCountry = {
      country,
    };
    if (this.countryMapLocations[index].mapLocations.length > 0 && !this.countryMapLocations[index].entireCountry) {
      const dialogRef = this.dialog.open(EntireCountryConfirmDialogComponent, {
        data: {
          i: index,
          country: this.countryMapLocations[index].disasterCountry.country.name,
        },
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((dialogResponse: RemoveEntireCountryDialogResponse) => {
        if (dialogResponse.removePinnedLocations) {
          this.countryMapLocations[dialogResponse.countryIndex].mapLocations = [];
          this.countryMapLocations[index].entireCountry = true;
        } else {
          this.editDisasterForm.controls['countryLocations']['controls'][dialogResponse.countryIndex]['controls'][
            'entireCountry'
          ].setValue(false);
          this.countryMapLocations[index].entireCountry = false;
        }
      });
    }
    this.countryMapLocations[index].entireCountry = this.editDisasterForm.controls['countryLocations'].value[
      index
    ].entireCountry;
    this.disableAddButton = false;
  }

  excludeCountryLocation(form: FormGroup, country: Country): boolean {
    return (<FormArray>this.editDisasterForm.controls['countryLocations']).controls
      .filter((group) => group !== form)
      .map((group) => group.value.country)
      .map((value) => value && value.countryId === country.countryId)
      .reduce((left, right) => left || right, false);
  }

  setCoordinates(newLocation: MapLocation, index: number): void {}

  updateRadius(newLocation: MapLocation, index: number): void {}

  unsetCoordinates(index: number): void {}

  onAddCountry() {
    this.disableAddButton = true;
    (<FormArray>this.editDisasterForm.controls['countryLocations']).push(
      this.fb.group({
        country: [''],
        entireCountry: [false],
        geoLat: [''],
        geoLon: [''],
        radius: [''],
      })
    );
    this.countryMapLocations.push({ disasterCountry: {}, entireCountry: false, mapLocations: [] });
    this.setStep(this.editDisasterForm.controls['countryLocations']['controls'].length - 1);
  }

  ngAfterContentChecked(): void {
    this.editDisasterFormEvent.emit(this.countryMapLocations);
  }
}
