<div id="content">
  <div class="center main-img-wrapper">
    <carousel [slides]="slides"></carousel>
  </div>
  <!--//Container-->
  <div class="container">
    <div class="row">
      <search class="col s12"></search>
    </div>
    <div class="row">
      <disaster-list-home class="col s12"></disaster-list-home>
    </div>
    <div class="row">
      <marketing-message class="col s12"></marketing-message>
    </div>
  </div>
</div>
