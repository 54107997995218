import { Component } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';

import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';
import { DSSStepComponent } from '../dss-step/dss-step.component';

@Component({
  selector: 'dss-step3',
  templateUrl: './dss-step3.component.html',
  styleUrls: ['./dss-step3.component.css', '../dss-steps-common.css', '../../survey-table-common.css'],
  providers: [],
})
export class DSSStep3Component extends DSSStepComponent {
  leadContactForm: FormGroup;

  constructor(fb: FormBuilder, private surveyManager: DisasterSpecificSurveyManager) {
    super(surveyManager);

    this.invalidOnInit = {
      singles: {
        email: false,
      },
    };
    this.leadContactForm = <FormGroup>this.surveyManager.mainForm.controls['leadContact'];
  }

  assignDataToForm(): void {
    if (!this.surveyData) {
      return;
    }

    if (this.surveyData.leadContact) {
      this.surveyManager.mainForm.controls['leadContact'].patchValue(this.surveyData.leadContact, { emitEvent: false });
    } else {
      this.surveyManager.mainForm.controls['leadContact'].reset();
    }
    this.setInitialErrorVisibility();
  }

  private setInitialErrorVisibility(): void {
    if (this.surveyData.leadContact) {
      this.invalidOnInit.singles.email = !this.leadContactForm.get('value').get('email').valid;
    }
  }
}
