<div class="mapHeader">
  <h4>{{ 'Global-mapComponent-TITLE' | translate }}</h4>
  <p>{{ 'Global-mapComponent-DESC' | translate }}</p>
  <p><b>Note: </b>{{ 'Global-mapComponent-NOTE1' | translate }}</p>
  <p>{{ 'Global-mapComponent-NOTE2' | translate }}</p>

  <div *ngIf="error">
    <b>{{ 'Global-mapComponent-LOCATION_LOAD_ERROR' | translate }}</b>
  </div>

  <app-loader [loading]="loading"></app-loader>
</div>
<div class="mapContainer" *ngIf="!error">
  <agm-map
    [zoom]="zoom"
    [latitude]="baseLatitude"
    [longitude]="baseLongitude"
    [style.height.px]="height"
    (mapClick)="mapClick($event)"
  >
    <div *ngFor="let location of locations">
      <agm-marker [latitude]="location.latitude" [longitude]="location.longitude" (markerClick)="markerClick(iw)">
        <agm-info-window #iw>
          <a href="//{{ location?.URL }}" target="_blank">{{ location?.name }}</a>
        </agm-info-window>
      </agm-marker>
    </div>
  </agm-map>
</div>
