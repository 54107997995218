<div class="card">
  <div class="card-content black-text no-padding">
    <div class="row grey lighten-3">
      <div class="col left valign refresh">
        <a class="sr-link" (click)="loadSurveys()">
          <i class="material-icons loop black-text">loop</i>
        </a>
      </div>
    </div>
    <div class="row grey lighten-3">
      <div class="col s12 m8 l9">
        <ul>
          <li>• {{ 'LocationSpecificSurveysTableComponent-INSTRUCTIONS-1' | translate }}</li>

          <li>• {{ 'LocationSpecificSurveysTableComponent-INSTRUCTIONS-3' | translate }}</li>
        </ul>
      </div>
      <div class="col s12 m5 l3 right valign-wrapper">
        <div class="input-field search-input-field">
          <input
            [ngModel]="filterArg"
            (ngModelChange)="selectedPage = 0; filterArg = $event"
            class="validate"
            id="lss-search"
            type="text"
          />
          <label class="grey-text" for="lss-search">{{ 'UNIVERSAL-SEARCH' | translate }}</label>
        </div>
      </div>
    </div>

    <div *ngIf="surveys; else showLoader">
      <table class="grey lighten-3 table-wrapper striped">
        <thead>
          <tr>
            <th></th>
            <th>
              {{ 'SurveyTablesCommon-HEADER_LOCATION' | translate }}
            </th>
            <th class="hide-on-small-only">
              {{ 'SurveyTablesCommon-HEADER_LOCATION_CONTACTS' | translate }}
            </th>
            <th class="hide">
              {{ 'SurveyTablesCommon-HEADER_LAST_UPDATED' | translate }}
            </th>
            <th class="hide">
              {{ 'SurveyTablesCommon-HEADER_NEXT_UPDATE' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr [hidden]="surveys.length > 0">
            <td colspan="5">{{ 'LocationSpecificSurveysTableComponent-INSTRUCTIONS-2' | translate }}</td>
          </tr>
          <tr
            *ngFor="
              let lssEntry of surveys
                | genericSearchFilterPipe: filterArg:['countryName']
                | paginate: { id: 'lssPagination', itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
            "
          >
            <td>
              <i *ngIf="lssEntry.ownSurvey" class="material-icons"> lock_open </i>
              <i *ngIf="!lssEntry.ownSurvey" class="material-icons"> lock_outline </i>
            </td>
            <td>
              <a class="sr-link" (click)="onCountrySelect(lssEntry)">
                {{ lssEntry.countryName }}
              </a>
            </td>
            <td class="hide-on-small-only">
              <span *ngIf="lssEntry.user1">
                {{ lssEntry.user1 }}
              </span>
              <span *ngIf="lssEntry.user2">
                <br />
                {{ lssEntry.user2 }}
              </span>
            </td>
            <td class="hide">
              {{ 'UNIVERSAL-COMING_SOON' | translate }}
              <!--{{lssEntry.lastUpdated | date:AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT}}-->
            </td>
            <td class="hide">
              {{ 'UNIVERSAL-COMING_SOON' | translate }}
              <!--{{lssEntry.nextUpdateDue | date:AppSettings.DEFAULT_ANGULAR_DATE_FORMAT}}-->
            </td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        id="lssPagination"
        class="pagination"
        (pageChange)="selectedPage = $event"
      ></pagination-controls>
    </div>

    <ng-template #showLoader>
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </ng-template>
  </div>
</div>
