import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'app/common/config';
import { SurveyNotification } from 'app/models/SurveyNotification';
import { AuthenticationService } from 'app/login/login.service';
import { contentHeaders } from 'app/common/headers';
import { OutdatedSurvey } from 'app/models/OutdatedSurvey';
import { NewSurvey } from 'app/models/NewSurvey';
import { TranslateService } from '@ngx-translate/core';

const ENDPOINT_URL_NEW_SURVEYS = AppSettings.API_SERVER + '/smart-response/survey/new/list';
const ENDPOINT_URL_OUTDATED_SURVEYS = AppSettings.API_SERVER + '/smart-response/survey/outdated/list';

@Injectable()
export class SurveyNotificationsService {
  notificationParams: SurveyNotification = {};
  surveyNotificationsNew: SurveyNotification[] = [];
  surveyNotificationsOutdated: SurveyNotification[] = [];

  constructor(
    private http: HttpClient,
    public authService: AuthenticationService,
    private translateService: TranslateService
  ) {}

  getNewSurveys(): Observable<SurveyNotification[]> {
    if (!(this.authService.isLocation() || this.authService.isOrgGeneral())) return;
    return this.http
      .get<NewSurvey>(ENDPOINT_URL_NEW_SURVEYS, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(
        map((res) => {
          for (const key in res.responseData.survey_list) {
            if (res.responseData.survey_list[key].surveyType === 'LSS') {
              this.notificationParams.surveyName = res.responseData.survey_list[key].countryDto.name;
              this.notificationParams.surveyTypeFull = this.translateService.instant(
                'SurveyNotificationsComponent_LOCATION_SPECIFIC'
              );
              this.notificationParams.countryId = res.responseData.survey_list[key].countryDto.countryId;
            } else if (res.responseData.survey_list[key].surveyType === 'DSS') {
              this.notificationParams.surveyName = res.responseData.survey_list[key].disasterDto.name;
              this.notificationParams.surveyTypeFull = this.translateService.instant(
                'SurveyNotificationsComponent_DISASTER_SPECIFIC'
              );
              this.notificationParams.disasterId = res.responseData.survey_list[key].disasterDto.disasterId;
            } else {
              this.notificationParams.surveyName = res.responseData.survey_list[key].organizationDto.name;
              this.notificationParams.surveyTypeFull = this.translateService.instant(
                'SurveyNotificationsComponent_GENERAL'
              );
            }
            this.surveyNotificationsNew.push({
              surveyName: this.notificationParams.surveyName,
              surveyType: res.responseData.survey_list[key].surveyType,
              surveyTypeFull: this.notificationParams.surveyTypeFull,
              organizationId: res.responseData.survey_list[key].organizationDto.organizationId,
              countryId: this.notificationParams.countryId,
              disasterId: this.notificationParams.disasterId,
              new: res.responseData.survey_list[key].new,
              status: this.translateService.instant('SurveyNotificationsComponent_TO_BE_COMPLETED'),
            });
          }
          return this.surveyNotificationsNew;
        })
      );
  }

  getOutdatedSurveys(): Observable<SurveyNotification[]> {
    if (!(this.authService.isLocation() || this.authService.isOrgGeneral())) return;
    return this.http
      .get<OutdatedSurvey>(ENDPOINT_URL_OUTDATED_SURVEYS, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(
        map((res) => {
          for (const survey in res.responseData.survey_list) {
            this.notificationParams.organizationName = res.responseData.survey_list[survey].organizationName;
            this.notificationParams.organizationId = res.responseData.survey_list[survey].organizationId;
            this.notificationParams.surveyType = res.responseData.survey_list[survey].surveyType;
            this.notificationParams.outdatedSurveyStatus = res.responseData.survey_list[survey].outdatedSurveyStatus;
            for (const outdatedItem in res.responseData.survey_list[survey].outdatedItems) {
              for (const step in res.responseData.survey_list[survey].outdatedItems[outdatedItem]) {
                this.notificationParams.stepNumber =
                  res.responseData.survey_list[survey].outdatedItems[outdatedItem][step].stepNumber;
                this.notificationParams.questionNumber =
                  res.responseData.survey_list[survey].outdatedItems[outdatedItem][step].questionNumber;
                this.notificationParams.status = res.responseData.survey_list[survey].outdatedItems[outdatedItem][step].valueEmpty ?
                  this.translateService.instant('SurveyNotificationsComponent_EMPTY')
                  : this.translateService.instant('SurveyNotificationsComponent_OUTDATED');
                if (res.responseData.survey_list[survey].surveyType === 'GOB') {
                  this.notificationParams.surveyName = this.translateService.instant(
                    'SurveyNotificationsComponent_GENERAL_ORGANIZATION'
                  );
                  this.notificationParams.surveyTypeFull = this.translateService.instant(
                    'SurveyNotificationsComponent_GENERAL'
                  );
                } else if (res.responseData.survey_list[survey].surveyType === 'LSS') {
                  this.notificationParams.countryId = res.responseData.survey_list[survey].countryId;
                  this.notificationParams.surveyName = res.responseData.survey_list[survey].countryName;
                  this.notificationParams.surveyTypeFull = this.translateService.instant(
                    'SurveyNotificationsComponent_LOCATION_SPECIFIC'
                  );
                }
                this.surveyNotificationsOutdated.push({
                  surveyName: this.notificationParams.surveyName,
                  organizationName: this.notificationParams.organizationName,
                  organizationId: this.notificationParams.organizationId,
                  countryId: this.notificationParams.countryId,
                  surveyType: this.notificationParams.surveyType,
                  surveyTypeFull: this.notificationParams.surveyTypeFull,
                  outdatedSurveyStatus: this.notificationParams.outdatedSurveyStatus,
                  stepNumber: this.notificationParams.stepNumber,
                  questionNumber: this.notificationParams.questionNumber,
                  status: this.notificationParams.status,
                });
              }
            }
          }
          return this.surveyNotificationsOutdated;
        })
      );
  }
}
