import { Component, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from 'app/login/login.service';
import { OrganizationService } from '../organizations/organization.service';
import { DisasterSpecificSurveysTableComponent } from '../surveys/disasterSpecificTable/disaster-specific-surveys.component';
import { LocationSpecificSurveysTableComponent } from '../surveys/locationSpecificTable/location-specific-surveys.component';
import {
  GOBSurveyInputInterface,
  LSSSurveyInputInterface,
  DSSSurveyInputInterface,
} from '../surveys/survey-input-interface.model';
import { ActivatedRoute } from '@angular/router';
import { GeneralOrganizationBackgroundSurveyService } from '../surveys/generalOrganizationBackground/general-organization-background.service';
import { LocationSpecificSurveyService } from '../surveys/locationSpecificSurvey/location-specific-survey.service';
import { SurveyNotification, SurveyType } from './../../../models/SurveyNotification';
import { PendingTasksComponent } from '../pendingTasks/pendingTasks.component';

@Component({
  selector: 'dashboardHome',
  templateUrl: './home.component.html',
  styleUrls: ['../../common-card-content-title.css', './home.component.css'],
})
export class DashboardHomeComponent {
  private organizationName: String;
  logoUri: string;
  private componentToLoad: string = 'BACKGROUND_SURVEY';

  private selectedCountryValue: LSSSurveyInputInterface;
  private selectedDisasterValue: DSSSurveyInputInterface;
  public selectedOrganizationValue: GOBSurveyInputInterface = {
    organizationId: this.authService.getProfile().organizationId,
  };

  private openLssQuestion: [number, number] = null;
  readonly DEFAULT_START_NUMBER: number = 1;
  private openGobQuestion: [number, number] = null;
  notificationParams: SurveyNotification = {};
  pendingTaskParams: SurveyNotification = {};

  @ViewChild(LocationSpecificSurveysTableComponent, { static: false })
  locationSurvey: LocationSpecificSurveysTableComponent;
  @ViewChild(DisasterSpecificSurveysTableComponent, { static: false })
  disasterSurvey: DisasterSpecificSurveysTableComponent;
  @ViewChild(PendingTasksComponent, { static: false }) pendingTasks: PendingTasksComponent;

  constructor(
    public authService: AuthenticationService,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private gobService: GeneralOrganizationBackgroundSurveyService,
    private lssService: LocationSpecificSurveyService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getInputParams();
  }

  getInputParams() {
    this.route.queryParams.subscribe((params) => {
      this.notificationParams.organizationId = params['organization'];
      this.notificationParams.countryId = params['country'];
      this.notificationParams.disasterId = params['disaster'];
      this.notificationParams.surveyType = params['surveyType'];
      this.notificationParams.stepNumber = params['stepNumber'];
      this.notificationParams.questionNumber = params['questionNumber'];
      this.notificationParams.messageSurveyType = SurveyType[this.notificationParams.surveyType];
    });
    if (this.notificationParams.messageSurveyType === null || this.notificationParams.messageSurveyType === undefined) {
      this.notificationParams.messageSurveyType = SurveyType.LSS;
    } else {
      switch (this.notificationParams.messageSurveyType) {
        case SurveyType.LSS: {
          const selected: LSSSurveyInputInterface = {
            organizationId: this.notificationParams.organizationId,
            countryId: this.notificationParams.countryId,
          };
          this.onSelectedCountry(selected);
          break;
        }
        case SurveyType.DSS: {
          const selected: DSSSurveyInputInterface = {
            organizationId: this.notificationParams.organizationId,
            disasterId: this.notificationParams.disasterId,
          };
          this.onSelectedDisaster(selected);
          break;
        }
        default: {
          this.notificationParams.messageSurveyType = SurveyType.GOB;
          const selected: GOBSurveyInputInterface = { organizationId: this.notificationParams.organizationId };
          this.onSelectedOrganization(selected);
        }
      }
    }
  }

  ngAfterViewInit() {
    // If the user is type 2 or type 3 , then only fetch the organization's name.
    if (this.authService.isOrgGeneral() || this.authService.isLocation()) {
      // Using organization service to fetch the organization name
      this.organizationService.getOrganization(this.authService.getProfile().organizationId).subscribe((response) => {
        this.organizationName = response.name;
        this.logoUri = response.logoURI;
      });
      this.pendingTasks.loadTasks();
    }
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  updateTables($event: string) {
    this.locationSurvey.loadSurveys();
    this.disasterSurvey.loadSurveys();
    this.pendingTasks.loadTasks();
  }

  loadComponent(component: string) {
    this.componentToLoad = component;

    // When on mobile viewport (width smaller than 414 pixels) scroll to the component panel upon click
    if (window.innerWidth <= 414) {
      const scrollToElement: any = () => {
        let elem: any;
        switch (component) {
          case 'BACKGROUND_SURVEY':
            elem = document.querySelector('div#panelGob');
            break;
          case 'LSS_SURVEY':
            elem = document.querySelector('div#panelLss');
            break;
          case 'DSS_SURVEY':
            elem = document.querySelector('div#panelDss');
            break;
          case 'PENDING_TASKS':
            elem = document.querySelector('div#panelPT');
            break;
          default:
            elem = document.querySelector('div#panelLogo');
            break;
        }
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      };

      // using setTimeout because ngOnChange() did not fire upon changing this.componentToLoad
      setTimeout(scrollToElement, 50);
    }
  }

  onSelectedCountry(selected: LSSSurveyInputInterface) {
    this.openLssQuestion = [this.notificationParams.stepNumber, this.notificationParams.questionNumber];
    this.loadComponent('LSS_SURVEY');
    this.selectedCountryValue = selected;
    window.scrollTo(0, 0);
  }

  onSelectedDisaster(selected: DSSSurveyInputInterface) {
    this.loadComponent('DSS_SURVEY');
    this.selectedDisasterValue = selected;
    window.scrollTo(0, 0);
  }

  onSelectedOrganization(selected: GOBSurveyInputInterface) {
    this.openGobQuestion = [this.notificationParams.stepNumber, this.notificationParams.questionNumber];
    this.loadComponent('BACKGROUND_SURVEY');
    this.selectedOrganizationValue = selected;
    window.scrollTo(0, 0);
  }

  onSurveyOpened(survey: string) {
    if (survey === 'LSS_SURVEY') {
      this.openLssQuestion = null;
    } else if (survey === 'BACKGROUND_SURVEY') {
      this.openGobQuestion = null;
    }
  }

  onSurveySaved(): void {
    this.pendingTasks.loadTasks();
  }

  onGoToLSS(selected: LSSSurveyInputInterface) {
    this.openLssQuestion = [this.DEFAULT_START_NUMBER, this.DEFAULT_START_NUMBER];
    this.loadComponent('LSS_SURVEY');
    this.selectedCountryValue = selected;
    window.scrollTo(0, 0);
  }

  // returns the survey type so that the matching collapsible panel can be expanded
  surveyType() {
    return this.notificationParams.surveyType;
  }
}
