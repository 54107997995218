import { Component } from '@angular/core';

import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AppSettings } from '../../../../../common/config';
import { DocumentService } from '../../../../../sharedServices/documentService/document.service';
import { partnerFormatValidators } from '../../../../../validators/validators';
import { REPORT_SUBMISSION_FREQUENCY } from '../../survey-helpers';
import { handleErrors } from '../../../../../common/error';
import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';
import { DSSStepComponent } from '../dss-step/dss-step.component';
import { UsaStateNamePipe } from '../../../../../pipes/usaStateNamePipe';

@Component({
  selector: 'dss-step6',
  templateUrl: './dss-step6.component.html',
  styleUrls: ['./dss-step6.component.css', '../dss-steps-common.css', '../../survey-table-common.css'],
  providers: [UsaStateNamePipe],
})
export class DSSStep6Component extends DSSStepComponent {
  localStaffForm: FormGroup;
  nonLocalStaffForm: FormGroup;
  volunteerStaffForm: FormGroup;
  activityReportForm: FormGroup;
  engagementListForm: FormGroup;
  additionalInfoForm: FormGroup;
  locations: String; // comma separated list of locations used in HTML

  reportFrequencyTypes = REPORT_SUBMISSION_FREQUENCY;

  constructor(
    private fb: FormBuilder,
    private surveyManager: DisasterSpecificSurveyManager,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private documentService: DocumentService,
    private usaStateName: UsaStateNamePipe
  ) {
    super(surveyManager);

    this.invalidOnInit = {
      maps: {
        activityReport: {
          webLink: false,
        },
        localStaff: {
          mgmtStaffCount: false,
          professionalStaffCount: false,
          supportStaffCount: false,
        },
        nonLocalStaff: {
          mgmtStaffCount: false,
          professionalStaffCount: false,
          supportStaffCount: false,
        },
        volunteerStaff: {
          mgmtStaffCount: false,
          professionalStaffCount: false,
          supportStaffCount: false,
        },
      },
      arrays: {
        engagementList: [],
      },
    };

    this.localStaffForm = <FormGroup>this.surveyManager.mainForm.controls['localStaff'];
    this.nonLocalStaffForm = <FormGroup>this.surveyManager.mainForm.controls['nonLocalStaff'];
    this.volunteerStaffForm = <FormGroup>this.surveyManager.mainForm.controls['volunteerStaff'];
    this.activityReportForm = <FormGroup>this.surveyManager.mainForm.controls['activityReport'];
    this.engagementListForm = <FormGroup>this.surveyManager.mainForm.controls['engagementList'];
    this.additionalInfoForm = <FormGroup>this.surveyManager.mainForm.controls['additionalInfo'];

    const formGroup = <FormGroup>this.activityReportForm.controls['value'];

    // subscribe to value change for hasValue
    formGroup.controls['hasValue'].valueChanges.subscribe((event) => {
      // empty controls if hasValue is false
      if (!event) {
        formGroup.controls['webLink'].setValue(null, { emitEvent: false });
        formGroup.controls['fileId'].setValue(null, { emitEvent: false });
        formGroup.controls['reportFrequencyId'].setValue(null, { emitEvent: false });
        formGroup.controls['reportFrequencyOther'].setValue(null, { emitEvent: false });
      }
    });

    // subscribe to value change for reportFrequencyId
    formGroup.controls['reportFrequencyId'].valueChanges.subscribe((event) => {
      // empty control reportFrequencyOther if reportFrequencyId != 5
      // (i.e. frequency is NOT Other)
      if (event !== 5) {
        formGroup.controls['reportFrequencyOther'].setValue(null, { emitEvent: false });
      }
    });

    // subscribe to value change for webLink
    formGroup.controls['webLink'].valueChanges.subscribe((event) => {
      this.setHasValueField();
    });

    // subscribe to value change for fileId
    formGroup.controls['fileId'].valueChanges.subscribe((event) => {
      this.setHasValueField();
    });
  }

  assignDataToForm(): void {
    if (!this.surveyData) {
      return;
    }

    if (this.surveyData.localStaff) {
      this.localStaffForm.patchValue(this.surveyData.localStaff, { emitEvent: false });
    } else {
      this.localStaffForm.reset();
    }

    if (this.surveyData.nonLocalStaff) {
      this.nonLocalStaffForm.patchValue(this.surveyData.nonLocalStaff, { emitEvent: false });
    } else {
      this.nonLocalStaffForm.reset();
    }

    if (this.surveyData.volunteerStaff) {
      this.volunteerStaffForm.patchValue(this.surveyData.volunteerStaff, { emitEvent: false });
    } else {
      this.volunteerStaffForm.reset();
    }

    if (this.surveyData.activityReport) {
      this.activityReportForm.patchValue(this.surveyData.activityReport, { emitEvent: false });
    } else {
      this.activityReportForm.reset();
    }

    let engagementListControls: FormControl[] = [this.fb.control(null, partnerFormatValidators)];
    if (this.surveyData.engagementList) {
      this.engagementListForm
        .get('lastUpdatedDate')
        .patchValue(this.surveyData.engagementList.lastUpdatedDate, { emitEvent: false });
      this.engagementListForm
        .get('lastUpdatedUser')
        .patchValue(this.surveyData.engagementList.lastUpdatedUser, { emitEvent: false });
      this.engagementListForm
        .get('updatedBySRAdmin')
        .patchValue(this.surveyData.engagementList.updatedBySRAdmin, { emitEvent: false });

      if (this.surveyData.engagementList.value && this.surveyData.engagementList.value.length) {
        engagementListControls = this.surveyData.engagementList.value.map((engagement) =>
          this.fb.control({ value: engagement, disabled: false }, partnerFormatValidators)
        );
      }
    } else {
      this.engagementListForm.reset();
    }
    this.engagementListForm.setControl('value', this.fb.array(engagementListControls));

    if (this.surveyData.additionalInfo) {
      this.additionalInfoForm.patchValue(this.surveyData.additionalInfo, { emitEvent: false });
    }

    // Assign location (separated by comma)
    this.locations = this.surveyData.disaster.locations
      .map((o) => this.usaStateName.transform(o.country.name))
      .filter((element, idx, self) => idx === self.indexOf(element))
      .join(', ');

    this.setInitialErrorVisibility();
  }

  onCancel(): void {
    this.assignDataToForm();
    this.cancel.emit(null);
  }

  onFileSelected(event: any, formControl: FormControl): void {
    const targetFile = event.target.files[0];

    if (!(targetFile instanceof File)) {
      return;
    }

    if (targetFile.size > AppSettings.MAXIMUM_DOCUMENT_UPLOAD_SIZE) {
      this.notificationsService.error(
        this.translateService.instant('DisasterSpecificSurveysTableComponent-TITLE'),
        this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );
      return;
    }

    if (AppSettings.DEFAULT_ALLOWED_DOCUMENT_TYPES.indexOf(targetFile.type) === -1) {
      this.notificationsService.error(
        this.translateService.instant('DisasterSpecificSurveysTableComponent-TITLE'),
        this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );
      return;
    }

    this.documentService.uploadDocument(targetFile).subscribe(
      (success) => {
        formControl.setValue(success.fileId);
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'DisasterSpecificSurveysTableComponent-TITLE'
        );
      }
    );
  }

  onAddEngagement() {
    (<FormArray>this.engagementListForm.get('value')).push(this.fb.control('', partnerFormatValidators));
  }
  onDeleteEngagement(index: number) {
    (<FormArray>this.engagementListForm.get('value')).removeAt(index);
  }

  setHasValueField(): void {
    const formGroup = <FormGroup>this.activityReportForm.controls['value'];

    if (formGroup.controls['webLink'].value || formGroup.controls['fileId'].value) {
      formGroup.controls['hasValue'].setValue(true, { emitEvent: false });
    } else {
      if (formGroup.controls['hasValue'].value) {
        formGroup.controls['hasValue'].setValue(false, { emitEvent: false });
      } else {
        formGroup.controls['hasValue'].setValue(null, { emitEvent: false });
      }
    }
  }

  private setInitialErrorVisibility(): void {
    if (this.surveyData.activityReport) {
      this.invalidOnInit.maps.activityReport.webLink = !this.activityReportForm.get('value').get('webLink').valid;
    }
    if (this.surveyData.localStaff) {
      for (const count in this.invalidOnInit.maps.localStaff) {
        this.invalidOnInit.maps.localStaff[count] = !this.localStaffForm.get('value').get(count).valid;
      }
    }
    if (this.surveyData.nonLocalStaff) {
      for (const count in this.invalidOnInit.maps.nonLocalStaff) {
        this.invalidOnInit.maps.nonLocalStaff[count] = !this.nonLocalStaffForm.get('value').get(count).valid;
      }
    }
    if (this.surveyData.volunteerStaff) {
      for (const count in this.invalidOnInit.maps.volunteerStaff) {
        this.invalidOnInit.maps.volunteerStaff[count] = !this.volunteerStaffForm.get('value').get(count).valid;
      }
    }
    if (this.surveyData.engagementList) {
      this.invalidOnInit.arrays.engagementList = this.engagementListForm
        .get('value')
        ['controls'].map((control) => !control.valid);
    }
  }
}
