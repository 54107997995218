import { Component, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MaterializeAction } from 'angular2-materialize';
import { SendMessageService } from './send-message.service';
import { TranslateService } from '@ngx-translate/core';
import { handleErrors } from '../common/error';
import { NotificationsService } from 'angular2-notifications';
import { emptySpaceValidator } from '../validators/emptySpaceValidator';

@Component({
  selector: 'send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.css'],
  providers: [],
})
export class SendMessageComponent {
  sendMessageForm: FormGroup;
  subjectField: FormControl;
  messageTextField: FormControl;

  @Input() modalActions: EventEmitter<string | MaterializeAction>;

  isSendingMessage: boolean = false;

  constructor(
    private messageService: SendMessageService,
    private builder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {
    this.subjectField = new FormControl('', [Validators.required, emptySpaceValidator()]);
    this.messageTextField = new FormControl('', [Validators.required, emptySpaceValidator()]);

    this.sendMessageForm = builder.group({
      subjectField: this.subjectField,
      messageTextField: this.messageTextField,
    });
  }

  onSubmit() {
    /* prevent sending duplicated email by repeated click before message is sent */
    if (this.isSendingMessage) {
      return;
    }

    this.isSendingMessage = true;
    this.messageService.submitMessage(this.subjectField.value, this.messageTextField.value).subscribe(
      (response) => {
        this.notificationsService.success(
          this.translateService.instant('SendMessageComponent-TITLE'),
          this.translateService.instant('SendMessageComponent-TOAST_SUCCESS', { text: this.subjectField.value })
        );

        this.isSendingMessage = false;

        this.resetMessageForm();
        this.modalActions.emit({ action: 'modal', params: ['close'] });
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationsService, 'SendMessageComponent-TITLE');

        this.isSendingMessage = false;
        this.modalActions.emit({ action: 'modal', params: ['close'] });
      }
    );
  }

  private resetMessageForm() {
    // reset the form values on successful submit
    this.subjectField = new FormControl('', Validators.required);
    this.messageTextField = new FormControl('', Validators.required);

    this.sendMessageForm = this.builder.group({
      subjectField: this.subjectField,
      messageTextField: this.messageTextField,
    });
  }

  onCancel() {
    this.modalActions.emit({ action: 'modal', params: ['close'] });
    this.resetMessageForm();
  }
}
