import { zip } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Disaster } from '../../models/Disaster';
import { DisasterAdminService } from '../../dashboard/servicesComponets/disaster/disaster.service';
import { Service } from '../../models/Service';
import { ServicesProvidedService } from '../../dashboard/servicesComponets/service/service.service';
import { AppSettings } from '../../common/config';
import { RecentSurveyService } from '../disasterList/recent-survey.service';
import { RecentSurvey, SurveyType } from '../../models/RecentSurvey';

const DISASTER_LIST_TYPES: string[] = ['starred'];

@Component({
  selector: 'disastersPage',
  templateUrl: './disastersPage.component.html',
  styleUrls: [
    '../../searchResults/common-search.css',
    '../../dashboard/common-base.css',
    '../disasterList/disasterList.component.css',
    './disastersPage.component.css',
  ],
})
export class DisastersPageComponent implements OnInit {
  surveyList: RecentSurvey[] = [];
  disastersAreLoaded: boolean = false;

  DISASTER_LIST_TYPES = DISASTER_LIST_TYPES;
  AppSettings = AppSettings;

  disasters: { [key: string]: (Disaster | Service)[] };
  serviceList: { [index: number]: Service } = {};
  newSurveys: any[] = [];

  error: boolean;

  surveyType = SurveyType;
  loading = false;
  tableColumns: String[] = ['organization', 'location', 'type', 'date', 'details'];
  disasterItemsPerPageArg: number = 10;
  disasterSelectedPage: number = 0;
  surveyItemsPerPageArg: number = 10;
  surveySelectedPage: number = 0;
  constructor(
    private disasterService: DisasterAdminService,
    private servicesProvidedService: ServicesProvidedService,
    private sanitizer: DomSanitizer,
    private recentSurveyService: RecentSurveyService
  ) {}

  ngOnInit() {
    const starredObservable = this.disasterService.getStarredDisasters(0);
    const allObservable = this.disasterService.getDisasters();
    const serviceListObservable = this.servicesProvidedService.getServices();
    this.loading = true;

    allObservable
      .pipe(
        zip(starredObservable, serviceListObservable, (recent, starred, serviceList) => ({
          recent,
          starred,
          serviceList,
        }))
      )
      .subscribe(
        (success) => {
          success.serviceList.forEach((service, index) => {
            this.serviceList[service.serviceId] = service;
          });
          this.disasters = success;
          for (const dType of DISASTER_LIST_TYPES) {
            this.disasters[dType].forEach((item) => {
              item.summary = this.sanitizer.bypassSecurityTrustHtml(<string>item.summary);

              if (item.logo === null) {
                item.logo = {
                  uri: 'assets/images/org-default.png',
                };
              }
            });
          }
          this.disastersAreLoaded = true;
        },
        (error) => {
          this.error = true;
        }
      );

    const newSurverysSubscription = this.recentSurveyService.getNewSurveys().subscribe((res) => {
      this.newSurveys = res;
    });
    const recentGobSurveyObservable = this.recentSurveyService.getRecentSurveys(100, SurveyType.Gob);
    const recentLssSurveyObservable = this.recentSurveyService.getRecentSurveys(100, SurveyType.Lss);
    const recentDssSurveyObservable = this.recentSurveyService.getRecentSurveys(100, SurveyType.Dss);
    this.loading = true;
    recentGobSurveyObservable
      .pipe(
        zip(recentLssSurveyObservable, recentDssSurveyObservable, (gobSurvey, lssSurvey, dssSurvey) => ({
          gobSurvey,
          lssSurvey,
          dssSurvey,
        }))
      )
      .subscribe((response) => {
        this.loading = false;
        this.surveyList = this.surveyList
          .concat(response.gobSurvey)
          .concat(response.lssSurvey)
          .concat(response.dssSurvey);

        this.surveyList.sort((a, b) => {
          return b.surveyUpdateDate - a.surveyUpdateDate;
        });
      });
  }
  onDisasterItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.disasterItemsPerPageArg = +updatedVal;
    this.disasterSelectedPage = 0;
  }
  onSurveyItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.surveyItemsPerPageArg = +updatedVal;
    this.surveySelectedPage = 0;
  }

  isOrganizationNew(survey: RecentSurvey): boolean {
    return this.newSurveys.some((newSurvey) => newSurvey.organizationId === survey.orgId);
  }
}
