import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { handleErrors } from '../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { forkJoin, from, Observable, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  Collection,
  ConvertedMoney,
  Country,
  Dictionary,
  Disaster,
  DonationCampaign,
  LocationSpecificSurveyInput,
  Money,
  OrgLocationScore,
} from '../../models';
import { CountryService } from '../../dashboard/servicesComponets/country/country.service';
import { LocationAboutService } from './location-about.service';
import { ResponseProfileComponent } from '../responseProfile/response-profile.component';
import { validateResponseField } from '../survey-response-helpers';
import { AppSettings } from '../../common/config';
import { GeneralOrganizationBackgroundSurveyService } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.service';
import { GeneralOrganizationBackgroundSurveyInput } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.model';
import { LocationSpecificSurveyService } from '../../dashboard/servicesComponets/surveys/locationSpecificSurvey/location-specific-survey.service';
import * as _ from 'lodash';
import { MaterializeAction } from 'angular2-materialize';
import { CurrencyService } from 'app/sharedServices/currencyService/currency.service';
import { CurrencyPickerService } from '../../header/currencyPicker/currency-picker.service';

@Component({
  selector: 'location-about',
  templateUrl: './location-about.component.html',
  styleUrls: ['./location-about.component.css', '../common-search.css'],
})
export class LocationAboutComponent extends ResponseProfileComponent implements OnInit {
  public loading = true;
  public location: Country;
  public respondingOrgs: any[];
  public donationCampaigns: DonationCampaign[];
  public disasters: Disaster[];
  public csloCount: Number[];
  public ingoCount: Number[];
  public serviceList: string[]; // combined listed of unique service across all responsding orgs
  public sortingActions: EventEmitter<OrgLocationScore> = new EventEmitter<OrgLocationScore>();
  public organizationScores: OrgLocationScore[];

  public itemsPerPageArg: number = 10;
  public selectedPage: number = 0;

  public serviceFilterList: string[] = [];

  public mergeLocation: any;
  public latestLssSurvey: LocationSpecificSurveyInput[];

  title: string = 'LocationAboutComponent-TITLE';
  subscriptions: Dictionary<Subscription> = {
    route: null,
    currency: null,
  };

  money: Collection<ConvertedMoney> = {
    singles: {},
    arrays: {
      budget: [],
    },
  };

  isSubmitting: boolean = false;
  oName: string;
  oID: string;
  oDonateUrl: string;
  donateModalActions = new EventEmitter<string | MaterializeAction>();
  tippingModalActions = new EventEmitter<string | MaterializeAction>();

  constructor(
    private countryService: CountryService,
    //  private orgService: OrganizationService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private locationAboutService: LocationAboutService,
    private lssService: LocationSpecificSurveyService,
    private globalLoader: SlimLoadingBarService,
    private gobService: GeneralOrganizationBackgroundSurveyService,
    private currencyService: CurrencyService,
    private currencyPickerService: CurrencyPickerService
  ) {
    super(currencyService, currencyPickerService, notificationsService, translateService);
  }
  ngOnInit() {
    this.addSubscription('route', this.getRouteSubscription.bind(this));
    this.globalLoader.start();
    this.loading = true;
  }

  private getRouteSubscription(): Subscription {
    const routeObservable = this.route.params.pipe(
      switchMap((params: Params) => {
        const locationId = +params['id'];
        const lssListObservable = this.lssService.getSurveysByLocation(locationId);
        const locationAboutObservable = this.locationAboutService.getDisastersByLocation(locationId);
        const csloCountObservable = this.locationAboutService.getOrganizationCount(
          locationId,
          AppSettings.ORGANIZATION_TYPES['CSLO']
        );
        const ingoCountObservable = this.locationAboutService.getOrganizationCount(
          locationId,
          AppSettings.ORGANIZATION_TYPES['INGO']
        );
        const countryObservale = this.countryService.getCountry(locationId);

        return forkJoin([
          countryObservale,
          lssListObservable,
          csloCountObservable,
          ingoCountObservable,
          locationAboutObservable,
        ]).pipe(
          map(([country, lssList, csloCount, ingoCount, disasterList]) => ({
            country,
            lssList,
            csloCount,
            ingoCount,
            disasterList,
          }))
        );
      })
    );

    return routeObservable.subscribe(
      (success) => {
        this.onLocationLoaded(success);
      },
      (error) => {
        this.globalLoader.complete();
        this.loading = false;
        handleErrors(error, this.translateService, this.notificationsService, this.title);
      }
    );
  }

  private getCurrencySubscription(): Subscription {
    return this.setupCurrencyContext().subscribe(([conversions, formats]) => {
      this.setCurrencies(conversions.currency, formats);
      this.setMoney(conversions.money);
    });
  }

  private setMoney(money: Collection<ConvertedMoney>): void {
    if (money.arrays && money.arrays.budget) {
      money.arrays.budget.forEach((budget, i) => {
        this.respondingOrgs[i]['budget'] = budget.converted;
      });
    }
    this.money = money;
  }

  private onLocationLoaded(data: any): void {
    this.globalLoader.complete();
    this.loading = false;
    this.location = data.country;
    const respondingLssList: LocationSpecificSurveyInput[] = data.lssList
      .map((lss) => lss.scoreDto.surveyDto)
      .filter((survey) => survey && survey.surveySubmissionId !== null);
    this.getRespondingGobs(respondingLssList).subscribe((gobList: GeneralOrganizationBackgroundSurveyInput[]) => {
      this.setRespondingOrgs(respondingLssList, gobList);
      this.serviceList = this.getUniqueServiceList();
      this.addSubscription('currency', this.getCurrencySubscription.bind(this));
    });
    this.disasters = data.disasterList;
    this.csloCount = data.csloCount.responseData.count;
    this.ingoCount = data.ingoCount.responseData.count;
    this.organizationScores = data.lssList.map((item) => {
      if (item.scoreDto.surveyDto) {
        return <OrgLocationScore>{
          organizationName: item.scoreDto.surveyDto.organizationName,
          organizationId: item.scoreDto.surveyDto.organizationId,
          countryName: item.scoreDto.surveyDto.country ? item.scoreDto.surveyDto.country.name : null,
          countryId: item.scoreDto.surveyDto.country ? item.scoreDto.surveyDto.country.countryId : null,
          updateScore: item.scoreDto.updateScore,
          adminEditScore: item.scoreDto.adminEditScore,
          directServiceScore: item.scoreDto.directServiceScore,
          refusedQuestionScore: item.scoreDto.refusedQuestionScore,
          localStaffScore: item.scoreDto.localStaffScore,
        };
      }
    });
    this.latestLssSurvey = data.lssList.map((lss) => lss.scoreDto.surveyDto);
    this.mergeLocation = _.mapValues(_.groupBy(this.latestLssSurvey, 'organizationName'), (value) =>
      _.chain(value).map('operatingLocationList.value').flattenDeep().value()
    );
    let i: number = 1;

    Object.entries(this.mergeLocation).forEach(([key, values]) => {
      const pinColor = this.getRandomColor();
      if (Array.isArray(values)) {
        values.forEach((item) => {
          if (item != null) {
            item.organizationName = key.toString();
            item.label = i.toString();
            item.color = pinColor;
            item.visible = false;
          }
        });
        i += 1;
      }
    });
    this.mergeLocation = _.compact(_.flattenDeep(Object.values(this.mergeLocation)));
  }

  getRandomColor() {
    const colors = {
      black: '#2d2e30',
      blue: '#0000ff',
      brown: '#a52a2a',
      chocolate: '#D2691E',
      coral: '#FF7F50',
      cyan: '#00ffff',
      darkblue: '#00008b',
      darkblue1: '#1E3A5E',
      darkbrown: '#5c2b29',
      darkcyan: '#008b8b',
      darkgreen: '#006400',
      darkgreen1: '#16504b',
      darkkhaki: '#bdb76b',
      darkmagenta: '#8b008b',
      darkorange: '#614a19',
      darkred: '#8b0000',
      darkviolet: '#9400d3',
      darkyellow: '#999900',
      deeppink: '#FF1493',
      firebrick: '#B22222',
      gold: '#ffd700',
      goldenrod: '#DAA520',
      indigo: '#4b0082',
      lime: '#00ff00',
      maroon: '#800000',
      mediumaquamarine: '#66CDAA',
      mediumspringgreen: '00FA9A',
      navy: '#000080',
      olive: '#808000',
      orange: '#ffa500',
      orangered: '#FF4500',
      palevioletred: '#DB7093',
      purple: '#800080',
      teal: '#008080',
      turquoise: '#40E0D0',
      violet: '#800080',
      red: '#ff0000',
      yellow: '#ffff00',
    };
    return Object.values(colors)[Math.floor(Math.random() * Object.keys(colors).length)];
  }

  private getRespondingGobs(
    surveyList: LocationSpecificSurveyInput[]
  ): Observable<GeneralOrganizationBackgroundSurveyInput[]> {
    const gob$Arr: Observable<GeneralOrganizationBackgroundSurveyInput>[] = surveyList.map((lss) => {
      return this.gobService.getMostRecentSurvey(lss.organizationId).pipe(
        catchError(() => {
          console.log('Error loading the latest GOB survey for ' + lss.organizationId);
          return from([null]);
        })
      );
    });

    return forkJoin(gob$Arr);
  }

  setRespondingOrgs(lssList: LocationSpecificSurveyInput[], gobList: GeneralOrganizationBackgroundSurveyInput[]): any {
    this.respondingOrgs = [];

    lssList.forEach((lss, i) => {
      const org = {
        id: lss.organizationId,
        name: lss.organizationName,
        staffCount: this.getStaffCount(lss),
        serviceList: this.getServiceList(lss),
        serviceListPreview: this.getServiceList(lss).slice(0, 3),
        hqLocation: lss.organizationDto.country,
        donateButtonDisabled: lss.donateButtonDisabled,
        donateUrl: '',
      };
      const gob: GeneralOrganizationBackgroundSurveyInput = gobList.find(
        (gob) => gob.organizationId === lss.organizationId
      );
      if (gob.donateUrl !== null) {
        org.donateUrl = gob.donateUrl && gob.donateUrl.value;
      }

      this.respondingOrgs[i] = org;
      this.money.arrays.budget[i] = new ConvertedMoney(validateResponseField<Money>(lss.overallBudget));
    });
  }

  getUniqueServiceList(): string[] {
    let serviceList = [];
    for (const org of this.respondingOrgs) {
      serviceList = serviceList.concat(org.serviceList);
    }

    // remove duplicates
    serviceList = serviceList.filter((item, pos) => {
      return serviceList.indexOf(item) === pos;
    });

    return serviceList;
  }

  private getStaffCount(lssSurvey: LocationSpecificSurveyInput): number {
    let staffCount = 0;
    if (!lssSurvey) {
      return staffCount;
    }

    for (const staffType of ['localStaff', 'nonLocalStaff']) {
      if (lssSurvey[staffType]) {
        staffCount =
          staffCount +
          lssSurvey[staffType].value.mgmtStaffCount +
          lssSurvey[staffType].value.professionalStaffCount +
          lssSurvey[staffType].value.supportStaffCount;
      }
    }

    return staffCount;
  }

  private getServiceList(lssSurvey: LocationSpecificSurveyInput): string[] {
    const serviceList = [];

    if (!lssSurvey || !lssSurvey.serviceList) {
      return serviceList;
    }

    for (const service of lssSurvey.serviceList.value) {
      if (service.directProportion || service.indirectProportion) {
        serviceList.push(service.serviceProvided.name);
      }
    }

    return serviceList;
  }

  private toggleServiceFilter(service: string) {
    // toggles a service name's existence in the filter list
    if (this.serviceFilterList.indexOf(service) >= 0) {
      this.serviceFilterList = this.serviceFilterList.filter((serviceFilter) => serviceFilter !== service);
    } else {
      this.serviceFilterList.push(service);
    }
  }

  onItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.itemsPerPageArg = +updatedVal;
    this.selectedPage = 0;
  }

  openModal(orgId: number) {
    this.sortingActions.emit(this.organizationScores.find((score) => score.organizationId === orgId));
  }

  openDonateModal(oName: string, oID: string, oDonateUrl: string) {
    this.oName = oName;
    this.oID = oID;
    this.oDonateUrl = oDonateUrl;
    this.donateModalActions.emit({ action: 'modal', params: ['open'] });
  }
}
