import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl } from '@angular/forms';
import { DonationManagementService } from '../searchResults/donationHelper/donation-management.service';
import { StripeDonation } from 'app/models';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { handleErrors } from 'app/common/error';
import { getNumberFromInput, getFloatFromInput } from 'app/dashboard/servicesComponets/surveys/survey-helpers';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { AppSettings } from 'app/common/config';
import { EmailValidator } from '../validators/emailValidator';
import { LeadingTrailingWhiteSpaceValidator } from '../validators/leadingTrailingWhiteSpaceValidator';
import { Router } from '@angular/router';

export interface PossibleButtons {
  btn50: boolean;
  btn100: boolean;
  btn250: boolean;
  btn500: boolean;
  btn1000: boolean;
  btn0: boolean;
}

@Component({
  selector: 'share-stripe-custom',
  templateUrl: './shareStripe.component.html',
  styleUrls: ['../searchResults/disasterAbout/disaster-about.component.css', './shareStripe.component.css'],
  providers: [DonationManagementService],
})
export class ShareStripeComponent implements OnInit {
  @Input() donateModalActions: any;
  @Input() tippingModalActions: any;
  @Input() tippingText: boolean;
  customStripeForm: FormGroup;

  loading: boolean;
  isSubmitting: boolean = false;
  otherAmount: number = 0;
  boolAmount: boolean = false;
  amount: number = 0;

  displayThankYou: boolean = false;

  buttons: PossibleButtons = {
    btn50: false,
    btn100: false,
    btn250: false,
    btn500: false,
    btn1000: false,
    btn0: false,
  };

  switchAmountButton = (buttons: PossibleButtons, amount: number): PossibleButtons =>
    Object.keys(buttons).reduce(
      (newButtons, key) =>
        key === 'btn' + amount ? { ...newButtons, ['btn' + amount]: true } : { ...newButtons, [key]: false },
      {} as PossibleButtons
    );
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private donationManagementService: DonationManagementService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private globalLoader: SlimLoadingBarService
  ) {}

  ngOnInit() {
    this.globalLoader.start();
    this.loading = true;

    this.buildForm();
  }

  buildForm() {
    this.customStripeForm = this.formBuilder.group({
      donationFrequency: ['once'],
      amount: ['', [Validators.pattern(AppSettings.ONLY_NUMERIC_FULL_STRING), Validators.maxLength(15)]],
      cardNumber: ['', [Validators.required, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)]],
      cardExpirationMonth: ['', [Validators.required]],
      cardExpirationYear: [
        '',
        [Validators.required, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)],
      ],
      cvv: ['', [Validators.required, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)]],
      currency: [''],
      donorEmail: [
        '',
        [
          Validators.required,
          EmailValidator.mailFormat,
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ],
      ],
      donorFirstName: ['', [Validators.required, Validators.maxLength(32)]],
      donorLastName: ['', [Validators.required, Validators.maxLength(32)]],
      postalCode: ['', [Validators.required, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)]],
    });
  }

  setAmount(amount: number): void {
    this.amount = amount;

    this.buttons = this.switchAmountButton(this.buttons, this.amount);
    this.boolAmount = true;
    if (this.buttons.btn0) {
      this.boolAmount = true;
    }
  }

  getAmount() {
    this.otherAmount = getFloatFromInput(this.customStripeForm.controls['amount'].value);
    return this.otherAmount ? this.otherAmount.toFixed(2) : this.amount;
  }

  pay() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    const newRecord: StripeDonation = this.customStripeForm.value;
    if (this.buttons.btn0) {
      newRecord.amount = this.otherAmount;
    } else {
      newRecord.amount = this.amount;
    }
    newRecord.cardExpirationMonth = getNumberFromInput(this.customStripeForm.controls['cardExpirationMonth'].value);
    newRecord.cardExpirationYear = getNumberFromInput(this.customStripeForm.controls['cardExpirationYear'].value);
    newRecord.currency = 'USD';
    this.donationManagementService.addNewDonationCharge(newRecord).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.buildForm();
        this.amount = 0;
        this.otherAmount = 0;
        this.buttons = this.switchAmountButton(this.buttons, -1);

        if (this.tippingModalActions) {
          this.displayThankYou = true;
        } else {
          this.router.navigate(['/dap-donation-thanks']);
        }

        this.notificationsService.success(
          this.translateService.instant('Stripe-PAYMENT_INFO'),
          this.translateService.instant('Stripe-SUCCESS_CREATE_MESSAGE')
        );
      },
      (error) => {
        this.isSubmitting = false;
        handleErrors(error, this.translateService, this.notificationsService, 'Stripe-PAYMENT_INFO');
      }
    );
  }

  closeModal() {
    if (this.donateModalActions || this.tippingModalActions) {
      this.donateModalActions.emit({ action: 'modal', params: ['close'] });
      this.tippingModalActions.emit({ action: 'modal', params: ['close'] });
      this.displayThankYou = false;
    }
  }
}
