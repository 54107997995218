import { Injectable } from '@angular/core';
import { Country } from 'app/models/Country';
import { handleErrors } from 'app/common/error';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CountryService } from '../../country/country.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CountryListService {
  constructor(
    private countryService: CountryService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {}

  isCountryUSA(countryId?: number): Observable<boolean> {
    return this.countryService.getCountries().pipe(
      map((countries) => {
        if (countryId === null) {
          return true;
        }
        for (const country of countries) {
          if (country.countryId === countryId) {
            return country.isInUS;
          }
        }
      }),
      catchError((error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'SurveyCommon-COUNTRY_LIST_NOT_AVAILABLE'
        );
        return of('error', error);
      })
    );
  }
}
