import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrgValidator } from '../validators/orgValidator';
import { EmailValidator } from '../validators/emailValidator';
import { AuthenticationService } from '../login/login.service';
import { LeadingTrailingWhiteSpaceValidator } from '../validators/leadingTrailingWhiteSpaceValidator';
import { handleErrors } from '../common/error';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { AppSettings } from '../common/config';
import { Country } from '../models/Country';
import { PhoneValidator } from '../validators/phoneValidator';
import { numbersOnlyValidator } from '../validators/onlyNumbersValidator';

@Component({
  selector: 'nominate',
  templateUrl: './nominate.component.html',
  styleUrls: ['./nominate.component.css'],
})
export class NominateComponent {
  isSubmitting: boolean = false;
  nominateForm: FormGroup;

  error: string;

  public AppSettings = AppSettings;

  loading: boolean;

  constructor(
    public router: Router,
    public authService: AuthenticationService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private loaderService: SlimLoadingBarService
  ) {
    this.nominateForm = new FormGroup({
      organizationName: new FormControl('', [
        Validators.required,
        Validators.maxLength(128),
        OrgValidator.orgFormat,
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        numbersOnlyValidator(),
      ]),
      orgLocation: new FormControl('', [
        Validators.maxLength(200),
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        numbersOnlyValidator(),
      ]),
      orgDo: new FormControl('', [
        Validators.maxLength(200),
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        numbersOnlyValidator(),
      ]),
      orgWhy: new FormControl('', [
        Validators.maxLength(200),
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        numbersOnlyValidator(),
      ]),
      orgKnow: new FormControl('', []),
      contactName: new FormControl('', [
        Validators.required,
        Validators.maxLength(64),
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        numbersOnlyValidator(),
      ]),
      contactEmail: new FormControl('', [
        Validators.required,
        Validators.maxLength(64),
        EmailValidator.mailFormat,
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
      ]),
      contactPhone: new FormControl('', [
        Validators.required,
        Validators.maxLength(32),
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        PhoneValidator.phoneFormat,
      ]),
      captcha: new FormControl(null, Validators.required),
    });
  }

  onValidCaptchaResponse(captcha: string) {
    this.nominateForm.controls['captcha'].setValue(captcha);
  }

  onCaptchaExpire($event: any) {
    this.nominateForm.controls['captcha'].reset();
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this.loaderService.start();
    this.loading = true;

    const submitData = {
      organizationName: this.nominateForm.value.organizationName,
      orgLocation: this.nominateForm.value.orgLocation,
      orgDo: this.nominateForm.value.orgDo,
      orgWhy: this.nominateForm.value.orgWhy,
      orgKnow: this.nominateForm.value.orgKnow,
      contactName: this.nominateForm.value.contactName,
      contactEmail: this.nominateForm.value.contactEmail,
      contactPhone: this.nominateForm.value.contactPhone.replace(/\s/g, ''),
    };

    this.authService.nominateOrganization(submitData, this.nominateForm.value.captcha).subscribe(
      (response) => {
        this.router.navigate(['/nominate-success']);

        this.isSubmitting = false;
        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'NominateComponent-NOMINATE_ORGANIZATION_ACCOUNT_HEADING'
        );

        this.isSubmitting = false;
        this.loaderService.complete();
        this.loading = false;
      }
    );
  }
}
