import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../common/config';
import { contentHeaders, xWwwFormUrlEncodedHeaders } from '../common/headers';

const VALIDATE_TOKEN_API_ENDPOINT = AppSettings.API_SERVER + '/smart-response/validate_token';
const RESET_PASSWORD_API_ENDPOINT = AppSettings.API_SERVER + '/smart-response/admin/reset_password';

@Injectable()
export class ResetPasswordAuth {
  constructor(public http: HttpClient) {}

  tokenValidation(token: string) {
    let params = new HttpParams();

    params = params.set('token', token);

    return this.http.post(VALIDATE_TOKEN_API_ENDPOINT, params.toString(), { headers: xWwwFormUrlEncodedHeaders });
  }

  resetPassword(submitData: any) {
    const body = JSON.stringify(submitData);

    return this.http.post(RESET_PASSWORD_API_ENDPOINT, body, { headers: contentHeaders });
  }
}
