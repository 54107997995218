<div class="modal-content">
  <div class="card-content text-size">
    <div class="sr-bold h5">{{ 'Donation-THANKS_TEXT' | translate }} {{ orgName }}!</div>
    <p>
      {{ 'Donation-DESCRIPTION' | translate }}
    </p>
    <p class="sr-bold h5">{{ 'Donation-PLEASE_HELP_OUT' | translate }}</p>
    <div class="divider"></div>
    <div class="spacing-div col s12">
      <label for="donationAmount" class="sr-label">
        {{ 'Donation-HOW_MUCH_TO_DONATE' | translate }} {{ orgName }}:
      </label>
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !donateForm.controls['donationAmount'].valid && donateForm.controls['donationAmount'].touched
        }"
      >
        <i class="material-icons prefix">payments</i>
        <input
          id="donationAmount"
          type="text"
          materialize="characterCounter"
          length="15"
          maxlength="15"
          [formControl]="donateForm.controls['donationAmount']"
        />
        <div
          class="sr-error-area"
          *ngIf="!donateForm.controls['donationAmount'].valid && donateForm.controls['donationAmount'].touched"
        >
          <div *ngIf="donateForm.controls['donationAmount'].errors.pattern">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_USD' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="spacing-div col s12">
      <div class="sr-bold info">{{ 'Donation-NOTE' | translate }}</div>
      <!-- Email field -->
      <label class="sr-label" for="donatorEmail">
        {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
      </label>
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            !donateForm.controls['donatorEmail'].valid &&
            (donateForm.controls['donatorEmail'].touched || donateForm.controls['donatorEmail'].value.length)
        }"
      >
        <i class="material-icons prefix">email</i>
        <input
          id="donatorEmail"
          type="text"
          materialize="characterCounter"
          length="15"
          maxlength="64"
          [formControl]="donateForm.controls['donatorEmail']"
          [placeholder]="'UNIVERSAL-CONTACT_EMAIL' | translate"
        />
        <div
          class="sr-error-area"
          *ngIf="
            !donateForm.controls['donatorEmail'].valid &&
            (donateForm.controls['donatorEmail'].touched || donateForm.controls['donatorEmail'].value.length)
          "
        >
          <div *ngIf="donateForm.controls['donatorEmail'].errors.mailFormat">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="spacing-div col s12">
      <h5>
        {{ 'Donation-TIPPING_QUEST' | translate }}
        <span class="sr-required-mark">*</span>
      </h5>
      <p>{{ 'Donation-TIPPING_INFO' | translate }}</p>
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !donateForm.controls['tippingSr'].valid && donateForm.controls['tippingSr'].touched
        }"
      >
        <input
          id="tippingSr_yes"
          class="with-gap"
          type="radio"
          [formControl]="donateForm.controls['tippingSr']"
          (change)="tippingSr = true"
          [value]="true"
          (click)="onSubmit(orgId, donateUrl)"
        />
        <label for="tippingSr_yes">
          {{ 'UNIVERSAL-YES' | translate }}
        </label>
        <br />
        <input
          id="tippingSr_no"
          class="with-gap"
          type="radio"
          [formControl]="donateForm.controls['tippingSr']"
          (change)="tippingSr = false"
          [value]="false"
          (click)="onSubmit(orgId, donateUrl)"
        />
        <label for="tippingSr_no">
          {{ 'UNIVERSAL-NO' | translate }}
        </label>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="row">
    <div class="input-field col s6 offset-s3">
      <button
        class="waves-effect waves-light btn cyan darken-3 col s12"
        (click)="this.donateModalActions.emit({ action: 'modal', params: ['close'] })"
      >
        <i class="material-icons left">clear</i>
        <span class="hide-on-small-only">
          {{ 'DashboardIncomingMessagesComponent-MODAL_CLOSE' | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
