import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../common/config';
import { CurrencyCodeObject, Dictionary } from '../../models';

@Injectable()
export class CurrencyService {
  private CURRENCY_MAP_DATA: string = AppSettings.CURRENCY_MAP;
  private map: Dictionary<CurrencyCodeObject> = {};

  constructor(private http: HttpClient) {
    this.getCurrencyMapData().subscribe();
  }

  public getCurrencyMapData(): Observable<Dictionary<CurrencyCodeObject>> {
    if (Object.keys(this.map).length) {
      return from([this.map]);
    }
    return this.http.get<Dictionary<CurrencyCodeObject>>(this.CURRENCY_MAP_DATA).pipe(
      tap((map) => {
        this.map = map;
      })
    );
  }

  public getCurrencyObjForCode(key: string): Observable<CurrencyCodeObject> {
    let codeObj: CurrencyCodeObject;

    return this.getCurrencyMapData().pipe(
      catchError(() => {
        return from([null]);
      }),
      map((data: Dictionary<CurrencyCodeObject>) => {
        if (data && data.hasOwnProperty(key)) {
          codeObj = data[key];
          return codeObj;
        }
      })
    );
  }
}
