<div class="row">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12">
    <nav class="breadcrumb-nav">
      <div class="nav-wrapper">
        <div class="col s12 breadcrumb-div">
          <a routerLink="/" class="breadcrumb">
            {{ 'UNIVERSAL-NAVIGATION_HOME' | translate }}
          </a>
          <a class="breadcrumb">
            {{ location?.name }}
          </a>
        </div>
      </div>
    </nav>
    <div class="divider"></div>
  </div>
</div>

<div class="page-container">
  <div class="row">
    <div class="col s12 m12 l8">
      <div class="row">
        <div class="col s12" *ngIf="mergeLocation && mergeLocation.length; else noResponse">
          <div class="col s12 m12 l5">
            <div class="sr-bold black-text lighten-1">
              <h5>{{ location?.name }}</h5>
            </div>

            <div class="description-element">
              <span class="sr-bold">{{ 'COUNTRY_COMMON-DISASTER-BACKGROUND_LABEL' | translate }} : </span>
              <div class="sr-justify summary" [innerHtml]="location?.background"></div>
            </div>
          </div>
          <div class="col s12 m12 l7">
            <div class="row">
              <div class="col s12 card-text-wrapper sr-bold">
                <p class="no-padding">
                  {{ 'locationAboutComponent-MAP_INSTRUCTION' | translate }}
                </p>
                <input
                  type="checkbox"
                  class="filled-in"
                  id="filled-in-box"
                  [checked]="showAll"
                  (change)="showAll = !showAll"
                />
                <label for="filled-in-box"> {{ 'locationAboutComponent-SHOW_ALL' | translate }} </label>
              </div>
              <div class="col s12">
                <location-map
                  [source]="mergeLocation"
                  [country]="location?.name"
                  [multi]="true"
                  [editable]="false"
                  [showInfoWindow]="false"
                  [showAll]="showAll"
                >
                </location-map>
              </div>
              <div class="col s12">
                <ul materialize="collapsible" class="collapsible" data-collapsible="accordion">
                  <li>
                    <div class="collapsible-header sr-bold blue-text text-size custom-float">
                      <i class="material-icons expand-more">expand_more</i>
                      <i class="material-icons">location_on</i>
                      {{ 'locationAboutComponent-MAP-PIN-LIST' | translate }}
                    </div>
                    <div class="collapsible-body">
                      <div class="survey">
                        <div class="scrollable">
                          <ul>
                            <li *ngFor="let location of mergeLocation | unique: 'organizationName'">
                              {{ location.label }}. {{ location.organizationName }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noResponse>
          <div class="sr-bold black-text lighten-1">
            <h5>{{ location?.name }}</h5>
          </div>

          <div class="description-element">
            <span class="sr-bold">{{ 'COUNTRY_COMMON-DISASTER-BACKGROUND_LABEL' | translate }} : </span>
            <div class="sr-justify summary" [innerHtml]="location?.background"></div>
          </div>
        </ng-template>

        <div class="col s12">
          <span class="sr-bold">{{ 'COMMON_SEARCH-SERVICES_PROVIDED_TITLE' | translate }} : </span>
          <div class="sr-justify">
            <span *ngFor="let service of serviceList; let isLast = last">
              <span class="service-tag">
                <a
                  class="waves-effect waves-light btn cyan"
                  style="margin-right: 1%"
                  [ngClass]="{ 'darken-2': serviceFilterList.includes(service) }"
                  (click)="toggleServiceFilter(service)"
                  >{{ service }}</a
                >
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12 m8 l4 offset-m2">
      <div class="card org-card">
        <div class="card-content">
          <p class="center black-text sr-bold text-size">
            {{ 'COMMON_SEARCH-GROUPS_ACTIVE' | translate }}
          </p>
          <div class="divider"></div>
          <table class="centered">
            <thead class="groups-active-header">
              <tr>
                <th class="sr-bold">
                  {{ 'COMMON_SEARCH-CIVIL_SOCIETY' | translate }}
                </th>
                <th class="sr-bold">
                  {{ 'COMMON_SEARCH-INTL_NGO' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ csloCount }}
                </td>
                <td>
                  {{ ingoCount }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <share-buttons-custom [metadata]="location?.metadata"> </share-buttons-custom>
    </div>
  </div>

  <div class="row">
    <div class="col s12">
      <ul class="tabs" materialize="tabs">
        <li class="tab col s6 active">
          <a href="#organizations-list">
            {{ 'COMMON_SEARCH-ORGS' | translate }}
          </a>
        </li>

        <li class="tab col s6">
          <a href="#disasters-list">
            {{ 'COMMON_SEARCH-DISASTERS' | translate }}
          </a>
        </li>
      </ul>
      <div class="card left-column tab-content" id="organizations-list">
        <div class="card-content">
          <h6 class="sr-bold org-card-title">
            {{ 'LocationAboutComponent-LIST_OF_ORGS_IN_LOCATION' | translate: { locationName: location?.name } }}
          </h6>
          <div class="row s3 m3 valign-wrapper sr-filter-row">
            <label for="entries" class="my_select1">{{ 'UNIVERSAL-SHOW_TEXT' | translate }}</label>
            <select
              materialize="material_select"
              (change)="onItemsPerPageChange($event.target.value)"
              name="entries"
              id="entries"
              class="browser-default entries-select"
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <label for="entries" class="my_select1">{{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}</label>
          </div>
          <!-- Table for Organization Data -->
          <div class="grey lighten-3">
            <table class="centered responsive-table">
              <thead>
                <tr>
                  <th class="header-cell">Organization Name</th>
                  <th class="header-cell">Staff in Location</th>
                  <th class="header-cell hide-on-med-and-down">Services Provided</th>
                  <th class="header-cell">Overall Annual Budget in Location</th>
                  <th class="header-cell">Headquarters Location</th>
                  <th class="header-cell"></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  *ngFor="
                    let org of respondingOrgs
                      | orgServiceFilter: serviceFilterList
                      | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage };
                    let i = index
                  "
                >
                  <!-- Organization Name -->
                  <td class="align-top text-left-align org-name-td cell">
                    <a [routerLink]="['/org-location', org.id, location.countryId]" class="vertical-alignment">{{
                      org.name
                    }}</a>
                    <i
                      (click)="openModal(org.id)"
                      class="material-icons add-left-padding blue-text score-modal vertical-alignment"
                      >insert_chart</i
                    >
                  </td>

                  <!-- Staff Responding -->
                  <td class="valign-top cell">{{ org.staffCount }}</td>

                  <!-- Service Provided : show service(s) and its(their) progress -->
                  <td class="valign-top cell hide-on-med-and-down">
                    <!-- Service Name -->
                    <div *ngFor="let service of org.serviceListPreview">
                      <div>{{ service }}</div>
                    </div>
                    <div>
                      <a [routerLink]="['/org-location', org.id, location.countryId]">
                        {{ 'LocationAboutComponent-VIEW_MORE' | translate }}
                      </a>
                    </div>
                  </td>

                  <td class="cell">
                    {{
                      org.budget?.amount !== null
                        ? (org.budget?.amount | customCurrency: currency?.map[org.budget?.currency])
                        : '&nbsp;'
                    }}
                  </td>
                  <td class="cell">{{ org.hqLocation }}</td>

                  <!-- Donate button -->

                  <td class="valign-top cell">
                    <a
                      class="btn blue lighten-1 waves-effect waves-light"
                      (click)="openDonateModal(org.name, org.id, org.donateUrl)"
                      target="_blank"
                      [href]="org.donateUrl | linkHttp"
                      [ngClass]="{ disabled: org.donateButtonDisabled || org.donateUrl === undefined || org.donateUrl === '' || org.donateUrl === null }"
                    >
                      {{ 'DisasterAboutComponent-DONATE' | translate }}
                    </a>
                    <div>
                      <a [routerLink]="['/org-location', org.id, location.countryId]">
                        {{ 'LocationAboutComponent-LEARN_MORE' | translate }}
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              id="donateModal"
              class="modal modal-fixed-footer"
              materialize="modal"
              [materializeParams]="[{ dismissible: true }]"
              [materializeActions]="donateModalActions"
            >
              <donation-modal
                [orgName]="oName"
                [orgId]="oID"
                [donateUrl]="oDonateUrl"
                [donateModalActions]="donateModalActions"
                [tippingModalActions]="tippingModalActions"
              >
              </donation-modal>
            </div>
            <!--Tipping Modal-->
            <div
              id="tippingModal"
              class="modal modal-fixed-footer"
              materialize="modal"
              [materializeParams]="[{ dismissible: true }]"
              [materializeActions]="tippingModalActions"
            >
              <givelively-donation
                [donateModalActions]="donateModalActions"
                [tippingModalActions]="tippingModalActions"
                [tippingText]="true"
              >
              </givelively-donation>
            </div>
          </div>
        </div>
        <div class="row s12 m12">
          <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
        </div>
      </div>

      <div class="card left-column tab-content" id="disasters-list">
        <div class="card-content">
          <h6 class="sr-bold org-card-title">
            {{ 'LocationAboutComponent-LIST_OF_DISASTERS_IN_LOCATION' | translate: { locationName: location?.name } }}
          </h6>

          <ul class="collection tab-content">
            <li class="collection-item list-item" *ngFor="let disaster of disasters | unique: 'disasterId'">
              <div class="row">
                <div class="col s12 m2 l2">
                  <img
                    [alt]="disaster?.name"
                    [src]="disaster?.logo ? disaster?.logo?.uri : 'assets/images/disaster-default.png'"
                    class="responsive-img disaster-img"
                  />
                </div>
                <div class="col s12 m10 l10">
                  <div class="blue-text lighten-1 sr-link">
                    <a [routerLink]="['/disaster', disaster.disasterId]">
                      {{ disaster?.name }}
                    </a>
                  </div>
                  <div class="sr-justify" [innerHTML]="disaster?.summary"></div>
                  <div class="sr-link list-link">
                    <a [routerLink]="['/disaster', disaster.disasterId]">
                      {{ 'LocationAboutComponent-LEARN_MORE' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<score-by-location-modal [modalActions]="sortingActions"></score-by-location-modal>
