<div class="container">
  <h2>{{ 'Donation_Thanks-THANK_YOU' | translate }}</h2>
  <p>{{ 'Donation_Thanks-GRATITUDE_TEXT' | translate }}</p>
  <p>{{ 'Donation_Thanks-EMAIL_TEXT' | translate }}</p>
  <p>{{ 'Donation_Thanks-GRATITUDE_TEXT_2' | translate }}</p>
  <a class="subscribe-button btn yellow black-text" [routerLink]="['/subscribe']">
    {{ 'Donation_Thanks-SUBSCRIBE_LINK' | translate }}
  </a>

  <br />

  <p>{{ 'Donation_Thanks-SOCIAL_MEDIA' | translate }}</p>
  <div class="row">
    <ul class="social grey-text left-align row s12 m12 l3 push-l9">
      <li class="social-icons">
        <a href="https://www.facebook.com/DisasterAccountability" target="_blank">
          <img src="assets/images/facebook-icon-footer.png" alt="facebook" />
        </a>
      </li>
      <li class="social-icons">
        <a href="https://twitter.com/disasteraccount" target="_blank">
          <img src="assets/images/twitter-icon-footer.png" alt="twitter" />
        </a>
      </li>
      <li class="social-icons">
        <a href="https://www.linkedin.com/company/disaster-accountability-project" target="_blank">
          <img src="assets/images/linkedin-icon-footer.png" alt="linkedin" />
        </a>
      </li>
    </ul>
  </div>
</div>
