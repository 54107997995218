<div class="ts-container">
  <span class="timestamp-text" [ngClass]="colorStatusClass[status]">
    {{ statusText[status] | translate }}
  </span>
  <ng-template [ngIf]="timestamp">
    <i
      class="tiny material-icons"
      matTooltip="{{ 'COMMON_SEARCH-UPDATED_ON' | translate }} {{
        timestamp | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT
      }}"
      [ngClass]="colorStatusClass[status]"
    >
      calendar_today
    </i>
  </ng-template>
</div>
