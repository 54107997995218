import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FAQService } from './faq.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  providers: [FAQService],
})
export class FrequentlyAskedQuestionsComponent implements OnInit {
  public faqType: 'donor' | 'media' | 'survivor' | 'org' | 'gov';

  public currentYear = new Date().getFullYear();

  constructor(private route: ActivatedRoute, private faqService: FAQService) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      // hack to switch default 'active' (shown) collapsible body, when route is changed when this
      // component is already loaded. basically this deletes and stamps out again the collapsible
      this.faqType = null;

      setTimeout(() => (this.faqType = params['id']), 0);
    });
  }
}
