<div class="card">
  <div class="card-content black-text">
    <div class="card-text-wrapper grey lighten-3">
      <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
        <thead>
          <tr class="sr-base-table-row">
            <th>
              Survey Name
              <i class="tiny material-icons">swap_vert</i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="authService.isOrgGeneral() && gobPending">
            <td>
              <a
                [routerLink]="['/dashboard/']"
                [queryParams]="{
                  country: 3,
                  organization: authService.getProfile().organizationId,
                  surveyType: 'GOB',
                  stepNumber: 8,
                  questionNumber: 1
                }"
              >
                {{ 'SurveyNotificationsComponent_GENERAL_ORGANIZATION' | translate }}</a
              >
            </td>
          </tr>
          <tr *ngFor="let lssEntry of surveys">
            <td>
              <a
                class="sr-link"
                [routerLink]="['/dashboard/']"
                [queryParams]="{
                  country: lssEntry.countryId,
                  organization: authService.getProfile().organizationId,
                  surveyType: 'LSS',
                  stepNumber: 8,
                  questionNumber: 1
                }"
              >
                {{ lssEntry.countryName }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
