import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'survey-step-progress-bar',
  templateUrl: 'survey-step-progress-bar.component.html',
  styleUrls: ['survey-step-progress-bar.component.css'],
})
export class SurveyStepProgressBarComponent implements OnInit {
  @Input() currentStepIndex: number = 0;
  @Input() totalSteps: number = 0;
  @Input() nextStep: EventEmitter<number> = new EventEmitter<number>();

  @Output() stepTabClicked: EventEmitter<number> = new EventEmitter();
  @Output() errorModal: EventEmitter<string> = new EventEmitter();
  steps: number[];
  constructor() {}

  ngOnInit() {
    this.steps = Array(this.totalSteps)
      .fill(0)
      .map((x, i) => i);
  }

  onSelectStep(step: number) {
    this.stepTabClicked.emit(step);
    if (this.currentStepIndex === -1) {
      this.errorModal.emit();
      return;
    }
  }
}
