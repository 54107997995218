import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { LeadingTrailingWhiteSpaceValidator } from './leadingTrailingWhiteSpaceValidator';
import { NameValidator } from './nameValidator';
import { OrgValidator } from './orgValidator';

export const PASSWORD_CONTROL_NAME = 'password';
export const CONFIRM_PASSWORD_CONTROL_NAME = 'passwordConfirmation';

export interface PasswordMismatchError {
  passwordMismatch: boolean;
}

/**
 *
 * @param formGroup has two formcontrols with names PASSWORD_CONTROL_NAME and CONFIRM_PASSWORD_CONTROL_NAME
 * @returns {null}
 * @constructor
 */

export function passwordMismatchValidator(formGroup: FormGroup) {
  if (
    isNullOrUndefined(formGroup.get(PASSWORD_CONTROL_NAME)) ||
    isNullOrUndefined(formGroup.get(CONFIRM_PASSWORD_CONTROL_NAME))
  ) {
    return null;
  }

  const passwordsMatch: boolean =
    formGroup.get(PASSWORD_CONTROL_NAME).value === formGroup.get(CONFIRM_PASSWORD_CONTROL_NAME).value;

  if (passwordsMatch) {
    return null;
  }

  return {
    passwordMismatch: true,
  };
}

export const ltwsFormatWithLen = (len: number) => [
  Validators.maxLength(len),
  LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
];

function optionalOrgFormatValidators(len: number): ValidatorFn[] {
  return [OrgValidator.orgFormat, ...ltwsFormatWithLen(len)];
}

export const nameValidators: ValidatorFn[] = [Validators.required, NameValidator.nameFormat, ...ltwsFormatWithLen(32)];

export const orgFormatValidators: ValidatorFn[] = [Validators.required, ...optionalOrgFormatValidators(128)];

export const membershipFormatValidators: ValidatorFn[] = optionalOrgFormatValidators(128);

export const partnerFormatValidators: ValidatorFn[] = optionalOrgFormatValidators(256);
