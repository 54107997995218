export interface SurveyNotification {
  surveyName?: string;
  messageSurveyType?: SurveyType;
  surveyType?: string;
  surveyTypeFull?: string;
  organizationId?: number;
  organizationName?: string;
  countryId?: number;
  countryName?: string;
  disasterId?: number;
  outdatedSurveyStatus?: string;
  new?: boolean;
  stepNumber?: number;
  questionNumber?: number;
  order?: number;
  status?: string;
  lastUpdateDate?: number;
  valueEmpty?: boolean;
}
export enum SurveyType {
  LSS = 1,
  GOB,
  DSS,
}
