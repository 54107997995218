import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { CurrencyCodes } from 'app/models';
import { AppSettings } from '../../../../../common/config';
import { handleErrors } from '../../../../../common/error';
import { DocumentService } from '../../../../../sharedServices/documentService/document.service';
import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';
import { DisasterSpecificSurveyService } from '../disaster-specific-survey.service';
import { DSSStepComponent } from '../dss-step/dss-step.component';
import { OnlyWhiteSpaceValidator } from 'app/validators/onlyWhiteSpaceValidator';

@Component({
  selector: 'dss-step4',
  templateUrl: './dss-step4.component.html',
  styleUrls: ['./dss-step4.component.css', '../dss-steps-common.css', '../../survey-table-common.css'],
  providers: [],
})
export class DSSStep4Component extends DSSStepComponent {
  currencyCodeList: CurrencyCodes[];

  solicitDonationForm: FormGroup;
  raisedFundForm: FormGroup;
  spentFundForm: FormGroup;
  earnedInterestForm: FormGroup;
  earnedInterestSpentForm: FormGroup;
  transferredFundForm: FormGroup;
  receivingOrgArray: FormArray;

  constructor(
    fb: FormBuilder,
    private surveyManager: DisasterSpecificSurveyManager,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private documentService: DocumentService,
    private surveyService: DisasterSpecificSurveyService
  ) {
    super(surveyManager);

    this.invalidOnInit = {
      singles: {
        raisedFund: false,
        spentFund: false,
        earnedInterest: false,
      },
      arrays: {
        fundTransferList: [],
      },
    };

    this.getCurrencyCodeList();

    this.solicitDonationForm = <FormGroup>this.surveyManager.mainForm.controls['solicitDonation'];
    this.raisedFundForm = <FormGroup>this.surveyManager.mainForm.controls['raisedFund'];
    this.spentFundForm = <FormGroup>this.surveyManager.mainForm.controls['spentFund'];
    this.earnedInterestForm = <FormGroup>this.surveyManager.mainForm.controls['earnedInterest'];
    this.earnedInterestSpentForm = <FormGroup>this.surveyManager.mainForm.controls['earnedInterestSpent'];
    this.transferredFundForm = <FormGroup>this.surveyManager.mainForm.controls['transferredFund'];

    this.earnedInterestSpentForm.get('value').setValidators(OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat);
    this.earnedInterestForm.updateValueAndValidity();

    // Subscribe to change to see if fund value has been entered,
    // if yes then assign true to "hasValue"
    this.raisedFundForm.valueChanges.subscribe((event) => {
      this.setHasValueForFundSection(this.raisedFundForm, event);
    });

    this.spentFundForm.valueChanges.subscribe((event) => {
      this.setHasValueForFundSection(this.spentFundForm, event);
    });

    this.earnedInterestForm.valueChanges.subscribe((event) => {
      this.setHasValueForFundSection(this.earnedInterestForm, event);
    });

    this.solicitDonationForm
      .get('value')
      .get('isNotSolicitingDonation')
      .valueChanges.subscribe((event) => {
        if (event) {
          this.solicitDonationForm.get('value').get('isCurrentlySolicitingDonation').setValue(null);

          this.solicitDonationForm.get('value').get('startSolicitDonationDate').setValue(null);

          this.solicitDonationForm.get('value').get('stopSolicitDonationDate').setValue(null);
        } else {
          // Set this to null since the back end DSS submit API expects this to be null
          this.solicitDonationForm.get('value').get('isNotSolicitingDonation').setValue(null, { emitEvent: false });
        }
      });

    this.solicitDonationForm
      .get('value')
      .get('isCurrentlySolicitingDonation')
      .valueChanges.subscribe((event) => {
        if (event) {
          // Set this to null since the back end DSS submit API expects this to be null
          this.solicitDonationForm.get('value').get('isNotSolicitingDonation').setValue(null, { emitEvent: false });

          // Empty stopSolicitDonationDate
          this.solicitDonationForm.get('value').get('stopSolicitDonationDate').setValue(null, { emitEvent: false });
        }
      });

    this.transferredFundForm
      .get('value')
      .get('hasValue')
      .valueChanges.subscribe((event) => {
        this.handleTransferredFundHasValueChange(event);
      });
  }

  onFileSelected(event: any, formControl: FormControl): void {
    const targetFile = event.target.files[0];

    if (!(targetFile instanceof File)) {
      return;
    }

    if (targetFile.size > AppSettings.MAXIMUM_DOCUMENT_UPLOAD_SIZE) {
      this.notificationsService.error(
        this.translateService.instant('DisasterSpecificSurveysTableComponent-TITLE'),
        this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );
      return;
    }

    if (AppSettings.DEFAULT_ALLOWED_DOCUMENT_TYPES.indexOf(targetFile.type) === -1) {
      this.notificationsService.error(
        this.translateService.instant('DisasterSpecificSurveysTableComponent-TITLE'),
        this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );
      return;
    }

    this.documentService.uploadDocument(targetFile).subscribe(
      (success) => {
        formControl.setValue(success.fileId);
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'DisasterSpecificSurveysTableComponent-TITLE'
        );
      }
    );
  }

  onDeleteRecievingOrg(index: number) {
    // can"t delete last item
    if (this.receivingOrgArray.controls.length === 1) {
      return;
    }

    this.receivingOrgArray.removeAt(index);
  }

  onAddRecievingOrg(index: number) {
    this.addRecievingOrgLine(this.receivingOrgArray);
  }

  assignDataToForm(): void {
    if (!this.surveyData) {
      return;
    }

    if (this.surveyData.solicitDonation) {
      this.surveyManager.mainForm.controls['solicitDonation'].patchValue(this.surveyData.solicitDonation);
    } else {
      this.surveyManager.mainForm.controls['solicitDonation'].reset();
    }

    if (this.surveyData.raisedFund) {
      this.surveyManager.mainForm.controls['raisedFund'].patchValue(this.surveyData.raisedFund);
    } else {
      this.surveyManager.mainForm.controls['raisedFund'].reset({
        value: {
          currency: 'USD',
        },
      });
    }

    if (this.surveyData.spentFund) {
      this.surveyManager.mainForm.controls['spentFund'].patchValue(this.surveyData.spentFund);
    } else {
      this.surveyManager.mainForm.controls['spentFund'].reset({
        value: {
          currency: 'USD',
        },
      });
    }

    if (this.surveyData.earnedInterest) {
      this.surveyManager.mainForm.controls['earnedInterest'].patchValue(this.surveyData.earnedInterest, {
        emitEvent: false,
      });
    } else {
      this.surveyManager.mainForm.controls['earnedInterest'].reset({
        value: {
          currency: 'USD',
        },
      });
    }

    if (this.surveyData.earnedInterestSpent) {
      this.surveyManager.mainForm.controls['earnedInterestSpent'].patchValue(this.surveyData.earnedInterestSpent, {
        emitEvent: false,
      });
    } else {
      this.surveyManager.mainForm.controls['earnedInterestSpent'].reset();
    }

    this.setupTransferredFundControls();
    this.setInitialErrorVisibility();
  }

  setupTransferredFundControls() {
    const valueFormGroup = <FormGroup>this.transferredFundForm.controls['value'];

    if (!this.surveyData.transferredFund) {
      this.receivingOrgArray = <FormArray>valueFormGroup.controls['fundTransferList'];
      return;
    }

    this.transferredFundForm.get('lastUpdatedDate').setValue(this.surveyData.transferredFund.lastUpdatedDate);
    this.transferredFundForm.get('lastUpdatedUser').setValue(this.surveyData.transferredFund.lastUpdatedUser);
    this.transferredFundForm.get('updatedBySRAdmin').setValue(this.surveyData.transferredFund.updatedBySRAdmin);

    this.transferredFundForm.get('value').get('hasValue').setValue(this.surveyData.transferredFund.value.hasValue);

    this.transferredFundForm.get('value').get('fileId').setValue(this.surveyData.transferredFund.value.fileId);

    const receivingOrgs = <FormArray>valueFormGroup.controls['fundTransferList'];
    // clear already existing items from array
    // tslint:disable-next-line:no-increment-decrement
    for (let i = receivingOrgs.length; i >= 0; i--) {
      receivingOrgs.removeAt(i);
    }

    if (
      this.surveyData.transferredFund.value.fundTransferList &&
      this.surveyData.transferredFund.value.fundTransferList.length
    ) {
      for (const eachTransfer of this.surveyData.transferredFund.value.fundTransferList) {
        const fundTransferLine: FormGroup = this.surveyManager.getFundTransferLineSection();
        fundTransferLine.setValue({
          fund: eachTransfer.fund ? eachTransfer.fund : null,
          receivingOrganization: eachTransfer.receivingOrganization,
          currency: eachTransfer.currency ? eachTransfer.currency : 'USD',
        });
        receivingOrgs.push(fundTransferLine);
      }

      this.receivingOrgArray = <FormArray>valueFormGroup.controls['fundTransferList'];
    } else {
      this.receivingOrgArray = <FormArray>valueFormGroup.controls['fundTransferList'];
      this.addRecievingOrgLine(this.receivingOrgArray);
    }
  }

  addRecievingOrgLine(array: FormArray) {
    const fundTransferLine: FormGroup = this.surveyManager.getFundTransferLineSection();
    fundTransferLine.setValue({
      fund: null,
      receivingOrganization: null,
      currency: 'USD',
    });
    array.push(fundTransferLine);
  }

  setHasValueForFundSection(form: FormGroup, val: any) {
    if (!form || !val) {
      return;
    }

    const valueGroup: FormGroup = <FormGroup>form.controls['value'];

    if (!valueGroup || !val.value) {
      return;
    }

    if (typeof val.value.fund === 'number') {
      valueGroup.controls['hasValue'].setValue(true, { emitEvent: false });
    } else {
      valueGroup.controls['hasValue'].setValue(null, { emitEvent: false });
    }
  }

  handleTransferredFundHasValueChange(val: boolean) {
    if (this.receivingOrgArray) {
      // if hasValue is false empty receiving org data
      if (!val) {
        // below if is only to cover the scenraio where user swicths from Yes to No to Yes on the UI
        if (this.receivingOrgArray.length === 1) {
          // clear already existing items from array
          // tslint:disable-next-line:no-increment-decrement
          for (let i = this.receivingOrgArray.length; i >= 0; i--) {
            this.receivingOrgArray.removeAt(i);
          }
        }
      } else {
        if (this.receivingOrgArray.length === 0) {
          this.addRecievingOrgLine(this.receivingOrgArray);
        }
      }
    }
  }

  // get list of currency codes
  getCurrencyCodeList() {
    this.surveyService.getCurrencyCodes().subscribe((res: CurrencyCodes) => {
      this.currencyCodeList = res.responseData.currency_list;
      this.currencyCodeList.sort();
    });
  }

  private setInitialErrorVisibility(): void {
    if (this.surveyData.raisedFund) {
      this.invalidOnInit.singles.raisedFund = !this.raisedFundForm.get('value').valid;
    }
    if (this.surveyData.spentFund) {
      this.invalidOnInit.singles.spentFund = !this.spentFundForm.get('value').valid;
    }
    if (this.surveyData.earnedInterest) {
      this.invalidOnInit.singles.earnedInterest = !this.earnedInterestForm.get('value').valid;
    }
    if (
      this.surveyData.transferredFund &&
      this.receivingOrgArray &&
      this.receivingOrgArray.controls &&
      this.receivingOrgArray.controls.length
    ) {
      this.invalidOnInit.arrays.fundTransferList = this.receivingOrgArray.controls.map(
        (control: AbstractControl) => !control.get('fund').valid
      );
    }
  }
}
