import { Component } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';

import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';
import { DSSStepComponent } from '../dss-step/dss-step.component';
import { OnlyWhiteSpaceValidator } from 'app/validators/onlyWhiteSpaceValidator';

@Component({
  selector: 'dss-step1',
  templateUrl: './dss-step1.component.html',
  styleUrls: ['./dss-step1.component.css', '../dss-steps-common.css', '../../survey-table-common.css'],
  providers: [],
})
export class DSSStep1Component extends DSSStepComponent {
  organizationRespondingToDisasterForm: FormGroup;
  reliefRecoveryServiceForm: FormGroup;
  activityHistoryForm: FormGroup;
  disasterStageOfWorkForm: FormGroup;
  stakeholderForm: FormGroup;
  orgResponseVideoUrlForm: FormGroup;

  constructor(fb: FormBuilder, private surveyManager: DisasterSpecificSurveyManager) {
    super(surveyManager);

    this.invalidOnInit = {
      singles: {
        orgResponseVideoUrl: false,
      },
    };

    this.organizationRespondingToDisasterForm = <FormGroup>(
      this.surveyManager.mainForm.controls['organizationRespondingToDisaster']
    );
    this.reliefRecoveryServiceForm = <FormGroup>this.surveyManager.mainForm.controls['reliefRecoveryService'];
    this.activityHistoryForm = <FormGroup>this.surveyManager.mainForm.controls['activityHistory'];
    this.disasterStageOfWorkForm = <FormGroup>this.surveyManager.mainForm.controls['disasterStageOfWork'];
    this.stakeholderForm = <FormGroup>this.surveyManager.mainForm.controls['stakeholder'];
    this.orgResponseVideoUrlForm = <FormGroup>this.surveyManager.mainForm.controls['orgResponseVideoUrl'];

    this.stakeholderForm.get('value').setValidators(OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat);
    this.stakeholderForm.get('value').updateValueAndValidity();

    this.reliefRecoveryServiceForm
      .get('value')
      .get('isNotProvidingService')
      .valueChanges.subscribe((event) => {
        if (event) {
          this.reliefRecoveryServiceForm.get('value').get('isCurrentlyProvidingService').setValue(null);

          this.reliefRecoveryServiceForm.get('value').get('startServiceDate').setValue(null);

          this.reliefRecoveryServiceForm.get('value').get('stopServiceDate').setValue(null);
        } else {
          // Set this to null since the back end DSS submit API expects this to be null
          this.reliefRecoveryServiceForm.get('value').get('isNotProvidingService').setValue(null, { emitEvent: false });
        }
      });

    this.reliefRecoveryServiceForm
      .get('value')
      .get('isCurrentlyProvidingService')
      .valueChanges.subscribe((event) => {
        if (event) {
          // Set this to null since the back end DSS submit API expects this to be null
          this.reliefRecoveryServiceForm.get('value').get('isNotProvidingService').setValue(null, { emitEvent: false });

          // Empty stopServiceDate
          this.reliefRecoveryServiceForm.get('value').get('stopServiceDate').setValue(null, { emitEvent: false });
        }
      });
    this.activityHistoryForm.get('value').setValidators(OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat);
    this.activityHistoryForm.updateValueAndValidity();
  }

  assignDataToForm(): void {
    if (!this.surveyData) {
      return;
    }

    this.organizationRespondingToDisasterForm.controls['value'].setValue(
      !this.surveyData.organizationRespondingToDisaster,
      { emitEvent: false }
    );

    if (this.surveyData.reliefRecoveryService) {
      this.surveyManager.mainForm.controls['reliefRecoveryService'].patchValue(this.surveyData.reliefRecoveryService, {
        emitEvent: false,
      });
    } else {
      this.surveyManager.mainForm.controls['reliefRecoveryService'].reset();
    }

    if (this.surveyData.activityHistory) {
      this.surveyManager.mainForm.controls['activityHistory'].patchValue(this.surveyData.activityHistory, {
        emitEvent: false,
      });
    } else {
      this.surveyManager.mainForm.controls['activityHistory'].reset();
    }

    if (this.surveyData.disasterStageOfWork) {
      this.surveyManager.mainForm.controls['disasterStageOfWork'].patchValue(this.surveyData.disasterStageOfWork, {
        emitEvent: false,
      });
    } else {
      this.surveyManager.mainForm.controls['disasterStageOfWork'].reset();
    }

    if (this.surveyData.stakeholder) {
      this.surveyManager.mainForm.controls['stakeholder'].patchValue(this.surveyData.stakeholder, { emitEvent: false });
    } else {
      this.surveyManager.mainForm.controls['stakeholder'].reset();
    }

    if (this.surveyData.orgResponseVideoUrl) {
      this.surveyManager.mainForm.controls['orgResponseVideoUrl'].patchValue(this.surveyData.orgResponseVideoUrl, {
        emitEvent: false,
      });
    } else {
      this.surveyManager.mainForm.controls['orgResponseVideoUrl'].reset();
    }
    this.setInitialErrorVisibility();
  }

  private setInitialErrorVisibility(): void {
    if (this.surveyData.orgResponseVideoUrl) {
      this.invalidOnInit.singles.orgResponseVideoUrl = !this.orgResponseVideoUrlForm.get('value').valid;
    }
  }
}
