<div *ngIf="formElement?.lastUpdated" class="col s12 sub-input-field-prefilled updated-mark">
  <input
    [formControl]="formElement.markAsUpdatedControl"
    [id]="'last-updated-metadata-' + id"
    [attr.disabled]="isDisabled"
    type="checkbox"
  />
  <label class="padding" [for]="'last-updated-metadata-' + id">
    {{ 'UNIVERSAL-SURVEY_MARK_AS_UPDATED' | translate }}
  </label>

  <p class="change-margin" *ngIf="formElement.updatedBySRAdmin">
    {{
      'UNIVERSAL-SURVEY_LAST_UPDATED_BY_SR_ADMIN'
        | translate: { when: formElement.lastUpdated | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }
    }}
  </p>
  <p class="change-margin" *ngIf="!formElement.updatedBySRAdmin">
    {{
      'UNIVERSAL-SURVEY_LAST_UPDATED_AND_WHO'
        | translate
          : {
              who: formElement.lastUpdatedUser.email,
              when: formElement.lastUpdated | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT
            }
    }}
  </p>
</div>
