import { FormArray, FormGroup, FormControl } from '@angular/forms';

export class SolicitingDonationValidator {
  /**
   * tests data for business logic
   * @param control
   * @returns {null}
   */

  public static validateData(controlGroup: FormGroup) {
    if (!controlGroup) {
      return null;
    }

    // return as valid if the dates are NOT yet valid
    // seems contradicotry but we want these validations to kick in after
    // valid dates have been entered
    if (!controlGroup.get('startSolicitDonationDate').valid || !controlGroup.get('stopSolicitDonationDate').valid) {
      return null;
    }

    const isNotSolicitingDonation = controlGroup.get('isNotSolicitingDonation').value;
    const isCurrentlySolicitingDonation = controlGroup.get('isCurrentlySolicitingDonation').value;
    let startSolicitDonationDate = controlGroup.get('startSolicitDonationDate').value;
    let stopSolicitDonationDate = controlGroup.get('stopSolicitDonationDate').value;

    startSolicitDonationDate = startSolicitDonationDate === '' ? null : startSolicitDonationDate;
    stopSolicitDonationDate = stopSolicitDonationDate === '' ? null : stopSolicitDonationDate;

    if (
      isNotSolicitingDonation &&
      (isCurrentlySolicitingDonation !== null || startSolicitDonationDate !== null || stopSolicitDonationDate !== null)
    ) {
      return { isNotSolicitingDonation: true };
    }

    if (!isNotSolicitingDonation) {
      if (isCurrentlySolicitingDonation && startSolicitDonationDate === null) {
        return { mustHaveStartDate: true };
      }

      if (isCurrentlySolicitingDonation && stopSolicitDonationDate !== null) {
        return { canNotHaveStopDate: true };
      }

      if (
        isCurrentlySolicitingDonation !== null &&
        !isCurrentlySolicitingDonation &&
        (startSolicitDonationDate === null || stopSolicitDonationDate === null)
      ) {
        return { mustHaveBothDates: true };
      }

      if (startSolicitDonationDate !== null && stopSolicitDonationDate !== null) {
        const start = Date.parse(startSolicitDonationDate);
        const stop = Date.parse(stopSolicitDonationDate);

        if (start > stop) {
          return { startDateCanNotBeAfterStop: true };
        }
      }
    }

    return null;
  }
}
