<div class="row">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12 my_btns">
    <a [routerLink]="['create']" class="waves-effect waves-light btn cyan" role="button">
      <i class="material-icons left">add</i>
      {{ 'DashboardCountryBaseComponent-ADD_BUTTON' | translate }}
    </a>
    <div class="right">
      <a class="waves-effect waves-light btn cyan">{{ 'UNIVERSAL-NAVIGATION_EXPORT' | translate }}</a>
      <a class="waves-effect waves-light btn cyan">{{ 'UNIVERSAL-NAVIGATION_PRINT' | translate }}</a>
    </div>
  </div>
</div>

<div class="row">
  <div class="col s12 m12">
    <div>
      <div class="col s3 m3 left valign-wrapper sr-filter-row">
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-SHOW_TEXT' | translate }}</label>
        <select
          (change)="onItemsPerPageChange($event.target.value)"
          name="entries"
          id="entries"
          class="browser-default my_select2"
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
        </select>
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}</label>
      </div>
      <div class="col s4 m2 right valign-wrapper">
        <div class="input-field search-input">
          <input
            [ngModel]="filterArg"
            (ngModelChange)="selectedPage = 0; filterArg = $event"
            class="validate"
            id="search"
            type="text"
          />
          <label class="grey-text" for="search">{{ 'UNIVERSAL-SEARCH' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col s12 m12">
  <div class="card">
    <div class="card-content black-text">
      <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
        <thead>
          <tr class="sr-base-table-row">
            <th data-field="name" class="header-cell names sr-link" (click)="setSortedField('name')">
              {{ 'COUNTRY_COMMON-NAME_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              data-field="abbreviation"
              class="abbreviations hide-on-small-only sr-link"
              (click)="setSortedField('abbreviation')"
            >
              {{ 'COUNTRY_COMMON-ABBR_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              data-field="background"
              class="header-cell backgrounds hide-on-small-only sr-link"
              (click)="setSortedField('background')"
            >
              {{ 'COUNTRY_COMMON-DISASTER-BACKGROUND_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th colspan="3" data-field="action" class="header-cell actions action-header-cell">
              {{ 'UNIVERSAL-TABLE_COLUMN_ACTIONS' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let country of countries
                | genericSearchFilterPipe: filterArg:['name', 'countryCode', 'abbreviation']
                | genericSortPipe: sortField:sortDescending
                | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
            "
          >
            <td class="cell-value names">{{ country.name }}</td>
            <td class="cell-value abbreviations hide-on-small-only">{{ country.abbreviation }}</td>
            <td class="cell-value backgrounds hide-on-small-only" [innerHTML]="country.background"></td>
            <td class="cell-action-icon">
              <a [routerLink]="['view', country.countryId]" class="waves-effect waves-light btn-floating cyan">
                <i class="material-icons left">visibility</i>
              </a>
            </td>
            <td class="cell-action-icon">
              <a [routerLink]="['edit', country.countryId]" class="waves-effect waves-light btn-floating cyan">
                <i class="material-icons left">mode_edit</i>
              </a>
            </td>
            <td class="cell-action-icon">
              <a class="waves-effect waves-light btn-floating cyan disabled">
                <i class="material-icons left">delete</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col s12 m12">
    <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
  </div>
</div>
