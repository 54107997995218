import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SREvent } from '../../../../../../models';

@Component({
  selector: 'gob-user-management',
  templateUrl: './gob-user-management.component.html',
})
export class GOBUserManagement implements OnInit {
  public currentState;
  public payload;
  @Input() organizationId;
  @Input() parentEvent$: Observable<SREvent>;
  @Output() status: EventEmitter<SREvent> = new EventEmitter<SREvent>();
  constructor() {
    this.currentState = {
      general_view: true,
      add_user: false,
      edit: false,
      view: false,
    };
  }

  ngOnInit() {
    if (this.parentEvent$) {
      this.parentEvent$.subscribe((event: SREvent) => this.handleAction(event));
    }
  }

  handleAction(action: SREvent) {
    if (Object.keys(this.currentState).includes(action.type)) {
      const newState = Object.assign(this.currentState);

      for (const id in newState) {
        if (newState.hasOwnProperty(id)) {
          if (id.toString() === action.type) {
            newState[id] = true;
            this.payload = action.payload;
          } else {
            newState[id] = false;
          }
        }
      }

      this.currentState = Object.assign(newState);
    }

    if (['general_view', 'view'].includes(action.type)) {
      this.status.emit({ type: 'no_form' });
    }

    if (action.type === 'add_user_is_form_empty' && action.hasOwnProperty('payload')) {
      this.status.emit({ type: 'is_form_empty', payload: action.payload });
    }
  }
}
