<!-- to enable a button, include the desired button's name into the binding list -->
<!--   e.g. facebook and twitter buttons are currently enabled -->

<!-- here is the list of supported buttons:
  [twitter]
  [facebook]
  [linkedIn]
  [tumblr]
  [google]
  [pinterest]
  [stumbleUpOn]
  [reddit]
  [whatsApp]
-->

<share-buttons [theme]="'material-dark'" [include]="['facebook', 'twitter']" [title]="metadata"> </share-buttons>
