<div class="row">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12 my_btns">
    <a [routerLink]="['create']" class="waves-effect waves-light btn cyan" role="button">
      <i class="material-icons left">add</i>
      {{ 'DashboardServiceBaseComponent-ADD_BUTTON' | translate }}
    </a>
    <div class="right">
      <a class="waves-effect waves-light btn cyan">{{ 'UNIVERSAL-NAVIGATION_EXPORT' | translate }}</a>
      <a class="waves-effect waves-light btn cyan">{{ 'UNIVERSAL-NAVIGATION_PRINT' | translate }}</a>
    </div>
  </div>
</div>

<div class="row">
  <div class="col s12 m12 sr-filter-row">
    <div>
      <div class="col s3 m3 left valign-wrapper sr-filter-row">
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-SHOW_TEXT' | translate }}</label>
        <select
          materialize="material_select"
          (change)="onItemsPerPageChange($event.target.value)"
          name="entries"
          id="entries"
          class="browser-default my_select2"
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
        </select>
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}</label>
      </div>
      <div class="col s4 m2 right valign-wrapper">
        <div class="input-field search-input">
          <input
            [ngModel]="filterArg"
            (ngModelChange)="selectedPage = 0; filterArg = $event"
            class="validate"
            id="search"
            type="text"
          />
          <label class="grey-text" for="search">{{ 'UNIVERSAL-SEARCH' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col s12 m12">
  <div class="card">
    <div class="card-content black-text">
      <table class="grey lighten-3 table-wrapper striped sr-custom-table-row fixed-layout-table">
        <thead>
          <tr class="sr-base-table-row">
            <th data-field="name" class="header-cell name sr-link" (click)="setSortedField('name')">
              {{ 'SERVICE_COMMON-NAME_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              data-field="summary"
              class="header-cell summary hide-on-small-only sr-link"
              (click)="setSortedField('summary')"
            >
              {{ 'SERVICE_COMMON-SUMMARY_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th data-field="action" colspan="3" class="header-cell actions action-header-cell">
              {{ 'UNIVERSAL-TABLE_COLUMN_ACTIONS' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let service of services
                | genericSearchFilterPipe: filterArg:['name', 'summary']
                | genericSortPipe: sortField:sortDescending
                | paginate: { currentPage: selectedPage, itemsPerPage: itemsPerPageArg }
            "
            class="sr-base-table-row"
          >
            <td class="cell-value">{{ service.name }}</td>
            <td class="cell-value hide-on-small-only">{{ service.summary | truncate: 90 }}</td>
            <td class="cell-action-icon actions">
              <a
                [routerLink]="['view', service.serviceId]"
                role="button"
                class="waves-effect waves-light btn-floating cyan btn-small"
              >
                <i class="material-icons">visibility</i>
              </a>
            </td>
            <td class="cell-action-icon actions">
              <a
                [routerLink]="['edit', service.serviceId]"
                role="button"
                class="waves-effect waves-light btn-floating cyan"
              >
                <i class="material-icons">mode_edit</i>
              </a>
            </td>
            <td class="cell-action-icon actions">
              <a role="button" class="waves-effect waves-light btn-floating cyan disabled">
                <i class="material-icons">delete</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col s12 m12">
    <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
  </div>
</div>
