import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserType } from '../../../models/User';

@Component({
  selector: 'users',
  templateUrl: './user_management.component.html',
  styleUrls: ['./user_management.component.css'],
  providers: [],
})
export class UserManagementComponent {
  constructor() {}
}

export function userRequiresSelectedOrganizationValidator(formGroup: FormGroup) {
  const userType: number = parseInt(formGroup.get('userType').value, 10);
  if ((userType === UserType.ORG_GENERAL || userType === UserType.LOCATION) && formGroup.get('organizationId').value) {
    return null;
  }
  if (userType === UserType.SUPER) {
    return null;
  }

  return {
    missingOrganization: true,
  };
}
