import { zip as zipFrom, Subscription, Observable, of } from 'rxjs';
import { switchMap, flatMap, zip } from 'rxjs/operators';
import { Component, EventEmitter, OnInit, OnChanges, OnDestroy, Output } from '@angular/core';

import { AppSettings } from '../../common/config';

@Component({
  selector: 'dap-donation',
  templateUrl: './dap-donation.component.html',
  styleUrls: ['./dap-donation.component.css'],
})
export class DapDonationComponent {
  public AppSettings = AppSettings;
  online: boolean = true;
  check: boolean = false;
  phone: boolean = false;

  constructor() {}

  donateMethod(arg) {
    if (arg.target.value === 'online') {
      this.online = true;
      this.phone = this.check = false;
    } else if (arg.target.value === 'check') {
      this.check = true;
      this.online = this.phone = false;
    } else {
      this.phone = true;
      this.check = this.online = false;
    }
  }
}
