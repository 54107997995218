import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from './carousel.animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [useAnimation(fadeIn, { params: { time: '1300ms' } })]),
      transition('* => void', [useAnimation(fadeOut, { params: { time: '1300ms' } })]),
    ]),
  ],
})
export class CarouselComponent implements OnInit {
  @Input() slides: {
    src: string;
  }[];

  countries = [
    'CarouselComponent-COUNTRIES-Pakistan',
    'CarouselComponent-COUNTRIES-India',
    'CarouselComponent-COUNTRIES-Nepal',
    'CarouselComponent-COUNTRIES-Bangladesh',
    'CarouselComponent-COUNTRIES-Puerto-Rico',
    'CarouselComponent-COUNTRIES-Nigeria',
    'CarouselComponent-COUNTRIES-Haiti',
    'CarouselComponent-COUNTRIES-Louisiana',
    'CarouselComponent-COUNTRIES-Ghana',
    'CarouselComponent-COUNTRIES-Florida',
    'CarouselComponent-COUNTRIES-Philippines',
    'CarouselComponent-COUNTRIES-California',
    'CarouselComponent-COUNTRIES-North-Carolina',
    'CarouselComponent-COUNTRIES-Indonesia',
    'CarouselComponent-COUNTRIES-South-Carolina',
    'CarouselComponent-COUNTRIES-Texas',
    'CarouselComponent-COUNTRIES-Colombia',
    'CarouselComponent-COUNTRIES-Washington',
    'CarouselComponent-COUNTRIES-Guatemala',
    'CarouselComponent-COUNTRIES-Liberia',
    'CarouselComponent-COUNTRIES-Mexico',
    'CarouselComponent-COUNTRIES-Turkey',
    'CarouselComponent-COUNTRIES-Myanmar',
    'CarouselComponent-COUNTRIES-Peru',
    'CarouselComponent-COUNTRIES-Zimbabwe',
    'CarouselComponent-COUNTRIES-Sri-Lanka',
    'CarouselComponent-COUNTRIES-USVI',
    'CarouselComponent-COUNTRIES-New-York',
    'CarouselComponent-COUNTRIES-New-Jersey',
    'CarouselComponent-COUNTRIES-Uganda',
    'CarouselComponent-COUNTRIES-Missouri',
    'CarouselComponent-COUNTRIES-Lebanon',
    'CarouselComponent-COUNTRIES-Burkina-Faso',
    'CarouselComponent-COUNTRIES-Malawi',
    'CarouselComponent-COUNTRIES-Tanzania',
    'CarouselComponent-COUNTRIES-Georgia',
    'CarouselComponent-COUNTRIES-Maryland',
    'CarouselComponent-COUNTRIES-Chile',
    'CarouselComponent-COUNTRIES-Jordan',
    'CarouselComponent-COUNTRIES-Pennsylvania',
    'CarouselComponent-COUNTRIES-Arizona',
    'CarouselComponent-COUNTRIES-Bahamas',
    'CarouselComponent-COUNTRIES-Cameroon',
    'CarouselComponent-COUNTRIES-Greece',
    'CarouselComponent-COUNTRIES-Massachusetts',
    'CarouselComponent-COUNTRIES-Mali',
    'CarouselComponent-COUNTRIES-El-Salvador',
    'CarouselComponent-COUNTRIES-Mauritania',
    'CarouselComponent-COUNTRIES-Yemen',
    'CarouselComponent-COUNTRIES-Gambia',
    'CarouselComponent-COUNTRIES-Tennessee',
    'CarouselComponent-COUNTRIES-Bahrain',
    'CarouselComponent-COUNTRIES-Cambodia',
    'CarouselComponent-COUNTRIES-DRC',
    'CarouselComponent-COUNTRIES-Dominican-Republic',
    'CarouselComponent-COUNTRIES-Guinea',
    'CarouselComponent-COUNTRIES-Hawaii',
    'CarouselComponent-COUNTRIES-Illinois',
    'CarouselComponent-COUNTRIES-Iraq',
    'CarouselComponent-COUNTRIES-Mississippi',
    'CarouselComponent-COUNTRIES-Japan',
    'CarouselComponent-COUNTRIES-Rwanda',
    'CarouselComponent-COUNTRIES-South-Sudan',
    'CarouselComponent-COUNTRIES-St-Maarten',
    'CarouselComponent-COUNTRIES-Thailand',
    'CarouselComponent-COUNTRIES-Nicaragua',
    'CarouselComponent-COUNTRIES-Australia',
    'CarouselComponent-COUNTRIES-Saint-Lucia',
    'CarouselComponent-COUNTRIES-Suriname',
    'CarouselComponent-COUNTRIES-Barbados',
    'CarouselComponent-COUNTRIES-Virginia',
    'CarouselComponent-COUNTRIES-Washington-Dc',
    'CarouselComponent-COUNTRIES-Mozambique',
    'CarouselComponent-COUNTRIES-Togo',
    'CarouselComponent-COUNTRIES-Alabama',
    'CarouselComponent-COUNTRIES-Benin',
    'CarouselComponent-COUNTRIES-Brazil',
    'CarouselComponent-COUNTRIES-Burundi',
    'CarouselComponent-COUNTRIES-Antigua-and-Barbuda',
    'CarouselComponent-COUNTRIES-Uruguay',
    'CarouselComponent-COUNTRIES-Congo',
    'CarouselComponent-COUNTRIES-Niger',
    'CarouselComponent-COUNTRIES-Michigan',
    'CarouselComponent-COUNTRIES-Romania',
    'CarouselComponent-COUNTRIES-Poland',
    'CarouselComponent-COUNTRIES-Slovakia',
    'CarouselComponent-COUNTRIES-Sierra-Leone',
    'CarouselComponent-COUNTRIES-Kentucky',
    'CarouselComponent-COUNTRIES-Colorado',
    'CarouselComponent-COUNTRIES-Syria',
  ];

  connectionsType = [
    'CarouselComponent-DONORS',
    'CarouselComponent-SURVIVORS',
    'CarouselComponent-RESPONDERS',
    'CarouselComponent-MEDIA',
    'CarouselComponent-FOUNDATIONS',
    'CarouselComponent-CSR',
  ];

  disasters = 'CarouselComponent-DISASTERS';

  timerCarousel: number = 8000;
  timerWordsRotation: number = 4000;
  countryActive: number = 1;
  connectionTypeActive: number = 1;
  currentSlide: number = 0;

  country1: string;
  country2: string;
  connectionType1: string;
  connectionType2: string;
  currentLanguage: string = '';

  constructor(private ts: TranslateService) {
    this.randomWords();
  }

  ngOnInit() {
    this.preloadImages();
    this.repeatSlider();
    this.wordsRotation();
  }

  repeatSlider() {
    setTimeout(() => {
      const next = this.currentSlide + 1;
      this.currentSlide = next === this.slides.length ? 0 : next;
      this.randomWords();
      this.repeatSlider();
    }, this.timerCarousel);
  }

  wordsRotation() {
    setTimeout(() => {
      if (this.countryActive >= 2) {
        this.countryActive = 1;
        this.connectionTypeActive = 1;
        this.randomWords();
        this.wordsRotation();
        return;
      }
      this.countryActive += 1;
      this.connectionTypeActive += 1;
      this.wordsRotation();
    }, this.timerWordsRotation);
  }

  randomWords() {
    this.countries.sort(() => 0.5 - Math.random());
    this.connectionsType.sort(() => 0.5 - Math.random());

    this.country1 = this.ts.instant(this.countries[0]);
    this.country2 = this.ts.instant(this.countries[1]);

    this.connectionType1 = this.ts.instant(this.connectionsType[0]);
    this.connectionType2 = this.ts.instant(this.connectionsType[1]);

    this.currentLanguage = this.ts.getBrowserLang();
  }

  preloadImages() {
    for (const slide of this.slides) {
      new Image().src = slide.src;
    }
  }
}
