<div class="row">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12 m12 my_btns">
    <a
      *ngIf="GOB_COMPONENT"
      (click)="dispatchAction('add_user')"
      class="waves-effect waves-light btn cyan"
      role="button"
    >
      <i class="material-icons left">add</i>
      {{ 'DashboardUserManagementBaseComponent-ADD_USER' | translate }}
    </a>
    <a *ngIf="!GOB_COMPONENT" [routerLink]="['create']" class="waves-effect waves-light btn cyan" role="button">
      <i class="material-icons left">add</i>
      {{ 'DashboardUserManagementBaseComponent-ADD_USER' | translate }}
    </a>
    <a
      *ngIf="authService.isSuper()"
      (click)="this.modalActions.emit({ action: 'modal', params: ['open'] })"
      class="waves-effect waves-light btn cyan"
      role="button"
    >
      {{ 'DashboardUserManagementBaseComponent-USER_REPORT_BUTTON' | translate }}
    </a>
  </div>
</div>

<div class="row">
  <div class="col s12 m12 sr-filter-row">
    <div>
      <div class="col s3 m3 left valign-wrapper sr-filter-row">
        <label for="entries" class="my_select1">
          {{ 'UNIVERSAL-SHOW_TEXT' | translate }}
        </label>
        <select
          (change)="onItemsPerPageChange($event.target.value)"
          name="entries"
          id="entries"
          class="browser-default my_select2"
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
        </select>
        <label for="entries" class="my_select1">
          {{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}
        </label>
      </div>

      <div class="col s4 m2 right valign-wrapper">
        <div class="input-field search-input">
          <input [ngModel]="filterArg" (ngModelChange)="selectedPage = 0; filterArg = $event" id="search" type="text" />
          <label class="grey-text" for="search">
            {{ 'UNIVERSAL-SEARCH' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col s12 m12">
  <div class="card">
    <div class="card-content black-text">
      <table
        [ngClass]="{ 'org-admin-table': authService.isOrgGeneral() }"
        class="centered grey lighten-3 table-wrapper striped sr-custom-table-row fixed-layout-table"
      >
        <thead>
          <tr class="sr-base-table-row override-th-alignment">
            <th class="sr-link" (click)="setSortedField('firstName')">
              {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
              <i class="material-icons tiny"> swap_vert </i>
            </th>
            <th class="sr-link" (click)="setSortedField('lastName')">
              {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
              <i class="material-icons tiny"> swap_vert </i>
            </th>
            <th class="hide-on-small-only sr-link" (click)="setSortedField('userType')">
              {{ 'UserManagementCommon-USER_TYPE' | translate }}
              <i class="material-icons tiny"> swap_vert </i>
            </th>
            <th
              *ngIf="authService.isSuper()"
              class="hide-on-med-and-down sr-link text-wrapping"
              (click)="setSortedField('organizationName')"
            >
              {{ 'UNIVERSAL-CONTACT_ORGANIZATION' | translate }}
              <i class="material-icons tiny"> swap_vert </i>
            </th>
            <th *ngIf="authService.isSuper()">
              {{ 'UserManagementCommon-EMAIL' | translate }}
            </th>
            <th class="hide-on-small-only">
              {{ 'UserManagementCommon-ENABLED' | translate }}
            </th>
            <th class="hide-on-med-and-down">
              {{ 'UserManagementCommon-LAST_LOGIN' | translate }}
            </th>
            <th
              *ngIf="authService.isSuper() && !GOB_COMPONENT"
              class="header-cell action-header-cell hide-on-med-and-down"
            >
              {{ 'UserManagementCommon-LOCKED_UNTIL' | translate }}
            </th>
            <th *ngIf="authService.isSuper()" class="header-cell admin-actions action-header-cell" colspan="3">
              {{ 'UNIVERSAL-TABLE_COLUMN_ACTIONS' | translate }}
            </th>
            <th *ngIf="authService.isOrgGeneral()" class="header-cell actions action-header-cell" colspan="2">
              {{ 'UNIVERSAL-TABLE_COLUMN_ACTIONS' | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let user of users
                | genericSearchFilterPipe: filterArg:['firstName', 'lastName', 'userTypeName', 'organizationName']
                | genericSortPipe: sortField:sortDescending
                | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage, id: 'users' }
            "
            class="sr-base-table-row"
          >
            <td class="cell-value">
              {{ user.firstName }}
            </td>
            <td class="cell-value">
              {{ user.lastName }}
            </td>
            <td class="cell-value hide-on-small-only">
              {{ user.userTypeName }}
            </td>
            <td *ngIf="authService.isSuper()" class="cell-value hide-on-med-and-down">
              {{ user.organizationName }}
            </td>
            <td *ngIf="authService.isSuper()" class="cell-value">
              <input
                class="cell-value"
                type="checkbox"
                [id]="'email-chk-' + user.userId"
                (change)="turnOffUserEmail(user)"
                [(ngModel)]="user.emailNotificationTurnedOff"
              />
              <label [for]="'email-chk-' + user.userId">&nbsp;</label>
            </td>
            <td class="cell-value hide-on-small-only">
              {{ user.enabled }}
            </td>
            <td class="cell-value hide-on-med-and-down admin-time">
              <a href="#" (click)="onViewLoginHistory($event, user.userId)">
                {{ user.lastLoginTime | date: AppSettings.SR_LOGIN_DATE_FORMAT }}
                <br />
                {{ user.lastLoginTime | date: AppSettings.SR_LOGIN_TIME_FORMAT }}
              </a>
            </td>
            <td *ngIf="authService.isSuper() && !GOB_COMPONENT" class="cell-value hide-on-med-and-down admin-time">
              {{ user.lockUntil | date: AppSettings.SR_LOGIN_DATE_FORMAT }}
              <br />
              {{ user.lockUntil | date: AppSettings.SR_LOGIN_TIME_FORMAT }}
            </td>
            <td class="cell-action-icon center">
              <a
                *ngIf="!GOB_COMPONENT"
                [routerLink]="['view', user.userId]"
                class="waves-effect waves-light btn-floating cyan"
              >
                <i class="material-icons left">visibility</i>
              </a>
              <a
                *ngIf="GOB_COMPONENT"
                (click)="dispatchAction('view', user.userId)"
                class="waves-effect waves-light btn-floating cyan"
              >
                <i class="material-icons left">visibility</i>
              </a>
            </td>
            <td class="cell-action-icon center">
              <a
                *ngIf="!GOB_COMPONENT"
                [routerLink]="['edit', user.userId]"
                class="waves-effect waves-light btn-floating cyan"
              >
                <i class="material-icons left">mode_edit</i>
              </a>
              <a
                *ngIf="GOB_COMPONENT"
                (click)="dispatchAction('edit', user.userId)"
                class="waves-effect waves-light btn-floating cyan"
              >
                <i class="material-icons left">mode_edit</i>
              </a>
            </td>
            <td class="cell-action-icon center" *ngIf="authService.isSuper()">
              <a (click)="onDelete(user)" class="waves-effect waves-light btn-floating cyan">
                <i class="material-icons left">delete</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Modal Structure -->
  <div
    id="loginHistoryModal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="[{ dismissible: true }]"
    [materializeActions]="modalActionsLoginHistory"
  >
    <div class="modal-content">
      <table>
        <thead>
          <tr>
            <th>{{ 'UserManagementCommon-LOGIN_TIMES' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let loginTime of loginTimes" class="row">
            <td>{{ loginTime | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button
        class="left waves-effect waves-light btn cyan darken-3"
        (click)="this.modalActionsLoginHistory.emit({ action: 'modal', params: ['close'] })"
      >
        {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
      </button>
    </div>
  </div>

  <div class="col s12 m12">
    <pagination-controls (pageChange)="selectedPage = $event" id="users"></pagination-controls>
  </div>
</div>

<!-- Get User Report Modal -->
<div
  id="userReportModal"
  class="modal modal-fixed-footer"
  materialize="modal"
  [materializeParams]="[{ dismissible: true }]"
  [materializeActions]="modalActions"
>
  <div class="modal-content">
    <h4>{{ 'DashboardUserManagementBaseComponent-USER_REPORT_MODAL_TITLE' | translate }}</h4>
    <div class="divider"></div>
    <p>{{ 'DashboardUserManagementBaseComponent-USER_REPORT_MODAL_INSTRUCTIONS' | translate }}</p>
    <div *ngFor="let field of reportFields" class="row">
      <input
        id="{{ field['name'] }}-checkbox"
        type="checkbox"
        class="userReportCheckbox"
        (change)="setReportFields(field['name'])"
      />
      <label for="{{ field['name'] }}-checkbox">{{ field['i18n'] | translate }}</label>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="left waves-effect waves-light btn cyan darken-3"
      (click)="this.modalActions.emit({ action: 'modal', params: ['close'] })"
    >
      {{ 'DashboardIncomingMessagesComponent-MODAL_CLOSE' | translate }}
    </button>
    <button
      (click)="downloadUserReport()"
      [disabled]="noReportFieldsSelected()"
      class="right waves-effect waves-light btn cyan darken-3"
    >
      {{ 'DashboardUserManagementBaseComponent-DOWNLOAD_SPREADSHEET' | translate }}
    </button>
  </div>
</div>
