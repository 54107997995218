import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function numbersOnlyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue: string = control.value;
    const numbersOnlyPattern = /^[0-9\s]+$/;
    if (numbersOnlyPattern.test(inputValue)) {
      return { numbersOnly: true };
    }
    return null;
  };
}
