import { Component } from '@angular/core';

// const template = require('./service.component.html');
// const styles = require('./service.component.css');

@Component({
  selector: 'dashboardService',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css'],
  providers: [],
})
export class DashboardServiceComponent {
  constructor() {}
}
