import { Component, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Language, LANGUAGE_CONFIG } from './language.model';
import { AppSettings } from '../../common/config';
import { HtmlOption } from '../../models';
import { GlobalLoaderFacade } from '../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { LanguagePickerService } from './language-picker.service';

@Component({
  selector: 'language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.css'],
})
export class LanguagePickerComponent implements OnInit {
  languages: Language[] = LANGUAGE_CONFIG;
  initOption$: EventEmitter<string> = new EventEmitter<string>();
  optionsLabel: string = 'LanguagePickerComponent-LANGUAGE_TEXT';
  optionsPrefix: string = 'lang';
  languageOptions: HtmlOption[];

  isChangingLanguages: boolean = false;

  constructor(
    private translateService: TranslateService,
    private globalLoaderService: GlobalLoaderFacade,
    private notificationService: NotificationsService,
    private languageService: LanguagePickerService
  ) {}

  ngOnInit() {
    this.languageOptions = this.languages.map((item) => ({ value: item.languageCode, label: item.name }));

    // Following three lines change first displayed language in option list to current
    const languageIndex = this.languageOptions.findIndex(
      (x) => x.value === this.languageService.getCachedLanguageCode()
    );
    this.languageOptions.splice(0, 0, this.languageOptions[languageIndex]);
    this.languageOptions.splice(languageIndex + 1, 1);

    this.changeLangTo(this.translateService.currentLang);
    this.initOption$.emit(this.translateService.currentLang);

    this.translateService.onLangChange.subscribe((next) => {
      this.changeLangTo(next.lang);
    });
  }

  changeLangTo(code: string): void {
    const lang: Language = this.languages.find((item) => item.languageCode === code);

    // due to https://github.com/ocombe/@ngx-translate/core/issues/390
    // if the request fails, the error function passed to subscribe() will never be called, so we cannot enforce
    // this rule
    // if (this.isChangingLanguages) {
    // 	return;
    // }

    // this.isChangingLanguages = true;

    this.globalLoaderService.start();

    this.translateService.use(lang.languageCode).subscribe(
      (success) => {
        this.isChangingLanguages = false;
        this.globalLoaderService.complete();
        this.languageService.setPreferredLanguage(lang.languageCode);
      },
      (error) => {
        this.isChangingLanguages = false;
        this.globalLoaderService.complete();

        this.notificationService.error(
          this.translateService.instant('LanguagePickerComponent-TITLE'),
          this.translateService.instant('LanguagePickerComponent-FAILED_TO_CHANGE'),
          AppSettings.NOTIFICATIONS_ERROR_OPTIONS
        );
      }
    );
  }
}
