import { Component, Input, OnInit } from '@angular/core';
import { ReportSurveyTypes } from '../reportModal/report.model';
import { AppSettings } from '../../common/config';

@Component({
  selector: 'last-updated-icon-and-timestamp',
  templateUrl: './last-updated.component.html',
  styleUrls: ['./last-updated.component.css'],
})
export class LastUpdatedSurveyResultsComponent implements OnInit {
  @Input()
  timestamp: number;

  @Input()
  surveyType: string;

  public colorStatusClass: { [key: string]: string } = {
    'up-to-date': 'green-text text-darken-3',
    outdated: 'orange-text text-darken-3',
    refused: 'red-text',
  };

  public statusText: { [key: string]: string } = {
    'up-to-date': '',
    outdated: 'COMMON_SEARCH-STATUS_NO_RESPONSE',
    refused: 'COMMON_SEARCH-STATUS_REFUSED',
  };

  public status: 'up-to-date' | 'outdated' | 'refused' = 'up-to-date';

  public AppSettings = AppSettings;

  constructor() {}

  ngOnInit() {
    const timeSinceEdit = Date.now() - this.timestamp;

    if (timeSinceEdit < 0) {
      return;
    }

    // apply status based on difference between now and timestamp
    // Todo: figure out how to handle individual report types, do they need to have different behavior??
    switch (this.surveyType) {
      case (ReportSurveyTypes.GOB, 'NA'):
        if (timeSinceEdit < AppSettings.GOB_EXPIRATION_TIME_IN_MILLISECONDS) {
          this.status = 'up-to-date';
        } else if (timeSinceEdit < AppSettings.GOB_EXPIRATION_TIME_IN_MILLISECONDS * 2) {
          this.status = 'outdated';
        } else {
          this.status = 'refused';
        }
    }
  }
}
