import { map, share, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { contentHeaders } from '../../../common/headers';

import { AppSettings } from '../../../common/config';
import { Country } from '../../../models/Country';
import { AuthenticationService } from '../../../login/login.service';
import { SRResponse } from './../../../models/SRResponse';

@Injectable()
export class CountryService {
  private url = AppSettings.API_SERVER + '/smart-response/admin/country';

  private CACHE_KEY = 'DAP_COUNTRIES_CACHE';
  private CACHE_TIMEOUT = 60000; // 1 minute in milliseconds

  constructor(public http: HttpClient, public authService: AuthenticationService) {}

  updateCache(data: Country[]): void {
    const obj = {
      retrieved_at: Date.now(),
      countries: data,
    };

    localStorage.setItem(this.CACHE_KEY, JSON.stringify(obj));
  }

  validCache(): Country[] {
    const obj = localStorage.getItem(this.CACHE_KEY);

    if (obj) {
      const otherObj = JSON.parse(obj);

      const now = new Date().getTime();
      const retrievedAtTime = new Date(otherObj.retrieved_at).getTime();

      if (now - retrievedAtTime < this.CACHE_TIMEOUT) {
        return otherObj.countries;
      }
    }

    return null;
  }

  invalidateCache(): void {
    localStorage.removeItem(this.CACHE_KEY);
  }

  getCountries(): Observable<Country[]> {
    // todo This needs to be refactored to improve how caching is handled
    let cache: Country[];

    if ((cache = this.validCache())) {
      return of(cache);
    }

    const countries$ = this.http
      .get<SRResponse>(this.url, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(
        map((res) => res.responseData.country_list),
        tap((countries) => this.updateCache(countries)),
        share()
      );

    return countries$;
  }

  getCountry(countryId: number): Observable<Country> {
    // todo get from cache if possible
    return this.http
      .get<SRResponse>(this.url + '/' + countryId, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.country));
  }

  addCountry(country: Country): Observable<Country[]> {
    // todo smart update cache
    const countries$ = this.http
      .post<Country[]>(this.url, JSON.stringify(country), { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(
        tap(() => this.invalidateCache()),
        share()
      );

    return countries$;
  }

  updateCountry(country: Country): Observable<Country[]> {
    // todo smart update cache
    const countries$ = this.http
      .put<Country[]>(this.url + '/' + country.countryId, JSON.stringify(country), {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(
        tap(() => this.invalidateCache()),
        share()
      );
    return countries$;
  }

  deleteCountry(countryId: number): Observable<Country[]> {
    // todo smart update cache
    const countries$ = this.http
      .delete<Country[]>(this.url + '/' + countryId, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(
        tap(() => this.invalidateCache()),
        share()
      );

    return countries$;
  }
}
