import { ResponseField } from '../models';

export function formatStringList(list: string[]): string[] {
  if (Array.isArray(list) && list.length > 0) {
    return list;
  }
  return null;
}

export function validateResponseField<T>(field: ResponseField<T>): T {
  return field && field.value ? field.value : null;
}
