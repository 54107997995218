import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../common/config';
import { AgmInfoWindow } from '@agm/core';

@Component({
  selector: 'app-global-map',
  templateUrl: './global-map.component.html',
  styleUrls: ['./global-map.component.css'],
})
export class GlobalMapComponent implements OnInit {
  private URL: string = AppSettings.GLOBAL_ORGANIZATION_MAP_LIST + AppSettings.GLOBAL_ORGANIZATION_MAP_LIST_APIKEY;
  private zoom: number = 2;
  private baseLatitude: number = 35.0;
  private baseLongitude: number = 5.0;
  private height: number = Math.floor(window.innerHeight * 0.7);
  locations: Location[] = [];
  error = false;

  constructor(private http: HttpClient) {
    this.currentIW = null;
    this.previousIW = null;
  }
  loading = true;

  currentIW: AgmInfoWindow;
  previousIW: AgmInfoWindow;

  ngOnInit() {
    this.http.get(this.URL).subscribe((response: any) => {
      this.error = false;
      let indexLatitude = -1;
      let indexLocation = -1;
      let indexLongitude = -1;
      let indexUrlToLocationPage = -1;
      response['values'].map((locationData) => {
        if (locationData.indexOf('latitude') !== -1) {
          indexLatitude = locationData.indexOf('latitude');
          indexLocation = locationData.indexOf('location');
          indexLongitude = locationData.indexOf('longitude');
          indexUrlToLocationPage = locationData.indexOf('URL to location page');
        } else {
          if (
            indexLatitude !== -1 &&
            indexUrlToLocationPage !== -1 &&
            indexLocation !== -1 &&
            indexLongitude !== -1 &&
            locationData[indexLatitude] !== null &&
            locationData[indexLatitude] !== undefined &&
            locationData[indexLatitude] !== ''
          ) {
            const l: Location = {
              latitude: +locationData[indexLatitude],
              URL: locationData[indexUrlToLocationPage],
              name: locationData[indexLocation],
              longitude: +locationData[indexLongitude],
            };
            this.locations.push(l);
          }
          this.loading = false;
        }
      });
    });
    (error: any) => {
      this.loading = false;
      this.error = true;
    };
  }

  mapClick() {
    if (this.previousIW) {
      this.previousIW.close();
    }
  }

  markerClick(infoWindow) {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
  }
}

interface Location {
  name: string;
  URL: string;
  latitude: number;
  longitude: number;
}
