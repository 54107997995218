<div class="option-picker">
  <label for="{{ prefix }}-option-list">
    {{ label | translate }}
  </label>
  <select
    #selectControl
    id="{{ prefix }}-option-list"
    name="option-list"
    class="option-list"
    (change)="onSelectOption(selectControl.value)"
  >
    <ng-container *ngFor="let option of options">
      <option
        class="option"
        [value]="option.value"
        [selected]="option.value === selectedOption"
        [class.selected-option]="option.value === selectedOption"
      >
        {{ option.label }}
      </option>
    </ng-container>
  </select>
</div>
