export interface RecentSurvey {
  surveyType: SurveyType;
  submissionId?: number;
  surveyUpdateDate?: number;
  orgName: string;
  orgId: number;
  typeId?: number;
  typeName?: string;
}

export enum SurveyType {
  Lss = 1,
  Gob,
  Dss,
}
