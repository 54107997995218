import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../common/config';
import { HttpClient } from '@angular/common/http';

const SHORT_SEARCH_URL = AppSettings.API_SERVER + '/smart-response/country_disaster_search';

@Injectable()
export class ShortUrlService {
  constructor(private http: HttpClient) {}

  findHashTag(name: string): Observable<any> {
    return this.http.get(`${SHORT_SEARCH_URL}/${name}`);
  }
}
