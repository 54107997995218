<div class="col s12 card-panel registration-card card">
  <div class="row">
    <div class="card-title sr-bold">
      {{ 'RegistrationComponent-CREATE_ORGANIZATION_ACCOUNT_HEADING' | translate }}
    </div>

    <div class="divider"></div>
  </div>

  <div class="sr-required-notif">*{{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>
  <app-loader [loading]="loading"></app-loader>
  <div class="row margin">
    <div
      class="input-field col s12"
      [ngClass]="{
        'sr-has-error': !organizationName.valid && organizationName.value.length && organizationName.touched
      }"
    >
      <i class="material-icons prefix">business</i>
      <input id="org_name" type="text" [formControl]="organizationName" materialize="characterCounter" length="128" />
      <label for="org_name">
        {{ 'DashboardOrganizationCreateComponent-NAME' | translate }}<span class="sr-required-mark"> *</span>
      </label>
      <div class="sr-error-area" *ngIf="!organizationName.valid && organizationName.value.length">
        <div *ngIf="organizationName.hasError('required')">
          {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
        </div>
        <div *ngIf="organizationName.hasError('maxlength')">
          {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 128 } }}
        </div>
        <div *ngIf="organizationName.hasError('leadingTrailingWhiteSpaceFormat')">
          {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
        </div>
        <div *ngIf="organizationName.hasError('orgFormat')">
          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_ORGNAME' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col s12">
      <div class="my_btns right">
        <button
          (click)="onSubmit()"
          type="submit"
          class="btn waves-effect waves-light cyan"
          [disabled]="isSubmitting || !organizationName.valid"
        >
          {{ 'UNIVERSAL-NAVIGATION_ADD' | translate }}
        </button>
        <button type="button" (click)="onCancel()" class="btn waves-effect waves-light cyan">
          {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
