import { FormControl } from '@angular/forms';

interface ValidationResult {
  [key: string]: boolean;
}

export class OrgValidator {
  public static orgFormat(control: FormControl): ValidationResult {
    if (control.value) {
      const ORG_REGEXP = /^[^<>\"!]+$/;
      if (control.value !== '' && !ORG_REGEXP.test(control.value)) {
        return { orgFormat: true };
      }
    }
    return null;
  }
}
