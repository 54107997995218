import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../../common/config';
import { contentHeaders } from '../../../../common/headers';
import { AuthenticationService } from '../../../../login/login.service';

const ENDPOINT_URL: string = AppSettings.API_SERVER + '/smart-response/';

@Injectable()
export class ListComponentService {
  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  get(endpoint: string, keyProp: string): Observable<string[]> {
    return this.http.get(ENDPOINT_URL + endpoint, { headers: this.authService.getAuthHeader(contentHeaders) }).pipe(
      map((success) => {
        const responseData = success['responseData'];

        for (const key in responseData) {
          return responseData[key].map((item: any) => item[keyProp]);
        }

        return [];
      })
    );
  }

  getDefault(endpoint: string): Observable<any> {
    return this.http.get(ENDPOINT_URL + endpoint, { headers: this.authService.getAuthHeader(contentHeaders) }).pipe(
      map((success) => {
        const responseData = success['responseData'];

        for (const key in responseData) {
          return responseData[key];
        }

        return [];
      })
    );
  }

  add(endpoint: string, propertyName: string, delta: string[], keyProp: string): Observable<string[]> {
    const data = {};
    data[propertyName] = delta;

    return this.http
      .post(ENDPOINT_URL + endpoint, JSON.stringify(data), { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(
        map((success) => {
          const responseData = success['responseData'];

          for (const key in responseData) {
            return responseData[key].map((item: any) => item[keyProp]);
          }

          return [];
        })
      );
  }
}
