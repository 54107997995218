import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GOBSurveyInputInterface } from '../survey-input-interface.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AppSettings } from '../../../../common/config';

@Component({
  selector: 'manual-gob-survey-edits',
  providers: [],
  templateUrl: './general-organization-background-survey.component.html',
  styleUrls: ['./generic-survey.component.css'],
})
export class ManualGeneralOrganizationBackgroundSurveyBrowserAndEditorComponent implements OnInit {
  surveyInput: GOBSurveyInputInterface;
  organizationId: number;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const organizationId = +params['organizationId'];
      this.organizationId = organizationId;

      this.surveyInput = {
        organizationId: this.organizationId,
      };
    });
  }
}
