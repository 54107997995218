<div class="row" *ngIf="disastersAreLoaded">
  <ul materialize="tabs" class="tabs">
    <!--		<li class="tab col s4">
			<a class="active" href="#trendingDisastersList">
				{{ 'DisasterListHomeComponent-TAB_TITLE_TRENDING' | translate }}
			</a>
		</li>
-->
    <!-- <li class="tab col s4">
			<a class="active" href="#recentDisastersList">
				{{ 'DisasterListHomeComponent-TAB_TITLE_RECENT' | translate }}
			</a>
		</li> -->
    <li class="tab col s6">
      <a href="#starredDisastersList">
        {{ 'DisasterListHomeComponent-TAB_TITLE_STARRED' | translate }}
      </a>
    </li>
    <li class="tab col s6">
      <a href="#recentSurveys">
        {{ 'DisasterListHomeComponent-TAB_TITLE_RECENT_SURVEY' | translate }}
      </a>
    </li>
  </ul>

  <ng-template [ngIf]="error">
    {{ 'DisasterListHomeComponent-GENERIC_FAILURE_ERROR' | translate }}
  </ng-template>

  <ng-template [ngIf]="!error && !disasters">
    <div class="progress">
      <div class="indeterminate"></div>
    </div>
  </ng-template>

  <ng-template [ngIf]="disasters">
    <div *ngFor="let listType of DISASTER_LIST_TYPES" id="{{ listType }}DisastersList">
      <ul materialize="collapsible" class="collapsible" data-collapsible="accordion">
        <ng-container *ngFor="let disaster of disasters[listType]">
          <li *ngIf="!disaster.inactive">
            <div class="collapsible-header active">
              <div class="row valign-wrapper">
                <div class="title col l5 m5 s12 valign-wrapper">
                  <i class="material-icons">expand_more</i>
                  {{ 'DisasterListHomeComponent-DISASTER_TITLE' | translate: { disasterName: disaster.name } }}
                </div>
                <div class="org-link col l7 m7 s12 right-align">
                  <!-- <time>
										{{ 'DisasterListHomeComponent-DISASTER_DATE' | translate:{dateOfDisaster: disaster.dateOfDisaster | date:AppSettings.DEFAULT_ANGULAR_DATE_FORMAT} }}
									</time> -->
                  <span>
                    {{ disaster.disasterType }}
                  </span>
                  <a class="sr-bold" [routerLink]="['/disaster', disaster?.disasterId]">
                    {{ 'DisasterListHomeComponent-FIND_ORGANIZATION' | translate }}
                  </a>
                </div>
              </div>
            </div>

            <div class="collapsible-body">
              <div class="collection">
                <div class="row collection-item">
                  <div class="col l2 m3 s12" id="img-div">
                    <img
                      [src]="disaster?.logo?.uri"
                      alt="disaster logo"
                      class="disaster-img col s12 m12 responsive-img"
                    />
                    <div class="disaster-link input-field col s12 m12 center-align">
                      <div class="left col s12 m12">
                        <a [routerLink]="['/disaster', disaster?.disasterId]" class="sr-bold">
                          {{ 'DisasterListHomeComponent-LEARN_MORE' | translate }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col l10 m9 s12" id="text-div">
                    <div class="col s12 m12 sr-justify summary" [innerHtml]="disaster.summary"></div>
                    <!-- <div class="col s12 m12 sr-justify input-field">
											<span class="sr-bold">
												{{ 'DisasterListHomeComponent-SERVICES_PROVIDED' | translate }}
											</span>
											<br>
											<span *ngFor="let sp of disaster.services; let isLast = last">
												{{ serviceList[sp.serviceId].name }}{{ isLast ? '' : ', ' }}
											</span>
										</div> -->
                    <div class="col s12 m12 sr-justify input-field">
                      <span class="sr-bold">
                        {{ 'DisasterListHomeComponent-LOCATIONS' | translate }}
                      </span>
                      <div>
                        <span
                          *ngFor="let location of disaster.locations | unique: 'country.countryId'; let isLast = last"
                        >
                          <a [routerLink]="['/location', location.country.countryId]">
                            {{ location.country.name }}
                          </a>
                          {{ isLast ? '' : ', ' }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
      <div class="row valign-wrapper">
        <span class="disaster-link input-field col s12 m12 center-align sr-bold">
          <a [routerLink]="['/disasters']">
            {{ 'DisasterAboutComponent-VIEW_MORE' | translate }}
          </a>
        </span>
      </div>
    </div>
  </ng-template>
  <app-loader [loading]="loading"></app-loader>
  <div id="recentSurveys">
    <table class="centered lighten-3 striped responsive-table">
      <thead>
        <tr>
          <th>{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-ORGNAME' | translate }}</th>
          <th>{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-LOCATION-NAME' | translate }}</th>
          <th>{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-SURVEY-TYPE' | translate }}</th>
          <th>{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-UPDATE-DATE' | translate }}</th>
          <th>{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-DETAILS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let survey of surveyList">
          <td>{{ survey?.orgName }} <button *ngIf="isOrganizationNew(survey)">NEW!</button></td>
          <td>{{ survey?.typeName }}</td>
          <ng-template [ngIf]="survey?.surveyType === surveyType.Gob">
            <td>{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-GENERAL-SPECIFIC' | translate }}</td>
            <td>{{ survey?.surveyUpdateDate | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}</td>
            <td>
              <a [routerLink]="['organization', survey?.orgId]" target="_blank">{{
                'DisasterListHomeComponent-TAB_RECENT_SURVEY-VIEW-PAGE' | translate
              }}</a>
            </td>
          </ng-template>
          <ng-template [ngIf]="survey?.surveyType === surveyType.Lss">
            <td>{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-LOCATION-SPECIFIC' | translate }}</td>
            <td>{{ survey?.surveyUpdateDate | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}</td>
            <td>
              <a [routerLink]="['org-location', survey?.orgId, survey?.typeId]" target="_blank">{{
                'DisasterListHomeComponent-TAB_RECENT_SURVEY-VIEW-PAGE' | translate
              }}</a>
            </td>
          </ng-template>
          <ng-template [ngIf]="survey?.surveyType === surveyType.Dss">
            <td>{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-DISASTER-SPECIFIC' | translate }}</td>
            <td>{{ survey?.surveyUpdateDate | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}</td>
            <td>
              <a [routerLink]="['org-disaster', survey?.orgId, survey?.typeId]" target="_blank">{{
                'DisasterListHomeComponent-TAB_RECENT_SURVEY-VIEW-PAGE' | translate
              }}</a>
            </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
    <div class="row valign-wrapper">
      <span class="disaster-link input-field col s12 m12 center-align sr-bold">
        <a [routerLink]="['/disasters']" fragment="recentSurveys">
          {{ 'DisasterAboutComponent-VIEW_MORE' | translate }}
        </a>
      </span>
    </div>
  </div>
</div>
