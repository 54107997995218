import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, forkJoin, from, of } from 'rxjs';
import { catchError, map, switchAll, tap } from 'rxjs/operators';
import { AppSettings } from '../../common/config';
import {
  Collection,
  ConvertedMoney,
  CurrencyCodes,
  CurrencyRate,
  Dictionary,
  Money,
  MoneyConversionOutput,
} from '../../models';

const RETRIEVAL_ENDPOINT_CURRENCY_CODE_URL = AppSettings.API_SERVER + '/smart-response/admin/currency';
const RETRIEVAL_ENDPOINT_CURRENCY_RATE_URL = AppSettings.API_SERVER + '/smart-response/admin/currency_rate?currency=';

export interface StoredCurrencyRateResponse {
  [name: string]: CurrencyRate;
}

@Injectable({
  providedIn: 'root',
})
export class CurrencyPickerService {
  private rates: Dictionary<number> = {};

  private preferredCurrencySubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.getCachedCurrencyCode()
  );

  constructor(private http: HttpClient) {}

  public getCurrencyCodes(): Observable<CurrencyCodes> {
    return this.http
      .get<CurrencyCodes>(`${RETRIEVAL_ENDPOINT_CURRENCY_CODE_URL}`)
      .pipe(map((resp) => resp as CurrencyCodes));
  }

  public getPreferredCurrency(): Observable<string> {
    return this.preferredCurrencySubject$.asObservable();
  }

  public setPreferredCurrency(code: string) {
    localStorage.setItem(AppSettings.LOCAL_STORAGE_CURRENCY_CODE_KEY, code);
    this.preferredCurrencySubject$.next(code);
  }

  /**
   * Converts a collection of `ConvertedMoney` values to the preferred currency
   *
   * @param collection the collection of `ConvertedMoney` values to convert
   * @returns the collection of `ConvertedMoney` with converted values
   */
  public convertCollectionToPreferred(collection: Collection<ConvertedMoney>): Observable<MoneyConversionOutput> {
    // Automatically detect all money amounts that need conversion.
    // Retain the category name of each amount during conversion, so we know where to put it afterwards.
    const preferredCurrency: string = this.preferredCurrencySubject$.value;
    const currencies: string[] = [preferredCurrency];
    const unconverted: string[] = [];
    let hasError: boolean = false;
    const singleFunds$arr: Observable<{ category: string; money: Money }>[] = [];
    let singleFunds$: Observable<Dictionary<ConvertedMoney>> = from([{}]);
    let arrayFunds$: Observable<Dictionary<ConvertedMoney[]>> = from([{}]);

    if (collection.singles && Object.keys(collection.singles).length) {
      for (const category in collection.singles) {
        const initialCurrency: string = collection.singles[category].initial.currency;
        if (!Object.keys(this.rates).includes(initialCurrency) && !currencies.includes(initialCurrency)) {
          currencies.push(initialCurrency);
        }
        singleFunds$arr.push(
          this.convertTo(collection.singles[category].initial, preferredCurrency).pipe(
            catchError(() => {
              return from([null]);
            }),
            map((converted: Money) => ({ category, money: converted }))
          )
        );
      }

      singleFunds$ = forkJoin(singleFunds$arr).pipe(
        map((convertedArr) => {
          const singleFunds: Dictionary<ConvertedMoney> = {};
          for (const item of convertedArr) {
            if (item.money) {
              singleFunds[item.category] = new ConvertedMoney(collection.singles[item.category].initial, item.money);
            } else {
              hasError = true;
              const initialCurrency: string = collection.singles[item.category].initial.currency;
              if (!unconverted.includes(initialCurrency)) {
                unconverted.push(initialCurrency);
              }
              singleFunds[item.category] = new ConvertedMoney(collection.singles[item.category].initial);
            }
          }
          return singleFunds;
        })
      );
    }

    if (collection.arrays && Object.keys(collection.arrays).length) {
      const arrayFunds$arr: Observable<{ category: string; money: ConvertedMoney[] }>[] = [];
      for (const category in collection.arrays) {
        let categoryFunds$: Observable<ConvertedMoney>[] = [];
        if (collection.arrays[category].length) {
          categoryFunds$ = collection.arrays[category].map((item, i) => {
            const initialCurrency: string = item.initial.currency;
            if (!Object.keys(this.rates).includes(initialCurrency) && !currencies.includes(initialCurrency)) {
              currencies.push(initialCurrency);
            }
            return this.convertTo(item.initial, preferredCurrency).pipe(
              catchError(() => {
                return from([null]);
              }),
              map((converted: Money) => {
                let convertedMoney: ConvertedMoney = null;
                if (converted) {
                  convertedMoney = new ConvertedMoney(collection.arrays[category][i].initial, converted);
                } else {
                  hasError = true;
                  if (!unconverted.includes(initialCurrency)) {
                    unconverted.push(initialCurrency);
                  }
                  convertedMoney = new ConvertedMoney(collection.arrays[category][i].initial);
                }
                return convertedMoney;
              })
            );
          });
        }
        if (categoryFunds$.length) {
          arrayFunds$arr.push(
            forkJoin(categoryFunds$).pipe(
              map((convertedMoney: ConvertedMoney[]) => ({ category, money: convertedMoney }))
            )
          );
        } else {
          arrayFunds$arr.push(from([{ category, money: [] }]));
        }
      }

      arrayFunds$ = forkJoin(arrayFunds$arr).pipe(
        map((converted) => {
          const arrayFunds: Dictionary<ConvertedMoney[]> = {};
          for (const arr of converted) {
            arrayFunds[arr.category] = arr.money;
          }
          return arrayFunds;
        })
      );
    }

    const fetchRates$: Observable<number>[] = currencies.map((currency) =>
      this.getCurrencyRate(currency).pipe(
        tap((rate) => {
          if (rate !== null && rate !== undefined) {
            this.rates[currency] = rate;
          }
        })
      )
    );

    return forkJoin(fetchRates$).pipe(
      map(() => forkJoin([singleFunds$, arrayFunds$])),
      switchAll(),
      map(([singles, arrays]) => {
        const result: MoneyConversionOutput = {
          hasError,
          unconverted,
          money: { singles, arrays },
          currency: preferredCurrency,
        };
        return result;
      })
    );
  }

  private getCurrencyRate(code: string): Observable<number> {
    if (this.rates[code]) {
      return of(this.rates[code]);
    }
    const currRate$: Observable<number> = this.getHttpCurrencyRate(code).pipe(
      tap((rate) => {
        this.rates[code] = rate;
      })
    );
    return currRate$;
  }

  private getHttpCurrencyRate(code: string): Observable<number> {
    if (code) {
      return this.http.get<CurrencyRate>(`${RETRIEVAL_ENDPOINT_CURRENCY_RATE_URL}${code}`).pipe(
        catchError(() => {
          return from([
            {
              responseData: {
                rate: null,
              },
            },
          ]);
        }),
        map((resp: CurrencyRate) => resp.responseData.rate)
      );
    }
    return from([null]);
  }

  private convertTo(money: Money, currency: string): Observable<Money> {
    if (money && (money.amount === null || money.amount === undefined)) {
      const converted: Money = { currency: money.currency, amount: null };
      return from([converted]);
    }

    const converted$: Observable<Money> = new Observable((observer) => {
      let fromCurrencyRate: number;
      let toCurrencyRate: number;

      let amount: number = money.amount;
      const fromCurrency: string = money.currency;

      /* get the currency rates */
      const fromCurrencyRate$ = this.getCurrencyRate(fromCurrency);
      const toCurrencyRate$ = this.getCurrencyRate(currency);

      forkJoin([fromCurrencyRate$, toCurrencyRate$])
        .pipe(
          map(([fromRate, toRate]) => ({
            fromRate,
            toRate,
          }))
        )
        .subscribe((data: { fromRate; toRate }) => {
          fromCurrencyRate = data.fromRate;
          toCurrencyRate = data.toRate;
          if (fromCurrencyRate && toCurrencyRate) {
            amount = this.calculateConvertedAmount(money.amount, fromCurrencyRate, toCurrencyRate);
            const converted: Money = { currency, amount };
            observer.next(converted);
          } else {
            observer.error();
          }
          observer.complete();
        });
    });
    return converted$;
  }

  private calculateConvertedAmount(amount: number, from: number, to: number): number {
    let convertedAmount = null;
    if (amount === null) {
      return null;
    }

    convertedAmount = (amount / 1) * (1 / from) * (to / 1);
    return convertedAmount;
  }

  private getCachedCurrencyCode(): string {
    const cachedCurrencyCode = localStorage.getItem(AppSettings.LOCAL_STORAGE_CURRENCY_CODE_KEY);
    if (cachedCurrencyCode) {
      return cachedCurrencyCode;
    }
    return AppSettings.DEFAULT_CURRENCY;
  }
}
