import { FormArray, FormGroup, FormControl } from '@angular/forms';

export class TransferredFundValidator {
  public static validateData(controlGroup: FormGroup) {
    if (!controlGroup) {
      return null;
    }

    const hasValue = controlGroup.get('hasValue').value;
    const fileId = controlGroup.get('fileId').value;
    const fundTransferList = <FormArray>controlGroup.get('fundTransferList');

    if (hasValue) {
      if (fileId && !TransferredFundValidator.isFundTransferListEmpty(fundTransferList)) {
        return { onlyOneReceivingOrgList: true };
      }
    }

    return null;
  }

  static isFundTransferListEmpty(array: FormArray): boolean {
    let isEmpty: boolean = false;

    if (!array) {
      isEmpty = true;
    } else {
      for (const fundRow of array.controls) {
        const orgName = fundRow.get('receivingOrganization').value;
        const amt = fundRow.get('fund').value;

        if (!orgName && !amt) {
          isEmpty = true;
          break;
        }
      }
    }

    return isEmpty;
  }

  static isFundTransferListValid(array: FormArray): boolean {
    let isValid: boolean = true;

    if (!array) {
      isValid = false;
    } else {
      for (const fundRow of array.controls) {
        const orgName = fundRow.get('receivingOrganization').value;
        const amt = fundRow.get('fund').value;

        if (!(orgName && amt)) {
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  }
}
