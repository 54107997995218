<!-- Search -->
<div class="row search-container">
  <div id="Organization-tab" class="search-container-tab col s12">
    <ng2-completer
      class="completer"
      [ngClass]="{ 'search-selected': url.length > 0 }"
      [minSearchLength]="3"
      [datasource]="customData"
      [pause]="700"
      [placeholder]="'SearchComponent-SEARCH_PLACEHOLDER' | translate"
      [textNoResults]="'SearchComponent-SEARCH_NOT_FOUND' | translate"
      [textSearching]="'UNIVERSAL-SEARCHING' | translate"
      (selected)="onSelectedItem($event)"
    >
    </ng2-completer>
    <!-- Search button -->
    <div class="search-button">
      <a [routerLink]="url" role="button" class="btn btn-primary blue input-lg">
        <i class="material-icons center">search</i>
      </a>
    </div>

    <!-- <div class="advanced-search col s12">
      <a
        disabled
        class="disabled">
        {{ 'SearchComponent-ADVANCED_ORGANIZATION_TITLE' | translate }}
      </a>
    </div> -->
  </div>
</div>
<div class="row">
  <div class="col s12">
    {{ 'SearchComponent-LABEL-NARROW-BY' | translate }}
  </div>
  <div class="col l3 s6">
    <input id="all-chk" type="checkbox" (click)="toggleAll($event)" [(ngModel)]="allChecked" />
    <label for="all-chk">{{ 'SearchComponent-LABEL-ALL' | translate }}</label>
  </div>
  <div class="col l3 s6">
    <input
      id="disaster-chk"
      type="checkbox"
      (change)="toggleEnum($event.target.checked, searchEnum.Disaster)"
      [(ngModel)]="disasterChecked"
    />
    <label for="disaster-chk">{{ 'SearchComponent-LABEL-DISASTERS' | translate }} </label>
  </div>
  <div class="col l3 s6">
    <input
      id="location-chk"
      type="checkbox"
      (change)="toggleEnum($event.target.checked, searchEnum.Location)"
      [(ngModel)]="locationChecked"
    />
    <label for="location-chk">{{ 'SearchComponent-LABEL-LOCATIONS' | translate }} </label>
  </div>
  <div class="col l3 s6">
    <input
      id="organization-chk"
      type="checkbox"
      (change)="toggleEnum($event.target.checked, searchEnum.Organization)"
      [(ngModel)]="organizationChecked"
    />
    <label for="organization-chk">{{ 'SearchComponent-LABEL-ORGANIZATIONS' | translate }} </label>
  </div>
</div>
