<app-loader [loading]="loading"></app-loader>
<div class="row" *ngIf="!editDisasterForm.controls['global'].value">
  <ul class="collapsible" materialize="collapsible" data-collapsible="accordion">
    <li *ngFor="let countryForm of editDisasterForm.controls['countryLocations'].controls; let i = index">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span *ngIf="!countryForm.controls['country'].value">
                {{ 'DISASTER_COMMON-SELECT_COUNTRY_TEXT' | translate }}
              </span>
              {{ countryForm.controls['country'].value?.name }}
            </mat-panel-title>
            <mat-panel-description>
              <span></span>
            </mat-panel-description>
            <i
              *ngIf="editDisasterForm.controls['countryLocations'].controls.length > 1"
              class="material-icons right link"
              (click)="onDeleteCountry(i)"
            >
              <mat-icon>delete</mat-icon>
            </i>
          </mat-expansion-panel-header>

          <div class="row" (change)="onChange(i)">
            <div class="select-country col s12 m2 label-text grey-text" style="margin-top: 1%">
              {{ 'DISASTER_COMMON-COUNTRY_LABEL' | translate }}
              <span class="sr-required-mark">*</span>
            </div>
            <div class="select-country col s6 m6 l5">
              <select id="countrySelect" [formControl]="countryForm.controls['country']" class="browser-default">
                <ng-container *ngIf="!countryForm.controls['country'].dirty">
                  <option [selected]="true" [ngValue]="countryForm.controls['country'].value">
                    {{ countryForm.controls['country'].value?.name }}
                  </option>
                </ng-container>
                <option
                  *ngFor="let country of countries"
                  [ngValue]="country"
                  [hidden]="excludeCountryLocation(countryForm, country)"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="col s3" style="margin-top: 1%">
              <input
                type="checkbox"
                id="{{ i }}"
                name="entireCountry"
                [formControl]="countryForm.controls['entireCountry']"
              />
              <label for="{{ i }}">Entire Country?</label>
            </div>
          </div>

          <div class="col s12">
            <location-map
              *ngIf="countryForm.controls['country'].value && !countryForm.controls['entireCountry'].value"
              [source]="countryMapLocations[i].mapLocations"
              [multi]="true"
              [country]="countryForm.controls['country'].value?.name"
              [showInfoWindow]="true"
              (locationAdded)="setCoordinates($event, i)"
              (radiusChanged)="updateRadius($event, i)"
              (locationRemoved)="unsetCoordinates(i)"
            >
            </location-map>
          </div>
          <br />
        </mat-expansion-panel>
      </mat-accordion>
    </li>
    <li class="select-country-footer row">
      <button
        class="btn cyan right"
        (click)="onAddCountry()"
        [disabled]="
          editDisasterForm.controls['countryLocations'].controls.length > 0 &&
          editDisasterForm.controls['countryLocations'].controls[
            editDisasterForm.controls['countryLocations'].controls.length - 1
          ].controls['country'].value?.name == null
        "
      >
        <i class="material-icons left">add</i>
        {{ 'DISASTER_COMMON-ADD_COUNTRY_BUTTON' | translate }}
      </button>
    </li>
  </ul>
</div>
