<ng2-slim-loading-bar></ng2-slim-loading-bar>
<smart-header (showSendMessage)="sendMessageModalActions.emit({ action: 'modal', params: ['open'] })"></smart-header>
<main>
  <router-outlet></router-outlet>
</main>
<smart-footer></smart-footer>
<simple-notifications [options]="options"></simple-notifications>
<div
  id="send-message-modal"
  class="modal card"
  materialize="modal"
  [materializeParams]="[{ dismissible: true }]"
  [materializeActions]="sendMessageModalActions"
>
  <send-message [modalActions]="sendMessageModalActions"></send-message>
</div>
