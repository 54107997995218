import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'givelively-donation',
  templateUrl: './givelively-donation.component.html',
  styleUrls: ['./givelively-donation.component.css'],
})
export class GiveLivelyDonationComponent implements OnInit {
  @Input() donateModalActions: any;
  @Input() tippingModalActions: any;
  @Input() tippingText: any;
  constructor() {}

  ngOnInit() {
    this.enableGiveLivelyWidget();
  }

  enableGiveLivelyWidget() {
    const gl = document.createElement('script');
    gl.src = 'https://secure.givelively.org/widgets/branded_donation/disaster-accountability-project.js';
    document.head.appendChild(gl);
  }
}
