<div *ngIf="emailRequestSent" class="my-font black-text center my-container">
  {{ 'ResetComponent-IF_ACCOUNT_EXISTS' | translate }}
  <span class="green-text">{{ email.value }}</span
  >, {{ 'ResetComponent-EMAIL_SENT_SHORTLY' | translate }}.
  <br />
  <a [routerLink]="['/login']">
    {{ 'ResetComponent-CLICK_HERE_LINK' | translate }}
  </a>
  {{ 'ResetComponent-RETURN_TO_LOGIN' | translate }}.
</div>
<app-loader [loading]="loading"></app-loader>
<div *ngIf="!emailRequestSent">
  <div class="valign-wrapper">
    <div class="row valign">
      <div class="col s20 hoverable reset-card card">
        <div class="input-field col s12 center row">
          <h5 class="center">
            {{ 'ResetComponent-RESET_PASSWORD_HEADING' | translate }}
          </h5>
        </div>
        <form>
          <div class="row margin">
            <div class="input-field col s12" [ngClass]="{ 'sr-has-error': !email.valid && email.touched }">
              <i class="material-icons prefix">email</i>
              <input type="email" id="email" [formControl]="email" />
              <label for="email">
                {{ 'ResetComponent-ENTER_EMAIL_ADDRESS_LABEL' | translate }}<span class="sr-required-mark"> *</span>
              </label>
              <div class="sr-error-area" *ngIf="!email.valid && email.touched">
                <div *ngIf="email.errors.required" class="sr-padding-required-text">
                  {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                </div>
                <div *ngIf="email.errors.mailFormat">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12">
              <button
                (click)="onReset()"
                [disabled]="!email.valid || isSubmitting"
                class="btn waves-effect waves-light cyan col s12"
              >
                {{ 'ResetComponent-SEND' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
