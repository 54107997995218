import { Component, Input } from '@angular/core';
import { FormControl, FormArray, Validators } from '@angular/forms';
import { ListComponentData } from '../list.model';
import { ListComponentService } from './list.service';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subscription } from 'rxjs';

declare const $: any;

@Component({
  selector: 'list-card',
  providers: [],
  templateUrl: './list.component.html',
  styleUrls: ['../../../common-card-content-title.css', './list.component.css'],
})
export class ListComponent {
  get config(): ListComponentData {
    return this.listConfig;
  }

  @Input()
  set config(listComponentData: ListComponentData) {
    this.listConfig = listComponentData;

    this.listService.get(listComponentData.endpoint, listComponentData.key).subscribe(
      (success) => {
        this.items = success;

        this.resetForm();
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationService, 'ListComponentData_TITLE');
      }
    );
  }

  VALIDATION_MAX_LENGTH = 64;

  private listConfig: ListComponentData;

  isSubmitting: boolean = false;
  isFormSubmittable: boolean = false;

  items: string[];
  extraItems: FormArray;
  extraItemsSubscription: Subscription;

  constructor(
    private listService: ListComponentService,
    private translateService: TranslateService,
    private notificationService: NotificationsService
  ) {}

  resetForm() {
    if (this.extraItemsSubscription) {
      this.extraItemsSubscription.unsubscribe();
    }

    this.isFormSubmittable = false;

    this.extraItems = new FormArray([new FormControl('', Validators.maxLength(this.VALIDATION_MAX_LENGTH))]);
    this.extraItemsSubscription = this.extraItems.valueChanges.subscribe((response: string[]) => {
      this.isFormSubmittable =
        this.extraItems.valid &&
        response
          .map((unrestricted: string): string => $.trim(unrestricted))
          .filter((value: string): boolean => value.length > 0)
          .reduce((left: string, right: string): string => left.concat(right), '').length > 0;
    });
  }

  addExtraItem(): void {
    this.extraItems.push(new FormControl('', Validators.maxLength(this.VALIDATION_MAX_LENGTH)));
  }

  onSubmit(): void {
    if (this.isSubmitting) {
      return;
    }

    if (!this.extraItems.valid) {
      return;
    }

    const itemsToSubmit = this.extraItems.value
      .map((unrestricted: string): string => $.trim(unrestricted))
      .filter((value: string): boolean => value.length > 0);

    if (0 === itemsToSubmit.length) {
      return;
    }

    if (!confirm(this.translateService.instant('ListComponent-ON_SAVE_CONFIRMATION'))) {
      return;
    }

    this.isSubmitting = true;

    this.listService
      .add(this.listConfig.endpoint, this.listConfig.propertyName, itemsToSubmit, this.listConfig.key)
      .subscribe(
        (success) => {
          this.items = success;
          this.resetForm();

          this.isSubmitting = false;

          this.notificationService.success(
            this.translateService.instant('ListComponentData_TITLE'),
            this.translateService.instant('UNIVERSAL-TOAST_SUCCESS')
          );
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'ListComponentData_TITLE');

          this.isSubmitting = false;
        }
      );
  }

  onClearChanges(): void {
    if (!confirm(this.translateService.instant('ListComponent-ON_CANCEL_CONFIRMATION'))) {
      return;
    }

    this.resetForm();
  }
}
