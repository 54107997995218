import { FormArray, FormGroup, FormControl } from '@angular/forms';

interface ValidationResult {
  [key: string]: boolean;
}

export class ServicesProvidedValidator {
  /**
   * test that all directProportion and indirectProportion add up to 100 or less
   * @param controlArray
   * @returns {null}
   */
  public static proportionTotal(controlArray: FormArray): ValidationResult {
    const invalidResult = {
      proportionTotal: true,
    };

    // fail
    if (!controlArray) {
      return invalidResult;
    }

    // compute total
    let total = 0;
    let indirect: number;
    let direct: number;

    for (const control of controlArray.controls) {
      direct = Number(control.get('directProportion').value);
      indirect = Number(control.get('indirectProportion').value);

      if (isNaN(direct) || isNaN(indirect)) {
        return invalidResult;
      }

      total = total + direct + indirect;
    }

    // validation should pass if total is less than or equal to 100
    if (total <= 100) {
      return null;
    }
    return invalidResult;
  }
}
