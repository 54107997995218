import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RegistrationSuccessComponent } from './registration-success.component';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: RegistrationSuccessComponent }])],
  exports: [RouterModule],
})
export class RegistrationSuccessRoutingModule {}

@NgModule({
  imports: [CommonModule, RegistrationSuccessRoutingModule, TranslateModule],
  declarations: [RegistrationSuccessComponent],
  providers: [],
})
export class RegistrationSuccessFeatureModule {}
