<div class="card">
  <app-loader [loading]="loading"></app-loader>
  <div class="card-content black-text">
    <form (submit)="onSubmit()" [formGroup]="editCountryForm">
      <div class="card-title sr-bold">{{ 'DashboardCountryCreateComponent-ADD_COUNTRY_TITLE' | translate }}</div>
      <div class="divider"></div>
      <div class="sr-required-notif">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>
      <br />

      <!-- Name field -->
      <div class="row">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error': !editCountryForm.controls['name'].valid && editCountryForm.controls['name'].touched
          }"
        >
          <input id="name" type="text" [formControl]="editCountryForm.controls['name']" />
          <label for="name" class="black-text"
            >{{ 'COUNTRY_COMMON-NAME_LABEL' | translate }}<span class="sr-required-mark">*</span>
          </label>

          <div
            class="sr-error-area"
            *ngIf="!editCountryForm.controls['name'].valid && editCountryForm.controls['name'].touched"
          >
            <div *ngIf="editCountryForm.controls['name'].errors.required">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="editCountryForm.controls['name'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 128 } }}
            </div>
          </div>
        </div>
      </div>

      <!-- Country Code field -->
      <div class="row">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error':
              !editCountryForm.controls['countryCode'].valid && editCountryForm.controls['countryCode'].touched
          }"
        >
          <input id="countryCode" type="text" [formControl]="editCountryForm.controls['countryCode']" />
          <label for="countryCode" class="black-text">{{ 'COUNTRY_COMMON-CODE_LABEL' | translate }}</label>

          <div
            class="sr-error-area"
            *ngIf="!editCountryForm.controls['countryCode'].valid && editCountryForm.controls['countryCode'].touched"
          >
            <div *ngIf="editCountryForm.controls['countryCode'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 8 } }}
            </div>
          </div>
        </div>
      </div>

      <!-- Long Abbreviation field -->
      <div class="row">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error':
              !editCountryForm.controls['longAbbreviation'].valid && editCountryForm.controls['countryCode'].touched
          }"
        >
          <input id="longAbbr" type="text" [formControl]="editCountryForm.controls['longAbbreviation']" />
          <label for="longAbbr" class="black-text">{{ 'COUNTRY_COMMON-LONG_ABBR_LABEL' | translate }}</label>

          <div
            class="sr-error-area"
            *ngIf="
              !editCountryForm.controls['longAbbreviation'].valid &&
              editCountryForm.controls['longAbbreviation'].touched
            "
          >
            <div *ngIf="editCountryForm.controls['longAbbreviation'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 8 } }}
            </div>
          </div>
        </div>
      </div>

      <!-- Abbreviation -->
      <div class="row">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error':
              !editCountryForm.controls['abbreviation'].valid && editCountryForm.controls['abbreviation'].touched
          }"
        >
          <input id="abbr" type="text" [formControl]="editCountryForm.controls['abbreviation']" />

          <label for="abbr" class="black-text"
            >{{ 'COUNTRY_COMMON-ABBR_LABEL' | translate }}<span class="sr-required-mark">*</span>
          </label>
          <div
            class="sr-error-area"
            *ngIf="!editCountryForm.controls['abbreviation'].valid && editCountryForm.controls['abbreviation'].touched"
          >
            <div *ngIf="editCountryForm.controls['abbreviation'].errors.required">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="editCountryForm.controls['abbreviation'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 8 } }}
            </div>
          </div>
        </div>
      </div>

      <!-- Recent Disaster field -->
      <div class="row">
        <div class="rich-text-input-label">
          {{ 'COUNTRY_COMMON-DISASTER-BACKGROUND_LABEL' | translate }}
        </div>
        <div class="input-field col s12">
          <rich-text-editor id="background" [form]="editCountryForm.controls['background']"> </rich-text-editor>
        </div>
      </div>

      <!-- Hashtag field -->
      <div class="row">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error':
              !editCountryForm.controls['countryHashtag'].valid && editCountryForm.controls['countryHashtag'].touched
          }"
        >
          <input id="hashtag" type="text" [formControl]="editCountryForm.controls['countryHashtag']" />
          <label for="hashtag" class="black-text">{{ 'COUNTRY_COMMON-HASHTAG_LABEL' | translate }} </label>
          <div
            class="sr-error-area"
            *ngIf="
              !editCountryForm.controls['countryHashtag'].valid && editCountryForm.controls['countryHashtag'].touched
            "
          >
            <div *ngIf="editCountryForm.controls['countryHashtag'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 128 } }}
            </div>
          </div>
          <p class="blue-text info shift-margin">
            {{ 'UNIVERSAL-INSTRUCTIONS_HASHTAG' | translate }}
          </p>
        </div>
      </div>

      <!-- Metadata Field -->
      <div class="row">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error': !editCountryForm.controls['metadata'].valid && editCountryForm.controls['metadata'].touched
          }"
        >
          <input id="metadata" type="text" [formControl]="editCountryForm.controls['metadata']" />
          <label for="metadata" class="black-text">{{ 'COUNTRY_COMMON-METADATA_LABEL' | translate }} </label>
          <div
            class="sr-error-area"
            *ngIf="!editCountryForm.controls['metadata'].valid && editCountryForm.controls['metadata'].touched"
          >
            <div *ngIf="editCountryForm.controls['metadata'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 40 } }}
            </div>
          </div>
          <p class="blue-text info shift-margin">
            {{ 'UNIVERSAL-INSTRUCTIONS_METADATA' | translate }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <div class="my_btns right">
            <button class="btn waves-effect waves-light cyan" [disabled]="isSubmitting || !editCountryForm.valid">
              {{ 'UNIVERSAL-NAVIGATION_ADD' | translate }}
            </button>
            <a class="btn cyan" (click)="onCancel()">{{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
