import { Component, OnInit } from '@angular/core';
import { CountryService } from '../country.service';
import { Country } from '../../../../models/Country';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'dashboardBaseCountries',
  templateUrl: './base.component.html',
  styleUrls: ['../../../common-base.css', './base.component.css'],
  providers: [CountryService],
})
export class DashboardCountryBaseComponent implements OnInit {
  countries: Country[];

  filterArg: string;

  itemsPerPageArg: number = 10;
  selectedPage: number = 0;

  sortField: string;
  sortDescending: boolean = true;
  loading: boolean = false;

  constructor(
    public service: CountryService,
    private translateService: TranslateService,
    private globalLoaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {}

  onItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.itemsPerPageArg = +updatedVal;
    this.selectedPage = 0;
  }

  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  ngOnInit() {
    this.globalLoaderService.start();
    this.loading = true;
    this.service.getCountries().subscribe(
      (data) => {
        this.countries = data;
        this.globalLoaderService.complete();
        this.loading = false;
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationService, 'COUNTRY_COMMON-TITLE');

        this.globalLoaderService.complete();
        this.loading = false;
      }
    );
  }

  onDelete(countryToDelete: Country) {
    if (
      confirm(
        this.translateService.instant('DashboardCountryBaseComponent-DELETE_COUNTRY_CONFIRMATION', {
          countryName: countryToDelete.name,
        })
      )
    ) {
      this.globalLoaderService.start();

      this.service.deleteCountry(countryToDelete.countryId).subscribe(
        (response) => {
          this.notificationService.success(
            this.translateService.instant('COUNTRY_COMMON-TITLE'),
            this.translateService.instant('DashboardCountryBaseComponent_SUCCESS_DELETE', {
              countryName: countryToDelete.name,
            })
          );

          // remove countryToDelete from countries array
          this.countries = this.countries.filter((item: Country) => item.countryId !== countryToDelete.countryId);

          // reset select page to an existing page if previously selected page no longer "exists"
          const numPages = Math.ceil(this.countries.length / this.itemsPerPageArg);
          this.selectedPage = this.selectedPage > numPages ? numPages : this.selectedPage;

          this.globalLoaderService.complete();
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'COUNTRY_COMMON-TITLE');

          this.globalLoaderService.complete();
        }
      );
    }
  }
}
