<!--footer -->

<footer class="page-footer no-padding grey lighten-3">
  <div class="row footer-wrapper">
    <ul class="social grey-text right-align col s12 m12 l3 push-l9">
      <li class="social-icons">
        <a href="https://www.facebook.com/DisasterAccountability" target="_blank">
          <img src="assets/images/facebook-icon-footer.png" alt="facebook" />
        </a>
      </li>
      <!-- prevent space char between inline-block elements
         -->
      <li class="social-icons">
        <a href="https://twitter.com/disasteraccount" target="_blank">
          <img src="assets/images/twitter-icon-footer.png" alt="twitter" />
        </a>
      </li>
      <!-- prevent space char between inline-block elements
         -->
      <li class="social-icons">
        <a href="https://www.linkedin.com/company/disaster-accountability-project" target="_blank">
          <img src="assets/images/linkedin-icon-footer.png" alt="linkedin" />
        </a>
      </li>
    </ul>
    <ul class="links left-align col s12 m12 l9 pull-l3">
      <li>
        <a class="grey-text text-darken-2" href="http://disasteraccountability.org/what-we-do/" target="_blank">
          {{ 'FooterComponent-WHAT_WE_DO_LINK' | translate }}
        </a>
      </li>
      <!-- prevent space char between inline-block elements
         -->
      <li>
        <a class="grey-text text-darken-2" [routerLink]="['/about-us']">
          {{ 'FooterComponent-ABOUT_US_LINK' | translate }}
        </a>
      </li>
      <!-- prevent space char between inline-block elements
         -->
      <li>
        <a class="grey-text text-darken-2" href="http://disasteraccountability.org/get-involved/" target="_blank">
          {{ 'FooterComponent-GET_INVOLVED_LINK' | translate }}
        </a>
      </li>
      <!-- prevent space char between inline-block elements
         -->
      <li>
        <a class="grey-text text-darken-2" href="http://disasteraccountability.org/news-media/" target="_blank">
          {{ 'FooterComponent-NEWS_+_MEDIA_LINK' | translate }}
        </a>
      </li>
      <!-- prevent space char between inline-block elements
         -->
      <li>
        <a class="grey-text text-darken-2" href="https://updates.smartresponse.org/" target="_blank">
          {{ 'FooterComponent-BLOG_LINK' | translate }}
        </a>
      </li>
      <!-- prevent space char between inline-block elements
         -->
      <li>
        <a class="grey-text text-darken-2" href="http://disasteraccountability.org/contact-us/" target="_blank">
          {{ 'FooterComponent-CONTACT_US' | translate }}
        </a>
      </li>
    </ul>
  </div>
  <div class="copyright grey lighten-2 grey-text text-darken-2">
    <ul class="links center-align">
      <li>
        <a class="grey-text text-darken-2" [routerLink]="['/volunteers']">
          {{ 'FooterComponent-OUR_VOLUNTEERS' | translate }}</a
        >
      </li>
      <li>
        <a class="grey-text text-darken-2" [routerLink]="['/privacy-policy']">
          {{ 'FooterComponent-PRIVACY' | translate }}</a
        >
      </li>
      <li class="grey-text">
        {{ 'FooterComponent-COPYRIGHT' | translate: { copyrightYear: copyrightYear } }}
      </li>
    </ul>
  </div>
</footer>
