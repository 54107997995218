<blocking-loader [blockingLoaderActions]="blockingLoaderEventEmitter"></blocking-loader>
<app-loader [loading]="loading"></app-loader>
<div class="card" *ngIf="noErrorResponse">
  <div class="card-content white-text">
    <div class="card-title center cyan">
      {{
        surveyDataInput?.country?.name
          ? ('LocationSpecificSurveyComponent-CARD_TITLE'
            | translate: { location: surveyDataInput?.country?.name | usaStateName })
          : ('UNIVERSAL-LOADING_MESSAGE' | translate)
      }}
      <i *ngIf="allowClosing" class="material-icons prefix right sr-link close-icon" (click)="onCloseLSS()"> close </i>
    </div>
    <div class="divider"></div>
    <div *ngIf="!surveyDataInput || loadingSurveyData">
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <div *ngIf="surveyDataInput && !loadingSurveyData">
      <p class="black-text">
        {{ 'SurveyCommon-CARD_INSTRUCTIONS' | translate }}
      </p>

      <p class="black-text" *ngIf="surveyDataInput.lastUpdatedUser && surveyDataInput.lastUpdatedDate">
        <ng-template [ngIf]="surveyDataInput.lastUpdatedUser.userType === UserType.SUPER">
          {{
            'UNIVERSAL-SURVEY_LAST_UPDATED_BY_SR_ADMIN'
              | translate
                : {
                    when: surveyDataInput.lastUpdatedDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT
                  }
          }}
        </ng-template>

        <ng-template [ngIf]="surveyDataInput.lastUpdatedUser.userType !== UserType.SUPER">
          {{
            'UNIVERSAL-SURVEY_LAST_UPDATED_AND_WHO'
              | translate
                : {
                    who: surveyDataInput.lastUpdatedUser.email,
                    when: surveyDataInput.lastUpdatedDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT
                  }
          }}
        </ng-template>
      </p>

      <ul class="collection">
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger" href="" (click)="showStep(0, $event)">
            {{
              'LocationSpecificSurveyComponent-STEP_1_TITLE'
                | translate: { location: surveyDataInput?.country?.name | usaStateName }
            }}
          </a>
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger" href="" (click)="showStep(1, $event)">
            {{ 'LocationSpecificSurveyComponent-STEP_2_TITLE' | translate }}
          </a>
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger" href="" (click)="showStep(2, $event)">
            {{
              'LocationSpecificSurveyComponent-STEP_3_TITLE'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </a>
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger" href="" (click)="showStep(3, $event)">
            {{ 'LocationSpecificSurveyComponent-STEP_4_TITLE' | translate }}
          </a>
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger" href="" (click)="showStep(4, $event)">
            {{ 'LocationSpecificSurveyComponent-STEP_5_TITLE' | translate }}
          </a>
        </li>
        <li class="collection-item grey lighten-3">
          <a class="modal-trigger" href="" (click)="showStep(5, $event)">
            {{ 'LocationSpecificSurveyComponent-STEP_6_TITLE' | translate }}
          </a>
        </li>

        <li class="collection-item grey lighten-3">
          <a class="modal-trigger" href="" (click)="showStep(6, $event)">
            {{
              'LocationSpecificSurveyComponent-STEP_7_TITLE'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </a>
        </li>

        <li class="collection-item grey lighten-3">
          <a class="modal-trigger" href="" (click)="showStep(7, $event)">
            {{ 'SurveyCommon-STEP_8_TITLE' | translate }}
          </a>
        </li>
      </ul>

      <p *ngIf="surveyDataInput.outdated" class="red-text">
        {{ 'UNIVERSAL-SURVEY_LABEL_SURVEY_OUTDATED_WARNING' | translate }}
      </p>
    </div>
  </div>
</div>

<!-- Modal's overlay -->
<div id="overlay" [style.display]="overlayDisplay" [class.overlay-visible]="overlayVisible"></div>

<div *ngIf="surveyDataInput">
  <!-- LSS Steps -->
  <!-- Modal lss step 1 -->
  <div
    id="lss-step1-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step1ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="1"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>

      <div class="modal-step-title">
        {{
          'LocationSpecificSurveyComponent-STEP_1_TITLE'
            | translate: { location: surveyDataInput?.country?.name | usaStateName }
        }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="row margin">
        <!-- office address -->
        <div id="ques11" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12">
          <label class="sr-label" for="step1_address_1">
            {{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 1 } }}
          </label>
          <div class="input-field spacing col s12">
            <i class="material-icons prefix">home</i>
            <input
              id="step1_address_1"
              type="text"
              placeholder="{{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 1 } }}"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="lssForm['officeAddress'].valueControl.controls['address1']"
            />
          </div>

          <label class="sr-label" for="step1_address_2">
            {{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 2 } }}
          </label>
          <div class="input-field spacing col s12">
            <i class="material-icons prefix">home</i>
            <input
              id="step1_address_2"
              type="text"
              placeholder="{{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 2 } }}"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="lssForm['officeAddress'].valueControl.controls['address2']"
            />
          </div>

          <label class="sr-label" for="step1_city">
            {{ 'UNIVERSAL-ADDRESS_CITY' | translate }}
          </label>
          <div class="input-field spacing col s12">
            <i class="material-icons prefix">location_city</i>
            <input
              id="step1_city"
              type="text"
              placeholder="{{ 'UNIVERSAL-ADDRESS_CITY' | translate }}"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="lssForm['officeAddress'].valueControl.controls['city']"
            />
          </div>

          <label class="sr-label" for="step1_state_province">
            {{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}
          </label>
          <div class="input-field spacing col s12">
            <i class="material-icons prefix">location_city</i>
            <input
              id="step1_state_province"
              type="text"
              placeholder="{{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="lssForm['officeAddress'].valueControl.controls['state']"
            />
          </div>
          <last-edited-metadata class="no-left-spacing" [formElement]="lssForm['officeAddress']"></last-edited-metadata>
        </div>
        <!-- new ein goverment id -->

        <div class="spacing-div col s12">
          <label class="sr-label" for="gov_id">
            {{ 'LocationSpecificSurveyComponent-STEP_1_GOV_ID' | translate }}
          </label>
          <div class="input-field col s12">
            <i class="material-icons prefix">vpn_key</i>
            <input
              id="gov_id"
              type="text"
              placeholder="{{ 'LocationSpecificSurveyComponent-STEP_1_GOV_ID' | translate }}"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="lssForm['govIdOrEin'].valueControl"
            />
          </div>
          <last-edited-metadata [formElement]="lssForm['govIdOrEin']"></last-edited-metadata>
        </div>

        <div id="ques12" class="spacing-div col s12">
          <label class="sr-label" for="gov_id2">
            {{ 'LocationSpecificSurveyComponent-STEP_1_GOV_ID2' | translate }}
          </label>
          <div class="input-field col s12">
            <i class="material-icons prefix">vpn_key</i>
            <input
              id="gov_id2"
              type="text"
              placeholder="{{ 'LocationSpecificSurveyComponent-STEP_1_GOV_ID2' | translate }}"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="lssForm['govIdOrEin2'].valueControl"
            />
          </div>
          <last-edited-metadata [formElement]="lssForm['govIdOrEin2']"></last-edited-metadata>
        </div>

        <!-- start of operations date -->
        <div id="ques12" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12">
          <label class="sr-label" for="step1_startOpDateYear">
            {{
              'LocationSpecificSurveyComponent-STEP_1_Q_BEGIN_OPERATIONS'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !lssForm['startOpDateYear'].valueControl.valid &&
                (lssForm['startOpDateYear'].valueControl.touched || invalidOnInit?.singles?.startOpDateYear)
            }"
          >
            <i class="material-icons prefix">today</i>
            <input type="hidden" id="step1_startOpDate" [formControl]="lssForm['startOpDate'].valueControl" />
            <input
              type="text"
              id="step1_startOpDateYear"
              placeholder="{{
                'LocationSpecificSurveyComponent-STEP_1_Q_BEGIN_OPERATIONS'
                  | translate
                    : {
                        orgName: surveyDataInput.organizationName,
                        location: surveyDataInput?.country?.name | usaStateName
                      }
              }}"
              [formControl]="lssForm['startOpDateYear'].valueControl"
            />

            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !lssForm['startOpDateYear'].valueControl.valid &&
                (lssForm['startOpDateYear'].valueControl.touched || invalidOnInit?.singles?.startOpDateYear)
              "
            >
              <div *ngIf="lssForm['startOpDateYear'].valueControl.hasError('isYear')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_YEAR' | translate }}
              </div>
              <div *ngIf="lssForm['startOpDateYear'].valueControl.hasError('pastYear')">
                {{ 'UNIVERSAL-FORM_ERRORS_PAST_YEAR' | translate }}
              </div>
              <div *ngIf="lssForm['startOpDateYear'].valueControl.hasError('minimumYear')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_YEAR_TOO_LOW' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="lssForm['startOpDate']"></last-edited-metadata>
        </div>

        <!-- brief history of activities -->
        <div class="spacing-div col s12">
          <label class="sr-label" for="step1_brief_history">
            {{
              'LocationSpecificSurveyComponent-STEP_1_Q_BRIEF_HISTORY'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </label>
          <div class="input-field col s12">
            <textarea
              id="step1_brief_history"
              class="materialize-textarea"
              materialize="characterCounter"
              length="600"
              maxlength="600"
              [formControl]="lssForm['activityHistory'].valueControl"
            >
            </textarea>
          </div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['activityHistory']"
          ></last-edited-metadata>
        </div>
        <div id="ques13" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 4 } }}
        </div>
        <!-- video url of activity history  -->
        <div class="spacing-div col s12">
          <label class="sr-label" for="step1_history_video_url">
            {{
              'LocationSpecificSurveyComponent-STEP_1_Q_HISTORY_VIDEO_URL'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !lssForm['activityHistoryVideoUrl'].valueControl.valid &&
                (lssForm['activityHistoryVideoUrl'].valueControl.touched ||
                  invalidOnInit?.singles?.activityHistoryVideoUrl)
            }"
          >
            <i class="material-icons prefix">link</i>
            <input
              id="step1_history_video_url"
              materialize="characterCounter"
              length="256"
              maxlength="256"
              type="url"
              [formControl]="lssForm['activityHistoryVideoUrl'].valueControl"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !lssForm['activityHistoryVideoUrl'].valueControl.valid &&
                (lssForm['activityHistoryVideoUrl'].valueControl.touched ||
                  invalidOnInit?.singles?.activityHistoryVideoUrl)
              "
            >
              <div *ngIf="lssForm['activityHistoryVideoUrl'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
              </div>
              <div *ngIf="lssForm['activityHistoryVideoUrl'].valueControl.hasError('vimeoYoutubeUrlFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_VIMEO_YOUTUBE_URL' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['activityHistoryVideoUrl']"
          ></last-edited-metadata>
        </div>

        <!-- video url of current program  -->
        <div class="spacing-div col s12">
          <label class="sr-label" for="step1_current_program_video_url">
            {{
              'LocationSpecificSurveyComponent-STEP_1_Q_CURRENT_PROGRAM0_VIDEO_URL'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </label>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !lssForm['currentProgramVideoUrl'].valueControl.valid &&
                (lssForm['currentProgramVideoUrl'].valueControl.touched ||
                  invalidOnInit?.singles?.currentProgramVideoUrl)
            }"
          >
            <i class="material-icons prefix">link</i>
            <input
              id="step1_current_program_video_url"
              materialize="characterCounter"
              length="256"
              maxlength="256"
              type="url"
              [formControl]="lssForm['currentProgramVideoUrl'].valueControl"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !lssForm['currentProgramVideoUrl'].valueControl.valid &&
                (lssForm['currentProgramVideoUrl'].valueControl.touched ||
                  invalidOnInit?.singles?.currentProgramVideoUrl)
              "
            >
              <div *ngIf="lssForm['currentProgramVideoUrl'].valueControl.errors.pattern">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
              </div>
              <div *ngIf="lssForm['currentProgramVideoUrl'].valueControl.hasError('vimeoYoutubeUrlFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_VIMEO_YOUTUBE_URL' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['currentProgramVideoUrl']"
          ></last-edited-metadata>
        </div>

        <!-- organization representative -->
        <div id="ques14" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: 4 } }}
        </div>
        <p>
          {{
            'LocationSpecificSurveyComponent-STEP_1_Q_STAFF_REP_DON'
              | translate
                : {
                    orgName: surveyDataInput.organizationName,
                    location: surveyDataInput?.country?.name | usaStateName
                  }
          }}
        </p>

        <div class="spacing-div col s12">
          <label class="sr-label" for="step1_first_name">
            {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
          </label>
          <div class="input-field spacing col s12">
            <i class="material-icons prefix">face</i>
            <input
              id="step1_first_name"
              type="text"
              placeholder="{{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="lssForm['repContact'].valueControl.controls['firstName']"
            />
          </div>

          <label class="sr-label" for="step1_last_name">
            {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
          </label>
          <div class="input-field spacing col s12">
            <i class="material-icons prefix">face</i>
            <input
              id="step1_last_name"
              type="text"
              placeholder="{{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="lssForm['repContact'].valueControl.controls['lastName']"
            />
          </div>

          <label class="sr-label" for="step1_title">
            {{ 'UNIVERSAL-CONTACT_TITLE' | translate }}
          </label>
          <div class="input-field spacing col s12">
            <i class="material-icons prefix">mode_edit</i>
            <input
              id="step1_title"
              type="text"
              placeholder="{{ 'UNIVERSAL-CONTACT_TITLE' | translate }}"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="lssForm['repContact'].valueControl.controls['title']"
            />
          </div>

          <label class="sr-label" for="staffRepresentativeEmail">
            {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
          </label>
          <div
            class="input-field spacing col s12"
            [ngClass]="{
              'sr-has-error':
                !lssForm['repContact'].valueControl.controls['email'].valid &&
                (lssForm['repContact'].valueControl.controls['email'].touched || invalidOnInit?.maps?.repContact?.email)
            }"
          >
            <i class="material-icons prefix">email</i>
            <input
              id="staffRepresentativeEmail"
              type="text"
              placeholder="{{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="lssForm['repContact'].valueControl.controls['email']"
            />
            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !lssForm['repContact'].valueControl.controls['email'].valid &&
                (lssForm['repContact'].valueControl.controls['email'].touched || invalidOnInit?.maps?.repContact?.email)
              "
            >
              <div *ngIf="lssForm['repContact'].valueControl.controls['email'].hasError('mailFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
              </div>
            </div>
          </div>

          <label class="sr-label" for="step1_call">
            {{ 'UNIVERSAL-CONTACT_PHONE' | translate }}
          </label>
          <div class="input-field spacing col s12">
            <i class="material-icons prefix">call</i>
            <input
              id="step1_call"
              type="text"
              placeholder="{{ 'UNIVERSAL-CONTACT_PHONE' | translate }}"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="lssForm['repContact'].valueControl.controls['phone1']"
            />
            <p class="blue-text info shift-margin">
              {{ 'UNIVERSAL-INSTRUCTIONS_PHONE_FULL' | translate }}
            </p>
            <div
              class="shift-margin sr-error-area"
              *ngIf="
                !lssForm['repContact'].valueControl.controls['phone1'].valid &&
                lssForm['repContact'].valueControl.controls['phone1'].touched
              "
            >
              <div *ngIf="lssForm['repContact'].valueControl.controls['phone1'].errors.phoneFormat">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PHONE' | translate }}
              </div>
            </div>
          </div>

          <div class="input-field col s12 shift-padding">
            <div class="col m2 s12 label-text">
              {{ 'UNIVERSAL-ADDRESS_COUNTRY' | translate }}
            </div>
            <select
              id="rep-country"
              [formControl]="lssForm['repContact'].valueControl.controls['country']"
              class="browser-default col m6 s12"
            >
              <option [ngValue]="null">
                {{ 'SurveyCommon-NO_COUNTRY_SELECTED' | translate }}
              </option>
              <option *ngFor="let country of countries" [ngValue]="country">
                {{ country.name }}
              </option>
            </select>
          </div>
          <last-edited-metadata class="no-left-spacing" [formElement]="lssForm['repContact']"></last-edited-metadata>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button class="waves-effect waves-light btn cyan darken-3 left" (click)="onCancel(step1ModalActions)">
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>

      <button
        [disabled]="!canSaveAndExit()"
        class="waves-effect waves-light btn cyan darken-3"
        (click)="onSaveAndExit(step1ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>

      <div class="right">
        <button
          *ngIf="!canSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [0])"
          class="modal-trigger modal-close waves-effect waves-light btn red darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>

        <button
          (click)="onModalAction(onNext, [0])"
          class="modal-trigger modal-close waves-effect waves-light btn cyan"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Modal lss step 2 -->

  <div
    id="lss-step2-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step2ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="2"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'LocationSpecificSurveyComponent-STEP_2_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="row margin">
        <!-- services provided -->
        <div id="ques21" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: step2QuestCount } }}
        </div>
        <p>
          {{
            'LocationSpecificSurveyComponent-STEP_2_Q_SERVICES_PROVIDED'
              | translate
                : {
                    orgName: surveyDataInput.organizationName,
                    location: surveyDataInput?.country?.name | usaStateName
                  }
          }}
        </p>

        <p class="info blue-text">
          {{ 'SurveyCommon-Q_SP_PERC_RULE' | translate }}
        </p>

        <div class="row">
          <div class="no-padding col s12">
            <div class="input-field col s12" id="disaster_table_div">
              <table id="disaster_service_table" class="grey lighten-3 table-wrapper centered striped">
                <thead>
                  <tr>
                    <th class="hide-on-small-only">&nbsp;</th>
                    <th>
                      {{ 'SurveyCommon-Q_SP_SERVICES' | translate }}
                    </th>
                    <th>
                      {{ 'SurveyCommon-Q_SP_DIRECT' | translate }}
                    </th>
                    <th>
                      {{ 'SurveyCommon-Q_SP_INDIRECT' | translate }}
                      <br />
                      <span class="hide-on-med-and-down">{{ 'SurveyCommon-Q_SP_FUND_GRANT' | translate }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="4">
                      <div class="red-text">
                        {{
                          'SurveyCommon-Q_SP_Total'
                            | translate
                              : {
                                  percent: calculateTotalPercentServicesProvided()
                                }
                        }}
                      </div>
                      <div class="red-text" *ngIf="calculateTotalPercentServicesProvided() > 100">
                        {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PERCENTAGE_OVER_100' | translate }}
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let formGroup of lssForm['serviceList'].valueControl.controls; let i = index">
                    <td class="hide-on-small-only">
                      <img
                        width="64"
                        height="64"
                        src="{{ formGroup.controls['serviceProvided'].controls['fileURI'].value }}"
                      />
                    </td>
                    <td>
                      <p class="column1">
                        {{ formGroup.controls['serviceProvided'].controls['name'].value }}
                      </p>
                    </td>
                    <td>
                      <div
                        class="input-field col s12"
                        [ngClass]="{
                          'sr-has-error':
                            !formGroup.controls['directProportion'].valid &&
                            (formGroup.controls['directProportion'].touched ||
                              invalidOnInit?.arrays?.serviceListDirect[i]),
                          'hide-input':
                            formGroup.controls['serviceProvided'].controls['name'].value == 'Grant-making / Re-granting'
                        }"
                      >
                        <input
                          class="column2"
                          type="text"
                          placeholder="%"
                          [formControl]="formGroup.controls['directProportion']"
                        />
                        <div
                          class="sr-error-area"
                          *ngIf="
                            !formGroup.controls['directProportion'].valid &&
                            (formGroup.controls['directProportion'].touched ||
                              invalidOnInit?.arrays?.serviceListDirect[i])
                          "
                        >
                          <div
                            class="percentage_error"
                            *ngIf="formGroup.controls['directProportion'].hasError('pattern')"
                          >
                            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PERCENTAGE' | translate }}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div
                        class="input-field col s12"
                        [ngClass]="{
                          'sr-has-error':
                            !formGroup.controls['indirectProportion'].valid &&
                            (formGroup.controls['indirectProportion'].touched ||
                              invalidOnInit?.arrays?.serviceListIndirect[i])
                        }"
                      >
                        <input
                          class="column3"
                          type="text"
                          placeholder="%"
                          [formControl]="formGroup.controls['indirectProportion']"
                        />
                        <div
                          class="sr-error-area"
                          *ngIf="
                            !formGroup.controls['indirectProportion'].valid &&
                            (formGroup.controls['indirectProportion'].touched ||
                              invalidOnInit?.arrays?.serviceListIndirect[i])
                          "
                        >
                          <div
                            class="percentage_error"
                            *ngIf="formGroup.controls['indirectProportion'].hasError('pattern')"
                          >
                            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PERCENTAGE' | translate }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <div class="red-text">
                        {{
                          'SurveyCommon-Q_SP_Total'
                            | translate
                              : {
                                  percent: calculateTotalPercentServicesProvided()
                                }
                        }}
                      </div>
                      <div class="red-text" *ngIf="calculateTotalPercentServicesProvided() > 100">
                        {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PERCENTAGE_OVER_100' | translate }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
            <last-edited-metadata
              class="no-left-spacing no-padding"
              [formElement]="lssForm['serviceList']"
            ></last-edited-metadata>
          </div>
        </div>

        <!-- relief/recovery items -->
        <div id="ques22" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: step2QuestCount } }}
        </div>
        <div class="spacing-div col s12">
          <div class="input-field col s12 relief-table-div">
            <p>
              {{
                'LocationSpecificSurveyComponent-STEP_2_Q_RELIEF_RECOVERY_TITLE'
                  | translate
                    : {
                        orgName: surveyDataInput.organizationName,
                        location: surveyDataInput?.country?.name | usaStateName
                      }
              }}
            </p>
            <table class="relief-table">
              <thead>
                <tr>
                  <th class="center-align">
                    {{ 'LocationSpecificSurveyComponent-STEP_2_Q_RELIEF_RECOVERY_LABEL' | translate }}
                  </th>
                  <th class="center-align">
                    {{ 'LocationSpecificSurveyComponent-STEP_2_Q_RR_QUANTITY' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let formGroup of lssForm['reliefTypeList'].valueControl.controls; let i = index">
                  <td>
                    {{ formGroup.controls['name'].value }}
                  </td>

                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !formGroup.controls['itemCount'].valid &&
                          (formGroup.controls['itemCount'].touched || invalidOnInit?.arrays?.reliefTypeList[i])
                      }"
                    >
                      <input class="column3" type="text" [formControl]="formGroup.controls['itemCount']" />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !formGroup.controls['itemCount'].valid &&
                          (formGroup.controls['itemCount'].touched || invalidOnInit?.arrays?.reliefTypeList[i])
                        "
                      >
                        <div class="percentage_error" *ngIf="formGroup.controls['itemCount'].hasError('pattern')">
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="info blue-text">
              {{ 'LocationSpecificSurveyComponent-STEP_2_Q_RR_MISSING' | translate }}
            </p>
          </div>
          <last-edited-metadata
            class="no-left-spacing no-padding"
            [formElement]="lssForm['reliefTypeList']"
          ></last-edited-metadata>
        </div>

        <!-- accept donations of imported goods -->
        <div *ngIf="!hideAcceptImportedGoodsQuest">
          <div id="ques23" class="red-text question-count">
            {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: step2QuestCount } }}
          </div>
          <div class="spacing-div col s12">
            <div class="input-field radio-button-spacing col s12">
              <p>
                {{ 'LocationSpecificSurveyComponent-STEP_2_Q_ACCEPT_DONATIONS_OF_IMPORTED_GOODS' | translate }}
              </p>

              <input
                [formControl]="lssForm['acceptDonationsOfImportedGoods'].valueControl.get('acceptImportedGoods')"
                [value]="true"
                (change)="handleAcceptDonationsOfImportedGoodsChange()"
                id="accept_donations_of_imported_goods_true_rb"
                class="with-gap"
                name="accept_donations_of_imported_goods"
                type="radio"
              />

              <label for="accept_donations_of_imported_goods_true_rb">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>

              <input
                [formControl]="lssForm['acceptDonationsOfImportedGoods'].valueControl.get('acceptImportedGoods')"
                [value]="false"
                (change)="handleAcceptDonationsOfImportedGoodsChange()"
                id="accept_donations_of_imported_goods_false_rb"
                class="with-gap"
                name="accept_donations_of_imported_goods"
                type="radio"
              />

              <label for="accept_donations_of_imported_goods_false_rb">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </div>

            <div *ngIf="lssForm['acceptDonationsOfImportedGoods'].valueControl.get('acceptImportedGoods').value">
              <div class="input-field col s12">
                <p>
                  {{
                    'LocationSpecificSurveyComponent-STEP_2_Q_ORG_PREV_IMPORTED_GOODS_INTO_LOC'
                      | translate: { location: surveyDataInput?.country?.name }
                  }}
                </p>
              </div>
              <div class="input-field spacing col s12">
                <input
                  [formControl]="
                    lssForm['acceptDonationsOfImportedGoods'].valueControl.get('hasPreviouslyAcceptedImportedGoods')
                  "
                  [value]="true"
                  id="prev_accepted_imported_goods_true_rb"
                  class="with-gap"
                  name="prev_accepted_imported_goods"
                  type="radio"
                />

                <label for="prev_accepted_imported_goods_true_rb">
                  {{ 'UNIVERSAL-YES' | translate }}
                </label>

                <input
                  [formControl]="
                    lssForm['acceptDonationsOfImportedGoods'].valueControl.get('hasPreviouslyAcceptedImportedGoods')
                  "
                  [value]="false"
                  id="prev_accepted_imported_goods_false_rb"
                  class="with-gap"
                  name="prev_accepted_imported_goods"
                  type="radio"
                />

                <label for="prev_accepted_imported_goods_false_rb">
                  {{ 'UNIVERSAL-NO' | translate }}
                </label>
              </div>

              <div
                *ngIf="
                  lssForm['acceptDonationsOfImportedGoods'].valueControl.get('acceptImportedGoods').value &&
                  lssForm['acceptDonationsOfImportedGoods'].valueControl.get('acceptImportedGoods').value != null
                "
              >
                <div class="spacing-div col s12">
                  <label class="sr-label" for="importer_of_goods_full_name">
                    {{ 'LocationSpecificSurveyComponent-STEP_2_Q_FULL_NAME_OF_THE_IMPORTER_OF_GOODS' | translate }}
                  </label>
                  <div class="input-field col s12">
                    <textarea
                      [formControl]="lssForm['acceptDonationsOfImportedGoods'].valueControl.get('importerOfRecord')"
                      id="importer_of_goods_full_name"
                      class="materialize-textarea"
                      materialize="characterCounter"
                      length="200"
                      maxlength="200"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="lssForm['acceptDonationsOfImportedGoods'].valueControl.get('acceptImportedGoods').value != null"
              class="col s12"
            >
              <last-edited-metadata
                class="no-left-spacing no-padding"
                [formElement]="lssForm['acceptDonationsOfImportedGoods']"
              ></last-edited-metadata>
            </div>
          </div>
        </div>

        <!-- stages of disaster -->
        <div class="spacing-div col s12">
          <ng-template [ngIf]="!hideAcceptImportedGoodsQuest">
            <div id="ques24" class="red-text question-count">
              {{
                'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: step2QuestCount }
              }}
            </div>
          </ng-template>

          <ng-template [ngIf]="hideAcceptImportedGoodsQuest">
            <div id="ques23" class="red-text question-count">
              {{
                'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: step2QuestCount }
              }}
            </div>
          </ng-template>
          <p class="stages-p">
            {{
              'LocationSpecificSurveyComponent-STEP_2_Q_DISASTER_STAGES'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </p>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_PREP' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('preparedStage')"
                [value]="true"
                name="preparedness_checkbox"
                type="radio"
                id="preparedness_checkbox_yes"
                class="with-gap"
              />
              <label for="preparedness_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('preparedStage')"
                [value]="false"
                name="preparedness_checkbox"
                type="radio"
                id="preparedness_checkbox_no"
                class="with-gap"
              />
              <label for="preparedness_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_MITI' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('mitigationStage')"
                [value]="true"
                name="mitigationStage_checkbox"
                type="radio"
                id="mitigationStage_checkbox_yes"
                class="with-gap"
              />
              <label for="mitigationStage_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('mitigationStage')"
                [value]="false"
                name="mitigationStage_checkbox"
                type="radio"
                id="mitigationStage_checkbox_no"
                class="with-gap"
              />
              <label for="mitigationStage_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_RELI' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('reliefStage')"
                [value]="true"
                name="reliefStage_checkbox"
                type="radio"
                id="reliefStage_checkbox_yes"
                class="with-gap"
              />
              <label for="reliefStage_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('reliefStage')"
                [value]="false"
                name="reliefStage_checkbox"
                type="radio"
                id="reliefStage_checkbox_no"
                class="with-gap"
              />
              <label for="reliefStage_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_RESP' | translate }}: </span>
            <span class="col m9 s12 responsiveRadioButtons">
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('responseStage')"
                [value]="true"
                name="responseStage_checkbox"
                type="radio"
                id="responseStage_checkbox_yes"
                class="with-gap"
              />
              <label for="responseStage_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('responseStage')"
                [value]="false"
                name="responseStage_checkbox"
                type="radio"
                id="responseStage_checkbox_no"
                class="with-gap"
              />
              <label for="responseStage_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <div class="input-field spacing col s12 horizontal-radio">
            <span class="col m3 s12"> {{ 'SurveyCommon-Q_DS_RECO' | translate }}: </span>
            <span class="col m6 s12 responsiveRadioButtons">
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('recoveryStage')"
                [value]="true"
                name="recoveryStage_checkbox"
                type="radio"
                id="recoveryStage_checkbox_yes"
                class="with-gap"
              />
              <label for="recoveryStage_checkbox_yes">
                {{ 'UNIVERSAL-YES' | translate }}
              </label>
              <input
                [formControl]="lssForm['disasterStageOfWork'].valueControl.get('recoveryStage')"
                [value]="false"
                name="recoveryStage_checkbox"
                type="radio"
                id="recoveryStage_checkbox_no"
                class="with-gap"
              />
              <label for="recoveryStage_checkbox_no">
                {{ 'UNIVERSAL-NO' | translate }}
              </label>
            </span>
          </div>

          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['disasterStageOfWork']"
          ></last-edited-metadata>
        </div>

        <!-- emergency plan -->
        <div class="spacing-div col s12">
          <ng-template [ngIf]="!hideAcceptImportedGoodsQuest">
            <div id="ques25" class="red-text question-count">
              {{
                'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 5, QuestionCount: step2QuestCount }
              }}
            </div>
          </ng-template>

          <ng-template [ngIf]="hideAcceptImportedGoodsQuest">
            <div id="ques24" class="red-text question-count">
              {{
                'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: step2QuestCount }
              }}
            </div>
          </ng-template>

          <div class="input-field radio-button-spacing col s12">
            <p>
              {{
                'LocationSpecificSurveyComponent-STEP_2_Q_EMER_PLAN'
                  | translate: { orgName: surveyDataInput.organizationName }
              }}
            </p>

            <input
              [formControl]="lssForm['disasterResponsePlan'].valueControl.get('hasValue')"
              [value]="true"
              id="response_plan_true_rb"
              class="with-gap"
              name="response_plan"
              type="radio"
            />

            <label for="response_plan_true_rb">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>

            <input
              [formControl]="lssForm['disasterResponsePlan'].valueControl.get('hasValue')"
              [value]="false"
              (change)="handleDisasterResponsePlanChange()"
              id="response_plan_false_rb"
              class="with-gap"
              name="response_plan"
              type="radio"
            />

            <label for="response_plan_false_rb">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>

          <div *ngIf="lssForm['disasterResponsePlan'].valueControl.get('hasValue').value">
            <div class="input-field col s12">
              <p>
                {{ 'LocationSpecificSurveyComponent-PLAN_UPLOAD_OR_PASTE' | translate }}
              </p>
            </div>

            <div *ngIf="lssForm['disasterResponsePlan'].valueControl.get('fileId').value" class="input-field col s12">
              <p>
                {{ 'UNIVERSAL-FILE_PERMANENT_DELETE_WARNING' | translate }}
              </p>

              <p>
                <a
                  class="btn"
                  target="_blank"
                  href="{{
                    documentService.getDocumentDownloadLink(
                      lssForm['disasterResponsePlan'].valueControl.get('fileId').value
                    )
                  }}"
                >
                  {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                </a>
              </p>

              <p>
                <button class="btn" (click)="lssForm['disasterResponsePlan'].valueControl.get('fileId').setValue(null)">
                  {{ 'UNIVERSAL-DELETE_CURRENT_FILE' | translate }}
                </button>
              </p>
            </div>

            <div class="input-field spacing col s12">
              <div class="file-field input-field">
                <div class="btn cyan">
                  <span>
                    {{ 'UNIVERSAL-FILE' | translate }}
                  </span>
                  <input
                    (change)="onFileSelected($event, lssForm['disasterResponsePlan'].valueControl.get('fileId'))"
                    type="file"
                  />
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path" type="text" />
                </div>
              </div>
              <div>
                <p class="sr-img-guidelines">
                  {{ 'LogoUploadComponent-MAXIMUM_FILE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

                  {{ 'LogoUploadComponent-DEFAULT_ALLOWED_FILE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
                </p>
              </div>
            </div>

            <div
              class="input-field spacing col s12"
              [ngClass]="{
                'sr-has-error':
                  !lssForm['disasterResponsePlan'].valueControl.get('webLink').valid &&
                  (lssForm['disasterResponsePlan'].valueControl.get('webLink').touched ||
                    invalidOnInit?.maps?.disasterResponsePlan?.webLink)
              }"
            >
              <i class="material-icons prefix">link</i>
              <input
                class="column3"
                id="responsePlanUrl"
                type="text"
                placeholder="{{ 'UNIVERSAL-LINK_PLACEHOLDER' | translate }}"
                materialize="characterCounter"
                length="512"
                maxlength="512"
                [formControl]="lssForm['disasterResponsePlan'].valueControl.get('webLink')"
              />
              <label for="responsePlanUrl" class="active">
                {{ 'LocationSpecificSurveyComponent-PLAN_PASTE_LINK' | translate }}
              </label>
              <div
                class="sr-error-area shift-margin"
                *ngIf="
                  !lssForm['disasterResponsePlan'].valueControl.get('webLink').valid &&
                  (lssForm['disasterResponsePlan'].valueControl.get('webLink').touched ||
                    invalidOnInit?.maps?.disasterResponsePlan?.webLink)
                "
              >
                <div *ngIf="lssForm['disasterResponsePlan'].valueControl.get('webLink').hasError('pattern')">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="lssForm['disasterResponsePlan'].valueControl.get('hasValue').value != null" class="col s12">
            <last-edited-metadata
              class="no-left-spacing no-padding"
              [formElement]="lssForm['disasterResponsePlan']"
            ></last-edited-metadata>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button class="waves-effect waves-light btn cyan darken-3 left" (click)="onCancel(step2ModalActions)">
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        [disabled]="!canSaveAndExit()"
        class="waves-effect waves-light btn cyan darken-3"
        (click)="onSaveAndExit(step2ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          *ngIf="!canSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [1])"
          class="modal-trigger modal-close waves-effect waves-light btn red darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [1])"
          class="modal-trigger modal-action modal-close waves-effect waves-light btn cyan"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [1])"
          class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Step 3 -->

  <div
    id="lss-step3-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step3ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="3"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{
          'LocationSpecificSurveyComponent-STEP_3_TITLE'
            | translate
              : {
                  orgName: surveyDataInput.organizationName,
                  location: surveyDataInput?.country?.name | usaStateName
                }
        }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>

      <div class="row margin">
        <div id="ques31" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 3 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="input-field no-padding col s12">
            <p class="margin-top-zero">
              <span>
                {{
                  'UNIVERSAL-SURVEY_QUESTIONS_LOCAL_STAFF'
                    | translate
                      : {
                          orgName: surveyDataInput.organizationName,
                          location: surveyDataInput?.country?.name | usaStateName
                        }
                }}
              </span>
            </p>
            <table class="centered grey lighten-3 table-wrapper staff-table">
              <thead>
                <tr>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                  </th>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                  </th>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['localStaff'].valueControl.controls['mgmtStaffCount'].valid &&
                          (lssForm['localStaff'].valueControl.controls['mgmtStaffCount'].touched ||
                            invalidOnInit?.maps?.localStaff?.mgmtStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['localStaff'].valueControl.controls['mgmtStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['localStaff'].valueControl.controls['mgmtStaffCount'].valid &&
                          (lssForm['localStaff'].valueControl.controls['mgmtStaffCount'].touched ||
                            invalidOnInit?.maps?.localStaff?.mgmtStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="lssForm['localStaff'].valueControl.controls['mgmtStaffCount'].hasError('pattern')"
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['localStaff'].valueControl.controls['professionalStaffCount'].valid &&
                          (lssForm['localStaff'].valueControl.controls['professionalStaffCount'].touched ||
                            invalidOnInit?.maps?.localStaff?.professionalStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['localStaff'].valueControl.controls['professionalStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['localStaff'].valueControl.controls['professionalStaffCount'].valid &&
                          (lssForm['localStaff'].valueControl.controls['professionalStaffCount'].touched ||
                            invalidOnInit?.maps?.localStaff?.professionalStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="
                            lssForm['localStaff'].valueControl.controls['professionalStaffCount'].hasError('pattern')
                          "
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['localStaff'].valueControl.controls['supportStaffCount'].valid &&
                          (lssForm['localStaff'].valueControl.controls['supportStaffCount'].touched ||
                            invalidOnInit?.maps?.localStaff?.supportStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['localStaff'].valueControl.controls['supportStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['localStaff'].valueControl.controls['supportStaffCount'].valid &&
                          (lssForm['localStaff'].valueControl.controls['supportStaffCount'].touched ||
                            invalidOnInit?.maps?.localStaff?.supportStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="lssForm['localStaff'].valueControl.controls['supportStaffCount'].hasError('pattern')"
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p class="info blue-text">
                {{ 'SurveyCommon-Q_EXCLUSIVE_EMPLOYEES' | translate: { orgName: surveyDataInput.organizationName } }}
              </p>
            </div>
          </div>
          <last-edited-metadata
            class="no-left-spacing no-padding"
            [formElement]="lssForm['localStaff']"
          ></last-edited-metadata>
        </div>
        <div id="ques32" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 3 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="input-field no-padding col s12">
            <p class="margin-top-zero">
              <span>
                {{
                  'UNIVERSAL-SURVEY_QUESTIONS_NON_LOCAL_STAFF'
                    | translate
                      : {
                          orgName: surveyDataInput.organizationName,
                          location: surveyDataInput?.country?.name | usaStateName
                        }
                }}
              </span>
            </p>
            <table class="centered grey lighten-3 table-wrapper staff-table">
              <thead>
                <tr>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                  </th>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                  </th>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['nonLocalStaff'].valueControl.controls['mgmtStaffCount'].valid &&
                          (lssForm['nonLocalStaff'].valueControl.controls['mgmtStaffCount'].touched ||
                            invalidOnInit?.maps?.nonLocalStaff?.mgmtStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['nonLocalStaff'].valueControl.controls['mgmtStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['nonLocalStaff'].valueControl.controls['mgmtStaffCount'].valid &&
                          (lssForm['nonLocalStaff'].valueControl.controls['mgmtStaffCount'].touched ||
                            invalidOnInit?.maps?.nonLocalStaff?.mgmtStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="lssForm['nonLocalStaff'].valueControl.controls['mgmtStaffCount'].hasError('pattern')"
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['nonLocalStaff'].valueControl.controls['professionalStaffCount'].valid &&
                          (lssForm['nonLocalStaff'].valueControl.controls['professionalStaffCount'].touched ||
                            invalidOnInit?.maps?.nonLocalStaff?.professionalStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['nonLocalStaff'].valueControl.controls['professionalStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['nonLocalStaff'].valueControl.controls['professionalStaffCount'].valid &&
                          (lssForm['nonLocalStaff'].valueControl.controls['professionalStaffCount'].touched ||
                            invalidOnInit?.maps?.nonLocalStaff?.professionalStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="
                            lssForm['nonLocalStaff'].valueControl.controls['professionalStaffCount'].hasError('pattern')
                          "
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['nonLocalStaff'].valueControl.controls['supportStaffCount'].valid &&
                          (lssForm['nonLocalStaff'].valueControl.controls['supportStaffCount'].touched ||
                            invalidOnInit?.maps?.nonLocalStaff?.supportStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['nonLocalStaff'].valueControl.controls['supportStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['nonLocalStaff'].valueControl.controls['supportStaffCount'].valid &&
                          (lssForm['nonLocalStaff'].valueControl.controls['supportStaffCount'].touched ||
                            invalidOnInit?.maps?.nonLocalStaff?.supportStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="
                            lssForm['nonLocalStaff'].valueControl.controls['supportStaffCount'].hasError('pattern')
                          "
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p class="info blue-text">
                {{ 'SurveyCommon-Q_EXCLUSIVE_EMPLOYEES' | translate: { orgName: surveyDataInput.organizationName } }}
              </p>
            </div>
          </div>
          <last-edited-metadata
            class="no-left-spacing no-padding"
            [formElement]="lssForm['nonLocalStaff']"
          ></last-edited-metadata>
        </div>

        <div id="ques33" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 3 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="input-field no-padding col s12">
            <p class="margin-top-zero">
              <span>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_VOLUNTEER_STAFF' | translate }}
              </span>
            </p>
            <table class="centered grey lighten-3 table-wrapper staff-table">
              <thead>
                <tr>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
                  </th>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
                  </th>
                  <th>
                    {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['volunteerStaff'].valueControl.controls['mgmtStaffCount'].valid &&
                          (lssForm['volunteerStaff'].valueControl.controls['mgmtStaffCount'].touched ||
                            invalidOnInit?.maps?.volunteerStaff?.mgmtStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['volunteerStaff'].valueControl.controls['mgmtStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['volunteerStaff'].valueControl.controls['mgmtStaffCount'].valid &&
                          (lssForm['volunteerStaff'].valueControl.controls['mgmtStaffCount'].touched ||
                            invalidOnInit?.maps?.volunteerStaff?.mgmtStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="lssForm['volunteerStaff'].valueControl.controls['mgmtStaffCount'].hasError('pattern')"
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['volunteerStaff'].valueControl.controls['professionalStaffCount'].valid &&
                          (lssForm['volunteerStaff'].valueControl.controls['professionalStaffCount'].touched ||
                            invalidOnInit?.maps?.volunteerStaff?.professionalStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['volunteerStaff'].valueControl.controls['professionalStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['volunteerStaff'].valueControl.controls['professionalStaffCount'].valid &&
                          (lssForm['volunteerStaff'].valueControl.controls['professionalStaffCount'].touched ||
                            invalidOnInit?.maps?.volunteerStaff?.professionalStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="
                            lssForm['volunteerStaff'].valueControl.controls['professionalStaffCount'].hasError(
                              'pattern'
                            )
                          "
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error':
                          !lssForm['volunteerStaff'].valueControl.controls['supportStaffCount'].valid &&
                          (lssForm['volunteerStaff'].valueControl.controls['supportStaffCount'].touched ||
                            invalidOnInit?.maps?.volunteerStaff?.supportStaffCount)
                      }"
                    >
                      <input
                        class="column2"
                        type="text"
                        [formControl]="lssForm['volunteerStaff'].valueControl.controls['supportStaffCount']"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !lssForm['volunteerStaff'].valueControl.controls['supportStaffCount'].valid &&
                          (lssForm['volunteerStaff'].valueControl.controls['supportStaffCount'].touched ||
                            invalidOnInit?.maps?.volunteerStaff?.supportStaffCount)
                        "
                      >
                        <div
                          class="percentage_error"
                          *ngIf="
                            lssForm['volunteerStaff'].valueControl.controls['supportStaffCount'].hasError('pattern')
                          "
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p class="info blue-text">
                {{ 'SurveyCommon-Q_EXCLUSIVE_EMPLOYEES' | translate: { orgName: surveyDataInput.organizationName } }}
              </p>
            </div>
          </div>
          <last-edited-metadata
            class="no-left-spacing no-padding"
            [formElement]="lssForm['volunteerStaff']"
          ></last-edited-metadata>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="divider"></div>
      <button class="waves-effect waves-light btn cyan darken-3 left" (click)="onCancel(step3ModalActions)">
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        [disabled]="!canSaveAndExit()"
        class="waves-effect waves-light btn cyan darken-3"
        (click)="onSaveAndExit(step3ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          *ngIf="!canSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [2])"
          class="modal-trigger modal-close waves-effect waves-light btn red darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [2])"
          class="modal-trigger modal-action modal-close waves-effect waves-light btn cyan"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [2])"
          class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Step 4 -->

  <div
    id="lss-step4-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step4ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="4"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>

      <div class="modal-step-title">
        {{ 'LocationSpecificSurveyComponent-STEP_4_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="row margin">
        <div id="ques41" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 3 } }}
        </div>

        <!-- overall budget -->
        <div class="spacing-div col s12">
          <label class="sr-label" for="overallBudget">
            {{
              'LocationSpecificSurveyComponent-STEP_4_Q_OVERALL_BUDGET'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </label>

          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !lssForm['overallBudget'].valueControl.valid &&
                (lssForm['overallBudget'].valueControl.touched || invalidOnInit?.singles?.overallBudget)
            }"
          >
            <!-- currency -->
            <select
              id="currencyCodeList"
              class="browser-default col s4 m3 l2"
              [formControl]="lssForm['overallBudget'].valueControl.controls['currency']"
            >
              <option *ngFor="let currencyCode of currencyCodeList" [ngValue]="currencyCode">
                {{ currencyCode }}
              </option>
            </select>

            <div class="col s8 m9 l10">
              <!-- amount -->
              <input
                type="text"
                id="overallBudget"
                autofocus
                [formControl]="lssForm['overallBudget'].valueControl.controls['amount']"
              />
              <!-- errors -->
              <div
                class="sr-error-area"
                *ngIf="
                  !lssForm['overallBudget'].valueControl.controls['amount'].valid &&
                  (lssForm['overallBudget'].valueControl.touched || invalidOnInit?.singles?.overallBudget)
                "
              >
                <div
                  class="percentage_error"
                  *ngIf="lssForm['overallBudget'].valueControl.controls['amount'].hasError('pattern')"
                >
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_USD' | translate }}
                </div>
              </div>
            </div>
          </div>
          <last-edited-metadata class="no-left-spacing" [formElement]="lssForm['overallBudget']"></last-edited-metadata>
        </div>

        <!-- fiscal year ending -->
        <div id="ques42" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 3 } }}
        </div>
        <div class="spacing-div col s12">
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !lssForm['fiscalYearEnding'].valueControl.valid &&
                (lssForm['fiscalYearEnding'].valueControl.touched || invalidOnInit?.singles?.fiscalYearEnding)
            }"
          >
            <i class="material-icons prefix">today</i>
            <input
              type="date"
              min="1000-01-01"
              max="3000-01-01"
              id="step4_fiscalYearEnding"
              [formControl]="lssForm['fiscalYearEnding'].valueControl"
            />
            <label class="active" for="step4_fiscalYearEnding">
              {{ 'CommonGOBSurvey-Q_ORG_FISCAL_YR_ENDING' | translate }}
            </label>

            <div
              class="sr-error-area shift-margin"
              *ngIf="
                !lssForm['fiscalYearEnding'].valueControl.valid &&
                (lssForm['fiscalYearEnding'].valueControl.touched || invalidOnInit?.singles?.fiscalYearEnding)
              "
            >
              <div *ngIf="lssForm['fiscalYearEnding'].valueControl.hasError('dateFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
              </div>
              <div *ngIf="lssForm['fiscalYearEnding'].valueControl.hasError('fiscalYearDate')">
                {{ 'UNIVERSAL-FORM_ERRORS_FISCAL_YEAR_DATE' | translate }}
              </div>
            </div>
          </div>
          <last-edited-metadata [formElement]="lssForm['fiscalYearEnding']"></last-edited-metadata>
        </div>

        <!-- annual reports -->
        <div id="ques43" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 3 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="radio-button-spacing col s12">
            <p>
              {{
                'LocationSpecificSurveyComponent-STEP_4_Q_ANNUAL_REPORTS'
                  | translate
                    : {
                        orgName: surveyDataInput.organizationName,
                        location: surveyDataInput?.country?.name | usaStateName
                      }
              }}
            </p>

            <input
              [formControl]="lssForm['annualReport'].valueControl.get('hasValue')"
              [value]="true"
              id="publish_program_yes_rb"
              class="with-gap"
              name="publish_program"
              type="radio"
            />
            <label for="publish_program_yes_rb">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>
            <br />
            <input
              [formControl]="lssForm['annualReport'].valueControl.get('hasValue')"
              [value]="false"
              (change)="handleAnnualReportChange()"
              class="with-gap"
              name="publish_program"
              type="radio"
              id="publish_program_no_rb"
            />
            <label for="publish_program_no_rb">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>

          <div *ngIf="lssForm['annualReport'].valueControl.get('hasValue').value">
            <div class="input-field col s12">
              <p>
                {{ 'LocationSpecificSurveyComponent-STEP_4_Q_RECENT_REPORT' | translate }}
              </p>
            </div>
            <div *ngIf="lssForm['annualReport'].valueControl.get('fileId').value" class="input-field col s12">
              <p>
                {{ 'UNIVERSAL-FILE_PERMANENT_DELETE_WARNING' | translate }}
              </p>
              <p>
                <a
                  class="btn"
                  target="_blank"
                  href="{{
                    documentService.getDocumentDownloadLink(lssForm['annualReport'].valueControl.get('fileId').value)
                  }}"
                >
                  {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                </a>
              </p>
              <p>
                <button class="btn" (click)="lssForm['annualReport'].valueControl.get('fileId').setValue(null)">
                  {{ 'UNIVERSAL-DELETE_CURRENT_FILE' | translate }}
                </button>
              </p>
            </div>

            <div class="input-field spacing col s12">
              <div class="file-field input-field">
                <div class="btn cyan">
                  <span>
                    {{ 'UNIVERSAL-FILE' | translate }}
                  </span>
                  <input
                    (change)="onFileSelected($event, lssForm['annualReport'].valueControl.get('fileId'))"
                    type="file"
                  />
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path" type="text" />
                </div>
              </div>
              <div>
                <p class="sr-img-guidelines">
                  {{ 'LogoUploadComponent-MAXIMUM_FILE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

                  {{ 'LogoUploadComponent-DEFAULT_ALLOWED_FILE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
                </p>
              </div>
            </div>

            <div
              class="input-field spacing col s12"
              [ngClass]="{
                'sr-has-error':
                  !lssForm['annualReport'].valueControl.get('webLink').valid &&
                  (lssForm['annualReport'].valueControl.get('webLink').touched ||
                    invalidOnInit?.maps?.annualReport?.webLink)
              }"
            >
              <i class="material-icons prefix">link</i>
              <input
                class="column3"
                id="responsePlanUrl"
                type="text"
                placeholder="{{ 'UNIVERSAL-LINK_PLACEHOLDER' | translate }}"
                materialize="characterCounter"
                length="512"
                maxlength="512"
                [formControl]="lssForm['annualReport'].valueControl.get('webLink')"
              />
              <label for="responsePlanUrl" class="active">
                {{ 'LocationSpecificSurveyComponent-PLAN_PASTE_LINK' | translate }}
              </label>
              <div
                class="sr-error-area shift-margin"
                *ngIf="
                  !lssForm['annualReport'].valueControl.get('webLink').valid &&
                  (lssForm['annualReport'].valueControl.get('webLink').touched ||
                    invalidOnInit?.maps?.annualReport?.webLink)
                "
              >
                <div *ngIf="lssForm['annualReport'].valueControl.get('webLink').hasError('pattern')">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="lssForm['annualReport'].valueControl.get('hasValue').value != null" class="col s12">
            <last-edited-metadata
              class="no-left-spacing no-padding"
              [formElement]="lssForm['annualReport']"
            ></last-edited-metadata>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="divider"></div>
      <button class="waves-effect waves-light btn cyan darken-3 left" (click)="onCancel(step4ModalActions)">
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        [disabled]="!canSaveAndExit()"
        class="waves-effect waves-light btn cyan darken-3"
        (click)="onSaveAndExit(step4ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          *ngIf="!canSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [3])"
          class="modal-trigger modal-close waves-effect waves-light btn red darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [3])"
          class="modal-trigger modal-action modal-close waves-effect waves-light btn cyan"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [3])"
          class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Step 5 -->

  <div
    id="lss-step5-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step5ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="5"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>
      <div class="modal-step-title">
        {{ 'LocationSpecificSurveyComponent-STEP_5_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="row margin">
        <div id="ques51" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="input-field radio-button-spacing col s12" id="prot">
            <p>
              {{
                'LocationSpecificSurveyComponent-STEP_5_Q_MONITOR_PROT'
                  | translate
                    : {
                        orgName: surveyDataInput.organizationName,
                        location: surveyDataInput?.country?.name | usaStateName
                      }
              }}
            </p>

            <input
              [formControl]="lssForm['monitorProtocol'].valueControl.get('hasValue')"
              [value]="true"
              id="evaluation_protocol_yes_rb"
              class="with-gap"
              name="evaluation_protocol"
              type="radio"
            />

            <label for="evaluation_protocol_yes_rb">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>

            <br />

            <input
              [formControl]="lssForm['monitorProtocol'].valueControl.get('hasValue')"
              [value]="false"
              (change)="handleMonitorProtocolChange()"
              class="with-gap"
              name="evaluation_protocol"
              type="radio"
              id="evaluation_protocol_no_rb"
            />

            <label for="evaluation_protocol_no_rb">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>

          <div *ngIf="lssForm['monitorProtocol'].valueControl.get('hasValue').value">
            <div class="input-field col s12">
              <p>
                {{ 'LocationSpecificSurveyComponent-STEP_5_Q_SHARE_PROT' | translate }}
              </p>
            </div>

            <div *ngIf="lssForm['monitorProtocol'].valueControl.get('fileId').value" class="input-field col s12">
              <p>
                {{ 'UNIVERSAL-FILE_PERMANENT_DELETE_WARNING' | translate }}
              </p>
              <p>
                <a
                  class="btn"
                  target="_blank"
                  href="{{
                    documentService.getDocumentDownloadLink(lssForm['monitorProtocol'].valueControl.get('fileId').value)
                  }}"
                >
                  {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                </a>
              </p>
              <p>
                <button class="btn" (click)="lssForm['monitorProtocol'].valueControl.get('fileId').setValue(null)">
                  {{ 'UNIVERSAL-DELETE_CURRENT_FILE' | translate }}
                </button>
              </p>
            </div>

            <div class="input-field spacing col s12">
              <div class="file-field input-field">
                <div class="btn cyan">
                  <span>
                    {{ 'UNIVERSAL-FILE' | translate }}
                  </span>
                  <input
                    (change)="onFileSelected($event, lssForm['monitorProtocol'].valueControl.get('fileId'))"
                    type="file"
                  />
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path" type="text" />
                </div>
              </div>
              <div>
                <p class="sr-img-guidelines">
                  {{ 'LogoUploadComponent-MAXIMUM_FILE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}
                  <br />

                  {{ 'LogoUploadComponent-DEFAULT_ALLOWED_FILE_TYPES_DISPLAY_MESSAGE' | translate }}
                  <br />
                </p>
              </div>
            </div>

            <div
              class="input-field spacing col s12"
              [ngClass]="{
                'sr-has-error':
                  !lssForm['monitorProtocol'].valueControl.get('webLink').valid &&
                  (lssForm['monitorProtocol'].valueControl.get('webLink').touched ||
                    invalidOnInit?.maps?.monitorProtocol?.webLink)
              }"
            >
              <i class="material-icons prefix">link</i>
              <input
                class="column3"
                id="responsePlanUrl"
                type="text"
                placeholder="{{ 'UNIVERSAL-LINK_PLACEHOLDER' | translate }}"
                materialize="characterCounter"
                length="512"
                maxlength="512"
                [formControl]="lssForm['monitorProtocol'].valueControl.get('webLink')"
              />
              <label for="responsePlanUrl" class="active">
                {{ 'LocationSpecificSurveyComponent-PLAN_PASTE_LINK' | translate }}
              </label>
              <div
                class="sr-error-area shift-margin"
                *ngIf="
                  !lssForm['monitorProtocol'].valueControl.get('webLink').valid &&
                  (lssForm['monitorProtocol'].valueControl.get('webLink').touched ||
                    invalidOnInit?.maps?.monitorProtocol?.webLink)
                "
              >
                <div *ngIf="lssForm['monitorProtocol'].valueControl.get('webLink').hasError('pattern')">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="lssForm['monitorProtocol'].valueControl.get('hasValue').value != null" class="col s12">
            <last-edited-metadata
              class="no-left-spacing no-padding"
              [formElement]="lssForm['monitorProtocol']"
            ></last-edited-metadata>
          </div>
        </div>

        <div id="ques52" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 4 } }}
        </div>
        <div class="col s12" [class.spacing-div]="!lssForm['engagement'].valueControl.get('hasEngagement').value">
          <div class="input-field radio-button-spacing col s12" id="partner">
            <p>
              {{
                'LocationSpecificSurveyComponent-STEP_5_Q_PARTNER'
                  | translate
                    : {
                        orgName: surveyDataInput.organizationName,
                        location: surveyDataInput?.country?.name | usaStateName
                      }
              }}
            </p>
            <input
              [formControl]="lssForm['engagement'].valueControl.get('hasEngagement')"
              [value]="true"
              id="partnership_yes_rb"
              class="with-gap"
              name="partnership"
              type="radio"
            />
            <label for="partnership_yes_rb">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>
            <br />
            <input
              [formControl]="lssForm['engagement'].valueControl.get('hasEngagement')"
              [value]="false"
              class="with-gap"
              name="partnership"
              type="radio"
              id="partnership_no_rb"
            />
            <label for="partnership_no_rb">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>
        </div>
        <div
          class="col s12"
          [class.spacing-div]="lssForm['engagement'].valueControl.get('hasEngagement').value"
          *ngIf="lssForm['engagement'].valueControl.get('hasEngagement').value"
        >
          <div class="input-field col s12">
            <p>
              {{ 'LocationSpecificSurveyComponent-STEP_5_Q_LIST_PARTNERS' | translate }}
            </p>
            <div
              class="input-field input-list col s12"
              *ngFor="
                let formControl of lssForm['engagement'].valueControl.get('engagementList').controls;
                let i = index
              "
              [ngClass]="{
                'sr-has-error': !formControl.valid && (formControl.touched || invalidOnInit?.arrays?.engagementList[i])
              }"
            >
              <i class="material-icons prefix">business</i>
              <input
                id="{{ 'engagementList' + i }}"
                type="text"
                materialize="characterCounter"
                length="256"
                maxlength="256"
                [formControl]="formControl"
                [placeholder]="'SurveyCommon-Q_LIST_PARTNER_NAME' | translate"
              />
              <i class="material-icons right sr-link" (click)="onDeleteEngagement(i)"> delete </i>
              <div
                class="sr-error-area shift-margin"
                *ngIf="!formControl.valid && (formControl.touched || invalidOnInit?.arrays?.engagementList[i])"
              >
                <div *ngIf="formControl.value.length > 0">
                  <div *ngIf="formControl.hasError('leadingTrailingWhiteSpaceFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
                  </div>
                  <div *ngIf="formControl.hasError('orgFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PARTNERNAME' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin">
              <button (click)="onAddEngagement()" class="btn waves-effect waves-light cyan">
                {{ 'SurveyCommon-ADD_PARTNER_BUTTON' | translate }}
              </button>
            </div>
          </div>
          <last-edited-metadata class="no-left-spacing" [formElement]="lssForm['engagement']"></last-edited-metadata>
        </div>

        <div id="ques53" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 4 } }}
        </div>
        <div class="col s12">
             
          <p>
            {{ 'LocationSpecificSurveyComponent-STEP_5_Q_LIST_NETWORKS' | translate }}
          </p>
          <div class="row">
            <div
              class="input-field input-list col s12"
              [ngClass]="{
                'sr-has-error':
                  !formGroup.controls['membershipList'].valid &&
                  (formGroup.controls['membershipList'].touched || invalidOnInit?.arrays?.membershipList[i])
              }"
              *ngFor="let formGroup of lssForm['membershipList'].valueControl.controls; let i = index"
            >
              <i class="material-icons prefix">business</i>
              <input
                id="{{ 'step53_membershipList' + i }}"
                type="text"
                materialize="characterCounter"
                length="128"
                maxlength="128"
                [formControl]="formGroup.controls['membershipList']"
                [placeholder]="'LocationSpecificSurveyComponent-STEP_5_Q_LIST_NETWORK_NAME' | translate"
              />
              <i class="material-icons right sr-link" (click)="onDeleteMembershipList(i)"> delete </i>
              <div
                class="sr-error-area shift-margin"
                *ngIf="
                  !formGroup.controls['membershipList'].valid &&
                  (formGroup.controls['membershipList'].touched || invalidOnInit?.arrays?.membershipList[i])
                "
              >
                <div *ngIf="formGroup.controls['membershipList'].value.length > 0">
                  <div *ngIf="formGroup.controls['membershipList'].hasError('leadingTrailingWhiteSpaceFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
                  </div>
                  <div *ngIf="formGroup.controls['membershipList'].hasError('orgFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_INVALID_MEMBERSHIPNAME' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin">
              <button (click)="onAddMembershipList()" class="btn waves-effect waves-light cyan">
                {{ 'LocationSpecificSurveyComponent-STEP_5_ADD_MEMBERSHIP_BUTTON' | translate }}
              </button>
            </div>
          </div>
             
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['membershipList']"
          ></last-edited-metadata>
        </div>
        <!-- Vetting Agencies -->
        <div id="ques54" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: 4 } }}
        </div>
        <div class="col s12">
          <p>
            {{
              'LocationSpecificSurveyComponent-STEP_5_Q_VETTING_AGENCY'
                | translate: { orgName: surveyDataInput.organizationName }
            }}
          </p>
          <p>
            {{
              'LocationSpecificSurveyComponent-STEP_5_Q_VETTING_AGENCY_DETAILS'
                | translate: { orgName: surveyDataInput.organizationName }
            }}
          </p>
        </div>
        <div class="col s12">
          <div class="search-input spacing-div">
            <input
              [ngModel]="filterArg"
              (ngModelChange)="selectedPage = 0; filterArg = $event"
              class="validate"
              id="va_search"
              type="text"
              materialize="characterCounter"
              length="256"
              maxlength="256"
            />
            <label class="grey-text" for="va_search">{{ 'UNIVERSAL-SEARCH' | translate }}</label>
          </div>
          <div class="va_frame">
            <div
              *ngFor="
                let vettingAgency of selectedVettingAgencies
                  | genericSearchFilterPipe: filterArg:['agencyId', 'agencyName'];
                let i = index
              "
            >
              <input
                id="{{ 'step54_vettingAgencies_' + i }}"
                type="checkbox"
                [value]="vettingAgency['agencyName']"
                [checked]="vettingAgency.checkedSelection"
                [disabled]="lssForm['vettingAgencies'].valueControl.controls.length === 3"
                (change)="onVettingAgencyCheckboxChange($event, vettingAgency['agencyId'])"
              />
              <label class="padding" for="{{ 'step54_vettingAgencies_' + i }}">
                {{ vettingAgency['agencyName'] }}
              </label>
            </div>
          </div>
          <div class="blue-text info" *ngIf="lssForm['vettingAgencies'].valueControl.controls.length === 3">
            {{ 'LocationSpecificSurveyComponent-STEP_5_VA_EXCEED_ERROR' | translate: { num: 3 } }}
          </div>
        </div>
        <div class="col 12">
          <p class="blue-text info">
            {{ 'LocationSpecificSurveyComponent-STEP_5_Q_VA_PERMANENT_DELETE_WARNING' | translate }}
          </p>
        </div>
        <div class="col 12" [innerHTML]="'LocationSpecificSurveyComponent-STEP_5_Q_VA_REQUEST_TEXT' | translate"></div>
        <div class="col s12">
          <ul class="collapsible" materialize="collapsible" data-collapsible="accordion">
            <li
              *ngFor="let formGroup of lssForm['vettingAgencies'].valueControl.controls; let i = index"
              class="custom-collapsible"
            >
              <div class="collapsible-header cyan-text">
                <span class="custom-text sr-bold">{{ formGroup.controls['agencyName'].value }}</span>
                <i class="material-icons right sr-link col s2 m1" (click)="onDeleteVettingAgency(i)">delete</i>
                <i class="material-icons right sr-link col s2 m1 expand">expand_more</i>
              </div>
              <div class="collapsible-body">
                <div class="row">
                  <div class="survey">
                    <div class="spacing"></div>
                    <div class="spacing-div col s12">
                      <div class="input-field col s12">
                        <i class="material-icons prefix">today</i>
                        <input
                          type="date"
                          min="1000-01-01"
                          max="3000-01-01"
                          id="{{ 'step54_startDate_' + i }}"
                          [formControl]="formGroup.controls['startDate']"
                          [ngClass]="{
                            'sr-has-error':
                              (!formGroup.controls['startDate'].valid && formGroup.controls['startDate'].value) ||
                              !formGroup.valid
                          }"
                        />
                        <label class="active" for="{{ 'step54_startDate_' + i }}">
                          {{ 'UNIVERSAL-START_DATE_LABEL' | translate }}:
                          <span class="sr-label cyan-text sr-answer">{{
                            formGroup.controls['startDate'].value | date: 'MMM dd yyyy'
                          }}</span>
                        </label>
                        <div
                          class="sr-error-area shift-margin"
                          *ngIf="
                            (!formGroup.controls['startDate'].valid && formGroup.controls['startDate'].touched) ||
                            !formGroup.valid
                          "
                        >
                          <div *ngIf="formGroup.controls['startDate'].hasError('dateFormat')">
                            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
                          </div>
                          <div *ngIf="formGroup.hasError('startExpiration')">
                            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_START_EXPIRATION_DATES' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="spacing-div col s12">
                      <div class="input-field col s12">
                        <i class="material-icons prefix">today</i>
                        <input
                          type="date"
                          min="1000-01-01"
                          max="3000-01-01"
                          id="{{ 'step54_expirationDate_' + i }}"
                          [formControl]="formGroup.controls['expirationDate']"
                          [ngClass]="{
                            'sr-has-error':
                              (!formGroup.controls['expirationDate'].valid &&
                                formGroup.controls['expirationDate'].value) ||
                              !formGroup.valid
                          }"
                        />
                        <label class="active" for="{{ 'step54_expirationDate_' + i }}">
                          {{ 'UNIVERSAL-EXPIRATION_DATE_LABEL' | translate }}:
                          <span class="sr-label cyan-text sr-answer">{{
                            formGroup.controls['expirationDate'].value | date: 'MMM dd yyyy'
                          }}</span>
                        </label>
                        <div
                          class="sr-error-area shift-margin"
                          *ngIf="
                            (!formGroup.controls['expirationDate'].valid && formGroup.controls['startDate'].touched) ||
                            !formGroup.valid
                          "
                        >
                          <div *ngIf="formGroup.controls['expirationDate'].hasError('dateFormat')">
                            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
                          </div>
                          <div *ngIf="formGroup.hasError('startExpiration')">
                            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_START_EXPIRATION_DATES' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col s12 mb-1">
                      <div class="sr-bold">
                        {{ 'LocationSpecificSurveyComponent-STEP_5_Q_VA_LETTER' | translate }}
                      </div>
                      <div class="red-text">
                        {{ 'UNIVERSAL-FILE_PERMANENT_DELETE_WARNING' | translate }}
                      </div>
                      <div *ngFor="let letter of formGroup.controls['vettingAgencyLetterDtos'].controls; let l = index">
                        <div class="input-field col s12">
                          <div class="sr-bold">
                            {{ 'LocationSpecificSurveyComponent-STEP_5_LETTER_FILE' | translate: { index: l + 1 } }}
                          </div>
                          <div *ngIf="letter.value.fileId" class="col s12">
                            <div *ngFor="let vettingAgency of selectedVettingAgencies">
                              <div *ngIf="formGroup.controls['agencyId'].value === vettingAgency.agencyId">
                                <p class="sr-label cyan-text" *ngIf="checkFileId(letter.value.fileId)">
                                  {{
                                    'LocationSpecificSurveyComponent-STEP_5_FILE_NAME'
                                      | translate
                                        : {
                                            name: vettingAgency.vettingAgencyLetterDtos[l].fileName
                                          }
                                  }}
                                </p>
                                <p>
                                  <a
                                    class="btn"
                                    target="_blank"
                                    href="{{ documentService.getDocumentDownloadLink(letter.value.fileId) }}"
                                  >
                                    {{ 'UNIVERSAL-DOWNLOAD_NAME_FILE' | translate: { index: l + 1 } }}
                                  </a>
                                </p>
                                <p>
                                  <button class="btn red" (click)="onDeleteVettingAgencyFile(formGroup, l)">
                                    {{ 'UNIVERSAL-DELETE_NAME_FILE' | translate: { index: l + 1 } }}
                                  </button>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col s12" *ngIf="!checkFileId(letter.value.fileId)">
                          <div class="file-field input-field">
                            <div class="btn cyan">
                              <span>
                                {{ 'UNIVERSAL-FILE' | translate }}
                              </span>

                              <input
                                id="{{ 'step54_file_id_' + l }}"
                                (change)="onFileSelected($event, letter.get('fileId'))"
                                type="file"
                              />
                            </div>
                            <div class="file-path-wrapper">
                              <input class="file-path mb-0" type="text" />
                            </div>
                            <div>
                              <p class="sr-img-guidelines">
                                {{ 'LogoUploadComponent-MAXIMUM_FILE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

                                {{ 'LogoUploadComponent-DEFAULT_ALLOWED_FILE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="spacing col 12">
                      <button (click)="onAddVettingAgencyLetter(i)" class="btn waves-effect waves-light cyan">
                        {{ 'LocationSpecificSurveyComponent-STEP_5_ADD_VA_LETTER_BUTTON' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <last-edited-metadata class="no-left-spacing" [formElement]="lssForm['vettingAgencies']"></last-edited-metadata>
        <!-- End of Vetting Agencies -->
      </div>
    </div>

    <div class="modal-footer">
      <div class="divider"></div>
      <button class="waves-effect waves-light btn cyan darken-3 left" (click)="onCancel(step5ModalActions)">
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        [disabled]="!canSaveAndExit()"
        class="waves-effect waves-light btn cyan darken-3"
        (click)="onSaveAndExit(step5ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          *ngIf="!canSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [4])"
          class="modal-trigger modal-close waves-effect waves-light btn red darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [4])"
          class="modal-trigger modal-action modal-close waves-effect waves-light btn cyan"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [4])"
          class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Step 6 -->

  <div
    id="lss-step6-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step6ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="6"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>

      <div class="modal-step-title">
        {{ 'LocationSpecificSurveyComponent-STEP_6_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider title-divider"></div>

      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>

      <div class="row margin">
        <div id="ques61" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12">
          <label class="sr-label" for="step6_procurement_contract_management">
            {{
              'LocationSpecificSurveyComponent-STEP_6_Q_PROCUREMENT_CONTRACT_MGMT'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </label>
          <div class="input-field col s12">
            <textarea
              [formControl]="lssForm['contractManagement'].valueControl"
              id="step6_procurement_contract_management"
              class="materialize-textarea"
              materialize="characterCounter"
              length="512"
              maxlength="512"
            >
            </textarea>
          </div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['contractManagement']"
          ></last-edited-metadata>
        </div>

        <div id="ques62" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12">
          <label class="sr-label" for="step6_corruption_conflicts">
            {{
              'LocationSpecificSurveyComponent-STEP_6_Q_CORRUPTION_CONFLICTS'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </label>
          <div class="input-field col s12">
            <textarea
              [formControl]="lssForm['preventCorruption'].valueControl"
              id="step6_corruption_conflicts"
              class="materialize-textarea"
              materialize="characterCounter"
              length="512"
              maxlength="512"
            >
            </textarea>
          </div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['preventCorruption']"
          ></last-edited-metadata>
        </div>

        <div id="ques63" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12" *ngIf="surveyDataInput?.country?.isInUS">
          <div class="input-field radio-button-spacing col s12">
            <p>
              {{
                'LocationSpecificSurveyComponent-STEP_6_Q_RECEIVE_US_GOVT_FUNDS'
                  | translate: { orgName: surveyDataInput.organizationName }
              }}
            </p>
            <input
              [formControl]="lssForm['acceptGovFund'].valueControl"
              [value]="true"
              id="step_6_us_govt_funds_yes_rb"
              class="with-gap"
              name="step_6_us_govt_funds"
              type="radio"
            />
            <label for="step_6_us_govt_funds_yes_rb">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>
            <br />
            <input
              [formControl]="lssForm['acceptGovFund'].valueControl"
              [value]="false"
              class="with-gap"
              name="step_6_us_govt_funds"
              type="radio"
              id="step_6_us_govt_funds_no_rb"
            />
            <label for="step_6_us_govt_funds_no_rb">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>
          <last-edited-metadata class="no-left-spacing" [formElement]="lssForm['acceptGovFund']"></last-edited-metadata>
        </div>

        <div class="col s12">
          <p>
            {{
              'LocationSpecificSurveyComponent-STEP_6_SECTION_PROVIDE_FOR_DISABLED'
                | translate
                  : {
                      orgName: surveyDataInput.organizationName,
                      location: surveyDataInput?.country?.name | usaStateName
                    }
            }}
          </p>
        </div>

        <div class="spacing-div col s12">
          <label class="sr-label" for="step6_physical_access">
            {{ 'LocationSpecificSurveyComponent-STEP_6_Q_PHYSICAL_ACCESS' | translate }}
          </label>
          <div class="input-field col s12">
            <textarea
              [formControl]="lssForm['disabilityPhysicalAccess'].valueControl"
              id="step6_physical_access"
              class="materialize-textarea"
              materialize="characterCounter"
              length="512"
              maxlength="512"
            >
            </textarea>
          </div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['disabilityPhysicalAccess']"
          ></last-edited-metadata>
        </div>

        <div class="spacing-div col s12">
          <label class="sr-label" for="step6_program_access">
            {{ 'LocationSpecificSurveyComponent-STEP_6_Q_PROGRAM_ACCESS' | translate }}
          </label>
          <div class="input-field col s12">
            <textarea
              [formControl]="lssForm['disabilityProgramAccess'].valueControl"
              id="step6_program_access"
              class="materialize-textarea"
              materialize="characterCounter"
              length="512"
              maxlength="512"
            >
            </textarea>
          </div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['disabilityProgramAccess']"
          ></last-edited-metadata>
        </div>

        <div class="spacing-div col s12">
          <label class="sr-label" for="step6_communication_access">
            {{ 'LocationSpecificSurveyComponent-STEP_6_Q_COMMUNICATION_ACCESS' | translate }}
          </label>
          <div class="input-field col s12">
            <textarea
              [formControl]="lssForm['disabilityCommunicationAccess'].valueControl"
              id="step6_communication_access"
              class="materialize-textarea"
              materialize="characterCounter"
              length="512"
              maxlength="512"
            >
            </textarea>
          </div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="lssForm['disabilityCommunicationAccess']"
          ></last-edited-metadata>
        </div>

        <!-- child protection emergency plan -->
        <div id="ques64" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: 4 } }}
        </div>
        <div class="spacing-div col s12">
          <div class="input-field radio-button-spacing col s12">
            <p>
              {{ 'LocationSpecificSurveyComponent-STEP_6_Q_CHILDPROTECTION_EMERGENCY_PLAN' | translate }}
            </p>

            <input
              [formControl]="lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue')"
              [value]="true"
              (change)="handleChildProtectionEmergencyPlanChange()"
              id="childprotection_emergency_plan_true_rb"
              class="with-gap"
              name="childprotection_emergency_plan"
              type="radio"
            />
            <label for="childprotection_emergency_plan_true_rb">
              {{ 'UNIVERSAL-YES' | translate }}
            </label>
            <br />
            <input
              [formControl]="lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue')"
              [value]="false"
              (change)="handleChildProtectionEmergencyPlanChange()"
              id="childprotection_emergency_plan_false_rb"
              class="with-gap"
              name="childprotection_emergency_plan"
              type="radio"
            />
            <label for="childprotection_emergency_plan_false_rb">
              {{ 'UNIVERSAL-NO' | translate }}
            </label>
          </div>

          <div *ngIf="lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue').value">
            <div class="input-field col s12">
              <p>
                {{ 'LocationSpecificSurveyComponent-PLAN_UPLOAD_OR_PASTE' | translate }}
              </p>
              <p class="red-text">
                {{ 'UNIVERSAL-FILE_PERMANENT_DELETE_WARNING' | translate }}
              </p>
            </div>
            <div
              *ngIf="lssForm['childProtectionEmergencyPlan'].valueControl.get('fileId').value"
              class="input-field col s12"
            >
              <p>
                <a
                  class="btn"
                  target="_blank"
                  href="{{
                    documentService.getDocumentDownloadLink(
                      lssForm['childProtectionEmergencyPlan'].valueControl.get('fileId').value
                    )
                  }}"
                >
                  {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
                </a>
              </p>
              <p>
                <button
                  class="btn"
                  (click)="lssForm['childProtectionEmergencyPlan'].valueControl.get('fileId').setValue(null)"
                >
                  {{ 'UNIVERSAL-DELETE_CURRENT_FILE' | translate }}
                </button>
              </p>
            </div>

            <div class="input-field spacing col s12">
              <div class="file-field input-field">
                <div class="btn cyan">
                  <span>
                    {{ 'UNIVERSAL-FILE' | translate }}
                  </span>
                  <input
                    (change)="
                      onFileSelected($event, lssForm['childProtectionEmergencyPlan'].valueControl.get('fileId'))
                    "
                    type="file"
                  />
                </div>
                <div class="file-path-wrapper">
                  <input class="file-path" type="text" />
                </div>
              </div>
              <div>
                <p class="sr-img-guidelines">
                  {{ 'LogoUploadComponent-MAXIMUM_FILE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

                  {{ 'LogoUploadComponent-DEFAULT_ALLOWED_FILE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
                </p>
              </div>
            </div>

            <div
              class="input-field spacing col s12"
              [ngClass]="{
                'sr-has-error':
                  !lssForm['childProtectionEmergencyPlan'].valueControl.get('webLink').valid &&
                  (lssForm['childProtectionEmergencyPlan'].valueControl.get('webLink').touched ||
                    invalidOnInit?.maps?.childProtectionEmergencyPlan?.webLink)
              }"
            >
              <i class="material-icons prefix">link</i>
              <input
                class="column3"
                id="childProtectionEmergencyPlanUrl"
                type="text"
                placeholder="{{ 'UNIVERSAL-LINK_PLACEHOLDER' | translate }}"
                materialize="characterCounter"
                length="512"
                maxlength="512"
                [formControl]="lssForm['childProtectionEmergencyPlan'].valueControl.get('webLink')"
              />
              <label for="childProtectionEmergencyPlanUrl" class="active">
                {{ 'LocationSpecificSurveyComponent-PLAN_PASTE_LINK' | translate }}
              </label>
              <div
                class="sr-error-area shift-margin"
                *ngIf="
                  !lssForm['childProtectionEmergencyPlan'].valueControl.get('webLink').valid &&
                  (lssForm['childProtectionEmergencyPlan'].valueControl.get('webLink').touched ||
                    invalidOnInit?.maps?.childProtectionEmergencyPlan?.webLink)
                "
              >
                <div *ngIf="lssForm['childProtectionEmergencyPlan'].valueControl.get('webLink').hasError('pattern')">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              !lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue').value &&
              lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue').value != null
            "
          >
            <div class="spacing-div col s12">
              <br />

              <label class="sr-label" for="childProtectionEmergencyPlanComment">
                {{ 'LocationSpecificSurveyComponent-STEP_6_Q_CHILDPROTECTION_EMERGENCY_PLAN_COMMENT' | translate }}
              </label>
              <div class="input-field col s12">
                <textarea
                  [formControl]="lssForm['childProtectionEmergencyPlan'].valueControl.get('comment')"
                  id="childProtectionEmergencyPlanComment"
                  class="materialize-textarea"
                  materialize="characterCounter"
                  length="500"
                  maxlength="500"
                >
                </textarea>
              </div>
            </div>
          </div>
          <div
            *ngIf="lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue').value != null"
            class="col s12"
          >
            <last-edited-metadata
              class="no-left-spacing no-padding"
              [formElement]="lssForm['childProtectionEmergencyPlan']"
            ></last-edited-metadata>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button class="waves-effect waves-light btn cyan darken-3 left" (click)="onCancel(step6ModalActions)">
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        [disabled]="!canSaveAndExit()"
        class="waves-effect waves-light btn cyan darken-3"
        (click)="onSaveAndExit(step6ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          *ngIf="!canSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [5])"
          class="modal-trigger modal-close waves-effect waves-light btn red darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [5])"
          class="modal-trigger modal-action modal-close waves-effect waves-light btn cyan"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [5])"
          class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- Step 7 -->
  <div
    id="lss-step7-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step7ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="7"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>

      <div class="modal-step-title">
        {{
          'LocationSpecificSurveyComponent-STEP_7_TITLE'
            | translate
              : {
                  orgName: surveyDataInput.organizationName,
                  location: surveyDataInput?.country?.name | usaStateName
                }
        }}
      </div>
    </div>
    <div id="ques71" class="modal-content">
      <div class="divider"></div>

      <p *ngIf="!lssForm['operatingInEntireCountry'].valueControl.value" class="red-text">
        {{ 'LocationSpecificSurveyComponent-STEP_7_HELP_TEXT' | translate }}
      </p>
      <div>
        <input
          type="checkbox"
          id="operatingCountry"
          name="entireCountry"
          [formControl]="lssForm['operatingInEntireCountry'].valueControl"
        />
        <label for="operatingCountry">
          {{
            'LocationSpecificSurveyComponent-STEP_7_ENTIRE_COUNTRY_TEXT'
              | translate: { location: surveyDataInput?.country?.name }
          }}</label
        >
      </div>
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <agm-map
        style="height: 80%"
        *ngIf="!lssForm['operatingInEntireCountry'].valueControl.value"
        [latitude]="mapLocationLat"
        [longitude]="mapLocationLng"
        (mapClick)="mapClicked($event)"
        [zoom]="6"
      >
        <!--Need i=index in ngFor to pass it in the deleteMarker function below.-->
        <agm-marker
          *ngFor="let formGroup of lssForm['operatingLocationList'].valueControl.controls; let i = index"
          [latitude]="formGroup.controls['geoLatitude'].value"
          [longitude]="formGroup.controls['geoLongitude'].value"
          [label]="formGroup.controls['label'].value"
          (markerClick)="markerClicked($event)"
        >
          <agm-info-window [isOpen]="false" [disableAutoPan]="true">
            <label class="sr-bold">{{ 'LocationSpecificSurveyComponent-STEP_7_RADIUS' | translate }}</label>
            <select id="radiusSelect" class="browser-default" [formControl]="formGroup.controls['radius']">
              <option *ngFor="let radius of radii" [ngValue]="radius.value ? radius.value : null">
                {{ radius.display }}
              </option>
            </select>
            <div
              class="sr-error-area"
              *ngIf="!formGroup.controls['radius'].valid && formGroup.controls['radius'].touched"
            >
              <div *ngIf="formGroup.controls['radius'].errors.required">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
            </div>
            <a class="waves-effect waves-light btn" (click)="onMarkerDelete(i)">
              {{ 'LocationSpecificSurveyComponent-STEP_7_REMOVE_PIN' | translate }}
            </a>
          </agm-info-window>
        </agm-marker>
        <agm-circle
          *ngFor="let formGroup of lssForm['operatingLocationList'].valueControl.controls"
          [latitude]="formGroup.controls['geoLatitude'].value"
          [longitude]="formGroup.controls['geoLongitude'].value"
          [radius]="formGroup.controls['radius'].value * 1000"
          fillColor="red"
        >
        </agm-circle>
      </agm-map>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button class="waves-effect waves-light btn cyan darken-3 left" (click)="onCancel(step7ModalActions)">
        <ng-content *ngTemplateOutlet="btnTxtCancel"></ng-content>
      </button>
      <button
        [disabled]="!canSaveAndExit()"
        class="waves-effect waves-light btn cyan darken-3"
        (click)="onSaveAndExit(step7ModalActions)"
      >
        <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
      </button>
      <div class="right">
        <button
          *ngIf="!canSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [6])"
          class="modal-trigger modal-close waves-effect waves-light btn red darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          (click)="onModalAction(onNext, [6])"
          class="modal-trigger modal-action modal-close waves-effect waves-light btn cyan"
        >
          <ng-content *ngTemplateOutlet="btnTxtNext"></ng-content>
        </button>

        <button
          (click)="onModalAction(onPrev, [6])"
          class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>
  <!-- Step 8 -->
  <div
    id="lss-step8-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="step8ModalActions"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="8"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
      >
      </survey-step-progress-bar>

      <div class="modal-step-title">
        {{ 'SurveyCommon-STEP_8_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider"></div>
      <div class="card">
        <div class="card-content black-text">
          <div class="card-text-wrapper grey lighten-3">
            <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
              <thead>
                <tr class="sr-base-table-row">
                  <th *ngFor="let head of headElements; let i = index" (click)="setSortedField(head.sortName)">
                    {{ head.name | translate }}
                    <i class="tiny material-icons">swap_vert</i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let msg of emptyOutdatedQuestions
                      | genericSortPipe: sortField:sortDescending
                      | paginate
                        : {
                            itemsPerPage: itemsPerPageArg,
                            currentPage: selectedPage
                          }
                  "
                >
                  <td>
                    <a
                      [routerLink]="do_nothing"
                      (click)="onModalAction(onSelectQuestion, [msg.stepNumber, msg.questionNumber])"
                    >
                      {{ msg.surveyName }}</a
                    >
                  </td>
                  <td>{{ msg.surveyTypeFull }}</td>
                  <td>{{ msg.stepNumber }}</td>
                  <td>{{ msg.questionNumber }}</td>
                  <td>{{ msg.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col s12 m12">
        <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button class="waves-effect waves-light btn cyan darken-3 left" (click)="onCancel(step8ModalActions)">
        <ng-content *ngTemplateOutlet="btnTxtReturn"></ng-content>
      </button>
      <div class="right">
        <button
          (click)="onModalAction(onPrev, [7])"
          class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3 left"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
        <button
          *ngIf="!canSaveAndExit() && !isSubmitting"
          (click)="onModalAction(errorButtonClicked, [7])"
          class="modal-trigger modal-close waves-effect waves-light btn red darken-3 left"
        >
          <ng-content *ngTemplateOutlet="btnTxtErr"></ng-content>
        </button>
        <button
          [disabled]="!canSaveAndExit()"
          class="waves-effect waves-light btn cyan darken-3"
          (click)="onSaveAndExit(step8ModalActions)"
        >
          <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
        </button>
      </div>
    </div>
  </div>

  <!-- LSS error Modal -->
  <div
    id="lss-error-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.error"
  >
    <div class="modal-header">
      <survey-step-progress-bar
        [totalSteps]="TOTAL_STEPS"
        [currentStepIndex]="-1"
        (stepTabClicked)="onModalAction(selectStep, [$event])"
        (errorModal)="closeErrorModal()"
      >
      </survey-step-progress-bar>

      <div class="modal-step-title">
        {{ 'SurveyCommon-ERROR-MODAL_TITLE' | translate }}
      </div>
    </div>
    <div class="modal-content">
      <div class="divider"></div>
      <div *ngIf="errStepDetails?.length > 0">
        <p class="black-text">
          {{ 'SurveyCommon-ERROR-MODAL_FIX_ERROR_TEXT' | translate }}
        </p>
        <div class="card">
          <div class="card-content black-text">
            <div class="card-text-wrapper grey lighten-3">
              <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
                <thead>
                  <tr class="sr-base-table-row">
                    <th>
                      {{ 'SurveyCommon-ERROR-MODAL_STEP_QUESTION_HEADER' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let err of errStepDetails
                        | paginate
                          : {
                              itemsPerPage: itemsPerPageArg,
                              currentPage: selectedPage,
                              id: 'errPage'
                            }
                    "
                  >
                    <td>
                      <a
                        [routerLink]="do_nothing"
                        (click)="onModalAction(onSelectQuestion, [err.stepNum, err.questionNum])"
                      >
                        {{
                          'SurveyCommon-ERROR-MODAL_STEP_DETAIL'
                            | translate
                              : {
                                  stepNum: err.stepNum,
                                  questionNum: err.questionNum,
                                  questionCount: err.questionCount
                                }
                        }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col s12 m12">
          <pagination-controls (pageChange)="selectedPage = $event" id="errPage">
            {{ selectedPage }}
          </pagination-controls>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="divider"></div>
      <button (click)="onCancel(modalActions.error)" class="waves-effect waves-light btn cyan darken-3 left">
        <ng-content *ngTemplateOutlet="btnTxtReturn"></ng-content>
      </button>
      <div class="right">
        <button
          [disabled]="!canSaveAndExit()"
          (click)="onSaveAndExit(prevStepModal)"
          class="waves-effect waves-light btn cyan darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtSave"></ng-content>
        </button>
        <button
          (click)="onModalAction(onPrev, [-1, prevStepIndex])"
          class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3"
        >
          <ng-content *ngTemplateOutlet="btnTxtPrev"></ng-content>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #btnTxtCancel>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_CANCEL' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_CANCEL' | translate"
  >
    clear
  </i>
</ng-template>
<ng-template #btnTxtErr>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_ERROR' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_ERROR' | translate"
  >
    error
  </i>
</ng-template>
<ng-template #btnTxtNext>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_NEXT' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_NEXT' | translate"
  >
    chevron_right
  </i>
</ng-template>
<ng-template #btnTxtPrev>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_PREV' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_PREV' | translate"
    >chevron_left</i
  >
</ng-template>
<ng-template #btnTxtReturn>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_RETURN_TO_DASHBOARD' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_RETURN_TO_DASHBOARD' | translate"
  >
    clear
  </i>
</ng-template>
<ng-template #btnTxtSave>
  <i
    class="material-icons hide-on-large-only"
    [title]="'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate"
  >
    save
  </i>
  <span class="hide-on-small-only hide-on-med-only">{{ 'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate }}</span>
</ng-template>
