import { Component, OnInit, EventEmitter } from '@angular/core';
import { Message } from './../../../models/Message';
import { AppSettings } from '../../../common/config';
import { IncomingMessagesService } from './incoming-messages.service';
import { UnreadMessagesService } from '../../../header/unread-messages/unread-messages.service';
import { handleErrors } from '../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'incoming-messages',
  templateUrl: './incoming-messages.component.html',
  styleUrls: ['../../common-card-content-title.css', '../../common-base.css', './incoming-messages.component.css'],
  providers: [IncomingMessagesService],
})
export class DashboardIncomingMessagesComponent implements OnInit {
  modalActions = new EventEmitter<string | MaterializeAction>();

  messages: Message[];
  selectedMessage: Message;
  filterArg: string;
  itemsPerPageArg: number = 5;
  selectedPage: number = 0;
  sortField: string = 'createdDate';
  sortDescending: boolean = true;
  isRetrieving: boolean = false;
  AppSettings = AppSettings;
  loading: boolean;

  constructor(
    private incomingMessageService: IncomingMessagesService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationsService: NotificationsService,
    private unreadMessagesService: UnreadMessagesService
  ) {}

  ngOnInit(): void {
    this.updateMessages();
  }

  updateMessages(): void {
    if (this.isRetrieving) {
      return;
    }

    this.isRetrieving = true;

    this.loaderService.start();
    this.loading = true;

    this.messages = null;

    this.unreadMessagesService.refreshUnreadMessages();

    this.incomingMessageService.getMessages().subscribe(
      (success) => {
        this.messages = success;

        const unreadMessageIds: number[] = this.unreadMessagesService.getUnreadMessages().map((msg: Message) => {
          return msg.messageId;
        });

        this.messages.forEach((msg: Message) => {
          msg.isBeingDismissed = false;
          msg.isUnread = unreadMessageIds.indexOf(msg.messageId) !== -1;
        });

        this.isRetrieving = false;

        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'DashboardIncomingMessagesComponent-TITLE'
        );

        this.isRetrieving = false;

        this.loaderService.complete();
        this.loading = false;
      }
    );
  }

  dismissMessage(message: Message): void {
    if (message.isBeingDismissed) {
      return;
    }

    if (!confirm(this.translateService.instant('DashboardIncomingMessagesComponent-CONFIRM_DISMISS'))) {
      return;
    }

    this.loaderService.start();
    this.loading = true;

    message.isBeingDismissed = true;

    this.incomingMessageService.dismissMessage(message.messageId).subscribe(
      (success) => {
        this.messages = this.messages.filter((iterMsg: Message) => iterMsg.messageId !== message.messageId);

        // reset select page to an existing page if previously selected page no longer "exists"
        const numPages = Math.ceil(this.messages.length / this.itemsPerPageArg);
        this.selectedPage = Math.min(this.selectedPage, numPages);

        if (this.selectedMessage) {
          this.modalActions.emit({ action: 'modal', params: ['close'] });
          this.selectedMessage = null;
        }

        this.markAsRead(message.messageId);
        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        message.isBeingDismissed = false;

        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'DashboardIncomingMessagesComponent-TITLE'
        );

        this.loaderService.complete();
        this.loading = false;
      }
    );
  }

  setSelectedMessage(msg: Message): void {
    this.selectedMessage = msg;

    this.modalActions.emit({ action: 'modal', params: ['open'] });
    this.markAsRead(msg.messageId);
  }

  clearSelected() {
    this.selectedMessage = null;
  }

  setSortedField(newSortField: string): void {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  markAsRead(messageId: number): void {
    this.unreadMessagesService.markAsRead(messageId).subscribe(
      () => {
        this.updateMessages();
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'DashboardIncomingMessagesComponent-ERROR-MESSAGE-READ'
        );
      }
    );
  }
}
