import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserManagementService } from '../user_management.service';
import { SREvent, User, userTypeToName, UserType } from '../../../../models';
import { OrganizationService } from '../../organizations/organization.service';
import { Organization } from '../../../../models/Organization';
import { NameValidator } from '../../../../validators/nameValidator';
import { EmailValidator } from '../../../../validators/emailValidator';
import { LeadingTrailingWhiteSpaceValidator } from '../../../../validators/leadingTrailingWhiteSpaceValidator';
import { TranslateService } from '@ngx-translate/core';
import { handleErrors } from '../../../../common/error';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { AuthenticationService } from '../../../../login/login.service';
import { userRequiresSelectedOrganizationValidator } from '../user_management.component';
import { PhoneValidator } from '../../../../validators/phoneValidator';

@Component({
  selector: 'dashboardUserManagementCreate',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css', '../../../common-create.css'],
  providers: [],
})
export class DashboardUserManagementCreateComponent implements OnInit {
  isSubmitting: boolean;

  editUserForm: FormGroup;

  organizations: Organization[];

  userTypeToName: string[] = userTypeToName;
  userTypeEnum: any = UserType;
  @Input() GOB_COMPONENT;
  @Input() payload;
  @Output() action: EventEmitter<SREvent> = new EventEmitter<SREvent>();
  @Input() organizationId;
  loading: boolean;

  constructor(
    private router: Router,
    private userManagementService: UserManagementService,
    private organizationService: OrganizationService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService,
    public authService: AuthenticationService
  ) {
    this.isSubmitting = false;
  }

  ngOnInit() {
    this.loaderService.start();
    this.loading = true;

    this.editUserForm = new FormGroup(
      {
        email: new FormControl('', [
          Validators.required,
          Validators.maxLength(64),
          EmailValidator.mailFormat,
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ]),
        firstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(32),
          NameValidator.nameFormat,
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ]),
        lastName: new FormControl('', [
          Validators.required,
          Validators.maxLength(32),
          NameValidator.nameFormat,
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
        ]),
        phoneNumber: new FormControl('', [
          Validators.required,
          Validators.maxLength(32),
          LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
          PhoneValidator.phoneFormat,
        ]),

        userType: new FormControl(
          this.GOB_COMPONENT ? 3 : this.authService.isOrgGeneral() ? UserType.LOCATION : UserType.ORG_GENERAL,
          Validators.required
        ),
        enabled: new FormControl(this.GOB_COMPONENT ? true : false, Validators.required),
        activated: new FormControl(this.GOB_COMPONENT ? true : false, Validators.required),

        organizationId: new FormControl(
          this.GOB_COMPONENT && this.organizationId
            ? this.organizationId
            : this.authService.getProfile().organizationId || null
        ),
      },
      userRequiresSelectedOrganizationValidator
    );

    this.editUserForm.statusChanges.subscribe(() => {
      this.dispatchAction('add_user_is_form_empty', this.isEmpty());
    });

    this.organizationService.getOrganizations().subscribe(
      (response) => {
        this.organizations = response;

        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');

        this.loaderService.complete();
        this.loading = false;
      }
    );

    this.dispatchAction('add_user_is_form_empty', true);
  }

  dispatchAction(event, data?) {
    this.action.emit({
      type: event,
      payload: data,
    });
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.editUserForm.controls['phoneNumber'].setValue(
      this.editUserForm.controls['phoneNumber'].value.replace(/\s/g, '')
    );

    this.loaderService.start();
    this.loading = true;

    this.isSubmitting = true;

    const newUser: User = this.editUserForm.getRawValue();

    // newUser.organizationAdmin = this.editUserForm.value.userType == UserType.ORG_GENERAL;

    this.userManagementService.addUser(newUser).subscribe(
      (response) => {
        this.isSubmitting = false;

        this.notificationService.success(
          this.translateService.instant('UserManagementCommon-TABLE_TITLE'),
          this.translateService.instant('DashboardUserManagementCreateComponent-NEW_USER_SUCCESS', {
            firstName: newUser.firstName,
            lastName: newUser.lastName,
          })
        );
        if (this.GOB_COMPONENT) {
          this.dispatchAction('general_view');
        } else {
          this.router.navigate(['dashboard/users']);
        }

        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        this.isSubmitting = false;

        handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');

        this.loaderService.complete();
        this.loading = false;
      }
    );
  }

  onCancel() {
    if (this.GOB_COMPONENT) {
      this.dispatchAction('general_view');
    } else {
      if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
        this.router.navigate(['dashboard/users']);
      }
    }
  }

  private isEmpty(): boolean {
    for (const field in this.editUserForm.controls) {
      if (
        this.editUserForm.controls[field] !== null &&
        typeof this.editUserForm.controls[field].value === 'string' &&
        this.editUserForm.controls[field].value.replace(/\s/g, '') !== ''
      ) {
        return false;
      }
    }
    return true;
  }
}
