import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../login/login.service';

/**
 * Protect the /dashboard view from any user not logged in. Works together with AuthService to redirect user to
 *  intended destination upon successful login (and successful user role check).
 */
@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }

    this.authService.redirectUrl = null;

    this.router.navigate(['/page-not-found']);
    return false;
  }
}

/**
 * Super Admin guard protects routes specifically available to the super admin, and so on for each of the user roles.
 */
@Injectable()
export class CanActivateViaSuperAdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isSuper()) {
      return true;
    }

    this.router.navigate(['/dashboard']);
    return false;
  }
}

/**
 * Protect routes specifically available to general org admins and super admins.
 */
@Injectable()
export class CanActivateViaOrgOrAboveAdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isOrgGeneral() || this.authService.isSuper()) {
      return true;
    }
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/dashboard']);
    }

    return false;
  }
}

/**
 * Protect routes specifically available to general org admins.
 */
@Injectable()
export class CanActivateViaOrgAdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService) {}

  canActivate(): boolean {
    return this.authService.isOrgGeneral();
  }
}

/**
 * Protect routes specifically available to local org admins or general org admins.
 */
@Injectable()
export class CanActivateViaLocalOrOrgAdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isOrgGeneral() || this.authService.isLocation()) {
      return true;
    }
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/dashboard']);
    }

    return false;
  }
}

@Injectable()
export class CanActivateViaLocalAdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService) {}

  canActivate(): boolean {
    return this.authService.isLocation();
  }
}

/**
 * If the user is already logged in, and attempts to navigate to /login, then redirect them to the dashboard.
 */
@Injectable()
export class CanActivateViaAlreadyLoggedInGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    return true;
  }
}
