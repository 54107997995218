import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EmailValidator } from '../../app/validators/emailValidator';
import { LeadingTrailingWhiteSpaceValidator } from '../../app/validators/leadingTrailingWhiteSpaceValidator';
import { NameValidator } from '../../app/validators/nameValidator';
import { AppSettings } from '../common/config';
import { CountryService } from '../dashboard/servicesComponets/country/country.service';
import { Country } from '../models/Country';

@Component({
  selector: 'app-subscribe',
  templateUrl: './newsletter-subscribe.component.html',
})
export class NewsLetterSubscriptionComponent implements OnInit {
  subscribeForm: FormGroup;
  retValue: string;
  countries: Country[];

  constructor(private http: HttpClient, private router: Router, private countryService: CountryService) {}

  ngOnInit() {
    this.retValue = window.location.href + '-success';
    this.createForm();
    this.countryService.getCountries().subscribe((countries) => (this.countries = countries));
  }

  createForm() {
    this.subscribeForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(80),
        EmailValidator.mailFormat,
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
      ]),
      first_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(40),
        NameValidator.nameFormat,
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
      ]),
      last_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(80),
        NameValidator.nameFormat,
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
      ]),
      company: new FormControl(''),
      street: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      country: new FormControl(''),
    });
  }
}
