import { FormControl } from '@angular/forms';
import { AppSettings } from '../common/config';

interface ValidationResult {
  [key: string]: boolean;
}

export class VimeoYoutubeUrlValidator {
  public static vimeoYoutubeUrlFormat(control: FormControl): ValidationResult {
    if (control.value) {
      if (
        control.value !== '' &&
        !AppSettings.VIMEO_URL_REGEX_PATTERN.test(control.value) &&
        !AppSettings.YOUTUBE_URL_REGEX_PATTERN.test(control.value)
      ) {
        return { vimeoYoutubeUrlFormat: true };
      }
    }
    return null;
  }
}
