import { map, debounceTime, tap } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// import Models
import { Country } from '../../../../../../models/Country';
import { User } from '../../../../../../models/User';
import {
  CountryGroup,
  GeneralOrganizationBackgroundSurveyService,
} from '../../general-organization-background.service';
// import services
import { CompleterData, CompleterService } from 'ng2-completer';
import { UserManagementService } from '../../../../user_management/user_management.service';
import { MaterializeDirective, MaterializeAction } from 'angular2-materialize';
import { AuthenticationService } from 'app/login/login.service';

@Component({
  selector: 'gob-location-operation',
  templateUrl: './gob-location-operation.component.html',
  styleUrls: ['./gob-location-operation.scss'],
})
export class GOBLocationOperationComponent implements OnInit {
  @Input() locationContactList;
  @Input() surveyCanceled;
  @Output() surveyUpdated = new EventEmitter<boolean>();
  // get referance to PrimaryContact dropdown
  @ViewChild('selectPrimaryContact', { static: false }) locationPrimaryContact;

  public countries: Country[]; // all countries
  public filteredCountries: Country[]; // countries in the left list
  public selectedList: CountryGroup[] = []; // countries in the left list
  public savedList: CountryGroup[] = []; // countries already saved to db
  unassignedCountries: Country[];
  public autocompleteData: CompleterData; // data for AutoCompleter

  public term = new FormControl();
  public selectedCountry = null;
  public selectedCountryhasNoUser: boolean = false;

  public addedLocation: CountryGroup;
  public collapsibleActions = new EventEmitter<string | MaterializeAction>();

  public gobFormStep6: CountryGroup[] = [];

  constructor(
    private surveyService: GeneralOrganizationBackgroundSurveyService,
    private userManagementService: UserManagementService,
    private completerService: CompleterService,
    public authService: AuthenticationService
  ) {
    this.surveyService.gobFormStep6.subscribe((data: CountryGroup[]) => {
      const selectedList = [];
      for (const key in data) {
        selectedList.push(data[key]);
      }
      this.selectedList = selectedList;
      this.gobFormStep6 = data;
    });
  }

  ngOnInit() {
    this.surveyService
      .gobFormStep7Obs(this.locationContactList)
      .subscribe((data: { countries: Country[]; gobFormStep6: CountryGroup[]; selectedCountries: any }) => {
        this.countries = data.countries;
        this.selectedList = data.selectedCountries;
        this.savedList = data.selectedCountries;
        this.unassignedCountries = data.countries.filter((country) => {
          let isSelectedCountry = false;
          // tslint:disable-next-line:no-increment-decrement
          for (let i = 0; i < this.selectedList.length; i++) {
            if (country.abbreviation === this.selectedList[i].country.abbreviation) isSelectedCountry = true;
          }
          return !isSelectedCountry;
        });
        this.filteredCountries = this.unassignedCountries;
        this.surveyService.gobFormStep6.next(data.gobFormStep6);
      });

    this.term.valueChanges
      .pipe(debounceTime(400))
      .pipe(tap(() => (this.selectedCountry = null)))
      .pipe(map((term) => term.toLowerCase()))
      .subscribe((term) => {
        this.filteredCountries = this.unassignedCountries.filter(
          (country) => country.name.toLowerCase().indexOf(term) > -1
        );
      });
  }

  ngOnChanges() {
    if (this.surveyCanceled) {
      this.selectedCountryhasNoUser = false; // resetting the value of the flag
      this.surveyService
        .gobFormStep7Obs(this.locationContactList)
        .subscribe((data: { countries: Country[]; gobFormStep6: CountryGroup[]; selectedCountries: any }) => {
          this.countries = data.countries;
          this.selectedList = data.selectedCountries;
          this.unassignedCountries = data.countries.filter((country) => {
            let isSelectedCountry = false;
            // tslint:disable-next-line:no-increment-decrement
            for (let i = 0; i < this.selectedList.length; i++) {
              if (country.abbreviation === this.selectedList[i].country.abbreviation) isSelectedCountry = true;
            }
            return !isSelectedCountry;
          });
          this.filteredCountries = this.unassignedCountries;
          this.surveyService.gobFormStep6.next(data.gobFormStep6);
        });

      this.term.valueChanges
        .pipe(debounceTime(400))
        .pipe(tap(() => (this.selectedCountry = null)))
        .pipe(map((term) => term.toLowerCase()))
        .subscribe((term) => {
          this.filteredCountries = this.unassignedCountries.filter(
            (country) => country.name.toLowerCase().indexOf(term) > -1
          );
        });
    }
  }

  selectCountry(country, action: string = 'add') {
    const selectedCountry = {
      action,
      name: country.name,
      code: country.abbreviation,
      disabledSelection: false,
      notDeletable: false,
    };

    // check if the country has assigned the contact
    const selectedLocation = this.gobFormStep6[selectedCountry.code];
    if (selectedLocation) {
      selectedCountry.notDeletable =
        (selectedLocation.user1 && selectedLocation.user1 !== null) ||
        (selectedLocation.user2 && selectedLocation.user2 !== null);
    }
    if (action === 'add') {
      if (this.selectedList.filter((eachLocation) => eachLocation.country.name === country.name).length > 0) {
        selectedCountry.disabledSelection = true;
        selectedCountry.action = 'delete';
      }
    }

    this.selectedCountry = selectedCountry;
    this.addCountry(country);
  }

  public addCountry(country, user1: User = null, user2: User = null) {
    const countryCode = this.selectedCountry ? this.selectedCountry.code : null;
    if (country.abbreviation !== countryCode) return;

    const findCountry = this.countries.filter((country) => country.abbreviation === countryCode)[0];
    const newLocation: CountryGroup = {
      user1,
      user2,
      country: findCountry,
    };
    this.selectedCountryhasNoUser = true; // country added but user is not selected for the country
    this.selectedList.push(newLocation);
    this.gobFormStep6[countryCode] = newLocation;
    this.surveyService.gobFormStep6.next(this.gobFormStep6);
    this.surveyUpdated.emit(true);
    this.unassignedCountries = this.unassignedCountries.filter((country) => {
      return country.abbreviation !== countryCode;
    });
    this.filteredCountries = this.unassignedCountries;
    // Pop open collapsible on new addition
    this.addedLocation = newLocation;
    this.collapsibleActions.emit('collapsible');

    // Set focus with a delay because locationPrimaryContact is available only after ngAfterViewInit event fires
    setTimeout(() => {
      this.locationPrimaryContact.nativeElement.focus();
    }, 150);
  }

  onDeleteCountryOfOperations(countryCode) {
    this.selectedList = this.selectedList.filter((eachLocation) => eachLocation.country.abbreviation !== countryCode);
    this.savedList = this.savedList.filter((eachLocation) => eachLocation.country.abbreviation !== countryCode);
    this.selectedCountry = null;
    if (this.selectedList && this.selectedList.length > 0) {
      this.selectedCountryhasNoUser = this.selectedList.find((x) => !x.user1) ? true : false;
    }
    delete this.gobFormStep6[countryCode];
    this.surveyUpdated.emit(true);
    this.surveyService.gobFormStep6.next(this.gobFormStep6);
    const countryToAdd = this.countries.find((country) => {
      return country.abbreviation === countryCode;
    });
    this.unassignedCountries.push(countryToAdd);
    this.unassignedCountries.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      return 1;
    });
    this.filteredCountries = this.unassignedCountries;
  }

  sameList(savedList, selectedList) {
    if (savedList.length !== selectedList.length) return false;
    for (let i = 0; i < savedList.length; i += 1) {
      if (savedList[i] !== selectedList[i]) return false;
    }
    return true;
  }

  selectContact($event, countryCode: string, user: string) {
    const selectedUser = this.userManagementService.users.find((u) => u.userId === +$event.target.value);
    this.selectedCountry = this.countries.find((s) => s.abbreviation === countryCode);

    if ($event && this.gobFormStep6[countryCode][user] !== selectedUser) {
      this.gobFormStep6[countryCode][user] = selectedUser;
      this.selectedList.map((eachLocation) => {
        if (eachLocation.country.abbreviation === countryCode) {
          eachLocation[user] = selectedUser;
        }
      });
      this.surveyUpdated.emit(true);
      this.surveyService.gobFormStep6.next(this.gobFormStep6);
    }

    if (this.selectedList && this.selectedList.length > 0) {
      this.selectedCountryhasNoUser = this.selectedList.find((x) => !x.user1) ? true : false;
    }
  }
}
