<quill-editor [formControl]="form">
  <div quill-editor-toolbar>
    <span class="ql-formats">
      <button class="ql-bold" [title]="'Bold'"></button>
      <button class="ql-italic" [title]="'Italic'"></button>
      <button class="ql-underline" [title]="'Underline'"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-blockquote" [title]="'Blockquote'"></button>
    </span>
    <span class="ql-formats">
      <select class="ql-header" [title]="'Header'">
        <option selected></option>
        <option value="1"></option>
        <option value="2"></option>
      </select>
    </span>
    <span class="ql-formats">
      <button class="ql-list" [title]="'List'" value="ordered"></button>
      <button class="ql-list" [title]="'List'" value="bullet"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-clean" [title]="'Clean'" value="ordered"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-link" [title]="'Link'"></button>
    </span>
  </div>
</quill-editor>
