import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LSSSurveyInputInterface } from '../survey-input-interface.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AppSettings } from '../../../../common/config';

@Component({
  selector: 'manual-lss-survey-edits',
  providers: [],
  templateUrl: './location-specific-survey.component.html',
  styleUrls: ['./generic-survey.component.css'],
})
export class ManualLocationSpecificSurveyBrowserAndEditorComponent implements OnInit {
  organizationId: number;
  countryId: number;

  surveyInput: LSSSurveyInputInterface;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const organizationId = +params['organizationId'];
      const countryId = +params['countryId'];

      this.organizationId = organizationId;
      this.countryId = countryId;

      this.surveyInput = {
        organizationId: this.organizationId,
        countryId: this.countryId,
      };
    });
  }
}
