import { switchMap, zip } from 'rxjs/operators';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Organization } from '../../../../models/Organization';
import { GeneralOrganizationBackgroundSurveyInput } from '../../surveys/generalOrganizationBackground/general-organization-background.model';
import { OrganizationService } from '../organization.service';
import { GeneralOrganizationBackgroundSurveyService } from '../../surveys/generalOrganizationBackground/general-organization-background.service';
import { OrgDisasterService } from '../../../../searchResults/orgDisaster/org-disaster.service';
import { handleErrors } from '../../../../common/error';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../../../common/config';
import { NotificationsService } from 'angular2-notifications';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'dashboardViewOrganization',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css', '../../../common-view.css'],
  providers: [OrgDisasterService],
})
export class DashboardOrganizationViewComponent implements OnInit {
  public myOrganization: Organization;
  public locations = [];
  public disasters = [];

  AppSettings = AppSettings;
  modalActions = new EventEmitter<string | MaterializeAction>();

  orgMetadataForm: FormControl;
  orgLocationMetadataForms: FormGroup;
  orgDisasterMetadataForms: FormGroup;

  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private orgService: OrganizationService,
    private surveyService: GeneralOrganizationBackgroundSurveyService,
    private orgDisasterService: OrgDisasterService,
    private loaderService: SlimLoadingBarService,
    private translateService: TranslateService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit() {
    this.orgLocationMetadataForms = new FormGroup({});
    this.orgDisasterMetadataForms = new FormGroup({});

    this.loaderService.start();

    this.route.params
      .pipe(
        switchMap((params: Params) => {
          return this.surveyService
            .getMostRecentSurvey(+params['id'])
            .pipe(zip(this.orgService.getOrganization(+params['id']), (survey, org) => ({ survey, org })));
        })
      )
      .subscribe((success) => {
        this.loaderService.complete();

        this.myOrganization = success.org;
        this.orgMetadataForm = new FormControl(this.myOrganization.metadata);

        if (success.survey.locationContactList) {
          this.locations = success.survey.locationContactList.map((e) => ({
            id: e.value.countryId,
            name: e.value.countryName,
          }));
          this.locations.forEach((location) => {
            this.getOrgLocationMetadata(location.id);
          });
        }

        this.orgDisasterService.getOrgRespondToDisaster(this.myOrganization.organizationId).subscribe((success) => {
          if (success) {
            this.disasters = success.map((e) => ({
              id: e.disaster.disasterId,
              name: e.disaster.name,
            }));
            this.disasters.forEach((disaster) => {
              this.getOrgDisasterMetadata(disaster.id);
            });
          }
        });
      });
  }

  submitMetadata() {
    this.modalActions.emit({ action: 'modal', params: ['close'] });

    if (this.orgMetadataForm.dirty) {
      this.orgService
        .updateOrganizationMetadata(this.myOrganization.organizationId, this.orgMetadataForm.value)
        .subscribe(
          (response) => {
            this.notificationService.success(
              this.translateService.instant('DashboardOrganizationViewComponent-METADATA_SUBMISSION'),
              this.translateService.instant('UNIVERSAL-TOAST_SUCCESS')
            );
          },
          (error) => {
            handleErrors(
              error,
              this.translateService,
              this.notificationService,
              this.translateService.instant('DashboardOrganizationViewComponent-METADATA_SUBMISSION')
            );
          }
        );
    }

    for (const location of this.locations) {
      const form = this.orgLocationMetadataForms.controls[location.id];
      if (form.dirty) {
        this.orgService
          .updateOrgLocationMetadata(this.myOrganization.organizationId, location.id, form.value)
          .subscribe(
            (response) => {
              this.notificationService.success(
                this.translateService.instant('DashboardOrganizationViewComponent-METADATA_SUBMISSION'),
                this.translateService.instant('UNIVERSAL-TOAST_SUCCESS')
              );
            },
            (error) => {
              handleErrors(
                error,
                this.translateService,
                this.notificationService,
                this.translateService.instant('DashboardOrganizationViewComponent-METADATA_SUBMISSION')
              );
            }
          );
      }
    }

    for (const disaster of this.disasters) {
      const form = this.orgDisasterMetadataForms.controls[disaster.id];
      if (form.dirty) {
        this.orgService
          .updateOrgDisasterMetadata(this.myOrganization.organizationId, disaster.id, form.value)
          .subscribe(
            (response) => {
              this.notificationService.success(
                this.translateService.instant('DashboardOrganizationViewComponent-METADATA_SUBMISSION'),
                this.translateService.instant('UNIVERSAL-TOAST_SUCCESS')
              );
            },
            (error) => {
              handleErrors(
                error,
                this.translateService,
                this.notificationService,
                this.translateService.instant('DashboardOrganizationViewComponent-METADATA_SUBMISSION')
              );
            }
          );
      }
    }
  }

  getOrgLocationMetadata(locationId: number): void {
    this.orgService.getOrgLocationMetadata(this.myOrganization.organizationId, locationId).subscribe(
      (metadata) => {
        this.orgLocationMetadataForms.addControl(locationId.toString(10), new FormControl(metadata));
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationService,
          this.translateService.instant('DashboardOrganizationViewComponent-METADATA_RETRIEVAL')
        );
      }
    );
  }

  getOrgDisasterMetadata(disasterId: number): void {
    this.orgService.getOrgDisasterMetadata(this.myOrganization.organizationId, disasterId).subscribe(
      (metadata) => {
        this.orgDisasterMetadataForms.addControl(disasterId.toString(10), new FormControl(metadata));
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationService,
          this.translateService.instant('DashboardOrganizationViewComponent-METADATA_RETRIEVAL')
        );
      }
    );
  }
}
