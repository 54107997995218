import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserManagementService } from '../user_management.service';
import { DashboardRedirectService } from '../../shared/dashboard-redirect.service';
import { User, userTypeToName } from '../../../../models/User';
import { Organization } from '../../../../models/Organization';
import { AuthenticationService } from '../../../../login/login.service';
import { handleErrors } from '../../../../common/error';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { AppSettings } from '../../../../common/config';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dashboardViewUserManagement',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css', '../../../common-view.css'],
  providers: [],
})
export class DashboardUserManagementViewComponent implements OnInit {
  public myUser: User;
  public myOrg: Organization;
  @Input() GOB_COMPONENT;
  @Input() payload;
  @Output() action = new EventEmitter();

  public AppSettings = AppSettings;

  loading: boolean;

  constructor(
    public authService: AuthenticationService,
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private loaderService: SlimLoadingBarService,
    private translateService: TranslateService,
    private notificationService: NotificationsService,
    private dashRedirectService: DashboardRedirectService
  ) {}

  ngOnInit() {
    if (this.GOB_COMPONENT) {
      const id = this.payload;
      this.getUser(id);
    } else {
      this.route.params.subscribe((params) => {
        const id = +params['id'];
        this.getUser(id);
      });
    }
  }

  getUser(id) {
    this.loaderService.start();
    this.loading = true;

    this.userManagementService
      .getUser(id)
      .pipe(this.dashRedirectService.redirectUnauthorized())
      .subscribe(
        (data) => {
          this.myUser = data;
          (<any>this.myUser).userTypeName = userTypeToName[data.userType];

          this.myOrg = data.organization;
          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');

          this.loaderService.complete();
          this.loading = false;
        }
      );
  }

  dispatchAction(event, data?) {
    this.action.emit({
      type: event,
      payload: data,
    });
  }
}
