import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { CompleterItem } from 'ng2-completer';

import { SearchResult, SearchType } from '../../models/SearchResult';
import { CustomData } from './customdata.service';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent {
  searchEnum = SearchType;

  public customData: CustomData;

  public selectedItem: SearchResult;

  public url = [];

  allChecked = true;
  locationChecked = false;
  organizationChecked = false;
  disasterChecked = false;

  constructor(private router: Router, private http: HttpClient) {
    this.customData = new CustomData(http);
    this.customData.searchType = this.searchEnum.All;
  }

  public onSelectedItem(selected: CompleterItem) {
    this.url = [];
    if (selected) {
      this.selectedItem = <SearchResult>selected.originalObject;
      const type: string = this.selectedItem.searchResultType.toLowerCase();
      this.url.push(`/${type}`);
      this.url.push(this.selectedItem.disasterLocationOrganizationId);
      this.router.navigate(this.url);
    }
  }

  public toggleEnum(checked: boolean, type: SearchType) {
    if (this.allChecked) {
      this.allChecked = !this.allChecked;
      this.customData.searchType &= ~this.searchEnum.All;
    }

    checked ? (this.customData.searchType |= type) : (this.customData.searchType &= ~type);

    if (!this.disasterChecked && !this.locationChecked && !this.organizationChecked) {
      this.allChecked = true;
      this.customData.searchType = this.searchEnum.All;
    }
  }

  toggleAll($event) {
    if (!$event.target.checked) {
      $event.preventDefault();
    }

    this.locationChecked = this.disasterChecked = this.organizationChecked = false;
    this.customData.searchType = this.searchEnum.All;
  }
}
