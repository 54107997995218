import { Organization } from './Organization';

export interface User {
  userId?: number;

  // disabled or not yet activated users cannot login, but they can be assigned as location admins
  enabled?: boolean;
  activated?: boolean;

  registerDate?: string | number | Date;
  lastLoginTime?: string | number | Date;

  // [optional] one to one relationship between User and Organization, todo should be condensed
  organization?: Organization;
  organizationId?: number;
  organizationAdmin?: boolean;

  // user's required information for contact, profile
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;

  // one of UserType values
  userType?: number;

  // helper properties for use in displaying User in a friendly manner
  userTypeName?: string;
  organizationName?: string;

  govId?: number;
  countryId?: number;

  emailNotificationTurnedOff?: boolean;
  // this is if the user is locked out - displays the timestamp until the user is locked out
  lockUntil?: string;
}

export const userTypeToName: string[] = ['', 'Super Admin', 'Org General Admin', 'Location Admin'];

export enum UserType {
  SUPER = 1,
  ORG_GENERAL = 2,
  LOCATION = 3,
}
