import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'picker-menu',
  templateUrl: './picker-menu.component.html',
  styleUrls: ['./picker-menu.component.css'],
})
export class PickerMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() { }
  
  ngAfterViewInit() {
    $('.collapsible').collapsible();
  }
}
