<div class="col s12 m12">
  <app-loader [loading]="loading"></app-loader>
  <div class="card">
    <div class="card-content black-text">
      <div class="row user-title">
        <h5>{{ 'DashboardUserManagementCreateComponent-ADD_USER_HEADING' | translate }}</h5>
      </div>

      <div class="divider"></div>

      <div class="row">
        <div class="sr-required-notif padded">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>

        <div class="user-container">
          <!-- First Name field -->
          <label class="black-text sr-label" for="firstName">
            {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
            <span class="sr-required-mark">*</span>
          </label>
          <div
            class="input-field col s12 m12"
            [ngClass]="{
              'sr-has-error': !editUserForm.controls['firstName'].valid && editUserForm.controls['firstName'].touched
            }"
          >
            <input
              id="firstName"
              type="text"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="editUserForm.controls['firstName']"
              [placeholder]="'UNIVERSAL-CONTACT_FIRST_NAME' | translate"
            />
            <div
              class="sr-error-area"
              *ngIf="!editUserForm.controls['firstName'].valid && editUserForm.controls['firstName'].touched"
            >
              <div *ngIf="editUserForm.controls['firstName'].errors.required">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
              <div *ngIf="editUserForm.controls['firstName'].value.length > 0">
                <div *ngIf="editUserForm.controls['firstName'].hasError('maxlength')">
                  {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
                </div>
                <div *ngIf="editUserForm.controls['firstName'].hasError('leadingTrailingWhiteSpaceFormat')">
                  {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
                </div>
                <div *ngIf="editUserForm.controls['firstName'].hasError('nameFormat')">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NAME' | translate }}
                </div>
              </div>
            </div>
          </div>

          <!-- Last Name field -->
          <label class="black-text sr-label" for="lastName">
            {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
            <span class="sr-required-mark">*</span>
          </label>
          <div
            class="input-field col s12 m12"
            [ngClass]="{
              'sr-has-error': !editUserForm.controls['lastName'].valid && editUserForm.controls['lastName'].touched
            }"
          >
            <input
              id="lastName"
              type="text"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="editUserForm.controls['lastName']"
              [placeholder]="'UNIVERSAL-CONTACT_LAST_NAME' | translate"
            />
            <div
              class="sr-error-area"
              *ngIf="!editUserForm.controls['lastName'].valid && editUserForm.controls['lastName'].touched"
            >
              <div *ngIf="editUserForm.controls['lastName'].errors.required">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
              <div *ngIf="editUserForm.controls['lastName'].value.length > 0">
                <div *ngIf="editUserForm.controls['lastName'].hasError('maxlength')">
                  {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
                </div>
                <div *ngIf="editUserForm.controls['lastName'].hasError('leadingTrailingWhiteSpaceFormat')">
                  {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
                </div>
                <div *ngIf="editUserForm.controls['lastName'].hasError('nameFormat')">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NAME' | translate }}
                </div>
              </div>
            </div>
          </div>

          <!-- Email field -->
          <label class="black-text sr-label" for="email">
            {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
            <span class="sr-required-mark">*</span>
          </label>
          <div
            class="input-field col s12 m12"
            [ngClass]="{
              'sr-has-error':
                !editUserForm.controls['email'].valid &&
                (editUserForm.controls['email'].touched || editUserForm.controls['email'].value.length)
            }"
          >
            <input
              id="email"
              type="text"
              materialize="characterCounter"
              length="64"
              maxlength="64"
              [formControl]="editUserForm.controls['email']"
              [placeholder]="'UNIVERSAL-CONTACT_EMAIL' | translate"
            />
            <div
              class="sr-error-area"
              *ngIf="
                !editUserForm.controls['email'].valid &&
                (editUserForm.controls['email'].touched || editUserForm.controls['email'].value.length)
              "
            >
              <div *ngIf="editUserForm.controls['email'].errors.required">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
              <div *ngIf="editUserForm.controls['email'].errors.mailFormat">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}.
              </div>
            </div>
          </div>

          <!-- phoneNumber field -->
          <label class="black-text sr-label" for="phoneNumber">
            {{ 'UNIVERSAL-CONTACT_PHONE' | translate }}
            <span class="sr-required-mark">*</span>
          </label>
          <div
            class="input-field col s12 m12"
            [ngClass]="{
              'sr-has-error':
                !editUserForm.controls['phoneNumber'].valid && editUserForm.controls['phoneNumber'].touched
            }"
          >
            <input
              id="phoneNumber"
              type="text"
              materialize="characterCounter"
              length="32"
              maxlength="32"
              [formControl]="editUserForm.controls['phoneNumber']"
              [placeholder]="'UNIVERSAL-CONTACT_PHONE' | translate"
            />
            <div
              class="sr-error-area"
              *ngIf="!editUserForm.controls['phoneNumber'].valid && editUserForm.controls['phoneNumber'].touched"
            >
              <div *ngIf="editUserForm.controls['phoneNumber'].errors.required">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
              <div *ngIf="editUserForm.controls['phoneNumber'].errors.phoneFormat">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PHONE' | translate }}
              </div>
            </div>
          </div>

          <!-- Enabled field -->
          <div class="row margin col s12 m7 enabled-input">
            <div class="col s5 m3">
              {{ 'UserManagementCommon-ENABLED' | translate }}
              <span class="sr-required-mark">*</span>
            </div>
            <div
              class="col s7 m4 l3"
              [ngClass]="{
                'sr-has-error': !editUserForm.controls['enabled'].valid && editUserForm.controls['enabled'].touched
              }"
            >
              <input
                id="enabled-id"
                type="radio"
                name="enabled"
                [value]="true"
                class="with-gap"
                [formControl]="editUserForm.controls['enabled']"
              />
              <label for="enabled-id">
                {{ 'UNIVERSAL-ENABLED' | translate }}
              </label>
              <input
                id="disabled-id"
                type="radio"
                name="enabled"
                [value]="false"
                class="with-gap"
                [formControl]="editUserForm.controls['enabled']"
              />
              <label for="disabled-id">
                {{ 'UNIVERSAL-DISABLED' | translate }}
              </label>
            </div>
          </div>

          <!-- only super admin can create a user who is pre-activated, create another super admin / org admin,
                        and add to any organization -->
          <ng-template [ngIf]="authService.isSuper()">
            <!-- Activated field -->
            <div [hidden]="GOB_COMPONENT" class="row margin col s12 m7 enabled-input">
              <div class="col s5 m3">
                {{ 'UserManagementCommon-ACTIVATED' | translate }}
                <span class="sr-required-mark">*</span>
              </div>
              <div
                class="col s7 m6 l4"
                [ngClass]="{
                  'sr-has-error':
                    !editUserForm.controls['activated'].valid && editUserForm.controls['activated'].touched
                }"
              >
                <input
                  id="activated-id"
                  type="radio"
                  name="activated"
                  [value]="true"
                  class="with-gap"
                  [formControl]="editUserForm.controls['activated']"
                />
                <label for="activated-id">
                  {{ 'UNIVERSAL-ACTIVATED' | translate }}
                </label>
                <input
                  id="not-activated-id"
                  type="radio"
                  name="activated"
                  [value]="false"
                  class="with-gap"
                  [formControl]="editUserForm.controls['activated']"
                />
                <label for="not-activated-id">
                  {{ 'UNIVERSAL-NOT_YET_ACTIVATED' | translate }}
                </label>
              </div>
            </div>

            <!-- User Type field -->
            <div
              [hidden]="GOB_COMPONENT"
              class="input-field col s12 m12"
              [ngClass]="{
                'sr-has-error': !editUserForm.controls['userType'].valid && editUserForm.controls['userType'].touched
              }"
            >
              <select
                id="userType"
                [formControl]="editUserForm.controls['userType']"
                class="browser-default org-select"
              >
                <option *ngFor="let n of [1, 2, 3]" [value]="n">
                  {{ userTypeToName[n] }}
                </option>
              </select>

              <label for="userType" class="org-label">
                {{ 'UserManagementCommon-USER_TYPE' | translate }}
                <span class="sr-required-mark">*</span>
              </label>
              <div
                class="error-area2"
                *ngIf="!editUserForm.controls['userType'].valid && editUserForm.controls['userType'].touched"
              >
                <div *ngIf="editUserForm.controls['userType'].errors.required">
                  {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                </div>
              </div>
            </div>

            <div
              [hidden]="GOB_COMPONENT"
              class="input-field col s12 m12"
              *ngIf="
                editUserForm.controls['userType'].value == userTypeEnum.ORG_GENERAL ||
                editUserForm.controls['userType'].value == userTypeEnum.LOCATION
              "
            >
              <select
                id="organizationSelect"
                [formControl]="editUserForm.controls['organizationId']"
                class="browser-default org-select"
              >
                <option *ngFor="let org of organizations" [value]="org.organizationId">
                  {{ org.name }}
                </option>
              </select>
              <label for="organizationSelect" class="org-label">
                {{ 'UNIVERSAL-CONTACT_ORGANIZATION' | translate }}
                <span class="sr-required-mark">*</span>
              </label>
              <div
                class="error-area2"
                *ngIf="
                  editUserForm.controls['organizationId'].touched &&
                  editUserForm.controls['organizationId'].value === null
                "
              >
                <div>
                  {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </ng-template>

          <div class="col s12 m12">
            <div class="my_btns right">
              <a (click)="onCancel()" class="btn cyan">
                {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
              </a>
              <button
                (click)="onSubmit()"
                class="btn waves-effect waves-light cyan"
                [disabled]="isSubmitting || !editUserForm.valid"
              >
                {{ 'UNIVERSAL-NAVIGATION_ADD' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
