import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../common/config';
import { ReportOutputModel } from './report.model';
import { contentHeaders } from '../../common/headers';

const SUBMIT_REPORT_ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/report/submit';

@Injectable()
export class ReportModalService {
  constructor(private http: HttpClient) {}

  submitReport(report: ReportOutputModel): Observable<object> {
    return this.http.post(SUBMIT_REPORT_ENDPOINT_URL, JSON.stringify(report), { headers: contentHeaders });
  }
}
