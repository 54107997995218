import { Component, EventEmitter, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { HtmlOption } from '../../models';
import { CurrencyPickerService } from './currency-picker.service';

@Component({
  selector: 'currency-picker',
  templateUrl: './currency-picker.component.html',
  styleUrls: ['./currency-picker.component.css'],
})
export class CurrencyPickerComponent implements OnInit {
  currencyCodeList: string[];
  initOption$: EventEmitter<string> = new EventEmitter<string>();
  optionsPrefix: string = 'currency';
  optionsLabel: string = 'CurrencyPickerComponent-CURRENCY_TEXT';
  options: HtmlOption[];

  constructor(private currencyService: CurrencyPickerService) {}

  onSelectCurrency(code: string) {
    this.currencyService.setPreferredCurrency(code);
  }

  ngOnInit() {
    const currencyCodes$ = this.currencyService.getCurrencyCodes();
    const prefCurrency$ = this.currencyService.getPreferredCurrency();

    combineLatest([currencyCodes$, prefCurrency$])
      .pipe(
        map(([currencyCodes, prefCurr]) => ({
          currencyCodes,
          prefCurr,
        }))
      )
      .subscribe((success) => {
        this.currencyCodeList = success.currencyCodes.responseData.currency_list;
        this.currencyCodeList.sort();
        this.options = this.currencyCodeList.map((item) => ({ value: item, label: item }));
        this.initOption$.emit(success.prefCurr);
      });
  }
}
