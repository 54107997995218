<div class="modal-header">
  <survey-step-progress-bar [totalSteps]="7" [currentStepIndex]="6" (stepTabClicked)="onSelectStep($event)">
  </survey-step-progress-bar>
  <h4 class="modal-step-title">
    {{
      'DisasterSpecificSurveyComponent-STEP_6_TITLE'
        | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
    }}
  </h4>
</div>
<div class="modal-content">
  <div class="divider title-divider"></div>

  <div class="row margin">
    <div id="ques61" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 5 } }}
    </div>
    <div class="spacing-div col s12">
      <div class="input-field no-padding col s12">
        <p>
          {{
            'DisasterSpecificSurveyComponent-SURVEY_QUESTIONS_LOCAL_STAFF'
              | translate: { location: locations, disaster: surveyData.disaster.name }
          }}
        </p>

        <table class="centered grey lighten-3 table-wrapper">
          <thead>
            <tr>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
              </th>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
              </th>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      localStaffForm.get('value').get('mgmtStaffCount').invalid &&
                      (localStaffForm.get('value').get('mgmtStaffCount').touched ||
                        invalidOnInit?.maps?.localStaff?.mgmtStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="localStaffForm.get('value').get('mgmtStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      localStaffForm.get('value').get('mgmtStaffCount').invalid &&
                      (localStaffForm.get('value').get('mgmtStaffCount').touched ||
                        invalidOnInit?.maps?.localStaff?.mgmtStaffCount)
                    "
                  >
                    <div *ngIf="localStaffForm.get('value').get('mgmtStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      localStaffForm.get('value').get('professionalStaffCount').invalid &&
                      (localStaffForm.get('value').get('professionalStaffCount').touched ||
                        invalidOnInit?.maps?.localStaff?.professionalStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="localStaffForm.get('value').get('professionalStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      localStaffForm.get('value').get('professionalStaffCount').invalid &&
                      (localStaffForm.get('value').get('professionalStaffCount').touched ||
                        invalidOnInit?.maps?.localStaff?.professionalStaffCount)
                    "
                  >
                    <div *ngIf="localStaffForm.get('value').get('professionalStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      localStaffForm.get('value').get('supportStaffCount').invalid &&
                      (localStaffForm.get('value').get('supportStaffCount').touched ||
                        invalidOnInit?.maps?.localStaff?.supportStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="localStaffForm.get('value').get('supportStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      localStaffForm.get('value').get('supportStaffCount').invalid &&
                      (localStaffForm.get('value').get('supportStaffCount').touched ||
                        invalidOnInit?.maps?.localStaff?.supportStaffCount)
                    "
                  >
                    <div *ngIf="localStaffForm.get('value').get('supportStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p class="info blue-text">
            {{ 'SurveyCommon-Q_EXCLUSIVE_EMPLOYEES' | translate: { orgName: surveyData.organizationName } }}
          </p>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing no-padding"
          [formElement]="localStaffForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        >
        </last-edited-metadata>
      </div>
    </div>

    <div id="ques62" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 5 } }}
    </div>
    <div class="spacing-div col s12">
      <div class="input-field no-padding col s12">
        <p>
          {{
            'DisasterSpecificSurveyComponent-SURVEY_QUESTIONS_NON_LOCAL_STAFF'
              | translate: { location: locations, disaster: surveyData.disaster.name }
          }}
        </p>
        <table class="centered grey lighten-3 table-wrapper">
          <thead>
            <tr>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
              </th>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
              </th>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      nonLocalStaffForm.get('value').get('mgmtStaffCount').invalid &&
                      (nonLocalStaffForm.get('value').get('mgmtStaffCount').touched ||
                        invalidOnInit?.maps?.nonLocalStaff?.mgmtStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="nonLocalStaffForm.get('value').get('mgmtStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      nonLocalStaffForm.get('value').get('mgmtStaffCount').invalid &&
                      (nonLocalStaffForm.get('value').get('mgmtStaffCount').touched ||
                        invalidOnInit?.maps?.nonLocalStaff?.mgmtStaffCount)
                    "
                  >
                    <div *ngIf="nonLocalStaffForm.get('value').get('mgmtStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      nonLocalStaffForm.get('value').get('professionalStaffCount').invalid &&
                      (nonLocalStaffForm.get('value').get('professionalStaffCount').touched ||
                        invalidOnInit?.maps?.nonLocalStaff?.professionalStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="nonLocalStaffForm.get('value').get('professionalStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      nonLocalStaffForm.get('value').get('professionalStaffCount').invalid &&
                      (nonLocalStaffForm.get('value').get('professionalStaffCount').touched ||
                        invalidOnInit?.maps?.nonLocalStaff?.professionalStaffCount)
                    "
                  >
                    <div *ngIf="nonLocalStaffForm.get('value').get('professionalStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>

              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      nonLocalStaffForm.get('value').get('supportStaffCount').invalid &&
                      (nonLocalStaffForm.get('value').get('supportStaffCount').touched ||
                        invalidOnInit?.maps?.nonLocalStaff?.supportStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="nonLocalStaffForm.get('value').get('supportStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      nonLocalStaffForm.get('value').get('supportStaffCount').invalid &&
                      (nonLocalStaffForm.get('value').get('supportStaffCount').touched ||
                        invalidOnInit?.maps?.nonLocalStaff?.supportStaffCount)
                    "
                  >
                    <div *ngIf="nonLocalStaffForm.get('value').get('supportStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p class="info blue-text">
            {{ 'SurveyCommon-Q_EXCLUSIVE_EMPLOYEES' | translate: { orgName: surveyData.organizationName } }}
          </p>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing no-padding"
          [formElement]="nonLocalStaffForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        >
        </last-edited-metadata>
      </div>
    </div>

    <div id="ques63" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 5 } }}
    </div>
    <div class="spacing-div col s12">
      <div class="input-field no-padding col s12">
        <p>
          {{
            'DisasterSpecificSurveyComponent-SURVEY_QUESTIONS_VOLUNTEER_STAFF'
              | translate: { disaster: surveyData.disaster.name }
          }}
        </p>
        <table class="centered grey lighten-3 table-wrapper">
          <thead>
            <tr>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_MANA' | translate }}
              </th>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_PROF' | translate }}
              </th>
              <th>
                {{ 'UNIVERSAL-SURVEY_QUESTIONS_SUPP' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      volunteerStaffForm.get('value').get('mgmtStaffCount').invalid &&
                      (volunteerStaffForm.get('value').get('mgmtStaffCount').touched ||
                        invalidOnInit?.maps?.volunteerStaff?.mgmtStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="volunteerStaffForm.get('value').get('mgmtStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      volunteerStaffForm.get('value').get('mgmtStaffCount').invalid &&
                      (volunteerStaffForm.get('value').get('mgmtStaffCount').touched ||
                        invalidOnInit?.maps?.volunteerStaff?.mgmtStaffCount)
                    "
                  >
                    <div *ngIf="volunteerStaffForm.get('value').get('mgmtStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      volunteerStaffForm.get('value').get('professionalStaffCount').invalid &&
                      (volunteerStaffForm.get('value').get('professionalStaffCount').touched ||
                        invalidOnInit?.maps?.volunteerStaff?.professionalStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="volunteerStaffForm.get('value').get('professionalStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      volunteerStaffForm.get('value').get('professionalStaffCount').invalid &&
                      (volunteerStaffForm.get('value').get('professionalStaffCount').touched ||
                        invalidOnInit?.maps?.volunteerStaff?.professionalStaffCount)
                    "
                  >
                    <div *ngIf="volunteerStaffForm.get('value').get('professionalStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      volunteerStaffForm.get('value').get('supportStaffCount').invalid &&
                      (volunteerStaffForm.get('value').get('supportStaffCount').touched ||
                        invalidOnInit?.maps?.volunteerStaff?.supportStaffCount)
                  }"
                >
                  <input
                    class="column2"
                    type="text"
                    [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                    [formControl]="volunteerStaffForm.get('value').get('supportStaffCount')"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      volunteerStaffForm.get('value').get('supportStaffCount').invalid &&
                      (volunteerStaffForm.get('value').get('supportStaffCount').touched ||
                        invalidOnInit?.maps?.volunteerStaff?.supportStaffCount)
                    "
                  >
                    <div *ngIf="volunteerStaffForm.get('value').get('supportStaffCount').hasError('pattern')">
                      {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p class="info blue-text">
            {{ 'SurveyCommon-Q_EXCLUSIVE_EMPLOYEES' | translate: { orgName: surveyData.organizationName } }}
          </p>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing no-padding"
          [formElement]="volunteerStaffForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        >
        </last-edited-metadata>
      </div>
    </div>

    <!-- publication frequency, most recent report -->
    <div id="ques64" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: 5 } }}
    </div>
    <div class="spacing-div col s12">
      <div class="input-field no-padding radio-button-spacing col s12">
        <p>
          {{
            'DisasterSpecificSurveyComponent-STEP_6_Q_SITUATION_REPORT'
              | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
          }}
        </p>
        <input
          [value]="true"
          id="s6_situation_report_yes_rb"
          class="with-gap"
          name="s6_situation_report"
          type="radio"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="activityReportForm.get('value').get('hasValue')"
        />
        <label for="s6_situation_report_yes_rb">
          {{ 'UNIVERSAL-YES' | translate }}
        </label>
        <br />
        <input
          [value]="false"
          class="with-gap"
          name="s6_situation_report"
          type="radio"
          id="s6_situation_report_no_rb"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="activityReportForm.get('value').get('hasValue')"
        />
        <label for="s6_situation_report_no_rb">
          {{ 'UNIVERSAL-NO' | translate }}
        </label>
      </div>

      <div *ngIf="activityReportForm.get('value').get('hasValue').value">
        <div class="col s12 spacing no-padding input-field">
          <div>
            <p>
              {{
                'DisasterSpecificSurveyComponent-STEP_6_Q_PUBLICATION_FREQ'
                  | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
              }}
            </p>

            <select
              class="browser-default col m6 s12"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              [formControl]="activityReportForm.get('value').get('reportFrequencyId')"
            >
              <option *ngFor="let freq of reportFrequencyTypes" [ngValue]="freq.id">
                {{ freq.displayName }}
              </option>
            </select>
          </div>
        </div>

        <label for="reportFrequencyOther" class="sr-label">
          {{ 'DisasterSpecificSurveyComponent-STEP_6_Q_PROVIDE_OTHER_REPORT_FREQ' | translate }}
        </label>
        <div
          class="spacing no-padding col s12 input-field"
          *ngIf="activityReportForm.get('value').get('reportFrequencyId').value === 5"
        >
          <input
            id="reportFrequencyOther"
            type="text"
            materialize="characterCounter"
            length="64"
            maxlength="64"
            placeholder="{{ 'DisasterSpecificSurveyComponent-STEP_6_Q_PROVIDE_OTHER_REPORT_FREQ' | translate }}"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="activityReportForm.get('value').get('reportFrequencyOther')"
          />
        </div>

        <div class="input-field no-padding col s12">
          <p>
            {{ 'DisasterSpecificSurveyComponent-STEP_6_Q_PROVIDE_PUBLICATION_URL' | translate }}
          </p>
        </div>

        <div class="input-field no-padding col s12">
          <p>
            {{ 'LocationSpecificSurveyComponent-PLAN_UPLOAD_OR_PASTE' | translate }}
          </p>
        </div>

        <div *ngIf="activityReportForm.get('value').get('fileId').value" class="input-field no-padding col s12">
          <p>
            {{ 'UNIVERSAL-FILE_PERMANENT_DELETE_WARNING' | translate }}
          </p>
          <p>
            <a
              class="btn"
              target="_blank"
              href="{{ documentService.getDocumentDownloadLink(activityReportForm.get('value').get('fileId').value) }}"
            >
              {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
            </a>
          </p>
          <p>
            <button class="btn" (click)="activityReportForm.get('value').get('fileId').setValue(null)">
              {{ 'UNIVERSAL-DELETE_CURRENT_FILE' | translate }}
            </button>
          </p>
        </div>

        <div class="file-field no-padding spacing input-field col s12">
          <div class="btn">
            <span>
              {{ 'UNIVERSAL-FILE' | translate }}
            </span>
            <input
              type="file"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              (change)="onFileSelected($event, activityReportForm.get('value').get('fileId'))"
            />
          </div>
          <div class="file-path-wrapper">
            <input
              class="file-path validate"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              type="text"
            />
          </div>
          <div>
            <p class="sr-img-guidelines">
              {{ 'LogoUploadComponent-MAXIMUM_FILE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

              {{ 'LogoUploadComponent-DEFAULT_ALLOWED_FILE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
            </p>
          </div>
        </div>

        <label for="activityReportUrl" class="sr-label">
          {{ 'DisasterSpecificSurveyComponent-PLAN_PASTE_LINK' | translate }}
        </label>
        <div
          class="input-field no-padding spacing col s12"
          [ngClass]="{
            'sr-has-error':
              !activityReportForm.get('value').get('webLink').valid &&
              (activityReportForm.get('value').get('webLink').touched || invalidOnInit?.maps?.activityReport?.webLink)
          }"
        >
          <i class="material-icons prefix">link</i>
          <input
            class="column3"
            id="activityReportUrl"
            type="text"
            placeholder="{{ 'DisasterSpecificSurveyComponent-PLAN_PASTE_LINK' | translate }}"
            materialize="characterCounter"
            length="512"
            maxlength="512"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="activityReportForm.get('value').get('webLink')"
          />
          <div
            class="sr-error-area shift-margin"
            *ngIf="
              !activityReportForm.get('value').get('webLink').valid &&
              (activityReportForm.get('value').get('webLink').touched || invalidOnInit?.maps?.activityReport?.webLink)
            "
          >
            <div *ngIf="activityReportForm.get('value').get('webLink').hasError('pattern')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_URL' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing no-padding"
          [formElement]="activityReportForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        >
        </last-edited-metadata>
      </div>
    </div>

    <div id="ques65" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 5, QuestionCount: 5 } }}
    </div>
    <div class="spacing-div col s12">
      <div class="input-field spacing no-padding col s12">
        <p>
          {{
            'DisasterSpecificSurveyComponent-STEP_6_Q_PARTNERING_ORGS_AND_AGENCIES'
              | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
          }}
        </p>
        <div
          class="input-field input-list col s12"
          *ngFor="let formControl of engagementListForm.get('value')['controls']; let i = index"
          [ngClass]="{
            'sr-has-error': !formControl.valid && (formControl.touched || invalidOnInit?.arrays?.engagementList[i])
          }"
        >
          <i class="material-icons prefix">business</i>
          <input
            id="{{ 'engagementList' + i }}"
            type="text"
            materialize="characterCounter"
            length="256"
            maxlength="256"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="formControl"
            [placeholder]="'SurveyCommon-Q_LIST_PARTNER_NAME' | translate"
          />
          <i class="material-icons right sr-link" (click)="onDeleteEngagement(i)"> delete </i>
          <div
            class="sr-error-area shift-margin"
            *ngIf="!formControl.valid && (formControl.touched || invalidOnInit?.arrays?.engagementList[i])"
          >
            <div *ngIf="formControl.value.length > 0">
              <div *ngIf="formControl.hasError('leadingTrailingWhiteSpaceFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
              </div>
              <div *ngIf="formControl.hasError('orgFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PARTNERNAME' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row margin">
          <button
            (click)="onAddEngagement()"
            class="btn waves-effect waves-light cyan"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          >
            {{ 'SurveyCommon-ADD_PARTNER_BUTTON' | translate }}
          </button>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing no-padding"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
          [formElement]="engagementListForm"
        >
        </last-edited-metadata>
      </div>
      <div class="input-field spacing no-padding col s12">
        <p>
          {{ 'DisasterSpecificSurveyComponent-STEP_6_Q_ADDITIONAL_COMMENTS' | translate }}
        </p>
        <textarea
          class="materialize-textarea"
          materialize="characterCounter"
          length="1200"
          maxlength="1200"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="additionalInfoForm.get('value')"
        >
        </textarea>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing no-padding"
          [formElement]="engagementListForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        >
        </last-edited-metadata>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <survey-footer
    [currentStepIndex]="5"
    [totalSteps]="totalSteps"
    [isValid]="isValid"
    (next)="onNext()"
    (prev)="onPrev()"
    (submit)="onSubmit()"
    (cancel)="onCancel()"
    (error)="onError()"
  >
  </survey-footer>
</div>
