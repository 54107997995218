import { Component } from '@angular/core';
import { ListComponentData } from './list.model';

export const DISASTER_TYPE_CONFIG: ListComponentData = {
  listName: 'ListComponent-DISASTER_TYPE_LIST_TITLE',
  listItemName: 'ListComponent-DISASTER_TYPE_LIST_SUB_HEADER',

  placeholderText: 'ListComponent-DISASTER_TYPE_LIST_PLACEHOLDER',

  endpoint: 'admin/disaster_type',
  propertyName: 'disasterTypeNames',
  key: 'name',
};

export const RELIEF_RECOVERY_CONFIG: ListComponentData = {
  listName: 'ListComponent-RELIEF_TYPE_LIST_TITLE',
  listItemName: 'ListComponent-RELIEF_TYPE_LIST_SUB_HEADER',

  placeholderText: 'ListComponent-RELIEF_TYPE_LIST_PLACEHOLDER',

  endpoint: 'admin/relief_type',
  propertyName: 'reliefTypeNames',
  key: 'name',
};

export const VETTING_AGENCIES_CONFIG: ListComponentData = {
  listName: 'ListComponent-VETTING-AGENCY_LIST_TITLE',
  listItemName: 'ListComponent-VETTING-AGENCY_LIST_SUB_HEADER',
  placeholderText: 'ListComponent-VETTING-AGENCY_LIST_PLACEHOLDER',
  propertyName: 'vettingAgencyNames',
  endpoint: 'admin/vetting_agency',
  key: 'agencyName',
};

@Component({
  selector: 'lists-container',
  providers: [],
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css'],
})
export class ListsContainerComponent {
  lists: ListComponentData[];

  constructor() {
    this.lists = [DISASTER_TYPE_CONFIG, RELIEF_RECOVERY_CONFIG, VETTING_AGENCIES_CONFIG];
  }
}
