import { FormControl } from '@angular/forms';

interface ValidationResult {
  [key: string]: boolean;
}
export class LeadingTrailingWhiteSpaceValidator {
  public static leadingTrailingWhiteSpaceFormat(control: FormControl): ValidationResult {
    const LEADINGTRAILING_WHITESPACE_REGEXP = /^(?: .*$|.* $)/i;

    if (LEADINGTRAILING_WHITESPACE_REGEXP.test(control.value)) {
      return { leadingTrailingWhiteSpaceFormat: true };
    }
    return null;
  }
}
