import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../common/config';
import { Observable } from 'rxjs';
import { RecentSurvey, SurveyType } from '../../models/RecentSurvey';
import { TranslateService } from '@ngx-translate/core';
import { SRResponse } from '../../models/SRResponse';
import { RecentSurveyResponse } from 'app/models';

const ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/survey/';

const NEW_ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/survey/gob/newly_approved';

@Injectable()
export class RecentSurveyService {
  constructor(private http: HttpClient, private translateService: TranslateService) {}

  getRecentSurveys(num: number, surveyType: SurveyType): Observable<RecentSurvey[]> {
    return this.http
      .get<SRResponse>(
        `${ENDPOINT_URL}${SurveyType[surveyType].toLowerCase()}/search?mergeRepeatResult=true&recordLimit=${num}`
      )
      .pipe(
        map((res) =>
          res.responseData.survey_list.map((item) => {
            let survey: RecentSurvey = {
              surveyType,
              submissionId: item.surveySubmissionId,
              surveyUpdateDate: item.lastUpdatedDate,
              orgName: item.organizationName,
              orgId: item.organizationId,
              typeName:
                item.hqAddress && item.hqAddress.value.country
                  ? item.hqAddress.value.country.name
                  : this.translateService.instant('COMMON_SEARCH-NO_RESPONSE'),
            };

            if (surveyType === SurveyType.Dss) {
              survey = Object.assign(survey, {
                typeId: item.disaster.disasterId,
                typeName:
                  item.disaster && item.disaster.name
                    ? item.disaster.name
                    : this.translateService.instant('COMMON_SEARCH-NO_RESPONSE'),
              });
            } else if (surveyType === SurveyType.Lss) {
              survey = Object.assign(survey, {
                typeId: item.country.countryId,
                typeName:
                  item.country && item.country.name
                    ? item.country.name
                    : this.translateService.instant('COMMON_SEARCH-NO_RESPONSE'),
              });
            }

            return survey;
          })
        )
      );
  }

  getNewSurveys(): Observable<RecentSurveyResponse[]> {
    return this.http.get<SRResponse>(`${NEW_ENDPOINT_URL}`).pipe(map((res) => res.responseData.survey_list));
  }
}
