import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericSortPipe',
  pure: true,
})
export class GenericSortPipe implements PipeTransform {
  /**
   *
   * @param inputs array of Objects to sort
   * @param sortField sort by this field
   * @param reverse return the array in reversed sorted order
   * @returns {any[]}
   */
  transform(inputs: any[], sortField: string, reverse: boolean = false): any[] {
    if (inputs && sortField) {
      // use JS Array.prototype.sort(cmpFunc()) to sort
      return inputs.sort((a, b) => {
        // make a copy of the elements for manipulation
        let aVal = a[sortField];
        let bVal = b[sortField];

        // if elements to be sorted are String, avoid case sorting issues
        if (typeof aVal === 'string') {
          aVal = aVal.toUpperCase();
        }
        if (typeof bVal === 'string') {
          bVal = bVal.toUpperCase();
        }

        if (aVal === bVal || (aVal === null && bVal === null)) {
          return 0;
        }

        if (aVal === null) {
          return reverse ? 1 : -1;
        }

        if (bVal === null) {
          return reverse ? -1 : 1;
        }

        // returning 1 or -1 decides the order: set the opposite of normal to reverse output
        return aVal > bVal ? (reverse ? -1 : 1) : reverse ? 1 : -1;
      });
    }

    return inputs;
  }
}
