import { filter } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

interface BreadCrumbPiece {
  routerLink: string[];
  name: string;
}

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
})
export class BreadcrumbsComponent {
  breadcrumbs: BreadCrumbPiece[];
  lastCrumb: string;

  constructor(private router: Router) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.breadCrumbifyRoute(this.router.routerState.snapshot.url);
    });

    setTimeout(() => this.breadCrumbifyRoute(this.router.routerState.snapshot.url), 75);
  }

  breadCrumbifyRoute(route: string): void {
    const routeParams = route.split('?')[0];

    const crumbs: BreadCrumbPiece[] = [];
    const routePieces = routeParams.split('/');

    if (routePieces.length === 0) {
      return;
    }

    // tslint:disable-next-line:no-increment-decrement
    for (let i = 0; i < routePieces.length; i++) {
      if (parseInt(routePieces[i], 10)) {
        continue;
      }
      crumbs.push({
        routerLink: [routePieces.slice(0, i + 1).join('/')],
        name: routePieces[i],
      });
    }

    this.lastCrumb = crumbs.splice(-1, 1)[0].name;

    this.breadcrumbs = crumbs;
  }
}
