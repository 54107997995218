<ng-template [ngIf]="myOrganization">
  <app-loader [loading]="loading"></app-loader>
  <div class="row">
    <div class="col m12 s12">
      <div class="card">
        <div class="card-content">
          <div class="card-title sr-bold">
            {{ 'DashboardOrganizationViewComponent-TITLE_LEFT' | translate }}
            <span class="sr-italic">{{ myOrganization.name }}</span>
            {{ 'DashboardOrganizationViewComponent-TITLE_RIGHT' | translate }}
          </div>
          <div class="divider"></div>
          <br />

          <div class="collection">
            <div class="row">
              <div class="col s12 m4">
                <div class="row collection-item">
                  <img
                    src="{{ myOrganization.logoURI }}"
                    alt="{{ 'DashboardOrganizationViewComponent-LOGO_ALT' | translate }}"
                    class="col s12 responsive-img"
                  />
                </div>
              </div>
              <div class="col s12 m8 organization-details">
                <div class="row collection-item">
                  <div class="sr-bold col s12 m4">{{ 'Organization_Common-NAME' | translate }}:</div>
                  <div class="col s12 m8">{{ myOrganization.name }}</div>
                </div>
                <div class="row collection-item">
                  <div class="sr-bold col s12 m4">{{ 'Organization_Common-CITY' | translate }}:</div>
                  <div class="col s12 m8">{{ myOrganization.city }}</div>
                </div>
                <div class="row collection-item">
                  <div class="sr-bold col s12 m4">{{ 'Organization_Common-STATE' | translate }}:</div>
                  <div class="col s12 m8">{{ myOrganization.state }}</div>
                </div>
                <div class="row collection-item">
                  <div class="sr-bold col s12 m4">{{ 'Organization_Common-COUNTRY' | translate }}:</div>
                  <div class="col s12 m8">
                    <div>{{ myOrganization.country }}</div>
                  </div>
                </div>
                <div class="row collection-item even">
                  <div class="sr-bold col s12 m4">{{ 'Organization_Common-SUMMARY' | translate }}:</div>
                  <div class="col s12 m8 sr-justify" [innerHtml]="myOrganization.summary"></div>
                </div>
                <div class="row collection-item even">
                  <div class="sr-bold col s12 m4">{{ 'Organization_Common-DATE_ADDED' | translate }}:</div>
                  <div class="col s12 m8 sr-justify">
                    {{ myOrganization.addedDate | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}
                  </div>
                </div>
                <div class="row collection-item even">
                  <div class="sr-bold col s12 m4">{{ 'DashboardOrganizationViewComponent-METADATA' | translate }}</div>
                  <div class="col s12 m8 sr-justify">{{ orgMetadataForm.value }}</div>
                </div>
                <div *ngFor="let location of locations" class="row collection-item even">
                  <div class="sr-bold col s12 m4">
                    {{
                      'DashboardOrganizationViewComponent-LOCATION_METADATA' | translate: { location: location.name }
                    }}
                  </div>
                  <div class="col s12 m8 sr-justify" *ngIf="orgLocationMetadataForms.controls[location.id]">
                    {{ orgLocationMetadataForms.controls[location.id].value }}
                  </div>
                </div>
                <div *ngFor="let disaster of disasters | unique: 'id'" class="row collection-item even">
                  <div class="sr-bold col s12 m4">
                    {{
                      'DashboardOrganizationViewComponent-DISASTER_METADATA' | translate: { disaster: disaster.name }
                    }}
                  </div>
                  <div class="col s12 m8 sr-justify" *ngIf="orgDisasterMetadataForms.controls[disaster.id]">
                    {{ orgDisasterMetadataForms.controls[disaster.id].value }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <div class="input-field right">
                  <button
                    class="waves-effect waves-light btn cyan"
                    (click)="this.modalActions.emit({ action: 'modal', params: ['open'] })"
                  >
                    {{ 'DashboardOrganizationViewComponent-METADATA_MODAL_BUTTON' | translate }}
                  </button>
                  <a
                    class="waves-effect waves-light btn cyan"
                    [routerLink]="['/dashboard/organizations/edit', myOrganization.organizationId]"
                  >
                    {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
                  </a>
                  <a class="waves-effect waves-light btn cyan" [routerLink]="['..']">
                    <i class="material-icons left">skip_previous</i>
                    {{ 'UNIVERSAL-NAVIGATION_BACK' | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Metadata Modal -->
  <div
    id="metadata-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="[{ dismissible: true }]"
    [materializeActions]="modalActions"
  >
    <div class="modal-content">
      <h4>{{ 'DashboardOrganizationViewComponent-METADATA_MODAL_TITLE' | translate }}</h4>
      <div class="divider"></div>
      <p>{{ 'DashboardOrganizationViewComponent-METADATA_INSTRUCTIONS' | translate }}</p>
      <ul>
        <li class="input-field col s12">
          <p>{{ myOrganization.name }}</p>
          <i class="material-icons prefix">share</i>
          <input
            id="sharing-metadata"
            type="text"
            [formControl]="orgMetadataForm"
            materialize="characterCounter"
            length="40"
            maxlength="40"
          />
        </li>
        <li *ngFor="let location of locations" class="input-field col s12">
          <p>{{ location.name }}</p>
          <i class="material-icons prefix">share</i>
          <input
            *ngIf="orgLocationMetadataForms.controls[location.id]"
            id="sharing-metadata"
            type="text"
            [formControl]="orgLocationMetadataForms.controls[location.id]"
            materialize="characterCounter"
            length="40"
            maxlength="40"
          />
        </li>
        <li *ngFor="let disaster of disasters | unique: 'id'" class="input-field col s12">
          <p>{{ disaster.name }}</p>
          <i class="material-icons prefix">share</i>
          <input
            *ngIf="orgDisasterMetadataForms.controls[disaster.id]"
            id="sharing-metadata"
            type="text"
            [formControl]="orgDisasterMetadataForms.controls[disaster.id]"
            materialize="characterCounter"
            length="40"
            maxlength="40"
          />
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button
        class="waves-effect waves-light btn cyan darken-3 right"
        [disabled]="orgMetadataForm.pristine && orgLocationMetadataForms.pristine && orgDisasterMetadataForms.pristine"
        (click)="submitMetadata()"
      >
        {{ 'UNIVERSAL-NAVIGATION_SAVE' | translate }}
      </button>
      <button
        class="waves-effect waves-light btn cyan darken-3 left"
        (click)="this.modalActions.emit({ action: 'modal', params: ['close'] })"
      >
        {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
      </button>
    </div>
  </div>
</ng-template>
