import { FormControl } from '@angular/forms';
import { BasicDateValidator } from './basicDateValidator';

interface ValidationResult {
  [key: string]: boolean;
}

export class YearDateValidator {
  /**
   * test that control value is in format 'x-y-z', where x, y, and z are valid integers
   * @param control
   * @returns {null}
   */
  public static isYear(control: FormControl): ValidationResult {
    const testValue = control.value || '';
    const invalidResult = {
      isYear: true,
    };

    // validation should pass if empty
    if (testValue === '') {
      return null;
    }

    if (isNaN(parseInt(testValue, 10)) || testValue.length !== 4) {
      return invalidResult;
    }

    return null;
  }

  public static pastYear(control: FormControl): ValidationResult {
    const today = new Date();
    const testValue = control.value || '';
    const invalidResult = {
      pastYear: true,
    };

    if (testValue === '') {
      return null;
    }

    if (today.getFullYear() < testValue) {
      return invalidResult;
    }
    return null;
  }
  public static minimumYear(control: FormControl): ValidationResult {
    const today = new Date();
    const minimumYear = 1850;

    const testValue = control.value || '';
    const invalidResult = {
      minimumYear: true,
    };

    // validation should pass if empty
    if (testValue === '') {
      return null;
    }

    if (parseInt(testValue, 10) < minimumYear) {
      return invalidResult;
    }
    return null;
  }
}
