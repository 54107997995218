import { Component, ViewChild, ViewChildren, Input, QueryList } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';
import { DSSStepComponent } from '../dss-step/dss-step.component';
import { MapLocation } from '../../../../../common/locationMap/location-map.model';
import { AgmInfoWindow, AgmMap, AgmMarker, MapsAPILoader, MouseEvent } from '@agm/core';
import { RadiusOption, radiusOptions } from '../../../disaster/radiusOptions';
declare var google: any;

@Component({
  selector: 'dss-step7',
  templateUrl: './dss-step7.component.html',
  styleUrls: ['./dss-step7.component.css', '../dss-steps-common.css', '../../survey-table-common.css'],
})
export class DSSStep7Component extends DSSStepComponent {
  locationListForm: FormGroup;

  mapLocations: MapLocation[] = [];

  currentCountry: string = '';

  mapLocationLng: number = -34.397;
  mapLocationLat: number = 150.644;

  @Input() countriesToBeShown = [];

  @Input()
  set triggerMapResize(triggerMapResize: boolean) {
    if (triggerMapResize) {
      setTimeout(() => {
        this.map.triggerResize();
        Promise.all(this.markerWindows.map((window) => window.close())).then(
          () => this.markerWindows && this.markerWindows.last && this.markerWindows.last.open()
        );
      }, 500);
    }
  }

  @ViewChild(AgmMap, { static: false }) map: AgmMap;
  @ViewChildren(AgmInfoWindow) markerWindows: QueryList<AgmInfoWindow>;

  radii: RadiusOption[] = radiusOptions.filter((option) => option.value !== null);

  ngAfterViewInit(): void {
    this.markerWindows.changes.subscribe(() => {
      Promise.all(this.markerWindows.map((window) => window.close())).then(
        () => this.markerWindows && this.markerWindows.last && this.markerWindows.last.open()
      );
    });
  }

  constructor(private surveyManager: DisasterSpecificSurveyManager, private mapsApi: MapsAPILoader) {
    super(surveyManager);

    this.locationListForm = <FormGroup>this.surveyManager.mainForm.controls['locationList'];
  }

  assignDataToForm(): void {
    if (!this.surveyData) {
      return;
    }
    if (this.countriesToBeShown.length > 0) {
      this.currentCountry = this.countriesToBeShown[0].country.name;
    }
    this.setMapCountry();

    if (this.surveyData.locationList) {
      this.locationListForm.get('lastUpdatedDate').setValue(this.surveyData.locationList.lastUpdatedDate);
      this.locationListForm.get('lastUpdatedUser').setValue(this.surveyData.locationList.lastUpdatedUser);
      this.locationListForm.get('updatedBySRAdmin').setValue(this.surveyData.locationList.updatedBySRAdmin);
    }

    this.locationListForm.controls['value'] = new FormArray([]);

    if (this.surveyData.locationList && this.surveyData.locationList.value) {
      let index = 0;
      for (const location of this.surveyData.locationList.value) {
        (<FormArray>this.locationListForm.controls['value']).push(
          new FormGroup({
            geoLatitude: new FormControl(location.geoLatitude),
            geoLongitude: new FormControl(location.geoLongitude),
            radius: new FormControl(location.radius),
            label: new FormControl(String.fromCharCode(65 + index)),
          })
        );
        index += 1;
      }
    } else {
      this.locationListForm.controls['value'] = new FormArray([]);
    }
  }

  setMapCountry() {
    if (!(this.currentCountry == null && this.currentCountry === '')) {
      this.mapsApi.load().then(() => {
        const geocoder = new google.maps.Geocoder();
        const location = this.currentCountry;
        geocoder.geocode({ address: location }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.mapLocationLat = results[0].geometry.location.lat();
            this.mapLocationLng = results[0].geometry.location.lng();
          } else {
            alert('Could not find location: ' + location);
          }
        });
      });
    }
  }

  mapClicked($event: MouseEvent): void {
    this.mapLocationLat = $event.coords.lat;
    this.mapLocationLng = $event.coords.lng;
    const length = (<FormArray>this.locationListForm.controls['value']).length;
    (<FormArray>this.locationListForm.controls['value']).push(
      new FormGroup({
        geoLatitude: new FormControl($event.coords.lat),
        geoLongitude: new FormControl($event.coords.lng),
        radius: new FormControl(25),
        label: new FormControl(String.fromCharCode(65 + length)),
      })
    );
  }

  markerClicked($event: AgmMarker) {
    Promise.all(this.markerWindows.map((window) => window.close())).then(
      () => $event && $event.infoWindow.forEach((window) => window.open())
    );
  }

  onMarkerDelete(index: number) {
    (<FormArray>this.locationListForm.controls['value']).removeAt(index);
    this.reassignMarkerLabels();
  }

  reassignMarkerLabels(): void {
    let index = 0;

    for (const marker of (<FormArray>this.locationListForm.controls['value']).controls) {
      marker.patchValue({
        label: String.fromCharCode(65 + index),
      });
      index += 1;
    }
  }
}
