<div class="container">
  <app-loader [loading]="loading"></app-loader>
  <div class="row">
    <div class="col s12 md10 l10 offset-l1 card-panel hoverable registration-card card">
      <div class="row">
        <div class="input-field col s12 center">
          <h5>
            {{ 'ChangePasswordComponent-TITLE' | translate }}
          </h5>
        </div>
      </div>

      <ng-template [ngIf]="hasSubmitted">
        <div class="row margin">
          <a [routerLink]="['/dashboard']">
            {{ 'ChangePasswordComponent-SUCCESSFUL_UPDATE_AND_LINK' | translate }}
          </a>
        </div>
      </ng-template>

      <ng-template [ngIf]="!hasSubmitted">
        <div class="sr-required-notif sr-padding-required-text">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>

        <div class="row margin">
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !form.controls['oldPass'].valid &&
                (form.controls['oldPass'].touched || form.controls['oldPass'].value.length)
            }"
          >
            <i class="material-icons prefix">perm_identity</i>
            <input id="oldPass" type="password" [formControl]="form.controls['oldPass']" />
            <label for="oldPass">
              {{ 'ChangePasswordComponent-OLD_PASS' | translate }}<span class="sr-required-mark"> *</span>
            </label>
            <div
              class="sr-error-area"
              *ngIf="
                !form.controls['oldPass'].valid &&
                (form.controls['oldPass'].touched || form.controls['oldPass'].value.length)
              "
            >
              <div *ngIf="form.controls['oldPass'].errors.required" class="sr-padding-required-text">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
              <div *ngIf="form.controls['oldPass'].hasError('emptySpace')" class="sr-padding-required-text">
                {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row margin">
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !form.controls['password'].valid &&
                (form.controls['password'].touched || form.controls['password'].value.length)
            }"
          >
            <i class="material-icons prefix">lock_open</i>
            <input
              id="password"
              type="password"
              materialize="characterCounter"
              length="45"
              [formControl]="form.controls['password']"
            />
            <label for="password">
              {{ 'ChangePasswordComponent-NEW_PASS' | translate }}<span class="sr-required-mark"> *</span>
            </label>

            <div
              class="sr-error-area sr-padding-required-text"
              *ngIf="
                !form.controls['password'].valid &&
                (form.controls['password'].touched || form.controls['password'].value)
              "
            >
              <div *ngIf="form.controls['password'].hasError('required')">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>

              <div *ngIf="form.controls['password'].hasError('minlength')">
                {{ 'UNIVERSAL-FORM_ERRORS_MIN_8_CHARACTERS' | translate }}
              </div>

              <div *ngIf="form.controls['password'].hasError('maxlength')">
                {{ 'UNIVERSAL-FORM_ERRORS_MAX_45_CHARACTERS' | translate }}
              </div>
              <div *ngIf="form.controls['password'].hasError('emptySpace')">
                {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row margin">
          <div class="input-field col s12" [ngClass]="{ 'sr-has-error': form.hasError('passwordMismatch') }">
            <i class="material-icons prefix">lock_open</i>

            <input id="passwordConfirmation" type="password" [formControl]="form.controls['passwordConfirmation']" />

            <label for="passwordConfirmation"> {{ 'UNIVERSAL-CONFIRM_PASSWORD_LABEL' | translate }}* </label>

            <div class="sr-error-area sr-padding-required-text" *ngIf="form.hasError('passwordMismatch')">
              {{ 'UNIVERSAL-FORM_ERRORS_PASSWORDS_MUST_MATCH' | translate }}
            </div>
          </div>
        </div>

        <div class="row margin">
          <div class="input-field center">
            <button
              (click)="onSubmit()"
              class="btn waves-effect waves-light cyan"
              [disabled]="isSubmitting || !form.valid"
            >
              {{ 'ChangePasswordComponent-SUBMIT' | translate }}
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
