import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Disaster } from '../../../../models/Disaster';
import { DisasterAdminService } from '../disaster.service';
import { AppSettings } from '../../../../common/config';
import { MaterializeAction } from 'angular2-materialize';
import { FormControl } from '@angular/forms';

enum DisasterType {
  APPROVED_DISASTERS = 'Approved disasters',
  NOMINATED_DISASTERS = 'Nominated disasters',
}

@Component({
  selector: 'dashboardBaseDisaster',
  templateUrl: './base.component.html',
  styleUrls: ['../../../common-base.css', './base.component.css'],
  providers: [],
})
export class DashboardDisasterBaseComponent implements OnInit {
  itemsPerPageArg: number = 10;
  selectedPage: number = 0;
  disasters: Disaster[];
  filterArg: string;
  disasterListType: string;

  // set initial sort field here
  sortField: string = 'timestamp';
  sortDescending: boolean = true;

  /* For use in Template */
  AppSettings = AppSettings;

  loading: boolean;
  DisasterType = DisasterType;
  modalActions = new EventEmitter<string | MaterializeAction>();

  currentDisaster: Disaster;
  priorityForm: FormControl;
  isSubmitting: boolean = false;

  constructor(public router: Router, public service: DisasterAdminService) {}

  ngOnInit() {
    this.disasterListType = this.DisasterType.APPROVED_DISASTERS;
  }

  switchDisasterList() {
    this.disasterListType === this.DisasterType.APPROVED_DISASTERS
      ? (this.disasterListType = this.DisasterType.NOMINATED_DISASTERS)
      : (this.disasterListType = this.DisasterType.APPROVED_DISASTERS);
  }
}
