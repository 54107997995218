export interface ReportInputModel {
  orgName: string;

  surveyId: number;

  surveyType: string;
  surveyTypeTranslationKey?: string;

  surveyQuestionKey?: string;
  surveyQuestionTranslationKey?: string;
}

export interface ReportOutputModel extends ReportInputModel {
  captcha: string;
  reason: string;
  redFlagReportRespondentComment?: string;
  redFlagReportRespondentEmail?: string;
  redFlagReportRespondentName?: string;
  redFlagReportRespondentPhone?: string;
  redFlagReportRespondentRole?: string;
  reportDate?: string;
}

export class ReportSurveyTypes {
  public static GOB = 'GOB';
  public static LSS = 'LSS';
  public static DSS = 'DSS';

  public static toTranslationKey(key: string) {
    return {
      GOB: 'UNIVERSAL-SURVEY_GOB',
      LSS: 'UNIVERSAL-SURVEY_LSS',
      DSS: 'UNIVERSAL-SURVEY_DSS',
    }[key];
  }
}
