<div class="valign-wrapper">
  <div class="row valign" [style.position]="'relative'">
    <div class="div-loader">
      <app-loader [loading]="authService.isAttemptingToLogIn"></app-loader>
    </div>

    <div class="col s20 hoverable login-card card" [ngStyle]="{ opacity: authService.isAttemptingToLogIn ? 0.4 : 1 }">
      <div class="input-field col s12 center row">
        <h5 class="center login-form-text">
          {{ 'LoginComponent-LOGIN_LABEL' | translate }}
        </h5>

        <div class="red-text" *ngIf="authService.errorText">{{ authService.errorText }}</div>
      </div>

      <form (submit)="onSubmit()">
        <div class="row margin">
          <div
            class="input-field col s12"
            [ngClass]="{ 'sr-has-error': !loginForm.controls['email'].valid && loginForm.controls['email'].touched }"
          >
            <i class="material-icons prefix">email</i>

            <input type="email" id="email" [formControl]="loginForm.controls['email']" />

            <label for="email"
              >{{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}<span class="sr-required-mark"> *</span></label
            >

            <div
              class="sr-error-area"
              *ngIf="!loginForm.controls['email'].valid && loginForm.controls['email'].touched"
            >
              <div *ngIf="loginForm.controls['email'].hasError('required')" class="sr-padding-required-text">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>

              <div class="sr-padding-required-text" *ngIf="loginForm.controls['email'].hasError('mailFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row margin">
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error': !loginForm.controls['password'].valid && loginForm.controls['password'].touched
            }"
          >
            <i class="material-icons prefix">lock</i>

            <input id="password" type="password" [formControl]="loginForm.controls['password']" />

            <label for="password"
              >{{ 'LoginComponent-PASSWORD_LABEL' | translate }}<span class="sr-required-mark"> *</span></label
            >

            <div
              class="sr-error-area"
              *ngIf="!loginForm.controls['password'].valid && loginForm.controls['password'].touched"
            >
              <div *ngIf="loginForm.controls['password'].hasError('required')" class="sr-padding-required-text">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
            </div>
          </div>

          <span class="pwd">
            <a [routerLink]="['/reset']" class="blue-text" role="button"
              >{{ 'LoginComponent-FORGOT_PASSWORD' | translate }}?</a
            >
          </span>
        </div>

        <div class="row">
          <div class="input-field col s6 offset-s3">
            <button
              id="login_button"
              type="submit"
              class="btn waves-effect waves-light col s12 cyan"
              [disabled]="authService.isAttemptingToLogIn || !loginForm.valid"
            >
              {{ 'LoginComponent-LOGIN_LABEL' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
