import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill/lib/quill-editor.component';

// Quill uses <strong> tags by default, change to <b>
const quill = require('quill');
const BOLD = quill.import('formats/bold');
BOLD.tagName = 'B';
quill.register(BOLD, true);

@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.css'],
  providers: [],
})
export class RichTextEditorComponent implements OnInit {
  @Input() form: FormControl;

  ngOnInit() {}
}
