import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppSettings } from '../../../../common/config';
import { EmailValidator } from '../../../../validators/emailValidator';
import { BasicDateValidator } from '../../../../validators/basicDateValidator';
import { ServicesProvidedValidator } from '../../../../validators/servicesProvidedValidator';
import { partnerFormatValidators } from '../../../../validators/validators';
import { ReliefRecoveryValidator } from './validators/reliefRecoveryValidator';
import { SolicitingDonationValidator } from './validators/solicitingDonationValidator';
import { TransferredFundValidator } from './validators/transferredFundValidator';
import { VimeoYoutubeUrlValidator } from 'app/validators/vimeoYoutubeUrlValidator';
import { OnlyWhiteSpaceValidator } from 'app/validators/onlyWhiteSpaceValidator';
import { PhoneValidator } from '../../../../validators/phoneValidator';

@Injectable()
export class DisasterSpecificSurveyManager {
  public mainForm: FormGroup;

  constructor(private fb: FormBuilder) {
    const sections = {};

    // Step 3 and 1 have been swapped in the UI!!!
    // Step 3
    sections['leadContact'] = this.getLeadContactSection();

    // Step 2
    sections['hqAddress'] = this.getHQAddressSection();

    // Step 1
    sections['organizationRespondingToDisaster'] = this.getValueSection();
    sections['reliefRecoveryService'] = this.getReliefRecoveryServiceSection();
    sections['activityHistory'] = this.getActivityHistorySection();
    sections['disasterStageOfWork'] = this.getDisasterStagesOfWorkSection();
    sections['stakeholder'] = this.getStakeholderSection();
    sections['orgResponseVideoUrl'] = this.getOrgResponseVideoUrlSection();

    // Step 4
    sections['solicitDonation'] = this.getSolicitDonationSection();
    sections['raisedFund'] = this.getRaisedFundSection();
    sections['spentFund'] = this.getSpentFundSection();
    sections['earnedInterest'] = this.getEarnedInterestFundSection();
    sections['earnedInterestSpent'] = this.getEarnedInterestSpentSection();
    sections['transferredFund'] = this.getTransferredFundSection();

    // Step 5
    sections['serviceList'] = this.getServiceListSection();

    // Step 6
    sections['localStaff'] = this.getLocalStaffSection();
    sections['nonLocalStaff'] = this.getNonLocalStaffSection();
    sections['volunteerStaff'] = this.getVolunteerStaffSection();
    sections['activityReport'] = this.getActivityReportSection();
    sections['engagementList'] = this.getEngagementListSection();
    sections['additionalInfo'] = this.getAdditionalInfoSection();

    // Step 7
    sections['locationList'] = this.getLocationList();

    this.mainForm = fb.group(sections);
  }

  public getServiceProvidedLineSection(): FormGroup {
    const form = this.fb.group({
      directProportion: [undefined, Validators.pattern(AppSettings.ONE_TO_ONE_HUNDRED_WHOLE_NUMBER_REGEX_PATTERN)],
      indirectProportion: [undefined, Validators.pattern(AppSettings.ONE_TO_ONE_HUNDRED_WHOLE_NUMBER_REGEX_PATTERN)],
      serviceProvided: this.fb.group({
        serviceId: [undefined],
        fileURI: [undefined],
        name: [undefined],
      }),
    });

    return form;
  }

  public getFundTransferLineSection(): FormGroup {
    const form = this.fb.group({
      fund: [undefined, [Validators.pattern(AppSettings.US_CURRENCY_REGEX_PATTERN)]],
      receivingOrganization: [undefined, [OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat]],
      currency: 'USD',
    });

    return form;
  }

  private getLeadContactSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group({
        firstName: [undefined, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
        lastName: [undefined, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
        title: [undefined, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
        email: [undefined, EmailValidator.mailFormat],
        phone1: [undefined, PhoneValidator.phoneFormat],
      }),
    };
    return this.fb.group(form);
  }

  private getHQAddressSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group({
        address1: [undefined, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
        address2: [undefined, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
        city: [undefined, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
        country: [undefined],
        state: [undefined, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
        zip: [undefined, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat],
      }),
    };

    return this.fb.group(form);
  }

  private getReliefRecoveryServiceSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group(
        {
          isCurrentlyProvidingService: [undefined],
          isNotProvidingService: [undefined],
          startServiceDate: [
            undefined,
            [BasicDateValidator.dateFormat, BasicDateValidator.pastDate, BasicDateValidator.inRangeYear],
          ],
          stopServiceDate: [
            undefined,
            [BasicDateValidator.dateFormat, BasicDateValidator.pastDate, BasicDateValidator.inRangeYear],
          ],
        },
        { validator: ReliefRecoveryValidator.validateData }
      ),
    };

    return this.fb.group(form);
  }

  private getActivityHistorySection(): FormGroup {
    return this.getValueSection();
  }

  private getDisasterStagesOfWorkSection() {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group({
        mitigationStage: [undefined],
        preparedStage: [undefined],
        recoveryStage: [undefined],
        reliefStage: [undefined],
        responseStage: [undefined],
      }),
    };

    return this.fb.group(form);
  }

  private getStakeholderSection(): FormGroup {
    return this.getValueSection();
  }

  private getSolicitDonationSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group(
        {
          isCurrentlySolicitingDonation: [undefined],
          isNotSolicitingDonation: [undefined],
          startSolicitDonationDate: [
            undefined,
            [BasicDateValidator.dateFormat, BasicDateValidator.pastDate, BasicDateValidator.inRangeYear],
          ],
          stopSolicitDonationDate: [
            undefined,
            [BasicDateValidator.dateFormat, BasicDateValidator.pastDate, BasicDateValidator.inRangeYear],
          ],
        },
        { validator: SolicitingDonationValidator.validateData }
      ),
    };

    return this.fb.group(form);
  }

  private getRaisedFundSection(): FormGroup {
    return this.getFundSection();
  }

  private getSpentFundSection(): FormGroup {
    return this.getFundSection();
  }

  private getEarnedInterestFundSection(): FormGroup {
    return this.getFundSection();
  }

  private getEarnedInterestSpentSection(): FormGroup {
    return this.getValueSection();
  }

  private getTransferredFundSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group(
        {
          hasValue: [undefined],
          fileId: [undefined],
          fundTransferList: this.fb.array([]),
        },
        { validator: TransferredFundValidator.validateData }
      ),
    };

    return this.fb.group(form);
  }

  private getServiceListSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.array([], ServicesProvidedValidator.proportionTotal),
    };

    return this.fb.group(form);
  }

  private getLocalStaffSection(): FormGroup {
    return this.getStaffSection();
  }

  private getNonLocalStaffSection(): FormGroup {
    return this.getStaffSection();
  }

  private getVolunteerStaffSection(): FormGroup {
    return this.getStaffSection();
  }

  private getActivityReportSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group({
        hasValue: [undefined],
        reportFrequencyId: [undefined],
        reportFrequencyOther: [undefined],
        webLink: [undefined, Validators.pattern(AppSettings.URL_REGEX_PATTERN)],
        fileId: [undefined],
      }),
    };

    return this.fb.group(form);
  }

  private getEngagementListSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.array([this.fb.control(null, partnerFormatValidators)]),
    };

    return this.fb.group(form);
  }

  private getOrgResponseVideoUrlSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: [undefined, VimeoYoutubeUrlValidator.vimeoYoutubeUrlFormat],
    };
    return this.fb.group(form);
  }

  private getAdditionalInfoSection(): FormGroup {
    return this.getValueSection();
  }

  // User to get sections
  // raisedFund,
  // spentFund
  // earnedInterest
  private getFundSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group({
        hasValue: [undefined],
        fund: [undefined, Validators.pattern(AppSettings.US_CURRENCY_REGEX_PATTERN)],
        currency: 'USD',
      }),
    };

    return this.fb.group(form);
  }

  // Used with sections where only value property is available.
  private getValueSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: [undefined],
    };

    return this.fb.group(form);
  }

  // Used with localStaff, nonLocalStaff and volunteerStaff sections
  private getStaffSection(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.group({
        mgmtStaffCount: [undefined, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)],
        professionalStaffCount: [undefined, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)],
        supportStaffCount: [undefined, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)],
      }),
    };

    return this.fb.group(form);
  }

  private getLocationList(): FormGroup {
    const form = {
      lastUpdatedDate: [undefined],
      lastUpdatedUser: [undefined],
      updatedBySRAdmin: [undefined],
      markAsUpdated: [undefined],

      value: this.fb.array([]),
    };

    return this.fb.group(form);
  }
}
