import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceIcons',
  pure: true,
})
export class ServiceIconsPipe implements PipeTransform {
  transform(serviceName: string): string {
    const assetMap = {
      'Advocacy ': 'advocacy',
      'Disaster Risk Reduction / Preparedness': 'disaster-risk-reduction-preparedness',
      'Emergency Telecommunications': 'emergency-telecommunications',
      'Environmental restoration': 'environmental-restoration',
      'Food Item Distribution': 'food-item-distribution',
      'Grant-making / Re-granting': 'grant-making-re-granting',
      Health: 'health',
      Infrastructure: 'infrastructure',
      Livelihoods: 'livelihoods',
      'Mental Health': 'mental-health',
      'Non-Food Item Distribution': 'non-food-item-distribution',
      'Physical Protection': 'physical-protection',
      'Post-disaster schooling': 'post-disaster-schooling',
      Shelter: 'shelter',
      'Vulnerable Populations': 'vulnerable-populations',
      'Water, Sanitation and Hygiene (WASH)': 'water-sanitation-hygiene',
    };

    return assetMap[serviceName] ? `assets/images/services/${assetMap[serviceName]}.png` : null;
  }
}
