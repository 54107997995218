<div
  class="modal modal-fixed-footer"
  materialize="modal"
  [materializeParams]="[{ dismissible: true }]"
  [materializeActions]="modalActions"
>
  <div class="modal-content row">
    <div class="modal-title col s12">
      {{ 'ReportModalComponent-TITLE' | translate }}
    </div>

    <div class="divider col s12"></div>

    <!-- reporting a survey in its entirety -->
    <p class="sr-justify col s12" *ngIf="!reportInputModel?.surveyQuestionKey">
      {{
        'ReportModalComponent-REPORT_SURVEY'
          | translate
            : {
                orgName: reportInputModel?.orgName,
                surveyType: (reportInputModel?.surveyTypeTranslationKey | translate)
              }
      }}
    </p>

    <!-- reporting a specific question's response in a survey -->
    <p class="sr-justify col s12" *ngIf="reportInputModel?.surveyQuestionKey">
      {{
        'ReportModalComponent-REPORT_SURVEY_QUESTION'
          | translate
            : {
                orgName: reportInputModel?.orgName,
                surveyType: (reportInputModel?.surveyTypeTranslationKey | translate),
                question: (reportInputModel?.surveyQuestionTranslationKey | translate)
              }
      }}
    </p>

    <p class="sr-justify col s12">
      {{ 'ReportModalComponent-REPORT_INSTRUCTION_TEXT' | translate }}
    </p>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            !reportModalForm.controls['redFlagReportRespondentName'] &&
            reportModalForm.controls['redFlagReportRespondentName'].touched
        }"
      >
        <i class="material-icons prefix">name</i>
        <input
          id="redFlagReportRespondentName"
          type="text"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="reportModalForm.controls['redFlagReportRespondentName']"
        />
        <label for="redFlagReportRespondentName">
          {{ 'ReportModalForm-NAME_LABEL' | translate }}
        </label>
        <div
          class="sr-error-area"
          *ngIf="
            !reportModalForm.controls['redFlagReportRespondentName'].valid &&
            reportModalForm.controls['redFlagReportRespondentName'].touched
          "
        >
          <div
            class="sr-padding-required-text"
            *ngIf="reportModalForm.controls['redFlagReportRespondentName'].value.length > 0"
          >
            <div *ngIf="reportModalForm.controls['redFlagReportRespondentName'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
            </div>
            <div
              *ngIf="
                reportModalForm.controls['redFlagReportRespondentName'].hasError('leadingTrailingWhiteSpaceFormat')
              "
            >
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            !reportModalForm.controls['redFlagReportRespondentEmail'] &&
            (reportModalForm.controls['redFlagReportRespondentEmail'].touched ||
              reportModalForm.controls['redFlagReportRespondentEmail'].value.length)
        }"
      >
        <i class="material-icons prefix">email</i>
        <input
          id="redFlagReportRespondentEmail"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          [formControl]="reportModalForm.controls['redFlagReportRespondentEmail']"
        />
        <label for="redFlagReportRespondentEmail">
          {{ 'ReportModalForm-EMAIL' | translate }}
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="
            !reportModalForm.controls['redFlagReportRespondentEmail'].valid &&
            (reportModalForm.controls['redFlagReportRespondentEmail'].touched ||
              reportModalForm.controls['redFlagReportRespondentEmail'].value.length)
          "
        >
          <div *ngIf="reportModalForm.controls['redFlagReportRespondentEmail'].errors.mailFormat">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}.
          </div>
          <div *ngIf="reportModalForm.controls['redFlagReportRespondentEmail'].hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 64 } }}
          </div>
          <div
            *ngIf="reportModalForm.controls['redFlagReportRespondentEmail'].hasError('leadingTrailingWhiteSpaceFormat')"
          >
            {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            !reportModalForm.controls['redFlagReportRespondentPhone'] &&
            reportModalForm.controls['redFlagReportRespondentPhone'].touched
        }"
      >
        <i class="material-icons prefix">call</i>
        <input
          id="redFlagReportRespondentPhone"
          type="tel"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="reportModalForm.controls['redFlagReportRespondentPhone']"
        />
        <label for="redFlagReportRespondentPhone">
          {{ 'ReportModalForm-PHONE_NUMBER' | translate }}
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="
            !reportModalForm.controls['redFlagReportRespondentPhone'].valid &&
            reportModalForm.controls['redFlagReportRespondentPhone'].touched
          "
        >
          <div *ngIf="reportModalForm.controls['redFlagReportRespondentPhone'].hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
          </div>
          <div
            *ngIf="reportModalForm.controls['redFlagReportRespondentPhone'].hasError('leadingTrailingWhiteSpaceFormat')"
          >
            {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row margin">
      <div class="col s12">
        <p for="relationship" class="left-align">
          {{ 'ReportModalForm-RELATIONSHIP' | translate }}
        </p>
      </div>
    </div>
    <div class="row margin">
      <select
        [formControl]="reportModalForm.controls['redFlagReportRespondentRole']"
        id="redFlagReportRespondentRole"
        class="my_select browser-default"
      >
        <option value="Employee of the organization">
          {{ 'ReportModalForm-EMP_VOLU_ORG' | translate }}
        </option>
        <option value="Volunteer of the organization">
          {{ 'ReportModalForm-VOL_VOLU_ORG' | translate }}
        </option>
        <option value="Employee of another organization">
          {{ 'ReportModalForm-EMP_VOLU_ORG_ANOTHER_ORG' | translate }}
        </option>
        <option value="Volunteer of another organization">
          {{ 'ReportModalForm-VOL_VOLU_ORG_ANOTHER_ORG' | translate }}
        </option>
        <option value="Dissaster survival">
          {{ 'ReportModalForm-DISASTER_SURVIVOR' | translate }}
        </option>
        <option value="Community member in location of organization's activities">
          {{ 'ReportModalForm-COMMUNITY_MEM_IN_LOC_OF_ORG_ACTIVITY' | translate }}
        </option>
        <option value="Media representative">
          {{ 'ReportModalForm-MEDIA_REP' | translate }}
        </option>
        <option value="Other">
          {{ 'ReportModalForm-OTHER' | translate }}
        </option>
      </select>
    </div>
    <div
      class="input-field col s12"
      [ngClass]="{
        'sr-has-error': !reportModalForm.controls['reason'].valid && reportModalForm.controls['reason'].touched
      }"
    >
      <i class="material-icons prefix hide-on-small-only">comment</i>

      <textarea
        id="reason"
        class="materialize-textarea"
        materialize="characterCounter"
        length="256"
        [formControl]="reportModalForm.controls['reason']"
      >
      </textarea>

      <label for="reason" class="left-align">
        {{ 'ReportModalComponent-REASON_FOR_REPORTING' | translate }}
        <span class="sr-required-mark">*</span>
      </label>
      <div
        class="sr-error-area modal-error"
        *ngIf="!reportModalForm.controls['reason'].valid && reportModalForm.controls['reason'].touched"
      >
        <div *ngIf="reportModalForm.controls['reason'].errors.required">
          {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
        </div>
      </div>
    </div>
    <div
      class="input-field col s12"
      [ngClass]="{
        'sr-has-error':
          !reportModalForm.controls['redFlagReportRespondentComment'].valid &&
          reportModalForm.controls['redFlagReportRespondentComment'].touched
      }"
    >
      <i class="material-icons prefix hide-on-small-only">comment</i>

      <textarea
        id="redFlagReportRespondentComment"
        class="materialize-textarea"
        materialize="characterCounter"
        length="256"
        [formControl]="reportModalForm.controls['redFlagReportRespondentComment']"
      >
      </textarea>

      <label for="redFlagReportRespondentComment" class="left-align">
        {{ 'ReportModalComponent-COMMENTS' | translate }}
      </label>

      <div
        class="sr-error-area"
        *ngIf="
          !reportModalForm.controls['redFlagReportRespondentComment'].valid &&
          reportModalForm.controls['redFlagReportRespondentComment'].touched
        "
      >
        <div
          class="sr-padding-required-text"
          *ngIf="reportModalForm.controls['redFlagReportRespondentComment'].value.length > 0"
        >
          <div
            *ngIf="
              reportModalForm.controls['redFlagReportRespondentComment'].hasError('leadingTrailingWhiteSpaceFormat')
            "
          >
            {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
          </div>
        </div>
      </div>
    </div>

    <ng-template [ngIf]="reportInputModel">
      <re-captcha
        class="recaptcha col s12"
        (resolved)="onValidCaptchaResponse($event)"
        siteKey="{{ AppSettings.RECAPTCHA_SITE_KEY }}"
      >
      </re-captcha>
    </ng-template>

    <div class="modal-footer col s12">
      <div class="buttons right">
        <button
          class="modal-submit btn waves-effect waves-light cyan"
          [disabled]="isSubmitting || !reportModalForm.valid"
          (click)="onSubmit()"
        >
          {{ 'ReportModalComponent-SUBMIT' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
