import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, flatMap, switchMap, zip } from 'rxjs/operators';
import { Component, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ServicesProvidedService } from '../../dashboard/servicesComponets/service/service.service';
import { DisasterAdminService } from '../../dashboard/servicesComponets/disaster/disaster.service';
import { GeneralOrganizationBackgroundSurveyService } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.service';
import { DisasterSpecificSurveyService } from '../../dashboard/servicesComponets/surveys/disasterSpecificSurvey/disaster-specific-survey.service';
import { LocationSpecificSurveyService } from '../../dashboard/servicesComponets/surveys/locationSpecificSurvey/location-specific-survey.service';
import { CurrencyService } from '../../sharedServices/currencyService/currency.service';
import { CurrencyPickerService } from '../../header/currencyPicker/currency-picker.service';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { DisasterAboutService } from './disaster-about.service';
import { ResponseProfileComponent } from '../responseProfile/response-profile.component';
import { validateResponseField } from '../survey-response-helpers';
import {
  Collection,
  ConvertedMoney,
  Dictionary,
  Disaster,
  DisasterSpecificSurveyInput,
  LocationSpecificSurveyInput,
  Money,
  MoneyConversionOutput,
  OrgDisasterScore,
  OrgLocationScore,
  Service,
} from '../../models';
import { AppSettings } from '../../common/config';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { MaterializeAction } from 'angular2-materialize';
import { GeneralOrganizationBackgroundSurveyInput } from 'app/dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.model';

@Component({
  selector: 'disaster-about',
  templateUrl: './disaster-about.component.html',
  styleUrls: ['./disaster-about.component.css', '../common-search.css'],
})
export class DisasterAboutComponent extends ResponseProfileComponent implements OnInit, OnChanges {
  public disaster: Disaster;
  public dssArray: DisasterSpecificSurveyInput[];
  public lssArray: LocationSpecificSurveyInput[];
  public disasterSummary: any; // used to store consolidated info from dss
  public respondingOrgs: any[];
  public locationOrgs: any[];
  public csloCount: Number[];
  public ingoCount: Number[];
  public widgetText: string;

  public AppSettings = AppSettings;

  private allAvailableServices: Service[];

  public disasterScoreActions: EventEmitter<OrgDisasterScore> = new EventEmitter<OrgDisasterScore>();
  public organizationDisasterScores: OrgDisasterScore[];

  public locationScoreActions: EventEmitter<OrgLocationScore> = new EventEmitter<OrgLocationScore>();
  public organizationLocationScores: OrgLocationScore[];

  public itemsPerPageArg: number = 10;
  public selectedPage: number = 0;

  subscriptions: Dictionary<Subscription> = {
    route: null,
    currency: null,
  };

  currencySubFirstRun: boolean = true;

  money: Collection<ConvertedMoney> = {
    singles: {},
    arrays: {
      respondingBudget: [],
      respondingRaised: [],
      respondingSpent: [],
      locationBudget: [],
    },
  };

  donateModalActions = new EventEmitter<string | MaterializeAction>();
  tippingModalActions = new EventEmitter<string | MaterializeAction>();
  isSubmitting: boolean = false;

  loading: boolean;
  oName: string;
  oID: string;
  oDonateUrl: string;

  constructor(
    private servicesProvidedService: ServicesProvidedService,
    private disasterAdminService: DisasterAdminService,
    private gobService: GeneralOrganizationBackgroundSurveyService,
    private dssSurveyService: DisasterSpecificSurveyService,
    private lssSurveyService: LocationSpecificSurveyService,
    private route: ActivatedRoute,
    private globalLoader: SlimLoadingBarService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private disasterAboutService: DisasterAboutService,
    private currencyService: CurrencyService,
    private currencyPickerService: CurrencyPickerService
  ) {
    super(currencyService, currencyPickerService, notificationsService, translateService);
    this.addSubscription('route', this.getRouteSubscription.bind(this));
  }

  ngOnInit() {
    this.loading = true;
    this.globalLoader.start();
  }

  ngOnChanges() {}

  onItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.itemsPerPageArg = +updatedVal;
    this.selectedPage = 0;
  }

  private createDisasterSurveysObservable(disasterID, displayDss): Observable<any> {
    if (displayDss) {
      return this.dssSurveyService.getSurveysByDisaster(disasterID);
    }
    return of([]);
  }

  private createLocationSurveysObservable(disasterID, displayDss): Observable<any> {
    if (!displayDss) {
      return this.lssSurveyService.getSurveysByRelatedDisaster(disasterID);
    }
    return of([]);
  }

  private getRouteSubscription(): Subscription {
    const routeObservable = this.route.params.pipe(
      switchMap((params: Params) => {
        const servicesProvidedObservable = this.servicesProvidedService.getServices();
        const csloCountObservable = this.disasterAboutService.getOrganizationCount(
          +params['id'],
          AppSettings.ORGANIZATION_TYPES['CSLO']
        );
        const ingoCountObservable = this.disasterAboutService.getOrganizationCount(
          +params['id'],
          AppSettings.ORGANIZATION_TYPES['INGO']
        );

        return this.disasterAdminService
          .getDisaster(+params['id'])
          .pipe(
            flatMap((disaster) => {
              const disasterSurveysObservable = this.createDisasterSurveysObservable(
                disaster.disasterId,
                disaster.displayDss
              );
              const locationSurveysObservable = this.createLocationSurveysObservable(
                disaster.disasterId,
                disaster.displayDss
              );
              return forkJoin(
                disasterSurveysObservable,
                locationSurveysObservable,
                (disasterSurveys, locationSurveys) => ({ disaster, disasterSurveys, locationSurveys })
              );
            })
          )
          .pipe(
            zip(
              servicesProvidedObservable,
              csloCountObservable,
              ingoCountObservable,
              (disasterAndSurveys, servicesProvided, csloCount, ingoCount: Service[]) => ({
                disasterAndSurveys,
                servicesProvided,
                csloCount,
                ingoCount,
              })
            )
          );
      })
    );

    return routeObservable.subscribe((success) => {
      this.onDisasterLoaded(success);
    });
  }

  private getCurrencySubscription(): Subscription {
    return this.setupCurrencyContext().subscribe(([conversions, formats]) => {
      this.setCurrencies(conversions.currency, formats);
      this.setMoney(conversions);
    });
  }

  private onDisasterLoaded(data: any): void {
    // Slim loading bar
    // this.globalLoader.complete();
    this.loading = false;
    this.disaster = data.disasterAndSurveys.disaster;
    this.dssArray = data.disasterAndSurveys.disasterSurveys
      .map((dss) => dss.scoreDto.surveyDto)
      .filter((survey) => survey && survey.surveySubmissionId !== null);
    this.lssArray = data.disasterAndSurveys.locationSurveys
      .map((lss) => lss.scoreDto.surveyDto)
      .filter((survey) => survey && survey.surveySubmissionId !== null);
    this.allAvailableServices = data.servicesProvided;
    this.csloCount = data.csloCount.responseData.count;
    this.ingoCount = data.ingoCount.responseData.count;
    if (this.disaster.displayDss) {
      this.organizationDisasterScores = data.disasterAndSurveys.disasterSurveys
        .filter((dss) => dss.scoreDto && dss.scoreDto.surveyDto)
        .map((dss) => {
          return <OrgDisasterScore>{
            organizationId: dss.scoreDto.surveyDto.organizationId,
            organizationName: dss.scoreDto.surveyDto.organizationName,
            disasterName: dss.scoreDto.surveyDto.disaster ? dss.scoreDto.surveyDto.disaster.name : null,
            updateScore: dss.scoreDto.updateScore,
            adminEditScore: dss.scoreDto.adminEditScore,
            directServiceScore: dss.scoreDto.directServiceScore,
            refusedQuestionScore: dss.scoreDto.refusedQuestionScore,
            localStaffScore: dss.scoreDto.localStaffScore,
            localStaffCountScore: dss.scoreDto.localStaffCountScore,
          };
        });
    } else {
      this.organizationLocationScores = data.disasterAndSurveys.locationSurveys
        .filter((lss) => lss.scoreDto && lss.scoreDto.surveyDto)
        .map((lss) => {
          return <OrgLocationScore>{
            organizationName: lss.scoreDto.surveyDto.organizationName,
            organizationId: lss.scoreDto.surveyDto.organizationId,
            countryName: lss.scoreDto.surveyDto.country ? lss.scoreDto.surveyDto.country.name : null,
            countryId: lss.scoreDto.surveyDto.country ? lss.scoreDto.surveyDto.country.countryId : null,
            updateScore: lss.scoreDto.updateScore,
            adminEditScore: lss.scoreDto.adminEditScore,
            directServiceScore: lss.scoreDto.directServiceScore,
            refusedQuestionScore: lss.scoreDto.refusedQuestionScore,
            localStaffScore: lss.scoreDto.localStaffScore,
          };
        });
    }

    // tslint:disable-next-line:no-increment-decrement
    for (let i = 0; i < this.disaster.services.length; i++) {
      const foundService = this.allAvailableServices.find((r) => r.serviceId === this.disaster.services[i].serviceId);

      if (foundService) {
        this.disaster.services[i] = foundService;
      }
    }
    // initialize
    this.disasterSummary = {
      moneyRaised: { amount: 0, currency: AppSettings.DEFAULT_CURRENCY },
      moneySpent: { amount: 0, currency: AppSettings.DEFAULT_CURRENCY },
      staffCount: 0,
      percentageSpent: null,
    };

    if (this.disaster.displayDss) {
      this.respondingOrgs = [];

      const respondingDssArray: DisasterSpecificSurveyInput[] = this.dssArray.filter(
        (dss) => dss.organizationRespondingToDisaster
      );

      const respondingGobObservableArray: Observable<GeneralOrganizationBackgroundSurveyInput>[] = respondingDssArray.map(
        (dss) => {
          return this.gobService.getMostRecentSurvey(dss.organizationId).pipe(
            catchError(() => {
              console.log('Error loading the latest GOB survey for ' + dss.organizationId);
              return of(null);
            })
          );
        }
      );

      forkJoin(respondingGobObservableArray).subscribe((gobArray: GeneralOrganizationBackgroundSurveyInput[]) => {
        respondingDssArray.forEach((dss, i) => {
          const org = {
            id: dss.organizationId,
            name: dss.organizationName,
            staffCount: this.getStaffCount(dss),
            serviceList: this.getServiceList(dss).slice(0, 3),
            isSolicitingDonations: dss.solicitDonation && dss.solicitDonation.value.isCurrentlySolicitingDonation,
            hqLocation: dss.organizationDto.country,
            donateButtonDisabled: dss.donateButtonDisabled,
            donateUrl: '',
          };
          const gob: GeneralOrganizationBackgroundSurveyInput = gobArray.find(
            (gob) => gob.organizationId === dss.organizationId
          );
          if (gob.donateUrl !== null) {
            org.donateUrl = gob.donateUrl && gob.donateUrl.value;
          }

          const respondingFunds: Dictionary<Money> = {
            respondingBudget: validateResponseField<Money>(gob.overallAnnualBudget),
            respondingRaised: validateResponseField<Money>(dss.raisedFund),
            respondingSpent: validateResponseField<Money>(dss.spentFund),
          };

          for (const [category, value] of Object.entries(respondingFunds)) {
            this.money.arrays[category][i] = new ConvertedMoney(value);
          }

          this.disasterSummary.staffCount += org.staffCount;

          this.respondingOrgs[i] = org;
        });
        this.addSubscription('currency', this.getCurrencySubscription.bind(this));
      });
    } else {
      const locationGobObservableArray: Observable<GeneralOrganizationBackgroundSurveyInput>[] = this.lssArray.map(
        (lss) => {
          return this.gobService.getMostRecentSurvey(lss.organizationId).pipe(
            catchError(() => {
              console.log('Error loading the latest GOB survey for ' + lss.organizationId);
              return of(null);
            })
          );
        }
      );

      this.locationOrgs = [];

      forkJoin(locationGobObservableArray).subscribe((gobArray: GeneralOrganizationBackgroundSurveyInput[]) => {
        this.lssArray.forEach((lss, i) => {
          const org = {
            id: lss.organizationId,
            name: lss.organizationName,
            location: lss.country.name,
            locationId: lss.country.countryId,
            staffCount: this.getLocationOrgStaffCount(lss),
            serviceList: this.getLocationOrgServiceList(lss).slice(0, 3),
            hqLocation: lss.organizationDto.country,
            donateButtonDisabled: lss.donateButtonDisabled,
            donateUrl: '',
          };

          const gob: GeneralOrganizationBackgroundSurveyInput = gobArray.find(
            (gob) => gob.organizationId === lss.organizationId
          );

          if (gob.donateUrl !== null) {
            org.donateUrl = gob.donateUrl && gob.donateUrl.value;
          }

          this.locationOrgs[i] = org;
          this.money.arrays.locationBudget[i] = new ConvertedMoney(validateResponseField<Money>(lss.overallBudget));
        });
        this.addSubscription('currency', this.getCurrencySubscription.bind(this));
      });
    }
  }

  openDonateModal(oName: string, oID: string, oDonateUrl: string) {
    this.oName = oName;
    this.oID = oID;
    this.oDonateUrl = oDonateUrl;

    this.donateModalActions.emit({ action: 'modal', params: ['open'] });
  }

  private setMoney(conversions: MoneyConversionOutput): void {
    const money: Collection<ConvertedMoney> = conversions.money;
    if (money.arrays) {
      if (money.arrays.respondingBudget && money.arrays.respondingBudget.length) {
        money.arrays.respondingBudget.forEach((budget, i) => {
          this.respondingOrgs[i]['budget'] = budget.converted;
        });
      }
      if (money.arrays.respondingRaised && money.arrays.respondingRaised.length) {
        money.arrays.respondingRaised.forEach((raised, i) => {
          this.respondingOrgs[i]['raised'] = raised.converted;
        });
      }
      if (money.arrays.respondingSpent && money.arrays.respondingSpent.length) {
        money.arrays.respondingSpent.forEach((spent, i) => {
          this.respondingOrgs[i]['spent'] = spent.converted;
        });
      }
      if (money.arrays.locationBudget && money.arrays.locationBudget.length) {
        money.arrays.locationBudget.forEach((budget, i) => {
          this.locationOrgs[i]['budget'] = budget.converted;
        });
      }
    }
    this.money = money;
    if (!conversions.hasError) {
      this.setFundraisingStats();
    }
  }

  private setFundraisingStats(): void {
    // populate respondingOrgs displayed properties with converted Money amounts
    const sumConvertedMoney = function (arr: ConvertedMoney[]): number {
      if (arr.length) {
        return arr
          .map((money: ConvertedMoney) => {
            if (money.converted && money.converted.amount !== null && money.converted.amount !== undefined) {
              return money.converted.amount;
            }
            return 0;
          })
          .reduce((sum, value) => sum + value);
      }
      return null;
    };
    const totalRaised: Money = {
      amount: sumConvertedMoney(this.money.arrays.respondingRaised),
      currency: this.currency.code,
    };
    const totalSpent: Money = {
      amount: sumConvertedMoney(this.money.arrays.respondingSpent),
      currency: this.currency.code,
    };
    this.disasterSummary.moneyRaised = totalRaised;
    this.disasterSummary.moneySpent = totalSpent;
    if (this.disasterSummary.percentageSpent === null || this.disasterSummary.percentageSpent === undefined) {
      this.disasterSummary.percentageSpent =
        totalRaised && totalSpent ? (100 * totalSpent.amount) / totalRaised.amount : 0;
    }
  }

  private getStaffCount(dssSurvey: DisasterSpecificSurveyInput): number {
    let staffCount = 0;
    if (!dssSurvey) {
      return staffCount;
    }

    for (const staffType of ['localStaff', 'nonLocalStaff']) {
      if (dssSurvey[staffType] && dssSurvey[staffType].value) {
        staffCount =
          staffCount +
          dssSurvey[staffType].value.mgmtStaffCount +
          dssSurvey[staffType].value.professionalStaffCount +
          dssSurvey[staffType].value.supportStaffCount;
      }
    }

    return staffCount;
  }

  private getLocationOrgStaffCount(lssSurvey: LocationSpecificSurveyInput): number {
    let staffCount = 0;
    if (!lssSurvey) {
      return staffCount;
    }

    for (const staffType of ['localStaff', 'nonLocalStaff']) {
      if (lssSurvey[staffType]) {
        staffCount =
          staffCount +
          lssSurvey[staffType].value.mgmtStaffCount +
          lssSurvey[staffType].value.professionalStaffCount +
          lssSurvey[staffType].value.supportStaffCount;
      }
    }

    return staffCount;
  }

  private getServiceList(dssSurvey: DisasterSpecificSurveyInput): string[] {
    const serviceList = [];

    if (dssSurvey && dssSurvey.serviceList) {
      for (const service of dssSurvey.serviceList.value) {
        if (service.directProportion || service.indirectProportion) {
          serviceList.push(service.serviceProvided.name);
        }
      }
    }

    return serviceList;
  }

  private getLocationOrgServiceList(lssSurvey: LocationSpecificSurveyInput): string[] {
    const serviceList = [];

    if (!lssSurvey || !lssSurvey.serviceList) {
      return serviceList;
    }

    for (const service of lssSurvey.serviceList.value) {
      if (service.directProportion || service.indirectProportion) {
        serviceList.push(service.serviceProvided.name);
      }
    }

    return serviceList;
  }

  openDssScoreModal(orgId: number) {
    this.disasterScoreActions.emit(this.organizationDisasterScores.find((score) => score.organizationId === orgId));
  }

  openLssScoreModal(orgId: number, countryId: number) {
    this.locationScoreActions.emit(
      this.organizationLocationScores.find((score) => score.organizationId === orgId && score.countryId === countryId)
    );
  }

  displayWidget() {
    if (this.widgetText) {
      this.widgetText = '';
    } else {
      this.widgetText = this.disasterAboutService.getWidgetText(this.disaster.disasterId);
    }
  }
}
