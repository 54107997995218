import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SurveyErrorCode, SurveyModalActions } from '../../../../../models';
import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';
import { DSSStepComponent } from '../dss-step/dss-step.component';

@Component({
  selector: 'dss-error-list',
  templateUrl: './dss-error-list.component.html',
  styleUrls: ['./dss-error-list.component.css', '../dss-steps-common.css', '../../survey-table-common.css'],
})
export class DSSErrorListComponent extends DSSStepComponent implements OnInit {
  @Input() modalActions: SurveyModalActions;
  @Input() totalSteps: number;

  @Input() disasterName: string;
  @Input() organizationName: string;

  @Input() errors: SurveyErrorCode[];

  @Output() errorModal: EventEmitter<string> = new EventEmitter();
  @Output() selectError: EventEmitter<SurveyErrorCode> = new EventEmitter();

  itemsPerPageArg: number = 10;
  selectedPage: number = 0;
  errSelectedPage: number = 0;

  constructor(private surveyManager: DisasterSpecificSurveyManager) {
    super(surveyManager);
  }

  ngOnInit() {}

  onSelectError(error: SurveyErrorCode): void {
    this.selectError.emit(error);
  }
}
