<agm-map
  [latitude]="centerLatitude"
  [longitude]="centerLongitude"
  (mapClick)="mapClicked($event)"
  [usePanning]="true"
  [zoom]="6"
>
  <!--Need i=index in ngFor to pass it in the deleteMarker function below.-->
  <ng-container *ngIf="source != null">
    <agm-marker
      *ngFor="let marker of [].concat(source); let i = index"
      [latitude]="marker.geoLatitude"
      [longitude]="marker.geoLongitude"
      [label]="marker.label"
      [openInfoWindow]="showInfoWindow"
      (markerClick)="onMarkerClicked(marker)"
      [zIndex]="i + 1"
    >
      <ng-container *ngIf="marker.geoLatitude && marker.geoLongitude">
        <agm-info-window [disableAutoPan]="true" [isOpen]="showInfoWindow">
          <label class="sr-bold">{{ 'LocationSpecificSurveyComponent-STEP_7_RADIUS' | translate }}</label>
          <select
            #radius
            class="browser-default"
            (change)="onRadiusChanged(marker.radius, marker.geoLatitude, marker.geoLongitude)"
            [(ngModel)]="marker.radius"
          >
            <option *ngFor="let radius of radii" [ngValue]="radius.value">
              {{ radius.display }}
            </option>
          </select>
          <a class="waves-effect waves-light btn" (click)="onMarkerDelete(marker)">
            {{ 'LocationSpecificSurveyComponent-STEP_7_REMOVE_PIN' | translate }}
          </a>
        </agm-info-window>
      </ng-container>
      <agm-circle
        *ngIf="marker.geoLatitude && marker.geoLongitude"
        [latitude]="marker.geoLatitude"
        [longitude]="marker.geoLongitude"
        [radius]="marker.radius ? marker.radius * 1000 : null"
        [fillColor]="marker.color ? marker.color : 'red'"
        [visible]="showAll ? showAll : marker.visible"
        (circleClick)="onMarkerClicked(marker)"
      >
      </agm-circle>
    </agm-marker>
  </ng-container>
</agm-map>
