import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FrequentlyAskedQuestionsComponent } from './faq.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterializeModule } from 'angular2-materialize';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: FrequentlyAskedQuestionsComponent }])],
  exports: [RouterModule],
})
export class FrequentlyAskedQuestionsRoutingModule {}

@NgModule({
  imports: [CommonModule, FrequentlyAskedQuestionsRoutingModule, TranslateModule, MaterializeModule],
  declarations: [FrequentlyAskedQuestionsComponent],
  providers: [],
})
export class FrequentlyAskedQuestionsFeatureModule {}
