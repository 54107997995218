import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../validators/emailValidator';
import { AuthenticationService } from '../login/login.service';
import { handleErrors } from '../common/error';
import { TranslateService } from '@ngx-translate/core';
import { UserType } from '../models/User';
import { NotificationsService } from 'angular2-notifications';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { AppSettings } from '../common/config';
import {
  ltwsFormatWithLen,
  nameValidators,
  orgFormatValidators,
  passwordMismatchValidator,
} from '../validators/validators';
import { CountryService } from '../dashboard/servicesComponets/country/country.service';
import { Country } from '../models/Country';
import { tap } from 'rxjs/operators';
import { PhoneValidator } from '../validators/phoneValidator';
import { emptySpaceValidator } from '../validators/emptySpaceValidator';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent {
  isSubmitting: boolean = false;
  registrationForm: FormGroup;
  error: string;
  public AppSettings = AppSettings;
  countries: Country[];
  loading: boolean;

  constructor(
    public router: Router,
    public authService: AuthenticationService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private loaderService: SlimLoadingBarService,
    private countryService: CountryService
  ) {
    const fb: FormBuilder = new FormBuilder();
    this.registrationForm = fb.group(
      {
        email: ['', [Validators.required, EmailValidator.mailFormat, ...ltwsFormatWithLen(64), emptySpaceValidator()]],
        confirmationEmail: ['', [Validators.required, EmailValidator.emailMatchValidator]],
        password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(45), emptySpaceValidator()]],
        passwordConfirmation: ['', Validators.required],
        firstName: ['', [...nameValidators, emptySpaceValidator()]],
        lastName: ['', [...nameValidators, emptySpaceValidator()]],
        organizationName: ['', [...orgFormatValidators, emptySpaceValidator()]],
        website: ['', [Validators.pattern(AppSettings.URL_REGEX_PATTERN), ...ltwsFormatWithLen(256)]],
        summary: ['', [Validators.required, ...ltwsFormatWithLen(1200), emptySpaceValidator()]],
        title: ['', [Validators.required, ...ltwsFormatWithLen(32), emptySpaceValidator()]],
        govId: ['', ltwsFormatWithLen(64)],
        countryId: ['', Validators.required],
        organizationAdmin: ['', Validators.required],
        phoneNumber: [
          '',
          [Validators.required, ...ltwsFormatWithLen(32), emptySpaceValidator(), PhoneValidator.phoneFormat],
        ],
        captcha: [null, Validators.required],
      },
      { validators: Validators.compose([passwordMismatchValidator, EmailValidator.emailMatchValidator]) }
    );
    this.countryService.getCountries().subscribe((countries) => (this.countries = countries));
  }

  onValidCaptchaResponse(captcha: string) {
    this.registrationForm.controls['captcha'].setValue(captcha);
  }

  onCaptchaExpire($event: any) {
    this.registrationForm.controls['captcha'].reset();
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this.loaderService.start();
    this.loading = true;
    const submitData = {
      ...this.registrationForm.value,
      userType: UserType.ORG_GENERAL,
      passwordConfirmation: undefined,
      confirmationEmail: undefined,
    };

    const { captcha } = this.registrationForm.value;
    const completeRegistration = () => {
      this.isSubmitting = false;
      this.loaderService.complete();
      this.loading = false;
    };

    this.authService
      .registerOrganization(submitData, captcha)
      .pipe(
        tap({
          next: completeRegistration,
          error: completeRegistration,
        })
      )
      .subscribe(
        (response) => this.router.navigate(['/registration-success']),
        (error) =>
          handleErrors(
            error,
            this.translateService,
            this.notificationsService,
            'RegistrationComponent-CREATE_ORGANIZATION_ACCOUNT_HEADING'
          )
      );
  }
}
