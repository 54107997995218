<ng-template [ngIf]="myService">
  <app-loader [loading]="loading"></app-loader>
  <div class="row">
    <div class="col m12 s12">
      <div class="card">
        <div class="card-content">
          <div class="card-title sr-bold">
            {{ 'UNIVERSAL-VIEW_TEXT' | translate }}
            <span class="sr-italic">{{ myService.name }}</span>
            {{ 'UNIVERSAL-INFORMATION_TEXT' | translate }}
          </div>
          <div class="divider"></div>
          <br />
          <div class="collection">
            <div class="row collection-item collection-padding">
              <div class="col m4 s12 img-radius-parent">
                <img
                  *ngIf="myService.file"
                  src="{{ myService.file.uri }}"
                  class="col s12 m12 responsive-img img-radius"
                />
                <img
                  *ngIf="!myService.file"
                  src="{{ myService.name | serviceIcons }}"
                  class="col s12 m12 responsive-img img-radius"
                />
              </div>
              <div class="col m8 s12">
                <div class="summary-field input-field col s12 m12">
                  <div class="col s4 m4 sr-bold">{{ 'SERVICE_COMMON-SUMMARY_LABEL' | translate }}:</div>
                  <div class="col s12 m12 sr-justify input-field">{{ myService.summary }}</div>
                </div>
              </div>
              <div class="col s12">
                <div class="input-field right">
                  <a
                    class="waves-effect waves-light btn cyan"
                    [routerLink]="['/dashboard/services/edit', myService.serviceId]"
                  >
                    {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
                  </a>
                  <a class="waves-effect waves-light btn cyan" [routerLink]="['..']">
                    <i class="material-icons left">skip_previous</i>
                    {{ 'UNIVERSAL-NAVIGATION_BACK' | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
