import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HtmlOption } from '../../models';
import { Language, LANGUAGE_CONFIG } from './language.model';

@Injectable({
  providedIn: 'root',
})
export class LanguagePickerService {
  constructor(private translateService: TranslateService) {}

  public setPreferredLanguage(code: string) {
    localStorage.setItem('language', JSON.stringify(code));
  }

  public getCachedLanguageCode(): string {
    const localStorageLanguage = JSON.parse(localStorage.getItem('language'));
    if (localStorageLanguage) {
      this.translateService.use(localStorageLanguage);
      return localStorageLanguage;
    }
    return LANGUAGE_CONFIG[0].languageCode;
  }
}
