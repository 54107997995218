<div class="modal-header">
  <survey-step-progress-bar [totalSteps]="7" [currentStepIndex]="-1" (stepTabClicked)="onSelectStep($event)">
  </survey-step-progress-bar>
  <h4 class="modal-step-title">
    {{ 'SurveyCommon-ERROR-MODAL_TITLE' | translate }}
  </h4>
</div>

<div class="modal-content">
  <div class="divider"></div>
  <div *ngIf="errors?.length > 0">
    <p class="black-text">
      {{ 'SurveyCommon-ERROR-MODAL_FIX_ERROR_TEXT' | translate }}
    </p>
    <div class="card">
      <div class="card-content black-text">
        <div class="card-text-wrapper grey lighten-3">
          <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
            <thead>
              <tr class="sr-base-table-row">
                <th>
                  {{ 'SurveyCommon-ERROR-MODAL_STEP_QUESTION_HEADER' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let err of errors
                    | paginate: { itemsPerPage: itemsPerPageArg, currentPage: errSelectedPage, id: 'errPage' }
                "
              >
                <td>
                  <a [routerLink]="do_nothing" (click)="onSelectError(err)">
                    {{
                      'SurveyCommon-ERROR-MODAL_STEP_DETAIL'
                        | translate
                          : { stepNum: err.stepNum, questionNum: err.questionNum, questionCount: err.questionCount }
                    }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col s12 m12">
      <pagination-controls (pageChange)="errSelectedPage = $event" id="errPage"> </pagination-controls>
    </div>
  </div>
</div>

<div class="modal-footer survey-modal-footer">
  <survey-footer
    [isErrorList]="true"
    [isFirstStep]="false"
    [isLastStep]="false"
    [currentStepIndex]="6"
    [totalSteps]="totalSteps"
    [isValid]="isValid"
    (next)="onNext()"
    (prev)="onPrev()"
    (submit)="onSubmit()"
    (cancel)="onCancel()"
  >
  </survey-footer>
</div>
