import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppSettings } from '../../../common/config';
import { contentHeaders, xWwwFormUrlEncodedHeaders } from '../../../common/headers';
import { AuthenticationService } from '../../../login/login.service';
import { SRResponse, UserApprove } from '../../../models';

@Injectable()
export class UserApprovalService {
  private URL_GET_USERS_FOR_APPROVAL = AppSettings.API_SERVER + '/smart-response/admin/users/approval';
  private URL_APPROVE_USER = AppSettings.API_SERVER + '/smart-response/admin/user/approve';
  private URL_REJECT_USER = AppSettings.API_SERVER + '/smart-response/admin/user/reject';

  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  getUsers(): Observable<UserApprove[]> {
    return this.http
      .get<SRResponse>(this.URL_GET_USERS_FOR_APPROVAL, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.usersApprovallist));
  }

  approve(email: string): Observable<UserApprove[]> {
    const body = 'email=' + email;

    return this.http.post<UserApprove[]>(this.URL_APPROVE_USER, body, {
      headers: this.authService.getAuthHeader(xWwwFormUrlEncodedHeaders),
    });
  }

  delete(email: string, isUserNotified: boolean): Observable<UserApprove[]> {
    const body = 'email=' + email + '&' + 'isUserNotified=' + isUserNotified;
    return this.http.post<UserApprove[]>(this.URL_REJECT_USER, body, {
      headers: this.authService.getAuthHeader(xWwwFormUrlEncodedHeaders),
    });
  }

  addUsertoSalesForce(
    user: UserApprove,
    accessToken: string,
    instanceUrl: string = AppSettings.SALESFORCE_CREATE_CONTACT_URL
  ): Observable<any> {
    const data = {
      FirstName: user.firstName,
      LastName: user.lastName,
      Email: user.email,
      SRUserType__c: user.userType,
      SRRegisteredAt__c: Date.now(),
      Company__c: user.organization.name,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    });

    return this.http.post(instanceUrl, JSON.stringify(data), { headers });
  }
}
