<div class="modal-header">
  <survey-step-progress-bar [totalSteps]="7" [currentStepIndex]="3" (stepTabClicked)="onSelectStep($event)">
  </survey-step-progress-bar>
  <h4 class="modal-step-title">
    {{
      'DisasterSpecificSurveyComponent-STEP_3_TITLE'
        | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
    }}
  </h4>
</div>
<div class="modal-content">
  <div class="divider title-divider"></div>

  <div class="row margin">
    <div id="ques31" class="red-text question-count"></div>
    <form>
      <!-- lead contact -->
      <div class="spacing-div col s12">
        <label for="s3_lead_contact_first_name" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">face</i>
          <input
            id="s3_lead_contact_first_name"
            type="text"
            placeholder="{{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="leadContactForm.get('value').get('firstName')"
          />
        </div>

        <label for="s3_lead_contact_last_name" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">face</i>
          <input
            id="s3_lead_contact_last_name"
            type="text"
            placeholder="{{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="leadContactForm.get('value').get('lastName')"
          />
        </div>

        <label for="s3_lead_contact_job" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_JOB_TITLE' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">face</i>
          <input
            id="s3_lead_contact_job"
            type="text"
            placeholder="{{ 'UNIVERSAL-CONTACT_JOB_TITLE' | translate }}"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="leadContactForm.get('value').get('title')"
          />
        </div>

        <label for="s3_lead_contact_phone" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_PHONE' | translate }}
        </label>
        <div class="input-field spacing col s12">
          <i class="material-icons prefix">phone</i>
          <input
            id="s3_lead_contact_phone"
            type="text"
            placeholder="{{ 'UNIVERSAL-CONTACT_PHONE' | translate }}"
            materialize="characterCounter"
            length="32"
            maxlength="32"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="leadContactForm.get('value').get('phone1')"
          />
          <p class="blue-text info shift-margin">
            {{ 'UNIVERSAL-INSTRUCTIONS_PHONE_FULL' | translate }}
          </p>
          <div
            class="shift-margin sr-error-area"
            *ngIf="
              !leadContactForm.get('value').get('phone1').valid && leadContactForm.get('value').get('phone1').touched
            "
          >
            <div *ngIf="leadContactForm.get('value').get('phone1').errors.phoneFormat">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PHONE' | translate }}
            </div>
          </div>
        </div>

        <label for="s3_lead_contact_email" class="sr-label">
          {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
        </label>
        <div
          class="input-field spacing col s12"
          [ngClass]="{
            'sr-has-error':
              !leadContactForm.get('value').get('email').valid &&
              (leadContactForm.get('value').get('email').touched || invalidOnInit?.singles?.email)
          }"
        >
          <i class="material-icons prefix">email</i>
          <input
            id="s3_lead_contact_email"
            type="text"
            materialize="characterCounter"
            length="64"
            placeholder="{{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}"
            maxlength="64"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="leadContactForm.get('value').get('email')"
          />
          <div
            class="sr-error-area shift-margin"
            *ngIf="
              !leadContactForm.get('value').get('email').valid &&
              (leadContactForm.get('value').get('email').touched || invalidOnInit?.singles?.email)
            "
          >
            <div *ngIf="leadContactForm.get('value').get('email').hasError('mailFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
            </div>
          </div>
        </div>
        <div>
          <last-edited-metadata
            class="no-left-spacing"
            [formElement]="leadContactForm"
            [isDisabled]="organizationRespondingToDisaster ? '' : null"
          ></last-edited-metadata>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <survey-footer
    [currentStepIndex]="2"
    [totalSteps]="totalSteps"
    [isValid]="isValid"
    (next)="onNext()"
    (prev)="onPrev()"
    (submit)="onSubmit()"
    (cancel)="onCancel()"
    (error)="onError()"
  >
  </survey-footer>
</div>
