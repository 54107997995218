import { SRResponse, DocumentInfo } from './../../models';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../common/config';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../login/login.service';
import { contentHeaders } from 'app/common/headers';

const API_URL = AppSettings.API_SERVER + '/smart-response/admin/document';

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  public getDocumentInfo(documentId: number): Observable<DocumentInfo> {
    return this.http
      .get<SRResponse>(`${API_URL}/${documentId}`, { headers: this.authService.getAuthHeader(null) })
      .pipe(map((res) => res.responseData.doc));
  }

  public getDocumentDownloadLink(documentId: number): string {
    return `${API_URL}/${documentId}/download`;
  }

  public uploadDocument(file: File): Observable<DocumentInfo> {
    const data = new FormData();
    data.append('doc', file, file.name);

    return this.http
      .post<SRResponse>(`${API_URL}`, data, { headers: this.authService.getAuthHeader(null) })
      .pipe(map((res) => res.responseData.doc));
  }
}
