import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'unread-messages',
  templateUrl: './unread-messages.component.html',
})
export class UnreadMessagesComponent implements OnInit {
  @Input() count: number;

  constructor() {}

  ngOnInit() {}
}
