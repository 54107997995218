import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateStringArray',
  pure: true,
})
export class TruncateStringArrayPipe implements PipeTransform {
  /**
   *
   * @param input {string}        : array input (no unicode support)
   * @param maxCharacter {number} : maximum character
   * @param delim                 : delimiter
   * @returns {string}            : transformed string
   *
   */

  transform(input: string[], maxCharacter: number, delim: string = ', '): string {
    let countChars = 0;
    let index = 0;
    const fullSplicedString = input.join(delim);
    const fullSplicedStringLength = input.join('').length;
    const ellipsis = maxCharacter < fullSplicedStringLength ? '...' : '';

    // exclude edge cases
    // empty array or maxCharacter less than 1
    if (input.length < 1 || maxCharacter < 1) {
      return '';
    }
    // single element in the array
    if (input.length === 1 || input[0].length >= maxCharacter) {
      return `${input[0]} ${ellipsis}`;
    }
    // maxCharacter exceeds the total characters
    if (maxCharacter > fullSplicedStringLength) {
      return fullSplicedString;
    }

    for (const entry of input) {
      countChars += entry.length;
      if (countChars > maxCharacter) {
        return `${input.slice(0, index).join(delim)} ${ellipsis}`;
      }
      if (countChars === maxCharacter) {
        return `${input.slice(0, index + 1).join(delim)} ${ellipsis}`;
      }
      index += 1;
    }
  }
}
