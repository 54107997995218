<ng-template [ngIf]="myUser">
  <div class="row">
    <div class="col m12 s12">
      <div class="card">
        <div class="card-content">
          <div class="card-title sr-bold">
            {{ 'UNIVERSAL-VIEW_TEXT' | translate }}
            <span class="sr-italic">
              {{
                'DashboardUserManagementViewComponent-TITLE_MIXIN'
                  | translate: { firstName: myUser.firstName, lastName: myUser.lastName }
              }}
            </span>
            {{ 'UNIVERSAL-INFORMATION_TEXT' | translate }}
          </div>

          <div class="divider"></div>

          <br />

          <div class="collection">
            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
              </div>
              <div class="col m8 s12">
                {{ myUser.firstName }}
              </div>
            </div>

            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
              </div>
              <div class="col m8 s12">
                {{ myUser.lastName }}
              </div>
            </div>

            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
              </div>
              <div class="col m8 s12">
                {{ myUser.email }}
              </div>
            </div>

            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UNIVERSAL-CONTACT_PHONE' | translate }}
              </div>
              <div class="col m8 s12">
                {{ myUser.phoneNumber }}
              </div>
            </div>

            <div *ngIf="myOrg" class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UNIVERSAL-CONTACT_ORGANIZATION' | translate }}
              </div>
              <div class="col m8 s12">
                {{ myOrg.name }}
              </div>
            </div>

            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UserManagementCommon-USER_TYPE' | translate }}
              </div>
              <div class="col m8 s12">
                <div>
                  {{ myUser.userTypeName }}
                </div>
              </div>
            </div>

            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UserManagementCommon-LAST_LOGIN' | translate }}
              </div>
              <div class="col m8 s12">
                <div>
                  {{ myUser.lastLoginTime | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }}
                </div>
              </div>
            </div>

            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UserManagementCommon-ADDED_DATE' | translate }}
              </div>
              <div class="col m8 s12">
                <div>
                  {{ myUser.registerDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }}
                </div>
              </div>
            </div>

            <div *ngIf="authService.isSuper()" class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UserManagementCommon-ENABLED' | translate }}
              </div>
              <div class="col m8 s12">
                <div>
                  {{ myUser.enabled }}
                </div>
              </div>
            </div>

            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UserManagementCommon-ACTIVATED' | translate }}
              </div>
              <div class="col m8 s12">
                <div>
                  {{ myUser.activated }}
                </div>
              </div>
            </div>

            <div *ngIf="authService.isSuper()" class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UNIVERSAL-GOVERNMENT_ID' | translate }}
              </div>
              <div class="col m8 s12">
                {{ myUser.govId }}
              </div>
            </div>

            <div class="row collection-item">
              <div class="sr-bold col m4 s12">
                {{ 'UNIVERSAL-COUNTRY_NAME' | translate }}
              </div>
              <div class="col m8 s12">
                {{ myUser.countryName }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <div class="input-field right" *ngIf="!GOB_COMPONENT">
                <a class="waves-effect waves-light btn cyan" [routerLink]="['/dashboard/users/edit', myUser.userId]">
                  {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
                </a>
                <a class="waves-effect waves-light btn cyan" [routerLink]="['..']">
                  <i class="material-icons left">skip_previous</i>
                  {{ 'UNIVERSAL-NAVIGATION_BACK' | translate }}
                </a>
              </div>
              <!-- Controllers for GOB Surveu -->
              <div class="input-field right" *ngIf="GOB_COMPONENT">
                <a class="waves-effect waves-light btn cyan" (click)="dispatchAction('edit', myUser.userId)">
                  {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
                </a>
                <a class="waves-effect waves-light btn cyan" (click)="dispatchAction('general_view')">
                  <i class="material-icons left">skip_previous</i>
                  {{ 'UNIVERSAL-NAVIGATION_BACK' | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
