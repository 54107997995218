import { zip, switchMap, map } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { ResponseProfileComponent } from '../responseProfile/response-profile.component';
import { AppSettings } from '../../common/config';
import { handleErrors } from '../../common/error';
import { ReportInputModel, ReportSurveyTypes } from '../reportModal/report.model';
import { GlobalLoaderFacade } from '../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { OrganizationService } from '../../dashboard/servicesComponets/organizations/organization.service';
import {
  Collection,
  ConvertedMoney,
  Dictionary,
  Disaster,
  DisasterSpecificSurveyInput,
  DisasterSpecificSurveyServiceListValue,
  Money,
  Organization,
} from '../../models';
import { DonationHelperService } from '../donationHelper/donation-helper.service';
import { DonationCampaignByDisasterService } from '../donationHelper/donation-campaign-by-disaster.service';
import { formatStringList, validateResponseField } from '../survey-response-helpers';
import { MaterializeAction } from 'angular2-materialize';
import { REPORT_SUBMISSION_FREQUENCY } from '../../dashboard/servicesComponets/surveys/survey-helpers';
import { GeneralOrganizationBackgroundSurveyService } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.service';

import { GeneralOrganizationBackgroundSurveyInput } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.model';

import { DisasterSpecificSurveyService } from '../../dashboard/servicesComponets/surveys/disasterSpecificSurvey/disaster-specific-survey.service';

import { LogoUploadService } from '../../dashboard/servicesComponets/logoUpload/logo-upload.service';
import { Address } from '../../pipes/locationPipe';
import { LocationMapComponent } from '../../common/locationMap/location-map.component';
import { UsaStateNamePipe } from 'app/pipes/usaStateNamePipe';
import { UniquePipe } from 'app/pipes/uniquePipe';
import { DocumentService } from '../../sharedServices/documentService/document.service';
import { CurrencyService } from 'app/sharedServices/currencyService/currency.service';
import { CurrencyPickerService } from 'app/header/currencyPicker/currency-picker.service';

declare var $: any;

interface OrgDisasterModel {
  plansToRespond: boolean;
  isSolicitingDonations: boolean;
  orgId: number;
  orgName: string;
  localStaffCount: number;
  nonLocalStaffCount: number;
  volunteerStaffCount: number;
  disaster: Disaster;
  donateButtonDisabled: boolean;
  engagements: string[];
}

@Component({
  selector: 'org-disaster',
  providers: [LogoUploadService, DonationCampaignByDisasterService, UsaStateNamePipe, UniquePipe],
  templateUrl: './org-disaster.component.html',
  styleUrls: ['./org-disaster.component.css', '../common-search.css'],
})
export class OrgDisasterComponent extends ResponseProfileComponent implements OnInit {
  public orgId: number;
  public disasterId: number;
  public context: OrgDisasterModel = {
    plansToRespond: null,
    isSolicitingDonations: null,
    orgId: null,
    orgName: null,
    localStaffCount: null,
    nonLocalStaffCount: null,
    volunteerStaffCount: null,
    disaster: null,
    donateButtonDisabled: false,
    engagements: null,
  };
  public organization: Organization;
  public orgDisasterMetadata: string;
  public latestGobSurvey: GeneralOrganizationBackgroundSurveyInput;
  public latestDssSurvey: DisasterSpecificSurveyInput;
  public disasterStageNames: { [id: string]: string } = {};
  public selectedReportFrequency: any;
  public errors: any;
  public orgLogoURI: string;
  public reportActions: EventEmitter<ReportInputModel> = new EventEmitter<ReportInputModel>();
  public address: Address;
  public videoId: string = '';
  title: string = 'OrgDisasterComponent-TITLE';
  loading: boolean;
  subscriptions: Dictionary<Subscription> = {};
  organizationDisasterLocations: any;
  currentCountry: string;
  donateModalActions = new EventEmitter<string | MaterializeAction>();
  tippingModalActions = new EventEmitter<string | MaterializeAction>();
  showOrgLocations = false;
  locations: string;

  money: Collection<ConvertedMoney> = {
    singles: {
      raised: null,
      spent: null,
      interest: null,
    },
    arrays: {
      transfers: [],
    },
  };

  @ViewChild(LocationMapComponent, { static: false }) locationsMap: LocationMapComponent;

  params = [
    {
      onOpen: (el) => {
        if (el.attr('id') === 'li7' && this.locationsMap) {
          this.locationsMap.resize();
        }
      },
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private documentService: DocumentService,
    private donationHelperService: DonationHelperService,
    private donationCampaignByDisasterService: DonationCampaignByDisasterService,
    private globalLoader: GlobalLoaderFacade,
    private gobService: GeneralOrganizationBackgroundSurveyService,
    private dssService: DisasterSpecificSurveyService,
    private logoUploadService: LogoUploadService,
    private organizationService: OrganizationService,
    private currencyService: CurrencyService,
    private currencyPickerService: CurrencyPickerService,
    private usaStateName: UsaStateNamePipe,
    private uniquePipe: UniquePipe
  ) {
    super(currencyService, currencyPickerService, notificationsService, translateService);
  }

  ngOnInit() {
    this.globalLoader.start();
    this.loading = true;

    this.addSubscription('route', this.getRouteSubscription.bind(this));
  }

  private getRouteSubscription(): Subscription {
    return combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        map(([params, queryParams]) => ({
          params,
          queryParams,
        })),
        switchMap(({ params, queryParams }) => {
          this.orgId = +params['org_id'];
          this.disasterId = +params['disaster_id'];
          this.videoId = queryParams['videoId'];
          const organizationObservable = this.organizationService.getOrganization(+params['org_id']);
          const orgDisasterMetadataObservable = this.organizationService.getOrgDisasterMetadata(
            +params['org_id'],
            +params['disaster_id']
          );
          const disasterDonationObservable = this.donationCampaignByDisasterService.getDonationCampaignByDisaster(
            +params['disaster_id']
          );

          return this.dssService.getMostRecentSurvey(this.orgId, this.disasterId).pipe(
            zip(
              this.gobService.getMostRecentSurvey(this.orgId),
              organizationObservable,
              orgDisasterMetadataObservable,
              disasterDonationObservable,
              (dss, gob, org, orgDisasterMetadata, disasterDonations) => ({
                dss,
                gob,
                org,
                orgDisasterMetadata,
                disasterDonations,
              })
            )
          );
        })
      )
      .subscribe(
        (success) => {
          this.globalLoader.complete();
          this.loading = false;
          this.latestGobSurvey = success.gob;
          if (success.gob.hqAddress) {
            this.address = {
              city: success.gob.hqAddress.value.city,
              state: success.gob.hqAddress.value.state,
              country: success.gob.hqAddress.value.country ? success.gob.hqAddress.value.country.name : null,
            };
          }

          this.orgDisasterMetadata = success.orgDisasterMetadata;
          const isDisasterGlobal = success.dss.disaster.global;

          this.organizationDisasterLocations = this.getOrganizationDisasterLocationsList(success);
          if (this.organizationDisasterLocations.length > 0 && !isDisasterGlobal) {
            this.currentCountry = this.organizationDisasterLocations[0].country.name;
            this.locations = this.uniquePipe
              .transform(this.organizationDisasterLocations, 'country.name')
              .map((o) => this.usaStateName.transform(o.country.name))
              .join(', ');
          } else if (isDisasterGlobal) {
            this.showOrgLocations = !this.showOrgLocations;
            this.locations = this.uniquePipe
              .transform(success.gob.locationContactList, 'value.countryName')
              .map((o) => this.usaStateName.transform(o.value.countryName))
              .join(', ');
          }

          this.latestDssSurvey = success.dss;
          this.organization = success.org;
          this.setContext(success);

          if (this.latestDssSurvey.activityReport) {
            this.selectedReportFrequency = REPORT_SUBMISSION_FREQUENCY.find(
              (e) => e.id === this.latestDssSurvey.activityReport.value.reportFrequencyId
            );
          }

          // filter services if both direct and indirect proportions are 0.
          if (success.dss && success.dss.serviceList && success.dss.serviceList.value) {
            this.latestDssSurvey.serviceList.value = this.latestDssSurvey.serviceList.value.filter(
              (service) => service.directProportion || service.indirectProportion
            ) as [DisasterSpecificSurveyServiceListValue];
          }

          // Set displayable names for disaster stages
          this.disasterStageNames['mitigationStage'] = this.translateService.instant('SurveyCommon-Q_DS_MITI');
          this.disasterStageNames['reliefStage'] = this.translateService.instant('SurveyCommon-Q_DS_RELI');
          this.disasterStageNames['recoveryStage'] = this.translateService.instant('SurveyCommon-Q_DS_RECO');
          this.disasterStageNames['responseStage'] = this.translateService.instant('SurveyCommon-Q_DS_RESP');
          this.disasterStageNames['preparedStage'] = this.translateService.instant('SurveyCommon-Q_DS_PREP');

          setTimeout(() => {
            const element = document.getElementById(this.videoId);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }, 1000);
        },
        (error) => {
          this.globalLoader.complete();
          this.loading = false;
          handleErrors(error, this.translateService, this.notificationsService, this.title);
        }
      );
  }

  getOrganizationDisasterLocationsList(data) {
    const orgLocations = data.gob.locationContactList;
    const disasterLocations = data.dss.disaster.locations;
    const result = [];
    for (const location of disasterLocations) {
      const loc = orgLocations.find((orgLocation) => orgLocation.value.countryName === location.country.name);
      if (loc) {
        result.push(location);
      }
    }
    return result;
  }

  setContext(data: any) {
    if (!data) {
      return;
    }

    if (data.gob) {
      this.context.orgId = data.gob.organizationId;
      this.context.orgName = data.gob.organizationName;

      this.logoUploadService.getLogo(this.context.orgId).subscribe(
        (success) => {
          this.orgLogoURI = `${success.uri}`;
        },
        (error) => {
          this.orgLogoURI = 'assets/images/org-default.png';
        }
      );
    }

    if (data.dss) {
      this.context.disaster = data.dss.disaster;
      this.context.plansToRespond =
        data.dss.organizationRespondingToDisaster ||
        (!data.dss.organizationRespondingToDisaster && !data.dss.surveySubmissionId);
      this.context.isSolicitingDonations = this.donationHelperService.isOrgSolicitingDonationsForDisaster(data.dss);
      this.context.localStaffCount = this.getLocalStaffCount(data.dss);
      this.context.nonLocalStaffCount = this.getNonLocalStaffCount(data.dss);
      this.context.volunteerStaffCount = this.getVolunteerStaffCount(data.dss);
      this.context.donateButtonDisabled = data.dss.donateButtonDisabled;
      this.context.engagements = this.getEngagements(data.dss);
      this.initMoneyAmts();
      this.addSubscription('currency', this.getCurrencySubscription.bind(this));
    }
  }

  private getCurrencySubscription(): Subscription {
    return this.setupCurrencyContext().subscribe(([conversions, formats]) => {
      this.setCurrencies(conversions.currency, formats);
      this.money = conversions.money;
    });
  }

  private initMoneyAmts(): void {
    const dssTransfers = validateResponseField(this.latestDssSurvey.transferredFund);
    const singleFunds: Dictionary<Money> = {
      raised: validateResponseField<Money>(this.latestDssSurvey.raisedFund),
      spent: validateResponseField<Money>(this.latestDssSurvey.spentFund),
      interest: validateResponseField<Money>(this.latestDssSurvey.earnedInterest),
    };

    for (const [category, value] of Object.entries(singleFunds)) {
      this.money.singles[category] = new ConvertedMoney(value);
    }

    const hasTransfers: boolean = dssTransfers && dssTransfers.hasValue && !!dssTransfers.fundTransferList;

    if (hasTransfers) {
      dssTransfers.fundTransferList.forEach((dssTransfer) => {
        this.money.arrays.transfers.push(new ConvertedMoney(dssTransfer));
      });
    }
  }

  // TODO: I think we won"t need to have so many isActive trigger
  // Why this needs to be public ?
  public isActive(): boolean {
    return true;
  }

  getLocalStaffCount(dss: DisasterSpecificSurveyInput): number {
    let count = 0;

    if (!dss || !dss.localStaff) {
      return null;
    }

    count += dss.localStaff.value.mgmtStaffCount ? dss.localStaff.value.mgmtStaffCount : 0;
    count += dss.localStaff.value.professionalStaffCount ? dss.localStaff.value.professionalStaffCount : 0;
    count += dss.localStaff.value.supportStaffCount ? dss.localStaff.value.supportStaffCount : 0;

    return count;
  }

  getNonLocalStaffCount(dss: DisasterSpecificSurveyInput): number {
    let count = 0;

    if (!dss || !dss.nonLocalStaff) {
      return null;
    }

    count += dss.nonLocalStaff.value.mgmtStaffCount ? dss.nonLocalStaff.value.mgmtStaffCount : 0;
    count += dss.nonLocalStaff.value.professionalStaffCount ? dss.nonLocalStaff.value.professionalStaffCount : 0;
    count += dss.nonLocalStaff.value.supportStaffCount ? dss.nonLocalStaff.value.supportStaffCount : 0;

    return count;
  }

  getVolunteerStaffCount(dss: DisasterSpecificSurveyInput): number {
    let count = 0;

    if (!dss || !dss.volunteerStaff) {
      return null;
    }

    count += dss.volunteerStaff.value.mgmtStaffCount ? dss.volunteerStaff.value.mgmtStaffCount : 0;
    count += dss.volunteerStaff.value.professionalStaffCount ? dss.volunteerStaff.value.professionalStaffCount : 0;
    count += dss.volunteerStaff.value.supportStaffCount ? dss.volunteerStaff.value.supportStaffCount : 0;

    return count;
  }

  private getEngagements(dss: DisasterSpecificSurveyInput): string[] {
    if (dss && dss.engagementList) {
      const list: string[] = this.latestDssSurvey.engagementList.value;
      return formatStringList(list);
    }
    return null;
  }

  getTimestampForResponse(surveyType: string, key: string): number {
    let ts;

    if (surveyType === ReportSurveyTypes.GOB) {
      ts = this.latestGobSurvey[key] ? this.latestGobSurvey[key].lastUpdatedDate : 0;
    } else if (surveyType === ReportSurveyTypes.DSS) {
      ts = this.latestDssSurvey[key] ? this.latestDssSurvey[key].lastUpdatedDate : 0;
    }

    ts = ts || 0;

    return ts;
  }

  openReport(surveyQuestionKey: string, surveyQuestionTranslationKey: string): void {
    this.reportActions.emit({
      surveyQuestionKey,
      surveyQuestionTranslationKey,
      orgName: this.context.orgName,
      surveyId: this.latestDssSurvey.surveySubmissionId,
      surveyType: ReportSurveyTypes.DSS,
    });
  }
  openDonateModal() {
    this.donateModalActions.emit({ action: 'modal', params: ['open'] });
  }

  requestDssSurveyUpdate(): void {
    this.dssService.requestDSSSurveyUpdate(this.disasterId, this.orgId).subscribe(
      (request) => {
        if (request.status === 0) {
          this.notificationsService.success(
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST'),
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST-INFO-SENT')
          );
        }
      },
      (err) => {
        if (err.error.errors[0].errorCode === 'SURVEY_REQUEST_INFO_ALREADY_SENT') {
          this.notificationsService.alert(
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST-INFO-ALREADY-SENT')
          );
        } else {
          handleErrors(err, this.translateService, this.notificationsService, 'UNIVERSAL-SURVEY_DSS');
        }
      }
    );
  }

  getTotalMgmtStaffCount(): number {
    let count = 0;
    let responded = false;

    // Add local staff
    if (this.latestDssSurvey.localStaff && this.latestDssSurvey.localStaff.value.mgmtStaffCount !== null) {
      responded = true;
      count += this.latestDssSurvey.localStaff.value.mgmtStaffCount;
    }

    // Add non local staff
    if (this.latestDssSurvey.nonLocalStaff && this.latestDssSurvey.nonLocalStaff.value.mgmtStaffCount !== null) {
      responded = true;
      count += this.latestDssSurvey.nonLocalStaff.value.mgmtStaffCount;
    }

    // Return null to get "no response" if only null responses were found
    if (!responded) {
      return null;
    }

    return count;
  }

  getTotalProfessionalStaffCount(): number {
    let count = 0;
    let responded = false;

    // Add local staff
    if (this.latestDssSurvey.localStaff && this.latestDssSurvey.localStaff.value.professionalStaffCount !== null) {
      responded = true;
      count += this.latestDssSurvey.localStaff.value.professionalStaffCount;
    }

    // Add non local staff
    if (
      this.latestDssSurvey.nonLocalStaff &&
      this.latestDssSurvey.nonLocalStaff.value.professionalStaffCount !== null
    ) {
      responded = true;
      count += this.latestDssSurvey.nonLocalStaff.value.professionalStaffCount;
    }

    // Return null to get "no response" if only null responses were found
    if (!responded) {
      return null;
    }

    return count;
  }

  getTotalSupportStaffCount(): number {
    let count = 0;
    let responded = false;

    // Add local staff
    if (this.latestDssSurvey.localStaff && this.latestDssSurvey.localStaff.value.supportStaffCount !== null) {
      responded = true;
      count += this.latestDssSurvey.localStaff.value.supportStaffCount;
    }

    // Add non local staff
    if (this.latestDssSurvey.nonLocalStaff && this.latestDssSurvey.nonLocalStaff.value.supportStaffCount !== null) {
      responded = true;
      count += this.latestDssSurvey.nonLocalStaff.value.supportStaffCount;
    }

    // Return null to get "no response" if only null responses were found
    if (!responded) {
      return null;
    }

    return count;
  }
}
