import { switchMap, map } from 'rxjs/operators';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';

import { GeneralOrganizationBackgroundSurveyService } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.service';
import { GeneralOrganizationBackgroundSurveyInput } from '../../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.model';

import { OrganizationService } from '../../dashboard/servicesComponets/organizations/organization.service';
import { Service } from '../../models/Service';
import { DisasterSpecificSurveyInput } from '../../models/DisasterSpecificSurvey.model';
import { ResponseProfileComponent } from '../responseProfile/response-profile.component';
import { OrgDisasterService } from '../orgDisaster/org-disaster.service';
import { validateResponseField } from '../survey-response-helpers';

import { ReportInputModel, ReportSurveyTypes } from '../reportModal/report.model';

import { GlobalLoaderFacade } from '../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { handleErrors } from '../../common/error';
import { Address } from '../../pipes/locationPipe';
import { AppSettings } from '../../common/config';
import { MaterializeAction } from 'angular2-materialize';
import { isNullOrUndefined } from 'util';
import { Collection, ConvertedMoney, Dictionary, Organization, Money } from '../../models';
import { CurrencyService } from '../../sharedServices/currencyService/currency.service';
import { CountryListService } from 'app/dashboard/servicesComponets/surveys/shared/countryList.service';
import { CurrencyPickerService } from 'app/header/currencyPicker/currency-picker.service';

const CHARITY_NAVIGATOR_BASE_URL = 'https://www.charitynavigator.org/ein/';
const GUIDESTAR_BASE_URL = 'https://www.guidestar.org/profile/';
@Component({
  selector: 'org-background',
  templateUrl: './org-background.component.html',
  styleUrls: ['./org-background.component.css', '../common-search.css'],
  providers: [OrgDisasterService],
})
export class OrgBackgroundComponent extends ResponseProfileComponent implements OnInit {
  public orgName: string;
  public orgId: number;
  public orgAddedDate: number;
  public organization: Organization;
  public serviceList: Service[];
  public disasterSpecificSurvey: any[];

  public latestGobSurvey: GeneralOrganizationBackgroundSurveyInput;
  public address: Address;

  public reportActions: EventEmitter<ReportInputModel> = new EventEmitter<ReportInputModel>();

  public isCountryUSA: boolean = false;
  public guideStarUrl: string = '';
  public charityNavigatorUrl: string = '';

  public AppSettings = AppSettings;
  public loading: boolean;
  public donateModalActions = new EventEmitter<string | MaterializeAction>();
  public tippingModalActions = new EventEmitter<string | MaterializeAction>();

  public videoId: string = '';

  title: string = 'OrgBackgroundComponent-TITLE';

  subscriptions: Dictionary<Subscription> = {};

  money: Collection<ConvertedMoney> = {
    singles: {
      budget: null,
    },
  };

  constructor(
    private surveyService: GeneralOrganizationBackgroundSurveyService,
    private organizationService: OrganizationService,
    private orgDisasterService: OrgDisasterService,
    private currencyService: CurrencyService,
    private currencyPickerService: CurrencyPickerService,
    private globalLoader: GlobalLoaderFacade,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private countryListService: CountryListService,
    private route: ActivatedRoute
  ) {
    super(currencyService, currencyPickerService, notificationsService, translateService);
  }

  public ngOnInit() {
    this.globalLoader.start();
    this.loading = true;

    this.addSubscription('route', this.getRouteSubscription.bind(this));
  }

  private getRouteSubscription(): Subscription {
    return combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        map(([params, queryParams]) => ({
          queryParams,
          id: +params.id,
        })),
        switchMap(({ id, queryParams }) =>
          this.surveyService.getMostRecentSurvey(id).pipe(map((survey) => ({ survey, queryParams })))
        )
      )
      .subscribe(
        ({ survey, queryParams }) => {
          this.globalLoader.complete();
          this.loading = false;

          this.videoId = queryParams['videoId'];

          // Nullify survey responses which are empty or made up of whitespaces
          for (const formStep in survey) {
            if (survey[formStep] !== null && typeof survey[formStep].value === 'object') {
              // Handle nested object responses
              for (const formField in survey[formStep].value) {
                // Values that are strings have their whitespaces removed and checked if they are empty
                if (
                  typeof survey[formStep].value[formField] === 'string' &&
                  survey[formStep].value[formField].replace(/\s/g, '') === ''
                ) {
                  // Setting the field value to null makes it a "no response", instead of a blank response
                  survey[formStep].value[formField] = null;
                }
              }
            } else if (
              survey[formStep] !== null &&
              typeof survey[formStep].value === 'string' &&
              survey[formStep].value.replace(/\s/g, '') === ''
            ) {
              // Handle string responses
              survey[formStep].value = null;
            }
          }

          this.orgAddedDate = survey.organizationDto.addedDate;
          this.latestGobSurvey = survey;
          this.orgName = survey.organizationName;
          this.orgId = survey.organizationId;

          if (survey.hqAddress) {
            this.address = {
              city: survey.hqAddress.value.city,
              state: survey.hqAddress.value.state,
              country: survey.hqAddress.value.country ? survey.hqAddress.value.country.name : null,
            };
            if (survey.hqAddress.value.country) {
              this.countryListService.isCountryUSA(survey.hqAddress.value.country.countryId).subscribe((result) => {
                this.isCountryUSA = result;
              });
            }
          }
          this.initMoneyAmts();
          this.addSubscription('currency', this.getCurrencySubscription.bind(this));

          if (this.latestGobSurvey.govIdOrEin) {
            const ein = this.latestGobSurvey.govIdOrEin.value;
            this.guideStarUrl = ein ? GUIDESTAR_BASE_URL + ein.replace(/\s/g, '') : null;
            this.charityNavigatorUrl = ein ? CHARITY_NAVIGATOR_BASE_URL + ein.replace(/[\s-]/g, '') : null;
          } else {
            this.guideStarUrl = null;
            this.charityNavigatorUrl = null;
          }

          this.organizationService
            .getOrganizationService(survey.organizationId)
            .subscribe((services) => (this.serviceList = services));

          this.organizationService.getOrganization(survey.organizationId).subscribe((org) => {
            this.organization = org;
          });

          this.orgDisasterService.getOrgRespondToDisaster(survey.organizationId).subscribe((res) => {
            this.disasterSpecificSurvey = res
              .reduce((accumulator: DisasterSpecificSurveyInput[], surveyToCompare: DisasterSpecificSurveyInput) => {
                const hasRequiredProperties =
                  surveyToCompare &&
                  surveyToCompare.reliefRecoveryService &&
                  surveyToCompare.reliefRecoveryService.value &&
                  surveyToCompare.lastUpdatedDate &&
                  surveyToCompare.disaster &&
                  surveyToCompare.disaster.disasterId;

                if (!hasRequiredProperties) {
                  return accumulator;
                }

                const storedSurveyIndex = accumulator.findIndex(
                  (survey: DisasterSpecificSurveyInput) =>
                    survey.disaster.disasterId === surveyToCompare.disaster.disasterId
                );

                if (storedSurveyIndex === -1) {
                  accumulator.push(surveyToCompare);
                } else if (accumulator[storedSurveyIndex].lastUpdatedDate < surveyToCompare.lastUpdatedDate) {
                  accumulator[storedSurveyIndex] = surveyToCompare;
                }

                return accumulator;
              }, [])
              .filter((survey: DisasterSpecificSurveyInput) => survey.organizationRespondingToDisaster);
          });

          setTimeout(() => {
            const element = document.getElementById(this.videoId);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }, 1000);
        },
        (error) => {
          this.globalLoader.complete();
          this.loading = false;
          handleErrors(error, this.translateService, this.notificationsService, 'OrgBackgroundComponent-TITLE');
        }
      );
  }

  private getCurrencySubscription(): Subscription {
    return this.setupCurrencyContext().subscribe(([conversions, formats]) => {
      this.setCurrencies(conversions.currency, formats);
      this.money = conversions.money;
    });
  }

  private initMoneyAmts(): void {
    const singleFunds: Dictionary<Money> = {
      budget: validateResponseField<Money>(this.latestGobSurvey.overallAnnualBudget),
    };

    for (const [category, value] of Object.entries(singleFunds)) {
      this.money.singles[category] = new ConvertedMoney(value);
    }
  }

  getTimestampForResponse(key: string): number {
    let a = !isNullOrUndefined(this.latestGobSurvey[key]) ? this.latestGobSurvey[key].lastUpdatedDate : 0;

    a = a || 0;

    const ret = Math.max(a, this.orgAddedDate);

    return ret;
  }

  getSurveyType(): string {
    return ReportSurveyTypes.GOB;
  }

  requestGobSurveyUpdate(): void {
    this.surveyService.requestGOBSurveyUpdate(this.organization.organizationId).subscribe(
      (request) => {
        if (request.status === 0) {
          this.notificationsService.success(
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST'),
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST-INFO-SENT')
          );
        }
      },
      (err) => {
        if (err.error.errors[0].errorCode === 'SURVEY_REQUEST_INFO_ALREADY_SENT') {
          this.notificationsService.alert(
            this.translateService.instant('SurveyCommon-UPDATE-REQUEST-INFO-ALREADY-SENT')
          );
        } else {
          handleErrors(err, this.translateService, this.notificationsService, 'UNIVERSAL-SURVEY_GOB');
        }
      }
    );
  }

  openReport(surveyQuestionKey: string, surveyQuestionTranslationKey: string): void {
    this.reportActions.emit({
      surveyQuestionKey,

      surveyQuestionTranslationKey,

      orgName: this.orgName,

      surveyId: this.latestGobSurvey.surveySubmissionId,

      surveyType: ReportSurveyTypes.GOB,
    });
  }
  openDonateModal() {
    this.donateModalActions.emit({ action: 'modal', params: ['open'] });
  }
}
