<nav class="breadcrumb-nav">
  <div class="nav-wrapper">
    <div class="col s12 breadcrumb-div">
      <a *ngFor="let crumb of breadcrumbs" [routerLink]="crumb.routerLink ? crumb.routerLink : null" class="breadcrumb">
        {{ crumb.name }}
      </a>
      <a disabled class="breadcrumb">
        {{ lastCrumb }}
      </a>
    </div>
  </div>
</nav>
<div class="divider"></div>
