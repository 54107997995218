import { Component } from '@angular/core';

@Component({
  selector: 'intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css'],
})
export class IntroComponent {
  introImages;
  constructor() {
    this.introImages = [
      'assets/images/introimages/1.jpg',
      'assets/images/introimages/2.jpg',
      'assets/images/introimages/3.jpg',
    ];
  }
}
