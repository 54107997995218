<div class="container">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12 md10 l10 offset-l1 card-panel hoverable registration-card card">
    <div class="row">
      <div class="input-field col s12 center">
        <h5 class="center login-form-text">
          {{ 'NominateComponent-NOMINATE_ORGANIZATION_ACCOUNT_HEADING' | translate }}
        </h5>
      </div>
    </div>

    <div class="sr-required-notif sr-padding-required-text">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            !nominateForm.controls['organizationName'].valid && nominateForm.controls['organizationName'].touched
        }"
      >
        <i class="material-icons prefix">business</i>
        <input
          id="org_name"
          type="text"
          materialize="characterCounter"
          length="128"
          maxlength="128"
          [formControl]="nominateForm.controls['organizationName']"
        />
        <label for="org_name">
          {{ 'NominateComponent-ORGANIZATION_NAME_LABEL' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!nominateForm.controls['organizationName'].valid && nominateForm.controls['organizationName'].touched"
        >
          <div *ngIf="nominateForm.controls['organizationName'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="nominateForm.controls['organizationName'].value.length > 0">
            <div *ngIf="nominateForm.controls['organizationName'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 128 } }}
            </div>
            <div *ngIf="nominateForm.controls['organizationName'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="nominateForm.controls['organizationName'].hasError('orgFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_ORGNAME' | translate }}
            </div>
            <div *ngIf="nominateForm.controls['organizationName'].hasError('numbersOnly')">
              {{ 'UNIVERSAL-FORM_ERRORS_ONLY_NUMBERS' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !nominateForm.controls['orgLocation'].valid && nominateForm.controls['orgLocation'].touched
        }"
      >
        <i class="material-icons prefix">my_location</i>
        <input
          id="orgLocation"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          name="orgLocation"
          [formControl]="nominateForm.controls['orgLocation']"
        />
        <label for="orgLocation">
          {{ 'NominateComponent-LOCATION' | translate }}
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!nominateForm.controls['orgLocation'].valid && nominateForm.controls['orgLocation'].touched"
        >
          <div *ngIf="nominateForm.controls['orgLocation'].value.length > 0">
            <div *ngIf="nominateForm.controls['orgLocation'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 200 } }}
            </div>
            <div *ngIf="nominateForm.controls['orgLocation'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="nominateForm.controls['orgLocation'].hasError('numbersOnly')">
              {{ 'UNIVERSAL-FORM_ERRORS_ONLY_NUMBERS' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{ 'sr-has-error': !nominateForm.controls['orgDo'].valid && nominateForm.controls['orgDo'].touched }"
      >
        <i class="material-icons prefix">local_activity</i>
        <input
          id="orgDo"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          name="orgDo"
          [formControl]="nominateForm.controls['orgDo']"
        />
        <label for="orgDo">
          {{ 'NominateComponent-DO' | translate }}
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!nominateForm.controls['orgDo'].valid && nominateForm.controls['orgDo'].touched"
        >
          <div *ngIf="nominateForm.controls['orgDo'].value.length > 0">
            <div *ngIf="nominateForm.controls['orgDo'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 200 } }}
            </div>
            <div *ngIf="nominateForm.controls['orgDo'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="nominateForm.controls['orgDo'].hasError('numbersOnly')">
              {{ 'UNIVERSAL-FORM_ERRORS_ONLY_NUMBERS' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !nominateForm.controls['orgWhy'].valid && nominateForm.controls['orgWhy'].touched
        }"
      >
        <i class="material-icons prefix">business_center</i>
        <input
          id="orgWhy"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          name="orgWhy"
          [formControl]="nominateForm.controls['orgWhy']"
        />
        <label for="orgWhy">
          {{ 'NominateComponent-WHY' | translate }}
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!nominateForm.controls['orgWhy'].valid && nominateForm.controls['orgWhy'].touched"
        >
          <div *ngIf="nominateForm.controls['orgWhy'].value.length > 0">
            <div *ngIf="nominateForm.controls['orgWhy'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 200 } }}
            </div>
            <div *ngIf="nominateForm.controls['orgWhy'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="nominateForm.controls['orgWhy'].hasError('numbersOnly')">
              {{ 'UNIVERSAL-FORM_ERRORS_ONLY_NUMBERS' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !nominateForm.controls['orgKnow'].valid && nominateForm.controls['orgKnow'].touched
        }"
      >
        <i class="material-icons prefix">person_outline</i>
        <select
          id="orgKnow"
          name="orgKnow"
          materialize="material_select"
          [formControl]="nominateForm.controls['orgKnow']"
        >
          <option selected></option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <label for="orgKnow">
          {{ 'NominateComponent-KNOW' | translate }}
        </label>
      </div>
    </div>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !nominateForm.controls['contactName'].valid && nominateForm.controls['contactName'].touched
        }"
      >
        <i class="material-icons prefix">face</i>
        <input
          id="contactName"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          name="contactName"
          [formControl]="nominateForm.controls['contactName']"
        />
        <label for="contactName">
          {{ 'NominateComponent-CONTACT_NAME' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!nominateForm.controls['contactName'].valid && nominateForm.controls['contactName'].touched"
        >
          <div *ngIf="nominateForm.controls['contactName'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="nominateForm.controls['contactName'].hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 64 } }}
          </div>
          <div *ngIf="nominateForm.controls['contactName'].hasError('leadingTrailingWhiteSpaceFormat')">
            {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
          </div>
          <div *ngIf="nominateForm.controls['contactName'].hasError('numbersOnly')">
            {{ 'UNIVERSAL-FORM_ERRORS_ONLY_NUMBERS' | translate }}
          </div>
        </div>
      </div>
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !nominateForm.controls['contactEmail'].valid && nominateForm.controls['contactEmail'].touched
        }"
      >
        <i class="material-icons prefix">email</i>
        <input
          id="contactEmail"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          name="contactEmail"
          [formControl]="nominateForm.controls['contactEmail']"
        />
        <label for="contactEmail">
          {{ 'NominateComponent-CONTACT_EMAIL' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!nominateForm.controls['contactEmail'].valid && nominateForm.controls['contactEmail'].touched"
        >
          <div *ngIf="nominateForm.controls['contactEmail'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="nominateForm.controls['contactEmail'].errors.mailFormat">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}.
          </div>
          <div *ngIf="nominateForm.controls['contactEmail'].hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 64 } }}
          </div>
          <div *ngIf="nominateForm.controls['contactEmail'].hasError('leadingTrailingWhiteSpaceFormat')">
            {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
          </div>
        </div>
      </div>
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !nominateForm.controls['contactPhone'].valid && nominateForm.controls['contactPhone'].touched
        }"
      >
        <i class="material-icons prefix">call</i>
        <input
          id="contactPhone"
          type="text"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          name="contactPhone"
          [formControl]="nominateForm.controls['contactPhone']"
        />
        <label for="contactPhone">
          {{ 'NominateComponent-CONTACT_PHONE' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!nominateForm.controls['contactPhone'].valid && nominateForm.controls['contactPhone'].touched"
        >
          <div *ngIf="nominateForm.controls['contactPhone'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="nominateForm.controls['contactPhone'].hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
          </div>
          <div *ngIf="nominateForm.controls['contactPhone'].hasError('leadingTrailingWhiteSpaceFormat')">
            {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
          </div>
          <div *ngIf="nominateForm.controls['contactPhone'].hasError('phoneFormat')">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PHONE' | translate }}
          </div>
        </div>
      </div>
      <p>
        {{ 'UNIVERSAL-FORM_PASS_CAPTCHA' | translate }}
      </p>
      <div class="input-field center">
        <re-captcha
          (resolved)="onValidCaptchaResponse($event)"
          siteKey="{{ AppSettings.RECAPTCHA_SITE_KEY }}"
          (error)="onCaptchaExpire($event)"
          errorMode="handled"
        >
        </re-captcha>
      </div>
      <div class="row margin">
        <div class="input-field center">
          <button
            (click)="onSubmit()"
            class="btn waves-effect waves-light cyan"
            [disabled]="isSubmitting || !nominateForm.valid"
          >
            {{ 'NominateComponent-NOMINATE_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
