import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SurveyInputInterface } from '../survey-input-interface.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { AppSettings } from '../../../../common/config';

const VALID_SURVEY_TYPES = ['gob', 'lss', 'dss'];

@Component({
  selector: 'manual-survey-edits',
  providers: [],
  templateUrl: './generic-survey.component.html',
  styleUrls: ['./generic-survey.component.css'],
})
export class ManualSurveyBrowserAndEditorComponent implements OnInit {
  surveyType: number;
  surveyId: number;

  surveyInput: SurveyInputInterface;

  SURVEY_GOB = 0;
  SURVEY_LSS = 1;
  SURVEY_DSS = 2;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = +params['id'];

      this.surveyType = VALID_SURVEY_TYPES.indexOf(params['type']);
      this.surveyId = id;

      if (-1 === this.surveyType) {
        this.notificationsService.error(
          this.translateService.instant('ManualSurveyBrowserAndEditorComponent-TITLE'),
          this.translateService.instant('ManualSurveyBrowserAndEditorComponent-INVALID_SURVEY_TYPE'),
          AppSettings.NOTIFICATIONS_ERROR_OPTIONS
        );
      }

      this.surveyInput = {
        surveyId: id,
      };
    });
  }
}
