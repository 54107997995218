import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orgServiceFilter',
  pure: false,
})
export class OrgServiceFilter implements PipeTransform {
  transform(orgs: any[], services: string[]): any[] {
    if (!orgs) return [];
    if (!services || !services.length) return orgs;

    // Goes through organizations then checks to see if it
    // contains any services in the filter
    return orgs.filter((org) => {
      return services.some((service) => org.serviceList.includes(service));
    });
  }
}
