import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-entire-country-confirm-dialog',
  templateUrl: './entire-country-confirm-dialog.component.html',
  styleUrls: ['./entire-country-confirm-dialog.component.css'],
})
export class EntireCountryConfirmDialogComponent implements OnInit {
  country: String;
  responseObj: RemoveEntireCountryDialogResponse;

  constructor(
    public dialogRef: MatDialogRef<EntireCountryConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.country = this.data['country'];
    this.responseObj = { removePinnedLocations: false, countryIndex: this.data['i'] };
  }

  close(removePins: boolean): void {
    this.responseObj.removePinnedLocations = removePins;
    this.dialogRef.close(this.responseObj);
  }
}

export interface RemoveEntireCountryDialogResponse {
  removePinnedLocations: boolean;
  countryIndex: number;
}
