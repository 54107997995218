import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Collection, DisasterSpecificSurveyInput, SurveyModalActions } from '../../../../../models';
import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';

@Component({
  selector: 'dss-step',
  templateUrl: './dss-step.component.html',
  styleUrls: ['./dss-step.component.css'],
  providers: [],
})
export class DSSStepComponent implements OnInit {
  invalidOnInit: Collection<boolean>;

  protected dSSSurveyData = new BehaviorSubject<DisasterSpecificSurveyInput>(null);

  @Input('survey-data')
  set surveyData(value) {
    this.dSSSurveyData.next(value);
  }

  get surveyData() {
    return this.dSSSurveyData.getValue();
  }

  @Input() totalSteps: number;
  @Input() modalActions: SurveyModalActions = {
    steps: null,
    error: null,
  };
  @Input() isSubmitting: boolean = false;
  @Input() isValid: boolean = false;
  @Input() organizationRespondingToDisaster: boolean;

  @Output() prev: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() selectStep: EventEmitter<number> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();

  constructor(surveyManager: DisasterSpecificSurveyManager) {
    surveyManager.mainForm.valueChanges.subscribe((event) => {
      // Allow user to submit form and ignore errors if they aren't responding anyway
      this.isValid = surveyManager.mainForm.valid || this.organizationRespondingToDisaster;
    });
  }

  ngOnInit() {
    this.dSSSurveyData.subscribe((x) => this.assignDataToForm());
  }

  // This method should be defined by each Step component
  // deriving from this base class
  assignDataToForm(): void {}

  onPrev(): void {
    this.prev.emit(null);
  }

  onNext(): void {
    this.next.emit(null);
  }

  onSelectStep(stepIndex: number): void {
    if (stepIndex !== null && stepIndex !== undefined) {
      this.selectStep.emit(stepIndex);
    }
  }

  onCancel(): void {
    this.cancel.emit(null);
  }

  onSubmit(): void {
    this.submit.emit(null);
  }

  onError(): void {
    this.error.emit(null);
  }
}
