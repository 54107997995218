<div class="card">
  <div class="card-content black-text no-padding">
    <div class="row grey lighten-3">
      <div class="col left valign refresh">
        <a class="sr-link" (click)="loadSurveys()">
          <i class="material-icons loop black-text">loop</i>
        </a>
      </div>
    </div>
    <div class="row grey lighten-3">
      <div class="col s12 m8 l9 card-text-wrapper">
        <p class="no-padding">
          {{ 'DisasterSpecificSurveysTableComponent-INSTRUCTIONS' | translate }}
        </p>
      </div>
      <div class="col s12 m5 l3 right valign-wrapper">
        <div class="input-field search-input-field">
          <input
            [ngModel]="filterArg"
            (ngModelChange)="selectedPage = 0; filterArg = $event"
            class="validate"
            id="search-dss"
            type="text"
          />
          <label class="grey-text" for="search">{{ 'UNIVERSAL-SEARCH' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="progress" *ngIf="isLoading">
      <div class="indeterminate"></div>
    </div>

    <ng-template [ngIf]="!isLoading">
      <table class="grey lighten-3 table-wrapper striped">
        <thead>
          <tr>
            <th *ngIf="authService.isOrgGeneral()"></th>
            <th>
              {{ 'DisasterSpecificSurveysTableComponent-HEADER_DISASTER' | translate }}
            </th>
            <th class="hide-on-small-only">
              {{ 'SurveyTablesCommon-HEADER_LOCATION' | translate }}
            </th>
            <th class="hide">
              {{ 'SurveyTablesCommon-HEADER_LAST_UPDATED' | translate }}
            </th>
            <th class="hide">
              {{ 'SurveyTablesCommon-HEADER_LAST_UPDATED_BY' | translate }}
            </th>
            <th class="hide">
              {{ 'SurveyTablesCommon-HEADER_NEXT_UPDATE' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let dssEntry of disasterList
                | genericSearchFilterPipe: filterArg:['disasterName', 'countryNamesCommaSeparated']
                | paginate: { id: 'dssPagination', itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
            "
          >
            <td *ngIf="authService.isOrgGeneral()">
              <i *ngIf="dssEntry.ownSurvey" class="material-icons"> lock_open </i>
              <i *ngIf="!dssEntry.ownSurvey" class="material-icons"> lock_outline </i>
            </td>
            <td>
              <a class="sr-link" (click)="onDisasterSelect(dssEntry)">
                {{ dssEntry.disasterName }}
              </a>
            </td>

            <td class="hide-on-small-only">
              {{ dssEntry.countryNamesCommaSeparated }}
            </td>
          </tr>
        </tbody>
      </table>

      <pagination-controls
        id="dssPagination"
        class="pagination"
        (pageChange)="selectedPage = $event"
      ></pagination-controls>
    </ng-template>
  </div>
</div>
