import { isNullOrUndefined } from 'util';
import { User } from '../../../models/User';
import { FormControl, FormGroup, FormArray } from '@angular/forms';

export function getNumberFromInput(input: any): number {
  if (isNullOrUndefined(input) || input.length === 0) {
    return null;
  }

  return +input || 0;
}

export function getFloatFromInput(input: any): number {
  // Only keep numbers and periods from input and convert to float
  if (isNullOrUndefined(input) || input.length === 0) {
    return null;
  }
  if (typeof input === 'number') {
    return input;
  }

  return parseFloat(input.toString().replace(/[^\d.]/g, ''));
}

export function isInputFilledNumber(input: any): boolean {
  return input !== undefined && input !== null && input !== '' && !isNaN(Number(input));
}

export function responseToFormElement(survey, key, validators = []): FormElement {
  if (!isNullOrUndefined(survey[key])) {
    return {
      lastUpdated: new Date(survey[key].lastUpdatedDate),
      lastUpdatedUser: survey[key].lastUpdatedUser,
      updatedBySRAdmin: survey[key].updatedBySRAdmin,

      valueControl: new FormControl(survey[key].value, validators),
      markAsUpdatedControl: new FormControl(false),
    };
  }

  return {
    lastUpdated: null,
    lastUpdatedUser: null,
    updatedBySRAdmin: null,

    valueControl: new FormControl(null, validators),
    markAsUpdatedControl: new FormControl(false),
  };
}

/**
 * Adjusts modal content height and positioning based on the
 * header and footer height, in order to support dynamically
 * long header text
 * @param inputModal The modal to adjust
 */
export function adjustModalContent(inputModal: HTMLElement) {
  let modal: HTMLElement;
  let header: HTMLElement;
  let content: HTMLElement;
  let footer: HTMLElement;

  // Unwrap modal child elements if needed (for DSS)
  if (inputModal.childElementCount === 1) {
    modal = inputModal.children[0] as HTMLElement;
  } else {
    modal = inputModal;
  }

  // Find modal child element references
  const childNodes: HTMLElement[] = [];
  for (let i = 0; i < modal.childNodes.length; i = i + 1) {
    childNodes.push(modal.childNodes[i] as HTMLElement);
  }
  childNodes.forEach((node: HTMLElement) => {
    if (node.classList.contains('modal-header')) {
      header = node;
    } else if (node.classList.contains('modal-content')) {
      content = node;
    } else if (node.classList.contains('modal-footer')) {
      footer = node;
    }
  });

  // If elements exist, adjust content height appropriately
  if (header && content && footer) {
    const offsetHeight = header.offsetHeight + footer.offsetHeight;
    const offsetTop = header.offsetHeight;
    content.setAttribute('style', `height: calc(100% - ${offsetHeight}px); top: ${offsetTop}px;`);
  }
}

export interface FormElement {
  lastUpdated: Date;
  lastUpdatedUser: User;
  updatedBySRAdmin: boolean;

  valueControl: FormControl | FormGroup | FormArray;
  markAsUpdatedControl: FormControl;
}

export interface ReportFrequency {
  id: number;
  displayName: string;
}

export const REPORT_SUBMISSION_FREQUENCY: ReportFrequency[] = [
  {
    id: 0,
    displayName: 'Daily',
  },
  {
    id: 1,
    displayName: 'Weekly',
  },
  {
    id: 2,
    displayName: 'Monthly',
  },
  {
    id: 3,
    displayName: 'Quarterly',
  },
  {
    id: 4,
    displayName: 'Annually',
  },
  {
    id: 5,
    displayName: 'Other',
  },
];
