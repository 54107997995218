import { Component, EventEmitter, Input, OnChanges, OnInit, ViewContainerRef } from '@angular/core';
import { Disaster } from '../../../../models';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { DisasterAdminService } from '../disaster.service';
import { NotificationsService } from 'angular2-notifications';
import { AppSettings } from '../../../../common/config';
import { FormControl, Validators } from '@angular/forms';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'dashboardListDisaster',
  templateUrl: './list.component.html',
  styleUrls: ['../../../common-base.css', './list.component.css'],
})
export class DashboardDisasterListComponent implements OnInit, OnChanges {
  constructor(
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    public service: DisasterAdminService,
    private notificationService: NotificationsService
  ) {}

  @Input()
  disasterListType: string;
  disasterViewType: string = 'view';
  disasterEditType: string = 'edit';
  disasters: Disaster[];
  loading: boolean;
  sortDescending: boolean = true;
  sortField: string = 'timestamp';
  selectedPage: number = 0;
  itemsPerPageArg: number = 10;
  filterArg: string;
  priorityForm: FormControl;
  currentDisaster: Disaster;
  modalActions = new EventEmitter<string | MaterializeAction>();
  /* For use in Template */
  AppSettings = AppSettings;

  ngOnInit() {
    this.loadDisasterList(this.disasterListType);
  }

  loadDisasterList(disasterListType: string) {
    if (disasterListType === 'Approved disasters') {
      this.disasterViewType = 'view';
      this.disasterEditType = 'edit';
      this.loaderService.start();
      this.loading = true;
      this.service.getDisasters().subscribe(
        (data) => {
          this.successResponse(data);
        },
        (error) => {
          this.errorResponse(error);
        }
      );
    } else if (disasterListType === 'Nominated disasters') {
      this.disasterViewType = 'viewnominated';
      this.disasterEditType = 'editnominated';
      this.loaderService.start();
      this.loading = true;
      this.service.getNominatedDisasters().subscribe(
        (data) => {
          this.successResponse(data);
        },
        (error) => {
          this.errorResponse(error);
        }
      );
    }
  }

  successResponse(data) {
    this.disasters = data;
    if (data.length > 0) {
      this.disasters.forEach((item) => {
        (<Disaster>item).disasterLocations = item.locations
          .map((country) => country.country.name)
          .filter((element, idx, self) => idx === self.indexOf(element));
      });

      this.currentDisaster = this.disasters[0];
      this.priorityForm = new FormControl(
        this.disasters[0].priority,
        Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)
      );
    } else {
      this.notificationService.alert(
        this.translateService.instant('DISASTER_COMMON-TITLE'),
        this.translateService.instant('DashboardDisasterViewComponent-NO_DISASTERS_TO_LIST')
      );
    }

    this.loaderService.complete();
    this.loading = false;
  }

  errorResponse(error) {
    handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');

    this.loaderService.complete();
    this.loading = false;
  }

  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  onDelete(disasterToDelete: Disaster) {
    if (
      confirm(
        this.translateService.instant('DashboardDisasterBaseComponent-DELETE_DISASTER_CONFIRMATION', {
          disasterName: disasterToDelete.name,
        })
      )
    ) {
      this.loaderService.start();

      this.service.deleteDisaster(disasterToDelete.disasterId).subscribe(
        (response) => {
          this.notificationService.success(
            this.translateService.instant('DISASTER_COMMON-TITLE'),
            this.translateService.instant('DashboardDisasterBaseComponent_SUCCESS_DELETE', {
              disasterName: disasterToDelete.name,
            })
          );

          // remove disasterToDelete from disasters array
          this.disasters = this.disasters.filter((item) => item.disasterId !== disasterToDelete.disasterId);

          // reset select page to an existing page if previously selected page no longer "exists"
          const numPages = Math.ceil(this.disasters.length / this.itemsPerPageArg);
          this.selectedPage = this.selectedPage > numPages ? numPages : this.selectedPage;

          this.loaderService.complete();
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');

          this.loaderService.complete();
        }
      );
    }
  }

  onItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.itemsPerPageArg = +updatedVal;
    this.selectedPage = 0;
  }

  openPriorityModal(disaster: Disaster): void {
    this.currentDisaster = disaster;
    this.priorityForm = new FormControl(
      disaster.priority,
      Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)
    );
    this.modalActions.emit({ action: 'modal', params: ['open'] });
  }

  ngOnChanges(): void {
    this.loadDisasterList(this.disasterListType);
  }
}
