import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkHttp',
})
export class LinkHttpPipe implements PipeTransform {
  /**
   *
   * @param http link
   * @returns append http if there is no protocol
   */
  transform(href: string) {
    if (!href) return href;
    return href.startsWith('http://') || href.startsWith('https://') ? href : 'http://' + href;
  }
}
