import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'unique',
  pure: false,
})
export class UniquePipe implements PipeTransform {
  transform(obj: any, path: string): any {
    return obj != null ? _.uniqBy(obj, path) : obj;
  }
}
