import { Component, EventEmitter, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { handleErrors } from '../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Donation } from '../../../models';
import { getNumberFromInput } from 'app/dashboard/servicesComponets/surveys/survey-helpers';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DonationManagementService } from '../../donationHelper/donation-management.service';
import { MaterializeAction } from 'angular2-materialize';
import { EmailValidator } from '../../../validators/emailValidator';
import { LeadingTrailingWhiteSpaceValidator } from '../../../validators/leadingTrailingWhiteSpaceValidator';
import { AppSettings } from 'app/common/config';

@Component({
  selector: 'donation-modal',
  templateUrl: './donation-modal.component.html',
  styleUrls: ['../../common-search.css', './donation-modal.component.css'],
  providers: [DonationManagementService],
})
export class DonationModalComponent implements OnInit {
  public loading = true;
  isSubmitting: boolean = false;
  donateForm: FormGroup;
  tippingSr: boolean = false;
  @Input() orgName: any;
  @Input() orgId: any;
  @Input() donateUrl: any;
  @Input() donateModalActions: any;
  @Input() tippingModalActions: any;
  constructor(
    //  private orgService: OrganizationService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private globalLoader: SlimLoadingBarService,
    private donationManagementService: DonationManagementService
  ) {}

  ngOnInit() {
    this.globalLoader.start();
    this.loading = true;

    this.donateForm = new FormGroup({
      donatorEmail: new FormControl('', [
        Validators.maxLength(64),
        EmailValidator.mailFormat,
        LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
      ]),
      donationAmount: new FormControl('', Validators.pattern(AppSettings.US_CURRENCY_REGEX_PATTERN)),
      tippingSr: new FormControl('', Validators.required),
    });
  }

  onSubmit(orgId: number, donateUrl: string) {
    if (this.isSubmitting) {
      return;
    }

    this.globalLoader.start();
    this.loading = true;
    this.isSubmitting = true;
    const newRecord: Donation = this.donateForm.value;
    const organizationName = this.orgName;

    newRecord.donatedOrgId = orgId;
    newRecord.donationAmount = getNumberFromInput(this.donateForm.controls['donationAmount'].value);
    this.donationManagementService.addNewDondationRecord(newRecord).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.notificationsService.success(
          this.translateService.instant('Donation-COMMON_TITLE'),
          this.translateService.instant('Donation-SUCCESS_CREATE_MESSAGE', { organizationName })
        );
        this.globalLoader.complete();
        this.loading = false;
        this.donateModalActions.emit({ action: 'modal', params: ['close'] });
        if (this.tippingSr) {
          this.tippingModalActions.emit({ action: 'modal', params: ['open'] });
        }
        this.donateForm.reset();
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationsService, 'Donation-COMMON_TITLE');
        this.isSubmitting = false;
        this.globalLoader.complete();
        this.loading = false;
      }
    );
  }
}
