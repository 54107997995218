<div class="row">
  <div class="col s12">
    <p class="center-align sr-bold volunteer-header">
      {{ 'VolunteerPage-HEADER' | translate }}
    </p>
  </div>

  <div class="col s12 volunteer-info">
    <div class="card">
      <div class="card-content black-text">
        <p class="sr-justify">{{ 'VolunteerPage-INFO_1' | translate }}</p>
        <p class="sr-justify">{{ 'VolunteerPage-INFO_2' | translate }}</p>
        <h6 class="right-align sr-bold sr-italic sr-justify hide-on-small-only">
          {{ 'VolunteerPage-VOLUNTEERING_INFO_LINK' | translate }}
          <a href="https://www.volunteermatch.org/search/org663759.jsp">Click Here</a>
        </h6>
        <h6 class="right-align sr-bold sr-italic sr-justify center hide-on-med-and-up">
          {{ 'VolunteerPage-VOLUNTEERING_INFO_LINK' | translate }}<br /><a
            href="https://www.volunteermatch.org/search/org663759.jsp"
            >Click Here</a
          >
        </h6>
      </div>
    </div>
  </div>

  <div class="col s12 l9">
    <p class="center-align sr-bold sr-italic volunteer-header">
      {{ 'VolunteerPage-VOLUNTEERS-HEADER' | translate }}
    </p>
    <div class="row">
      <div class="volunteer-col col s12 m4" *ngFor="let volunteer of volunteers | async">
        <div class="volunteer-card card grey lighten-4">
          <div class="card-image">
            <img class="activator" src="{{ volunteer.photo }}" />
          </div>
          <div class="card-content">
            <p class="sr-bold">{{ volunteer.name }}<i class="material-icons right activator">message</i></p>
            <p>{{ volunteer.title }}</p>
            <p>{{ volunteer.location }}</p>
          </div>
          <div class="card-action center-align">
            <a class="sr-bold sr-italic" href="{{ volunteer.url }}" target="_blank">{{ volunteer.website }}</a>
          </div>
          <div class="card-reveal">
            <span class="card-title grey-text text-darken-4"
              >{{ volunteer.name }}<i class="material-icons right">close</i></span
            >
            <p>{{ volunteer.biography }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col s12 l3">
    <p class="center sr-bold sr-italic volunteer-header">
      {{ 'VolunteerPage-ADDITIONAL_THANKS_HEADER' | translate }}
    </p>
    <div class="row">
      <div class="col s6 m4 l12" *ngFor="let acknowledgment of acknowledgments | async">
        <div class="logo valign-wrapper center">
          <img class="responsive-img" src="{{ acknowledgment.logo }}" />
        </div>
      </div>
    </div>
  </div>
</div>
