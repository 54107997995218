<ng-template [ngIf]="state?.display">
  <div id="content" class="loader-container">
    <img src="assets/images/transparent-background.png" id="background-image" />

    <div class="preloader-wrapper medium active loader">
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>

    <div class="loader-msg">
      {{ state?.translation_key | translate }}
    </div>
  </div>
</ng-template>
