import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SRResponse } from '../../models';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../common/config';
import { Observable } from 'rxjs';

const ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/survey/lss/organizations/';

@Injectable()
export class DonationCampaignByLocationService {
  constructor(private http: HttpClient) {}

  getDonationCampaignByLocation(locationId: number): Observable<OrgLocationDonationCampaign[]> {
    return this.http.get<SRResponse>(ENDPOINT_URL + locationId).pipe(
      map((resp) => {
        return resp.responseData.lss_table_list.map((item) => {
          return new OrgLocationDonationCampaign(null);
        });
      })
    );
  }
}

export class OrgLocationDonationCampaign {
  constructor(obj?: any) {
    this.organizationId = obj && obj.organizationId;
    this.locationId = obj && obj.countryId;
    this.givingPortalUrl = obj && obj.givingPortalUrl;
    this.campaignName = obj && obj.campaignName;
  }

  organizationId: number;
  locationId: number;
  givingPortalUrl: string;
  campaignName: string;
}
