<div>
  <dashboardUserManagementBase
    [organizationId]="organizationId"
    [GOB_COMPONENT]="true"
    *ngIf="currentState['general_view']"
    (action)="handleAction($event)"
  ></dashboardUserManagementBase>

  <dashboardUserManagementCreate
    *ngIf="currentState['add_user']"
    [GOB_COMPONENT]="true"
    [payload]="payload"
    [organizationId]="organizationId"
    (action)="handleAction($event)"
  ></dashboardUserManagementCreate>

  <dashboardUserManagementEdit
    *ngIf="currentState['edit']"
    [GOB_COMPONENT]="true"
    [payload]="payload"
    (action)="handleAction($event)"
  ></dashboardUserManagementEdit>

  <dashboardViewUserManagement
    *ngIf="currentState['view']"
    [GOB_COMPONENT]="true"
    [payload]="payload"
    (action)="handleAction($event)"
  ></dashboardViewUserManagement>
</div>
