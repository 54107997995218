export interface Language {
  name: string;
  languageCode: string;
}

export const LANGUAGE_CONFIG: Language[] = [
  {
    name: 'English',
    languageCode: 'en',
  },
  {
    name: 'Arabic - اﻟﻌﺮﺑﻴﺔ',
    languageCode: 'ar',
  },
  /*{
   name: "Farsi - فارسی",
   languageCode: "fa"
  },
  {
    name: "Japanese - 日本語",
    languageCode: "ja"
  }, */
  {
    name: 'French - Français',
    languageCode: 'fr',
  },
  {
    name: 'Spanish - Español',
    languageCode: 'es',
  },
  {
    name: 'Bahasa Indonesia',
    languageCode: 'id',
  },
  {
    name: 'Turkish',
    languageCode: 'tr',
  },
  {
    name: 'Ukrainian - Українська',
    languageCode: 'uk',
  },
  {
    name: 'Portuguese - Português',
    languageCode: 'pt',
  },

  /*	{
   name: "Sinhala - සිංහල",
   languageCode: "si"
   },
   {
   name: "Tamil - தமிழ்",
   languageCode: "ta"
   },*/
];
