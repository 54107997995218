<ul class="collection">
  <li class="collection-item grey lighten-3">
    <a class="modal-trigger" href="" (click)="onStepClick(0, $event)">
      {{
        'DisasterSpecificSurveyComponent-STEP_1_TITLE'
          | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
      }}
    </a>
  </li>

  <li class="collection-item grey lighten-3">
    <a class="modal-trigger" href="" (click)="onStepClick(1, $event)">
      {{
        'DisasterSpecificSurveyComponent-STEP_2_TITLE'
          | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
      }}
    </a>
  </li>

  <li class="collection-item grey lighten-3">
    <a class="modal-trigger" href="" (click)="onStepClick(2, $event)">
      {{
        'DisasterSpecificSurveyComponent-STEP_3_TITLE'
          | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
      }}
    </a>
  </li>

  <li class="collection-item grey lighten-3">
    <a class="modal-trigger" href="" (click)="onStepClick(3, $event)">
      {{
        'DisasterSpecificSurveyComponent-STEP_4_TITLE'
          | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
      }}
    </a>
  </li>

  <li class="collection-item grey lighten-3">
    <a class="modal-trigger" href="" (click)="onStepClick(4, $event)">
      {{
        'DisasterSpecificSurveyComponent-STEP_5_TITLE'
          | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
      }}
    </a>
  </li>

  <li class="collection-item grey lighten-3">
    <a class="modal-trigger" href="" (click)="onStepClick(5, $event)">
      {{
        'DisasterSpecificSurveyComponent-STEP_6_TITLE'
          | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
      }}
    </a>
  </li>

  <li class="collection-item grey lighten-3">
    <a class="modal-trigger" href="" (click)="onStepClick(6, $event)">
      {{
        'DisasterSpecificSurveyComponent-STEP_7_TITLE'
          | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
      }}
    </a>
  </li>
</ul>
