import { Component, EventEmitter, HostListener } from '@angular/core';
import { ReportInputModel, ReportSurveyTypes } from '../searchResults/reportModal/report.model';

const AVAILABLE_IMAGES = [
  'assets/images/front-page-carousel/1.jpg',
  'assets/images/front-page-carousel/2.jpg',
  'assets/images/front-page-carousel/3.jpg',
];

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  public reportActions: EventEmitter<ReportInputModel> = new EventEmitter<ReportInputModel>();
  public exampleReport: ReportInputModel;

  public slides: Object[];
  public resolution: string;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    if (window.innerWidth >= 769 && window.innerWidth <= 1200) {
      this.resolution = 'laptop';
    } else if (window.innerWidth >= 601 && window.innerWidth <= 768) {
      this.resolution = 'tablet';
    } else if (window.innerWidth >= 0 && window.innerWidth <= 600) {
      this.resolution = 'mobile';
    } else {
      this.resolution = 'desktop';
    }

    this.slides = [
      { src: `assets/images/carousel/${this.resolution}/1.jpg` },
      { src: `assets/images/carousel/${this.resolution}/2.jpg` },
      { src: `assets/images/carousel/${this.resolution}/3.jpg` },
    ];
  }

  constructor() {
    this.exampleReport = {
      orgName: 'Smart Response',
      surveyType: ReportSurveyTypes.GOB,
      surveyId: 1,
    };
    this.getScreenSize();
  }
}
