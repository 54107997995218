import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../../common/config';
import { LocationAndContact } from '../../../../models/LocationAndContact';
import { AuthenticationService } from '../../../../login/login.service';
import { contentHeaders } from '../../../../common/headers';
import { GeneralOrganizationBackgroundSurveyService } from '../generalOrganizationBackground/general-organization-background.service';
import { HttpClient } from '@angular/common/http';

const ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/admin/organizations/';

const MOCK_DATA = () => [
  {
    organizationId: 42,
    countryId: 1,
    countryName: 'United States',
    lastUpdated: 42,
    nextUpdateDue: 42,
    user1: {
      userId: 42,
      email: 'local_org_admin@smartresponse.org',
      firstName: 'George',
      lastName: 'Harrison',
    },
    user2: {
      userId: 2,
      email: 'local_org_admin@smartresponse.org',
      firstName: 'Ringo',
      lastName: 'Starr',
    },
  },
  {
    organizationId: 42,
    countryId: 3,
    countryName: 'Albania',
    lastUpdated: 42,
    nextUpdateDue: 42,
    user1: {
      userId: 42,
      email: 'local_org_admin@smartresponse.org',
      firstName: 'John',
      lastName: 'Lennon',
    },
  },
  {
    organizationId: 42,
    countryId: 4,
    countryName: 'Algeria',
    lastUpdated: 42,
    nextUpdateDue: 42,
    user1: {
      userId: 42,
      email: 'local_org_admin@smartresponse.org',
      firstName: 'Paul',
      lastName: 'McCartney',
    },
  },
];

@Injectable()
export class LocationSpecificTableService {
  constructor(
    private http: HttpClient,
    public authService: AuthenticationService,
    private gobService: GeneralOrganizationBackgroundSurveyService
  ) {}

  getLocationsAndContacts(organizationId: number): Observable<LocationAndContact[]> {
    return this.gobService.getMostRecentSurvey(organizationId).pipe(
      map((res) => {
        if (res.locationContactList) {
          return res.locationContactList.map((val) => val.value);
        }

        return [];
      })
    );
  }
}
