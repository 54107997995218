import { AbstractControl, ValidationErrors } from '@angular/forms';

export class OnlyWhiteSpaceValidator {
  static onlyWhiteSpaceFormat(control: AbstractControl): ValidationErrors | null {
    const isSpace = /^\s+$/.test(control.value);
    if ((control.value as string) && isSpace) {
      return { isOnlyWhiteSpace: true };
    }
    return null;
  }
}
