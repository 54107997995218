import { Component, OnInit } from '@angular/core';
import { VolunteersPageService } from './volunteersPage.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'volunteers',
  templateUrl: './volunteersPage.component.html',
  styleUrls: ['./volunteersPage.component.css'],
  providers: [VolunteersPageService],
})
export class VolunteersPageComponent implements OnInit {
  volunteers: Observable<object[]>;
  acknowledgments: Observable<object[]>;

  constructor(public service: VolunteersPageService) {}

  ngOnInit() {
    this.volunteers = this.service.getVolunteers();
    this.acknowledgments = this.service.getAcknowledgments();
  }
}
