import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { ActivatedRoute, DefaultUrlSerializer } from '@angular/router';
import { AppSettings } from '../../../common/config';
import { UserApprovalService } from './user-approval.service';
import { handleErrors } from '../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { userTypeToName, UserApprove } from '../../../models';
import { Observable } from 'rxjs';

@Component({
  selector: 'user-approval',
  templateUrl: './user-approval.component.html',
  styleUrls: ['../../common-card-content-title.css', '../../common-base.css', './user-approval.component.css'],
  providers: [UserApprovalService],
})
export class DashboardUserApprovalComponent implements OnInit {
  salesForceCode: string;

  isRetrieving: boolean = false;

  users: UserApprove[];

  filterArg: string;

  itemsPerPageArg: number = 10;
  selectedPage: number = 0;
  sortField: string = 'registerDate';
  sortDescending: boolean = true;

  AppSettings = AppSettings;

  constructor(
    private userApprovalService: UserApprovalService,
    private translateService: TranslateService,
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        const urlTree = new DefaultUrlSerializer().parse(this.location.path(true).replace('#', '?'));
        const sfAccessToken = urlTree.queryParams['access_token'];
        const instanceUrl = decodeURIComponent(urlTree.queryParams['instance_url']);

        this.location.go('dashboard');

        if (sfAccessToken) {
          if (localStorage.approvedUser) {
            this.userApprovalService
              .addUsertoSalesForce(
                JSON.parse(localStorage.approvedUser),
                sfAccessToken,
                `${instanceUrl}/services/data/v56.0/sobjects/Contact`
              )
              .subscribe(
                (resp) => {
                  if (resp.success) {
                    localStorage.removeItem('approvedUser');
                    this.notificationService.success(
                      this.translateService.instant('DashboardUserApprovalComponent-TABLE_TITLE'),
                      this.translateService.instant('DashboardUserApprovalComponent-TOAST_APPROVE-SALESFORCE')
                    );
                  } else {
                    this.notificationService.error(
                      this.translateService.instant('DashboardUserApprovalComponent-TABLE_TITLE'),
                      this.translateService.instant('DashboardUserApprovalComponent-ERROR-CONTACT-APPROVE-SALESFORCE')
                    );
                  }
                },
                (error) => {
                  handleErrors(
                    error,
                    this.translateService,
                    this.notificationService,
                    'DashboardUserApprovalComponent-TABLE_TITLE'
                  );
                }
              );
          }
        }
      }
    }),
      this.update();
  }

  update(): void {
    if (this.isRetrieving) {
      return;
    }

    this.isRetrieving = true;

    this.users = null;

    this.userApprovalService.getUsers().subscribe(
      (data) => {
        this.users = data;

        this.users.forEach((val) => {
          val.isBeingDismissed = false;

          (<any>val).userTypeName = userTypeToName[val.userType];
          val.organizationName = val.organization ? val.organization.name : '';
        });

        this.isRetrieving = false;
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationService,
          'DashboardUserApprovalComponent-TABLE_TITLE'
        );

        this.isRetrieving = false;
      }
    );
  }

  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  approveUser(approvedUser: UserApprove) {
    if (approvedUser.isBeingDismissed) {
      return;
    }

    if (
      !confirm(
        this.translateService.instant('DashboardUserApprovalComponent-APPROVE_CONFIRMATION', {
          firstName: approvedUser.firstName,
          lastName: approvedUser.lastName,
        })
      )
    ) {
      return;
    }

    localStorage.approvedUser = JSON.stringify(approvedUser);

    this.userApprovalService.approve(approvedUser.email).subscribe(
      (response) => {
        this.notificationService.success(
          this.translateService.instant('DashboardUserApprovalComponent-TABLE_TITLE'),
          this.translateService.instant('DashboardUserApprovalComponent-TOAST_APPROVE', {
            firstName: approvedUser.firstName,
            lastName: approvedUser.lastName,
          })
        );

        this.removeUser(approvedUser);

        approvedUser.isBeingDismissed = false;
        setTimeout(this.addUserToSalesForce, 3000);
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationService,
          'DashboardUserApprovalComponent-TABLE_TITLE'
        );

        approvedUser.isBeingDismissed = false;
      }
    );
  }

  addUserToSalesForce() {
    window.location.assign(
      `${AppSettings.SALESFORCE_AUTHORIZE_URL}?response_type=token&` +
        `client_id=${AppSettings.SALESFORCE_CLIENT_ID}&redirect_uri=${encodeURIComponent(location.href)}`
    );
  }

  rejectUser(rejectedUser: UserApprove) {
    if (rejectedUser.isBeingDismissed) {
      return;
    }

    if (
      !confirm(
        this.translateService.instant('DashboardUserApprovalComponent-REJECT_CONFIRMATION', {
          firstName: rejectedUser.firstName,
          lastName: rejectedUser.lastName,
        })
      )
    ) {
      return;
    }
    rejectedUser.isBeingDismissed = true;

    this.userApprovalService.delete(rejectedUser.email, true).subscribe(
      (response) => {
        this.notificationService.success(
          this.translateService.instant('DashboardUserApprovalComponent-TABLE_TITLE'),
          this.translateService.instant('DashboardUserApprovalComponent-TOAST_REJECT', {
            firstName: rejectedUser.firstName,
            lastName: rejectedUser.lastName,
          })
        );

        this.removeUser(rejectedUser);

        rejectedUser.isBeingDismissed = false;
      },

      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationService,
          'DashboardUserApprovalComponent-TABLE_TITLE'
        );

        rejectedUser.isBeingDismissed = false;
      }
    );
  }

  removeUser(user: UserApprove) {
    this.users = this.users.filter((iterUser: UserApprove) => iterUser.userId !== user.userId);

    // reset select page to an existing page if previously selected page no longer "exists"
    const numPages = Math.ceil(this.users.length / this.itemsPerPageArg);
    this.selectedPage = Math.min(this.selectedPage, numPages);
  }
  deleteUser(deletedUser: UserApprove) {
    if (deletedUser.isBeingDismissed) {
      return;
    }

    if (
      !confirm(
        this.translateService.instant('DashboardUserApprovalComponent-DELETE_CONFIRMATION', {
          firstName: deletedUser.firstName,
          lastName: deletedUser.lastName,
        })
      )
    ) {
      return;
    }
    deletedUser.isBeingDismissed = true;

    this.userApprovalService.delete(deletedUser.email, false).subscribe(
      (response) => {
        this.notificationService.success(
          this.translateService.instant('DashboardUserApprovalComponent-TABLE_TITLE'),
          this.translateService.instant('DashboardUserApprovalComponent-TOAST_DELETE', {
            firstName: deletedUser.firstName,
            lastName: deletedUser.lastName,
          })
        );

        this.removeUser(deletedUser);

        deletedUser.isBeingDismissed = false;
      },

      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationService,
          'DashboardUserApprovalComponent-TABLE_TITLE'
        );

        deletedUser.isBeingDismissed = false;
      }
    );
  }
}
