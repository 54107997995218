import { Component } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from '../../../common/config';
import { BasicDateValidator } from '../../../validators/basicDateValidator';
import { YearDateValidator } from '../../../validators/yearDateValidator';
import { emptySpaceValidator } from '../../../validators/emptySpaceValidator';

@Component({
  selector: 'dashboardDisaster',
  templateUrl: './disaster.component.html',
  styleUrls: ['./disaster.component.css'],
  providers: [],
})
export class DashboardDisasterComponent {
  constructor() {}
}

export function checkLocationrequiredIfDisasterNotGlobal(formGroup: FormGroup) {
  // if global flag is false, then at least one location is required
  if (!formGroup.get('global').value as boolean) {
    const formArray = formGroup.get('countryLocations') as FormArray;
    const valid = formArray.controls
      .map((control) => control.value.country)
      .reduce((left, right) => left || right, false);

    return valid ? null : { missingLocation: true };
  }
}

export function getDisasterForm(fb) {
  return fb.group(
    {
      name: [null, [Validators.required, Validators.maxLength(64), emptySpaceValidator()]],
      summary: [null, [Validators.required, Validators.maxLength(1024), emptySpaceValidator()]],
      disasterType: [null, Validators.required],
      dateOfDisaster: [
        null,
        [
          Validators.required,
          BasicDateValidator.dateFormat,
          BasicDateValidator.pastDate,
          YearDateValidator.minimumYear,
        ],
      ],
      logo: [null],
      notificationTrack: [null, Validators.required],
      priority: [null, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)],
      disasterHashtag: [null, Validators.maxLength(128)],
      metadata: [null, Validators.maxLength(40)],
      servicesProvided: [null],
      displayDss: [null],
      global: [false, Validators.required],
      inactive: [null],
      locations: fb.array([fb.group({ country: [''], geoLat: [''], geoLon: [''], radius: [''], radiusUnits: [''] })]),
      countryLocations: fb.array([
        fb.group({
          country: [null],
          entireCountry: [null],
          locations: fb.array([
            fb.group({
              country: [null],
              geoLat: [null],
              geoLon: [null],
              radius: [null],
              radiusUnits: [null],
            }),
          ]),
        }),
      ]),
    },
    { validator: checkLocationrequiredIfDisasterNotGlobal }
  );
}

export function getNominationDisasterForm(fb) {
  return fb.group(
    {
      name: [null, [Validators.required, Validators.maxLength(64), emptySpaceValidator()]],
      summary: [null, [Validators.required, Validators.maxLength(1024), emptySpaceValidator()]],
      disasterType: [null, Validators.required],
      dateOfDisaster: [
        null,
        [
          Validators.required,
          BasicDateValidator.dateFormat,
          BasicDateValidator.pastDate,
          YearDateValidator.minimumYear,
        ],
      ],
      logo: [null],
      global: [false, Validators.required],
      inactive: [null],
      locations: fb.array([fb.group({ country: [''], geoLat: [''], geoLon: [''], radius: [''], radiusUnits: [''] })]),
      countryLocations: fb.array([
        fb.group({
          country: [null],
          entireCountry: [false],
          locations: fb.array([
            fb.group({
              country: [null],
              geoLat: [null],
              geoLon: [null],
              radius: [null],
              radiusUnits: [null],
            }),
          ]),
        }),
      ]),
    },
    { validator: checkLocationrequiredIfDisasterNotGlobal }
  );
}
