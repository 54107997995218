import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicesProvidedService } from '../service.service';
import { Service } from '../../../../models/Service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppSettings } from '../../../../common/config';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'dashboardEditService',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css', '../../../common-edit.css'],
  providers: [],
})
export class DashboardServiceEditComponent implements OnInit {
  isSubmitting: boolean = false;

  editServiceForm: FormGroup;

  logoFile: File;

  serviceId: number;
  currentService: Service;

  AppSettings = AppSettings;

  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private servicesProvided: ServicesProvidedService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = +params['id'];
      this.loaderService.start();
      this.loading = true;
      this.servicesProvided.getService(id).subscribe(
        (data) => {
          this.currentService = data;
          this.serviceId = data.serviceId;

          this.loaderService.complete();
          this.loading = false;

          this.editServiceForm = new FormGroup({
            removeLogo: new FormControl(false),
            name: new FormControl(data.name, Validators.required),
            summary: new FormControl(data.summary, Validators.required),
          });
        },
        (error) => {
          this.loaderService.complete();
          this.loading = false;

          handleErrors(error, this.translateService, this.notificationService, 'SERVICE_COMMON_TITLE');
        }
      );
    });
  }

  onLogoSelected(event: any) {
    const targetFile = event.target.files[0];

    if (undefined === targetFile) {
      this.logoFile = null;
    }

    if (!(targetFile instanceof File)) {
      return;
    }

    if (targetFile.size > AppSettings.MAXIMUM_IMAGE_UPLOAD_SIZE) {
      this.notificationService.error(
        this.translateService.instant('DashboardServiceCreateComponent-ADD_SERVICE_TITLE'),
        this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    if (AppSettings.DEFAULT_ALLOWED_IMAGE_TYPES.indexOf(targetFile.type) === -1) {
      this.notificationService.error(
        this.translateService.instant('DashboardServiceCreateComponent-ADD_SERVICE_TITLE'),
        this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    this.logoFile = targetFile;
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.loaderService.start();

    const updatedService: Service = {
      serviceId: this.serviceId,
      name: this.editServiceForm.value.name,
      summary: this.editServiceForm.value.summary,
      logo: this.logoFile,
      removeLogo: this.editServiceForm.value.removeLogo,
    };

    this.servicesProvided.updateService(updatedService).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.loaderService.complete();

        this.notificationService.success(
          this.translateService.instant('SERVICE_COMMON_TITLE'),
          this.translateService.instant('DashboardServiceEditComponent-SUCCESS_EDIT_MESSAGE', {
            serviceName: updatedService.name,
          })
        );

        this.router.navigate(['dashboard/services']);
      },
      (error) => {
        this.isSubmitting = false;
        this.loaderService.complete();

        handleErrors(error, this.translateService, this.notificationService, 'SERVICE_COMMON_TITLE');
      }
    );
  }

  onCancel() {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      this.router.navigate(['dashboard/services']);
    }
  }

  doNothing() {}
}
