<div class="container">
  <div class="col s12 md10 l10 offset-l1 card-panel hoverable card">
    <form ngNoForm Method="Post" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8">
      <div class="col s12 m12">
        <input type="hidden" name="oid" value="00Do0000000HJu5" />
        <input type="hidden" name="retURL" [value]="retValue" />
        <!-- <input type="hidden" name="debug" value="1"> -->

        <div class="card-content black-text">
          <div class="row user-title">
            <div class="input-field col s12 center">
              <h5>{{ 'SUBSCRIBE-COMPONENT-TITLE' | translate }}</h5>
            </div>
          </div>

          <div class="sr-required-notif padded">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>

          <!-- First Name field -->
          <div class="row">
            <div
              class="input-field col s12 m12"
              [ngClass]="{
                'sr-has-error':
                  !subscribeForm.controls['first_name'].valid && subscribeForm.controls['first_name'].touched
              }"
            >
              <i class="material-icons prefix">face</i>
              <input
                id="first_name"
                name="first_name"
                type="text"
                materialize="characterCounter"
                length="40"
                maxlength="40"
                [formControl]="subscribeForm.controls['first_name']"
              />
              <label for="first_name">
                {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
                <span class="sr-required-mark">*</span>
              </label>
              <div
                class="sr-error-area sr-padding-required-text"
                *ngIf="!subscribeForm.controls['first_name'].valid && subscribeForm.controls['first_name'].touched"
              >
                <div *ngIf="subscribeForm.controls['first_name'].errors.required">
                  {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                </div>
                <div *ngIf="subscribeForm.controls['first_name'].value.length > 0">
                  <div *ngIf="subscribeForm.controls['first_name'].hasError('maxlength')">
                    {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
                  </div>
                  <div *ngIf="subscribeForm.controls['first_name'].hasError('leadingTrailingWhiteSpaceFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
                  </div>
                  <div *ngIf="subscribeForm.controls['first_name'].hasError('nameFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NAME' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Last Name field -->
          <div class="row">
            <div
              class="input-field col s12 m12"
              [ngClass]="{
                'sr-has-error':
                  !subscribeForm.controls['last_name'].valid && subscribeForm.controls['last_name'].touched
              }"
            >
              <i class="material-icons prefix">face</i>
              <input
                id="last_name"
                type="text"
                name="last_name"
                materialize="characterCounter"
                length="80"
                maxlength="80"
                [formControl]="subscribeForm.controls['last_name']"
              />
              <label for="last_name">
                {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
                <span class="sr-required-mark">*</span>
              </label>
              <div
                class="sr-error-area"
                *ngIf="!subscribeForm.controls['last_name'].valid && subscribeForm.controls['last_name'].touched"
              >
                <div *ngIf="subscribeForm.controls['last_name'].errors.required">
                  {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                </div>
                <div *ngIf="subscribeForm.controls['last_name'].value.length > 0">
                  <div *ngIf="subscribeForm.controls['last_name'].hasError('maxlength')">
                    {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
                  </div>
                  <div *ngIf="subscribeForm.controls['last_name'].hasError('leadingTrailingWhiteSpaceFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
                  </div>
                  <div *ngIf="subscribeForm.controls['last_name'].hasError('nameFormat')">
                    {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NAME' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Email field -->
          <div class="row">
            <div
              class="input-field col s12 m12"
              [ngClass]="{
                'sr-has-error':
                  !subscribeForm.controls['email'].valid &&
                  (subscribeForm.controls['email'].touched || subscribeForm.controls['email'].value.length)
              }"
            >
              <i class="material-icons prefix">email</i>
              <input
                id="email"
                name="email"
                type="text"
                materialize="characterCounter"
                length="80"
                maxlength="80"
                [formControl]="subscribeForm.controls['email']"
              />
              <label for="email">
                {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
                <span class="sr-required-mark">*</span>
              </label>
              <div
                class="sr-error-area"
                *ngIf="
                  !subscribeForm.controls['email'].valid &&
                  (subscribeForm.controls['email'].touched || subscribeForm.controls['email'].value.length)
                "
              >
                <div *ngIf="subscribeForm.controls['email'].errors.required">
                  {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                </div>
                <div *ngIf="subscribeForm.controls['email'].errors.mailFormat">
                  {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
                </div>
              </div>
            </div>
          </div>

          <!-- Organization field -->
          <div class="row">
            <div class="input-field col s12 m12">
              <i class="material-icons prefix">business</i>
              <input
                id="company"
                name="company"
                type="text"
                materialize="characterCounter"
                length="40"
                maxlength="40"
                [formControl]="subscribeForm.controls['company']"
              />
              <label for="company">
                {{ 'SUBSCRIBE-COMPONENT-ORG-LABEL' | translate }}
              </label>
            </div>
          </div>

          <!-- Address fields-->

          <!-- Street -->
          <div class="row">
            <div class="input-field col s12 m12">
              <i class="material-icons prefix">home</i>
              <input
                id="street"
                name="street"
                type="text"
                materialize="characterCounter"
                length="64"
                maxlength="64"
                [formControl]="subscribeForm.controls['street']"
              />
              <label for="street">
                {{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 1 } }}
              </label>
            </div>
          </div>

          <!-- City -->
          <div class="row">
            <div class="input-field col s12 m12">
              <i class="material-icons prefix">location_city</i>
              <input
                id="city"
                name="city"
                type="text"
                materialize="characterCounter"
                length="40"
                maxlength="40"
                [formControl]="subscribeForm.controls['city']"
              />
              <label for="city">
                {{ 'UNIVERSAL-ADDRESS_CITY' | translate }}
              </label>
            </div>
          </div>

          <!-- State/Province -->
          <div class="row">
            <div class="input-field col s12 m12">
              <i class="material-icons prefix">location_city</i>
              <input
                id="state"
                name="state"
                type="text"
                materialize="characterCounter"
                length="20"
                maxlength="20"
                [formControl]="subscribeForm.controls['state']"
              />
              <label for="state">
                {{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}
              </label>
            </div>
          </div>

          <!-- Zip/Postal code -->
          <div class="row">
            <div class="input-field col s12 m12">
              <i class="material-icons prefix">room</i>
              <input
                id="zip"
                name="zip"
                type="text"
                materialize="characterCounter"
                length="20"
                maxlength="20"
                [formControl]="subscribeForm.controls['zip']"
              />
              <label for="zip">
                {{ 'UNIVERSAL-ADDRESS_POSTAL_ZIPCODE' | translate }}
              </label>
            </div>
          </div>

          <!-- country -->
          <div class="row">
            <div class="col m2 s12 label-text">
              {{ 'UNIVERSAL-ADDRESS_COUNTRY' | translate }}
            </div>
            <select
              name="country"
              id="cuntry"
              class="browser-default col m6 s12"
              [formControl]="subscribeForm.controls['country']"
            >
              <option [ngValue]="null">
                {{ 'SurveyCommon-NO_COUNTRY_SELECTED' | translate }}
              </option>
              <option *ngFor="let country of countries" [value]="country.name">
                {{ country.name }}
              </option>
            </select>
          </div>

          <!-- buttons -->
          <div class="col s12 m12">
            <div class="input-field center">
              <button type="submit" class="btn waves-effect waves-light cyan" [disabled]="!subscribeForm.valid">
                {{ 'SUBSCRIBE-COMPONENT-SUBMIT-BTN' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
