import { Component, Input, EventEmitter } from '@angular/core';
import { BlockingLoaderState } from './blocking-loader.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'blocking-loader',
  templateUrl: './blocking-loader.component.html',
  styleUrls: ['./blocking-loader.component.css'],
})
export class BlockingLoaderComponent {
  private subscription: Subscription;

  public state: BlockingLoaderState;

  @Input()
  set blockingLoaderActions(emitter: EventEmitter<BlockingLoaderState>) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = emitter.subscribe((event: BlockingLoaderState) => {
      this.state = event;
    });
  }

  constructor() {}
}
