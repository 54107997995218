import { pairwise } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from './common/config';
import { LANGUAGE_CONFIG } from './header/languagePicker/language.model';
import { Subscription } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { GlobalLoaderFacade } from './sharedServices/globalLoaderFacade/global-loader-facade.service';
import { environment } from '../environments/environment';

declare let window: {
  dataLayer: any;
  scrollTo: (...args) => void;
};

declare let dataLayer: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  public options = AppSettings.NOTIFICATIONS_DEFAULT_OPTIONS;

  private subscriptions: Subscription[] = [];

  sendMessageModalActions: EventEmitter<string | MaterializeAction>;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private loaderService: GlobalLoaderFacade
  ) {}

  ngOnInit() {
    this.enableGoogleAnalytics();

    const browserLang = this.translateService.getBrowserLang();

    this.translateService.setDefaultLang('en');

    this.sendMessageModalActions = new EventEmitter<string | MaterializeAction>();

    if (LANGUAGE_CONFIG.findIndex((item) => item.languageCode === browserLang) >= 0) {
      this.translateService.use(browserLang);
    } else {
      this.translateService.use('en');
    }

    this.subscriptions.push(
      // save or restore scroll position on route change
      this.router.events.pipe(pairwise()).subscribe(([prevRouteEvent, currRouteEvent]) => {
        if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
          this.loaderService.start();
        }
        if (currRouteEvent instanceof NavigationEnd) {
          window.scrollTo(0, 0);
          this.loaderService.complete();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  enableGoogleAnalytics() {
    const gaScript = document.createElement('script');
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.ga_config}`;
    gaScript.async = true;
    document.getElementsByTagName('head')[0].appendChild(gaScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(...args) {
      args && dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', environment.ga_config);
  }
}
