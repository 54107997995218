<div class="col s12">
  <app-loader [loading]="loading"></app-loader>
  <div class="card">
    <div class="card-content black-text">
      <ng-template [ngIf]="editServiceForm">
        <div class="card-title sr-bold">
          {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
          <span class="sr-italic">{{ currentService.name }}</span>
          {{ 'UNIVERSAL-INFORMATION_TEXT' | translate }}
        </div>
        <div class="divider"></div>
        <div class="sr-required-notif">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>
        <br />

        <div *ngIf="currentService.file" class="row margin">
          <p>
            {{ 'SERVICE_COMMON-CURRENT_LOGO' | translate }}
          </p>

          <img src="{{ currentService.file.uri }}" />
        </div>

        <!-- Logo field -->
        <div class="row margin">
          <div class="input-field col s12">
            <div class="grey-text">{{ 'SERVICE_COMMON-LOGO_LABEL' | translate }}</div>
            <div class="file-field input-field">
              <div class="btn cyan">
                <span>{{ 'UNIVERSAL-FILE' | translate }}</span>
                <input
                  id="serviceLogo"
                  type="file"
                  class="col s12"
                  (change)="onLogoSelected($event)"
                  [disabled]="editServiceForm.controls['removeLogo'].value"
                />
              </div>
              <div class="file-path-wrapper">
                <input class="file-path validate" type="text" />
              </div>
            </div>
            <p class="sr-img-guidelines">
              {{ 'LogoUploadComponent-MAXIMUM_IMAGE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

              {{ 'LogoUploadComponent-RESIZE-IMG-WARNING' | translate }}<br />

              {{ 'LogoUploadComponent-DEFAULT_ALLOWED_IMAGE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
            </p>
          </div>
        </div>

        <!-- Remove Logo field -->
        <!-- this field will only be shown if there is an existing logo *and* no selected logo -->
        <div *ngIf="!logoFile && currentService.fileURI" class="row margin">
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !editServiceForm.controls['removeLogo'].valid && editServiceForm.controls['removeLogo'].touched
            }"
          >
            <input
              id="removeLogo"
              type="checkbox"
              [formControl]="editServiceForm.controls['removeLogo']"
              (click)="doNothing()"
            />
            <label for="removeLogo" class="active black-text">
              {{ 'SERVICE_COMMON-DELETE_CURRENT_LOGO' | translate }}
              <span class="sr-required-mark">*</span>
            </label>
          </div>
        </div>

        <!-- Name field -->
        <div class="row margin">
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error': !editServiceForm.controls['name'].valid && editServiceForm.controls['name'].touched
            }"
          >
            <input id="name" type="text" [formControl]="editServiceForm.controls['name']" />
            <label for="name" class="active black-text"
              >{{ 'SERVICE_COMMON-NAME_LABEL' | translate }} <span class="sr-required-mark">*</span></label
            >
            <div
              class="sr-error-area"
              *ngIf="!editServiceForm.controls['name'].valid && editServiceForm.controls['name'].touched"
            >
              <div *ngIf="editServiceForm.controls['name'].errors.required">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>

        <!-- Summary field -->
        <div class="row margin">
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error': !editServiceForm.controls['summary'].valid && editServiceForm.controls['summary'].touched
            }"
          >
            <textarea
              id="summary"
              class="materialize-textarea"
              [formControl]="editServiceForm.controls['summary']"
            ></textarea>
            <label for="summary" class="active black-text"
              >{{ 'SERVICE_COMMON-SUMMARY_LABEL' | translate }} <span class="sr-required-mark">*</span></label
            >
            <div
              class="sr-error-area"
              *ngIf="!editServiceForm.controls['summary'].valid && editServiceForm.controls['summary'].touched"
            >
              <div *ngIf="editServiceForm.controls['summary'].errors.required">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row margin">
          <div class="col s12">
            <div class="my_btns right">
              <button
                class="btn waves-effect waves-light cyan"
                [disabled]="isSubmitting || !editServiceForm.valid"
                [ngClass]="{ disabled: !editServiceForm.valid }"
                (click)="onSubmit()"
              >
                {{ 'UNIVERSAL-NAVIGATION_SAVE' | translate }}
              </button>
              <a class="btn cyan" (click)="onCancel()">
                {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
