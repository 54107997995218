<br />
<div class="card">
  <div class="card-content black-text">
    <div class="card-title center white-text cyan title">
      List Editor {{ config && ' | ' + (config.listName | translate) }}
    </div>
    <div class="card-text-wrapper grey lighten-3">
      <div *ngIf="!config || !items || isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>
      <div class="clearfix" *ngIf="config && items">
        <table id="list" class="grey lighten-3 table-wrapper striped sr-custom-table-row">
          <thead>
            <tr>
              <th>{{ config?.listItemName | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of items">
              <td>
                {{ item }}
              </td>
            </tr>

            <tr *ngFor="let extraItem of extraItems.controls; let isLast = last">
              <td>
                <input
                  [formControl]="extraItem"
                  type="text"
                  placeholder="{{ config?.placeholderText | translate }}"
                  (input)="isLast && addExtraItem()"
                />

                <ng-template [ngIf]="!extraItem.valid">
                  {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: VALIDATION_MAX_LENGTH } }}
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="input-field right">
          <button [disabled]="isSubmitting" (click)="onClearChanges()" class="waves-effect waves-light btn cyan btns">
            <div class="cancels">
              {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
            </div>
          </button>

          <button
            [disabled]="isSubmitting || !isFormSubmittable"
            (click)="onSubmit()"
            class="waves-effect waves-light btn cyan btns"
          >
            <div class="saves">
              {{ 'UNIVERSAL-NAVIGATION_SAVE' | translate }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
