import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { TranslateModule } from '@ngx-translate/core';

import { LocationMapComponent } from './location-map.component';
import { SharedServicesFeatureModule } from '../../sharedServices/shared-services.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCsG1DwuyKnJYGoLJ-EKCgsyxGK-ghDfBk',
    }),
    SharedServicesFeatureModule,
  ],
  exports: [LocationMapComponent, AgmCoreModule],
  declarations: [LocationMapComponent],
  providers: [GoogleMapsAPIWrapper],
})
export class LocationMapModule {}
