import { Component, EventEmitter, Input } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { OrgDisasterScore } from '../../../models/OrganizationScore';

@Component({
  selector: 'score-by-disaster',
  templateUrl: './score-by-disaster.component.html',
  styleUrls: ['../score-modal.css', '../../common-search.css'],
})
export class ScoreByDisasterComponent {
  sortingActions = new EventEmitter<string | MaterializeAction>();
  private subscription: Subscription;

  public score: OrgDisasterScore;
  public totalPoints;

  @Input()
  public set modalActions(eventEmitter: EventEmitter<OrgDisasterScore>) {
    if (isNullOrUndefined(eventEmitter)) {
      return;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = eventEmitter.subscribe((next) => {
      if (isNullOrUndefined(next)) {
        return;
      }

      this.score = next;

      this.totalPoints = this.getTotalScore();

      this.openModal();
    });
  }

  openModal() {
    this.sortingActions.emit({ action: 'modal', params: ['open'] });
  }

  closeModal() {
    this.sortingActions.emit({ action: 'modal', params: ['close'] });
  }

  getTotalScore(): number {
    return (
      this.score.adminEditScore +
      this.score.directServiceScore +
      this.score.localStaffScore +
      this.score.refusedQuestionScore +
      this.score.updateScore +
      this.score.localStaffCountScore
    );
  }
}
