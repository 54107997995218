import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from '../../../common/config';

import { AuthenticationService } from '../../../login/login.service';
import { contentHeaders } from '../../../common/headers';
import { SRResponse } from '../../../models/SRResponse';
import { FileUploadResponse } from '../../../models/FileUploadResponse';

const ENDPOINT = AppSettings.API_SERVER + '/smart-response/admin/organizations/';

@Injectable()
export class LogoUploadService {
  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  getLogo(organizationId: number): Observable<FileUploadResponse> {
    return this.http
      .get<SRResponse>(ENDPOINT + organizationId + '/logo', { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.organization_logo));
  }

  deleteLogo(organizationId: number): Observable<any> {
    return this.http.delete(`${ENDPOINT}${organizationId}/logo`, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  uploadLogo(file: File, organizationId: number): Observable<FileUploadResponse> {
    const data = new FormData();
    data.append('logo', file);

    return this.http
      .post<SRResponse>(`${ENDPOINT}${organizationId}/logo`, data, { headers: this.authService.getAuthHeader(null) })
      .pipe(map((res) => res.responseData.organization_logo));
  }
}
