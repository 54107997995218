import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { AppSettings } from 'app/common/config';
import {
  GeneralOrganizationBackgroundSurveyInput,
  GeneralOrganizationBackgroundSurveyOutput,
} from './general-organization-background.model';
import { contentHeaders } from 'app/common/headers';
import { AuthenticationService } from 'app/login/login.service';
import { SRResponse } from 'app/models/SRResponse';
import { SurveyNotification } from 'app/models/SurveyNotification';
import { SurveyUpdateRequest } from 'app/models/SurveyUpdateRequest';
import { getNumberFromInput, getFloatFromInput } from '../survey-helpers';

declare const $: any;

const RETRIEVAL_ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/survey/gob/search';
const RETRIEVAL_ENDPOINT_URL_BY_ID = AppSettings.API_SERVER + '/smart-response/survey/gob';
const RETRIEVAL_ENDPOINT_FOR_EDIT_URL_BY_ID = AppSettings.API_SERVER + '/smart-response/survey/gob/edit';
const SUBMIT_SURVEY_ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/survey/gob/submit';
const NO_AFFIRMATION_NOTIFICATION_URL = AppSettings.API_SERVER + '/smart-response/survey/gob/no_affirmation';
const EMPTY_OUTDATED_GOB_SURVEY_ENDPOINT_URL =
  AppSettings.API_SERVER + '/smart-response/survey/gob/empty_outdated_questions';
const REQUEST_GOB_SURVEY_UPDATE_URL = AppSettings.API_SERVER + '/smart-response/survey/gob/request_info';
const RETRIEVAL_ENDPOINT_CURRENCY_CODE_URL = AppSettings.API_SERVER + '/smart-response/admin/currency';

// import Services
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from 'app/dashboard/servicesComponets/country/country.service';
import { NotificationsService } from 'angular2-notifications';

// import Models
import { Country } from 'app/models/Country';
import { User } from 'app/models/User';
import { GeneralOrganizationalBackgroundSurveyComponent } from './general-organization-background.component';
import { EmptyOutdatedQuestions } from 'app/models/EmptyOutdatedQuestions';
import { SurveyErrorCode } from 'app/models';
import { CurrencyCodes } from 'app/models/CurrencyCodes';

export interface CountryGroup {
  country: Country;
  user1: User;
  user2: User;
}

interface SurveyOutputField {
  markAsUpdated: boolean;
  value: any;
}

const gobErrorCodeList: SurveyErrorCode[] = [
  { errCode: 'organizationName', stepNum: 1, questionNum: 1, questionCount: 4 },
  { errCode: 'govIdOrEin', stepNum: 1, questionNum: 2, questionCount: 4 },
  { errCode: 'govIdOrEin2', stepNum: 1, questionNum: 3, questionCount: 4 },
  { errCode: 'donateButtonDisabled', stepNum: 1, questionNum: 4, questionCount: 4 },
  { errCode: 'hqAddress', stepNum: 2, questionNum: 1, questionCount: 1 },
  { errCode: 'hqContact', stepNum: 3, questionNum: 1, questionCount: 1 },
  { errCode: 'summary', stepNum: 4, questionNum: 2, questionCount: 4 },
  { errCode: 'orgIntroVideoUrl', stepNum: 4, questionNum: 3, questionCount: 4 },
  { errCode: 'staffIntroVideoUrl', stepNum: 4, questionNum: 3, questionCount: 4 },
  { errCode: 'orgTypeFaithBased', stepNum: 4, questionNum: 4, questionCount: 4 },
  { errCode: 'orgTypeInternationalNGO', stepNum: 4, questionNum: 4, questionCount: 4 },
  { errCode: 'orgTypeNationalOrganization', stepNum: 4, questionNum: 4, questionCount: 4 },
  { errCode: 'orgTypeLocalCivilSociety', stepNum: 4, questionNum: 4, questionCount: 4 },
  { errCode: 'website', stepNum: 5, questionNum: 1, questionCount: 7 },
  { errCode: 'donateUrl', stepNum: 5, questionNum: 1, questionCount: 7 },
  { errCode: 'facebookUrl', stepNum: 5, questionNum: 1, questionCount: 7 },
  { errCode: 'overallAnnualBudget', stepNum: 5, questionNum: 2, questionCount: 7 },
  { errCode: 'fiscalYearEnding', stepNum: 5, questionNum: 2, questionCount: 7 },
  { errCode: 'staffCount', stepNum: 5, questionNum: 3, questionCount: 7 },
  { errCode: 'hqStaffCount', stepNum: 5, questionNum: 3, questionCount: 7 },
  { errCode: 'twitterHandle', stepNum: 5, questionNum: 4, questionCount: 7 },
  { errCode: 'discriminationPolicy', stepNum: 5, questionNum: 5, questionCount: 7 },
  { errCode: 'religiousAffiliation', stepNum: 5, questionNum: 6, questionCount: 7 },
  { errCode: 'whistleblowerPolicy', stepNum: 5, questionNum: 7, questionCount: 7 },
  { errCode: 'step7LocationContactError', stepNum: 7, questionNum: 1, questionCount: 1 },
  { errCode: 'affirmationError', stepNum: 1, questionNum: 4, questionCount: 4 },
  { errCode: 'userManagementFormValid', stepNum: 6, questionNum: 1, questionCount: 1 },
];

@Injectable()
export class GeneralOrganizationBackgroundSurveyService {
  public gobFormStep6 = new Subject();
  // outdatedSurveys: SurveyNotification[] = [];
  gobSurveyOutdated: GeneralOrganizationalBackgroundSurveyComponent;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private countryService: CountryService,
    public authService: AuthenticationService
  ) {}

  getMostRecentSurvey(organizationId: number): Observable<GeneralOrganizationBackgroundSurveyInput> {
    const query = $.param({ organizationId, recordLimit: 1 });

    return this.http
      .get<SRResponse>(`${RETRIEVAL_ENDPOINT_URL}?${query}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp.responseData.survey_list[0] as any));
  }

  getMostRecentSurveyForEdit(organizationId: number): Observable<GeneralOrganizationBackgroundSurveyInput> {
    return this.http
      .get<SRResponse>(`${RETRIEVAL_ENDPOINT_FOR_EDIT_URL_BY_ID}/${organizationId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp.responseData.survey as any));
  }

  getSurveyById(surveyId: number): Observable<GeneralOrganizationBackgroundSurveyInput> {
    return this.http
      .get<SRResponse>(`${RETRIEVAL_ENDPOINT_URL_BY_ID}/${surveyId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp.responseData.survey as any));
  }

  submitSurvey(organizationId: number, survey: GeneralOrganizationBackgroundSurveyOutput): Promise<boolean> {
    return this.http
      .post<boolean>(`${SUBMIT_SURVEY_ENDPOINT_URL}/${organizationId}`, JSON.stringify(survey), {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .toPromise();
  }

  requestGOBSurveyUpdate(organizationId: number): Observable<SurveyUpdateRequest> {
    return this.http
      .post<SRResponse>(`${REQUEST_GOB_SURVEY_UPDATE_URL}/${organizationId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res));
  }

  public gobFormStep7Obs(locationContactList): Observable<{}> {
    return this.countryService.getCountries().pipe(
      map((countries: Country[]) => {
        const gobFormStep6 = {};
        const selectedCountries = [];
        // this.filteredCountries = this.countries = countries;
        if (locationContactList) {
          for (const locationContactEntry of locationContactList) {
            const targetCountry: Country = countries.filter(
              (country) => country.countryId === locationContactEntry.value.countryId
            )[0];
            if (!isNullOrUndefined(targetCountry)) {
              const findCountry = countries.filter((country) => country.abbreviation === targetCountry.abbreviation)[0];
              if (isNullOrUndefined(findCountry)) {
                this.alert(findCountry);
              } else {
                const newLocation: CountryGroup = {
                  country: findCountry,
                  user1: locationContactEntry.value.user1,
                  user2: locationContactEntry.value.user2,
                };
                selectedCountries.push(newLocation); // for displaying list
                gobFormStep6[findCountry.abbreviation] = newLocation; // for saving in form
              }
            }
          }
        }

        return {
          countries,
          gobFormStep6,
          selectedCountries,
        };
      })
    );
  }

  private alert(countryCode) {
    this.notificationsService.error(
      this.translateService.instant('GeneralOrganizationalBackgroundSurveyComponent-TITLE'),
      this.translateService.instant('GeneralOrganizationalBackgroundSurveyComponent-STEP_7_BAD_COUNTRY_CODE', {
        countryCode,
      }),
      AppSettings.NOTIFICATIONS_ERROR_OPTIONS
    );

    console.log(`Could not find country code: ${countryCode}`);
  }

  public denyAffirmation(organizationId: number): Observable<GeneralOrganizationalBackgroundSurveyComponent> {
    return this.http.post<GeneralOrganizationalBackgroundSurveyComponent>(
      `${NO_AFFIRMATION_NOTIFICATION_URL}/${organizationId}`,
      null,
      { headers: this.authService.getAuthHeader(contentHeaders) }
    );
  }

  checkEmptyOutdatedStatus(
    organizationId: number,
    survey: GeneralOrganizationBackgroundSurveyOutput
  ): Observable<EmptyOutdatedQuestions> {
    return this.http
      .post(`${EMPTY_OUTDATED_GOB_SURVEY_ENDPOINT_URL}/${organizationId}`, JSON.stringify(survey), {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp as EmptyOutdatedQuestions));
  }

  private get blankOutputField(): SurveyOutputField {
    return {
      markAsUpdated: false,
      value: null,
    };
  }

  getEmptyOutdatedQuestions(organizationId: number): Observable<EmptyOutdatedQuestions> {
    return this.getMostRecentSurvey(organizationId).pipe(
      map((input: GeneralOrganizationBackgroundSurveyInput) => this.convertInputToOutput(input)),
      switchMap((output: GeneralOrganizationBackgroundSurveyOutput) => {
        return this.checkEmptyOutdatedStatus(organizationId, output);
      })
    );
  }

  getCurrencyCodes(): Observable<CurrencyCodes> {
    return this.http
      .get<CurrencyCodes>(`${RETRIEVAL_ENDPOINT_CURRENCY_CODE_URL}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp as CurrencyCodes));
  }

  getGOBErrorCodeList(): SurveyErrorCode[] {
    return gobErrorCodeList;
  }

  private convertInputToOutput(
    input: GeneralOrganizationBackgroundSurveyInput
  ): GeneralOrganizationBackgroundSurveyOutput {
    const survey: Partial<GeneralOrganizationBackgroundSurveyOutput> = {};
    // fields that default to null
    for (const field of [
      'discriminationPolicy',
      'donateUrl',
      'fiscalYearEnding',
      'facebookUrl',
      'govIdOrEin',
      'govIdOrEin2',
      'mission',
      'religiousAffiliation',
      'summary',
      'whistleblowerPolicy',
      'orgTypeFaithBased',
      'orgTypeInternationalNGO',
      'orgTypeNationalOrganization',
      'orgTypeLocalCivilSociety',
      'orgIntroVideoUrl',
      'staffIntroVideoUrl',
      'twitterHandle',
      'website',
    ]) {
      survey[field] = this.blankOutputField;
      if (input[field] && input[field].value !== undefined) {
        survey[field].value = input[field].value;
      }
    }

    // fields that default to false
    for (const field of ['affirmation1Affirmed', 'affirmation2Affirmed', 'affirmation3Affirmed']) {
      survey[field] = input[field] || false;
    }

    // multipart fields
    survey.hqAddress = {
      markAsUpdated: false,
      value: {
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        country: {
          countryId: null,
        },
      },
    };
    if (input.hqAddress && input.hqAddress.value) {
      for (const field of ['address1', 'address2', 'city', 'state', 'zip']) {
        if (input.hqAddress.value[field] !== undefined) {
          survey.hqAddress.value[field] = input.hqAddress.value[field];
        }
      }
      if (input.hqAddress.value.country) {
        survey.hqAddress.value.country.countryId = getNumberFromInput(input.hqAddress.value.country.countryId);
      }
    }

    survey.hqContact = {
      markAsUpdated: false,
      value: {
        email: null,
        alternativeEmail: null,
        firstName: null,
        lastName: null,
        phone1: null,
        phone2: null,
        title: null,
      },
    };
    if (input.hqContact && input.hqContact.value) {
      for (const field of ['email', 'alternativeEmail', 'firstName', 'lastName', 'phone1', 'phone2', 'title']) {
        if (input.hqContact.value[field] !== undefined) {
          survey.hqContact.value[field] = input.hqContact.value[field];
        }
      }
    }

    // number fields
    for (const field of ['staffCount', 'hqStaffCount']) {
      survey[field] = this.blankOutputField;
      if (input[field]) {
        survey[field].value = getNumberFromInput(input[field].value);
      }
    }

    // unique/complex fields
    survey.affirmed = survey.affirmation1Affirmed && survey.affirmation2Affirmed;

    // default to true
    survey.donateButtonDisabled =
      input.donateButtonDisabled !== null && input.donateButtonDisabled !== undefined
        ? input.donateButtonDisabled
        : true;

    survey.locationContactList = this.blankOutputField;
    let locationContactList = [];
    if (input.locationContactList && input.locationContactList.length) {
      locationContactList = input.locationContactList.map((locationContact) => ({
        countryId: locationContact.value.countryId,
        user1: locationContact.value.user1,
        user2: locationContact.value.user2 || null,
      }));
    }
    if (locationContactList.length) {
      survey.locationContactList.value = [locationContactList.pop()];
      locationContactList.forEach((_) => {
        survey.locationContactList.value.push(locationContactList.pop());
      });
    }

    survey.organizationName = input.organizationName || null;

    survey.overallAnnualBudget = {
      markAsUpdated: false,
      value: {
        currency: AppSettings.DEFAULT_CURRENCY,
        amount: null,
      },
    };
    if (input.overallAnnualBudget && input.overallAnnualBudget.value) {
      if (input.overallAnnualBudget.value.currency) {
        survey.overallAnnualBudget.value.currency = input.overallAnnualBudget.value.currency;
      }
      survey.overallAnnualBudget.value.amount = getFloatFromInput(input.overallAnnualBudget.value.amount);
    }

    survey.paypalId = this.blankOutputField;

    survey.previousSurveyId = getNumberFromInput(input.surveySubmissionId);

    return <GeneralOrganizationBackgroundSurveyOutput>survey;
  }
}
