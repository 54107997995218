import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscribe-success',
  templateUrl: './newsletter-subscribe-success.component.html',
})
export class NewsLetterSubscribeSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
