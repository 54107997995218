import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AppSettings } from '../../../../common/config';
import { AuthenticationService } from '../../../../login/login.service';
import { contentHeaders } from '../../../../common/headers';
import { DisasterAndContacts } from './disaster-specific-table.model';
import { SRResponse } from '../../../../models/SRResponse';

const ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/survey/dss/list/';

@Injectable()
export class DisasterOrgListService {
  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  getDisasters(organizationId: number): Observable<DisasterAndContacts[]> {
    return this.http
      .get<SRResponse>(ENDPOINT_URL + organizationId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(
        map((res: SRResponse) =>
          res.responseData.disaster_org_list.map((disasterOrg) => {
            const dac = disasterOrg as DisasterAndContacts;
            // flatten the disaster name and country list to facilitate the search functionality on the dss grid
            dac.disasterName = dac.disaster.name;

            if (dac.countryList) {
              dac.countryNamesCommaSeparated = dac.countryList
                .map((country) => country.name)
                .filter((element: string, idx: number, self: any) => idx === self.indexOf(element))
                .reduce((countryNamesCommaSeparated: string, countryName: string) =>
                  countryNamesCommaSeparated.concat(`, ${countryName}`)
                );
            }

            return dac;
          })
        )
      );
  }
}
