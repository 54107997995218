import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountryService } from '../country.service';
import { Country } from '../../../../models/Country';
import { handleErrors } from '../../../../common/error';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dashboardViewCountry',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css', '../../../common-view.css'],
  providers: [],
})
export class DashboardCountryViewComponent implements OnInit {
  public myCountry: Country;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private countryService: CountryService,
    private translateService: TranslateService,
    private globalLoaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.globalLoaderService.start();
      this.loading = true;

      const id = +params['id'];

      this.countryService.getCountry(id).subscribe(
        (data) => {
          this.myCountry = data;

          this.myCountry.background = this.sanitizer.bypassSecurityTrustHtml(<string>this.myCountry.background);

          this.globalLoaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'COUNTRY_COMMON-TITLE');

          this.globalLoaderService.complete();
          this.loading = false;
        }
      );
    });
  }
}
