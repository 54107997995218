import { EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

import { Collection, Dictionary, MaterializeModalParams, SurveyModalActions } from '../../../../../models';
import { adjustModalContent, FormElement } from '../../survey-helpers';

const MODAL_ZINDEX_MIN = 1000;

export class SurveyComponent {
  readonly TOTAL_STEPS: number;
  readonly MISSING_STEP_INDEX: number = 1;

  protected modalZIndexMin: number = MODAL_ZINDEX_MIN;
  modalActions: SurveyModalActions = {
    steps: [],
    error: null,
    processing: false,
  };

  overlayDisplay: string = 'none';
  overlayVisible: boolean = false;

  @Input()
  openWith: [number, number] = null;
  @Output()
  surveyOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  surveySaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected modalScrollActive: boolean = false;
  protected modalScrollTarget: string = null;

  // Parameters for all modals
  readonly modalParams: MaterializeModalParams[] = [
    {
      dismissible: false,
      opacity: 0,
      out_duration: 250,
      complete: () => {
        this.modalActions.processing = false;
      },
      ready: (modal) => {
        this.modalActions.processing = false;
        adjustModalContent(modal[0]);

        if (this.modalScrollActive) {
          location.hash = this.modalScrollTarget;
          this.modalScrollActive = false;
          this.modalScrollTarget = null;
        }

        const overlays = Array.prototype.slice.call(document.getElementsByClassName('modal-overlay'));
        const modals = Array.prototype.slice.call(document.getElementsByClassName('modal modal-fixed-footer open'));
        if (Array.isArray(overlays) && overlays.length) {
          for (const element of overlays) {
            element.remove();
          }
        }
        function getZIndex(e: Node) {
          const zIndex = Number(
            window.document.defaultView.getComputedStyle(<HTMLElement>e).getPropertyValue('z-index')
          );
          if (isNaN(Number(zIndex))) return getZIndex(e.parentNode);
          return zIndex;
        }
        if (Array.isArray(modals) && modals.length > 0) {
          for (const element of modals) {
            const zIndex: number = getZIndex(element);
            if (zIndex < this.modalZIndexMin) {
              element.style.zIndex = this.modalZIndexMin;
            }
          }
        }
      },
    },
  ];

  invalidOnInit: Collection<boolean> = {
    singles: {},
  };

  constructor(protected translateService: TranslateService, protected notificationsService: NotificationsService) {}

  /**
   * Wrapper for functions that switch modals.
   * Filters any function calls that happen during the switch.
   * @param callback
   * @param parameters
   */
  onModalAction(callback: Function, parameters: any[] = null): void {
    if (!this.modalActions.processing) {
      this.modalActions.processing = true;
      let params: any[] = null;
      if (parameters && Array.isArray(parameters) && parameters.length) {
        params = parameters;
      }
      callback.apply(this, params);
    }
  }

  /**
   * Generates a new, updated array of errors based on a form control's modified value
   * @param control The form control to check for errors
   * @param errorName The name of the error to add or remove
   * @param prevErrors The list of errors to modify
   */
  protected updateErrors(control: FormControl, errorName: string, prevErrors: string[]): string[] {
    const errors: string[] = prevErrors ? [...prevErrors] : [];
    if (errorName && errorName.length > 0 && control) {
      if (control.valid) {
        return errors.filter((item) => item !== errorName);
      }
      if (control.invalid && errors.indexOf(errorName) === -1) {
        return [...errors, errorName];
      }
      if (control.value instanceof Array) {
        for (const n in control.value) {
          if (control.get(n) && control.get(n).invalid) {
            return [...errors, errorName];
          }
        }
      }
    }
    return errors;
  }

  protected openPrevModal(currentStepIndex: number, prevStepIndex?: number): number {
    let targetStepIndex: number = currentStepIndex - 1;
    if (this.modalActions.steps[targetStepIndex]) {
      this.modalActions.steps[targetStepIndex].emit({ action: 'modal', params: ['open'] });
    } else if (currentStepIndex === -1) {
      targetStepIndex = this.modalActions.steps[prevStepIndex] ? prevStepIndex : this.TOTAL_STEPS - 1;
      this.modalActions.steps[targetStepIndex].emit({ action: 'modal', params: ['open'] });
    }
    return targetStepIndex;
  }

  protected openNextModal(currentStepIndex: number): number {
    let targetStepIndex: number = currentStepIndex + 1;
    if (this.TOTAL_STEPS) {
      targetStepIndex = targetStepIndex < this.TOTAL_STEPS ? currentStepIndex + 1 : this.TOTAL_STEPS;
    }
    if (this.modalActions.steps[currentStepIndex] && this.modalActions.steps[targetStepIndex]) {
      this.modalActions.steps[targetStepIndex].emit({ action: 'modal', params: ['open'] });
      return targetStepIndex;
    }
    return currentStepIndex;
  }

  protected closeErrorModal() {
    if (this.modalActions.error) {
      this.modalActions.error.emit({ action: 'modal', params: ['close'] });
    }
  }

  /**
   * Scrolls to a target survey question in a target modal
   * @param stepNum The target modal's step number
   * @param questionNum The target question number
   */
  protected scrollToQuestion(stepNum, questionNum): void {
    if (stepNum >= 1 && stepNum <= this.TOTAL_STEPS) {
      setTimeout(() => {
        const stepIndex: number = stepNum - 1;
        this.modalScrollActive = true;
        this.modalScrollTarget = 'ques' + stepNum + questionNum;
        this.modalActions.steps[stepIndex].emit({ action: 'modal', params: ['open'] });
      }, this.modalParams[0].out_duration);
    }
  }

  protected hideOverlay() {
    this.overlayVisible = false;
    setTimeout(() => {
      this.overlayDisplay = 'none';
    }, this.modalParams[0].out_duration);
  }

  protected showOverlay() {
    this.overlayDisplay = 'block';
    setTimeout(() => {
      this.overlayVisible = true;
    });
  }

  initModal(callback: Function): void {
    if (
      this.openWith &&
      this.openWith.length &&
      this.openWith[0] !== undefined &&
      this.openWith[0] !== null &&
      this.openWith[1] !== undefined &&
      this.openWith[1] !== null
    ) {
      this.onModalAction(callback, this.openWith);
      this.showOverlay();
      this.surveyOpened.emit(true);
    }
  }

  protected setInitialErrorVisibility(key: string, form: Dictionary<FormElement>): void {
    if (form[key]) {
      this.invalidOnInit.singles[key] = !form[key].valueControl.valid;
    }
  }

  protected notifySaveSuccess(surveyTitle: string): void {
    this.notificationsService.success(
      this.translateService.instant(surveyTitle),
      this.translateService.instant('UNIVERSAL-TOAST_SUCCESS_SAVED_SURVEY')
    );
    this.surveySaved.emit(true);
  }
}
