import { zip } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Disaster } from '../../models/Disaster';
import { DisasterAdminService } from '../../dashboard/servicesComponets/disaster/disaster.service';
import { Service } from '../../models/Service';
import { ServicesProvidedService } from '../../dashboard/servicesComponets/service/service.service';
import { AppSettings } from '../../common/config';
import { RecentSurveyService } from './recent-survey.service';
import { RecentSurvey, SurveyType } from '../../models/RecentSurvey';

const DISASTER_LIST_TYPES: string[] = [
  // 'trending',
  // 'recent',
  'starred',
];

@Component({
  selector: 'disaster-list-home',
  templateUrl: './disasterList.component.html',
  styleUrls: ['./disasterList.component.css'],
})
export class DisasterListHomeComponent implements OnInit {
  surveyList: RecentSurvey[] = [];
  newSurveys: any[] = [];
  disastersAreLoaded: boolean = false;

  DISASTER_LIST_TYPES = DISASTER_LIST_TYPES;
  AppSettings = AppSettings;

  disasters: { [key: string]: (Disaster | Service)[] };
  serviceList: { [index: number]: Service } = {};

  error: boolean;

  surveyType = SurveyType;
  loading = false;

  constructor(
    private disasterService: DisasterAdminService,
    private servicesProvidedService: ServicesProvidedService,
    private sanitizer: DomSanitizer,
    private recentSurveyService: RecentSurveyService
  ) {}

  ngOnInit() {
    const recentObservable = this.disasterService.getRecentDisasters(5);
    const starredObservable = this.disasterService.getStarredDisasters(5);
    const serviceListObservable = this.servicesProvidedService.getServices();
    this.loading = true;

    recentObservable
      .pipe(
        zip(starredObservable, serviceListObservable, (recent, starred, serviceList) => ({
          recent,
          starred,
          serviceList,
        }))
      )
      .subscribe(
        (success) => {
          success.serviceList.forEach((service, index) => {
            this.serviceList[service.serviceId] = service;
          });
          this.disasters = success;
          for (const dType of DISASTER_LIST_TYPES) {
            this.disasters[dType].forEach((item) => {
              item.summary = this.sanitizer.bypassSecurityTrustHtml(<string>item.summary);

              if (item.logo === null) {
                item.logo = {
                  uri: 'assets/images/org-default.png',
                };
              }
            });
          }
          this.disastersAreLoaded = true;
        },
        (error) => {
          this.error = true;
        }
      );

    const newSurverysSubscription = this.recentSurveyService.getNewSurveys().subscribe((res) => {
      this.newSurveys = res;
    });

    const recentGobSurveyObservable = this.recentSurveyService.getRecentSurveys(5, SurveyType.Gob);
    const recentLssSurveyObservable = this.recentSurveyService.getRecentSurveys(5, SurveyType.Lss);
    const recentDssSurveyObservable = this.recentSurveyService.getRecentSurveys(5, SurveyType.Dss);
    this.loading = true;
    recentGobSurveyObservable
      .pipe(
        zip(recentLssSurveyObservable, recentDssSurveyObservable, (gobSurvey, lssSurvey, dssSurvey) => ({
          gobSurvey,
          lssSurvey,
          dssSurvey,
        }))
      )
      .subscribe((response) => {
        this.loading = false;
        this.surveyList = this.surveyList
          .concat(response.gobSurvey)
          .concat(response.lssSurvey)
          .concat(response.dssSurvey);

        this.surveyList.sort((a, b) => {
          return b.surveyUpdateDate - a.surveyUpdateDate;
        });

        this.surveyList = this.surveyList.splice(0, 5);
      });
  }

  isOrganizationNew(survey: RecentSurvey): boolean {
    return this.newSurveys.some((newSurvey) => newSurvey.organizationId === survey.orgId);
  }
}
