<button
  class="waves-effect waves-light btn cyan darken-3 left"
  (click)="onCancel()"
  *ngIf="!isLastStep && !isErrorList"
  [disabled]="isSubmitting"
>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_CANCEL' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_CANCEL' | translate"
  >
    clear
  </i>
</button>

<button
  class="waves-effect waves-light btn cyan darken-3 left"
  (click)="onCancel()"
  *ngIf="isLastStep || isErrorList"
  [disabled]="isSubmitting"
>
  <i
    class="material-icons"
    [title]="'UNIVERSAL-NAVIGATION_RETURN_TO_DASHBOARD' | translate"
    [attr.aria-label]="'UNIVERSAL-NAVIGATION_RETURN_TO_DASHBOARD' | translate"
  >
    clear
  </i>
</button>

<div class="right">
  <button
    type="submit"
    class="waves-effect waves-light btn cyan darken-3"
    (click)="onSubmit()"
    [disabled]="isSubmitting || !isValid"
  >
    <i
      class="material-icons hide-on-large-only"
      [title]="'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate"
      [attr.aria-label]="'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate"
    >
      save
    </i>
    <span class="hide-on-small-only hide-on-med-only">{{ 'UNIVERSAL-NAVIGATION_SAVE_AND_QUIT' | translate }}</span>
  </button>

  <button
    class="modal-trigger modal-close waves-effect waves-light btn red darken-3"
    *ngIf="!isValid && !isSubmitting && !isErrorList"
    (click)="onError()"
  >
    <i
      class="material-icons"
      [title]="'UNIVERSAL-NAVIGATION_ERROR' | translate"
      [attr.aria-label]="'UNIVERSAL-NAVIGATION_ERROR' | translate"
    >
      error
    </i>
  </button>

  <button
    class="modal-trigger modal-close waves-effect waves-light btn cyan"
    (click)="onNext()"
    *ngIf="!isLastStep && !isErrorList"
    [disabled]="!isValid || isSubmitting"
  >
    <i
      class="material-icons"
      [title]="'UNIVERSAL-NAVIGATION_NEXT' | translate"
      [attr.aria-label]="'UNIVERSAL-NAVIGATION_NEXT' | translate"
    >
      chevron_right
    </i>
  </button>

  <button
    class="modal-trigger modal-close waves-effect waves-light btn yellow darken-3"
    (click)="onPrev()"
    *ngIf="!isFirstStep"
    [disabled]="(!isValid || isSubmitting) && !isErrorList"
  >
    <i
      class="material-icons"
      [title]="'UNIVERSAL-NAVIGATION_PREV' | translate"
      [attr.aria-label]="'UNIVERSAL-NAVIGATION_PREV' | translate"
    >
      chevron_left
    </i>
  </button>
</div>
