import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { contentHeaders } from '../common/headers';

import { AppSettings } from '../common/config';
import { User, UserType } from '../models/User';
import { getSingleError } from '../common/error';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { SRResponse } from '../models/SRResponse';

const REGISTER_ORGANIZATION_ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/register_user';
const NOMINATE_ORGANIZATION_ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/organization/nominate';

interface Timers {
  HOUR_BEFORE?: any;
  THIRTY_MINUTES_BEFORE?: any;
  FIVE_MINUTES_BEFORE?: any;
  LOGOUT?: any;
}

@Injectable()
export class AuthenticationService {
  public errorText: string;
  public isLoggedIn: boolean;
  public jwtToken: string;

  public isAttemptingToLogIn: boolean;
  public redirectUrl: string;

  private profile: User;

  private tokenExpiration: Date;
  private timerIds: Timers;
  private getNotificationId: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private loaderService: SlimLoadingBarService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    /**
     * attempt to load the token and profile from local storage. if either cannot be found, reset both fields.
     * @type {any}
     */
    const localStorageJWT = localStorage.getItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY);
    const localStorageProfile = localStorage.getItem(AppSettings.LOCAL_STORAGE_PROFILE_KEY);
    const localStoragetokenExpiration = localStorage.getItem(AppSettings.LOCAL_STORAGE_TOKEN_EXPIRATION_KEY);

    if (localStorageJWT && localStorageProfile && localStoragetokenExpiration) {
      this.loginHelper(localStorageJWT, JSON.parse(localStorageProfile), +localStoragetokenExpiration);
    } else {
      this.reset();
    }

    this.errorText = null;

    this.isAttemptingToLogIn = false;
  }

  reset(): void {
    this.isLoggedIn = false;
    this.isAttemptingToLogIn = false;
    this.profile = null;
    this.jwtToken = null;

    if (this.timerIds) {
      clearTimeout(this.timerIds.HOUR_BEFORE);
      clearTimeout(this.timerIds.THIRTY_MINUTES_BEFORE);
      clearTimeout(this.timerIds.FIVE_MINUTES_BEFORE);
      clearTimeout(this.timerIds.LOGOUT);
    }

    if (!this.isTokenExpired()) {
      localStorage.removeItem(AppSettings.LOCAL_STORAGE_PROFILE_KEY);
      localStorage.removeItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY);
      localStorage.removeItem(AppSettings.LOCAL_STORAGE_TOKEN_EXPIRATION_KEY);
    }

    this.errorText = null;
  }

  loginHelper(token: string, profile: User, tokenExpiration: number): void {
    // if we log in *after* the token has expired, logout
    if (Date.now() > tokenExpiration) {
      setTimeout(
        () => (this.getNotificationId = this.notify('AuthenticationService-TOKEN-EXPIRED')),
        AppSettings.ONE_SECOND_IN_MILLISECONDS
      );
      this.reset();
      return;
    }

    const toLogout = tokenExpiration - Date.now();

    this.isLoggedIn = true;
    this.profile = profile;
    this.jwtToken = token;

    // expiration and timers
    this.tokenExpiration = new Date(tokenExpiration);

    /** uncomment me for testing!
     console.log('Hour Before notification in '
     + (this.tokenExpiration.getTime() - AppSettings.ONE_HOUR_IN_MILLISECONDS - Date.now()) + ' milliseconds');
     console.log('Thirty Minutes Before notification in '
     + (this.tokenExpiration.getTime() - (AppSettings.ONE_MINUTE_IN_MILLISECONDS * 30) - Date.now()) + ' milliseconds');
     console.log('Five Minutes Before notification in '
     + (this.tokenExpiration.getTime() - (AppSettings.ONE_MINUTE_IN_MILLISECONDS * 5) - Date.now()) + ' milliseconds');

     console.log('Logout will expire at ' + this.tokenExpiration);
     **/
    this.timerIds = {
      LOGOUT: setTimeout(
        () => {
          this.getNotificationId = this.notify('AuthenticationService-AUTO_LOGOUT');
          this.logout();
        },
        this.tokenExpiration.getTime() - Date.now(),
        ''
      ),
    };

    if (toLogout > AppSettings.ONE_MINUTE_IN_MILLISECONDS * 5) {
      this.timerIds.FIVE_MINUTES_BEFORE = setTimeout(
        () => (this.getNotificationId = this.notify('AuthenticationService-FIVE_MINS_TIL_LOGOUT')),
        this.tokenExpiration.getTime() - AppSettings.ONE_MINUTE_IN_MILLISECONDS * 5 - Date.now()
      );
    }

    if (toLogout > AppSettings.ONE_MINUTE_IN_MILLISECONDS * 30) {
      this.timerIds.THIRTY_MINUTES_BEFORE = setTimeout(
        () => (this.getNotificationId = this.notify('AuthenticationService-THIRTY_MINS_TIL_LOGOUT')),
        this.tokenExpiration.getTime() - AppSettings.ONE_MINUTE_IN_MILLISECONDS * 30 - Date.now()
      );
    }

    if (toLogout > AppSettings.ONE_HOUR_IN_MILLISECONDS) {
      this.timerIds.HOUR_BEFORE = setTimeout(
        () => (this.getNotificationId = this.notify('AuthenticationService-ONE_HOUR_TIL_LOGOUT')),
        this.tokenExpiration.getTime() - AppSettings.ONE_HOUR_IN_MILLISECONDS - Date.now()
      );
    }

    // persist to cache
    localStorage.setItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY, this.jwtToken);
    localStorage.setItem(AppSettings.LOCAL_STORAGE_PROFILE_KEY, JSON.stringify(this.profile));
    localStorage.setItem(AppSettings.LOCAL_STORAGE_TOKEN_EXPIRATION_KEY, '' + this.tokenExpiration.getTime());
  }

  notify(messageKey: string): string {
    this.removePrevNotification();

    return this.notificationsService.alert(
      this.translateService.instant('AuthenticationService-TITLE'),
      this.translateService.instant(messageKey),
      AppSettings.NOTIFICATIONS_ERROR_OPTIONS
    ).id;
  }

  logout(): void {
    this.reset();

    this.router.navigate(['/']);
  }

  login(username: string, password: string): void {
    /* prevent simultaneous logins and race conditions */
    if (this.isAttemptingToLogIn) {
      return;
    }
    this.isAttemptingToLogIn = true;
    this.removePrevNotification();

    const body = JSON.stringify({ username, password });
    const target = AppSettings.API_SERVER + '/smart-response/jwt';

    this.loaderService.start();

    this.http
      .post<SRResponse>(target, body, { headers: contentHeaders })
      .subscribe(
        (res) => {
          const data = res.responseData;
          this.reset();

          this.loginHelper(data.token, data.profile, Date.now() + AppSettings.LOGIN_EXPIRATION_TIMEOUT);

          let navigateTarget = '/dashboard';

          if (this.redirectUrl) {
            navigateTarget = this.redirectUrl;
            this.redirectUrl = null;
          }

          this.router.navigate([navigateTarget]);

          this.loaderService.complete();
        },
        (error) => {
          this.reset();

          this.errorText = this.translateService.instant(getSingleError(error));

          this.loaderService.complete();
        }
      );
  }

  registerOrganization(newOrganization: any, captcha: string) {
    const body = JSON.stringify(newOrganization);

    const params: HttpParams = new HttpParams().set('captcha', captcha);

    return this.http.post(REGISTER_ORGANIZATION_ENDPOINT_URL, body, {
      params,
      headers: contentHeaders,
    });
  }

  nominateOrganization(nomOrganization: any, captcha: string) {
    const body = JSON.stringify(nomOrganization);

    const params = new HttpParams().set('captcha', captcha);

    return this.http.post(NOMINATE_ORGANIZATION_ENDPOINT_URL, body, {
      params,
      headers: contentHeaders,
    });
  }

  getAuthHeader(headers: HttpHeaders): HttpHeaders {
    // if our current token is expired
    if (this.tokenExpiration && Date.now() > this.tokenExpiration.getTime()) {
      this.notify('AuthenticationService-AUTO_LOGOUT');
      this.logout();
    }

    const newHeaders = headers
      ? headers.append('Authorization', 'Bearer ' + this.getToken())
      : new HttpHeaders().set('Authorization', 'Bearer ' + this.getToken());
    return newHeaders;
  }

  getToken(): string {
    return this.jwtToken;
  }

  getProfile(): User {
    return this.profile;
  }

  isSuper(): boolean {
    return this.profile === null ? false : UserType.SUPER === this.profile.userType;
  }

  isOrgGeneral(): boolean {
    return this.profile === null ? false : UserType.ORG_GENERAL === this.profile.userType;
  }

  isLocation(): boolean {
    return this.profile === null ? false : UserType.LOCATION === this.profile.userType;
  }

  removePrevNotification(): void {
    if (this.getNotificationId) {
      this.notificationsService.remove(this.getNotificationId);
      this.getNotificationId = null;
    }
  }

  isTokenExpired(): boolean {
    if (this.tokenExpiration) {
      return this.tokenExpiration.getTime() < Date.now();
    }
  }
}
