<blocking-loader [blockingLoaderActions]="blockingLoaderEventEmitter"></blocking-loader>
<app-loader [loading]="loading"></app-loader>
<div class="col s12">
  <div class="card" *ngIf="editDisasterForm">
    <div class="card-content black-text">
      <h4>
        {{ 'DashboardDisasterCreateComponent-NOMINATE_DISASTER_TITLE' | translate }}
      </h4>

      <div class="divider"></div>

      <div class="sr-required-notif">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>

      <br />

      <div class="row margin">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error': !editDisasterForm.controls['name'].valid && editDisasterForm.controls['name'].touched
          }"
        >
          <input
            id="name"
            type="text"
            materialize="characterCounter"
            length="64"
            maxlength="64"
            [formControl]="editDisasterForm.controls['name']"
          />
          <label for="name">
            {{ 'DISASTER_COMMON-NAME_LABEL' | translate }}
            <span class="sr-required-mark">*</span>
          </label>

          <div
            class="sr-error-area"
            *ngIf="!editDisasterForm.controls['name'].valid && editDisasterForm.controls['name'].touched"
          >
            <div *ngIf="editDisasterForm.controls['name'].hasError('required')">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['name'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 64 } }}
            </div>
            <div *ngIf="editDisasterForm.controls['name'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div class="rich-text-input-label">
          {{ 'DISASTER_COMMON-SUMMARY_LABEL' | translate }}
          ({{ 'UNIVERSAL-FORMATTED_MAX_CHARACTERS' | translate: { number: 1024 } }})
          <span class="sr-required-mark">*</span>
        </div>
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error': !editDisasterForm.controls['summary'].valid && editDisasterForm.controls['summary'].touched
          }"
        >
          <rich-text-editor id="summary" [form]="editDisasterForm.controls['summary']"> </rich-text-editor>

          <div
            class="sr-error-area"
            *ngIf="!editDisasterForm.controls['summary'].valid && editDisasterForm.controls['summary'].touched"
          >
            <div *ngIf="editDisasterForm.controls['summary'].hasError('required')">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['summary'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 1024 } }}
            </div>
            <div *ngIf="editDisasterForm.controls['summary'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div class="input-field">
          <div class="col s12 m4 label-text grey-text">
            {{ 'DISASTER_COMMON-DISASTER_TYPE_LABEL' | translate }}
            <span class="sr-required-mark">*</span>
          </div>
          <div
            class="col s12 m6 l4"
            [ngClass]="{
              'sr-has-error':
                !editDisasterForm.controls['disasterType'].valid && editDisasterForm.controls['disasterType'].touched
            }"
          >
            <select
              id="disasterType"
              [formControl]="editDisasterForm.controls['disasterType']"
              class="browser-default my_select"
            >
              <option selected>
                {{ 'UNIVERSAL-FORM_CHOOSE_OPTION' | translate }}
              </option>

              <option *ngFor="let disasterType of disasterTypes">
                {{ disasterType }}
              </option>
            </select>

            <div
              class="sr-error-area"
              *ngIf="
                !editDisasterForm.controls['disasterType'].valid && editDisasterForm.controls['disasterType'].touched
              "
            >
              <div *ngIf="editDisasterForm.controls['disasterType'].hasError('required')">
                {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div
          class="input-field col s12"
          [ngClass]="{
            'sr-has-error':
              !editDisasterForm.controls['dateOfDisaster'].valid &&
              (editDisasterForm.controls['dateOfDisaster'].touched || editDisasterForm.controls['dateOfDisaster'].value)
          }"
        >
          <input
            id="dateOfDisaster"
            type="date"
            min="1000-01-01"
            max="3000-01-01"
            [formControl]="editDisasterForm.controls['dateOfDisaster']"
            class="datepicker"
          />
          <label for="dateOfDisaster" class="active">
            {{ 'DISASTER_COMMON-DISASTER_DATE_LABEL' | translate }}
            <span class="sr-required-mark">*</span>
          </label>

          <div
            class="sr-error-area"
            *ngIf="
              !editDisasterForm.controls['dateOfDisaster'].valid &&
              (editDisasterForm.controls['dateOfDisaster'].touched || editDisasterForm.controls['dateOfDisaster'].value)
            "
          >
            <div *ngIf="editDisasterForm.controls['dateOfDisaster'].hasError('required')">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['dateOfDisaster'].hasError('pastDate')">
              {{ 'UNIVERSAL-FORM_ERRORS_PAST_DATE' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['dateOfDisaster'].hasError('dateFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
            </div>
            <div *ngIf="editDisasterForm.controls['dateOfDisaster'].hasError('minimumYear')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_YEAR_TOO_LOW' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="row margin">
        <div class="input-field radio-button-spacing col s12">
          <p class="label-text grey-text">
            {{ 'DISASTER_COMMON-GLOBAL-DISASTER' | translate }}
          </p>

          <input
            name="global"
            id="global_yes"
            class="with-gap"
            type="radio"
            [value]="true"
            [formControl]="editDisasterForm.controls['global']"
          />
          <label for="global_yes">
            {{ 'UNIVERSAL-YES' | translate }}
          </label>
          <br />

          <input
            name="global"
            id="global_no"
            class="with-gap"
            type="radio"
            [value]="false"
            [formControl]="editDisasterForm.controls['global']"
          />
          <label for="global_no">
            {{ 'UNIVERSAL-NO' | translate }}
          </label>
        </div>
      </div>

      <!-- Country Multi Select Accordion Table -->
      <location-map-list
        [editDisasterForm]="this.editDisasterForm"
        (editDisasterFormEvent)="setCountryList($event)"
      ></location-map-list>

      <div class="row">
        <div class="right my_btns">
          <button
            (click)="onSubmit()"
            [disabled]="isSubmitting || !editDisasterForm.valid"
            type="submit"
            class="btn cyan"
            [ngClass]="{ disabled: isSubmitting || !editDisasterForm.valid }"
          >
            {{ 'UNIVERSAL-NAVIGATION_SUBMIT' | translate }}
          </button>

          <a class="btn cyan" (click)="onCancel()">
            {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
