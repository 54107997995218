import { Component, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../login/login.service';
import { NotificationsService } from 'angular2-notifications';
import { AppSettings } from '../../../../common/config';
import { DisasterOrgListService } from './disaster-org-list.service';
import { getErrorMessageFromRestCall } from '../../../../common/error';
import { DisasterAndContacts } from './disaster-specific-table.model';
import { DSSSurveyInputInterface } from '../survey-input-interface.model';
import { DisasterSpecificSurveyService } from '../disasterSpecificSurvey/disaster-specific-survey.service';

@Component({
  selector: 'disaster-specific-surveys-table',
  providers: [],
  templateUrl: './disaster-specific-surveys.component.html',
  styleUrls: ['../survey-table-common.css', './disaster-specific-surveys.component.css'],
})
export class DisasterSpecificSurveysTableComponent {
  disasterList: DisasterAndContacts[];

  @Output()
  selectedDisasterData: EventEmitter<DSSSurveyInputInterface> = new EventEmitter<DSSSurveyInputInterface>();
  selectedDisaster: DSSSurveyInputInterface;

  filterArg: string;

  isLoading: boolean = false;
  itemsPerPageArg: number = AppSettings.DSS_LIST_ITEMS_PER_PAGE;

  selectedPage: number = 0;

  constructor(
    private notificationsService: NotificationsService,
    public authService: AuthenticationService,
    private disasterOrgListService: DisasterOrgListService,
    private dssService: DisasterSpecificSurveyService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.loadSurveys();
  }

  loadSurveys() {
    this.isLoading = true;

    this.disasterOrgListService.getDisasters(this.authService.getProfile().organizationId).subscribe(
      (disasterData) => {
        this.isLoading = false;
        this.disasterList = disasterData.filter((disasterOrg) => !disasterOrg.disaster.inactive);

        // TODO: populateDSSSurveyData() makes a separate REST call for each disaster returned from getDisasters() which
        // is inefficient. Filed issue https://github.com/Disaster-Accountability-Project/SR-March2016/issues/1994
        this.populateDSSSurveyData();
      },
      (error) => {
        this.isLoading = false;

        this.notificationsService.error(
          this.translateService.instant('DisasterSpecificSurveysTableComponent-TITLE'),
          `Failed to load DSS table: ${getErrorMessageFromRestCall(error)}`,
          AppSettings.NOTIFICATIONS_ERROR_OPTIONS
        );
      }
    );
  }

  onDisasterSelect(selected: DisasterAndContacts): void {
    this.selectedDisaster = {
      disasterId: selected.disaster.disasterId,
      organizationId: selected.organization.organizationId,
      countryNamesCommaSeparated: selected.countryNamesCommaSeparated,
    };
    this.selectedDisasterData.emit(this.selectedDisaster);
  }

  populateDSSSurveyData(): void {
    this.disasterList.forEach((disasterOrg: DisasterAndContacts, index) => {
      this.isLoading = true;

      // Check if any surveys are available
      this.dssService
        .getMostRecentSurvey(disasterOrg.organization.organizationId, disasterOrg.disaster.disasterId)
        .subscribe((surveyResponse) => {
          this.isLoading = false;

          if (surveyResponse.surveySubmissionId) {
            this.disasterList[index].lastUpdated = surveyResponse.lastUpdatedDate;

            if (this.authService.getProfile().userId === surveyResponse.lastUpdatedUser.userId) {
              this.disasterList[index].ownSurvey = true;
              this.disasterList[index].lastUpdatedBy = this.translateService.instant(
                'SurveyTablesCommon-OWN_SURVEY_NAME'
              );
            } else {
              this.disasterList[index].ownSurvey = false;
              this.disasterList[index].lastUpdatedBy = this.translateService.instant('SurveyTablesCommon-PERSON_NAME', {
                firstName: surveyResponse.lastUpdatedUser.firstName,
                lastName: surveyResponse.lastUpdatedUser.lastName,
              });
            }
          }
        });
    });
  }
}
