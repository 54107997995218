import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Volunteer } from '../models/Volunteer';
import { map } from 'rxjs/operators';

@Injectable()
export class VolunteersPageService {
  constructor(private http: HttpClient) {}

  getVolunteers(): Observable<Volunteer[]> {
    return this.http
      .get<Volunteer[]>('./assets/volunteers/volunteers.json')
      .pipe(map((results) => results.sort((a, b) => a.orderGroup - b.orderGroup)));
  }

  getAcknowledgments(): Observable<any> {
    return this.http.get('./assets/volunteers/acknowledgments.json');
  }
}
