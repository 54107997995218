/**
 * modules, libraries, Angular
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

/**
 * guards
 */
import {
  CanActivateViaAuthGuard,
  CanActivateViaSuperAdminGuard,
  CanActivateViaOrgOrAboveAdminGuard,
  CanActivateViaLocalOrOrgAdminGuard,
} from '../common/auth.guard';

/**
 * components
 */
import { DashboardComponent } from './dashboard.component';
import { DashboardHomeComponent } from './servicesComponets/home/home.component';
import { DashboardServiceComponent } from './servicesComponets/service/service.component';
import { DashboardServiceBaseComponent } from './servicesComponets/service/base/base.component';
import { DashboardServiceEditComponent } from './servicesComponets/service/edit/edit.component';
import { DashboardServiceViewComponent } from './servicesComponets/service/view/view.component';
import { DashboardServiceCreateComponent } from './servicesComponets/service/create/create.component';
import { DashboardDisasterComponent } from './servicesComponets/disaster/disaster.component';
import { DashboardDisasterBaseComponent } from './servicesComponets/disaster/base/base.component';
import { DashboardDisasterEditComponent } from './servicesComponets/disaster/edit/edit.component';
import { DashboardDisasterViewComponent } from './servicesComponets/disaster/view/view.component';
import { DashboardDisasterListComponent } from './servicesComponets/disaster/list/list.component';
import { DashboardDisasterCreateComponent } from './servicesComponets/disaster/create/create.component';
import { DashboardOrganizationsComponent } from './servicesComponets/organizations/organizations.component';
import { DashboardOrganizationBaseComponent } from './servicesComponets/organizations/base/base.component';
import { DashboardOrganizationEditComponent } from './servicesComponets/organizations/edit/edit.component';
import { DashboardOrganizationViewComponent } from './servicesComponets/organizations/view/view.component';
import { DashboardOrganizationCreateComponent } from './servicesComponets/organizations/create/create.component';
import { CountryComponent } from './servicesComponets/country/country.component';
import { DashboardCountryBaseComponent } from './servicesComponets/country/base/base.component';
import { DashboardCountryEditComponent } from './servicesComponets/country/edit/edit.component';
import { DashboardCountryViewComponent } from './servicesComponets/country/view/view.component';
import { DashboardCountryCreateComponent } from './servicesComponets/country/create/create.component';
import { UserManagementComponent } from './servicesComponets/user_management/user_management.component';
import { DashboardUserManagementBaseComponent } from './servicesComponets/user_management/base/base.component';
import { DashboardUserManagementEditComponent } from './servicesComponets/user_management/edit/edit.component';
import { DashboardUserManagementViewComponent } from './servicesComponets/user_management/view/view.component';
import { DashboardUserManagementCreateComponent } from './servicesComponets/user_management/create/create.component';
import { DashboardDisasterNominateComponent } from './servicesComponets/disaster/nominate/nominate.component';
import {
  ManualSurveyBrowserAndEditorComponent,
  ManualGeneralOrganizationBackgroundSurveyBrowserAndEditorComponent,
  ManualLocationSpecificSurveyBrowserAndEditorComponent,
  ManualDisasterSpecificSurveyBrowserAndEditorComponent,
} from './servicesComponets/surveys/manualEdits';
import { ChangePasswordComponent } from './servicesComponets/changePassword/change-password.component';
import { DashboardIncomingMessagesComponent } from './servicesComponets/incomingMessages/incoming-messages.component';
import { SurveyNotificationsComponent } from './servicesComponets/surveyNotifications/surveyNotifications.component';
import { ListsContainerComponent } from './servicesComponets/configureLists/lists.component';

import { SurveyFooterComponent } from './servicesComponets/surveys/shared/survey-footer/survey-footer.component';

import { SurveyStepProgressBarComponent } from './servicesComponets/surveys/shared/survey-step-progress-bar/survey-step-progress-bar.component';

/* tslint:disable:max-line-length */
import { GeneralOrganizationalBackgroundSurveyComponent } from './servicesComponets/surveys/generalOrganizationBackground/general-organization-background.component';
import { GOBLocationOperationComponent } from './servicesComponets/surveys/generalOrganizationBackground/gob-steps/gob-location-operation/gob-location-operation.component';
import { GOBUserManagement } from './servicesComponets/surveys/generalOrganizationBackground/gob-steps/gob-user-management/gob-user-management';
import { DisasterSpecificSurveysTableComponent } from './servicesComponets/surveys/disasterSpecificTable/disaster-specific-surveys.component';
/* tslint:enable:max-line-length */

import {
  DSSStepListComponent,
  DSSStepComponent,
  DSSStep1Component,
  DSSStep2Component,
  DSSStep3Component,
  DSSStep4Component,
  DSSStep5Component,
  DSSStep6Component,
  DSSStep7Component,
  DisasterSpecificSurveyManager,
} from './servicesComponets/surveys/disasterSpecificSurvey';

import { DisasterSpecificSurveyComponent } from './servicesComponets/surveys/disasterSpecificSurvey/disaster-specific-survey.component';
import { DSSErrorListComponent } from './servicesComponets/surveys/disasterSpecificSurvey/dss-error-list/dss-error-list.component';
import { LocationSpecificSurveyComponent } from './servicesComponets/surveys/locationSpecificSurvey/location-specific-survey.component';

// tslint:disable-next-line:max-line-length
import { LocationSpecificSurveysTableComponent } from './servicesComponets/surveys/locationSpecificTable/location-specific-surveys.component';

import { LogoUploadComponent } from './servicesComponets/logoUpload/logo-upload.component';
import { ListComponent } from './servicesComponets/configureLists/list/list.component';
import { DashboardUserApprovalComponent } from './servicesComponets/userApproval/user-approval.component';
import { LastEditedMetadataComponent } from './servicesComponets/surveys/lastEditedFormMetadata/last-edited-metadata.component';
import { BreadcrumbsComponent } from './servicesComponets/breadcrumbs/breadcrumbs.component';
import { BlockingLoaderComponent } from './servicesComponets/blockingLoader/blocking-loader.component';

import { RichTextEditorComponent } from './servicesComponets/richTextEditor/rich-text-editor.component';

/**
 * services
 */
import { UserManagementService } from './servicesComponets/user_management/user_management.service';
import { ListComponentService } from './servicesComponets/configureLists/list/list.service';
import { ChangePasswordService } from './servicesComponets/changePassword/change-password.service';
import { IncomingMessagesService } from './servicesComponets/incomingMessages/incoming-messages.service';
import { LogoUploadService } from './servicesComponets/logoUpload/logo-upload.service';
import { PendingTasksService } from './servicesComponets/pendingTasks/pendingTasks.service';
import { DashboardRedirectService } from './servicesComponets/shared/dashboard-redirect.service';
import { UserApprovalService } from './servicesComponets/userApproval/user-approval.service';
import { DisasterOrgListService } from './servicesComponets/surveys/disasterSpecificTable/disaster-org-list.service';
import { LocationSpecificTableService } from './servicesComponets/surveys/locationSpecificTable/location-specific-table.service';
import { SharedServicesFeatureModule } from '../sharedServices/shared-services.module';
import { LocationMapModule } from '../common/locationMap/location-map.module';
import { LoaderModule } from '../common/loader/loader.module';
import { SurveyNotificationsService } from './servicesComponets/surveyNotifications/surveyNotifications.service';

// tslint:disable-next-line:max-line-length
import { LocationMapListComponent } from './servicesComponets/disaster/locationMapList/locationMapList.component';
import { EntireCountryConfirmDialogComponent } from './servicesComponets/disaster/locationMapList/entire-country-confirm-dialog/entire-country-confirm-dialog.component';
import { PendingTasksComponent } from './servicesComponets/pendingTasks/pendingTasks.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      /* milestone 2 and 3 routes: dashboards */
      {
        path: '',
        component: DashboardComponent,
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: '',
            component: DashboardHomeComponent,
          },
          {
            path: 'services',
            component: DashboardServiceComponent,
            canActivate: [CanActivateViaSuperAdminGuard],
            children: [
              { path: '', component: DashboardServiceBaseComponent },
              { path: 'edit/:id', component: DashboardServiceEditComponent },
              { path: 'view/:id', component: DashboardServiceViewComponent },
              { path: 'create', component: DashboardServiceCreateComponent },
              { path: '**', redirectTo: '', pathMatch: 'full' },
            ],
          },
          {
            path: 'disasters',
            component: DashboardDisasterComponent,
            children: [
              {
                path: '',
                component: DashboardDisasterBaseComponent,
                canActivate: [CanActivateViaSuperAdminGuard],
              },
              {
                path: 'edit/:id',
                component: DashboardDisasterEditComponent,
                canActivate: [CanActivateViaSuperAdminGuard],
              },
              {
                path: 'editnominated/:id',
                component: DashboardDisasterEditComponent,
                canActivate: [CanActivateViaSuperAdminGuard],
              },
              {
                path: 'view/:id',
                component: DashboardDisasterViewComponent,
                canActivate: [CanActivateViaSuperAdminGuard],
              },
              {
                path: 'viewnominated/:id',
                component: DashboardDisasterViewComponent,
                canActivate: [CanActivateViaSuperAdminGuard],
              },
              {
                path: 'create',
                component: DashboardDisasterCreateComponent,
                canActivate: [CanActivateViaSuperAdminGuard],
              },
              {
                path: 'nominate',
                component: DashboardDisasterNominateComponent,
                canActivate: [CanActivateViaLocalOrOrgAdminGuard],
              },
              { path: '**', redirectTo: '', pathMatch: 'full' },
            ],
          },
          {
            path: 'organizations',
            component: DashboardOrganizationsComponent,
            canActivate: [CanActivateViaSuperAdminGuard],
            children: [
              { path: '', component: DashboardOrganizationBaseComponent },
              { path: 'edit/:id', component: DashboardOrganizationEditComponent },
              { path: 'view/:id', component: DashboardOrganizationViewComponent },
              { path: 'create', component: DashboardOrganizationCreateComponent },
              { path: '**', redirectTo: '', pathMatch: 'full' },
            ],
          },
          {
            path: 'countries',
            component: CountryComponent,
            canActivate: [CanActivateViaSuperAdminGuard],
            children: [
              { path: '', component: DashboardCountryBaseComponent },
              { path: 'edit/:id', component: DashboardCountryEditComponent },
              { path: 'view/:id', component: DashboardCountryViewComponent },
              { path: 'create', component: DashboardCountryCreateComponent },
              { path: '**', redirectTo: '', pathMatch: 'full' },
            ],
          },
          {
            path: 'users',
            component: UserManagementComponent,
            canActivate: [CanActivateViaOrgOrAboveAdminGuard],
            children: [
              { path: '', component: DashboardUserManagementBaseComponent },
              { path: 'edit/:id', component: DashboardUserManagementEditComponent },
              { path: 'view/:id', component: DashboardUserManagementViewComponent },
              { path: 'create', component: DashboardUserManagementCreateComponent },
              { path: '**', redirectTo: '', pathMatch: 'full' },
            ],
          },
          {
            path: 'configure-lists',
            component: ListsContainerComponent,
            canActivate: [CanActivateViaSuperAdminGuard],
          },
          {
            path: 'messages',
            component: DashboardIncomingMessagesComponent,
            canActivate: [CanActivateViaSuperAdminGuard],
          },
          {
            path: 'surveyNotifications',
            component: SurveyNotificationsComponent,
            canActivate: [CanActivateViaLocalOrOrgAdminGuard],
          },
          {
            path: 'change-password',
            component: ChangePasswordComponent,
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: 'surveys/:type/:id',
            component: ManualSurveyBrowserAndEditorComponent,
            canActivate: [CanActivateViaSuperAdminGuard],
          },
          {
            path: 'surveys/gob/edit/:organizationId',
            component: ManualGeneralOrganizationBackgroundSurveyBrowserAndEditorComponent,
            canActivate: [CanActivateViaOrgOrAboveAdminGuard],
          },
          {
            path: 'surveys/lss/edit/:organizationId/:countryId',
            component: ManualLocationSpecificSurveyBrowserAndEditorComponent,
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: 'surveys/dss/edit/:organizationId/:disasterId',
            component: ManualDisasterSpecificSurveyBrowserAndEditorComponent,
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: 'pendingTasks',
            component: PendingTasksComponent,
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: '**',
            redirectTo: '',
            pathMatch: 'full',
          },
        ],
      },
    ]),
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [RouterModule],
  declarations: [EntireCountryConfirmDialogComponent],
  entryComponents: [EntireCountryConfirmDialogComponent],
})
export class DashboardRoutingModule {}

@NgModule({
  imports: [
    CommonModule,

    ImageCropperModule,

    DashboardRoutingModule,

    TranslateModule,

    SharedServicesFeatureModule,

    LocationMapModule,

    QuillModule.forRoot(),

    LoaderModule,
    MatExpansionModule,
    MatIconModule,
    MatDialogModule,
  ],
  providers: [
    UserManagementService,
    ListComponentService,
    ChangePasswordService,
    DashboardRedirectService,
    IncomingMessagesService,
    LogoUploadService,
    PendingTasksService,
    UserApprovalService,
    SurveyNotificationsService,

    DisasterOrgListService,
    LocationSpecificTableService,
    DisasterSpecificSurveyManager,
  ],
  declarations: [
    DashboardComponent,
    DashboardUserApprovalComponent,
    ChangePasswordComponent,
    ListsContainerComponent,
    ListComponent,
    DashboardHomeComponent,
    DashboardIncomingMessagesComponent,
    SurveyNotificationsComponent,
    LogoUploadComponent,

    DashboardServiceComponent,
    DashboardServiceBaseComponent,
    DashboardServiceEditComponent,
    DashboardServiceViewComponent,
    DashboardServiceCreateComponent,

    DashboardDisasterComponent,
    DashboardDisasterBaseComponent,
    DashboardDisasterEditComponent,
    DashboardDisasterViewComponent,
    DashboardDisasterCreateComponent,
    DashboardDisasterListComponent,
    DashboardDisasterNominateComponent,

    DashboardOrganizationsComponent,
    DashboardOrganizationBaseComponent,
    DashboardOrganizationEditComponent,
    DashboardOrganizationViewComponent,
    DashboardOrganizationCreateComponent,

    CountryComponent,
    DashboardCountryBaseComponent,
    DashboardCountryEditComponent,
    DashboardCountryViewComponent,
    DashboardCountryCreateComponent,

    UserManagementComponent,
    DashboardUserManagementBaseComponent,
    DashboardUserManagementEditComponent,
    DashboardUserManagementViewComponent,
    DashboardUserManagementCreateComponent,

    SurveyFooterComponent,
    SurveyStepProgressBarComponent,
    DSSErrorListComponent,
    DSSStepListComponent,
    DSSStepComponent,
    DSSStep1Component,
    DSSStep2Component,
    DSSStep3Component,
    DSSStep4Component,
    DSSStep5Component,
    DSSStep6Component,
    DSSStep7Component,
    DisasterSpecificSurveyComponent,
    DisasterSpecificSurveysTableComponent,
    GeneralOrganizationalBackgroundSurveyComponent,
    LocationSpecificSurveyComponent,
    LocationSpecificSurveysTableComponent,
    ManualSurveyBrowserAndEditorComponent,
    ManualDisasterSpecificSurveyBrowserAndEditorComponent,
    ManualGeneralOrganizationBackgroundSurveyBrowserAndEditorComponent,
    ManualLocationSpecificSurveyBrowserAndEditorComponent,
    GOBLocationOperationComponent,
    GOBUserManagement,
    LastEditedMetadataComponent,

    BlockingLoaderComponent,
    BreadcrumbsComponent,
    RichTextEditorComponent,
    LocationMapListComponent,
    PendingTasksComponent,
  ],
  exports: [RichTextEditorComponent],
})
export class DashboardFeatureModule {}
