import { FormControl } from '@angular/forms';

export function errSort(first, second) {
  // Sort by step number, then sort by question number
  if (first.stepNum < second.stepNum) {
    return -1;
  }
  if (first.stepNum > second.stepNum) {
    return 1;
  }
  if (first.stepNum === second.stepNum) {
    if (first.questionNum < second.questionNum) {
      return -1;
    }
    if (first.questionNum > second.questionNum) {
      return 1;
    }
  }
  return 0;
}
