import { Component, OnChanges, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialNetworkService } from './socialNetwork.service';

@Component({
  selector: 'social-network',
  templateUrl: './socialNetwork.component.html',
  styleUrls: ['./socialNetwork.component.css'],
  providers: [SocialNetworkService],
})
export class SocialNetworkComponent implements OnChanges, OnInit, OnDestroy {
  @Input('disasterId') disasterId: string;
  @Input('locationId') locationId: string;
  @Input('orgId') orgId: string;

  public tweets$: Observable<any>;
  public news$: Observable<any>;

  constructor(private socialNetworkService: SocialNetworkService) {}

  ngOnChanges() {}

  ngOnInit() {
    this.tweets$ = this.disasterId
      ? this.socialNetworkService.getDisasterTweets(this.orgId, this.disasterId)
      : this.socialNetworkService.getLocationTweets(this.orgId, this.locationId);

    this.news$ = this.disasterId
      ? this.socialNetworkService.getDisasterNews(this.orgId, this.disasterId)
      : this.socialNetworkService.getLocationNews(this.orgId, this.locationId);
  }

  ngOnDestroy() {}
}
