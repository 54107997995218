<img
  [ngClass]="{ 'sr-link': authService.isOrgGeneral() || (authService.isSuper() && orgId) }"
  (click)="openModal()"
  alt="avatar"
  class="circle responsive-img profile-image center"
  width="64"
  height="64"
  [src]="logoUri"
/>

<div
  id="logo-upload-modal"
  class="modal"
  materialize="modal"
  [materializeParams]="[{ dismissible: true }]"
  [materializeActions]="modalActions"
>
  <div class="modal-content">
    <div class="card-title center white-text cyan">
      {{ 'LogoUploadComponent-LOGO-UPLOAD-TITLE' | translate }}
    </div>
    <div class="grey lighten-3 card-text-wrapper">
      <ng-template [ngIf]="isSubmitting">
        <div class="preloader-wrapper medium active loader">
          <div class="spinner-layer spinner-blue-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div>
            <div class="gap-patch">
              <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="!isSubmitting">
        <p *ngIf="!imageChangedEvent" class="new_img">
          <br />
          {{ 'LogoUploadComponent-NEW-IMG-TEXT' | translate }}
          <br /><br />
        </p>

        <div *ngIf="imageChangedEvent" class="file-path-wrapper">
          <div>
            <p>{{ 'LogoUploadComponent-LOGO_INFO' | translate }}<br /></p>
            <img [className]="'circle'" [src]="croppedImage" />
          </div>
          <div [className]="'photo_wrapper'">
            <p>{{ 'LogoUploadComponent-CROP_INFO' | translate }}<br /></p>
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="1 / 1"
              [resizeToWidth]="256"
              format="png"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="uploadButton.scrollIntoView()"
            ></image-cropper>
          </div>
        </div>
        <div class="file-field input-field">
          <div class="btn cyan">
            <span>{{ 'UNIVERSAL-FILE' | translate }}</span>
            <input (change)="onFileSelected($event)" type="file" />
          </div>
          <input class="file-path validate" type="text" />
        </div>
        <p class="sr-img-guidelines">
          {{ 'LogoUploadComponent-MAXIMUM_IMAGE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />

          {{ 'LogoUploadComponent-RESIZE-IMG-WARNING' | translate }}<br />

          {{ 'LogoUploadComponent-DEFAULT_ALLOWED_IMAGE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
        </p>
      </ng-template>

      <div class="upload-error sr-error-msg left-align" *ngIf="error">
        {{ error }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="logoUri"
      class="waves-effect waves-light btn red left"
      (click)="onDeleteLogo()"
      [disabled]="isSubmitting"
    >
      {{ 'LogoUploadComponent-DELETE_LOGO_BUTTON' | translate }}
    </button>
    <button
      #uploadButton
      [disabled]="!imageChangedEvent || isSubmitting"
      class="waves-effect waves-light btn green"
      (click)="onUploadFile()"
    >
      {{ 'LogoUploadComponent-UPLOAD-LOGO' | translate }}
    </button>

    <button class="modal-close waves-effect waves-light btn cyan" (click)="onUploadCancel()" [disabled]="">
      {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
    </button>
  </div>
</div>
