<div class="row">
  <ng-template [ngIf]="authService.isOrgGeneral() || authService.isLocation()">
    <div class="col s12 m12">
      <div class="card">
        <div class="card-content white-text">
          <div class="card-title center cyan">
            <p>{{ organizationName }}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngIf]="authService.isOrgGeneral() || authService.isLocation()">
    <div class="col s12 m6">
      <div class="card">
        <div class="card-content">
          <div class="white-text card-title center cyan">
            {{ 'Dashboard-SurveyComponent-TITLE' | translate }}
          </div>
          <ul class="collapsible" materialize="collapsible">
            <li *ngIf="authService.isOrgGeneral()">
              <div class="collapsible-header" (click)="loadComponent('LOGO')">
                <i class="material-icons">account_circle</i>
                <i class="material-icons right chevron-right">chevron_right</i>
                <span class="truncate">{{ 'Dashboard-SurveyComponent-STEP1-TITLE' | translate }}</span>
              </div>
            </li>
            <li *ngIf="authService.isOrgGeneral()">
              <div
                class="collapsible-header"
                (click)="loadComponent('BACKGROUND_SURVEY')"
                [ngClass]="{ active: surveyType() == 'GOB' }"
              >
                <i class="material-icons">business</i>
                <i class="material-icons right chevron-right">chevron_right</i>
                <span class="truncate">{{ 'Dashboard-SurveyComponent-STEP2-TITLE' | translate }}</span>
              </div>
            </li>
            <li>
              <div id="lSSurveys" class="collapsible-header">
                <i class="material-icons">location_on</i>
                <i class="material-icons right sr-link hide-on-closed">keyboard_arrow_down</i>
                <i class="material-icons right sr-link hide-on-opened">keyboard_arrow_up</i>
                <span class="truncate">{{ 'Dashboard-SurveyComponent-STEP3-TITLE' | translate }}</span>
              </div>
              <div class="collapsible-body">
                <location-specific-surveys-table
                  (selectedCountry)="onSelectedCountry($event)"
                ></location-specific-surveys-table>
              </div>
            </li>
            <li>
              <div class="collapsible-header" [ngClass]="{ active: surveyType() == 'DSS' }">
                <i class="material-icons">warning</i>
                <i class="material-icons right sr-link hide-on-closed">keyboard_arrow_down</i>
                <i class="material-icons right sr-link hide-on-opened">keyboard_arrow_up</i>
                <span class="truncate">{{ 'Dashboard-SurveyComponent-STEP4-TITLE' | translate }}</span>
              </div>

              <div class="collapsible-body">
                <disaster-specific-surveys-table
                  (selectedDisasterData)="onSelectedDisaster($event)"
                ></disaster-specific-surveys-table>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="authService.isOrgGeneral() || authService.isLocation()">
    <div class="col s12 m6">
      <div id="panelLogo" class="card" *ngIf="componentToLoad === 'LOGO' && authService.isOrgGeneral()">
        <div class="card-content center-align">
          <div class="white-text card-title center cyan">
            {{ 'LogoUploadComponent-LOGO-UPLOAD-TITLE' | translate }}
          </div>
          <div class="divider"></div>
          <p class="left-align">
            {{ 'LogoUploadComponent-LOGO-UPLOAD-INSTRUCTION' | translate }}
          </p>
          <logo-upload
            [logoUri]="logoUri"
            [organizationId]="authService.getProfile().organizationId"
            (logoChanged)="logoUri = $event"
          >
          </logo-upload>
        </div>
      </div>

      <div id="panelGob" *ngIf="componentToLoad === 'BACKGROUND_SURVEY' && authService.isOrgGeneral()">
        <general-organization-background-survey
          [surveyInterface]="selectedOrganizationValue"
          (refreshTablesAction)="updateTables($event)"
          (openLSSModal)="onGoToLSS($event)"
          [openWith]="openGobQuestion"
          (surveyOpened)="onSurveyOpened('BACKGROUND_SURVEY')"
          (surveySaved)="onSurveySaved()"
        >
        </general-organization-background-survey>
      </div>

      <div id="panelLss" *ngIf="componentToLoad === 'LSS_SURVEY' && selectedCountryValue">
        <location-specific-survey
          [surveyInterface]="selectedCountryValue"
          [allowClosing]="false"
          [openWith]="openLssQuestion"
          (closeLSS)="selectedCountryValue = null"
          (surveyOpened)="onSurveyOpened('LSS_SURVEY')"
          (surveySaved)="onSurveySaved()"
        >
        </location-specific-survey>
      </div>

      <div id="panelDss" *ngIf="componentToLoad === 'DSS_SURVEY' && selectedDisasterValue">
        <disaster-specific-survey
          [surveyInterface]="selectedDisasterValue"
          [allowClosing]="false"
          (closeDSS)="selectedDisasterValue = null"
        >
        </disaster-specific-survey>
      </div>
    </div>
  </ng-template>

  <div class="col s12 m12" *ngIf="authService.isSuper()">
    <user-approval></user-approval>
  </div>
</div>
<div class="row">
  <ng-template [ngIf]="authService.isOrgGeneral() || authService.isLocation()">
    <div class="col s12 m6">
      <div class="card">
        <div class="card-content">
          <div class="white-text card-title center deep-purple lighten-2">
            {{ 'Dashboard-SurveyComponent-PENDING-TASKS-TITLE' | translate }}
          </div>
          <ul class="collapsible" materialize="collapsible">
            <li *ngIf="authService.isOrgGeneral() || authService.isLocation()">
              <div class="collapsible-header active">
                <div id="panelPT" class="collapsible-header" (click)="loadComponent('PENDING_TASKS')">
                  <i class="material-icons">assignment</i>
                  <i class="material-icons right sr-link hide-on-closed">keyboard_arrow_down</i>
                  <i class="material-icons right sr-link hide-on-opened">keyboard_arrow_up</i>
                  <span class="truncate">{{ 'Dashboard-SurveyComponent-PENDING-TASKS' | translate }}</span>
                </div>
              </div>
              <div class="collapsible-body">
                <pending-tasks (click)="getInputParams()"> </pending-tasks>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
</div>
