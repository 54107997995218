<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_1' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_1_1' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_1_2' | translate }}
  </div>
</div>
<div class="general">
  {{ 'FAQ-GENERAL-INFO_1' | translate
  }}<a href="http://www.disasteraccountability.org/donate">http://www.disasteraccountability.org/donate</a>
  {{ 'FAQ-GENERAL-INFO_2' | translate }}
</div>
<div class="row">
  <div class="question general">
    {{ 'FAQ-GENERAL-QUESTION_2' | translate }}
  </div>
  <ul *ngIf="faqType" class="collapsible general" materialize="collapsible" data-collapsible="accordion">
    <li>
      <div [ngClass]="{ active: faqType === 'donor' }" class="collapsible-header sr-bold blue-text header-text">
        <i class="material-icons">expand_more</i> {{ 'FAQ-DONOR' | translate }}
      </div>
      <div class="collapsible-body style-collapsible-body">
        <div class="card grey lighten-4 donor">
          <div class="question donor-body">
            {{ 'FAQ-DONOR-TITLE-TEXT' | translate }}
            <a [routerLink]="['/subscribe']">
              {{ 'FAQ-DONOR-LINK' | translate }}
            </a>
          </div>
          <div class="donor-body">
            <div class="answer sr-justify">
              {{ 'FAQ-DONOR-INFO-TEXT_1' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-DONOR-INFO-TEXT_2' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-DONOR-INFO-TEXT_3' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-DONOR-INFO-TEXT_4' | translate }}
            </div>
          </div>
          <div class="sr-bold donor-body">
            {{ 'FAQ-DONOR-END-TEXT' | translate }}
            <a href="mailto:smartresponse@disasteraccountability.org">
              {{ 'FAQ-DONOR-LINK' | translate }}
            </a>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div [ngClass]="{ active: faqType === 'media' }" class="collapsible-header sr-bold blue-text header-text">
        <i class="material-icons">expand_more</i> {{ 'FAQ-MEDIA' | translate }}
      </div>
      <div class="collapsible-body style-collapsible-body">
        <div class="card grey lighten-4 donor">
          <div class="question donor-body">
            {{ 'FAQ-MEDIA-HOW-TO-HELP' | translate }}
          </div>
          <div class="donor-body">
            <div class="answer sr-justify">
              {{ 'FAQ-MEDIA-HOW-TO-HELP-INFO_1' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-MEDIA-HOW-TO-HELP-INFO_2' | translate }}
            </div>
          </div>
          <div class="sub-section">
            <div class="question donor-body">
              {{ 'FAQ-MEDIA-HOW-TO-HELP-LSS-TITLE' | translate }}
            </div>
            <ul class="donor-body">
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-LSS_1' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-LSS_2' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-LSS_3' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-LSS_4' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-LSS_5' | translate }}
              </li>
            </ul>
            <div class="question donor-body">
              {{ 'FAQ-MEDIA-HOW-TO-HELP-DSS-TITLE' | translate }}
            </div>
            <ul class="donor-body">
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-DSS_1' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-DSS_2' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-DSS_3' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-DSS_4' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-DSS_5' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-DSS_6' | translate }}
              </li>
              <li class="answer sr-justify">
                {{ 'FAQ-MEDIA-HOW-TO-HELP-DSS_7' | translate }}
              </li>
            </ul>
          </div>
          <div class="question donor-body">
            {{ 'FAQ-MEDIA-HOW-TO-ON-THE-GROUND' | translate }}
          </div>
          <div class="donor-body">
            <div class="answer sr-justify">
              {{ 'FAQ-MEDIA-HOW-TO-ON-THE-GROUND-INFO_1' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-MEDIA-HOW-TO-ON-THE-GROUND-INFO_2' | translate }}
            </div>
          </div>
          <div class="question donor-body">
            {{ 'FAQ-MEDIA-HOW-TO-RELIEF' | translate }}
          </div>
          <div class="donor-body">
            <div class="answer sr-justify">
              {{ 'FAQ-MEDIA-HOW-TO-RELIEF-INFO_1' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-MEDIA-HOW-TO-RELIEF-INFO_2' | translate }}
            </div>
          </div>
          <div class="question donor-body">
            {{ 'FAQ-MEDIA-HOW-TO-ANI-STORY' | translate }}
          </div>
          <div class="donor-body">
            <div class="answer sr-justify">
              {{ 'FAQ-MEDIA-HOW-TO-ANI-STORY-INFO_1' | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div [ngClass]="{ active: faqType === 'survivor' }" class="collapsible-header sr-bold blue-text header-text">
        <i class="material-icons">expand_more</i> {{ 'FAQ-SURVIVOR' | translate }}
      </div>
      <div class="collapsible-body style-collapsible-body">
        <div class="card grey lighten-4 donor">
          <div class="donor-body">
            <div class="question">
              {{ 'FAQ-SURVIVOR-HOW-TO-RESPONDING' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-SURVIVOR-HOW-TO-RESPONDING-ANSWER' | translate }}
            </div>
            <div class="question">
              {{ 'FAQ-SURVIVOR-HOW-TO-REPORT' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-SURVIVOR-HOW-TO-REPORT-ANSWER' | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div [ngClass]="{ active: faqType === 'org' }" class="collapsible-header sr-bold blue-text header-text">
        <i class="material-icons">expand_more</i> {{ 'FAQ-AID-ORGANIZER' | translate }}
      </div>
      <div class="collapsible-body style-collaspsible-body">
        <div class="card grey lighten-4 donor">
          <div class="donor-body">
            <div class="question">{{ 'FAQ-AID-ORGANIZATION-WELCOME' | translate }}</div>
            <div class="answer">{{ 'FAQ-AID-ORGANIZATION-TITLE-TEXT' | translate }}</div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_1' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_1_1' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_1_2' | translate: { currentYear: currentYear } }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_1_3' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_1_4' | translate }}
              <ul class="donor-body">
                <li class="donor-body">
                  {{ 'FAQ-AID-ORGANIZATION-ANSWER_1_4LIST_1' | translate }}
                </li>
                <li class="donor-body">
                  {{ 'FAQ-AID-ORGANIZATION-ANSWER_1_4LIST_2' | translate }}
                </li>
                <li class="donor-body">
                  {{ 'FAQ-AID-ORGANIZATION-ANSWER_1_4LIST_3' | translate }}
                </li>
              </ul>
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_1_5' | translate }}
            </div>
            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_2' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_2_1' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_2_2' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_2_3' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_3' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_3_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_4' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_4_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_5' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_5_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_6' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_6_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_7' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_7_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_8' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_8_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_9' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_9_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_10' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_10_1' | translate }}
              <a href="mailto:support@smartresponse.org">support@smartresponse.org</a>
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_11' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_11_1' | translate }}
              <ol>
                <li>
                  {{ 'FAQ-AID-ORGANIZATION-ANSWER_11_1LIST_1' | translate }}
                </li>
                <li>
                  {{ 'FAQ-AID-ORGANIZATION-ANSWER_11_1LIST_2' | translate }}
                </li>
              </ol>
            </div>

            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_11_2' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_12' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_12_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_13' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_13_1' | translate }}
            </div>

            <div class="question">
              {{ 'FAQ-AID-ORGANIZATION-QUESTION_14' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-AID-ORGANIZATION-ANSWER_14_1' | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div [ngClass]="{ active: faqType === 'gov' }" class="collapsible-header sr-bold blue-text header-text">
        <i class="material-icons">expand_more</i> {{ 'FAQ-GOVERNMENT' | translate }}
      </div>
      <div class="collapsible-body style-collapsible-body">
        <div class="card grey lighten-4 donor">
          <div class="donor-body">
            <div class="answer sr-justify">
              {{ 'FAQ-GOVERNMENT-INFO_1' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-GOVERNMENT-INFO_2' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-GOVERNMENT-INFO_3' | translate }}
            </div>
            <div class="answer sr-justify">
              {{ 'FAQ-GOVERNMENT-INFO_4' | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_3' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_3_1' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_3_2' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_3_3' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_3_4' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_3_5' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_4' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_4_1' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_4_2' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_4_3' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_4_4' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_5' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_5_1' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_5_2' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_6' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_6_1' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_6_2' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_6_3' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_7' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_7_1' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_8' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_8_1' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_8_2' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_9' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_9_1' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_10' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_10_1' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">
    {{ 'FAQ-GENERAL-QUESTION_11' | translate }}
  </div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ANSWER_11_1' | translate }}
  </div>
</div>
<div class="row general">
  <div class="question">{{ 'FAQ-GENERAL-ATTRIBUTION' | translate }}</div>
  <div class="answer sr-justify">
    {{ 'FAQ-GENERAL-ATTRIBUTION-INFO_1' | translate }} (<a
      href="https://www.cia.gov/library/publications/resources/the-world-factbook/"
      target="_blank"
      >https://www.cia.gov/library/publications/resources/the-world-factbook/</a
    >) {{ 'FAQ-GENERAL-ATTRIBUTION-INFO_2' | translate }} (<a href="http://reliefweb.int/disasters" target="_blank"
      >http://reliefweb.int/disasters</a
    >) {{ 'FAQ-GENERAL-ATTRIBUTION-INFO_3' | translate }}(<a href="https://www.50states.com" target="_blank"
      >https://www.50states.com</a
    >){{ 'FAQ-GENERAL-ATTRIBUTION-INFO_4' | translate }} (<a href="https://www.city-data.com/states/" target="_blank"
      >https://www.city-data.com/states/</a
    >) {{ 'FAQ-GENERAL-ATTRIBUTION-INFO_5' | translate }} (<a href="https://www.infoplease.com/" target="_blank"
      >https://www.infoplease.com/</a
    >) {{ 'FAQ-GENERAL-ATTRIBUTION-INFO_6' | translate }} (<a
      href="https://en.wikipedia.org/wiki/Main_Page"
      target="_blank"
      >https://en.wikipedia.org/wiki/Main_Page</a
    >) {{ 'FAQ-GENERAL-ATTRIBUTION-INFO_7' | translate }} (<a href="https://www.fema.gov/states/" target="_blank"
      >https://www.fema.gov/states/</a
    >){{ 'FAQ-GENERAL-ATTRIBUTION-INFO_8' | translate }} <strong>(WF)</strong
    >{{ 'FAQ-GENERAL-ATTRIBUTION-INFO_9' | translate }} <strong>(RW)</strong
    >{{ 'FAQ-GENERAL-ATTRIBUTION-INFO_10' | translate }} <strong>(50)</strong
    >{{ 'FAQ-GENERAL-ATTRIBUTION-INFO_11' | translate }} <strong>(CD)</strong
    >{{ 'FAQ-GENERAL-ATTRIBUTION-INFO_12' | translate }} <strong>(I)</strong
    >{{ 'FAQ-GENERAL-ATTRIBUTION-INFO_13' | translate }} <strong>(WK)</strong>
    {{ 'FAQ-GENERAL-ATTRIBUTION-INFO_14' | translate }} <strong>(FEMA)</strong>.
  </div>
</div>
