import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../validators/emailValidator';
import { ShortUrlService } from './short-url.service';
import { Observable, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'shortUrl',
  templateUrl: './short-url.component.html',
})
export class ShortUrlComponent implements OnDestroy {
  private destroyed = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private router: Router, private shortUrlService: ShortUrlService) {
    this.findShortUrl();
  }

  private findShortUrl() {
    this.route.url
      .pipe(
        map((url) => url[0].path),
        switchMap((path) => this.shortUrlService.findHashTag(path)),
        filter((response) => response.statusMessage === 'Success'),
        map((response) => response.responseData),
        map((data) =>
          /* don't use in keyword here...it returns true for undefined!! */
          !!data.disaster
            ? `./disaster/${data.disaster.disasterId}`
            : !!data.country
            ? `./location/${data.country.countryId}`
            : null
        ),
        tap((route) => (!!route ? this.router.navigate([route]) : this.router.navigate(['./page-not-found']))),
        takeUntil(this.destroyed),
        catchError(() => this.router.navigate(['./page-not-found']))
      )
      .subscribe(
        () => {},
        () => {}
      );
  }

  ngOnDestroy() {
    this.destroyed.next(true);
  }
}
