import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../common/config';

const ORGANIZATION_COUNT_URL = AppSettings.API_SERVER + '/smart-response/admin/organization/count';

@Injectable()
export class DisasterAboutService {
  constructor(private http: HttpClient) {}

  getOrganizationCount(organizationType: number, disasterId: number): Observable<any> {
    return this.http.get(`${ORGANIZATION_COUNT_URL}/${disasterId}/${organizationType}`);
  }

  getWidgetText(disasterId: number) {
    // tslint:disable-next-line:max-line-length
    return `<div class="smart-response-widget" data-disaster-id="${disasterId}"></div> <script src="${AppSettings.BASE_URL}/assets/widget/OrgData/widget.js"></script>`;
  }
}
