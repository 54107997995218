<div class="card">
  <div class="card-content black-text">
    <div class="card-text-wrapper grey lighten-3">
      <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
        <thead>
          <tr class="sr-base-table-row">
            <th *ngFor="let head of headElements; let i = index" (click)="setSortedField(head.sortName)">
              {{ head.name | translate }}
              <i class="tiny material-icons">swap_vert</i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let msg of allServices
                | genericSortPipe: sortField:sortDescending
                | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
            "
          >
            <td>
              <a
                [routerLink]="['/dashboard/']"
                [queryParams]="{
                  disaster: msg.disasterId,
                  country: msg.countryId,
                  organization: msg.organizationId,
                  surveyType: msg.surveyType,
                  stepNumber: msg.stepNumber,
                  questionNumber: msg.questionNumber
                }"
                >{{ msg.surveyName }}</a
              >
            </td>
            <td>{{ msg.surveyTypeFull }}</td>
            <td>{{ msg.stepNumber }}</td>
            <td>{{ msg.questionNumber }}</td>
            <td>{{ msg.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="col s12 m12">
  <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
</div>
