import { AppSettings } from '../common/config';
import { Collection } from './Collection';

/**
 * model for a sum of money
 */
export interface Money {
  currency: string;
  amount?: number;
  fund?: number;
  hasValue?: boolean;
}

/**
 * model for retaining raw Money data before and after currency conversions
 */
export class ConvertedMoney {
  private initialMoney: Money;
  private convertedMoney: Money;

  /**
   * If `initial`'s amount/fund and currency aren't provided, `ConvertedMoney`'s `initial` member will have a null amount and use the
   * app's default currency.
   * If either `converted.amount` or `converted.currency` are not provided, `ConvertedMoney`'s `converted` member will use `initial`'s
   * value.
   *
   * @param initial the money amount before conversion
   * @param converted the money amount after conversion
   */
  constructor(initial: Money, converted?: Money) {
    const initialMoney: Money = this.normalizeMoney(initial);

    this.initialMoney = initialMoney;
    this.convertedMoney = null;

    if (
      converted !== null &&
      converted !== undefined &&
      converted.amount !== null &&
      converted.amount !== undefined &&
      converted.currency
    ) {
      this.convertedMoney = converted;
    }
  }

  get initial(): Money {
    return this.initialMoney;
  }

  get converted(): Money {
    return this.convertedMoney ? this.convertedMoney : this.initialMoney;
  }

  set converted(money: Money) {
    this.convertedMoney = money;
  }

  /**
   * standardizes an input Money object to
   * 1) use the 'amount' property to contain the amount
   * 2) use a default value where no value is provided
   *
   * @param money the Money object to normalize
   * @returns a standardized Money object
   */
  private normalizeMoney(money: Money): Money {
    const initial: Money = {
      amount: null,
      currency: AppSettings.DEFAULT_CURRENCY,
    };
    if (money !== null && money !== undefined) {
      if (money.hasOwnProperty('fund')) {
        const hasFund: boolean = money.fund !== null && money.fund !== undefined;

        initial.amount = hasFund ? money.fund : null;
        initial.currency = hasFund && money.currency ? money.currency : AppSettings.DEFAULT_CURRENCY;
      } else if (money.hasOwnProperty('amount')) {
        const hasAmt: boolean = money.amount !== null && money.amount !== undefined;

        initial.amount = hasAmt ? money.amount : null;
        initial.currency = hasAmt && money.currency ? money.currency : AppSettings.DEFAULT_CURRENCY;
      }
    }
    return initial;
  }
}

/**
 * model for the output of a service that converts a collection of money
 */
export interface MoneyConversionOutput {
  money: Collection<ConvertedMoney>;
  currency: string;
  hasError: boolean;
  unconverted: string[];
}
