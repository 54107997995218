<div *ngIf="editUserForm" class="card">
  <app-loader [loading]="loading"></app-loader>
  <div class="card-content black-text">
    <div class="row user-title">
      <h5>
        {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
        <span class="sr-italic">
          {{
            'DashboardUserManagementEditComponent-TITLE_MIXIN'
              | translate: { firstName: currentUser.firstName, lastName: currentUser.lastName }
          }}
        </span>
        {{ 'UNIVERSAL-INFORMATION_TEXT' | translate }}
      </h5>
    </div>

    <div class="divider"></div>

    <div class="sr-required-notif padded">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>

    <br />

    <!-- First Name field -->
    <div class="row">
      <label for="firstName" class="sr-label">
        {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
        <span class="sr-required-mark">*</span>
      </label>
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !editUserForm.controls['firstName'].valid && editUserForm.controls['firstName'].touched
        }"
      >
        <input
          id="firstName"
          type="text"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="editUserForm.controls['firstName']"
          [placeholder]="'UNIVERSAL-CONTACT_FIRST_NAME' | translate"
        />
        <div
          class="sr-error-area"
          *ngIf="!editUserForm.controls['firstName'].valid && editUserForm.controls['firstName'].touched"
        >
          <div *ngIf="editUserForm.controls['firstName'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="editUserForm.controls['firstName'].value.length > 0">
            <div *ngIf="editUserForm.controls['firstName'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
            </div>
            <div *ngIf="editUserForm.controls['firstName'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="editUserForm.controls['firstName'].hasError('nameFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NAME' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Last Name field -->
    <div class="row">
      <label for="lastName" class="sr-label">
        {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
        <span class="sr-required-mark">*</span>
      </label>
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !editUserForm.controls['lastName'].valid && editUserForm.controls['lastName'].touched
        }"
      >
        <input
          id="lastName"
          type="text"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="editUserForm.controls['lastName']"
          [placeholder]="'UNIVERSAL-CONTACT_LAST_NAME' | translate"
        />
        <div
          class="sr-error-area"
          *ngIf="!editUserForm.controls['lastName'].valid && editUserForm.controls['lastName'].touched"
        >
          <div *ngIf="editUserForm.controls['lastName'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="editUserForm.controls['lastName'].value.length > 0">
            <div *ngIf="editUserForm.controls['lastName'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
            </div>
            <div *ngIf="editUserForm.controls['lastName'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="editUserForm.controls['lastName'].hasError('nameFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NAME' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Email field -->
    <div class="row">
      <label for="email" class="sr-label">
        {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
        <span class="sr-required-mark">*</span>
      </label>
      <div class="input-field col s12">
        <input
          id="email"
          type="text"
          [formControl]="editUserForm.controls['email']"
          [placeholder]="'UNIVERSAL-CONTACT_EMAIL' | translate"
        />
      </div>
    </div>

    <!-- phoneNumber field -->
    <div class="row">
      <label class="sr-label" for="phoneNumber">
        {{ 'UNIVERSAL-CONTACT_PHONE' | translate }}
        <span class="sr-required-mark">*</span>
      </label>
      <div
        class="input-field col s12 m12"
        [ngClass]="{
          'sr-has-error': !editUserForm.controls['phoneNumber'].valid && editUserForm.controls['phoneNumber'].touched
        }"
      >
        <input
          id="phoneNumber"
          type="text"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="editUserForm.controls['phoneNumber']"
          [placeholder]="'UNIVERSAL-CONTACT_PHONE' | translate"
        />

        <div
          class="sr-error-area"
          *ngIf="!editUserForm.controls['phoneNumber'].valid && editUserForm.controls['phoneNumber'].touched"
        >
          <div *ngIf="editUserForm.controls['phoneNumber'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="editUserForm.controls['phoneNumber'].errors.phoneFormat">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PHONE' | translate }}
          </div>
        </div>
      </div>
    </div>

    <!-- User Type field -->
    <div class="row">
      <div class="input-field col s12">
        <select id="userType" [formControl]="editUserForm.controls['userType']" class="browser-default org-select">
          <option value="1" *ngIf="editUserForm.controls['userType'].value === 1">
            {{ userTypeToName[1] }}
          </option>
          <option value="2">
            {{ userTypeToName[2] }}
          </option>
          <option value="3">
            {{ userTypeToName[3] }}
          </option>
        </select>
        <label for="userType" class="org-label">
          {{ 'UserManagementCommon-USER_TYPE' | translate }}
          <span class="sr-required-mark">*</span>
        </label>
      </div>
    </div>

    <!-- Enabled field -->
    <div class="row">
      <div class="col s12 m7">
        <div class="col s5 m3 user-pad">
          {{ 'UserManagementCommon-ENABLED' | translate }}
          <span class="sr-required-mark">*</span>
        </div>
        <div class="col s5 m4 l3 user-pad">
          <input
            id="active"
            type="radio"
            name="enabled"
            value="true"
            class="with-gap"
            [value]="true"
            [formControl]="editUserForm.controls['enabled']"
          />
          <label class="center-align" for="active">
            {{ 'UNIVERSAL-ENABLED' | translate }}
          </label>
          <input
            id="inactive"
            type="radio"
            name="enabled"
            value="false"
            class="with-gap"
            [value]="false"
            [formControl]="editUserForm.controls['enabled']"
          />
          <label class="center-align" for="inactive">
            {{ 'UNIVERSAL-DISABLED' | translate }}
          </label>
        </div>
      </div>
    </div>

    <ng-template [ngIf]="authService.isSuper()">
      <!-- Activated field -->
      <div class="row" [hidden]="GOB_COMPONENT">
        <div class="col s12 m7">
          <div class="col s5 m3 user-pad">
            {{ 'UserManagementCommon-ACTIVATED' | translate }}
            <span class="sr-required-mark">*</span>
          </div>
          <div class="col s6 m6 l4 user-pad">
            <input
              id="activated-id"
              type="radio"
              name="activated"
              value="true"
              class="with-gap"
              [value]="true"
              [formControl]="editUserForm.controls['activated']"
            />
            <label class="center-align" for="activated-id">
              {{ 'UNIVERSAL-ACTIVATED' | translate }}
            </label>
            <input
              id="deactivated-id"
              type="radio"
              name="activated"
              value="false"
              class="with-gap"
              [value]="false"
              [formControl]="editUserForm.controls['activated']"
            />
            <label for="deactivated-id">
              {{ 'UNIVERSAL-NOT_YET_ACTIVATED' | translate }}
            </label>
          </div>
        </div>
      </div>

      <!-- Organizations -->
      <div
        [hidden]="GOB_COMPONENT"
        class="row"
        *ngIf="userType == userTypeEnum.ORG_GENERAL || userType == userTypeEnum.LOCATION"
      >
        <div class="input-field col s12 m12">
          <select
            id="organizationSelect"
            [formControl]="editUserForm.controls['organizationId']"
            class="browser-default org-select"
          >
            <option *ngFor="let org of organizations" [value]="org.organizationId">
              {{ org.name }}
            </option>
          </select>
          <label for="organizationSelect" class="org-label">
            {{ 'UNIVERSAL-CONTACT_ORGANIZATION' | translate }}
            <span class="sr-required-mark">*</span>
          </label>
          <div
            class="error-area2"
            *ngIf="
              editUserForm.controls['organizationId'].touched && editUserForm.controls['organizationId'].value === null
            "
          >
            <div>
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="row margin">
      <div class="col s12 m12">
        <div class="my_btns right">
          <span *ngIf="authService.isSuper()">
            <button (click)="onResetUserPass()" class="waves-effect waves-light btn cyan">
              {{ 'UNIVERSAL-NAVIGATION_UPDATE_PASSWORD' | translate }}
            </button>
          </span>

          <button
            (click)="onSubmit()"
            class="waves-effect waves-light btn cyan"
            [disabled]="isSubmitting || !editUserForm.valid"
          >
            {{ 'UNIVERSAL-NAVIGATION_SAVE' | translate }}
          </button>
          <a (click)="onCancel()" class="btn cyan">
            {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
