import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emptySpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value && value.replace(/<[^>]*>/g, '').trim() === '') {
      // to handle control.value that returns empty space inside tags.
      return { emptySpace: true };
    }
    return null;
  };
}
