import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NewsLetterSubscribeSuccessComponent } from './newsletter-subscribe-success.component';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: NewsLetterSubscribeSuccessComponent }])],
  exports: [RouterModule],
})
export class SubscribeSuccessRoutingModule {}

@NgModule({
  imports: [CommonModule, SubscribeSuccessRoutingModule, TranslateModule],
  declarations: [NewsLetterSubscribeSuccessComponent],
  providers: [],
})
export class NewsLetterSubscribeSuccessFeatureModule {}
