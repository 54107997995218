import { Component } from '@angular/core';

// const template = require('./organizations.component.html');
// const styles = require('./organizations.component.css');

@Component({
  selector: 'organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css'],
})
export class DashboardOrganizationsComponent {
  constructor() {}
}
