import { SRResponse } from './../../models/SRResponse';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { contentHeaders } from '../../common/headers';
import { AuthenticationService } from '../../login/login.service';
import { AppSettings } from '../../common/config';

const ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/survey/dss/search/org/';
/**
 * If this page doesn't belogn to admin functionality , we can split it
 */
@Injectable()
export class OrgDisasterService {
  constructor(private http: HttpClient, public authService: AuthenticationService) {
    // console.log('OrgDisasterService constructor');
  }

  // TODO: Cooperate with back-end guys to get the desired response. Change any -> Response Model
  getOrgRespondToDisaster(orgId: any): Observable<any> {
    return this.http
      .get<SRResponse>(ENDPOINT_URL + orgId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.survey_list));
  }
}
