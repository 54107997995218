<div class="row">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12 my_btns">
    <a [routerLink]="['create']" class="waves-effect waves-light btn cyan" role="button">
      <i class="material-icons left">add</i>
      {{ 'DashboardDisasterBaseComponent-ADD_BUTTON' | translate }}
    </a>
    <a
      *ngIf="disasterListType == DisasterType.APPROVED_DISASTERS"
      (click)="switchDisasterList()"
      class="waves-effect waves-light btn cyan"
      role="button"
    >
      <i class="material-icons left">flag</i>
      {{ 'DashboardDisasterBaseComponent-NOMINATED_BUTTON' | translate }}
    </a>
    <a
      *ngIf="disasterListType == DisasterType.NOMINATED_DISASTERS"
      (click)="switchDisasterList()"
      class="waves-effect waves-light btn cyan"
      role="button"
    >
      <i class="material-icons left">check</i>
      {{ 'DashboardDisasterBaseComponent-APPROVED_DISASTER_BUTTON' | translate }}
    </a>
    <div class="right">
      <a class="waves-effect waves-light btn cyan">{{ 'UNIVERSAL-NAVIGATION_EXPORT' | translate }}</a>
      <a class="waves-effect waves-light btn cyan">{{ 'UNIVERSAL-NAVIGATION_PRINT' | translate }}</a>
    </div>
  </div>
</div>

<div>
  <h5 style="margin-left: 20px">{{ disasterListType }}</h5>
</div>

<dashboardListDisaster [disasterListType]="disasterListType"></dashboardListDisaster>
<!-- Edit Priority Modal -->

<div
  id="priorityModal"
  class="modal modal-fixed-footer col s10 m5 offset-m4"
  materialize="modal"
  [materializeParams]="[{ dismissible: true }]"
  [materializeActions]="modalActions"
>
  <div class="modal-content">
    <div *ngIf="priorityForm" class="card-content black-text">
      <h5>
        {{ 'DISASTER_COMMON-PRIORITY_MODAL_TITLE' | translate: { disaster: currentDisaster.name } }}
      </h5>
      <div class="divider"></div>
      <div class="input-field col s12" [ngClass]="{ 'sr-has-error': !priorityForm.valid && priorityForm.value }">
        <input class="priority-input" type="number" [formControl]="priorityForm" />
        <div class="sr-error-area" *ngIf="!priorityForm.valid && priorityForm.value">
          <div *ngIf="priorityForm.hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 64 } }}
          </div>
          <div *ngIf="priorityForm.hasError('pattern')">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WHOLE_NUMBER' | translate }}
          </div>
        </div>
        <p class="chips-notif blue-text text-darken-2">
          {{ 'DISASTER_COMMON-PRIORITY_DESCRIPTION' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
