import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { EmailValidator } from '../validators/emailValidator';
import { ForgetPasswordAuth } from './reset.service';
import { handleErrors } from '../common/error';
import { GlobalLoaderFacade } from '../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../login/login.service';

@Component({
  selector: 'reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css'],
  providers: [ForgetPasswordAuth],
})
export class ResetComponent {
  email: FormControl;

  emailRequestSent: boolean;

  isSubmitting: boolean;

  loading: boolean;

  constructor(
    private translateService: TranslateService,
    private forgetpasswordauth: ForgetPasswordAuth,
    private notificationsService: NotificationsService,
    private loaderService: GlobalLoaderFacade,
    private authService: AuthenticationService
  ) {
    this.emailRequestSent = false;
    this.isSubmitting = false;

    this.email = new FormControl('', [Validators.required, EmailValidator.mailFormat]);
  }

  onReset() {
    this.authService.errorText = null;

    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this.loaderService.start();
    this.loading = true;

    this.forgetpasswordauth.reset(this.email.value).subscribe(
      (res) => {
        this.emailRequestSent = true;

        this.isSubmitting = false;

        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationsService);

        this.isSubmitting = false;

        this.loaderService.complete();
        this.loading = false;
      }
    );
  }
}
