<div class="card">
  <app-loader [loading]="loading"></app-loader>
  <div class="card-content black-text">
    <div class="row">
      <div class="card-title center white-text cyan">
        {{ 'DashboardIncomingMessagesComponent-TITLE' | translate }}

        <div class="left refresh">
          <a (click)="updateMessages()">
            <i class="material-icons loop">loop</i>
          </a>
        </div>
      </div>
    </div>
    <div class="row search">
      <div class="col s12 m12">
        <div>
          <!-- CSS wizard please save me -->
          <div class="col s4 m2 right valign-wrapper">
            <div class="input-field search-input">
              <input
                [ngModel]="filterArg"
                (ngModelChange)="selectedPage = 0; filterArg = $event"
                class="validate"
                id="search"
                type="text"
              />
              <label class="grey-text" for="search"> {{ 'UNIVERSAL-SEARCH' | translate }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-text-wrapper grey lighten-3">
      <div *ngIf="messages">
        <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
          <thead>
            <tr class="sr-base-table-row">
              <th data-field="sender" class="sender header-cell sr-link" (click)="setSortedField('from')">
                {{ 'DashboardIncomingMessagesComponent-TABLE_HEADER_SENDER' | translate }}
                <i class="tiny material-icons">swap_vert</i>
              </th>

              <th
                data-field="timestamp"
                class="timestamp hide-on-small-only header-cell sr-link"
                (click)="setSortedField('createdDate')"
              >
                {{ 'DashboardIncomingMessagesComponent-TABLE_HEADER_TIMESTAMP' | translate }}
                <i class="tiny material-icons">swap_vert</i>
              </th>

              <th data-field="subject" class="subject header-cell sr-link" (click)="setSortedField('subject')">
                {{ 'DashboardIncomingMessagesComponent-TABLE_HEADER_SUBJECT' | translate }}
                <i class="tiny material-icons">swap_vert</i>
              </th>

              <th colspan="2" data-field="action" class="action action-header-cell">
                {{ 'DashboardIncomingMessagesComponent-TABLE_HEADER_ACTIONS' | translate }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="
                let msg of messages
                  | genericSearchFilterPipe: filterArg:['from', 'subject']
                  | genericSortPipe: sortField:sortDescending
                  | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
              "
              [style.font-weight]="msg.isUnread ? 500 : normal"
            >
              <td colspan="5" *ngIf="msg.isBeingDismissed">
                <div class="progress">
                  <div class="indeterminate"></div>
                </div>
              </td>

              <td *ngIf="!msg.isBeingDismissed" class="cell-value sender">
                {{ msg.from }}
              </td>

              <td *ngIf="!msg.isBeingDismissed" class="cell-value timestamp hide-on-small-only">
                {{ msg.createdDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }}
              </td>

              <td *ngIf="!msg.isBeingDismissed" class="cell-value subject">
                {{ msg.subject | truncate: 20 }}
              </td>

              <td class="cell-action-icon" *ngIf="!msg.isBeingDismissed">
                <a
                  (click)="setSelectedMessage(msg)"
                  class="modalIncomingMessage modal-trigger waves-effect waves-light btn-floating cyan"
                >
                  <i class="material-icons left">visibility</i>
                </a>
              </td>
              <td class="cell-action-icon" *ngIf="!msg.isBeingDismissed">
                <a (click)="dismissMessage(msg)" class="waves-effect waves-light btn-floating cyan">
                  <i class="material-icons left">delete</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="messages.length === 0" class="noMsg">
          <div class="sr-bold center msg">
            {{ 'DashboardIncomingMessagesComponent-NO_MESSAGES' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col s12 m12">
  <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
</div>
<!-- Modal Structure -->
<div
  id="incomingMessageModal"
  class="modal modal-fixed-footer"
  materialize="modal"
  [materializeParams]="[{ dismissible: true }]"
  [materializeActions]="modalActions"
>
  <div class="modal-content">
    <div *ngIf="selectedMessage">
      <div *ngIf="isSubmitting">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>

      <h4>
        {{ 'DashboardIncomingMessagesComponent-MODAL_TITLE' | translate }}
      </h4>

      <div class="divider"></div>

      <p>
        <span class="msgInfo">
          {{ 'DashboardIncomingMessagesComponent-MODAL_MESSAGE_FROM' | translate }}
        </span>
        {{ selectedMessage.from }}
      </p>

      <p>
        <span class="msgInfo">
          {{ 'DashboardIncomingMessagesComponent-MODAL_MESSAGE_SUBJECT' | translate }}
        </span>
        {{ selectedMessage.subject }}
      </p>

      <div class="msgInfo">
        {{ 'DashboardIncomingMessagesComponent-MODAL_MESSAGE_CONTENTS' | translate }}
      </div>
      <p>
        {{ selectedMessage.message }}
      </p>

      <span class="sr-bold">
        {{ selectedMessage.createdDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }}
      </span>
    </div>
  </div>
  <div class="modal-footer">
    <div class="divider"></div>

    <button
      class="left waves-effect waves-light btn cyan darken-3"
      (click)="this.modalActions.emit({ action: 'modal', params: ['close'] })"
    >
      {{ 'DashboardIncomingMessagesComponent-MODAL_CLOSE' | translate }}
    </button>

    <button
      (click)="dismissMessage(selectedMessage); clearSelected()"
      class="right waves-effect waves-light btn cyan darken-3"
    >
      {{ 'DashboardIncomingMessagesComponent-MODAL_ARCHIVE' | translate }}
    </button>
  </div>
</div>
