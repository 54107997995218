import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../common/config';
import { Observable } from 'rxjs';
import { Donation, SRResponse, StripeDonation } from 'app/models';
import { AuthenticationService } from 'app/login/login.service';
import { contentHeaders } from '../../common/headers';
const ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/admin/donation/';

@Injectable()
export class DonationManagementService {
  private urlDonation = AppSettings.API_SERVER + '/smart-response/admin/donation/new';
  private urlTip = AppSettings.API_SERVER + '/smart-response/admin/donate';
  private urlDonationByOrgId = AppSettings.API_SERVER + '/smart-response/admin/donation/org';
  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  getDonationListByOrgId(orgId: number): Observable<Donation[]> {
    return this.http
      .get<SRResponse>(this.urlDonationByOrgId + '/' + orgId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.donations));
  }
  getDonationListByOrgEmail(email: string): Observable<Donation[]> {
    return this.http
      .get<SRResponse>(`${ENDPOINT_URL}email?email=${email}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.donations));
  }

  addNewDondationRecord(donation: Donation): Observable<Response> {
    return this.http.post<Response>(this.urlDonation, JSON.stringify(donation), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  addNewDonationCharge(stripeDonation: StripeDonation): Observable<Response> {
    return this.http.post<Response>(this.urlTip, JSON.stringify(stripeDonation), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }
}
