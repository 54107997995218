<!--   Icon Section   -->
<div class="row">
  <div class="col s12 m4">
    <div class="icon-block">
      <img class="img_center" src="assets/images/icon1.png" />
      <h5 class="center-align">{{ 'MarketingComponent-SEARCH_COUNTRY_DISASTER_HEADING' | translate }}</h5>
      <p class="sr-justify">{{ 'MarketingComponent-SEARCH_COUNTRY_DISASTER_TEXT' | translate }}.</p>
    </div>
  </div>

  <div class="col s12 m4">
    <div class="icon-block">
      <img class="img_center" src="assets/images/icon2.png" />
      <h5 class="center-align">{{ 'MarketingComponent-SEARCH_ORGANIZATION_HEADING' | translate }}</h5>
      <p class="sr-justify">{{ 'MarketingComponent-SEARCH_ORGANIZATION_TEXT' | translate }}.</p>
    </div>
  </div>

  <div class="col s12 m4">
    <div class="icon-block">
      <img class="img_center" src="assets/images/icon3.png" />
      <h5 class="center-align">{{ 'MarketingComponent-DONATE_STAY_INFORMED_HEADING' | translate }}</h5>
      <p class="sr-justify">{{ 'MarketingComponent-DONATE_STAY_INFORMED_TEXT' | translate }}.</p>
    </div>
  </div>
</div>
