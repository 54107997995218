export interface SearchResult {
  disasterLocationOrganizationId: number;
  searchResultName: string;
  searchResultType: string;
}

export enum SearchType {
  None = 0,
  Location = 1 << 0,
  Disaster = 1 << 1,
  Organization = 1 << 2,
  All = Location | Disaster | Organization,
}
