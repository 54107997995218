import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: PageNotFoundComponent }])],
  exports: [RouterModule],
})
export class PageNotFoundRoutingModule {}

@NgModule({
  imports: [CommonModule, PageNotFoundRoutingModule, TranslateModule],
  declarations: [PageNotFoundComponent],
  providers: [],
})
export class PageNotFoundFeatureModule {}
