import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { contentHeaders } from '../../../common/headers';

import { AppSettings } from '../../../common/config';
import { AuthenticationService } from '../../../login/login.service';
import { UserLoginHistory, User, SRResponse } from '../../../models';

const ENDPOINT_USER = AppSettings.API_SERVER + '/smart-response/admin/user';
const ENDPOINT_USERS_PLURAL = AppSettings.API_SERVER + '/smart-response/admin/users';
const ENDPOINT_USERS_ORGANIZATION = AppSettings.API_SERVER + '/smart-response/admin/user/organization';
const ENDPOINT_USERS_REPORT = AppSettings.API_SERVER + '/smart-response/admin/users/download';
const ENDPOINT_USER_LOGIN_HISTORY = AppSettings.API_SERVER + '/smart-response/admin/user/loginhistory';
const ENDPOINT_USER_RESET_PASSWORD = AppSettings.API_SERVER + '/smart-response/admin/reset_user_password';

@Injectable()
export class UserManagementService {
  public users: User[];

  constructor(public http: HttpClient, public authService: AuthenticationService) {}

  getUsers(): Observable<User[]> {
    return this.http
      .get<SRResponse>(ENDPOINT_USERS_PLURAL, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.users));
  }

  getUsersForOrganization(organizationId: number): Observable<User[]> {
    return this.http
      .get<SRResponse>(`${ENDPOINT_USERS_ORGANIZATION}/${organizationId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.users));
  }

  getUser(userId: number): Observable<User> {
    return this.http
      .get<SRResponse>(ENDPOINT_USER + '/' + userId, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.user));
  }
  getUserLoginHistory(userId: number): Observable<UserLoginHistory[]> {
    return this.http
      .get<SRResponse>(ENDPOINT_USER_LOGIN_HISTORY + '/' + userId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(
        map((res) => {
          return res.responseData.userLoginHistory;
        })
      );
  }

  addUser(user: User): Observable<User[]> {
    const body = JSON.stringify(user);
    return this.http.post<User[]>(ENDPOINT_USER, body, { headers: this.authService.getAuthHeader(contentHeaders) });
  }

  updateUser(user: User): Observable<User[]> {
    const body = JSON.stringify(user);
    return this.http.put<User[]>(ENDPOINT_USER + '/' + user.userId, body, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  resetUserPassword(user: User): Observable<User[]> {
    const body = JSON.stringify(user);
    return this.http.post<User[]>(ENDPOINT_USER_RESET_PASSWORD + '?userId=' + user.userId, body, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  deleteUser(userId: number): Observable<User[]> {
    return this.http.delete<User[]>(ENDPOINT_USER + '/' + userId, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  getUserReport(fields: String[]): Observable<Blob> {
    const body = 'fields=' + encodeURIComponent(fields.join(', '));

    const headers = this.authService.getAuthHeader(contentHeaders);
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'application/vnd.ms-excel');

    const options = { headers, responseType: 'blob' as 'blob' };

    return this.http.post(ENDPOINT_USERS_REPORT, body, options).pipe(
      map((res) => {
        return new Blob([res], { type: 'application/vnd.ms-excel' });
      })
    );
  }

  turnOffUserEmail(userId: number, turnOff: boolean): Observable<User> {
    return this.http
      .put<SRResponse>(`${ENDPOINT_USER}/${userId}/${turnOff}`, null, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(
        map((res) => {
          return res.responseData.user;
        })
      );
  }
}
