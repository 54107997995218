<ul class="pinned picker-menu-list collapsible">
  <li>
    <div class="collapsible-body">
      <language-picker></language-picker>
      <currency-picker></currency-picker>
    </div>
    <div class="collapsible-header">
      <i class="material-icons left">language</i>
      <span class="title">{{ 'OptionPickerComponent-CHANGE_TEXT' | translate }}</span>
      <i class="material-icons right expand-more">expand_less</i>
    </div>
  </li>
</ul>
