<div class="card-content">
  <div class="card-title center white-text cyan">
    {{ 'SendMessageComponent-TITLE' | translate }}
  </div>
  <div class="row grey lighten-3">
    <form class="col s12" [formGroup]="sendMessageForm">
      <div class="row content">
        <div class="input-field col s12" [ngClass]="{ 'sr-has-error': !subjectField.valid && subjectField.touched }">
          <input
            id="subjectField"
            type="text"
            maxlength="254"
            length="254"
            materialize="characterCounter"
            [formControl]="sendMessageForm.controls['subjectField']"
          />
          <label for="subjectField" class="center-align"> {{ 'SendMessageComponent-SUBJECT' | translate }}* </label>
          <div class="sr-error-area" *ngIf="!subjectField.valid && subjectField.touched">
            <div *ngIf="subjectField.errors.required">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="sendMessageForm.controls['subjectField'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
        <div
          class="input-field col s12"
          [ngClass]="{ 'sr-has-error': !messageTextField.valid && messageTextField.touched }"
        >
          <textarea
            id="messageTextField"
            class="materialize-textarea"
            materialize="characterCounter"
            length="3999"
            maxlength="3999"
            [formControl]="sendMessageForm.controls['messageTextField']"
          ></textarea>
          <label for="messageTextField" class="center-align"> {{ 'SendMessageComponent-MESSAGE' | translate }}* </label>
          <div class="sr-error-area" *ngIf="!messageTextField.valid && messageTextField.touched">
            <div *ngIf="messageTextField.errors.required">
              {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
            </div>
            <div *ngIf="sendMessageForm.controls['messageTextField'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>

        <div class="col s12">
          <a href="mailto:todo@smartresponse.org">{{ 'SendMessageComponent-EMAIL_US' | translate }}</a>
        </div>

        <div class="btns col s12 center">
          <button (click)="onCancel()" class="waves-effect waves-light btn cyan">
            {{ 'UNIVERSAL-NAVIGATION_CANCEL' | translate }}
          </button>
          <button
            [disabled]="!sendMessageForm.valid || isSendingMessage"
            (click)="onSubmit()"
            class="waves-effect waves-light btn cyan"
          >
            {{ 'SendMessageComponent-SEND' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
