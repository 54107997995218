import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { HtmlOption } from '../../models';

@Component({
  selector: 'option-picker',
  templateUrl: './option-picker.component.html',
  styleUrls: ['./option-picker.component.css'],
})
export class OptionPickerComponent implements OnInit {
  selectedOption: string;
  @Input() options: HtmlOption[];
  @Input() initOption$: Observable<string>;
  @Input() prefix: string;
  @Input() label: string;
  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    if (this.initOption$) {
      this.initOption$.subscribe((initOption: string) => {
        this.selectedOption = initOption;
      });
    }
  }

  public onSelectOption(value: string) {
    if (this.selectedOption && this.selectedOption === value) {
      return;
    }
    this.selectedOption = value;
    this.select.emit(value);
  }
}
