import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../common/config';
import { Message } from './../../models/Message';
import { AuthenticationService } from '../../login/login.service';
import { contentHeaders } from '../../common/headers';
import { TranslateService } from '@ngx-translate/core';
import { handleErrors } from '../../common/error';
import { NotificationsService } from 'angular2-notifications';
import { SRResponse } from '../../models/SRResponse';

const ENDPOINT_URL_UNREAD_MESSAGES = AppSettings.API_SERVER + '/smart-response/admin/unread_messages';
const ENDPOINT_URL_MESSAGE_READ = AppSettings.API_SERVER + '/smart-response/admin/message_read';
let unreadCount: number = 0;
let unreadMessages: Message[] = [];

@Injectable()
export class UnreadMessagesService {
  constructor(
    private http: HttpClient,
    public authService: AuthenticationService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  fetchUnreadMessages(): Observable<Message[]> {
    if (!this.authService.isLoggedIn || !this.authService.isSuper()) return;
    return this.http
      .get<SRResponse>(ENDPOINT_URL_UNREAD_MESSAGES, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => (unreadMessages = res.responseData.msg_list)));
  }

  markAsRead(messageId: number): Observable<any> {
    return this.http.put(`${ENDPOINT_URL_MESSAGE_READ}/${messageId}`, '', {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  refreshUnreadMessages(): void {
    if (!this.authService.isLoggedIn || !this.authService.isSuper()) return;
    this.fetchUnreadMessages().subscribe(
      (resp) => {
        unreadMessages = resp;
        unreadCount = resp.length;
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'UnreadMessagesComponent-ERROR-NEW-MESSAGES'
        );
      }
    );
  }

  getCount(): number {
    return unreadCount;
  }

  getUnreadMessages(): Message[] {
    return unreadMessages;
  }
}
