import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppSettings } from '../../../../common/config';
import { FormElement } from '../survey-helpers';

let id = 0;

@Component({
  selector: 'last-edited-metadata',
  templateUrl: './last-edited-metadata.component.html',
  styleUrls: ['./last-edited-metadata.component.css'],
})
export class LastEditedMetadataComponent implements OnInit {
  // setting the type to `FormElement` causes compilation warnings/crash in browser
  @Input() formElement: any;
  @Input() isDisabled: boolean;

  id: string = `${(id += 1)}`;

  AppSettings = AppSettings;

  constructor() {}

  ngOnInit() {
    // Below if is only needed when this component is used in DSS survey steps,
    // there FormElement interface as defined in survey-helpers is NOT being used.
    // Below code transfers the corresponding FormGroup object to FormElement
    // to get this component become usable in DSS pages.
    if (this.formElement instanceof FormGroup) {
      this.formElement = {
        lastUpdated: this.formElement.get('lastUpdatedDate').value,
        lastUpdatedUser: this.formElement.get('lastUpdatedUser').value,
        updatedBySRAdmin: this.formElement.get('updatedBySRAdmin').value,
        valueControl: this.formElement.get('value'),
        markAsUpdatedControl: this.formElement.get('markAsUpdated'),
      };
    }
  }
}
