import { Component, EventEmitter, OnInit } from '@angular/core';
import { Country, Disaster, DisasterCountry, Service } from '../../../../models';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BlockingLoaderState } from '../../blockingLoader/blocking-loader.model';
import { MapLocation } from '../../../../common/locationMap/location-map.model';
import { Router } from '@angular/router';
import { DisasterAdminService } from '../disaster.service';
import { CountryService } from '../../country/country.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { zip } from 'rxjs/operators';
import { getNominationDisasterForm } from '../disaster.component';
import { handleErrors } from '../../../../common/error';
import { isNullOrUndefined } from 'util';
import { AppSettings } from '../../../../common/config';

declare const $: any;

@Component({
  selector: 'app-nominate',
  templateUrl: './nominate.component.html',
  styleUrls: ['./nominate.component.css'],
})
export class DashboardDisasterNominateComponent implements OnInit {
  isSubmitting: boolean = false;
  disableAddButton: boolean = true;
  disasterTypes: string[];
  countries: Country[];
  editDisasterForm: FormGroup;
  currentDisasterLogoURI: string;
  blockingLoaderEventEmitter = new EventEmitter<BlockingLoaderState>();
  loading: boolean;
  countryMapLocations: { disasterCountry: DisasterCountry; entireCountry: boolean; mapLocations: MapLocation[] }[] = [
    {
      disasterCountry: undefined,
      entireCountry: false,
      mapLocations: [],
    },
  ];

  constructor(
    private router: Router,
    private disasterAdminService: DisasterAdminService,
    private countryService: CountryService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.loaderService.start();
    this.loading = true;

    const disasterTypesObservable = this.disasterAdminService.getDisasterTypes();

    disasterTypesObservable
      .pipe(
        zip((disasterTypes: string[]) => ({
          disasterTypes,
        }))
      )
      .subscribe(
        (success: { disasterTypes: string[] }) => {
          this.disasterTypes = success.disasterTypes;
          this.editDisasterForm = getNominationDisasterForm(this.fb);
          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');
          this.loaderService.complete();
          this.loading = false;
        }
      );
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    console.log(this.countryMapLocations);
    const modifiedLocations: DisasterCountry[] = this.countryMapLocations
      .map((countryMapLocation) =>
        countryMapLocation.mapLocations.length > 0 && !countryMapLocation.entireCountry
          ? countryMapLocation.mapLocations.map(
              (loc) =>
                ({
                  country: countryMapLocation.disasterCountry.country,
                  entireCountry: countryMapLocation.entireCountry,
                  geoLat: `${loc.geoLatitude}`,
                  geoLon: `${loc.geoLongitude}`,
                  radius: loc.radius,
                } as DisasterCountry)
            )
          : ({
              country: countryMapLocation.disasterCountry.country,
              entireCountry: countryMapLocation.entireCountry,
              geoLat: '',
              geoLon: '',
              radius: 0,
            } as DisasterCountry)
      )
      // @ts-ignore
      .reduce((pre, cur) => pre.concat(cur), [])
      .filter((loc) => !isNullOrUndefined(loc.country) && !isNullOrUndefined(loc.country.countryId));
    const newDisaster: Disaster = this.editDisasterForm.value;
    newDisaster['locations'] = modifiedLocations;
    newDisaster['logo'] = { fileId: this.editDisasterForm.value.logo };
    delete newDisaster['countryLocations'];

    this.nominateDisaster(newDisaster);
  }

  onCancel(): void {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      this.router.navigate(['']);
    }
  }

  onFileSelected(event: any): void {
    const targetFile = event.target.files[0];

    if (!(targetFile instanceof File)) {
      return;
    }

    if (targetFile.size > AppSettings.MAXIMUM_IMAGE_UPLOAD_SIZE) {
      this.notificationService.error(
        this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
        this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    if (AppSettings.DEFAULT_ALLOWED_IMAGE_TYPES.indexOf(targetFile.type) === -1) {
      this.notificationService.error(
        this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
        this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    this.blockingLoaderEventEmitter.emit({
      display: true,
      translation_key: 'UNIVERSAL-LOADING_MESSAGE',
    });

    this.disasterAdminService.uploadDisasterLogo(targetFile).subscribe(
      (success) => {
        this.blockingLoaderEventEmitter.emit({
          display: false,
        });

        this.currentDisasterLogoURI = `${success.uri}`;
        this.editDisasterForm.controls['logo'].setValue(success.fileId);
        this.notificationService.success(
          this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
          this.translateService.instant('UNIVERSAL-SUCCESSFUL_UPLOAD')
        );
      },
      (error) => {
        this.blockingLoaderEventEmitter.emit({
          display: false,
        });

        handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');
      }
    );
  }

  onChange(index: number) {
    const country: Country = this.editDisasterForm.controls['countryLocations'].value[index].country;
    this.countryMapLocations[index].disasterCountry = {
      country,
    };
    this.disableAddButton = false;
  }

  setCountryList(countryList: any) {
    this.countryMapLocations = countryList;
  }

  nominateDisaster(newDisaster): void {
    this.disasterAdminService.nominateDisaster(newDisaster).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.notificationService.success(
          this.translateService.instant('DISASTER_COMMON-TITLE'),
          this.translateService.instant('DashboardDisasterNominateComponent-SUCCESS_CREATE_MESSAGE', {
            disasterName: newDisaster.name,
          })
        );

        this.router.navigate(['']);
        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        this.isSubmitting = false;

        handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');

        this.loaderService.complete();
        this.loading = false;
      }
    );
  }
}
