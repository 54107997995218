import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'locationify',
  pure: true,
})
export class LocationPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  transform(input: Address): string {
    if (isNullOrUndefined(input)) {
      return this.translateService.instant('COMMON_SEARCH-NO_RESPONSE');
    }

    if (isNullOrUndefined(input.city) && isNullOrUndefined(input.state) && isNullOrUndefined(input.country)) {
      return this.translateService.instant('COMMON_SEARCH-NO_RESPONSE');
    }

    return [input.city, input.state, input.country].filter((item) => item && item.length > 0).join(', ');
  }
}

export interface Address {
  city: string;
  state: string;
  country: string;
}
