import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from '../../../../models/Service';
import { ServicesProvidedService } from '../service.service';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'dashboardBaseService',
  templateUrl: './base.component.html',
  styleUrls: ['../../../common-base.css', './base.component.css'],
  providers: [],
})
export class DashboardServiceBaseComponent implements OnInit {
  services: Service[];

  filterArg: string;

  itemsPerPageArg: number = 10;
  selectedPage: number = 0;

  sortField: string;
  sortDescending: boolean = true;
  loading: boolean;

  constructor(
    public router: Router,
    public service: ServicesProvidedService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit() {
    this.loaderService.start();
    this.loading = true;

    this.service.getServices().subscribe(
      (data) => {
        this.services = data;

        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationService, 'SERVICE_COMMON_TITLE');

        this.loaderService.complete();
        this.loading = true;
      }
    );
  }

  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  onItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.itemsPerPageArg = +updatedVal;
    this.selectedPage = 0;
  }

  onDelete(serviceToDelete: Service) {
    if (
      confirm(
        this.translateService.instant('DashboardServiceBaseComponent-DELETE_SERVICE_CONFIRMATION', {
          serviceName: serviceToDelete.name,
        })
      )
    ) {
      this.loaderService.start();

      this.service.deleteService(serviceToDelete.serviceId).subscribe(
        (response) => {
          this.notificationService.success(
            this.translateService.instant('SERVICE_COMMON_TITLE'),
            this.translateService.instant('DashboardServiceBaseComponent_SUCCESS_DELETE', {
              serviceName: serviceToDelete.name,
            })
          );

          // remove serviceToDelete from services array
          this.services = this.services.filter((item) => item.serviceId !== serviceToDelete.serviceId);

          // reset select page to an existing page if previously selected page no longer "exists"
          const numPages = Math.ceil(this.services.length / this.itemsPerPageArg);
          this.selectedPage = this.selectedPage > numPages ? numPages : this.selectedPage;

          this.loaderService.complete();
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'SERVICE_COMMON_TITLE');

          this.loaderService.complete();
        }
      );
    }
  }
}
