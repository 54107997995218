import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DisasterAdminService } from '../disaster.service';
import { Disaster } from '../../../../models/Disaster';
import { handleErrors } from '../../../../common/error';
import { AppSettings } from '../../../../common/config';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { Service } from '../../../../models/Service';
import { ServicesProvidedService } from '../../service/service.service';

@Component({
  selector: 'dashboardViewDisaster',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css', '../../../common-view.css'],
  providers: [],
})
export class DashboardDisasterViewComponent implements OnInit {
  public myDisaster: Disaster;
  public servicesProvided: Service[];
  searchTermsValues: string;
  viewDisasterType: string = 'view'; // this to toggle between approved and nominated disaster
  isApprovalUnderProcess: boolean = false;

  /* For use in Template */
  AppSettings = AppSettings;

  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private disasterAdminService: DisasterAdminService,
    private servicesProvidedService: ServicesProvidedService,
    private loaderService: SlimLoadingBarService,
    private translateService: TranslateService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.loaderService.start();
      this.loading = true;
      const id = +params['id'];
      this.viewDisasterType = this.route.snapshot.url[0].path;
      const servicesRequest = this.servicesProvidedService.getServices();
      const disasterRequest =
        this.viewDisasterType === 'viewnominated'
          ? this.disasterAdminService.getNominatedDisaster(id)
          : this.disasterAdminService.getDisaster(id);
      forkJoin([disasterRequest, servicesRequest]).subscribe(
        (response) => {
          const disaster: Disaster = <Disaster>response[0];
          disaster.summary = this.sanitizer.bypassSecurityTrustHtml(<string>disaster.summary);
          this.searchTermsValues = disaster.searchTerms ? disaster.searchTerms.join(' | ') : '';
          disaster.logoURI = disaster.logo ? disaster.logo.uri : 'assets/images/org-default.png';

          const allServices: Service[] = <Service[]>response[1];

          if (disaster.services) {
            this.servicesProvided = disaster.services.map((disasterService) =>
              allServices.find((service) => service.serviceId === disasterService.serviceId)
            );
          }

          this.myDisaster = disaster;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');
        },
        () => {
          this.loaderService.complete();
          this.loading = false;
        }
      );
    });
  }

  onApprove(approvalType: string) {
    if (this.isApprovalUnderProcess) {
      return;
    }
    this.isApprovalUnderProcess = true;
    if (approvalType === 'approve') {
      this.disasterAdminService.approveNominatedDisaster(this.route.params['value']['id']).subscribe(
        (success) => {
          this.approvalSuccess('DashboardDisasterNominateComponent-SUCCESS_APPROVE_MESSAGE');
        },
        (error) => {
          this.approvalError(error);
        }
      );
    } else {
      this.disasterAdminService.rejectNominatedDisaster(this.route.params['value']['id']).subscribe(
        (success) => {
          this.approvalSuccess('DashboardDisasterNominateComponent-SUCCESS_REJECT_MESSAGE');
        },
        (error) => {
          this.approvalError(error);
        }
      );
    }
  }

  private approvalSuccess(successMessage: string) {
    this.notificationService.success(
      this.translateService.instant('DISASTER_COMMON-TITLE'),
      this.translateService.instant(successMessage, { disasterName: this.myDisaster.name })
    );
    this.router.navigate(['dashboard/disasters']);
  }

  private approvalError(error: any) {
    handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');

    this.loaderService.complete();
    this.isApprovalUnderProcess = false;
  }
}
