<div class="row">
  <div class="col s12 m12 sr-filter-row">
    <div>
      <div class="col s3 m3 left valign-wrapper sr-filter-row">
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-SHOW_TEXT' | translate }}</label>
        <select
          materialize="material_select"
          (change)="onItemsPerPageChange($event.target.value)"
          name="entries"
          id="entries"
          class="browser-default my_select2"
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
        </select>
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}</label>
      </div>
      <div class="col s4 m2 right valign-wrapper">
        <div class="input-field search-input">
          <input
            [ngModel]="filterArg"
            (ngModelChange)="selectedPage = 0; filterArg = $event"
            class="validate"
            id="search"
            type="text"
          />
          <label class="grey-text" for="search">{{ 'UNIVERSAL-SEARCH' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col s12 m12">
  <div class="card">
    <div class="card-content black-text">
      <table class="grey lighten-3 table-wrapper centered striped sr-custom-table-row fixed-layout-table">
        <thead>
          <tr class="sr-base-table-row">
            <th class="header-cell name sr-link" data-field="name" (click)="setSortedField('name')">
              {{ 'DISASTER_COMMON-NAME_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              class="header-cell priority hide-on-small-only sr-link"
              data-field="priority"
              (click)="setSortedField('priority')"
            >
              {{ 'DISASTER_COMMON-PRIORITY_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              class="header-cell type hide-on-small-only sr-link"
              data-field="disasterType"
              (click)="setSortedField('disasterType')"
            >
              {{ 'DISASTER_COMMON-DISASTER_TYPE_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              class="header-cell date hide-on-small-only sr-link"
              data-field="dateOfDisaster"
              (click)="setSortedField('dateOfDisaster')"
            >
              {{ 'DISASTER_COMMON-DISASTER_DATE_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th
              class="header-cell location hide-on-small-only sr-link"
              data-field="locations"
              (click)="setSortedField('locations')"
            >
              {{ 'DISASTER_COMMON-DISASTER_LOCATIONS_LABEL' | translate }}
              <i class="material-icons tiny">swap_vert</i>
            </th>
            <th class="header-cell action action-header-cell" colspan="2" data-field="action">
              {{ 'UNIVERSAL-TABLE_COLUMN_ACTIONS' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let disaster of disasters
                | genericSearchFilterPipe: filterArg:['name', 'priority', 'disasterType', 'dateOfDisaster', 'locations']
                | genericSortPipe: sortField:sortDescending
                | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
            "
          >
            <td class="cell-value name">
              {{ disaster.name }}
              <span [hidden]="!disaster.inactive">({{ 'DISASTER_COMMON-INACTIVE' | translate }})</span>
            </td>
            <td class="cell-value priority hide-on-small-only">
              <a (click)="openPriorityModal(disaster)">
                {{ disaster.priority }}
                <i class="material-icons tiny">mode_edit</i>
              </a>
            </td>
            <td class="cell-value type hide-on-small-only">{{ disaster.disasterType }}</td>
            <td class="cell-value date hide-on-small-only">{{ disaster.dateOfDisaster }}</td>
            <td class="cell-value location hide-on-small-only">
              {{ disaster.disasterLocations | truncateStringArray: 22 }}
            </td>
            <td class="cell-action-icon">
              <a
                [routerLink]="[disasterViewType, disaster.disasterId]"
                class="waves-effect waves-light btn-floating cyan"
              >
                <i class="material-icons left">visibility</i>
              </a>
            </td>
            <td class="cell-action-icon">
              <a
                [routerLink]="[disasterEditType, disaster.disasterId]"
                class="waves-effect waves-light btn-floating cyan"
              >
                <i class="material-icons left">mode_edit</i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col s12 m12">
    <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
  </div>
</div>
