export interface RadiusOption {
  value: number | null;
  display: string;
}

export const radiusOptions = [
  { value: null, display: '' },
  { value: 25, display: '25 km / 15 mi' },
  { value: 50, display: '50 km / 31 mi' },
  { value: 100, display: '100 km / 62 mi' },
  { value: 200, display: '200 km / 124 mi' },
  { value: 500, display: '500 km / 310 mi' },
  { value: 1000, display: '1000 km / 621 mi' },
];
