import { FormArray, FormGroup, FormControl } from '@angular/forms';

export class ReliefRecoveryValidator {
  /**
   * tests data for business logic
   * @param control
   * @returns {null}
   */

  public static validateData(controlGroup: FormGroup) {
    if (!controlGroup) {
      return null;
    }

    // return as valid if the dates are NOT yet valid
    // seems contradicotry but we want these validations to kick in after
    // valid dates have been entered
    if (!controlGroup.get('startServiceDate').valid || !controlGroup.get('stopServiceDate').valid) {
      return null;
    }

    const isNotProvidingService = controlGroup.get('isNotProvidingService').value;
    const isCurrentlyProvidingService = controlGroup.get('isCurrentlyProvidingService').value;
    let startServiceDate = controlGroup.get('startServiceDate').value;
    let stopServiceDate = controlGroup.get('stopServiceDate').value;

    startServiceDate = startServiceDate === '' ? null : startServiceDate;
    stopServiceDate = stopServiceDate === '' ? null : stopServiceDate;

    if (isNotProvidingService !== null && isNotProvidingService) {
      if (isCurrentlyProvidingService !== null || startServiceDate !== null || stopServiceDate !== null) {
        return { isNotProvidingService: true };
      }
    }

    if (isNotProvidingService === null) {
      if (isCurrentlyProvidingService === null && (startServiceDate !== null || stopServiceDate !== null)) {
        return { isNotProvidingService: true };
      }
    }

    if (isCurrentlyProvidingService !== null && !isCurrentlyProvidingService) {
      if (stopServiceDate === null || startServiceDate === null) {
        return { mustHaveBothDates: true };
      }

      const start = Date.parse(startServiceDate);
      const stop = Date.parse(stopServiceDate);

      if (start > stop) {
        return { startDateCanNotBeAfterStop: true };
      }
    }

    if (isCurrentlyProvidingService !== null && isCurrentlyProvidingService) {
      if (startServiceDate === null) {
        return { mustHaveStartDate: true };
      }

      if (stopServiceDate !== null) {
        return { canNotHaveStopDate: true };
      }
    }

    return null;
  }
}
