import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../common/config';

@Injectable()
export class ForgetPasswordAuth {
  private url = AppSettings.API_SERVER + '/smart-response/admin/forgot_password';

  constructor(public router: Router, public http: HttpClient) {}

  reset(email: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = 'email=' + email;

    return this.http.post(this.url, body, { headers });
  }
}
