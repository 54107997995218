<div class="container">
  <div class="center-align">
    <div class="title sr-bold">
      {{ 'DashboardOrganizationEditComponent-EDIT_ORGANIZATION_INFORMATION' | translate }}
    </div>
  </div>
  <ng-template [ngIf]="organizationId">
    <general-organization-background-survey [surveyInterface]="surveyInterface" class="col s12 m10 offset-m1">
    </general-organization-background-survey>
  </ng-template>
  <div class="row">
    <div class="col s12 center-align">
      <a class="waves-effect waves-light btn cyan" [routerLink]="['..']">
        <i class="material-icons left">skip_previous</i>{{ 'UNIVERSAL-NAVIGATION_BACK' | translate }}
      </a>
    </div>
  </div>
</div>
