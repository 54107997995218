import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SRResponse } from '../../models';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../common/config';
import { Observable } from 'rxjs';

const ENDPOINT_URL = AppSettings.API_SERVER + '/smart-response/survey/dss/organizations/';

@Injectable()
export class DonationCampaignByDisasterService {
  constructor(private http: HttpClient) {}

  getDonationCampaignByDisaster(disasterId: number): Observable<OrgDisasterDonationCampaign[]> {
    return this.http.get<SRResponse>(ENDPOINT_URL + disasterId).pipe(
      map((resp) => {
        return resp.responseData.dss_table_list.map((item) => {
          return new OrgDisasterDonationCampaign(null);
        });
      })
    );
  }
}

export class OrgDisasterDonationCampaign {
  constructor(obj?: any) {
    this.organizationId = obj && obj.organizationId;
    this.disasterId = obj && obj.disasterId;
    this.givingPortalUrl = obj && obj.givingPortalUrl;
    this.campaignName = obj && obj.campaignName;
  }

  organizationId: number;
  disasterId: number;
  givingPortalUrl: string;
  campaignName: string;
}
