<div class="modal-header">
  <survey-step-progress-bar [totalSteps]="7" [currentStepIndex]="4" (stepTabClicked)="onSelectStep($event)">
  </survey-step-progress-bar>
  <h4 class="modal-step-title">
    {{
      'DisasterSpecificSurveyComponent-STEP_4_TITLE'
        | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
    }}
  </h4>
</div>
<div class="modal-content">
  <div class="divider title-divider"></div>

  <div class="row margin">
    <div class="spacing-div col s12">
      <div class="input-field spacing col s12">
        <input
          [value]="true"
          id="s4_has_solicited_donations"
          class="with-gap"
          name="s4_has_solicited_donations"
          type="checkbox"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="solicitDonationForm.get('value').get('isNotSolicitingDonation')"
        />

        <label for="s4_has_solicited_donations">
          {{
            'DisasterSpecificSurveyComponent-STEP_4_Q_EVER_SOLICITED_DONATIONS'
              | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
          }}
        </label>
      </div>

      <div
        *ngIf="
          solicitDonationForm.get('value').get('isNotSolicitingDonation') &&
          !solicitDonationForm.get('value').get('isNotSolicitingDonation').value
        "
      >
        <div id="ques41" class="red-text question-count">
          {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 1, QuestionCount: 6 } }}
        </div>
        <div class="input-field spacing col s12">
          <p>
            {{
              'DisasterSpecificSurveyComponent-STEP_4_Q_CURRENTLY_SOLICITING_DONATIONS'
                | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
            }}
          </p>

          <input
            [value]="true"
            id="s4_soliciting_donations_yes_rb"
            class="with-gap"
            name="s4_soliciting_donations"
            type="radio"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="solicitDonationForm.get('value').get('isCurrentlySolicitingDonation')"
          />

          <label for="s4_soliciting_donations_yes_rb">
            {{ 'UNIVERSAL-YES' | translate }}
          </label>

          <br />

          <input
            [value]="false"
            class="with-gap"
            name="s4_soliciting_donations"
            type="radio"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            id="s4_soliciting_donations_no_rb"
            [formControl]="solicitDonationForm.get('value').get('isCurrentlySolicitingDonation')"
          />

          <label for="s4_soliciting_donations_no_rb">
            {{ 'UNIVERSAL-NO' | translate }}
          </label>
          <div class="sr-error-area" *ngIf="!solicitDonationForm.get('value').valid">
            <div *ngIf="solicitDonationForm.get('value').hasError('isNotSolicitingDonation')">
              {{ 'SurveyCommon-SELECT_YES_OR_NO' | translate }}
            </div>
          </div>
        </div>

        <div class="input-field spacing-div col s12">
          <p class="col s12">
            {{
              'DisasterSpecificSurveyComponent-STEP_4_Q_WHEN_BEGAN_SOLICITING'
                | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
            }}
          </p>
          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !solicitDonationForm.get('value').get('startSolicitDonationDate').valid &&
                solicitDonationForm.get('value').get('startSolicitDonationDate').value
            }"
          >
            <input
              type="date"
              min="1000-01-01"
              max="3000-01-01"
              id="s4_soliciting_donations_start_date"
              placeholder="{{ 'UNIVERSAL-START_DATE_LABEL' | translate }}"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              [formControl]="solicitDonationForm.get('value').get('startSolicitDonationDate')"
            />
            <div class="sr-error-area" *ngIf="!solicitDonationForm.get('value').valid">
              <div *ngIf="solicitDonationForm.get('value').get('startSolicitDonationDate').hasError('dateFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
              </div>
              <div *ngIf="solicitDonationForm.get('value').get('startSolicitDonationDate').hasError('pastDate')">
                {{ 'UNIVERSAL-FORM_ERRORS_PAST_DATE' | translate }}
              </div>
              <div *ngIf="solicitDonationForm.get('value').hasError('mustHaveStartDate')">
                {{ 'SurveyCommon-ENTER_START_DATE' | translate }}
              </div>
              <div *ngIf="solicitDonationForm.get('value').hasError('startDateCanNotBeAfterStop')">
                {{ 'SurveyCommon-START_DATE_CANNOT_BE_AFTER_END_DATE' | translate }}
              </div>
              <div *ngIf="solicitDonationForm.get('value').hasError('mustHaveBothDates')">
                {{ 'SurveyCommon-BOTH_START_DATE_END_DATE' | translate }}
              </div>
              <div *ngIf="solicitDonationForm.get('value').get('startSolicitDonationDate').hasError('inRangeYear')">
                {{ 'UNIVERSAL-FORM_ERRORS_YEAR_RANGE' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="input-field spacing-div col s12"
          *ngIf="
            solicitDonationForm.get('value').get('isCurrentlySolicitingDonation') &&
            !solicitDonationForm.get('value').get('isCurrentlySolicitingDonation').value
          "
        >
          <p class="col s12">
            {{
              'DisasterSpecificSurveyComponent-STEP_4_Q_WHEN_STOPPED_SOLICITING'
                | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
            }}
          </p>

          <div
            class="input-field col s12"
            [ngClass]="{
              'sr-has-error':
                !solicitDonationForm.get('value').get('stopSolicitDonationDate').valid &&
                solicitDonationForm.get('value').get('stopSolicitDonationDate').value
            }"
          >
            <input
              type="date"
              min="1000-01-01"
              max="3000-01-01"
              placeholder="{{ 'UNIVERSAL-END_DATE_LABEL' | translate }}"
              id="s4_soliciting_donations_end_date"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              [formControl]="solicitDonationForm.get('value').get('stopSolicitDonationDate')"
            />

            <div class="sr-error-area" *ngIf="!solicitDonationForm.get('value').valid">
              <div *ngIf="solicitDonationForm.get('value').get('stopSolicitDonationDate').hasError('dateFormat')">
                {{ 'UNIVERSAL-FORM_ERRORS_INVALID_DATE' | translate }}
              </div>
              <div *ngIf="solicitDonationForm.get('value').get('stopSolicitDonationDate').hasError('pastDate')">
                {{ 'UNIVERSAL-FORM_ERRORS_PAST_DATE' | translate }}
              </div>
              <div *ngIf="solicitDonationForm.get('value').hasError('canNotHaveStopDate')">
                {{ 'SurveyCommon-DONOT_ENTER_END_DATE' | translate }}
              </div>
              <div *ngIf="solicitDonationForm.get('value').get('stopSolicitDonationDate').hasError('inRangeYear')">
                {{ 'UNIVERSAL-FORM_ERRORS_YEAR_RANGE' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing"
          [formElement]="solicitDonationForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        ></last-edited-metadata>
      </div>
    </div>

    <div id="ques42" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 2, QuestionCount: 6 } }}
    </div>
    <div class="spacing-div col s12">
      <label for="s4_amount_raised" class="sr-label">
        {{
          'DisasterSpecificSurveyComponent-STEP_4_Q_HOW_MUCH_RAISED'
            | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
        }}
      </label>
      <div class="input-field col s12">
        <!-- currency -->
        <select
          id="s4_amount_raised_currency"
          class="browser-default col s4 m3 l2"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="raisedFundForm.get('value').get('currency')"
        >
          <option *ngFor="let currencyCode of currencyCodeList" [ngValue]="currencyCode">
            {{ currencyCode }}
          </option></select
        ><!--        </div> -->

        <div class="col s8 m9 l10">
          <!-- amount -->
          <input
            id="s4_amount_raised"
            type="text"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="raisedFundForm.get('value').get('fund')"
          />

          <!-- errors -->
          <div class="sr-error-area" *ngIf="!raisedFundForm.get('value').valid">
            <div
              *ngIf="
                raisedFundForm.get('value').get('fund').hasError('pattern') &&
                (raisedFundForm.get('value').get('fund').touched || invalidOnInit?.singles?.raisedFund)
              "
            >
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_USD' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing"
          [formElement]="raisedFundForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        ></last-edited-metadata>
      </div>
    </div>

    <div id="ques43" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 3, QuestionCount: 6 } }}
    </div>
    <div class="spacing-div col s12">
      <label for="s4_amount_spent" class="sr-label">
        {{
          'DisasterSpecificSurveyComponent-STEP_4_Q_HOW_MUCH_SPENT'
            | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
        }}
      </label>
      <div class="col s12 input-field">
        <!-- currency -->
        <select
          id="s4_amount_spent_currency"
          class="browser-default col s4 m3 l2"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="spentFundForm.get('value').get('currency')"
        >
          <option *ngFor="let currencyCode of currencyCodeList" [ngValue]="currencyCode">
            {{ currencyCode }}
          </option>
        </select>

        <div class="col s8 m9 l10">
          <!-- amount -->
          <input
            id="s4_amount_spent"
            type="text"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="spentFundForm.get('value').get('fund')"
          />

          <!-- errors -->
          <div class="sr-error-area" *ngIf="!spentFundForm.get('value').valid">
            <div
              *ngIf="
                spentFundForm.get('value').get('fund').hasError('pattern') &&
                (spentFundForm.get('value').get('fund').touched || invalidOnInit?.singles?.spentFund)
              "
            >
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_USD' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing"
          [formElement]="spentFundForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        ></last-edited-metadata>
      </div>
    </div>

    <div id="ques44" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 4, QuestionCount: 6 } }}
    </div>
    <div class="spacing-div col s12">
      <label for="s4_interest_earned" class="sr-label">
        {{
          'DisasterSpecificSurveyComponent-STEP_4_Q_INTEREST_EARNED'
            | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
        }}
      </label>
      <div class="col s12 input-field">
        <!-- currency -->
        <select
          id="s4_interest_earned_currency"
          class="browser-default col s4 m3 l2"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="earnedInterestForm.get('value').get('currency')"
        >
          <option *ngFor="let currencyCode of currencyCodeList" [ngValue]="currencyCode">
            {{ currencyCode }}
          </option>
        </select>

        <div class="col s8 m9 l10">
          <!-- amount -->
          <input
            id="s4_interest_earned"
            type="text"
            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
            [formControl]="earnedInterestForm.get('value').get('fund')"
          />

          <!-- errors -->
          <div class="sr-error-area" *ngIf="!earnedInterestForm.get('value').valid">
            <div
              *ngIf="
                earnedInterestForm.get('value').get('fund').hasError('pattern') &&
                (earnedInterestForm.get('value').get('fund').touched || invalidOnInit?.singles?.earnedInterest)
              "
            >
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_USD' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing"
          [formElement]="earnedInterestForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        ></last-edited-metadata>
      </div>
    </div>

    <div id="ques45" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 5, QuestionCount: 6 } }}
    </div>
    <div class="spacing-div col s12">
      <label for="s4_spend_interest" class="sr-label">
        {{
          'DisasterSpecificSurveyComponent-STEP_4_Q_HOW_INTEREST_SPENT'
            | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
        }}
        ( {{ 'UNIVERSAL-MAX_CHARACTERS' | translate: { number: 250 } }} )
      </label>
      <div class="col s12 long-label overflowing-label input-field">
        <textarea
          id="s4_spend_interest"
          class="materialize-textarea"
          materialize="characterCounter"
          length="250"
          maxlength="250"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="earnedInterestSpentForm.get('value')"
        >
        </textarea>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing"
          [formElement]="earnedInterestSpentForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        ></last-edited-metadata>
      </div>
    </div>

    <div id="ques46" class="red-text question-count">
      {{ 'CommonGOBSurvey-Q-QUESTION_NUMBER' | translate: { QuestionNumber: 6, QuestionCount: 6 } }}
    </div>
    <div class="spacing-div col s12">
      <div class="input-field spacing col s12">
        <p>
          {{
            'DisasterSpecificSurveyComponent-STEP_4_Q_HAS_TRANSFERRED'
              | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
          }}
        </p>

        <input
          [value]="true"
          id="s4_has_transferred_yes_rb"
          class="with-gap"
          name="s4_has_transferred"
          type="radio"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="transferredFundForm.get('value').get('hasValue')"
        />

        <label for="s4_has_transferred_yes_rb">
          {{ 'UNIVERSAL-YES' | translate }}
        </label>

        <br />

        <input
          [value]="false"
          class="with-gap"
          name="s4_has_transferred"
          type="radio"
          id="s4_has_transferred_no_rb"
          [attr.disabled]="organizationRespondingToDisaster ? '' : null"
          [formControl]="transferredFundForm.get('value').get('hasValue')"
        />

        <label for="s4_has_transferred_no_rb">
          {{ 'UNIVERSAL-NO' | translate }}
        </label>
      </div>

      <div *ngIf="transferredFundForm.get('value').get('hasValue').value">
        <p class="col s12 margin-top-zero">
          {{ 'DisasterSpecificSurveyComponent-STEP_4_Q_ENTER_RECIPIENTS' | translate }}
        </p>

        <!-- file upload section -->
        <p class="col s12 margin-top-zero">
          {{ 'DisasterSpecificSurveyComponent-STEP_4_Q_TRANSFERRED_FUND_UPLOAD_INFO' | translate }}
        </p>

        <div *ngIf="transferredFundForm.get('value').get('fileId').value" class="input-field col s12">
          <p>
            {{ 'UNIVERSAL-FILE_PERMANENT_DELETE_WARNING' | translate }}
          </p>
          <p>
            <a
              class="btn"
              target="_blank"
              href="{{ documentService.getDocumentDownloadLink(transferredFundForm.get('value').get('fileId').value) }}"
            >
              {{ 'UNIVERSAL-DOWNLOAD_CURRENT_FILE' | translate }}
            </a>
          </p>
          <p>
            <button class="btn" (click)="transferredFundForm.get('value').get('fileId').setValue(null)">
              {{ 'UNIVERSAL-DELETE_CURRENT_FILE' | translate }}
            </button>
          </p>
        </div>

        <div class="file-field input-field col s12">
          <div class="btn">
            <span>
              {{ 'UNIVERSAL-FILE' | translate }}
            </span>
            <input
              type="file"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              (change)="onFileSelected($event, transferredFundForm.get('value').get('fileId'))"
            />
          </div>
          <div class="file-path-wrapper">
            <input
              class="file-path validate"
              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
              type="text"
            />
          </div>
          <div>
            <p class="sr-img-guidelines">
              {{ 'LogoUploadComponent-MAXIMUM_FILE_UPLOAD_SIZE_DISPLAY_MESSAGE' | translate }}<br />
              {{ 'LogoUploadComponent-DEFAULT_ALLOWED_FILE_TYPES_DISPLAY_MESSAGE' | translate }}<br />
            </p>
          </div>
        </div>

        <!-- manual data entry section -->
        <div class="col s12 input-field">
          <table class="striped">
            <tbody>
              <tr *ngFor="let formGroup of receivingOrgArray?.controls; let i = index">
                <td>
                  <!--
                    Originally, there were too many items to fit on one
                    row when viewed on a phone, so we moved the values
                    to multiple rows in a nested table.
                  -->
                  <table class="sr-custom-table-compact">
                    <tr>
                      <th>
                        {{ 'DisasterSpecificSurveyComponent-STEP_4_Q_RECIPIENT_ORG' | translate }}
                      </th>
                      <td>
                        <i
                          *ngIf="transferredFundForm.get('value').get('fundTransferList').controls.length > 1"
                          class="material-icons right sr-link"
                          (click)="onDeleteRecievingOrg(i)"
                        >
                          delete
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div
                          class="input-field col s12"
                          [ngClass]="{ 'sr-has-error': !formGroup.get('receivingOrganization').valid }"
                        >
                          <input
                            type="text"
                            materialize="characterCounter"
                            length="512"
                            maxlength="512"
                            [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                            [formControl]="formGroup.get('receivingOrganization')"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        {{ 'DisasterSpecificSurveyComponent-STEP_4_Q_RECIPIENT_AMOUNT' | translate }}
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <div
                          class="input-field"
                          [ngClass]="{
                            'sr-has-error':
                              !formGroup.get('fund').valid &&
                              (formGroup.get('fund').touched || invalidOnInit?.arrays?.fundTransferList[i])
                          }"
                        >
                          <div class="col s4 m3 l3 override-col-padding">
                            <!-- currency -->
                            <select
                              class="browser-default"
                              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                              [formControl]="formGroup.get('currency')"
                            >
                              <option *ngFor="let currencyCode of currencyCodeList" [ngValue]="currencyCode">
                                {{ currencyCode }}
                              </option>
                            </select>
                          </div>
                          <div class="col s8 m9 l9">
                            <!-- amount -->
                            <input
                              type="text"
                              [attr.disabled]="organizationRespondingToDisaster ? '' : null"
                              [formControl]="formGroup.get('fund')"
                            />
                          </div>
                        </div>
                        <div
                          class="sr-error-area"
                          *ngIf="
                            !formGroup.get('fund').valid &&
                            (formGroup.get('fund').touched || invalidOnInit?.arrays?.fundTransferList[i])
                          "
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_USD' | translate }}
                        </div>
                      </td>
                      <td>
                        <i
                          *ngIf="transferredFundForm.get('value').get('fundTransferList').controls.length === i + 1"
                          class="material-icons right sr-link"
                          (click)="onAddRecievingOrg()"
                        >
                          add
                        </i>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col s12 sr-error-area" *ngIf="transferredFundForm.get('value').hasError('onlyOneReceivingOrgList')">
          {{ 'DisasterSpecificSurveyComponent-STEP_4_ERROR_BOTH_FUND_TYPES' | translate }}
        </div>
      </div>
      <div>
        <last-edited-metadata
          class="no-left-spacing"
          [formElement]="transferredFundForm"
          [isDisabled]="organizationRespondingToDisaster ? '' : null"
        ></last-edited-metadata>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <survey-footer
    [currentStepIndex]="3"
    [totalSteps]="totalSteps"
    [isValid]="isValid"
    (next)="onNext()"
    (prev)="onPrev()"
    (submit)="onSubmit()"
    (cancel)="onCancel()"
    (error)="onError()"
  >
  </survey-footer>
</div>
