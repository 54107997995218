<div class="container" *ngIf="!displayThankYou">
  <div class="row">
    <div class="col s12 m12">
      <div class="panel panel-default">
        <form [formGroup]="customStripeForm" (ngSubmit)="pay()">
          <div class="panel-body">
            <div class="row">
              <div class="col m12">
                <h3>{{ 'Stripe-PAYMENT_INFO' | translate }}</h3>
                <div class="divider"></div>
              </div>
            </div>
            <p *ngIf="tippingText" class="sr-bold">{{ 'Stripe-THANK_YOU_TEXT' | translate }}</p>

            <div class="row">
              <div class="col s6 m6">
                <label class="sr-label" for="donorFirstName">{{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}</label>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      !customStripeForm.get('donorFirstName').valid && customStripeForm.get('donorFirstName').touched
                  }"
                >
                  <input
                    type="text"
                    [formControl]="customStripeForm.get('donorFirstName')"
                    class="form-control"
                    placeholder="First Name"
                    id="donorFirstName"
                    length="32"
                    maxlength="32"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      !customStripeForm.get('donorFirstName').valid && customStripeForm.get('donorFirstName').touched
                    "
                  >
                    <div *ngIf="customStripeForm.get('donorFirstName').errors.required">
                      {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                    </div>
                    <div *ngIf="customStripeForm.get('donorFirstName').hasError('maxlength')">
                      {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col s6 m6">
                <label class="sr-label" for="donorLastName">{{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}</label>
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      !customStripeForm.get('donorLastName').valid && customStripeForm.get('donorLastName').touched
                  }"
                >
                  <input
                    type="text"
                    [formControl]="customStripeForm.get('donorLastName')"
                    class="form-control"
                    placeholder="Last Name"
                    id="donorLastName"
                    length="32"
                    maxlength="32"
                  />
                  <div
                    class="sr-error-area"
                    *ngIf="
                      !customStripeForm.get('donorLastName').valid && customStripeForm.get('donorLastName').touched
                    "
                  >
                    <div *ngIf="customStripeForm.get('donorLastName').errors.required">
                      {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                    </div>
                    <div *ngIf="customStripeForm.get('donorLastName').hasError('maxlength')">
                      {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div>
                <div class="col s12 m12">
                  <!-- Email field -->
                  <label class="sr-label" for="donorEmail">
                    {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
                  </label>
                  <div
                    class="input-field col s12"
                    [ngClass]="{
                      'sr-has-error':
                        !customStripeForm.get('donorEmail').valid && customStripeForm.get('donorEmail').touched
                    }"
                  >
                    <i class="material-icons prefix">email</i>
                    <input
                      id="donorEmail"
                      type="text"
                      materialize="characterCounter"
                      [formControl]="customStripeForm.get('donorEmail')"
                      [placeholder]="'UNIVERSAL-CONTACT_EMAIL' | translate"
                    />
                    <div
                      class="sr-error-area"
                      *ngIf="!customStripeForm.get('donorEmail').valid && customStripeForm.get('donorEmail').touched"
                    >
                      <div *ngIf="customStripeForm.get('donorEmail').errors.mailFormat">
                        {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}
                      </div>
                      <div *ngIf="customStripeForm.get('donorEmail').errors.required">
                        {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12">
                <div class="row spacing-div">
                  <div class="flex-container">
                    <div>
                      <input
                        type="button"
                        (click)="setAmount(50)"
                        class="btn btn-block"
                        value="$50"
                        [ngClass]="buttons.btn50 ? 'btn-highligh' : 'btn-money'"
                      />
                    </div>
                    <div>
                      <input
                        type="button"
                        (click)="setAmount(100)"
                        class="btn btn-block btn-money"
                        value="$100"
                        [ngClass]="buttons.btn100 ? 'btn-highligh' : 'btn-money'"
                      />
                    </div>
                    <div>
                      <input
                        type="button"
                        (click)="setAmount(250)"
                        class="btn btn-block btn-money"
                        value="$250"
                        [ngClass]="buttons.btn250 ? 'btn-highligh' : 'btn-money'"
                      />
                    </div>
                    <div>
                      <input
                        type="button"
                        (click)="setAmount(500)"
                        class="btn btn-block btn-money"
                        value="$500"
                        [ngClass]="buttons.btn500 ? 'btn-highligh' : 'btn-money'"
                      />
                    </div>
                    <div>
                      <input
                        type="button"
                        (click)="setAmount(1000)"
                        class="btn btn-block btn-money"
                        value="$1000"
                        [ngClass]="buttons.btn1000 ? 'btn-highligh' : 'btn-money'"
                      />
                    </div>
                    <div>
                      <input
                        type="button"
                        (click)="setAmount(0)"
                        class="btn btn-block btn-money"
                        value="{{ 'UNIVERSAL-OTHER' | translate }}"
                        [ngClass]="buttons.btn0 ? 'btn-highligh' : 'btn-money'"
                      />
                    </div>
                  </div>
                  <div *ngIf="buttons.btn0">
                    <div
                      class="input-field col s12"
                      [ngClass]="{
                        'sr-has-error': !customStripeForm.get('amount').valid && customStripeForm.get('amount').touched
                      }"
                    >
                      <i class="material-icons prefix">payments</i>
                      <input
                        id="amount"
                        type="text"
                        [placeholder]="amount"
                        name="amount"
                        [value]="amount"
                        [formControl]="customStripeForm.get('amount')"
                        length="15"
                        maxlength="15"
                        min="0"
                        materialize="characterCounter"
                      />

                      <div
                        class="sr-error-area"
                        *ngIf="!customStripeForm.get('amount').valid && customStripeForm.get('amount').touched"
                      >
                        <div
                          *ngIf="
                            customStripeForm.get('amount').errors.pattern ||
                            customStripeForm.get('amount').hasError('maxlength')
                          "
                        >
                          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_USD' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col s12 m12">
                <label for="cardNumber" class="sr-label">{{ 'Stripe-CARD_NUMBER' | translate }} </label>
                <div class="input-field col s12">
                  <i class="material-icons prefix">credit_card</i>
                  <input
                    id="cardNumber"
                    type="text"
                    formControlName="cardNumber"
                    placeholder="Valid Card Number"
                    length="16"
                    maxlength="16"
                    minlength="16"
                  />

                  <div
                    class="sr-error-area"
                    *ngIf="!customStripeForm.get('cardNumber').valid && customStripeForm.get('cardNumber').touched"
                  >
                    <div *ngIf="customStripeForm.get('cardNumber').errors.required">
                      {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                    </div>
                    <div
                      *ngIf="
                        customStripeForm.get('cardNumber').hasError('minlength') ||
                        customStripeForm.get('cardNumber').hasError('maxlength')
                      "
                    >
                      {{ 'Stripe-ERROR_INVALID_CARD_NUMBER' | translate }}
                    </div>
                    <div *ngIf="customStripeForm.get('cardNumber').errors.pattern">
                      {{ 'Stripe-ERROR_INVALID_CARD_NUMBER_PATTERN' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col s5 m5">
                <label class="sr-label"
                  ><span class="hide-on-small-only"> {{ 'Stripe-EXPERATION' | translate }}</span>
                  {{ 'Stripe-DATE' | translate }}</label
                >
                <div class="row">
                  <div class="col m6">
                    <div class="form-group">
                      <select formControlName="cardExpirationMonth" class="browser-default">
                        <option value="">{{ 'Stripe-SELECT-MONTH' | translate }}</option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !customStripeForm.get('cardExpirationMonth').valid &&
                          customStripeForm.get('cardExpirationMonth').touched
                        "
                      >
                        <div *ngIf="customStripeForm.get('cardExpirationMonth').errors.required">
                          {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col m6">
                    <div class="form-group">
                      <input
                        type="text"
                        formControlName="cardExpirationYear"
                        class="form-control"
                        placeholder="YYYY"
                        maxlength="4"
                        minlength="4"
                      />
                      <div
                        class="sr-error-area"
                        *ngIf="
                          !customStripeForm.get('cardExpirationYear').valid &&
                          customStripeForm.get('cardExpirationYear').touched
                        "
                      >
                        <div *ngIf="customStripeForm.get('cardExpirationYear').errors.required">
                          {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                        </div>
                        <div
                          *ngIf="
                            customStripeForm.get('cardExpirationYear').errors.pattern ||
                            customStripeForm.get('cardExpirationYear').hasError('minlength') ||
                            customStripeForm.get('cardExpirationYear').hasError('maxlength')
                          "
                        >
                          {{ 'Stripe-ERROR_INVALID_INPUT' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col s5 m5">
                <div class="row">
                  <div class="col m6">
                    <label class="sr-label">{{ 'Stripe-CVV' | translate }}</label>
                    <input
                      type="text"
                      formControlName="cvv"
                      class="form-control"
                      placeholder="CVC"
                      maxlength="3"
                      minlength="3"
                    />
                    <div
                      class="sr-error-area"
                      *ngIf="!customStripeForm.get('cvv').valid && customStripeForm.get('cvv').touched"
                    >
                      <div *ngIf="customStripeForm.get('cvv').errors.required">
                        {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                      </div>
                      <div *ngIf="customStripeForm.get('cvv').hasError('minlength')">
                        {{ 'UNIVERSAL-FORM_ERRORS_MIN_NUMBER' | translate: { number: 3 } }}
                      </div>
                      <div *ngIf="customStripeForm.get('cvv').errors.pattern">
                        {{ 'Stripe-ERROR_INVALID_INPUT' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="col m6">
                    <label class="sr-label">{{ 'UNIVERSAL-ADDRESS_POSTAL_ZIPCODE' | translate }}</label>
                    <input
                      type="text"
                      formControlName="postalCode"
                      class="form-control"
                      placeholder=""
                      maxlength="5"
                      minlength="5"
                    />
                    <div
                      class="sr-error-area"
                      *ngIf="!customStripeForm.get('postalCode').valid && customStripeForm.get('postalCode').touched"
                    >
                      <div *ngIf="customStripeForm.get('postalCode').errors.required">
                        {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
                      </div>
                      <div
                        *ngIf="
                          customStripeForm.get('postalCode').hasError('minlength') ||
                          customStripeForm.get('postalCode').hasError('maxlength')
                        "
                      >
                        {{ 'UNIVERSAL-FORM_ERRORS_MIN_NUMBER' | translate: { number: 5 } }}
                      </div>
                      <div *ngIf="customStripeForm.get('postalCode').errors.pattern">
                        {{ 'Stripe-ERROR_INVALID_INPUT' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <p class="sr-label">{{ 'Stripe-HOW_OFTEN_TO_DONATE' | translate }}</p>
              <div class="col s12 m12">
                <div
                  class="input-field col s12"
                  [ngClass]="{
                    'sr-has-error':
                      !customStripeForm.get('donationFrequency').valid &&
                      customStripeForm.get('donationFrequency').touched
                  }"
                >
                  <div class="col s12 m4">
                    <input
                      id="donationFrequency_once"
                      checked
                      class="with-gap"
                      type="radio"
                      [formControl]="customStripeForm.get('donationFrequency')"
                      value="once"
                      [checked]="true"
                    />
                    <label for="donationFrequency_once" class="black-text">
                      {{ 'Stripe-ONCE' | translate }}
                    </label>
                  </div>
                  <div class="col s12 m4">
                    <input
                      id="donationFrequency_weekly"
                      class="with-gap"
                      type="radio"
                      [formControl]="customStripeForm.get('donationFrequency')"
                      value="weekly"
                    />
                    <label for="donationFrequency_weekly" class="black-text">
                      {{ 'Stripe-WEEKLY' | translate }}
                    </label>
                  </div>
                  <div class="col s12 m4">
                    <input
                      id="donationFrequency_monthly"
                      class="with-gap"
                      type="radio"
                      [formControl]="customStripeForm.get('donationFrequency')"
                      value="monthly"
                    />
                    <label for="donationFrequency_monthly" class="black-text">
                      {{ 'Stripe-MONTHLY' | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col m12">
                <p class="small-text grey-text">{{ 'Stripe-SSL-ENCRYPTION' | translate }}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col s6 m6">
              <button
                class="btn waves-effect waves-light btn yellow black-text btn-large"
                *ngIf="!isSubmitting"
                [disabled]="isSubmitting || !customStripeForm.valid || customStripeForm.pristine || !boolAmount"
              >
                {{ 'Stripe-DONATE_NOW' | translate }}
              </button>
              <span
                class="btn waves-effect waves-light btn yellow black-text btn-large"
                *ngIf="isSubmitting"
                disabled
                >{{ 'Stripe-PROCESSING_PAYMENT' | translate }}</span
              >
            </div>
            <div class="col s6 m6">
              <label for="amount" class="sr-label">{{ 'Stripe-AMOUNT' | translate }}</label>

              <div class="input-field col s12">
                <i class="material-icons prefix">payments</i>

                <span class="sr-label total-amount">
                  <span *ngIf="buttons.btn0">{{ getAmount() }}</span>
                  <span *ngIf="!buttons.btn0">
                    {{ amount }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="displayThankYou">
  <!-- Thank You Content. Will be displayed as part of modal  -->
  <dap-donation-thanks></dap-donation-thanks>

  <div class="modal-footer">
    <div class="row">
      <div class="input-field col s6 offset-s3">
        <button class="waves-effect waves-light btn cyan darken-3 col s12" (click)="closeModal()">
          <i class="material-icons left">clear</i>
          <span class="hide-on-small-only">
            {{ 'DashboardIncomingMessagesComponent-MODAL_CLOSE' | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
