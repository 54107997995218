import { Component, OnChanges, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'share-buttons-custom',
  templateUrl: './shareButtons.component.html',
})
export class ShareButtonsComponent implements OnChanges, OnInit, OnDestroy {
  @Input('metadata') metadata: string;

  constructor() {}

  ngOnChanges() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
