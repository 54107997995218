import { Component } from '@angular/core';

import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

import { isNullOrUndefined } from 'util';
import { Service } from '../../../../../models';
import { ServicesProvidedService } from '../../../service/service.service';

import { DisasterSpecificSurveyManager } from '../disaster-specific-survey-manager';
import { DSSStepComponent } from '../dss-step/dss-step.component';

@Component({
  selector: 'dss-step5',
  templateUrl: './dss-step5.component.html',
  styleUrls: ['./dss-step5.component.css', '../dss-steps-common.css', '../../survey-table-common.css'],
  providers: [],
})
export class DSSStep5Component extends DSSStepComponent {
  serviceListForm: FormGroup;
  serviceListArray: FormGroup[];
  allAvailableServices: Service[];

  constructor(
    private fb: FormBuilder,
    private surveyManager: DisasterSpecificSurveyManager,
    private servicesProvidedService: ServicesProvidedService
  ) {
    super(surveyManager);

    this.invalidOnInit = {
      arrays: {
        serviceListDirect: [],
        serviceListIndirect: [],
      },
    };

    this.serviceListForm = <FormGroup>this.surveyManager.mainForm.controls['serviceList'];

    // Get services provided list
    this.servicesProvidedService.getServices().subscribe((data) => {
      this.allAvailableServices = data;
      this.buildServiceListComponents();
    });
  }

  assignDataToForm(): void {
    if (!this.surveyData) {
      return;
    }

    if (this.surveyData.serviceList) {
      this.serviceListForm.get('lastUpdatedDate').setValue(this.surveyData.serviceList.lastUpdatedDate);
      this.serviceListForm.get('lastUpdatedUser').setValue(this.surveyData.serviceList.lastUpdatedUser);
      this.serviceListForm.get('updatedBySRAdmin').setValue(this.surveyData.serviceList.updatedBySRAdmin);
    }

    this.setInitialErrorVisibility();
  }

  buildServiceListComponents(): void {
    if (!this.surveyData || !this.allAvailableServices) {
      return;
    }

    const existingServicesProvidedValues = {};
    if (this.surveyData.serviceList) {
      // Get current values from survey data
      for (const entry of this.surveyData.serviceList.value) {
        existingServicesProvidedValues[entry.serviceProvided.serviceId] = entry;
      }
    }

    const servicesProvidedArray = <FormArray>this.serviceListForm.controls['value'];

    // clear already existing items from array
    // tslint:disable-next-line:no-increment-decrement
    for (let i = servicesProvidedArray.length; i >= 0; i--) {
      servicesProvidedArray.removeAt(i);
    }

    // Build form controls dynamically for each service
    for (const serviceProvided of this.allAvailableServices) {
      const serviceProvidedLine: FormGroup = this.surveyManager.getServiceProvidedLineSection();
      serviceProvidedLine.setValue({
        serviceProvided: {
          name: serviceProvided.name,
          serviceId: serviceProvided.serviceId,
          fileURI: !isNullOrUndefined(serviceProvided.file) ? serviceProvided.file.uri : '',
        },
        directProportion:
          serviceProvided.serviceId in existingServicesProvidedValues
            ? existingServicesProvidedValues[serviceProvided.serviceId].directProportion
            : null,
        indirectProportion:
          serviceProvided.serviceId in existingServicesProvidedValues
            ? existingServicesProvidedValues[serviceProvided.serviceId].indirectProportion
            : null,
      });
      servicesProvidedArray.push(serviceProvidedLine);
    }

    this.serviceListArray = <FormGroup[]>(<FormArray>this.serviceListForm.controls['value']).controls;
    this.setInitialErrorVisibility();
  }

  calculateTotalPercentServicesProvided(): number {
    if (isNullOrUndefined(this.serviceListForm.controls['value'])) {
      return 0;
    }

    let total = 0;
    let indirect: number;
    let direct: number;
    const servicesProvidedArray = <FormArray>this.serviceListForm.controls['value'];
    for (const control of servicesProvidedArray.controls) {
      direct = parseInt(control.get('directProportion').value, 10);
      if (!isNaN(direct)) {
        total += direct;
      }

      indirect = parseInt(control.get('indirectProportion').value, 10);
      if (!isNaN(indirect)) {
        total += indirect;
      }
    }

    return total;
  }

  private setInitialErrorVisibility(): void {
    if (this.surveyData.serviceList && this.serviceListArray && this.serviceListArray.length) {
      this.invalidOnInit.arrays.serviceListDirect = this.serviceListArray.map(
        (control: FormGroup) => !control.get('directProportion').valid
      );
      this.invalidOnInit.arrays.serviceListIndirect = this.serviceListArray.map(
        (control: FormGroup) => !control.get('indirectProportion').valid
      );
    }
  }
}
