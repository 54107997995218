import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../common/config';
import { Message } from './../../../models/Message';
import { AuthenticationService } from '../../../login/login.service';
import { contentHeaders } from '../../../common/headers';
import { SRResponse } from './../../../models/SRResponse';

const ENDPOINT_URL_ACTIVE_MESSAGES = AppSettings.API_SERVER + '/smart-response/admin/message/active';
const ENDPOINT_URL_DISMISS = AppSettings.API_SERVER + '/smart-response/admin/message/dismiss/';

@Injectable()
export class IncomingMessagesService {
  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  getMessages(): Observable<Message[]> {
    return this.http
      .get<SRResponse>(ENDPOINT_URL_ACTIVE_MESSAGES, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.msg_list));
  }

  dismissMessage(messageId: number): Observable<any> {
    return this.http.delete(ENDPOINT_URL_DISMISS + messageId, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }
}
