import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CountryService } from '../country.service';
import { Country } from '../../../../models/Country';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';

declare var Materialize: any;

@Component({
  selector: 'dashboardCountryService',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css', '../../../common-edit.css'],
  providers: [],
})
export class DashboardCountryEditComponent implements OnInit {
  isSubmitting: boolean;

  editCountryForm: FormGroup;

  countryId: number;
  currentCountry: Country;
  loading: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private countryService: CountryService,
    private translateService: TranslateService,
    private globalLoaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {
    this.isSubmitting = false;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.globalLoaderService.start();
      this.loading = true;
      const id = +params['id'];

      this.countryService.getCountry(id).subscribe(
        (data) => {
          this.currentCountry = data;
          this.countryId = data.countryId;

          this.editCountryForm = new FormGroup({
            name: new FormControl(data.name, [Validators.required, Validators.maxLength(128)]),
            countryCode: new FormControl(data.countryCode, Validators.maxLength(8)),
            abbreviation: new FormControl(data.abbreviation, [Validators.required, Validators.maxLength(8)]),
            background: new FormControl(data.background),
            countryHashtag: new FormControl(data.countryHashtag, Validators.maxLength(128)),
            metadata: new FormControl(data.metadata, Validators.maxLength(40)),
            longAbbreviation: new FormControl(data.longAbbreviation, Validators.maxLength(8)),
            isInUS: new FormControl(data.isInUS),
          });

          this.globalLoaderService.complete();
          this.loading = false;
        },
        (error) => {
          this.globalLoaderService.complete();
          this.loading = false;

          handleErrors(error, this.translateService, this.notificationService, 'COUNTRY_COMMON-TITLE');
        }
      );
    });

    setTimeout(Materialize.updateTextFields, 250);
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this.globalLoaderService.start();

    const updatedCountry = Object.assign({}, this.editCountryForm.value, { countryId: this.countryId });

    this.countryService.updateCountry(updatedCountry).subscribe(
      (response) => {
        this.isSubmitting = false;

        this.globalLoaderService.complete();

        this.notificationService.success(
          this.translateService.instant('COUNTRY_COMMON-TITLE'),
          this.translateService.instant('DashboardCountryEditComponent-SUCCESS_EDIT_MESSAGE', {
            countryName: updatedCountry.name,
          })
        );

        this.router.navigate(['dashboard/countries']);
      },
      (error) => {
        this.isSubmitting = false;

        this.globalLoaderService.complete();

        handleErrors(error, this.translateService, this.notificationService, 'COUNTRY_COMMON-TITLE');
      }
    );
  }

  onCancel() {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      this.router.navigate(['dashboard/countries']);
    }
  }
}
