import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'survey-footer',
  templateUrl: 'survey-footer.component.html',
  styleUrls: ['survey-footer.component.css'],
})
export class SurveyFooterComponent {
  @Input() currentStepIndex: number = 0;
  @Input() totalSteps: number = 0;
  @Input() isSubmitting: boolean = false;
  @Input() isValid: boolean = false;
  @Input() isFirstStep: boolean = false;
  @Input() isLastStep: boolean = false;
  @Input() isErrorList: boolean = false;

  @Output() prev: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  onPrev(): void {
    this.prev.emit(null);
  }

  onNext(): void {
    this.next.emit(null);
  }

  onCancel(): void {
    this.cancel.emit(null);
  }

  onSubmit(): void {
    this.submit.emit(null);
  }

  onError(): void {
    this.error.emit(null);
  }
}
