import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserManagementService } from '../user_management.service';
import { User, userTypeToName } from '../../../../models/User';
import { AppSettings } from '../../../../common/config';
import { handleErrors } from '../../../../common/error';
import { NotificationsService } from 'angular2-notifications';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../login/login.service';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'dashboardUserManagementBase',
  templateUrl: './base.component.html',
  styleUrls: ['../../../common-base.css', './base.component.css'],
  providers: [],
})
export class DashboardUserManagementBaseComponent implements OnInit {
  users: User[];
  // history of login times for selected user
  loginTimes: number[];

  reportFields: Object[] = [
    {
      name: 'firstName',
      i18n: 'DashboardUserManagementBaseComponent-USER_REPORT_FIRST_NAME',
      enabled: false,
    },
    {
      name: 'lastName',
      i18n: 'DashboardUserManagementBaseComponent-USER_REPORT_LAST_NAME',
      enabled: false,
    },
    {
      name: 'email',
      i18n: 'DashboardUserManagementBaseComponent-USER_REPORT_EMAIL',
      enabled: false,
    },
    {
      name: 'orgName',
      i18n: 'DashboardUserManagementBaseComponent-USER_REPORT_ORG_NAME',
      enabled: false,
    },
    {
      name: 'countryName',
      i18n: 'DashboardUserManagementBaseComponent-USER_REPORT_COUNTRY_NAME',
      enabled: false,
    },
  ];

  modalActions = new EventEmitter<string | MaterializeAction>();
  modalActionsLoginHistory = new EventEmitter<string | MaterializeAction>();

  filterArg: string;

  itemsPerPageArg: number = 10;
  selectedPage: number = 0;
  sortField: string;
  sortDescending: boolean = true;
  error: string;
  loading: boolean;

  AppSettings = AppSettings;
  @Output() action = new EventEmitter();
  @Input() organizationId;
  @Input() GOB_COMPONENT: boolean;

  constructor(
    private userManagementService: UserManagementService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {
    if (this.GOB_COMPONENT) {
      this.loaderService.start();
      this.loading = true;
      this.userManagementService.getUsersForOrganization(this.organizationId).subscribe(
        (data: User[]) => {
          this.users = data;
          this.users.forEach((item) => {
            item.userTypeName = userTypeToName[item.userType];
            item.organizationName = item.organization ? item.organization.name : '';
            item['fullName'] = this.translateService.instant('UNIVERSAL-CONTACT_FULL_NAME_FILL_IN', {
              firstName: item.firstName,
              lastName: item.lastName,
            });
          });
          this.userManagementService.users = this.users;
          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');

          this.loaderService.complete();
          this.loading = false;
        }
      );
    } else {
      this.loaderService.start();
      this.loading = true;

      this.userManagementService.getUsers().subscribe(
        (data: User[]) => {
          this.users = data;
          this.users.forEach((item) => {
            item.userTypeName = userTypeToName[item.userType];
            item.organizationName = item.organization ? item.organization.name : '';
          });

          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');

          this.loaderService.complete();
          this.loading = false;
        }
      );
    }
  }

  dispatchAction(event, data?) {
    this.action.emit({
      type: event,
      payload: data,
    });
  }

  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  onItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.itemsPerPageArg = +updatedVal;
    this.selectedPage = 0;
  }

  onViewLoginHistory(event: any, id: number) {
    event.preventDefault();
    this.userManagementService.getUserLoginHistory(id).subscribe((data) => {
      this.loginTimes = data.map((data) => data.lastLoginTime);
      this.modalActionsLoginHistory.emit({ action: 'modal', params: ['open'] });
    });
  }

  onDelete(userToDelete: User) {
    if (
      confirm(
        this.translateService.instant('DashboardUserManagementBaseComponent-CONFIRM_DELETE', {
          firstName: userToDelete.firstName,
          lastName: userToDelete.lastName,
        })
      )
    ) {
      this.loaderService.start();

      this.userManagementService.deleteUser(userToDelete.userId).subscribe(
        (response) => {
          this.notificationService.success(
            this.translateService.instant('UserManagementCommon-TABLE_TITLE'),
            this.translateService.instant('DashboardUserManagementBaseComponent-SUCCESSFUL_DELETE', {
              firstName: userToDelete.firstName,
              lastName: userToDelete.lastName,
            })
          );

          // remove serviceToDelete from services array
          this.users = this.users.filter((item: User) => item.userId !== userToDelete.userId);

          // reset select page to an existing page if previously selected page no longer "exists"
          const numPages = Math.ceil(this.users.length / this.itemsPerPageArg);
          this.selectedPage = this.selectedPage > numPages ? numPages : this.selectedPage;

          this.loaderService.complete();
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');

          this.loaderService.complete();
        }
      );
    }
  }

  setReportFields(fieldName: String): void {
    const field = this.reportFields.find((e) => e['name'] === fieldName);
    field['enabled'] = !field['enabled'];
  }

  noReportFieldsSelected(): boolean {
    return this.reportFields.every((e) => e['enabled'] === false);
  }

  downloadUserReport(): void {
    this.userManagementService
      .getUserReport(this.reportFields.filter((e) => e['enabled']).map((e) => e['name']))
      .subscribe((response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.download = AppSettings.USER_REPORT_SPREADSHEET_FILENAME;
        document.body.appendChild(link);
        link.click();
        window.setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 100);
      });
  }

  turnOffUserEmail(user: User) {
    this.userManagementService.turnOffUserEmail(user.userId, user.emailNotificationTurnedOff).subscribe(
      (response) => {
        this.notificationService.success(
          this.translateService.instant('UserManagementCommon-TABLE_TITLE'),
          this.translateService.instant('DashboardUserManagementEditComponent-EDIT_USER_EMAIL_SETTING')
        );
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationService,
          'DashboardUserApprovalComponent-TABLE_TITLE'
        );
      }
    );
  }
}
