import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Disaster, SRResponse } from '../../models';
import { AppSettings } from '../../common/config';

const DISASTER_LOCATION_URL = AppSettings.API_SERVER + '/smart-response/disaster/location/';
const ORGANIZATION_COUNT_URL = AppSettings.API_SERVER + '/smart-response/admin/organization/count';

@Injectable()
export class LocationAboutService {
  constructor(private http: HttpClient) {}

  getOrganizationCount(locationId: number, organizationType: number): Observable<any> {
    return this.http.get(`${ORGANIZATION_COUNT_URL}?locationId=${locationId}&orgTypeId=${organizationType}`);
  }

  getDisastersByLocation(locationId: number): Observable<Disaster[]> {
    return this.http
      .get<SRResponse>(DISASTER_LOCATION_URL + locationId)
      .pipe(map((res) => res.responseData.disaster_by_location));
  }
}
