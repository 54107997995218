import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { contentHeaders } from '../../../common/headers';
import { AppSettings } from '../../../common/config';
import { Service } from '../../../models/Service';
import { AuthenticationService } from '../../../login/login.service';
import { SRResponse } from '../../../models/SRResponse';

const SERVICE_URL = AppSettings.API_SERVER + '/smart-response/admin/service';

@Injectable()
export class ServicesProvidedService {
  serviceObjectToFormData(service: Service): FormData {
    const formData = new FormData();
    const keys = ['name', 'summary', 'logo', 'removeLogo', 'serviceId'];

    for (const key of keys) {
      if (service[key] !== undefined) {
        formData.append(key, service[key]);
      }
    }

    return formData;
  }

  constructor(public http: HttpClient, public authService: AuthenticationService) {}

  getServices(): Observable<Service[]> {
    return this.http
      .get<SRResponse>(SERVICE_URL, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.service_list));
  }

  getService(serviceId: number): Observable<Service> {
    return this.http
      .get<SRResponse>(`${SERVICE_URL}/${serviceId}`, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.service));
  }

  addService(service: Service): Observable<Service[]> {
    return this.http.post<Service[]>(SERVICE_URL, this.serviceObjectToFormData(service), {
      headers: this.authService.getAuthHeader(null),
    });
  }

  updateService(service: Service): Observable<Service[]> {
    return this.http.post<Service[]>(`${SERVICE_URL}/${service.serviceId}`, this.serviceObjectToFormData(service), {
      headers: this.authService.getAuthHeader(null),
    });
  }

  deleteService(serviceId: number): Observable<Service[]> {
    return this.http.delete<Service[]>(`${SERVICE_URL}/${serviceId}`, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }
}
