<div class="row">
  <div class="col s12">
    <nav class="breadcrumb-nav">
      <div class="nav-wrapper">
        <div class="col s12 breadcrumb-div">
          <a routerLink="/" class="breadcrumb">
            {{ 'UNIVERSAL-NAVIGATION_HOME' | translate }}
          </a>
          <a class="breadcrumb"> Disasters / Latest Survey Updates </a>
        </div>
      </div>
    </nav>
    <div class="col s12 divider"></div>
  </div>
</div>
<div class="page-container">
  <div class="row" *ngIf="disastersAreLoaded">
    <ul materialize="tabs" class="tabs">
      <li class="tab col s6">
        <a href="#starredDisastersList">
          {{ 'DisasterListHomeComponent-TAB_TITLE_STARRED' | translate }}
        </a>
      </li>
      <li class="tab col s6">
        <a href="#recentSurveys">
          {{ 'DisasterListHomeComponent-TAB_TITLE_RECENT_SURVEY' | translate }}
        </a>
      </li>
    </ul>

    <ng-template [ngIf]="error">
      {{ 'DisasterListHomeComponent-GENERIC_FAILURE_ERROR' | translate }}
    </ng-template>

    <ng-template [ngIf]="!error && !disasters">
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </ng-template>

    <ng-template [ngIf]="disasters">
      <div *ngFor="let listType of DISASTER_LIST_TYPES" id="{{ listType }}DisastersList">
        <div class="row valign-wrapper">
          <div class="col s12 m12 sr-filter-row">
            <div>
              <div class="col s3 m3 left valign-wrapper sr-filter-row">
                <label for="entries" class="my_select1">{{ 'UNIVERSAL-SHOW_TEXT' | translate }}</label>
                <select
                  materialize="material_select"
                  (change)="onDisasterItemsPerPageChange($event.target.value)"
                  name="entries"
                  id="entries"
                  class="browser-default my_select2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                </select>
                <label for="entries" class="my_select1">{{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}</label>
              </div>
            </div>
          </div>
        </div>

        <ul materialize="collapsible" class="collapsible" data-collapsible="accordion">
          <ng-container
            *ngFor="
              let disaster of disasters[listType]
                | paginate
                  : { id: 'disasterLists', itemsPerPage: disasterItemsPerPageArg, currentPage: disasterSelectedPage }
            "
          >
            <li *ngIf="!disaster.inactive">
              <div class="collapsible-header active">
                <div class="row valign-wrapper">
                  <div class="title col l5 m5 s12 valign-wrapper">
                    <i class="material-icons">expand_more</i>
                    {{ 'DisasterListHomeComponent-DISASTER_TITLE' | translate: { disasterName: disaster.name } }}
                  </div>
                  <div class="org-link col l7 m7 s12 right-align">
                    <span>
                      {{ disaster.disasterType }}
                    </span>
                    <a class="sr-bold" [routerLink]="['/disaster', disaster?.disasterId]">
                      {{ 'DisasterListHomeComponent-FIND_ORGANIZATION' | translate }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="collapsible-body">
                <div class="collection">
                  <div class="row collection-item">
                    <div class="col l2 m3 s12" id="img-div">
                      <img
                        [src]="disaster?.logo?.uri"
                        alt="disaster logo"
                        class="disaster-img col s12 m12 responsive-img"
                      />
                      <div class="disaster-link input-field col s12 m12 center-align">
                        <div class="left col s12 m12">
                          <a [routerLink]="['/disaster', disaster?.disasterId]" class="sr-bold">
                            {{ 'DisasterListHomeComponent-LEARN_MORE' | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col l10 m9 s12 description-element" id="text-div">
                      <div class="col s12 m4 sr-justify input-field">
                        <span class="sr-bold">
                          {{ 'DisasterListHomeComponent-LOCATIONS' | translate }}
                        </span>
                        <div>
                          <span
                            *ngFor="let location of disaster.locations | unique: 'country.countryId'; let isLast = last"
                          >
                            <a [routerLink]="['/location', location.country.countryId]">
                              {{ location.country.name }}
                            </a>
                            {{ isLast ? '' : ', ' }}
                          </span>
                        </div>
                      </div>
                      <div class="col s12 m8 sr-justify summary" [innerHtml]="disaster.summary"></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ng-container>
          <div class="col s12 m12">
            <pagination-controls id="disasterLists" (pageChange)="disasterSelectedPage = $event"> </pagination-controls>
          </div>
        </ul>
      </div>
    </ng-template>
    <app-loader [loading]="loading"></app-loader>
    <div id="recentSurveys">
      <div class="row">
        <div class="col s12 m12 sr-filter-row">
          <div>
            <div class="col s3 m3 left valign-wrapper sr-filter-row">
              <label for="entries" class="my_select1">{{ 'UNIVERSAL-SHOW_TEXT' | translate }}</label>
              <select
                materialize="material_select"
                (change)="onSurveyItemsPerPageChange($event.target.value)"
                name="entries"
                id="entries"
                class="browser-default my_select2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
              <label for="entries" class="my_select1">{{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}</label>
            </div>
          </div>
        </div>
      </div>
      <mat-table
        #table
        [dataSource]="
          surveyList
            | paginate
              : { id: 'recentSurveyLists', itemsPerPage: surveyItemsPerPageArg, currentPage: surveySelectedPage }
        "
      >
        <!--Organization Column-->
        <ng-container matColumnDef="organization">
          <mat-header-cell *matHeaderCellDef class="sr-bold">
            {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-ORGNAME' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let survey">
            <span class="mobile-label">{{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-ORGNAME' | translate }}</span>
            {{ survey?.orgName }} <button *ngIf="isOrganizationNew(survey)">NEW!</button>
          </mat-cell>
        </ng-container>

        <!-- Location Column -->
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef class="sr-bold">
            {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-LOCATION-NAME' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let survey">
            <span class="mobile-label">{{
              'DisasterListHomeComponent-TAB_RECENT_SURVEY-LOCATION-NAME' | translate
            }}</span>
            {{ survey?.typeName }}
          </mat-cell>
        </ng-container>

        <!-- Survey Type Column -->
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef class="sr-bold">
            {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-SURVEY-TYPE' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let survey">
            <span class="mobile-label">{{
              'DisasterListHomeComponent-TAB_RECENT_SURVEY-SURVEY-TYPE' | translate
            }}</span>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Gob">
              {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-GENERAL-SPECIFIC' | translate }}
            </ng-template>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Lss">
              {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-LOCATION-SPECIFIC' | translate }}
            </ng-template>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Dss">
              {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-DISASTER-SPECIFIC' | translate }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <!-- Update Date Column -->
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef class="sr-bold">
            {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-UPDATE-DATE' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let survey">
            <span class="mobile-label">{{
              'DisasterListHomeComponent-TAB_RECENT_SURVEY-UPDATE-DATE' | translate
            }}</span>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Gob">
              {{ survey?.surveyUpdateDate | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}
            </ng-template>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Lss">
              {{ survey?.surveyUpdateDate | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}
            </ng-template>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Dss">
              {{ survey?.surveyUpdateDate | date: AppSettings.DEFAULT_ANGULAR_DATE_FORMAT }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <!-- See Details Column -->
        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef class="sr-bold">
            {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-DETAILS' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let survey">
            <span class="mobile-label">
              {{ 'DisasterListHomeComponent-TAB_RECENT_SURVEY-DETAILS' | translate }}
            </span>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Gob">
              <a [routerLink]="['/organization', survey?.orgId]" target="_blank">{{
                'DisasterListHomeComponent-TAB_RECENT_SURVEY-VIEW-PAGE' | translate
              }}</a>
            </ng-template>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Lss">
              <a [routerLink]="['/org-location', survey?.orgId, survey?.typeId]" target="_blank">{{
                'DisasterListHomeComponent-TAB_RECENT_SURVEY-VIEW-PAGE' | translate
              }}</a>
            </ng-template>
            <ng-template [ngIf]="survey?.surveyType === surveyType.Dss">
              <a [routerLink]="['/org-disaster', survey?.orgId, survey?.typeId]" target="_blank">{{
                'DisasterListHomeComponent-TAB_RECENT_SURVEY-VIEW-PAGE' | translate
              }}</a>
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
      </mat-table>
      <div class="col s12 m12">
        <pagination-controls id="recentSurveyLists" (pageChange)="surveySelectedPage = $event"> </pagination-controls>
      </div>
    </div>
  </div>
</div>
