<div class="modal modal-fixed-footer" materialize="modal" [materializeActions]="sortingActions">
  <div class="modal-content">
    <div class="modal-title col s12">
      <p class="sorting">{{ 'ScoreByDisasterComponent-MODAL-TITLE' | translate }}</p>
      <p class="org-name">{{ score?.organizationName }}</p>
      <p class="org-location">
        {{ 'ScoreByDisasterComponent-MODAL-Responingto' | translate: { disasterName: score?.disasterName } }}
      </p>
      <hr />
    </div>
    <div class="totalpoints-div">
      <img src="assets/images/algorithm.png" />
      <p class="totalpoints">{{ totalPoints }} / 100</p>
    </div>
    <div class="criteria">
      <p class="title">
        {{ 'ScoreByDisasterComponent-MODAL-SCORE_CURRENT' | translate }}
        <span class="points"> {{ score?.updateScore }} / 20 </span>
      </p>
      <p class="title">
        {{ 'ScoreByDisasterComponent-MODAL-SCORE_ADMIN_EDITS' | translate }}
        <span class="points">{{ score?.adminEditScore }} / 15 </span>
      </p>
      <p class="explain-title">{{ 'FEWEST_ADMIN_EDITS' | translate }}</p>
      <p class="title">
        {{ 'ScoreByDisasterComponent-MODAL-SCORE_SERVICE_PROVIDED' | translate }}
        <span class="points">{{ score?.directServiceScore }}/ 30 </span>
      </p>
      <p class="explain-title">{{ 'ARE_MORE_THAN_75%_DIRECT_SERVICES_PROVIDED' | translate }}</p>
      <p class="title">
        {{ 'ScoreByDisasterComponent-MODAL-SCORE_OUTDATED_QUESTIONS' | translate }}
        <span class="points">{{ score?.refusedQuestionScore }}/ 15 </span>
      </p>
      <p class="explain-title">{{ 'OUTDATED_QUESTIONS_IN_GOB_&_RELATED_DSS' | translate }}</p>
      <p class="title">
        {{ 'ScoreByDisasterComponent-MODAL-SCORE_PAID_STAFF_HIRED' | translate }}
        <span class="points">{{ score?.localStaffScore }}/ 10 </span>
      </p>
      <p class="explain-title">{{ 'HIRED_STAFF_ON_LOCATION' | translate }}</p>
      <p class="title">
        {{ 'ScoreByDisasterComponent-MODAL-PAID_STAFF' | translate }}
        <span class="points">{{ score?.localStaffCountScore }}/ 10 </span>
      </p>
      <p class="explain-title">{{ 'PAID_STAFF_ON_LOCATION' | translate }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="divider"></div>
    <div class="buttons center">
      <button class="modal-submit btn waves-effect waves-light cyan" (click)="closeModal()">
        <i class="material-icons left">done</i>
        <span class="hide-on-small-only">{{ 'Score-Modal-OK' | translate }}</span>
      </button>
    </div>
  </div>
</div>
