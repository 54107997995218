import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../common/config';
import { contentHeaders } from '../../../common/headers';
import { AuthenticationService } from '../../../login/login.service';

const ENDPOINT_URL = `${AppSettings.API_SERVER}/smart-response/admin/change_password`;

@Injectable()
export class ChangePasswordService {
  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  changePassword(oldPassword: string, newPassword: string): Observable<Response> {
    return this.http.post<Response>(
      ENDPOINT_URL,
      JSON.stringify({
        oldPassword,
        newPassword,
      }),
      { headers: this.authService.getAuthHeader(contentHeaders) }
    );
  }
}
