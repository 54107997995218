import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SearchType } from '../../models/SearchResult';
import { AppSettings } from '../../common/config';

import { CompleterData } from 'ng2-completer/src/services/completer-data';
import { CompleterItem } from 'ng2-completer/src/components/completer-item';
import { SRResponse } from '../../models/SRResponse';

const API_ENDPOINT = AppSettings.API_SERVER + '/smart-response/search';

export class CustomData extends Subject<CompleterItem[]> implements CompleterData {
  public searchType = SearchType.All;

  constructor(private http: HttpClient) {
    super();
  }

  public search(term: string): void {
    this.http
      .get<SRResponse>(API_ENDPOINT, {
        params: {
          search: term,
        },
      })
      .pipe(
        map((res) => {
          const matches = res.responseData.search_result_list
            .filter((item) => {
              const type: string = item.searchResultType;
              return SearchType[type] & this.searchType;
            })
            .map((item) => this.convertToItem(item)) as CompleterItem[];
          this.next(matches);
        })
      )
      .subscribe();
  }

  public cancel() {
    // Handle cancel
  }

  public convertToItem(data: any): CompleterItem | null {
    if (!data) {
      return null;
    }

    return {
      title: data.searchResultName,
      description: data.searchResultType,
      originalObject: data,
    } as CompleterItem;
  }
}
