<ng-template [ngIf]="myCountry">
  <app-loader [loading]="loading"></app-loader>
  <div class="row">
    <div class="col m12 s12">
      <div class="card">
        <div class="card-content">
          <div class="card-title sr-bold">
            {{ 'UNIVERSAL-VIEW_TEXT' | translate }}
            <span class="sr-italic">{{ myCountry.name }}</span>
            {{ 'UNIVERSAL-INFORMATION_TEXT' | translate }}
          </div>
          <div class="divider"></div>
          <br />
          <div class="collection row s-screen">
            <div class="row collection-item collection-padding even">
              <div class="sr-bold col m4 s12">{{ 'COUNTRY_COMMON-NAME_LABEL' | translate }}:</div>
              <div class="col m8 s12">{{ myCountry.name }}</div>
            </div>
            <div class="row collection-item collection-padding odd">
              <div class="sr-bold col m4 s12">{{ 'COUNTRY_COMMON-CODE_LABEL' | translate }}:</div>
              <div class="col m8 s12">{{ myCountry.countryCode }}</div>
            </div>
            <div class="row collection-item collection-padding odd">
              <div class="sr-bold col m4 s12">{{ 'COUNTRY_COMMON-LONG_ABBR_LABEL' | translate }}:</div>
              <div class="col m8 s12">{{ myCountry.longAbbreviation }}</div>
            </div>

            <div class="row collection-item collection-padding">
              <div class="sr-bold col m4 s12">{{ 'COUNTRY_COMMON-ABBR_LABEL' | translate }}:</div>
              <div class="col m8 s12">{{ myCountry.abbreviation }}</div>
            </div>
            <div class="row collection-item collection-padding">
              <div class="sr-bold col m4 s12">{{ 'COUNTRY_COMMON-DISASTER-BACKGROUND_LABEL' | translate }}:</div>
              <div class="col m8 s12 sr-justify">
                <div [innerHtml]="myCountry.background"></div>
              </div>
            </div>
            <div class="row collection-item collection-padding even">
              <div class="sr-bold col m4 s12">{{ 'COUNTRY_COMMON-HASHTAG_LABEL' | translate }}:</div>
              <div class="col m8 s12">{{ myCountry.countryHashtag }}</div>
            </div>
            <div class="row collection-item collection-padding even">
              <div class="sr-bold col m4 s12">{{ 'COUNTRY_COMMON-METADATA_LABEL' | translate }}:</div>
              <div class="col m8 s12">{{ myCountry.metadata }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col s12">
              <div class="input-field right">
                <a
                  class="waves-effect waves-light btn cyan"
                  [routerLink]="['/dashboard/countries/edit', myCountry.countryId]"
                >
                  {{ 'UNIVERSAL-EDIT_TEXT' | translate }}
                </a>
                <a class="waves-effect waves-light btn cyan" [routerLink]="['..']">
                  <i class="material-icons left">skip_previous</i>
                  {{ 'UNIVERSAL-NAVIGATION_BACK' | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
