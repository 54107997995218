import { filter } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '../login/login.service';
import { UnreadMessagesService } from './unread-messages/unread-messages.service';
import { AppSettings } from '../common/config';

@Component({
  selector: 'smart-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  inDashboardRoute: boolean = false;

  AppSettings = AppSettings;

  homeLinkDisable: boolean;

  @Output() showSendMessage: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public authService: AuthenticationService,
    private unreadMessagesService: UnreadMessagesService,
    private router: Router
  ) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const url = event.url;
      this.inDashboardRoute = this.router.routerState.snapshot.url.startsWith('/dashboard');
      // Disable home link on home page and update message count
      if (url === '/') {
        this.homeLinkDisable = true;
      } else if (url === '/dashboard') {
        this.unreadMessagesService.refreshUnreadMessages();
      } else {
        this.homeLinkDisable = false;
      }
    });
  }

  ngOnInit() {
    this.unreadMessagesService.refreshUnreadMessages();
  }

  onSendMessageClick() {
    this.showSendMessage.emit();
  }
}
