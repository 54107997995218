<div class="modal-header">
  <survey-step-progress-bar [totalSteps]="7" [currentStepIndex]="2" (stepTabClicked)="onSelectStep($event)">
  </survey-step-progress-bar>
  <h4 class="modal-step-title">
    {{
      'DisasterSpecificSurveyComponent-STEP_2_TITLE'
        | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
    }}
  </h4>
</div>
<div class="modal-content">
  <div class="divider title-divider"></div>

  <div class="row margin">
    <div id="ques21" class="red-text question-count"></div>
    <label for="s2_address_1" class="sr-label">
      {{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 1 } }}
    </label>
    <div class="input-field spacing col s12">
      <i class="material-icons prefix">home</i>
      <input
        id="s2_address_1"
        type="text"
        materialize="characterCounter"
        length="64"
        maxlength="64"
        placeholder="{{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 1 } }}"
        [attr.disabled]="organizationRespondingToDisaster ? '' : null"
        [formControl]="hqAddressForm.get('value').get('address1')"
      />
    </div>
    <label for="s2_address_2" class="sr-label">
      {{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 2 } }}
    </label>
    <div class="input-field spacing col s12">
      <i class="material-icons prefix">home</i>
      <input
        id="s2_address_2"
        type="text"
        materialize="characterCounter"
        length="64"
        maxlength="64"
        placeholder="{{ 'UNIVERSAL-ADDRESS_LINE_NUMBERED' | translate: { num: 2 } }}"
        [attr.disabled]="organizationRespondingToDisaster ? '' : null"
        [formControl]="hqAddressForm.get('value').get('address2')"
      />
    </div>

    <label for="s2_city" class="sr-label">
      {{ 'UNIVERSAL-ADDRESS_CITY' | translate }}
    </label>
    <div class="input-field spacing col s12">
      <i class="material-icons prefix">location_city</i>
      <input
        id="s2_city"
        type="text"
        materialize="characterCounter"
        length="32"
        maxlength="32"
        placeholder="{{ 'UNIVERSAL-ADDRESS_CITY' | translate }}"
        [attr.disabled]="organizationRespondingToDisaster ? '' : null"
        [formControl]="hqAddressForm.get('value').get('city')"
      />
    </div>

    <label for="s2_state" class="sr-label">
      {{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}
    </label>
    <div class="input-field spacing col s12">
      <i class="material-icons prefix">location_city</i>
      <input
        id="s2_state"
        type="text"
        materialize="characterCounter"
        length="32"
        maxlength="32"
        placeholder="{{ 'UNIVERSAL-ADDRESS_STATE_PROVINCE' | translate }}"
        (blur)="matchesUsaState($event)"
        [attr.disabled]="organizationRespondingToDisaster ? '' : null"
        [formControl]="hqAddressForm.get('value').get('state')"
      />
    </div>

    <label for="s2_zip" class="sr-label">
      {{ 'UNIVERSAL-ADDRESS_POSTAL_ZIPCODE' | translate }}
    </label>
    <div class="input-field spacing col s12">
      <i class="material-icons prefix">room</i>
      <input
        id="s2_zip"
        type="text"
        materialize="characterCounter"
        length="16"
        maxlength="16"
        placeholder="{{ 'UNIVERSAL-ADDRESS_POSTAL_ZIPCODE' | translate }}"
        [attr.disabled]="organizationRespondingToDisaster ? '' : null"
        [formControl]="hqAddressForm.get('value').get('zip')"
      />
    </div>

    <div class="input-field spacing col s12 shift-padding">
      <div class="col m2 s12 label-text">
        {{ 'UNIVERSAL-ADDRESS_COUNTRY' | translate }}
      </div>
      <select
        id="countriesList"
        class="browser-default col m6 s12"
        [attr.disabled]="organizationRespondingToDisaster ? '' : null"
        [formControl]="hqAddressForm.get('value').get('country')"
      >
        <option [ngValue]="null">
          {{ 'SurveyCommon-NO_COUNTRY_SELECTED' | translate }}
        </option>
        <option *ngFor="let country of countries" [ngValue]="country">
          {{ country.name }}
        </option>
      </select>
    </div>
    <div>
      <last-edited-metadata
        class="no-left-spacing"
        [formElement]="hqAddressForm"
        [isDisabled]="organizationRespondingToDisaster ? '' : null"
      ></last-edited-metadata>
    </div>
  </div>
</div>

<div class="modal-footer">
  <survey-footer
    [currentStepIndex]="1"
    [totalSteps]="totalSteps"
    [isValid]="isValid"
    (next)="onNext()"
    (prev)="onPrev()"
    (submit)="onSubmit()"
    (cancel)="onCancel()"
    (error)="onError()"
  >
  </survey-footer>
</div>
