import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericSearchFilterPipe',
  pure: true,
})
export class GenericSearchFilter implements PipeTransform {
  /**
   *
   * @param inputs array of Objects to filter
   * @param filterArg search filter
   * @param fieldsToSearch limit search to properties listed in array
   * @returns {any[]}
   */
  transform(inputs: any[], filterArg: string, fieldsToSearch: string[] = []): any[] {
    if (inputs && filterArg) {
      let searchFields = fieldsToSearch;
      /**
       * if fieldsToSearch is not provided, search every field of the inputs.
       */
      if (0 === searchFields.length && 0 < inputs.length) {
        searchFields = Object.keys(inputs[0]);
      }

      /**
       * return the inputs which have a property that is a string and whose key is in fieldsToSearch
       * and that has the search query included
       */
      return inputs.filter((input) =>
        Object.keys(input)
          .filter((removeFieldIfNotInSearchArray) => -1 !== searchFields.indexOf(removeFieldIfNotInSearchArray))
          .map((key) => input[key])
          .filter((removeFieldIfNotString) => typeof removeFieldIfNotString === 'string')
          .map((field: string) => field.toLowerCase().includes(filterArg.toLowerCase()))
          .reduce((prev: boolean, next: boolean) => prev || next, false)
      );
    }

    return inputs;
  }
}
