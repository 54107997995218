import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings } from '../common/config';
import { contentHeaders } from '../common/headers';
import { AuthenticationService } from '../login/login.service';
import { SRResponse, Twitter, NewsFeed } from '../models';

@Injectable()
export class SocialNetworkService {
  private urlLocationTweets = AppSettings.API_SERVER + '/smart-response/twitter/org_location';
  private urlDisasterTweets = AppSettings.API_SERVER + '/smart-response/twitter/org_disaster';
  private urlLocationNews = AppSettings.API_SERVER + '/smart-response/news/org/location';
  private urlDisasterNews = AppSettings.API_SERVER + '/smart-response/news/org/disaster';

  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  getLocationTweets(orgId: string, locationId: string): Observable<Twitter[]> {
    return this.http
      .get<SRResponse>(this.urlLocationTweets + '/' + orgId + '/' + locationId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(
        map((res) => res.responseData.tweet_list),
        map((tweetList) =>
          tweetList
            ? tweetList.map((tweet) => ({ ...tweet, formattedTime: this.formatTime(tweet.createdAt, true) }))
            : []
        )
      );
  }

  getDisasterTweets(orgId: string, disasterId: string): Observable<Twitter[]> {
    return this.http
      .get<SRResponse>(this.urlDisasterTweets + '/' + orgId + '/' + disasterId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(
        map((res) => {
          const tweetList = res.responseData.tweet_list;
          if (tweetList) {
            return tweetList.map((tweet) => {
              return { ...tweet, formattedTime: this.formatTime(tweet.createdAt, true) };
            });
          }
        })
      );
  }

  getLocationNews(orgId: string, locationId: string): Observable<NewsFeed[]> {
    return this.http
      .get<SRResponse>(this.urlLocationNews + '/' + locationId + '/' + orgId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(
        map((res) => {
          const newsList = res.responseData.news_item_list;
          if (newsList) {
            return newsList.map((news) => {
              const headLineAry = news.headLine.split(' - ');
              return {
                ...news,
                formattedTime: this.formatTime(news.newsDate, false),
                headLineTitle: headLineAry[0],
                headLineSource: headLineAry[1],
              };
            });
          }
        }),
        tap((news) => console.log('new', news))
      );
  }

  getDisasterNews(orgId: string, disasterId: string): Observable<NewsFeed[]> {
    return this.http
      .get<SRResponse>(this.urlDisasterNews + '/' + orgId + '/' + disasterId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(
        map((res) => {
          const newsList = res.responseData.news_item_list;
          if (newsList) {
            return newsList.map((news) => {
              const headLineAry = news.headLine.split(' - ');
              return {
                ...news,
                formattedTime: this.formatTime(news.newsDate, false),
                headLineTitle: headLineAry[0],
                headLineSource: headLineAry[1],
              };
            });
          }
        })
      );
  }

  private formatTime(milliseconds: number, condensed: boolean): string {
    const date = new Date(milliseconds);
    const hoursAgo = (Date.now() - date.getMilliseconds()) / 3.6e6;
    const minStr = condensed ? 'm' : ' minutes ago';
    const hrStr = condensed ? 'h' : ' hours ago';

    if (hoursAgo < 1) {
      return String(Math.floor(hoursAgo * 60)) + minStr;
    }
    if (hoursAgo < 24) {
      return String(Math.floor(hoursAgo)) + hrStr;
    }

    const locale = 'en-us';
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString(locale, options);
  }
}
