import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  pure: true,
})
export class TruncatePipe implements PipeTransform {
  /**
   *
   * @param input string to truncate if too long
   * @param maxLength maximum length of string to return
   * @returns truncated input string if arguments are valid, else input string
   */
  transform(input: string, maxLength?: number): string {
    if (
      input &&
      typeof input === 'string' &&
      typeof maxLength === 'number' &&
      maxLength >= 0 &&
      input.length > maxLength
    ) {
      return input.substr(0, maxLength) + '...';
    }

    return input;
  }
}
