import { FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { isNullOrUndefined } from 'util';

interface ValidationResult {
  [key: string]: boolean;
}

export const EMAIL_CONTROL_NAME = 'email';
export const CONFIRM_EMAIL_CONTROL_NAME = 'confirmationEmail';

export class EmailValidator {
  public static mailFormat(control: FormControl): ValidationResult {
    if (control.value) {
      // tslint:disable-next-line:max-line-length
      const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (control.value !== '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
        return { mailFormat: true };
      }
    }
    return null;
  }

  public static emailMatchValidator(formGroup: AbstractControl): ValidationErrors {
    const email = formGroup.get(EMAIL_CONTROL_NAME);
    const confirmationEmail = formGroup.get(CONFIRM_EMAIL_CONTROL_NAME);
    if (isNullOrUndefined(email) || isNullOrUndefined(confirmationEmail)) return null;
    return email.value === confirmationEmail.value ? null : { emailMismatch: true };
  }
}
