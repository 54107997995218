import { FormControl, FormGroup } from '@angular/forms';
import { AppSettings } from '../common/config';

const MAXIMUM_YEAR = AppSettings.MAXIMUM_YEAR;
const MINIMUM_YEAR = AppSettings.MINIMUM_YEAR;

interface ValidationResult {
  [key: string]: boolean;
}

export class BasicDateValidator {
  /**
   * test that control value is in format 'x-y-z', where x, y, and z are valid integers
   * @param control
   * @returns {null}
   */
  public static dateFormat(control: FormControl): ValidationResult {
    const testValue = control.value || '';
    const pieces = testValue.split('-');
    const invalidResult = {
      dateFormat: true,
    };

    // validation should pass if empty
    if (testValue === '') {
      return null;
    }

    if (pieces.length !== 3) {
      return invalidResult;
    }

    for (let i = 0; i < 3; i += 1) {
      const n = parseInt(pieces[i], 10);
      if (isNaN(n)) return invalidResult;
      // month must be in valid range
      if (i === 1 && (n < 1 || n > 12)) return invalidResult;
      // day must be in valid range
      if (i === 2 && (n < 1 || n > 31)) return invalidResult;
    }

    return null;
  }

  // current date counts as past date
  public static pastDate(control: FormControl): ValidationResult {
    const testValue = control.value || '';
    if (!testValue) return null;
    const [testYear, testMonth, testDay] = testValue.split('-').map((e) => parseInt(e, 10));
    const testDate = new Date(testYear, testMonth - 1, testDay);

    const invalidResult = {
      pastDate: true,
    };

    if (testDate.getTime() > Date.now()) return invalidResult;
    return null;
  }

  // the latest date allowed is the last day of next year
  // e.g. if today is June 6th 2018, the latest valid date is December 31st 2019
  public static fiscalYearDate(control: FormControl): ValidationResult {
    const testValue = control.value || '';
    if (!testValue) return null;
    const [testYear, testMonth, testDay] = testValue.split('-').map((e) => parseInt(e, 10));
    const testDate = new Date(testYear, testMonth - 1, testDay);
    const thisYear = new Date().getFullYear();
    const latestDate = new Date(thisYear + 1, 11, 31);

    const invalidResult = {
      fiscalYearDate: true,
    };

    if (testDate.getTime() > latestDate.getTime()) return invalidResult;
    return null;
  }

  public static inRangeYear(control: FormControl): ValidationResult {
    const testValue = control.value || '';
    if (testValue === '') return null;

    const year = parseInt(testValue.split('-')[0], 10);
    const invalidResult = {
      inRangeYear: true,
    };

    if (year < MINIMUM_YEAR || year > MAXIMUM_YEAR) return invalidResult;
    return null;
  }

  /**
   * start date must be less than expiration date
   * @param FormGroup
   * @returns {null}
   */
  public static startExpiration(fg: FormGroup): ValidationResult {
    const start = !!fg.get('startDate').value ? new Date(fg.get('startDate').value).getTime() : false;
    const expiration = !!fg.get('expirationDate').value ? new Date(fg.get('expirationDate').value).getTime() : false;
    if (!start || !expiration) return null;
    return start < expiration ? null : { startExpiration: true };
  }
}
