import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { contentHeaders } from '../../../common/headers';

import { AppSettings } from '../../../common/config';
import { Disaster } from '../../../models/Disaster';
import { AuthenticationService } from '../../../login/login.service';
import { SRResponse } from './../../../models/SRResponse';
import { FileUploadResponse } from './../../../models/FileUploadResponse';

import { HttpClient } from '@angular/common/http';

// tslint:disable:max-line-length

const DISASTER_URL = AppSettings.API_SERVER + '/smart-response/admin/disaster';
const DISASTER_NOMINATE_URL = AppSettings.API_SERVER + '/smart-response/admin/nominate_disaster';
const DISASTER_NOMINATE_APPROVE_URL = AppSettings.API_SERVER + '/smart-response/admin/nominate_disaster/approve';
const DISASTER_NOMINATE_REJECT_URL = AppSettings.API_SERVER + '/smart-response/admin/nominate_disaster/reject';
const DISASTER_TYPES_URL = AppSettings.API_SERVER + '/smart-response/admin/disaster_type';

const data = () => ({
  disasterId: 1,
  name: 'Test one two three four five six seven ',
  summary:
    "<b>Lorem ipsum</b> dolor sit amet, <a href='https://google.com/'>consectetur</a> adipiscing elit. Suspendisse neque purus, posuere id lobortis ac, suscipit vel tellus. Ut at urna quis arcu faucibus bibendum.",
  dateOfDisaster: '2016-10-06',
  disasterType: 'Earthquake',
  addedDate: '2016-01-01',
  priority: 100,
  notificationTrack: 'VENTI',
  logoURI: '/smart-response/404.png',
  inactive: false,
  services: [
    {
      name: 'Blood Donation',
      summary: 'Blood will be donated',
      serviceId: 1,
    },
    {
      name: 'Carpentry',
      summary: 'Volunteers will be assisting with building',
      serviceId: 2,
    },
    {
      name: 'Clean-Up',
      summary: 'Volunteers will be assisting with Clean-Up',
      serviceId: 3,
    },
    {
      name: 'Food',
      summary: 'Volunteers will be assisting with Food',
      serviceId: 4,
    },
  ],
  searchTerms: ['item1', 'item2', 'item3'],
  locations: [
    {
      country: {
        name: 'United States',
        countryId: 1,
        countryCode: '42',
        abbreviation: '422',
        longAbbreviation: '1234',
        background: '124',
      },
      geoLat: '34',
      geoLon: '68',
      radius: 50,
      radiusUnits: 'km',
    },
    {
      country: {
        name: 'Albania',
        countryId: 3,
        countryCode: '42',
        abbreviation: '422',
        longAbbreviation: '1234',
        background: '124',
      },
      geoLat: '-14',
      geoLon: '-170',
      radius: 50,
      radiusUnits: 'km',
    },
  ],
});

const MOCK_DATA_DISASTERS: Disaster[] = [
  {
    disasterId: 1,
    name: 'Haiti Earthquake',
    summary:
      "<b>Lorem ipsum</b> dolor sit amet, <a href='https://google.com/'>consectetur</a> adipiscing elit. Suspendisse neque purus, posuere id lobortis ac, suscipit vel tellus. Ut at urna quis arcu faucibus bibendum.",
    dateOfDisaster: '2010-01-10',
    disasterType: 'Earthquake',
    addedDate: '2016-01-01',
    priority: 100,
    notificationTrack: 'VENTI',
    logoURI: '/smart-response/404.png',
    inactive: false,
    services: [
      {
        name: 'Blood Donation',
        summary: 'Blood will be donated',
        serviceId: 1,
      },
      {
        name: 'Carpentry',
        summary: 'Volunteers will be assisting with building',
        serviceId: 2,
      },
      {
        name: 'Clean-Up',
        summary: 'Volunteers will be assisting with Clean-Up',
        serviceId: 3,
      },
      {
        name: 'Food',
        summary: 'Volunteers will be assisting with Food',
        serviceId: 4,
      },
    ],
    searchTerms: ['item1', 'item2', 'item3'],
    locations: [
      {
        country: {
          name: 'United States',
          countryId: 1,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '34',
        geoLon: '68',
        radius: 50,
        radiusUnits: 'km',
      },
      {
        country: {
          name: 'Albania',
          countryId: 3,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '-14',
        geoLon: '-170',
        radius: 50,
        radiusUnits: 'km',
      },
    ],
  },
  {
    disasterId: 1,
    name: 'Sichuan Earthquake',
    summary:
      "<b>Lorem ipsum</b> dolor sit amet, <a href='https://google.com/'>consectetur</a> adipiscing elit. Suspendisse neque purus, posuere id lobortis ac, suscipit vel tellus. Ut at urna quis arcu faucibus bibendum.",
    dateOfDisaster: '2008-05-12',
    disasterType: 'Earthquake',
    addedDate: '2016-01-01',
    priority: 100,
    notificationTrack: 'VENTI',
    logoURI: '/smart-response/404.png',
    inactive: false,
    services: [
      {
        name: 'Blood Donation',
        summary: 'Blood will be donated',
        serviceId: 1,
      },
      {
        name: 'Carpentry',
        summary: 'Volunteers will be assisting with building',
        serviceId: 2,
      },
      {
        name: 'Clean-Up',
        summary: 'Volunteers will be assisting with Clean-Up',
        serviceId: 3,
      },
      {
        name: 'Food',
        summary: 'Volunteers will be assisting with Food',
        serviceId: 4,
      },
    ],
    searchTerms: ['item1', 'item2', 'item3'],
    locations: [
      {
        country: {
          name: 'United States',
          countryId: 1,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '34',
        geoLon: '68',
        radius: 50,
        radiusUnits: 'km',
      },
      {
        country: {
          name: 'Albania',
          countryId: 3,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '-14',
        geoLon: '-170',
        radius: 50,
        radiusUnits: 'km',
      },
    ],
  },
  {
    disasterId: 1,
    name: 'Hurricane Katrina',
    summary:
      "<b>Lorem ipsum</b> dolor sit amet, <a href='https://google.com/'>consectetur</a> adipiscing elit. Suspendisse neque purus, posuere id lobortis ac, suscipit vel tellus. Ut at urna quis arcu faucibus bibendum.",
    dateOfDisaster: '2005-08-29',
    disasterType: 'Hurricane',
    addedDate: '2016-01-01',
    priority: 100,
    notificationTrack: 'VENTI',
    logoURI: '/smart-response/404.png',
    inactive: false,
    services: [
      {
        name: 'Blood Donation',
        summary: 'Blood will be donated',
        serviceId: 1,
      },
      {
        name: 'Carpentry',
        summary: 'Volunteers will be assisting with building',
        serviceId: 2,
      },
      {
        name: 'Clean-Up',
        summary: 'Volunteers will be assisting with Clean-Up',
        serviceId: 3,
      },
      {
        name: 'Food',
        summary: 'Volunteers will be assisting with Food',
        serviceId: 4,
      },
    ],
    searchTerms: ['item1', 'item2', 'item3'],
    locations: [
      {
        country: {
          name: 'United States',
          countryId: 1,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '34',
        geoLon: '68',
        radius: 50,
        radiusUnits: 'km',
      },
      {
        country: {
          name: 'Albania',
          countryId: 3,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '-14',
        geoLon: '-170',
        radius: 50,
        radiusUnits: 'km',
      },
    ],
  },
  {
    disasterId: 1,
    name: 'Indian Ocean Earthquake and Tsunami',
    summary:
      "<b>Lorem ipsum</b> dolor sit amet, <a href='https://google.com/'>consectetur</a> adipiscing elit. Suspendisse neque purus, posuere id lobortis ac, suscipit vel tellus. Ut at urna quis arcu faucibus bibendum.",
    dateOfDisaster: '2004-12-26',
    disasterType: 'Tsunami',
    addedDate: '2016-01-01',
    priority: 100,
    notificationTrack: 'VENTI',
    logoURI: '/smart-response/404.png',
    inactive: false,
    services: [
      {
        name: 'Blood Donation',
        summary: 'Blood will be donated',
        serviceId: 1,
      },
      {
        name: 'Carpentry',
        summary: 'Volunteers will be assisting with building',
        serviceId: 2,
      },
      {
        name: 'Clean-Up',
        summary: 'Volunteers will be assisting with Clean-Up',
        serviceId: 3,
      },
      {
        name: 'Food',
        summary: 'Volunteers will be assisting with Food',
        serviceId: 4,
      },
    ],
    searchTerms: ['item1', 'item2', 'item3'],
    locations: [
      {
        country: {
          name: 'United States',
          countryId: 1,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '34',
        geoLon: '68',
        radius: 50,
        radiusUnits: 'km',
      },
      {
        country: {
          name: 'Albania',
          countryId: 3,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '-14',
        geoLon: '-170',
        radius: 50,
        radiusUnits: 'km',
      },
    ],
  },
  {
    disasterId: 1,
    name: 'European Heat Wave',
    summary:
      "<b>Lorem ipsum</b> dolor sit amet, <a href='https://google.com/'>consectetur</a> adipiscing elit. Suspendisse neque purus, posuere id lobortis ac, suscipit vel tellus. Ut at urna quis arcu faucibus bibendum.",
    dateOfDisaster: '2003-07-01',
    disasterType: 'Heat Wave',
    addedDate: '2016-01-01',
    priority: 100,
    notificationTrack: 'VENTI',
    logoURI: '/smart-response/404.png',
    inactive: false,
    services: [
      {
        name: 'Blood Donation',
        summary: 'Blood will be donated',
        serviceId: 1,
      },
      {
        name: 'Carpentry',
        summary: 'Volunteers will be assisting with building',
        serviceId: 2,
      },
      {
        name: 'Clean-Up',
        summary: 'Volunteers will be assisting with Clean-Up',
        serviceId: 3,
      },
      {
        name: 'Food',
        summary: 'Volunteers will be assisting with Food',
        serviceId: 4,
      },
    ],
    searchTerms: ['item1', 'item2', 'item3'],
    locations: [
      {
        country: {
          name: 'United States',
          countryId: 1,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '34',
        geoLon: '68',
        radius: 50,
        radiusUnits: 'km',
      },
      {
        country: {
          name: 'Albania',
          countryId: 3,
          countryCode: '42',
          abbreviation: '422',
          longAbbreviation: '1234',
          background: '124',
        },
        geoLat: '-14',
        geoLon: '-170',
        radius: 50,
        radiusUnits: 'km',
      },
    ],
  },
];

// tslint:disable-next-line:no-increment-decrement
for (let i = 0; i < 30; i++) {
  MOCK_DATA_DISASTERS.push(data());
}

function duplicateMockDataDisasters(): Disaster[] {
  const disasters: Disaster[] = [];
  // tslint:disable-next-line:no-increment-decrement
  for (let i = 0; i < MOCK_DATA_DISASTERS.length; i++) {
    disasters.push(MOCK_DATA_DISASTERS[i]);
    /* for (let i = 0; i < 4; i++)
     disasters.push(data()); */
  }
  return disasters;
}

@Injectable()
export class DisasterAdminService {
  constructor(public http: HttpClient, public authService: AuthenticationService) {}

  getDisasterTypes(): Observable<string[]> {
    return this.http
      .get<SRResponse>(DISASTER_TYPES_URL, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.disaster_type_list.map((disaster) => disaster.name)));
  }

  getDisasterNotificationTracks(): Observable<string[]> {
    return of(['TALL', 'GRANDE', 'VENTI']);
  }

  getDisasters(): Observable<Disaster[]> {
    return this.http
      .get<SRResponse>(DISASTER_URL, { headers: contentHeaders })
      .pipe(map((res) => res.responseData.disaster_list));
  }

  getNominatedDisasters(): Observable<Disaster[]> {
    return this.http
      .get<SRResponse>(DISASTER_NOMINATE_URL, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.disaster_list));
  }

  getTrendingDisasters(): Observable<Disaster[]> {
    return of(duplicateMockDataDisasters().slice(0, 5));
  }

  getRecentDisasters(limit: number): Observable<Disaster[]> {
    return this.http
      .get<SRResponse>(`${DISASTER_URL}/recent?limit=${limit}`)
      .pipe(map((res) => <Disaster[]>res.responseData.disaster_list));
  }
  getStarredDisasters(limit: number): Observable<Disaster[]> {
    const reqUrl = limit ? `${DISASTER_URL}/priority?limit=${limit}` : `${DISASTER_URL}/priority`;
    return this.http.get<SRResponse>(reqUrl).pipe(map((res) => <Disaster[]>res.responseData.disaster_list));
  }

  getDisaster(disasterId: number): Observable<Disaster> {
    return this.http.get<SRResponse>(`${DISASTER_URL}/${disasterId}`).pipe(map((res) => res.responseData.disaster));
  }

  getNominatedDisaster(disasterId: number): Observable<Disaster> {
    return this.http
      .get<SRResponse>(`${DISASTER_NOMINATE_URL}/${disasterId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.disaster));
  }

  addDisaster(disaster: Disaster): Observable<Disaster[]> {
    return this.http.post<Disaster[]>(DISASTER_URL, JSON.stringify(disaster), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  updateDisaster(disaster: Disaster): Observable<Disaster[]> {
    return this.http.put<Disaster[]>(`${DISASTER_URL}/${disaster.disasterId}`, JSON.stringify(disaster), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  deleteDisaster(disasterId: number): Observable<Disaster[]> {
    return this.http.delete<Disaster[]>(`${DISASTER_URL}/${disasterId}`, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  uploadDisasterLogo(disasterLogo: File): Observable<FileUploadResponse> {
    const formData = new FormData();
    formData.append('logo', disasterLogo, disasterLogo.name);

    return this.http
      .post<SRResponse>(`${DISASTER_URL}/logo`, formData, { headers: this.authService.getAuthHeader(null) })
      .pipe(map((res) => res.responseData.disaster_logo));
  }

  nominateDisaster(disaster: Disaster): Observable<Disaster[]> {
    return this.http.post<Disaster[]>(DISASTER_NOMINATE_URL, JSON.stringify(disaster), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  approveNominatedDisaster(disasterId: number): Observable<{}> {
    return this.http.post(`${DISASTER_NOMINATE_APPROVE_URL}/${disasterId}`, JSON.stringify({}), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  rejectNominatedDisaster(disasterId: number): Observable<{}> {
    return this.http.delete(`${DISASTER_NOMINATE_REJECT_URL}/${disasterId}`, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  updateNominatedDisaster(disaster: Disaster): Observable<Disaster[]> {
    return this.http.put<Disaster[]>(`${DISASTER_NOMINATE_URL}/${disaster.disasterId}`, JSON.stringify(disaster), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }
}
