import { zip } from 'rxjs/operators';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DisasterAdminService } from '../disaster.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Country } from '../../../../models/Country';
import { CountryService } from '../../country/country.service';
import { handleErrors } from '../../../../common/error';
import { Disaster, DisasterCountry } from '../../../../models/Disaster';
import { TranslateService } from '@ngx-translate/core';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { ServicesProvidedService } from '../../service/service.service';
import { Service } from '../../../../models/Service';
import { getDisasterForm } from '../disaster.component';
import { AppSettings } from '../../../../common/config';
import { isNullOrUndefined } from 'util';
import { BlockingLoaderState } from '../../blockingLoader/blocking-loader.model';
import { RadiusOption, radiusOptions } from '../radiusOptions';
import { MapLocation } from '../../../../common/locationMap/location-map.model';

declare const $: any;

@Component({
  selector: 'dashboardCreateDisaster',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css', '../../../common-create.css'],
  providers: [],
})
export class DashboardDisasterCreateComponent implements OnInit {
  isSubmitting: boolean = false;
  disasterTypes: string[];
  notificationTracks: string[];

  countries: Country[];
  servicesProvided: Service[];

  editDisasterForm: FormGroup;

  currentDisasterLogoURI: string;

  blockingLoaderEventEmitter = new EventEmitter<BlockingLoaderState>();

  countryMapLocations: { disasterCountry: DisasterCountry; mapLocations: MapLocation[] }[] = [
    {
      disasterCountry: {},
      mapLocations: [],
    },
  ];

  loading: boolean;

  step: number = 0;

  setStep(index: number) {
    this.step = index;
  }

  constructor(
    private router: Router,
    private disasterAdminService: DisasterAdminService,
    private countryService: CountryService,
    private servicesProvidedService: ServicesProvidedService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.loaderService.start();
    this.loading = true;
    const disasterTypesObservable = this.disasterAdminService.getDisasterTypes();
    const servicesProvidedObservable = this.servicesProvidedService.getServices();
    const notificationTracksObservable = this.disasterAdminService.getDisasterNotificationTracks();

    disasterTypesObservable
      .pipe(
        zip(
          servicesProvidedObservable,
          notificationTracksObservable,
          (disasterTypes: string[], servicesProvided: Service[], notificationTracks: string[]) => ({
            disasterTypes,
            servicesProvided,
            notificationTracks,
          })
        )
      )
      .subscribe(
        (success: { disasterTypes: string[]; servicesProvided: Service[]; notificationTracks: string[] }) => {
          this.disasterTypes = success.disasterTypes;
          this.notificationTracks = success.notificationTracks;
          this.servicesProvided = success.servicesProvided;
          this.editDisasterForm = getDisasterForm(this.fb);
          this.editDisasterForm.patchValue({
            notificationTrack: this.notificationTracks[0] || null,
          });

          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');

          this.loaderService.complete();
          this.loading = false;
        }
      );
  }

  setCountryList(countryList: any) {
    this.countryMapLocations = countryList;
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    const modifiedLocations: DisasterCountry[] = this.countryMapLocations
      .map((countryMapLocation) =>
        countryMapLocation.mapLocations.length > 0
          ? countryMapLocation.mapLocations.map(
              (loc) =>
                ({
                  country: countryMapLocation.disasterCountry.country,
                  geoLat: `${loc.geoLatitude}`,
                  geoLon: `${loc.geoLongitude}`,
                  radius: loc.radius,
                } as DisasterCountry)
            )
          : ({
              country: countryMapLocation.disasterCountry.country,
              geoLat: '',
              geoLon: '',
              radius: 0,
              entireCountry: true,
            } as DisasterCountry)
      )
      // @ts-ignore
      .reduce((pre, cur) => pre.concat(cur), [])
      .filter((loc) => !isNullOrUndefined(loc.country) && !isNullOrUndefined(loc.country.countryId));
    const newDisaster: Disaster = this.editDisasterForm.value;
    newDisaster['locations'] = modifiedLocations;
    newDisaster['searchTerms'] = $('#search_terms_chips')
      .material_chip('data')
      .filter((term) => term)
      .map((chip) => chip['tag']);
    newDisaster['services'] = !isNullOrUndefined(this.editDisasterForm.value.servicesProvided)
      ? this.editDisasterForm.value.servicesProvided.map((sp) => ({ serviceId: sp }))
      : [];
    newDisaster['logo'] = { fileId: this.editDisasterForm.value.logo };
    delete newDisaster['countryLocations'];

    this.disasterAdminService.addDisaster(newDisaster).subscribe(
      (response) => {
        this.isSubmitting = false;
        this.notificationService.success(
          this.translateService.instant('DISASTER_COMMON-TITLE'),
          this.translateService.instant('DashboardDisasterCreateComponent-SUCCESS_CREATE_MESSAGE', {
            disasterName: newDisaster.name,
          })
        );

        this.router.navigate(['dashboard/disasters']);
        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        this.isSubmitting = false;
        handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');
        this.loaderService.complete();
        this.loading = false;
      }
    );
  }

  onCancel(): void {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      this.router.navigate(['dashboard/disasters']);
    }
  }

  onFileSelected(event: any): void {
    const targetFile = event.target.files[0];
    if (!(targetFile instanceof File)) {
      return;
    }

    if (targetFile.size > AppSettings.MAXIMUM_IMAGE_UPLOAD_SIZE) {
      this.notificationService.error(
        this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
        this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    if (AppSettings.DEFAULT_ALLOWED_IMAGE_TYPES.indexOf(targetFile.type) === -1) {
      this.notificationService.error(
        this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
        this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );
      return;
    }

    this.blockingLoaderEventEmitter.emit({
      display: true,
      translation_key: 'UNIVERSAL-LOADING_MESSAGE',
    });

    this.disasterAdminService.uploadDisasterLogo(targetFile).subscribe(
      (success) => {
        this.blockingLoaderEventEmitter.emit({
          display: false,
        });
        this.currentDisasterLogoURI = `${success.uri}`;
        this.editDisasterForm.controls['logo'].setValue(success.fileId);

        this.notificationService.success(
          this.translateService.instant('DISASTER_COMMON-TITLE_LOGO'),
          this.translateService.instant('UNIVERSAL-SUCCESSFUL_UPLOAD')
        );
      },
      (error) => {
        this.blockingLoaderEventEmitter.emit({
          display: false,
        });

        handleErrors(error, this.translateService, this.notificationService, 'DISASTER_COMMON-TITLE');
      }
    );
  }
}
