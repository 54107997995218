import { Injectable } from '@angular/core';
import { forkJoin, from, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from 'app/login/login.service';
import { Dictionary, EmptyOutdatedQuestions } from 'app/models';
import { GeneralOrganizationBackgroundSurveyService } from '../surveys/generalOrganizationBackground/general-organization-background.service';
import { LocationSpecificSurveyService } from '../surveys/locationSpecificSurvey/location-specific-survey.service';

@Injectable()
export class PendingTasksService {
  constructor(
    private authService: AuthenticationService,
    private gobService: GeneralOrganizationBackgroundSurveyService,
    private lssService: LocationSpecificSurveyService
  ) {}

  /**
   * Checks whether the user's owned surveys have any empty or outdated questions
   *
   * @param locations the country ID's of LSS's to check
   * @returns a tuple of the GOB's pending status and a map of the LSS's pending statuses,
   * in that order
   */
  public getPendingSurveys(locations: number[] = []): Observable<[boolean, Dictionary<boolean>]> {
    let isGobPending: boolean = false;
    const areLssPending: Dictionary<boolean> = {};

    if (!(this.authService.isLocation() || this.authService.isOrgGeneral())) {
      const result: [boolean, Dictionary<boolean>] = [isGobPending, areLssPending];
      return from([result]);
    }

    const orgId: number = this.authService.getProfile().organizationId;
    let isGobPending$: Observable<boolean> = from([false]);
    let areLssPending$: Observable<[number, boolean][]> = from([[]]);
    let areLssPending$Arr: Observable<[number, boolean]>[] = [];

    if (this.authService.isOrgGeneral()) {
      isGobPending$ = this.gobService.getEmptyOutdatedQuestions(orgId).pipe(
        catchError((_) => from([undefined])),
        map((questions: EmptyOutdatedQuestions) => this.isSurveyPending(questions))
      );
    }

    if (locations && locations.length) {
      areLssPending$Arr = locations.map((countryId: number) =>
        this.lssService.getEmptyOutdatedLSSSurveyById(orgId, countryId).pipe(
          catchError((_) => from([undefined])),
          map((questions: EmptyOutdatedQuestions) => {
            return [countryId, this.isSurveyPending(questions)];
          })
        )
      );
    }
    if (areLssPending$Arr.length) {
      areLssPending$ = forkJoin(areLssPending$Arr);
    }

    return isGobPending$.pipe(
      tap((isPending: boolean) => {
        isGobPending = isPending;
      }),
      switchMap((_) => areLssPending$),
      tap((arePending: [number, boolean][]) => {
        for (const [countryId, isPending] of arePending) {
          if (isPending) {
            areLssPending[countryId] = true;
          }
        }
      }),
      map((_) => [isGobPending, areLssPending])
    );
  }

  private isSurveyPending(questions: EmptyOutdatedQuestions): boolean {
    return questions &&
      questions.responseData &&
      questions.responseData.question_list &&
      Object.keys(questions.responseData.question_list).length
      ? true
      : false;
  }
}
