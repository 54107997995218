import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'app/common/config';
import { Observable } from 'rxjs';
import {
  LocationSpecificSurveyInput,
  LocationSpecificSurveyOutput,
  SurveyErrorCode,
  SRResponse,
  ScoreDto,
  ScoreSRResponse,
  User,
} from 'app/models';
import { contentHeaders } from 'app/common/headers';
import { AuthenticationService } from 'app/login/login.service';
import { EmptyOutdatedQuestions } from 'app/models/EmptyOutdatedQuestions';
import { SurveyUpdateRequest } from 'app/models/SurveyUpdateRequest';
import { CurrencyCodes } from 'app/models/CurrencyCodes';

declare const $: any;

const LSS_URL = AppSettings.API_SERVER + '/smart-response/survey/lss';
const EMPTY_OUTDATED_LSS_SURVEY_ENDPOINT_URL =
  AppSettings.API_SERVER + '/smart-response/survey/lss/empty_outdated_questions';
const REQUEST_LSS_SURVEY_UPDATE_URL = AppSettings.API_SERVER + '/smart-response/survey/lss/request_info';
const RETRIEVAL_ENDPOINT_CURRENCY_CODE_URL = AppSettings.API_SERVER + '/smart-response/admin/currency';

const EXAMPLE_USER: User = {
  email: 'sr_admin@smartresponse.org',
  firstName: 'SmartResponse',
  lastName: 'Admin',
  organizationId: null,
  organizationAdmin: false,
  userType: 1,
  lastLoginTime: new Date(1485277006000),
};

const EXAMPLE_SURVEY: LocationSpecificSurveyInput = {
  /* meta data */

  lastUpdatedDate: 1485277006000,
  lastUpdatedUser: EXAMPLE_USER,

  organizationId: 1,
  organizationName: 'Organization 1',

  country: {
    countryId: 1,
    name: 'United States',
    countryCode: '',
    abbreviation: 'USA',
    background: '',
    longAbbreviation: 'USA',
  },

  surveySubmissionId: 1,

  /* responses */

  /* step 1 */

  officeAddress: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      address1: 'address1',
      address2: 'address2',
      addressId: 42,
      city: 'city',
      state: 'state',
    },
  },

  activityHistory: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: 'history of activities',
  },

  activityHistoryVideoUrl: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: 'the video url of history of activities',
  },

  currentProgramVideoUrl: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: 'the video url of current program',
  },

  startOpDate: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: '2016-01-01',
  },

  repContact: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      alternativeEmail: 'alternativeEmail',
      email: 'email',
      firstName: 'firstName',
      lastName: 'lastName',
      phone1: 'phone1',
      title: 'title',
      country: {
        countryId: 1,
        name: 'United States',
        countryCode: '',
        abbreviation: 'USA',
        background: '',
        longAbbreviation: 'USA',
      },
    },
  },

  govIdOrEin: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,
    markAsUpdated: true,
    value: '123-456',
  },
  govIdOrEin2: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,
    markAsUpdated: true,
    value: '123-488',
  },

  /* step 2 */

  serviceList: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: [
      {
        directProportion: 34,
        indirectProportion: 0,
        serviceProvided: {
          serviceId: 1,
          name: 'service 1',
        },
      },
      {
        directProportion: 33,
        indirectProportion: 0,
        serviceProvided: {
          serviceId: 2,
          name: 'service 2',
        },
      },
      {
        directProportion: 33,
        indirectProportion: 0,
        serviceProvided: {
          serviceId: 3,
          name: 'service 3',
        },
      },
    ],
  },

  reliefTypeList: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: [
      {
        itemCount: 42,
        typeId: 1,
      },
      {
        itemCount: 3,
        typeId: 3,
      },
      {
        itemCount: 5,
        typeId: 5,
      },
    ],
  },

  acceptDonationsOfImportedGoods: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      acceptImportedGoods: true,
      hasPreviouslyAcceptedImportedGoods: true,
      importerOfRecord: 'full name of the importer record',
    },
  },

  disasterStageOfWork: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      mitigationStage: true,
      reliefStage: false,
      recoveryStage: true,
      responseStage: false,
      preparedStage: true,
    },
  },

  disasterResponsePlan: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasValue: true,
      fileId: null,
      webLink: 'http://smartresponse.org',
    },
  },

  /* step 3 */

  localStaff: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      mgmtStaffCount: 4,
      professionalStaffCount: 5,
      supportStaffCount: 6,
    },
  },

  nonLocalStaff: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      mgmtStaffCount: 7,
      professionalStaffCount: 8,
      supportStaffCount: 9,
    },
  },

  volunteerStaff: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      mgmtStaffCount: 10,
      professionalStaffCount: 11,
      supportStaffCount: 12,
    },
  },

  /* step 4 */

  overallBudget: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      currency: 'USD',
      amount: 420,
    },
  },

  fiscalYearEnding: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: '2016-01-12',
  },

  annualReport: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasValue: true,
      fileId: 1,
      webLink: 'http://somewhere.com',
    },
  },

  /* step 5 */

  monitorProtocol: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasValue: false,
      fileId: 1,
      webLink: 'http://somewhere.com',
    },
  },

  engagement: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: {
      hasEngagement: true,
      engagementList: ['engagment', 'list'],
    },
  },

  membershipList: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: ['membership', 'list'],
  },

  vettingAgencies: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: [
      {
        agencyId: 1,
        agencyName: 'agency',
        expirationDate: '2016-01-01',
        startDate: '2016-01-01',
        vettingAgencyLetterDtos: [
          {
            fileId: 1,
          },
        ],
      },
    ],
  },

  /* step 6 */

  contractManagement: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: false,

    value: 'asdf',
  },

  preventCorruption: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: false,

    value: 'asdf',
  },

  acceptGovFund: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: false,

    value: false,
  },

  disabilityPhysicalAccess: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: false,

    value: 'asdf',
  },

  disabilityProgramAccess: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: false,

    value: 'asdf',
  },

  disabilityCommunicationAccess: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: false,

    value: 'asdf',
  },

  childProtectionEmergencyPlan: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: false,

    value: {
      hasValue: false,
      fileId: 1,
      webLink: 'https://smartresponse.org',
      comment: null,
    },
  },

  /* step 7 */
  operatingLocationList: {
    lastUpdatedDate: 1485277006000,
    lastUpdatedUser: EXAMPLE_USER,
    updatedBySRAdmin: true,

    value: [
      {
        geoLatitude: 31.567,
        geoLongitude: -32.566,
        radius: 16098.4,
      },
    ],
  },

  operatingInEntireCountry: false,

  organizationDto: {
    country: 'UNITED STATES: Virgin Islands',
  },

  donateButtonDisabled: true,
};

const lssErrorCodeList: SurveyErrorCode[] = [
  { errCode: 'officeAddress', stepNum: 1, questionNum: 1, questionCount: 4 },
  { errCode: 'govIdOrEin', stepNum: 1, questionNum: 1, questionCount: 4 },
  { errCode: 'govIdOrEin2', stepNum: 1, questionNum: 1, questionCount: 4 },
  { errCode: 'startOpDate', stepNum: 1, questionNum: 2, questionCount: 4 },
  { errCode: 'startOpDateYear', stepNum: 1, questionNum: 2, questionCount: 4 },
  { errCode: 'activityHistory', stepNum: 1, questionNum: 2, questionCount: 4 },
  { errCode: 'activityHistoryVideoUrl', stepNum: 1, questionNum: 3, questionCount: 4 },
  { errCode: 'currentProgramVideoUrl', stepNum: 1, questionNum: 3, questionCount: 4 },
  { errCode: 'repContact', stepNum: 1, questionNum: 4, questionCount: 4 },
  { errCode: 'serviceList', stepNum: 2, questionNum: 1, questionCount: 5 },
  { errCode: 'reliefTypeList', stepNum: 2, questionNum: 2, questionCount: 5 },
  { errCode: 'acceptDonationsOfImportedGoods', stepNum: 2, questionNum: 3, questionCount: 5 },
  { errCode: 'disasterStageOfWork', stepNum: 2, questionNum: 4, questionCount: 5 },
  { errCode: 'disasterResponsePlan', stepNum: 2, questionNum: 5, questionCount: 5 },
  { errCode: 'itemCount', stepNum: 2, questionNum: 2, questionCount: 5 },
  { errCode: 'localStaff', stepNum: 3, questionNum: 1, questionCount: 3 },
  { errCode: 'nonLocalStaff', stepNum: 3, questionNum: 2, questionCount: 3 },
  { errCode: 'volunteerStaff', stepNum: 3, questionNum: 3, questionCount: 3 },
  { errCode: 'overallBudget', stepNum: 4, questionNum: 1, questionCount: 3 },
  { errCode: 'fiscalYearEnding', stepNum: 4, questionNum: 2, questionCount: 3 },
  { errCode: 'annualReport', stepNum: 4, questionNum: 3, questionCount: 3 },
  { errCode: 'monitorProtocol', stepNum: 5, questionNum: 1, questionCount: 4 },
  { errCode: 'engagement', stepNum: 5, questionNum: 2, questionCount: 4 },
  { errCode: 'membershipList', stepNum: 5, questionNum: 3, questionCount: 4 },
  { errCode: 'vettingAgencies', stepNum: 5, questionNum: 4, questionCount: 4 },
  { errCode: 'contractManagement', stepNum: 6, questionNum: 1, questionCount: 4 },
  { errCode: 'preventCorruption', stepNum: 6, questionNum: 2, questionCount: 4 },
  { errCode: 'acceptGovFund', stepNum: 6, questionNum: 3, questionCount: 4 },
  { errCode: 'disabilityPhysicalAccess', stepNum: 6, questionNum: 3, questionCount: 4 },
  { errCode: 'disabilityProgramAccess', stepNum: 6, questionNum: 3, questionCount: 4 },
  { errCode: 'disabilityCommunicationAccess', stepNum: 6, questionNum: 3, questionCount: 4 },
  { errCode: 'childProtectionEmergencyPlan', stepNum: 6, questionNum: 4, questionCount: 4 },
  { errCode: 'operatingInEntireCountry', stepNum: 7, questionNum: 2, questionCount: 3 },
  { errCode: 'operatingLocationList', stepNum: 7, questionNum: 3, questionCount: 3 },
];

@Injectable()
export class LocationSpecificSurveyService {
  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getMostRecentSurvey(organizationId: number, locationId: number): Observable<LocationSpecificSurveyInput> {
    return this.http
      .get<SRResponse>(`${LSS_URL}/${organizationId}/${locationId}`)
      .pipe(map((res) => res.responseData.survey));
  }

  getMostRecentSurveyForEdit(organizationId: number, locationId: number): Observable<LocationSpecificSurveyInput> {
    return this.http
      .get<SRResponse>(`${LSS_URL}/edit/${organizationId}/${locationId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.survey));
  }

  getSurveyById(surveyId: number): Observable<LocationSpecificSurveyInput> {
    return this.http.get<SRResponse>(`${LSS_URL}/${surveyId}`, {
	headers: this.authService.getAuthHeader(contentHeaders),
    }).pipe(map((res) => res.responseData.survey));
  }

  getSurveysByLocation(locationId: number): Observable<ScoreDto[]> {
    return this.http
      .get<ScoreSRResponse>(`${LSS_URL}/organizations/${locationId}`)
      .pipe(map((res) => res.responseData.lss_table_list));
  }

  getSurveysByRelatedDisaster(disasterId: number): Observable<LocationSpecificSurveyInput[]> {
    return this.http
      .get<SRResponse>(`${LSS_URL}/organizations?disasterId=${disasterId}`)
      .pipe(map((res) => res.responseData.lss_table_list));
  }

  submitSurvey(organizationId: number, locationId: number, survey: LocationSpecificSurveyOutput): Observable<Response> {
    return this.http.post<Response>(`${LSS_URL}/submit/${organizationId}/${locationId}`, JSON.stringify(survey), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  requestLSSSurveyUpdate(organizationId: number, locationId: number): Observable<SurveyUpdateRequest> {
    return this.http
      .post<SRResponse>(`${REQUEST_LSS_SURVEY_UPDATE_URL}/${organizationId}/${locationId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res));
  }

  // tslint:disable-next-line:max-line-length
  getEmptyOutdatedLSSSurvey(
    organizationId: number,
    countryId: number,
    survey: LocationSpecificSurveyOutput
  ): Observable<EmptyOutdatedQuestions> {
    return this.http
      .post(`${EMPTY_OUTDATED_LSS_SURVEY_ENDPOINT_URL}/${organizationId}/${countryId}`, JSON.stringify(survey), {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp as EmptyOutdatedQuestions));
  }

  getEmptyOutdatedLSSSurveyById(organizationId: number, countryId: number): Observable<EmptyOutdatedQuestions> {
    return this.http
      .get<EmptyOutdatedQuestions>(`${EMPTY_OUTDATED_LSS_SURVEY_ENDPOINT_URL}/${organizationId}/${countryId}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp as EmptyOutdatedQuestions));
  }

  getCurrencyCodes(): Observable<CurrencyCodes> {
    return this.http
      .get<CurrencyCodes>(`${RETRIEVAL_ENDPOINT_CURRENCY_CODE_URL}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((resp) => resp as CurrencyCodes));
  }

  getLSSErrorCodeList(): SurveyErrorCode[] {
    return lssErrorCodeList;
  }
}
