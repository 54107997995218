<div class="carousel">
  <ng-container *ngFor="let slide of slides; let i = index">
    <div class="slide-text-container">
      <!-- slide 1 -->
      <div
        class="slide-content slide1"
        [ngClass]="{
          'slide1-langue-en': currentLanguage === 'en',
          'slide1-langue-tr': currentLanguage === 'tr',
          'slide1-langue-fr': currentLanguage === 'fr',
          'slide1-langue-id': currentLanguage === 'id',
          'slide1-langue-pt': currentLanguage === 'pt'
        }"
        *ngIf="currentSlide === 0"
      >
        <span
          >{{ 'CarouselComponent-USE' | translate }}<br />
          {{ 'CarouselComponent-SMART' | translate
          }}<span class="response">{{ 'CarouselComponent-RESPONSE' | translate }}</span
          ><br />
          {{ 'CarouselComponent-TO_FIND_ORGANIZATIONS_IN' | translate }}<br /><span class="bg-word"
            ><span class="word-rotation" [class.active]="countryActive === 1" [class.hidden]="countryActive !== 1">{{
              country1 | translate
            }}</span>
            <span class="word-rotation" [class.active]="countryActive === 2" [class.hidden]="countryActive !== 2">{{
              country2 | translate
            }}</span>
          </span></span
        >
      </div>
      <!-- slide 2 -->
      <div
        class="slide-content slide2"
        [ngClass]="{
          'slide2-langue-tr': currentLanguage === 'tr'
        }"
        *ngIf="currentSlide === 1"
      >
        <span
          >{{ 'CarouselComponent-SMART' | translate
          }}<span class="response">{{ 'CarouselComponent-RESPONSE' | translate }}</span
          ><br />
          {{ 'CarouselComponent-CONNECTS' | translate }}<br /><span class="bg-word"
            ><span
              class="word-rotation"
              [class.active]="connectionTypeActive === 1"
              [class.hidden]="connectionTypeActive !== 1"
              >{{ connectionType1 | translate }}</span
            >
            <span
              class="word-rotation"
              [class.active]="connectionTypeActive === 2"
              [class.hidden]="connectionTypeActive !== 2"
              >{{ connectionType2 | translate }}</span
            > </span
          ><br />
          <span class="organization-text">{{ 'CarouselComponent-TO_ORGANIZATIONS' | translate }}</span
          ><br />
          {{ 'CarouselComponent-RESPONDING' | translate }}<br />
          {{ 'CarouselComponent-LOCALLY' | translate }}</span
        >
      </div>
      <!-- slide 3 -->
      <div
        class="slide-content slide3"
        [ngClass]="{
          'slide3-langue-tr': currentLanguage === 'tr',
          'slide3-langue-fr': currentLanguage === 'fr'
        }"
        *ngIf="currentSlide === 2"
      >
        <span
          >{{ 'CarouselComponent-JOURNALISTS' | translate }}<br />{{ 'CarouselComponent-USE' | translate }}
          {{ 'CarouselComponent-SMART' | translate
          }}<span class="response">{{ 'CarouselComponent-RESPONSE' | translate }}</span
          ><br />
          {{ 'CarouselComponent-TO_POPULATE_YOUR' | translate }}<br />{{ 'CarouselComponent-HOW_TO_HELP' | translate
          }}<br />{{ 'CarouselComponent-LIST_AFTER' | translate
          }}<span class="bg-word"
            ><span class="word-rotation active">{{ disasters | translate }}</span></span
          ></span
        >
      </div>
    </div>
    <img *ngIf="i === currentSlide" [src]="slide.src" class="slide" @carouselAnimation />
  </ng-container>
</div>
