import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AppSettings } from '../../../common/config';
import { Service } from '../../../models/Service';
import { contentHeaders } from '../../../common/headers';
import { AuthenticationService } from '../../../login/login.service';
import { Organization } from '../../../models/Organization';
import { SRResponse } from '../../../models/SRResponse';

@Injectable()
export class OrganizationService {
  private urlAdminOrgs = AppSettings.API_SERVER + '/smart-response/admin/organizations';
  private urlOrg = AppSettings.API_SERVER + '/smart-response/organization';
  private urlUpdateMetadata = AppSettings.API_SERVER + '/smart-response/admin/metadata/update';
  private urlGetMetadata = AppSettings.API_SERVER + '/smart-response/metadata/get';
  private urlAdminOrgCount = AppSettings.API_SERVER + '/smart-response/admin/all_organization/count';

  constructor(private http: HttpClient, public authService: AuthenticationService) {}

  getOrganizations(): Observable<Organization[]> {
    return this.http
      .get<SRResponse>(this.urlAdminOrgs, { headers: this.authService.getAuthHeader(contentHeaders) })
      .pipe(map((res) => res.responseData.org_list));
  }

  getOrganizationsWithOffset(search: string, offset: number, limit: number): Observable<Organization[]> {
    return this.http
      .get<SRResponse>(`${this.urlAdminOrgs}?search=${encodeURI(search)}&offset=${offset}&limit=${limit}`, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.org_list));
  }

  getOrganization(organizationId: number): Observable<Organization> {
    return this.http
      .get<SRResponse>(this.urlAdminOrgs + '/' + organizationId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.organization));
  }

  getOrganizationCount(search: string): Observable<number> {
    return this.http
      .get<SRResponse>(AppSettings.API_SERVER + '/smart-response/admin/all_organization/count')
      .pipe(map((res) => res.responseData.count));
  }

  addOrganization(organization: Organization): Observable<Organization[]> {
    return this.http.post<Organization[]>(this.urlAdminOrgs, JSON.stringify(organization), {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  updateOrganization(organization: Organization): Observable<Organization[]> {
    return this.http.put<Organization[]>(
      this.urlAdminOrgs + '/' + organization.organizationId,
      JSON.stringify(organization),
      { headers: this.authService.getAuthHeader(contentHeaders) }
    );
  }

  deleteorganization(organizationId: number): Observable<Organization[]> {
    return this.http.delete<Organization[]>(this.urlAdminOrgs + '/' + organizationId, {
      headers: this.authService.getAuthHeader(contentHeaders),
    });
  }

  getOrganizationService(organizationId: number): Observable<Service[]> {
    return this.http
      .get<SRResponse>(this.urlOrg + '/' + organizationId + '/' + 'services_provided', {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.service_provided));
  }

  updateOrganizationMetadata(organizationId: number, metadata: string): Observable<any> {
    const headers = this.authService.getAuthHeader(contentHeaders);
    const params = new HttpParams().set('metadata', metadata);

    const options = { headers, params };

    return this.http.put(this.urlUpdateMetadata + '/' + organizationId, null, options);
  }

  getOrgLocationMetadata(organizationId: number, locationId: number): Observable<string> {
    return this.http
      .get<SRResponse>(this.urlGetMetadata + '/org/country/' + organizationId + '/' + locationId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.metadata));
  }

  updateOrgLocationMetadata(organizationId: number, locationId: number, metadata: string): Observable<any> {
    const headers = this.authService.getAuthHeader(contentHeaders);
    const params = new HttpParams().set('metadata', metadata);

    const options = { headers, params };

    return this.http.put(this.urlUpdateMetadata + '/org/country/' + organizationId + '/' + locationId, null, options);
  }

  getOrgDisasterMetadata(organizationId: number, disasterId: number): Observable<string> {
    return this.http
      .get<SRResponse>(this.urlGetMetadata + '/org/disaster/' + organizationId + '/' + disasterId, {
        headers: this.authService.getAuthHeader(contentHeaders),
      })
      .pipe(map((res) => res.responseData.metadata));
  }

  updateOrgDisasterMetadata(organizationId: number, disasterId: number, metadata: string): Observable<any> {
    const headers = this.authService.getAuthHeader(contentHeaders);
    const params = new HttpParams().set('metadata', metadata);

    const options = { headers, params };

    return this.http.put(this.urlUpdateMetadata + '/org/disaster/' + organizationId + '/' + disasterId, null, options);
  }
}
