import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { handleErrors } from '../common/error';
import { ResetPasswordAuth } from './reset-password.service';
import { GlobalLoaderFacade } from '../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { passwordMismatchValidator } from '../validators/validators';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [ResetPasswordAuth],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;

  token: string;

  isTokenValid: boolean = false;
  hasChangedPassword: boolean = false;
  isLoading: boolean = false;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private resetPasswordAuth: ResetPasswordAuth,
    private loaderService: GlobalLoaderFacade,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {
    this.resetPasswordForm = new FormGroup(
      {
        password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(45)]),
        passwordConfirmation: new FormControl(null),
      },
      passwordMismatchValidator
    );
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.token = params['id'];

      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.loaderService.start();
      this.loading = true;

      this.resetPasswordAuth.tokenValidation(this.token).subscribe(
        (res) => {
          this.isTokenValid = true;

          this.isLoading = false;

          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(
            error,
            this.translateService,
            this.notificationsService,
            'ResetPasswordComponent-CHANGE_PASSWORD_HEADING'
          );

          this.isLoading = false;

          this.loaderService.complete();
          this.loading = false;
        }
      );
    });
  }

  onSubmit() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const submitData = {
      password: this.resetPasswordForm.value.password,
      token: this.token,
    };

    this.loaderService.start();

    this.resetPasswordAuth.resetPassword(submitData).subscribe(
      (res) => {
        this.isLoading = false;

        this.hasChangedPassword = true;

        this.loaderService.complete();
      },
      (error) => {
        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'ResetPasswordComponent-CHANGE_PASSWORD_HEADING'
        );

        this.isLoading = false;
        this.loaderService.complete();
      }
    );
  }
}
