import { Component } from '@angular/core';

// const template = require('./country.component.html');
// const styles = require('./country.component.css');

@Component({
  selector: 'country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css'],
  providers: [],
})
export class CountryComponent {
  constructor() {}
}
