<div class="row">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12">
    <nav class="breadcrumb-nav">
      <div class="nav-wrapper">
        <div class="col s12 breadcrumb-div">
          <a routerLink="/" class="breadcrumb">
            {{ 'UNIVERSAL-NAVIGATION_HOME' | translate }}
          </a>
          <a class="breadcrumb">
            {{ disaster?.name || ('DisasterAboutComponent-DISASTER_INFO_NO_NAME' | translate) }}
          </a>
        </div>
      </div>
    </nav>
    <div class="col s12 divider"></div>
  </div>
</div>
<div class="page-container">
  <div class="row">
    <h5 class="sr-bold black-text lighten-1">
      {{ disaster?.name }}
    </h5>
    <div class="col s8 offset-s2 m3 l2">
      <img
        src="{{ disaster?.logo ? disaster?.logo?.uri : 'assets/images/disaster-default.png' }}"
        alt="disaster?.name"
        class="collection-img responsive-img"
      />

      <div class="response-stats" *ngIf="disaster?.displayDss">
        <div class="description-element">
          <span class="sr-bold">
            {{ 'DisasterAboutComponent-MONEY_RAISED' | translate }}
          </span>
          <div class="color-text">
            {{
              disasterSummary?.moneyRaised?.amount
                | customCurrency: currency?.map[disasterSummary?.moneyRaised?.currency]
            }}
          </div>
        </div>
        <div class="description-element">
          <span class="sr-bold">
            {{ 'DisasterAboutComponent-PERC_SPENT' | translate }}
          </span>
          <div class="color-text">{{ disasterSummary?.percentageSpent | number: '1.2-2' }} %</div>
        </div>
        <div class="description-element">
          <span class="sr-bold">
            {{ 'DisasterAboutComponent-BOOTS_ON_GROUND' | translate }}
          </span>
          <div class="color-text">
            {{ disasterSummary?.staffCount }}
          </div>
        </div>
      </div>
    </div>

    <div class="col s12 m4 l6">
      <!-- explanation of the disaster -->
      <div class="description-element">
        <div class="sr-justify summary" [innerHtml]="disaster?.summary"></div>
      </div>
      <div class="description-element">
        <span class="sr-bold">
          {{ 'DISASTER_COMMON-DISASTER_DATE_LABEL' | translate }}
        </span>
        <div class="sr-justify">
          {{ disaster?.dateOfDisaster }}
        </div>
      </div>
      <div class="description-element">
        <span class="sr-bold">
          {{ 'DisasterListHomeComponent-LOCATIONS' | translate }}
        </span>
        <div class="sr-justify">
          <span *ngFor="let location of disaster?.locations | unique: 'country.countryId'; let isLast = last">
            <a [routerLink]="['/location', location.country.countryId]">
              {{ location.country.name }}
            </a>
            {{ isLast ? '' : ', ' }}
          </span>
        </div>
      </div>
      <!-- <div class="description-element">
        <span class="sr-bold">
          {{ 'DisasterAboutComponent-SERVICES' | translate }}
        </span>
        <div class="servicesContainer">
          <span *ngFor="let serviceProvided of disaster?.services; let i=index; let isLast = last">
            <span>
              {{serviceProvided.name}}
            </span> {{ isLast ? '' : ', ' }}
          </span>
        </div>
      </div> -->
    </div>

    <div class="col s12 m5 l4">
      <div class="card org-card" *ngIf="disaster?.displayDss">
        <div class="card-content">
          <p class="center black-text sr-bold text-size">
            {{ 'COMMON_SEARCH-GROUPS_ACTIVE' | translate }}
          </p>
          <div class="divider"></div>
          <table class="centered">
            <thead class="groups-active-header">
              <tr>
                <th class="sr-bold">
                  {{ 'COMMON_SEARCH-CIVIL_SOCIETY' | translate }}
                </th>
                <th class="sr-bold">
                  {{ 'COMMON_SEARCH-INTL_NGO' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ csloCount }}
                </td>
                <td>
                  {{ ingoCount }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <a class="waves-widget btn cyan" (click)="displayWidget()">{{
          'DisasterAboutComponent-GET_WIDGET' | translate
        }}</a>
      </div>
      <div *ngIf="widgetText">
        <div>{{ 'DisasterWidgetComponent-MODAL-TITLE' | translate }}</div>
        <div class="row">
          <div class="form-group">
            <textarea class="form-control widget-text" onclick="this.select();" readonly>{{ widgetText }}  </textarea>
          </div>
        </div>
      </div>
      <share-buttons-custom [metadata]="disaster?.metadata"> </share-buttons-custom>
    </div>
  </div>

  <div class="card" id="organizations-list">
    <div class="card-content">
      <h6 class="sr-bold org-list">
        {{ 'DisasterAboutComponent-LIST_ORGS_RESPONDING' | translate: { disasterName: disaster?.name } }}
      </h6>
      <div class="row">
        {{ 'DisasterAboutComponent-LIST_ORGS_RESPONDING_NOTICE' | translate }}
      </div>
      <div class="row s3 m3 valign-wrapper sr-filter-row">
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-SHOW_TEXT' | translate }}</label>
        <select
          materialize="material_select"
          (change)="onItemsPerPageChange($event.target.value)"
          name="entries"
          id="entries"
          class="browser-default entries-select"
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
        </select>
        <label for="entries" class="my_select1">{{ 'UNIVERSAL-ENTRIES_TEXT' | translate }}</label>
      </div>

      <!-- Table for Organization Data from DSS -->
      <div *ngIf="disaster?.displayDss" class="grey lighten-3">
        <table class="bordered centered responsive-table">
          <!-- Table Headers -->
          <thead>
            <tr>
              <th class="header-cell">{{ 'DisasterAboutComponent-ORG_NAME' | translate }}</th>
              <th class="header-cell">{{ 'DisasterAboutComponent-STAFF_RESPONDING' | translate }}</th>
              <th class="header-cell hide-on-med-and-down">{{ 'DisasterAboutComponent-SERVICES' | translate }}</th>
              <th class="header-cell">{{ 'DisasterAboutComponent-OVERALL_ANNUAL_BUDGET' | translate }}</th>
              <th class="header-cell">{{ 'DisasterAboutComponent-MONEY_RAISED' | translate }}</th>
              <th class="header-cell">{{ 'DisasterAboutComponent-HQ_LOCATION' | translate }}</th>
              <th class="header-cell"></th>
            </tr>
          </thead>
          <!-- Table Body -->
          <tbody>
            <tr
              *ngFor="
                let org of respondingOrgs | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
              "
            >
              <!-- Organization Name -->
              <td class="align-top text-left-align">
                <a class="sr-link" [routerLink]="['/org-disaster', org.id, disaster.disasterId]">{{ org.name }}</a>
                <i
                  (click)="openDssScoreModal(org.id)"
                  class="material-icons blue-text add-left-padding vertical-alignment"
                >
                  insert_chart</i
                >
              </td>
              <!-- Staff Responding -->
              <td class="valign-top cell">{{ org.staffCount }}</td>
              <!-- Service Provided : show service(s) and its(their) progress -->
              <td class="valign-top cell hide-on-med-and-down">
                <!-- <div class="row"> -->
                <div *ngFor="let service of org.serviceList">
                  <!-- Service Name -->
                  <div>{{ service }}</div>
                </div>
                <div>
                  <a class="sr-link" [routerLink]="['/org-disaster', org.id, disaster.disasterId]">
                    {{ 'DisasterAboutComponent-VIEW_MORE' | translate }}
                  </a>
                </div>
              </td>
              <td class="cell">
                {{
                  org.budget?.amount !== null
                    ? (org.budget?.amount | customCurrency: currency?.map[org.budget?.currency])
                    : '&nbsp;'
                }}
              </td>
              <td class="cell">
                {{
                  org.raised?.amount !== null
                    ? (org.raised?.amount | customCurrency: currency?.map[org.raised?.currency])
                    : '&nbsp;'
                }}
              </td>
              <td class="cell">
                {{ org.hqLocation }}
              </td>
              <!-- Donate button -->
              <td class="valign-top cell">
                <a
                  class="btn blue lighten-1 waves-effect waves-light"
                  (click)="openDonateModal(org.name, org.id, org.donateUrl)"
                  target="_blank"
                  [href]="org.donateUrl | linkHttp"
                  [ngClass]="{ disabled: org.donateButtonDisabled || org.donateUrl === '' }"
                >
                  {{ 'DisasterAboutComponent-DONATE' | translate }}
                </a>
                <div>
                  <a class="sr-link" [routerLink]="['/org-disaster', org.id, disaster.disasterId]">
                    {{ 'DisasterAboutComponent-LEARN_MORE' | translate }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Table for Organization Data from LSS-->
      <div *ngIf="!disaster?.displayDss" class="grey lighten-3">
        <table class="centered responsive-table">
          <thead>
            <tr>
              <th class="header-cell">Organization Name</th>
              <th class="header-cell">Location</th>
              <th class="header-cell">Staff in Location</th>
              <th class="header-cell hide-on-med-and-down">Services Provided</th>
              <th class="header-cell">Overall Annual Budget in Location</th>
              <th class="header-cell">Headquarters Location</th>
              <th class="header-cell"></th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="
                let org of locationOrgs
                  | unique: 'name'
                  | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
              "
            >
              <!-- Organization Name -->
              <td class="align-top text-left-align org-name-td cell">
                <a [routerLink]="['/org-location', org.id, org.locationId]" class="vertical-alignment">{{
                  org.name
                }}</a>
                <i
                  (click)="openLssScoreModal(org.id, org.locationId)"
                  class="material-icons add-left-padding blue-text score-modal vertical-alignment"
                  >insert_chart</i
                >
              </td>

              <!-- Location linking Disaster with this Organization -->
              <td class="valign-top cell">{{ org.location }}</td>

              <!-- Staff Responding -->
              <td class="valign-top cell">{{ org.staffCount }}</td>

              <!-- Service Provided : show service(s) and its(their) progress -->
              <td class="valign-top cell hide-on-med-and-down">
                <!-- Service Name -->
                <div *ngFor="let service of org.serviceList">
                  <div>{{ service }}</div>
                </div>
                <div>
                  <a [routerLink]="['/org-location', org.id, org.locationId]">
                    {{ 'LocationAboutComponent-VIEW_MORE' | translate }}
                  </a>
                </div>
              </td>

              <td class="cell">
                {{
                  org.budget?.amount !== null
                    ? (org.budget?.amount | customCurrency: currency?.map[org.budget?.currency])
                    : '&nbsp;'
                }}
              </td>
              <td class="cell">{{ org.hqLocation }}</td>

              <!-- Donate button -->
              <td class="valign-top cell">
                <a
                  class="btn blue lighten-1 waves-effect waves-light"
                  target="_blank"
                  (click)="openDonateModal(org.name, org.id, org.donateUrl)"
                  [href]="org.donateUrl | linkHttp"
                  [ngClass]="{ disabled: org.donateButtonDisabled || org.donateUrl === '' }"
                >
                  {{ 'DisasterAboutComponent-DONATE' | translate }}
                </a>
                <div>
                  <a class="sr-link" [routerLink]="['/org-location', org.id, org.locationId]">
                    {{ 'DisasterAboutComponent-LEARN_MORE' | translate }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Donation Modal -->
      <div
        id="donateModal"
        class="modal modal-fixed-footer col s10 m5 offset-m4"
        materialize="modal"
        [materializeParams]="[{ dismissible: true }]"
        [materializeActions]="donateModalActions"
      >
        <donation-modal
          [orgName]="oName"
          [orgId]="oID"
          [donateUrl]="oDonateUrl"
          [donateModalActions]="donateModalActions"
          [tippingModalActions]="tippingModalActions"
        >
        </donation-modal>
      </div>
      <!--Tipping Modal-->
      <div
        id="tippingModal"
        class="modal modal-fixed-footer col s10 m5 offset-m4"
        materialize="modal"
        [materializeParams]="[{ dismissible: true }]"
        [materializeActions]="tippingModalActions"
      >
        <givelively-donation
          [donateModalActions]="donateModalActions"
          [tippingModalActions]="tippingModalActions"
          [tippingText]="true"
        >
        </givelively-donation>
      </div>
    </div>
    <div class="row s12 m12">
      <pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
    </div>
  </div>
</div>
<score-by-disaster [modalActions]="disasterScoreActions"></score-by-disaster>
<score-by-location-modal [modalActions]="locationScoreActions"></score-by-location-modal>
