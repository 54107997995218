import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { OrganizationService } from '../organization.service';
import { handleErrors } from '../../../../common/error';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { LeadingTrailingWhiteSpaceValidator } from '../../../../validators/leadingTrailingWhiteSpaceValidator';
import { OrgValidator } from '../../../../validators/orgValidator';

@Component({
  selector: 'dashboardCreateOrganization',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css', '../../../common-create.css'],
  providers: [],
})
export class DashboardOrganizationCreateComponent {
  organizationName: FormControl;

  isSubmitting: boolean = false;

  loading: boolean;

  constructor(
    private router: Router,
    private orgService: OrganizationService,
    private loaderService: SlimLoadingBarService,
    private translateService: TranslateService,
    private notificationService: NotificationsService
  ) {
    this.organizationName = new FormControl('', [
      Validators.required,
      Validators.maxLength(128),
      OrgValidator.orgFormat,
      LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
    ]);
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.loaderService.start();
    this.loading = true;

    const submitData = {
      name: this.organizationName.value,
    };

    this.orgService.addOrganization(submitData).subscribe(
      (response) => {
        this.isSubmitting = false;

        this.notificationService.success(
          this.translateService.instant('Organization_Common-TITLE'),
          this.translateService.instant('DashboardOrganizationCreateComponent-SUCCESS_CREATE_MESSAGE', {
            orgName: submitData.name,
          })
        );

        this.router.navigate(['dashboard/organizations']);
        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationService, 'Organization_Common-TITLE');

        this.isSubmitting = false;

        this.loaderService.complete();
        this.loading = false;
      }
    );
  }

  onCancel() {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      this.router.navigate(['dashboard/organizations']);
    }
  }
}
