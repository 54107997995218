import { FormControl } from '@angular/forms';

interface ValidationResult {
  [key: string]: boolean;
}

export class PhoneValidator {
  public static phoneFormat(control: FormControl): ValidationResult {
    if (control.value) {
      // tslint:disable-next-line:max-line-length
      const PHONE_REGEXP = /^(?:\+?(\d{1,3}))?([-. (]?(\d{2,})[-. )]?)?((\d{2,})[-. ]?(\d{2,4}))(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
      if (control.value !== '' && !PHONE_REGEXP.test(control.value)) {
        return { phoneFormat: true };
      }
    }
    return null;
  }
}
