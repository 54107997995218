import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DisasterSpecificSurveyInput } from '../../../../../models';

@Component({
  selector: 'dss-step-list',
  templateUrl: 'dss-step-list.component.html',
  styleUrls: ['dss-step-list.component.css'],
})
export class DSSStepListComponent {
  private privateSurveyData = new BehaviorSubject<DisasterSpecificSurveyInput>(null);

  @Input('survey-data')
  set surveyData(value) {
    this.privateSurveyData.next(value);
  }

  get surveyData() {
    return this.privateSurveyData.getValue();
  }

  @Output() changeStep: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  onStepClick(stepIndex: number, event: Event) {
    // Prevent page from changing
    event.preventDefault();

    // Open modal
    this.changeStep.emit(stepIndex);
  }
}
