import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesProvidedService } from '../service.service';
import { Service } from '../../../../models/Service';
import { handleErrors } from '../../../../common/error';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../../../common/config';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'dashboardViewService',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css', '../../../common-view.css'],
  providers: [],
})
export class DashboardServiceViewComponent implements OnInit {
  public myService: Service;

  public AppSettings = AppSettings;

  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private servicesProvided: ServicesProvidedService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = +params['id'];

      this.loaderService.start();
      this.loading = true;

      this.servicesProvided.getService(id).subscribe(
        (data) => {
          this.myService = data;
          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'SERVICE_COMMON_TITLE');

          this.loaderService.complete();
          this.loading = false;
        }
      );
    });
  }
}
