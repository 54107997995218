import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../organization.service';
import { Organization } from '../../../../models/Organization';

import { AppSettings } from '../../../../common/config';
import { handleErrors } from '../../../../common/error';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'dashboardBaseOrganization',
  templateUrl: './base.component.html',
  styleUrls: ['../../../common-base.css', './base.component.css'],
  providers: [],
})
export class DashboardOrganizationBaseComponent implements OnInit {
  organizations: Organization[];

  filterArg: string;

  itemsPerPageArg: number = 10;
  selectedPage: number = 0;

  sortField: string;
  sortDescending: boolean = true;

  AppSettings = AppSettings;

  loading: boolean;
  totalItems = 0;

  constructor(
    private service: OrganizationService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService
  ) {}

  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  ngOnInit() {
    this.filterArg = '';
    this.service.getOrganizationCount(this.filterArg).subscribe((resp) => (this.totalItems = resp));

    this.getPage(1);
  }

  onItemsPerPageChange(updatedVal: number) {
    // if itemsPerArg is a string, there is undefined and wacky behavior exhibited by pagination
    this.itemsPerPageArg = +updatedVal;
    this.selectedPage = 0;
    this.getPage(1);
  }

  onFilterChange(search: string) {
    this.filterArg = search;
    this.selectedPage = 0;
    this.service.getOrganizationCount(this.filterArg).subscribe((resp) => (this.totalItems = resp));

    this.getPage(1);
  }

  onDelete(orgToDelete: Organization) {
    if (
      confirm(
        this.translateService.instant('DashboardOrganizationBaseComponent-DELETE_ORGANIZATION_CONFIRMATION', {
          orgName: orgToDelete.name,
        })
      )
    ) {
      this.loaderService.start();
      this.loading = true;

      this.service.deleteorganization(orgToDelete.organizationId).subscribe(
        (response) => {
          this.notificationService.success(
            this.translateService.instant('Organization_Common-TITLE'),
            this.translateService.instant('DashboardOrganizationBaseComponent_SUCCESS_DELETE', {
              orgName: orgToDelete.name,
            })
          );

          // remove serviceToDelete from services array
          this.organizations = this.organizations.filter((item) => item.organizationId !== orgToDelete.organizationId);

          // reset select page to an existing page if previously selected page no longer "exists"
          const numPages = Math.ceil(this.organizations.length / this.itemsPerPageArg);
          this.selectedPage = this.selectedPage > numPages ? numPages : this.selectedPage;

          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'Organization_Common-TITLE');

          this.loaderService.complete();
          this.loading = false;
        }
      );
    }
  }

  getPage($event) {
    this.loading = true;
    this.selectedPage = $event;
    this.service
      .getOrganizationsWithOffset(this.filterArg, (this.selectedPage - 1) * this.itemsPerPageArg, this.itemsPerPageArg)
      .subscribe(
        (data) => {
          this.organizations = data;

          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'Organization_Common-TITLE');

          this.loaderService.complete();
          this.loading = false;
        }
      );
  }
}
