<div class="modal-content">
  <h4>
    {{
      'DisasterSpecificSurveyComponent-STEP_1_TITLE'
        | translate: { orgName: surveyData.organizationName, disasterName: surveyData.disaster.name }
    }}
  </h4>

  <div class="divider"></div>

  <div class="row margin">Step 1 content</div>
</div>

<div class="modal-footer">
  <survey-footer
    [currentStepIndex]="0"
    [totalSteps]="totalSteps"
    [isValid]="isValid"
    (next)="onNext()"
    (prev)="onPrev()"
    (cancel)="onCancel()"
    (error)="onError()"
  >
  </survey-footer>
</div>
