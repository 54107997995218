import { AgmInfoWindow, AgmMap, AgmMarker, MapsAPILoader } from '@agm/core';
import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MaterializeAction } from 'angular2-materialize';
import { NotificationsService } from 'angular2-notifications';
import { CurrencyCodes } from 'app/models/CurrencyCodes';
import { EmptyOutdatedQuestions } from 'app/models/EmptyOutdatedQuestions';
import { SurveyNotification } from 'app/models/SurveyNotification';
import { VettingAgencyFormData } from 'app/models/VettingAgencyFormData';
import { VimeoYoutubeUrlValidator } from 'app/validators/vimeoYoutubeUrlValidator';
import { OnlyWhiteSpaceValidator } from 'app/validators/onlyWhiteSpaceValidator';
import { Observable } from 'rxjs';
import { zip } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { AppSettings } from '../../../../common/config';
import { handleErrors } from '../../../../common/error';
import {
  Collection,
  Country,
  Dictionary,
  LocationSpecificSurveyInput,
  LocationSpecificSurveyOutput,
  ReliefTypeFormData,
  Service,
  ServiceFormData,
  SurveyErrorCode,
  UserType,
} from '../../../../models';
import { ReliefRecoveryItem } from '../../../../models/ReliefRecoveryItem';
import { VettingAgencyInfo } from '../../../../models/VettingAgencyInfo';
import { DocumentService } from '../../../../sharedServices/documentService/document.service';
import { BasicDateValidator } from '../../../../validators/basicDateValidator';
import { EmailValidator } from '../../../../validators/emailValidator';
import { ServicesProvidedValidator } from '../../../../validators/servicesProvidedValidator';
import { membershipFormatValidators, partnerFormatValidators } from '../../../../validators/validators';
import { YearDateValidator } from '../../../../validators/yearDateValidator';
import { BlockingLoaderState } from '../../blockingLoader/blocking-loader.model';
import { ListComponentService } from '../../configureLists/list/list.service';
import { RELIEF_RECOVERY_CONFIG, VETTING_AGENCIES_CONFIG } from '../../configureLists/lists.component';
import { CountryService } from '../../country/country.service';
import { RadiusOption, radiusOptions } from '../../disaster/radiusOptions';
import { ServicesProvidedService } from '../../service/service.service';
import { DashboardRedirectService } from '../../shared/dashboard-redirect.service';
import { CountryListService } from '../shared/countryList.service';
import { errSort } from '../shared/survey-error-helpers';

import { SurveyComponent } from '../shared/survey/survey.component';
import {
  FormElement,
  getFloatFromInput,
  getNumberFromInput,
  isInputFilledNumber,
  responseToFormElement,
} from '../survey-helpers';
import { LSSSurveyInputInterface } from '../survey-input-interface.model';
import { LocationSpecificSurveyService } from './location-specific-survey.service';
import { PhoneValidator } from '../../../../validators/phoneValidator';

const SERVICES_PROVIDED_EXPECTED_TOTAL = 100;
const MAP_STEP_INDEX = 6;
declare var google: any;

@Component({
  selector: 'location-specific-survey',
  templateUrl: './location-specific-survey.component.html',
  styleUrls: ['./location-specific-survey.component.css', '../survey-table-common.css'],
})
export class LocationSpecificSurveyComponent extends SurveyComponent implements AfterViewInit {
  loadingSurveyData: boolean;
  @Input()
  set surveyInterface(surveyInterface: LSSSurveyInputInterface) {
    if (surveyInterface) {
      this.lssSurveyInputInterface = surveyInterface;
      this.loadingSurveyData = true;
      const servicesProvidedObservable = this.servicesProvidedService.getServices();
      const countryListObservable = this.countryService.getCountries();
      const reliefItemsObservable = this.listService.getDefault(RELIEF_RECOVERY_CONFIG.endpoint);
      const vettingAgencyInfoObservable = this.listService.getDefault(VETTING_AGENCIES_CONFIG.endpoint);
      let surveyObservable: Observable<LocationSpecificSurveyInput>;

      if (surveyInterface.surveyId) {
        surveyObservable = this.surveyService.getSurveyById(surveyInterface.surveyId);
      } else if (surveyInterface.organizationId && surveyInterface.countryId) {
        surveyObservable = this.surveyService.getMostRecentSurveyForEdit(
          surveyInterface.organizationId,
          surveyInterface.countryId
        );
      } else {
        console.log('Bad survey input!', surveyInterface);
        return;
      }

      surveyObservable
        .pipe(
          this.dashRedirectService.redirectUnauthorized(),
          zip(
            servicesProvidedObservable,
            countryListObservable,
            reliefItemsObservable,
            vettingAgencyInfoObservable,
            (
              survey: LocationSpecificSurveyInput,
              servicesProvided: Service[],
              countries: Country[],
              reliefItems: ReliefRecoveryItem[],
              vettingAgencyInfo: VettingAgencyInfo[]
            ) => ({
              survey,
              servicesProvided,
              countries,
              reliefItems,
              vettingAgencyInfo,
            })
          )
        )
        .subscribe(
          (success) => {
            this.servicesProvided = success.servicesProvided;
            this.surveyDataInput = success.survey;
            this.loadingSurveyData = false;
            this.reliefRecoveryItems = success.reliefItems;
            this.countries = success.countries;
            this.vettingAgencyInfo = success.vettingAgencyInfo;

            this.setMapCountry();
            this.resetFormToInput();
            this.getCurrencyCodeList();
            this.checkForCountry();
            this.loading = false;
            this.initModal(this.onSelectQuestion);
          },
          (error) => {
            handleErrors(error, this.translateService, this.notificationsService, 'UNIVERSAL-SURVEY_LSS');
            this.noErrorResponse = false;
            this.loading = false;
          }
        );
    }
  }

  @Output()
  closeLSS: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  allowClosing: boolean;

  @ViewChild(AgmMap, { static: false }) map: AgmMap;
  @ViewChildren(AgmInfoWindow) markerWindows: QueryList<AgmInfoWindow>;

  surveyDataInput: LocationSpecificSurveyInput;
  lssSurveyInputInterface: LSSSurveyInputInterface;
  emptyOutdatedQuestions: SurveyNotification[] = [];
  countryID: number = null;

  mapsApiLoader: MapsAPILoader;
  mapLocationLat: number;
  mapLocationLng: number;
  readonly MISSING_STEP_INDEX: number = 7;
  readonly TOTAL_STEPS: number = 8;
  lssFormErrors: string[] = [];
  errStepDetails: SurveyErrorCode[] = [];
  invalidOnInit: Collection<boolean> = {
    singles: {
      activityHistoryVideoUrl: false,
      currentProgramVideoUrl: false,
      fiscalYearEnding: false,
      overallBudget: false,
      startOpDateYear: false,
    },
    arrays: {
      engagementList: [],
      membershipList: [],
      reliefTypeList: [],
      serviceListDirect: [],
      serviceListIndirect: [],
    },
    maps: {
      annualReport: {
        webLink: false,
      },
      childProtectionEmergencyPlan: {
        webLink: false,
      },
      disasterResponsePlan: {
        webLink: false,
      },
      monitorProtocol: {
        webLink: false,
      },
      repContact: {
        email: false,
      },
      localStaff: {
        mgmtStaffCount: false,
        professionalStaffCount: false,
        supportStaffCount: false,
      },
      nonLocalStaff: {
        mgmtStaffCount: false,
        professionalStaffCount: false,
        supportStaffCount: false,
      },
      volunteerStaff: {
        mgmtStaffCount: false,
        professionalStaffCount: false,
        supportStaffCount: false,
      },
    },
  };
  currencyCodeList: CurrencyCodes[];

  radii: RadiusOption[] = radiusOptions.filter((option) => option.value !== null);

  headElements = [
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_SURVEY_NAME', sortName: 'surveyName' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_SURVEY_TYPE', sortName: 'surveyType' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_STEP', sortName: 'stepNumber' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_QUESTION', sortName: 'questionNumber' },
    { name: 'SurveyNotificationsComponent-TABLE_HEADER_STATUS', sortName: 'status' },
  ];
  surveyStatus: string;
  itemsPerPageArg: number = 10;
  selectedPage: number = 0;
  sortField: string = 'name';
  sortDescending: boolean = true;
  filterArg: string;
  step1ModalActions: EventEmitter<string | MaterializeAction>;
  step2ModalActions: EventEmitter<string | MaterializeAction>;
  step3ModalActions: EventEmitter<string | MaterializeAction>;
  step4ModalActions: EventEmitter<string | MaterializeAction>;
  step5ModalActions: EventEmitter<string | MaterializeAction>;
  step6ModalActions: EventEmitter<string | MaterializeAction>;
  step7ModalActions: EventEmitter<string | MaterializeAction>;
  step8ModalActions: EventEmitter<string | MaterializeAction>;

  prevStepModal: EventEmitter<string | MaterializeAction>;
  prevStepIndex: number;

  blockingLoaderEventEmitter = new EventEmitter<BlockingLoaderState>();

  isSubmitting: boolean = false;
  noErrorResponse: boolean = true;

  servicesProvided: Service[];
  reliefRecoveryItems: ReliefRecoveryItem[];
  countries: Country[];
  vettingAgencyInfo: VettingAgencyInfo[];
  selectedVettingAgencies = null;

  UserType = UserType;

  AppSettings = AppSettings;

  lssForm: Dictionary<FormElement>;
  surveyDataOutput: EmptyOutdatedQuestions;
  hideAcceptImportedGoodsQuest: boolean;
  step2QuestCount: number;
  loading = true;

  constructor(
    private servicesProvidedService: ServicesProvidedService,
    protected translateService: TranslateService,
    protected notificationsService: NotificationsService,
    private surveyService: LocationSpecificSurveyService,
    private listService: ListComponentService,
    private countryService: CountryService,
    private countryListService: CountryListService,
    private documentService: DocumentService,
    private dashRedirectService: DashboardRedirectService,
    private mapsApi: MapsAPILoader,
    private fb: FormBuilder
  ) {
    super(translateService, notificationsService);
    this.resetModalActions();
    this.mapsApiLoader = mapsApi;
  }

  ngAfterViewInit(): void {
    this.markerWindows.changes.subscribe(() => {
      Promise.all(this.markerWindows.map((window) => window.close())).then(
        () => this.markerWindows && this.markerWindows.last && this.markerWindows.last.open()
      );
    });
  }

  openStep7Modal(): void {
    this.map && this.map.triggerResize();
    this.step7ModalActions.emit({ action: 'modal', params: ['open'] });
    Promise.all(this.markerWindows.map((window) => window.close())).then(
      () => this.markerWindows && this.markerWindows.last && this.markerWindows.last.open()
    );
  }

  resetFormToInput(): void {
    if (isNullOrUndefined(this.surveyDataInput)) {
      // because backend returns null for a survey that has no previous submissions,
      // we have to guard against failing incorrectly
      this.surveyDataInput = <LocationSpecificSurveyInput>{};
    }

    this.resetModalActions();
    this.modalActions.steps[MAP_STEP_INDEX].subscribe((event) => {
      if (event.params[0] === 'open') {
        setTimeout(() => {
          this.map && this.map.triggerResize();
          Promise.all(this.markerWindows.map((window) => window.close())).then(
            () => this.markerWindows && this.markerWindows.last && this.markerWindows.last.open()
          );
        }, 100);
      }
    });

    this.lssForm = {};
    this.selectedVettingAgencies = this.vettingAgencyInfo.map((x) => Object.assign({}, x));

    /*************
     * Step 1
     */
    if (!isNullOrUndefined(this.surveyDataInput.officeAddress)) {
      this.lssForm['officeAddress'] = {
        lastUpdated: new Date(this.surveyDataInput.officeAddress.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.officeAddress.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.officeAddress.updatedBySRAdmin,

        valueControl: new FormGroup({
          address1: new FormControl(
            this.surveyDataInput.officeAddress.value.address1,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          address2: new FormControl(
            this.surveyDataInput.officeAddress.value.address2,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          city: new FormControl(
            this.surveyDataInput.officeAddress.value.city,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          state: new FormControl(
            this.surveyDataInput.officeAddress.value.state,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    } else {
      this.lssForm['officeAddress'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          address1: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          address2: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          city: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          state: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    const simpleStep1Questions = {
      activityHistory: OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat,
      activityHistoryVideoUrl: VimeoYoutubeUrlValidator.vimeoYoutubeUrlFormat,
      currentProgramVideoUrl: VimeoYoutubeUrlValidator.vimeoYoutubeUrlFormat,
      startOpDate: [],
      startOpDateYear: [YearDateValidator.isYear, YearDateValidator.pastYear, YearDateValidator.minimumYear],
    };

    for (const key in simpleStep1Questions) {
      if (simpleStep1Questions.hasOwnProperty(key)) {
        this.lssForm[key] = responseToFormElement(this.surveyDataInput, key, simpleStep1Questions[key]);
        this.setInitialErrorVisibility(key, this.lssForm);
      }
    }
    {
      const fcSOD = this.lssForm['startOpDate'].valueControl as FormControl;
      const fcSODY = this.lssForm['startOpDateYear'].valueControl as FormControl;

      fcSODY.valueChanges.subscribe((data) => {
        if (data === '') {
          fcSOD.setValue('');
        } else {
          fcSOD.setValue(data + '-01-01');
        }
      });

      if (fcSOD.value && fcSOD.value.length >= 4) {
        fcSODY.setValue(fcSOD.value.substring(0, 4));
      }
    }

    if (!(this.surveyDataInput.repContact === null || this.surveyDataInput.repContact === undefined)) {
      const phone1 = this.surveyDataInput.repContact.value.phone1 || '';
      if (
        !(
          this.surveyDataInput.repContact.value.country === null ||
          this.surveyDataInput.repContact.value.country === undefined
        )
      ) {
        this.countryID = this.surveyDataInput.repContact.value.country.countryId;
      }
      this.lssForm['repContact'] = {
        lastUpdated: new Date(this.surveyDataInput.repContact.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.repContact.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.repContact.updatedBySRAdmin,

        valueControl: new FormGroup({
          firstName: new FormControl(
            this.surveyDataInput.repContact.value.firstName,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          lastName: new FormControl(
            this.surveyDataInput.repContact.value.lastName,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          title: new FormControl(
            this.surveyDataInput.repContact.value.title,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
          email: new FormControl(this.surveyDataInput.repContact.value.email, EmailValidator.mailFormat),
          phone1: new FormControl(phone1.replace(/\s/g, ''), [
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat,
            PhoneValidator.phoneFormat,
          ]),
          country: new FormControl(this.countries.find((val) => val.countryId === this.countryID)),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.maps.repContact.email = !this.lssForm['repContact'].valueControl.get('email').valid;
    } else {
      this.lssForm['repContact'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          firstName: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          lastName: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          title: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
          email: new FormControl(null, EmailValidator.mailFormat),
          phone1: new FormControl(null, [OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat, PhoneValidator.phoneFormat]),
          country: new FormControl(),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    if (!isNullOrUndefined(this.surveyDataInput.govIdOrEin)) {
      this.lssForm['govIdOrEin'] = {
        lastUpdated: new Date(this.surveyDataInput.govIdOrEin.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.govIdOrEin.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.govIdOrEin.updatedBySRAdmin,

        valueControl: new FormControl(
          this.surveyDataInput.govIdOrEin.value,
          OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
        ),
        markAsUpdatedControl: new FormControl(false),
      };
    } else {
      this.lssForm['govIdOrEin'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    if (!isNullOrUndefined(this.surveyDataInput.govIdOrEin2)) {
      this.lssForm['govIdOrEin2'] = {
        lastUpdated: new Date(this.surveyDataInput.govIdOrEin2.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.govIdOrEin2.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.govIdOrEin2.updatedBySRAdmin,

        valueControl: new FormControl(
          this.surveyDataInput.govIdOrEin2.value,
          OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
        ),
        markAsUpdatedControl: new FormControl(false),
      };
    } else {
      this.lssForm['govIdOrEin2'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,
        valueControl: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /*************
     * Step 2
     */

    if (!isNullOrUndefined(this.surveyDataInput.serviceList)) {
      // build table of existing values
      const existingServicesProvidedValues = {};

      for (const entry of this.surveyDataInput.serviceList.value) {
        existingServicesProvidedValues[entry.serviceProvided.serviceId] = entry;
      }

      // construct base FormElement
      this.lssForm['serviceList'] = {
        lastUpdated: new Date(this.surveyDataInput.serviceList.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.serviceList.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.serviceList.updatedBySRAdmin,

        valueControl: new FormArray([], ServicesProvidedValidator.proportionTotal),
        markAsUpdatedControl: new FormControl(false),
      };

      for (const serviceProvided of this.servicesProvided) {
        (<FormArray>this.lssForm['serviceList'].valueControl).push(
          new FormGroup({
            serviceProvided: new FormGroup({
              name: new FormControl({ value: serviceProvided.name, disabled: true }),
              serviceId: new FormControl({ value: serviceProvided.serviceId, disabled: true }),
              fileURI: new FormControl({
                value: !isNullOrUndefined(serviceProvided.fileURI)
                  ? `${AppSettings.API_SERVER}${serviceProvided.fileURI}`
                  : '',
                disabled: true,
              }),
            }),

            directProportion: new FormControl(
              serviceProvided.serviceId in existingServicesProvidedValues
                ? existingServicesProvidedValues[serviceProvided.serviceId].directProportion
                : null,
              Validators.pattern(AppSettings.ONE_TO_ONE_HUNDRED_WHOLE_NUMBER_REGEX_PATTERN)
            ),
            indirectProportion: new FormControl(
              serviceProvided.serviceId in existingServicesProvidedValues
                ? existingServicesProvidedValues[serviceProvided.serviceId].indirectProportion
                : null,
              Validators.pattern(AppSettings.ONE_TO_ONE_HUNDRED_WHOLE_NUMBER_REGEX_PATTERN)
            ),
          })
        );
      }
      this.invalidOnInit.arrays.serviceListDirect = (<FormArray>this.lssForm['serviceList'].valueControl).controls.map(
        (control: FormGroup) => !control.get('directProportion').valid
      );
      this.invalidOnInit.arrays.serviceListIndirect = (<FormArray>(
        this.lssForm['serviceList'].valueControl
      )).controls.map((control: FormGroup) => !control.get('indirectProportion').valid);
    } else {
      this.lssForm['serviceList'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormArray([], ServicesProvidedValidator.proportionTotal),
        markAsUpdatedControl: new FormControl(false),
      };

      for (const serviceProvided of this.servicesProvided) {
        (<FormArray>this.lssForm['serviceList'].valueControl).push(
          new FormGroup({
            serviceProvided: new FormGroup({
              name: new FormControl({ value: serviceProvided.name, disabled: true }),
              serviceId: new FormControl({ value: serviceProvided.serviceId, disabled: false }),
              fileURI: new FormControl({
                value: !isNullOrUndefined(serviceProvided.file) ? serviceProvided.file.uri : '',
                disabled: true,
              }),
            }),

            directProportion: new FormControl(
              null,
              Validators.pattern(AppSettings.ONE_TO_ONE_HUNDRED_WHOLE_NUMBER_REGEX_PATTERN)
            ),
            indirectProportion: new FormControl(
              null,
              Validators.pattern(AppSettings.ONE_TO_ONE_HUNDRED_WHOLE_NUMBER_REGEX_PATTERN)
            ),
          })
        );
      }
    }

    if (!isNullOrUndefined(this.surveyDataInput.reliefTypeList)) {
      // build table of existing values
      const existingReliefTypeProvidedValues = {};

      for (const entry of this.surveyDataInput.reliefTypeList.value) {
        existingReliefTypeProvidedValues[entry.typeId] = entry;
      }

      // construct base FormElement
      this.lssForm['reliefTypeList'] = {
        lastUpdated: new Date(this.surveyDataInput.reliefTypeList.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.reliefTypeList.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.reliefTypeList.updatedBySRAdmin,

        valueControl: new FormArray([]),
        markAsUpdatedControl: new FormControl(false),
      };

      for (const reliefType of this.reliefRecoveryItems) {
        (<FormArray>this.lssForm['reliefTypeList'].valueControl).push(
          new FormGroup({
            name: new FormControl({ value: reliefType.name, disabled: true }),
            typeId: new FormControl({ value: reliefType.id, disabled: false }),

            itemCount: new FormControl(
              reliefType.id in existingReliefTypeProvidedValues
                ? existingReliefTypeProvidedValues[reliefType.id].itemCount
                : null,
              Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)
            ),
          })
        );
      }
      this.invalidOnInit.arrays.reliefTypeList = this.lssForm['reliefTypeList'].valueControl['controls'].map(
        (control: FormControl) => !control.get('itemCount').valid
      );
    } else {
      this.lssForm['reliefTypeList'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormArray([]),
        markAsUpdatedControl: new FormControl(false),
      };

      for (const reliefType of this.reliefRecoveryItems) {
        (<FormArray>this.lssForm['reliefTypeList'].valueControl).push(
          new FormGroup({
            name: new FormControl({ value: reliefType.name, disabled: true }),
            typeId: new FormControl({ value: reliefType.id, disabled: false }),

            itemCount: new FormControl(null, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)),
          })
        );
      }
    }

    /* accept donations of imported goods */

    if (!isNullOrUndefined(this.surveyDataInput.acceptDonationsOfImportedGoods)) {
      this.lssForm['acceptDonationsOfImportedGoods'] = {
        lastUpdated: new Date(this.surveyDataInput.acceptDonationsOfImportedGoods.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.acceptDonationsOfImportedGoods.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.acceptDonationsOfImportedGoods.updatedBySRAdmin,

        valueControl: new FormGroup({
          acceptImportedGoods: new FormControl(
            this.surveyDataInput.acceptDonationsOfImportedGoods.value.acceptImportedGoods
          ),
          hasPreviouslyAcceptedImportedGoods: new FormControl(
            this.surveyDataInput.acceptDonationsOfImportedGoods.value.hasPreviouslyAcceptedImportedGoods
          ),
          importerOfRecord: new FormControl(
            this.surveyDataInput.acceptDonationsOfImportedGoods.value.importerOfRecord,
            OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat
          ),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    } else {
      this.lssForm['acceptDonationsOfImportedGoods'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          acceptImportedGoods: new FormControl(),
          hasPreviouslyAcceptedImportedGoods: new FormControl(),
          importerOfRecord: new FormControl(null, OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /* disaster stages */

    if (!isNullOrUndefined(this.surveyDataInput.disasterStageOfWork)) {
      this.lssForm['disasterStageOfWork'] = {
        lastUpdated: new Date(this.surveyDataInput.disasterStageOfWork.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.disasterStageOfWork.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.disasterStageOfWork.updatedBySRAdmin,

        valueControl: new FormGroup({
          preparedStage: new FormControl(this.surveyDataInput.disasterStageOfWork.value.preparedStage),
          reliefStage: new FormControl(this.surveyDataInput.disasterStageOfWork.value.reliefStage),
          recoveryStage: new FormControl(this.surveyDataInput.disasterStageOfWork.value.recoveryStage),
          responseStage: new FormControl(this.surveyDataInput.disasterStageOfWork.value.responseStage),
          mitigationStage: new FormControl(this.surveyDataInput.disasterStageOfWork.value.mitigationStage),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    } else {
      this.lssForm['disasterStageOfWork'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          preparedStage: new FormControl(),
          reliefStage: new FormControl(),
          recoveryStage: new FormControl(),
          responseStage: new FormControl(),
          mitigationStage: new FormControl(),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /* disaster response or emergency plan */

    if (!isNullOrUndefined(this.surveyDataInput.disasterResponsePlan)) {
      this.lssForm['disasterResponsePlan'] = {
        lastUpdated: new Date(this.surveyDataInput.disasterResponsePlan.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.disasterResponsePlan.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.disasterResponsePlan.updatedBySRAdmin,

        valueControl: new FormGroup({
          hasValue: new FormControl(this.surveyDataInput.disasterResponsePlan.value.hasValue),
          fileId: new FormControl(this.surveyDataInput.disasterResponsePlan.value.fileId),
          webLink: new FormControl(
            this.surveyDataInput.disasterResponsePlan.value.webLink,
            Validators.pattern(AppSettings.URL_REGEX_PATTERN)
          ),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.maps.disasterResponsePlan.webLink = !this.lssForm['disasterResponsePlan'].valueControl.get(
        'webLink'
      ).valid;
    } else {
      this.lssForm['disasterResponsePlan'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          hasValue: new FormControl(),
          fileId: new FormControl(),
          webLink: new FormControl(null, Validators.pattern(AppSettings.URL_REGEX_PATTERN)),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /*************
     * Step 3
     */

    const staffTypes: string[] = ['localStaff', 'nonLocalStaff', 'volunteerStaff'];

    for (const staffType of staffTypes) {
      if (!isNullOrUndefined(this.surveyDataInput[staffType])) {
        const staffCounts: string[] = ['mgmtStaffCount', 'professionalStaffCount', 'supportStaffCount'];

        this.lssForm[staffType] = {
          lastUpdated: new Date(this.surveyDataInput[staffType].lastUpdatedDate),
          lastUpdatedUser: this.surveyDataInput[staffType].lastUpdatedUser,
          updatedBySRAdmin: this.surveyDataInput[staffType].updatedBySRAdmin,

          valueControl: new FormGroup({
            mgmtStaffCount: new FormControl(
              this.surveyDataInput[staffType].value.mgmtStaffCount,
              Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)
            ),
            professionalStaffCount: new FormControl(
              this.surveyDataInput[staffType].value.professionalStaffCount,
              Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)
            ),
            supportStaffCount: new FormControl(
              this.surveyDataInput[staffType].value.supportStaffCount,
              Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)
            ),
          }),
          markAsUpdatedControl: new FormControl(false),
        };
        for (const staffCount of staffCounts) {
          this.invalidOnInit.maps[staffType][staffCount] = !this.lssForm[staffType].valueControl.get(staffCount).valid;
        }
      } else {
        this.lssForm[staffType] = {
          lastUpdated: null,
          lastUpdatedUser: null,
          updatedBySRAdmin: null,

          valueControl: new FormGroup({
            mgmtStaffCount: new FormControl(null, Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)),
            professionalStaffCount: new FormControl(
              null,
              Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)
            ),
            supportStaffCount: new FormControl(
              null,
              Validators.pattern(AppSettings.POSITIVE_WHOLE_NUMBER_REGEX_PATTERN)
            ),
          }),
          markAsUpdatedControl: new FormControl(false),
        };
      }
    }

    /*************
     * Step 4
     */

    const simpleStep4Questions = {
      fiscalYearEnding: [BasicDateValidator.dateFormat, BasicDateValidator.fiscalYearDate],
    };

    for (const key in simpleStep4Questions) {
      if (simpleStep4Questions.hasOwnProperty(key)) {
        this.lssForm[key] = responseToFormElement(this.surveyDataInput, key, simpleStep4Questions[key]);
        this.setInitialErrorVisibility(key, this.lssForm);
      }
    }

    if (!isNullOrUndefined(this.surveyDataInput.overallBudget)) {
      this.lssForm['overallBudget'] = {
        lastUpdated: new Date(this.surveyDataInput.overallBudget.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.overallBudget.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.overallBudget.updatedBySRAdmin,

        valueControl: new FormGroup({
          currency: new FormControl(
            this.surveyDataInput.overallBudget.value.currency
              ? this.surveyDataInput.overallBudget.value.currency
              : 'USD'
          ),
          amount: new FormControl(
            this.surveyDataInput.overallBudget.value.amount,
            Validators.pattern(AppSettings.US_CURRENCY_REGEX_PATTERN)
          ),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.singles.overallBudget = !this.lssForm['overallBudget'].valueControl.valid;
    } else {
      this.lssForm['overallBudget'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          currency: new FormControl('USD'),
          amount: new FormControl(null, Validators.pattern(AppSettings.US_CURRENCY_REGEX_PATTERN)),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    if (!isNullOrUndefined(this.surveyDataInput.annualReport)) {
      this.lssForm['annualReport'] = {
        lastUpdated: new Date(this.surveyDataInput.annualReport.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.annualReport.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.annualReport.updatedBySRAdmin,

        valueControl: new FormGroup({
          hasValue: new FormControl(this.surveyDataInput.annualReport.value.hasValue),
          fileId: new FormControl(this.surveyDataInput.annualReport.value.fileId),
          webLink: new FormControl(
            this.surveyDataInput.annualReport.value.webLink,
            Validators.pattern(AppSettings.URL_REGEX_PATTERN)
          ),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.maps.annualReport.webLink = !this.lssForm['annualReport'].valueControl.get('webLink').valid;
    } else {
      this.lssForm['annualReport'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          hasValue: new FormControl(),
          fileId: new FormControl(),
          webLink: new FormControl(null, Validators.pattern(AppSettings.URL_REGEX_PATTERN)),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /*************
     * Step 5
     */

    if (!isNullOrUndefined(this.surveyDataInput.monitorProtocol)) {
      this.lssForm['monitorProtocol'] = {
        lastUpdated: new Date(this.surveyDataInput.monitorProtocol.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.monitorProtocol.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.monitorProtocol.updatedBySRAdmin,

        valueControl: new FormGroup({
          hasValue: new FormControl(this.surveyDataInput.monitorProtocol.value.hasValue),
          fileId: new FormControl(this.surveyDataInput.monitorProtocol.value.fileId),
          webLink: new FormControl(
            this.surveyDataInput.monitorProtocol.value.webLink,
            Validators.pattern(AppSettings.URL_REGEX_PATTERN)
          ),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.maps.monitorProtocol.webLink = !this.lssForm['monitorProtocol'].valueControl.get('webLink')
        .valid;
    } else {
      this.lssForm['monitorProtocol'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          hasValue: new FormControl(),
          fileId: new FormControl(),
          webLink: new FormControl(null, Validators.pattern(AppSettings.URL_REGEX_PATTERN)),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    if (!isNullOrUndefined(this.surveyDataInput.engagement)) {
      let engagementListControls: FormControl[];

      if (
        this.surveyDataInput.engagement.value.hasEngagement &&
        this.surveyDataInput.engagement.value.engagementList &&
        this.surveyDataInput.engagement.value.engagementList.length
      ) {
        engagementListControls = this.surveyDataInput.engagement.value.engagementList.map(
          (engagement) => new FormControl({ value: engagement, disabled: false }, partnerFormatValidators)
        );
      } else {
        engagementListControls = [new FormControl(null, partnerFormatValidators)];
      }

      this.lssForm['engagement'] = {
        lastUpdated: new Date(this.surveyDataInput.engagement.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.engagement.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.engagement.updatedBySRAdmin,

        valueControl: new FormGroup({
          hasEngagement: new FormControl(this.surveyDataInput.engagement.value.hasEngagement),
          engagementList: new FormArray(engagementListControls),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.arrays.engagementList = this.lssForm['engagement'].valueControl
        .get('engagementList')
        ['controls'].map((control) => !control.valid);
    } else {
      this.lssForm['engagement'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          hasEngagement: new FormControl(),
          engagementList: new FormArray([new FormControl(null, partnerFormatValidators)]),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    if (!isNullOrUndefined(this.surveyDataInput.membershipList)) {
      this.lssForm['membershipList'] = {
        lastUpdated: new Date(this.surveyDataInput.membershipList.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.membershipList.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.membershipList.updatedBySRAdmin,

        valueControl: this.fb.array([]),
        markAsUpdatedControl: this.fb.control(false),
      };
      this.surveyDataInput.membershipList.value.map((list) => {
        (<FormArray>this.lssForm['membershipList'].valueControl).push(
          this.fb.group({
            membershipList: [{ value: list, disabled: false }, membershipFormatValidators],
          })
        );
      });
      this.invalidOnInit.arrays.membershipList = (<FormArray>this.lssForm['membershipList'].valueControl).controls.map(
        (control: FormControl) => !control.valid
      );
    } else {
      this.lssForm['membershipList'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: this.fb.array([
          this.fb.group({
            membershipList: [null, membershipFormatValidators],
          }),
        ]),
        markAsUpdatedControl: this.fb.control(false),
      };
    }

    // Vetting Agencies
    if (!isNullOrUndefined(this.surveyDataInput.vettingAgencies)) {
      this.lssForm['vettingAgencies'] = {
        lastUpdated: new Date(this.surveyDataInput.vettingAgencies[0].lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.vettingAgencies[0].lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.vettingAgencies[0].updatedBySRAdmin,

        valueControl: this.fb.array([]),
        markAsUpdatedControl: new FormControl(false),
      };

      for (const vettingAgency of Object.assign(this.surveyDataInput.vettingAgencies)) {
        (<FormArray>this.lssForm['vettingAgencies'].valueControl).push(
          this.fb.group(
            {
              agencyId: new FormControl(vettingAgency.value.agencyId),
              agencyName: new FormControl(vettingAgency.value.agencyName),
              startDate: vettingAgency.value.startDate
                ? formatDate(new Date(vettingAgency.value.startDate).toISOString().slice(0, -1), 'yyyy-MM-dd', 'en_US')
                : null,
              expirationDate: vettingAgency.value.expirationDate
                ? formatDate(
                    new Date(vettingAgency.value.expirationDate).toISOString().slice(0, -1),
                    'yyyy-MM-dd',
                    'en_US'
                  )
                : null,
              vettingAgencyLetterDtos: this.fb.array(
                vettingAgency.value.vettingAgencyLetterDtos.map((fileId) => new FormControl(fileId))
              ),
            },
            {
              validators: [BasicDateValidator.startExpiration],
            }
          )
        );
        this.onVettingAgencyChecked(vettingAgency);
      }
    } else {
      this.lssForm['vettingAgencies'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: this.fb.array([]),
        markAsUpdatedControl: new FormControl(false),
      };
    }
    /*************
     * Step 6
     */

    const simpleStep6Questions = [
      'contractManagement',
      'preventCorruption',
      'acceptGovFund',
      'disabilityPhysicalAccess',
      'disabilityProgramAccess',
      'disabilityCommunicationAccess',
    ];

    for (const key of simpleStep6Questions) {
      this.lssForm[key] = responseToFormElement(this.surveyDataInput, key, [
        OnlyWhiteSpaceValidator.onlyWhiteSpaceFormat,
      ]);
      this.setInitialErrorVisibility(key, this.lssForm);
    }

    /* child protection emergency plan */

    if (!isNullOrUndefined(this.surveyDataInput.childProtectionEmergencyPlan)) {
      this.lssForm['childProtectionEmergencyPlan'] = {
        lastUpdated: new Date(this.surveyDataInput.childProtectionEmergencyPlan.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.childProtectionEmergencyPlan.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.childProtectionEmergencyPlan.updatedBySRAdmin,

        valueControl: new FormGroup({
          hasValue: new FormControl(this.surveyDataInput.childProtectionEmergencyPlan.value.hasValue),
          fileId: new FormControl(this.surveyDataInput.childProtectionEmergencyPlan.value.fileId),
          webLink: new FormControl(
            this.surveyDataInput.childProtectionEmergencyPlan.value.webLink,
            Validators.pattern(AppSettings.URL_REGEX_PATTERN)
          ),
          comment: new FormControl(this.surveyDataInput.childProtectionEmergencyPlan.value.comment),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
      this.invalidOnInit.maps.childProtectionEmergencyPlan.webLink = !this.lssForm[
        'childProtectionEmergencyPlan'
      ].valueControl.get('webLink').valid;
    } else {
      this.lssForm['childProtectionEmergencyPlan'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormGroup({
          hasValue: new FormControl(),
          fileId: new FormControl(),
          webLink: new FormControl(null, Validators.pattern(AppSettings.URL_REGEX_PATTERN)),
          comment: new FormControl(),
        }),
        markAsUpdatedControl: new FormControl(false),
      };
    }

    /* Step 7 */

    this.lssForm['operatingInEntireCountry'] = {
      lastUpdated: new Date(),
      lastUpdatedUser: null,
      updatedBySRAdmin: null,
      valueControl: new FormControl(this.surveyDataInput.operatingInEntireCountry),
      markAsUpdatedControl: new FormControl(false),
    };
    if (!isNullOrUndefined(this.surveyDataInput.operatingLocationList)) {
      this.lssForm['operatingLocationList'] = {
        lastUpdated: new Date(this.surveyDataInput.operatingLocationList.lastUpdatedDate),
        lastUpdatedUser: this.surveyDataInput.operatingLocationList.lastUpdatedUser,
        updatedBySRAdmin: this.surveyDataInput.operatingLocationList.updatedBySRAdmin,

        valueControl: new FormArray([]),
        markAsUpdatedControl: new FormControl(false),
      };

      let index = 0;
      for (const operatingLocation of this.surveyDataInput.operatingLocationList.value) {
        (<FormArray>this.lssForm['operatingLocationList'].valueControl).push(
          new FormGroup({
            geoLatitude: new FormControl(operatingLocation.geoLatitude),
            geoLongitude: new FormControl(operatingLocation.geoLongitude),
            radius: new FormControl(operatingLocation.radius, Validators.required),
            label: new FormControl(String.fromCharCode(65 + index)),
          })
        );
        index += 1;
      }
    } else {
      this.lssForm['operatingLocationList'] = {
        lastUpdated: null,
        lastUpdatedUser: null,
        updatedBySRAdmin: null,

        valueControl: new FormArray([]),
        markAsUpdatedControl: new FormControl(false),
      };
    }
    this.step8TabClicked();
  }

  setMapCountry() {
    this.mapsApiLoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const location = this.surveyDataInput.country.name;
      geocoder.geocode({ address: location }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.mapLocationLat = results[0].geometry.location.lat();
          this.mapLocationLng = results[0].geometry.location.lng();
        } else {
          alert('Could not find location: ' + location);
        }
      });
    });
  }

  mapClicked($event: any): void {
    this.mapLocationLat = $event.coords.lat;
    this.mapLocationLng = $event.coords.lng;
    const length = (<FormArray>this.lssForm['operatingLocationList'].valueControl).length;
    (<FormArray>this.lssForm['operatingLocationList'].valueControl).push(
      new FormGroup({
        geoLatitude: new FormControl($event.coords.lat),
        geoLongitude: new FormControl($event.coords.lng),
        radius: new FormControl(25, Validators.required),
        label: new FormControl(String.fromCharCode(65 + length)),
      })
    );
  }

  markerClicked($event: AgmMarker) {
    Promise.all(this.markerWindows && this.markerWindows.map((window) => window.close())).then(
      () => $event && $event.infoWindow.forEach((window) => window.open())
    );
  }

  onMarkerDelete(index: number) {
    (<FormArray>this.lssForm['operatingLocationList'].valueControl).removeAt(index);
    this.reassignMarkerLabels();
  }

  reassignMarkerLabels(): void {
    let index = 0;

    for (const marker of (<FormArray>this.lssForm['operatingLocationList'].valueControl).controls) {
      marker.patchValue({
        label: String.fromCharCode(65 + index),
      });
      index += 1;
    }
  }

  getServiceListForSubmission(): ServiceFormData[] {
    if (isNullOrUndefined(this.lssForm['serviceList'].valueControl)) {
      return null;
    }

    const serviceListControls = (<FormArray>this.lssForm['serviceList'].valueControl).controls.filter((serviceItem) => {
      return (
        isInputFilledNumber(serviceItem.get('directProportion').value) ||
        isInputFilledNumber(serviceItem.get('indirectProportion').value)
      );
    });

    const serviceList: ServiceFormData[] = serviceListControls.map((serviceItem) => ({
      directProportion: getNumberFromInput(serviceItem.get('directProportion').value),
      indirectProportion: getNumberFromInput(serviceItem.get('indirectProportion').value),
      serviceProvided: { serviceId: serviceItem.get('serviceProvided').get('serviceId').value },
    }));

    return serviceList;
  }

  calculateTotalPercentServicesProvided(): number {
    if (isNullOrUndefined(this.lssForm['serviceList'].valueControl)) {
      return 0;
    }

    let total = 0;
    let indirect: number;
    let direct: number;

    for (const control of (<FormArray>this.lssForm['serviceList'].valueControl).controls) {
      direct = parseInt(control.get('directProportion').value, 10);

      if (!isNaN(direct)) {
        total += direct;
      }

      indirect = parseInt(control.get('indirectProportion').value, 10);

      if (!isNaN(indirect)) {
        total += indirect;
      }
    }

    return total;
  }

  getReliefTypeListForSubmission(): ReliefTypeFormData[] {
    if (isNullOrUndefined(this.lssForm['reliefTypeList'].valueControl)) {
      return null;
    }

    const itemListControls = (<FormArray>this.lssForm['reliefTypeList'].valueControl).controls.filter((reliefItem) => {
      return isInputFilledNumber(reliefItem.get('itemCount').value);
    });

    const itemList: ReliefTypeFormData[] = itemListControls.map((serviceItem) => ({
      itemCount: getNumberFromInput(serviceItem.get('itemCount').value),
      typeId: serviceItem.get('typeId').value,
    }));

    return itemList;
  }

  getVettingAgencyListForSubmission(): VettingAgencyFormData[] {
    if (isNullOrUndefined(this.lssForm['vettingAgencies'].valueControl)) {
      return null;
    }
    const vettingAgencies = (<FormArray>this.lssForm['vettingAgencies'].valueControl).controls.filter((agency) => {
      return !(agency.get('agencyName').value === null || agency.get('agencyName').value === '');
    });
    return vettingAgencies.map((agency) => agency.value);
  }

  canSaveAndExit(): boolean {
    /**
     * requirements for saving LSS include:
     * - services provided equals 100%
     * *and* all the validation should pass
     */
    let allFormsValid = true;

    for (const f in this.lssForm) {
      if (this.lssForm[f].valueControl && this.lssForm[f].valueControl.value) {
        if (this.lssForm[f].valueControl.value instanceof Array) {
          for (const n in this.lssForm[f].valueControl.value) {
            if (this.lssForm[f].valueControl.get(n) && this.lssForm[f].valueControl.get(n).invalid) {
              allFormsValid = allFormsValid && this.lssForm[f].valueControl.get(n).valid;
            }
          }
        }
        allFormsValid = allFormsValid && this.lssForm[f].valueControl.valid;
      }

      /* break if any invalid form is found */
      if (!allFormsValid) {
        break;
      }
    }

    const totalPercentServicesProvided = this.calculateTotalPercentServicesProvided();

    return !this.isSubmitting && allFormsValid;
  }

  onSaveAndExit(currentModal: EventEmitter<string | MaterializeAction>): void {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    const survey: LocationSpecificSurveyOutput = this.getLSSSurveyInputs();
    // Nullify input responses which are empty or made up of whitespaces
    for (const formStep in survey) {
      if (survey[formStep] !== null && typeof survey[formStep].value === 'object') {
        // Handle nested object responses
        for (const formField in survey[formStep].value) {
          // Values that are strings have their whitespaces removed and checked if they are empty
          if (
            typeof survey[formStep].value[formField] === 'string' &&
            survey[formStep].value[formField].replace(/\s/g, '') === ''
          ) {
            // Setting the field value to null makes it a "no response", instead of a blank response
            survey[formStep].value[formField] = null;
          }
        }
      } else if (
        survey[formStep] !== null &&
        typeof survey[formStep].value === 'string' &&
        survey[formStep].value.replace(/\s/g, '') === ''
      ) {
        // Handle string responses
        survey[formStep].value = null;
      }
    }

    this.surveyService
      .submitSurvey(this.surveyDataInput.organizationId, this.surveyDataInput.country.countryId, survey)
      .subscribe(
        (success) => {
          this.notifySaveSuccess('UNIVERSAL-SURVEY_LSS');
          currentModal.emit({ action: 'modal', params: ['close'] });
          this.surveyDataInput = success['responseData']['survey'];
          this.isSubmitting = false;
          // this causes the survey to be refreshed - `surveyInterface` is a set() method defined above
          this.surveyDataInput = null;
          setTimeout(() => (this.surveyInterface = this.lssSurveyInputInterface), 0);
          this.hideOverlay();
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationsService, 'UNIVERSAL-SURVEY_LSS');

          this.isSubmitting = false;
        }
      );
  }

  getLSSSurveyInputs(): LocationSpecificSurveyOutput {
    const serviceListForSubmission: ServiceFormData[] = this.getServiceListForSubmission();
    const reliefTypeListForSubmission = this.getReliefTypeListForSubmission();
    const vettingAgencyListForSubmission = this.getVettingAgencyListForSubmission();

    const operatingLocations = this.lssForm['operatingLocationList'].valueControl.value
      .filter((location) => location.geoLatitude && location.geoLongitude)
      .map((operatingLocation) => {
        return {
          geoLatitude: operatingLocation.geoLatitude,
          geoLongitude: operatingLocation.geoLongitude,
          radius: operatingLocation.radius,
        };
      });

    const membershipList = Object.values(this.lssForm['membershipList'].valueControl.value)
      .filter((item) => !!item['membershipList'])
      .map((list) => {
        return list['membershipList'];
      });

    let engagementList: string[] = [];

    if (this.lssForm['engagement'].valueControl.get('hasEngagement').value) {
      engagementList = Object.values(
        this.lssForm['engagement'].valueControl.get('engagementList').value.filter((engagement: string) => !!engagement)
      );
    }

    // todo fixme typing
    const survey: LocationSpecificSurveyOutput = {
      /* meta data */
      previousSurveyId: this.surveyDataInput.surveySubmissionId,
      /* step 1 */
      officeAddress: {
        markAsUpdated: this.lssForm['officeAddress'].markAsUpdatedControl.value,
        value: (<FormGroup>this.lssForm['officeAddress'].valueControl).getRawValue(),
      },
      activityHistory: {
        markAsUpdated: this.lssForm['activityHistory'].markAsUpdatedControl.value,
        value: this.lssForm['activityHistory'].valueControl.value,
      },
      activityHistoryVideoUrl: {
        markAsUpdated: this.lssForm['activityHistoryVideoUrl'].markAsUpdatedControl.value,
        value: this.lssForm['activityHistoryVideoUrl'].valueControl.value,
      },
      currentProgramVideoUrl: {
        markAsUpdated: this.lssForm['currentProgramVideoUrl'].markAsUpdatedControl.value,
        value: this.lssForm['currentProgramVideoUrl'].valueControl.value,
      },
      startOpDate: {
        markAsUpdated: this.lssForm['startOpDate'].markAsUpdatedControl.value,
        value: this.lssForm['startOpDate'].valueControl.value,
      },
      repContact: {
        markAsUpdated: this.lssForm['repContact'].markAsUpdatedControl.value,
        value: (<FormGroup>this.lssForm['repContact'].valueControl).getRawValue(),
      },
      govIdOrEin: {
        markAsUpdated: this.lssForm['govIdOrEin'].markAsUpdatedControl.value,
        value: this.lssForm['govIdOrEin'].valueControl.value,
      },
      govIdOrEin2: {
        markAsUpdated: this.lssForm['govIdOrEin2'].markAsUpdatedControl.value,
        value: this.lssForm['govIdOrEin2'].valueControl.value,
      },

      /* step 2 */
      serviceList: {
        markAsUpdated: this.lssForm['serviceList'].markAsUpdatedControl.value,
        value: <[{ directProportion: number; indirectProportion: number; serviceProvided: { serviceId: number } }]>(
          serviceListForSubmission
        ),
      },
      reliefTypeList: {
        markAsUpdated: this.lssForm['reliefTypeList'].markAsUpdatedControl.value,
        value: <[{ itemCount: number; typeId: number }]>reliefTypeListForSubmission,
      },
      acceptDonationsOfImportedGoods: {
        markAsUpdated: this.lssForm['acceptDonationsOfImportedGoods'].markAsUpdatedControl.value,
        value: {
          acceptImportedGoods: this.lssForm['acceptDonationsOfImportedGoods'].valueControl.get('acceptImportedGoods')
            .value,
          hasPreviouslyAcceptedImportedGoods: this.lssForm['acceptDonationsOfImportedGoods'].valueControl.get(
            'hasPreviouslyAcceptedImportedGoods'
          ).value,
          importerOfRecord: this.lssForm['acceptDonationsOfImportedGoods'].valueControl.get('importerOfRecord').value,
        },
      },
      disasterStageOfWork: {
        markAsUpdated: this.lssForm['disasterStageOfWork'].markAsUpdatedControl.value,
        value: (<FormGroup>this.lssForm['disasterStageOfWork'].valueControl).getRawValue(),
      },
      disasterResponsePlan: {
        markAsUpdated: this.lssForm['disasterResponsePlan'].markAsUpdatedControl.value,
        value: {
          webLink: this.lssForm['disasterResponsePlan'].valueControl.get('webLink').value,
          fileId: this.lssForm['disasterResponsePlan'].valueControl.get('fileId').value,
          hasValue: this.lssForm['disasterResponsePlan'].valueControl.get('hasValue').value,
        },
      },

      /* step 3 */
      localStaff: {
        markAsUpdated: this.lssForm['localStaff'].markAsUpdatedControl.value,
        value: {
          mgmtStaffCount: getNumberFromInput(this.lssForm['localStaff'].valueControl.get('mgmtStaffCount').value),
          professionalStaffCount: getNumberFromInput(
            this.lssForm['localStaff'].valueControl.get('professionalStaffCount').value
          ),
          supportStaffCount: getNumberFromInput(this.lssForm['localStaff'].valueControl.get('supportStaffCount').value),
        },
      },
      nonLocalStaff: {
        markAsUpdated: this.lssForm['nonLocalStaff'].markAsUpdatedControl.value,
        value: {
          mgmtStaffCount: getNumberFromInput(this.lssForm['nonLocalStaff'].valueControl.get('mgmtStaffCount').value),
          professionalStaffCount: getNumberFromInput(
            this.lssForm['nonLocalStaff'].valueControl.get('professionalStaffCount').value
          ),
          supportStaffCount: getNumberFromInput(
            this.lssForm['nonLocalStaff'].valueControl.get('supportStaffCount').value
          ),
        },
      },
      volunteerStaff: {
        markAsUpdated: this.lssForm['volunteerStaff'].markAsUpdatedControl.value,
        value: {
          mgmtStaffCount: getNumberFromInput(this.lssForm['volunteerStaff'].valueControl.get('mgmtStaffCount').value),
          professionalStaffCount: getNumberFromInput(
            this.lssForm['volunteerStaff'].valueControl.get('professionalStaffCount').value
          ),
          supportStaffCount: getNumberFromInput(
            this.lssForm['volunteerStaff'].valueControl.get('supportStaffCount').value
          ),
        },
      },

      /* step 4 */
      fiscalYearEnding: {
        markAsUpdated: this.lssForm['fiscalYearEnding'].markAsUpdatedControl.value,
        value: this.lssForm['fiscalYearEnding'].valueControl.value,
      },
      overallBudget: {
        markAsUpdated: this.lssForm['overallBudget'].markAsUpdatedControl.value,
        value: {
          currency: this.lssForm['overallBudget'].valueControl.value.currency
            ? this.lssForm['overallBudget'].valueControl.value.currency
            : 'USD',
          amount: getFloatFromInput(this.lssForm['overallBudget'].valueControl.value.amount),
        },
      },
      annualReport: {
        markAsUpdated: this.lssForm['annualReport'].markAsUpdatedControl.value,
        value: {
          webLink: this.lssForm['annualReport'].valueControl.get('webLink').value,
          fileId: this.lssForm['annualReport'].valueControl.get('fileId').value,
          hasValue: this.lssForm['annualReport'].valueControl.get('hasValue').value,
        },
      },

      /* step 5 */
      monitorProtocol: {
        markAsUpdated: this.lssForm['monitorProtocol'].markAsUpdatedControl.value,
        value: {
          webLink: this.lssForm['monitorProtocol'].valueControl.get('webLink').value,
          fileId: this.lssForm['monitorProtocol'].valueControl.get('fileId').value,
          hasValue: this.lssForm['monitorProtocol'].valueControl.get('hasValue').value,
        },
      },
      engagement: {
        markAsUpdated: this.lssForm['engagement'].markAsUpdatedControl.value,
        value: {
          engagementList,
          hasEngagement: this.lssForm['engagement'].valueControl.get('hasEngagement').value,
        },
      },
      membershipList: {
        markAsUpdated: this.lssForm['membershipList'].markAsUpdatedControl.value,
        value: <string[]>membershipList,
      },

      vettingAgencies: {
        markAsUpdated: this.lssForm['vettingAgencies'].markAsUpdatedControl.value,
        value: <
          [
            {
              agencyId: number;
              agencyName: string;
              expirationDate: string;
              startDate: string;
              vettingAgencyLetterDtos: [{ fileId: number }];
            }
          ]
        >vettingAgencyListForSubmission,
      },

      /* step 6 */
      contractManagement: {
        markAsUpdated: this.lssForm['contractManagement'].markAsUpdatedControl.value,
        value: this.lssForm['contractManagement'].valueControl.value,
      },
      preventCorruption: {
        markAsUpdated: this.lssForm['preventCorruption'].markAsUpdatedControl.value,
        value: this.lssForm['preventCorruption'].valueControl.value,
      },
      acceptGovFund: {
        markAsUpdated: this.lssForm['acceptGovFund'].markAsUpdatedControl.value,
        value: this.lssForm['acceptGovFund'].valueControl.value,
      },
      disabilityPhysicalAccess: {
        markAsUpdated: this.lssForm['disabilityPhysicalAccess'].markAsUpdatedControl.value,
        value: this.lssForm['disabilityPhysicalAccess'].valueControl.value,
      },
      disabilityProgramAccess: {
        markAsUpdated: this.lssForm['disabilityProgramAccess'].markAsUpdatedControl.value,
        value: this.lssForm['disabilityProgramAccess'].valueControl.value,
      },
      disabilityCommunicationAccess: {
        markAsUpdated: this.lssForm['disabilityCommunicationAccess'].markAsUpdatedControl.value,
        value: this.lssForm['disabilityCommunicationAccess'].valueControl.value,
      },
      childProtectionEmergencyPlan: {
        markAsUpdated: this.lssForm['childProtectionEmergencyPlan'].markAsUpdatedControl.value,
        value: {
          hasValue: this.lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue').value,
          fileId: this.lssForm['childProtectionEmergencyPlan'].valueControl.get('fileId').value,
          webLink: this.lssForm['childProtectionEmergencyPlan'].valueControl.get('webLink').value,
          comment: this.lssForm['childProtectionEmergencyPlan'].valueControl.get('comment').value,
        },
      },

      /* step 7 */
      operatingInEntireCountry: this.lssForm['operatingInEntireCountry'].valueControl.value,

      operatingLocationList: {
        markAsUpdated: this.lssForm['operatingLocationList'].markAsUpdatedControl.value,
        value: <[{ geoLatitude: number; geoLongitude: number; radius: number }]>operatingLocations,
      },
    };

    return survey;
  }

  onFileSelected(event: any, formControl: FormControl): void {
    const targetFile = event.target.files[0];

    if (!(targetFile instanceof File)) {
      return;
    }

    if (targetFile.size > AppSettings.MAXIMUM_DOCUMENT_UPLOAD_SIZE) {
      this.notificationsService.error(
        this.translateService.instant('LocationSpecificSurveysTableComponent-TITLE'),
        this.translateService.instant('LogoUploadComponent-LARGE_FILE_ERROR'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    if (AppSettings.DEFAULT_ALLOWED_DOCUMENT_TYPES.indexOf(targetFile.type) === -1) {
      this.notificationsService.error(
        this.translateService.instant('LocationSpecificSurveysTableComponent-TITLE'),
        this.translateService.instant('LogoUploadComponent-ILLEGAL-FILE-TYPE'),
        AppSettings.NOTIFICATIONS_ERROR_OPTIONS
      );

      return;
    }

    this.blockingLoaderEventEmitter.emit({
      display: true,
      translation_key: 'UNIVERSAL-LOADING_MESSAGE',
    });

    this.documentService.uploadDocument(targetFile).subscribe(
      (success) => {
        this.blockingLoaderEventEmitter.emit({
          display: false,
        });

        formControl.setValue(success.fileId);
      },
      (error) => {
        this.blockingLoaderEventEmitter.emit({
          display: false,
        });

        handleErrors(
          error,
          this.translateService,
          this.notificationsService,
          'LocationSpecificSurveysTableComponent-TITLE'
        );
      }
    );
  }

  onCancel(currentModal: EventEmitter<string | MaterializeAction>): void {
    if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
      currentModal.emit({ action: 'modal', params: ['close'] });
      this.resetFormToInput();
      this.hideOverlay();
    }
  }

  onVettingAgencyChecked(vettingAgency) {
    this.selectedVettingAgencies.forEach((element) => {
      if (element.agencyId === vettingAgency.value.agencyId) {
        if (vettingAgency.value.checkedSelection && isNullOrUndefined(element.checkedSelection)) {
          element.checkedSelection = vettingAgency.value.checkedSelection;
        } else {
          element.checkedSelection = !element.checkedSelection;
        }
        element.vettingAgencyLetterDtos = vettingAgency.value.vettingAgencyLetterDtos;
        if (!isNullOrUndefined(vettingAgency.value.vettingAgencyLetterDtos)) {
          this.getFileName(element);
        }
      }
    });
  }

  onVettingAgencyCheckboxChange(event: any, agencyId: number) {
    const checkArray: FormArray = this.lssForm['vettingAgencies'].valueControl as FormArray;
    const selectedVettingAgencies = {
      value: {
        agencyId,
        agencyName: event.target.value,
        checkedSelection: true,
      },
    };
    if (event.target.checked) {
      if (!isNullOrUndefined(this.surveyDataInput.vettingAgencies)) {
        Object.assign(this.surveyDataInput.vettingAgencies).forEach((item) => {
          if (item.value.agencyName === event.target.value) {
            return;
          }
        });
      }

      checkArray.push(
        this.fb.group(
          {
            agencyId: new FormControl(agencyId),
            agencyName: new FormControl(event.target.value),
            expirationDate: new FormControl(null, [BasicDateValidator.dateFormat]),
            startDate: new FormControl(null, [BasicDateValidator.dateFormat]),
            vettingAgencyLetterDtos: this.fb.array([
              this.fb.group({
                fileId: new FormControl(),
              }),
            ]),
          },
          {
            validators: [BasicDateValidator.startExpiration],
          }
        )
      );
      this.onVettingAgencyChecked(selectedVettingAgencies);
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.get('agencyName').value === event.target.value) {
          checkArray.removeAt(i);
          this.onVettingAgencyChecked(selectedVettingAgencies);
          return;
        }
        i += 1;
      });
    }
  }

  getFileName(vettingAgency): void {
    vettingAgency.vettingAgencyLetterDtos.forEach((element) => {
      if (element.fileId) {
        const fileObservable = this.documentService.getDocumentInfo(element.fileId);
        fileObservable.subscribe((success) => {
          if (success) {
            element.fileName = success.name;
          }
        });
      }
    });
  }
  checkFileId(fileId): boolean {
    let checkFileName = false;
    this.selectedVettingAgencies.forEach((element) => {
      if (element.vettingAgencyLetterDtos) {
        element.vettingAgencyLetterDtos.forEach((doc) => {
          if (doc.fileId === fileId) {
            if (doc.fileName) {
              checkFileName = !checkFileName;
            }
          }
        });
      }
    });
    return checkFileName;
  }
  // Adding letter file
  onAddVettingAgencyLetter(index: number) {
    const checkArray = (<FormArray>this.lssForm['vettingAgencies'].valueControl).at(index);
    (<FormArray>checkArray.get('vettingAgencyLetterDtos')).push(
      this.fb.group({
        fileId: new FormControl(),
      })
    );
    this.onVettingAgencyChecked(checkArray);
  }

  onDeleteVettingAgency(index: number) {
    const checkArray = this.lssForm['vettingAgencies'].valueControl as FormArray;
    const delAgencyId = checkArray.at(index).get('agencyId').value;
    checkArray.removeAt(index);
    this.selectedVettingAgencies.forEach((element) => {
      if (element.agencyId === delAgencyId) {
        element.checkedSelection = !element.checkedSelection;
      }
    });
  }

  onAddEngagement() {
    (<FormArray>this.lssForm['engagement'].valueControl.get('engagementList')).push(
      new FormControl(null, partnerFormatValidators)
    );
  }
  onDeleteEngagement(index: number) {
    (<FormArray>this.lssForm['engagement'].valueControl.get('engagementList')).removeAt(index);
  }

  onAddMembershipList() {
    (<FormArray>this.lssForm['membershipList'].valueControl).push(
      this.fb.group({
        membershipList: ['', membershipFormatValidators],
      })
    );
  }

  onDeleteMembershipList(index: number) {
    (<FormArray>this.lssForm['membershipList'].valueControl).removeAt(index);
  }

  onCloseLSS(): void {
    this.closeLSS.emit(true);
  }

  onDeleteVettingAgencyFile(formGroup, index): void {
    const delFileId = (<FormArray>formGroup.controls['vettingAgencyLetterDtos']).at(index).value.fileId;
    this.selectedVettingAgencies.forEach((element) => {
      if (element.agencyId === formGroup.controls['agencyId'].value) {
        if (element.vettingAgencyLetterDtos) {
          element.vettingAgencyLetterDtos = element.vettingAgencyLetterDtos.filter(
            ({ fileId }) => fileId !== delFileId
          );
        }
      }
    });
    (<FormArray>formGroup.controls['vettingAgencyLetterDtos']).removeAt(index);
  }

  handleDisasterResponsePlanChange(): void {
    this.lssForm['disasterResponsePlan'].valueControl.get('webLink').setValue(null);
    this.lssForm['disasterResponsePlan'].valueControl.get('fileId').setValue(null);
  }

  handleAnnualReportChange(): void {
    this.lssForm['annualReport'].valueControl.get('webLink').setValue(null);
    this.lssForm['annualReport'].valueControl.get('fileId').setValue(null);
  }

  handleMonitorProtocolChange(): void {
    this.lssForm['monitorProtocol'].valueControl.get('webLink').setValue(null);
    this.lssForm['monitorProtocol'].valueControl.get('fileId').setValue(null);
  }

  handleChildProtectionEmergencyPlanChange(): void {
    if (this.lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue').value) {
      this.lssForm['childProtectionEmergencyPlan'].valueControl.get('comment').setValue(null);
    } else if (!this.lssForm['childProtectionEmergencyPlan'].valueControl.get('hasValue').value) {
      this.lssForm['childProtectionEmergencyPlan'].valueControl.get('webLink').setValue(null);
      this.lssForm['childProtectionEmergencyPlan'].valueControl.get('fileId').setValue(null);
    }
  }

  handleAcceptDonationsOfImportedGoodsChange(): void {
    if (!this.lssForm['acceptDonationsOfImportedGoods'].valueControl.get('acceptImportedGoods').value) {
      this.lssForm['acceptDonationsOfImportedGoods'].valueControl
        .get('hasPreviouslyAcceptedImportedGoods')
        .setValue(null);
      this.lssForm['acceptDonationsOfImportedGoods'].valueControl.get('importerOfRecord').setValue(null);
    }
  }

  checkForCountry() {
    const countryId = this.surveyDataInput.country.countryId;
    this.countryListService.isCountryUSA(countryId).subscribe((result) => {
      this.hideAcceptImportedGoodsQuest = result;
      this.hideAcceptImportedGoodsQuest ? (this.step2QuestCount = 4) : (this.step2QuestCount = 5);
    });
  }

  showStep(stepIndex: number, event?: Event) {
    // Prevent page from changing
    if (event) {
      event.preventDefault();
    }
    this.selectStep(stepIndex);
    this.showOverlay();
  }

  selectStep(stepIndex: number) {
    // Pre modal actions
    if (stepIndex === this.MISSING_STEP_INDEX) {
      this.step8TabClicked();
    }

    // Special handling for step 7
    if (stepIndex === MAP_STEP_INDEX) {
      this.openStep7Modal();
    } else {
      // Open modal
      this.modalActions.steps[stepIndex].emit({ action: 'modal', params: ['open'] });
    }
  }

  // fetches the data on selecting modal tab 8
  step8TabClicked() {
    const survey: LocationSpecificSurveyOutput = this.getLSSSurveyInputs();
    this.emptyOutdatedQuestions = [];
    this.surveyService
      .getEmptyOutdatedLSSSurvey(this.surveyDataInput.organizationId, this.surveyDataInput.country.countryId, survey)
      .subscribe((res) => {
        this.surveyDataOutput = <EmptyOutdatedQuestions>res;
        const questionList = Object.entries(this.surveyDataOutput.responseData.question_list);
        const nestedArr = questionList.map((item) =>
          item[1].map(
            (val) =>
              <SurveyNotification>{
                organizationId: this.surveyDataInput.organizationId,
                surveyName: this.surveyDataInput.country.name,
                surveyTypeFull: this.translateService.instant('SurveyNotificationsComponent_LOCATION_SPECIFIC'),
                stepNumber: val.stepNumber,
                questionNumber: val.questionNumber,
                status: val.valueEmpty
                  ? this.translateService.instant('SurveyNotificationsComponent_EMPTY')
                  : this.translateService.instant('SurveyNotificationsComponent_OUTDATED'),
              }
          )
        );
        this.emptyOutdatedQuestions = [].concat.apply([], nestedArr);
      });
  }

  // opens the selected modal and scrolls to the selected question
  public onSelectQuestion(stepNum: number, questionNum: number) {
    if (this.step8ModalActions || this.modalActions.error) {
      this.step8ModalActions.emit({ action: 'modal', params: ['close'] });
      this.modalActions.error.emit({ action: 'modal', params: ['close'] });
    }
    this.scrollToQuestion(stepNum, questionNum);
  }

  public onPrev(currentStepIndex: number, prevStepIndex?: number) {
    if (
      currentStepIndex === -1 &&
      (prevStepIndex === this.MISSING_STEP_INDEX || prevStepIndex === undefined || prevStepIndex === null)
    ) {
      this.step8TabClicked();
    }
    this.openPrevModal(currentStepIndex, prevStepIndex);
  }

  public onNext(currentStepIndex: number) {
    if (currentStepIndex === this.MISSING_STEP_INDEX - 1) {
      this.step8TabClicked();
    }
    this.openNextModal(currentStepIndex);
  }

  // header sort for the modal 8 tab
  setSortedField(newSortField: string) {
    if (newSortField === this.sortField) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortField = newSortField;
      this.sortDescending = true;
    }
  }

  // get list of currency codes
  getCurrencyCodeList() {
    this.surveyService.getCurrencyCodes().subscribe((res: CurrencyCodes) => {
      this.currencyCodeList = res.responseData.currency_list;
      this.currencyCodeList.sort();
    });
  }

  // build the error list
  errorButtonClicked(stepIndex: number): void {
    this.modalActions.steps[stepIndex].emit({ action: 'modal', params: ['close'] });
    this.prevStepIndex = stepIndex;
    this.prevStepModal = this.modalActions.steps[this.prevStepIndex];
    this.lssFormErrors = [];
    for (const f in this.lssForm) {
      const formControl: FormControl = this.lssForm[f].valueControl as FormControl;
      this.lssFormErrors = this.updateErrors(formControl, f, this.lssFormErrors);
    }
    this.showErrList();
    this.modalActions.error.emit({ action: 'modal', params: ['open'] });
  }

  // Get list of errors in the form steps
  showErrList() {
    this.errStepDetails = [];
    this.lssFormErrors.forEach((lssErr) => {
      const errDetail: SurveyErrorCode = this.surveyService.getLSSErrorCodeList().find((err) => err.errCode === lssErr);
      if (errDetail) {
        // This corrects the question count in the error list, in cases of the hidden question in step 2
        if (this.hideAcceptImportedGoodsQuest && errDetail.stepNum === 2) {
          // Copy by value instead of reference
          const updatedErrDetail = { ...errDetail };
          updatedErrDetail.questionCount -= 1;
          if (errDetail.questionNum === 5) {
            updatedErrDetail.questionNum -= 1;
          }
          if (!this.isDuplicateError(updatedErrDetail)) {
            this.errStepDetails.push(updatedErrDetail);
          }
        } else {
          if (!this.isDuplicateError(errDetail)) {
            this.errStepDetails.push(errDetail);
          }
        }
      }
    });
    this.errStepDetails.sort(errSort);
  }

  // Used to check for duplicate step details errors (from multi-part questions) in showErrList()
  isDuplicateError(newError: SurveyErrorCode) {
    return this.errStepDetails.some(
      (error) => newError.stepNum === error.stepNum && newError.questionNum === error.questionNum
    );
  }

  private resetModalActions(): void {
    this.step1ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step2ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step3ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step4ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step5ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step6ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step7ModalActions = new EventEmitter<string | MaterializeAction>();
    this.step8ModalActions = new EventEmitter<string | MaterializeAction>();

    this.modalActions = {
      steps: [
        this.step1ModalActions,
        this.step2ModalActions,
        this.step3ModalActions,
        this.step4ModalActions,
        this.step5ModalActions,
        this.step6ModalActions,
        this.step7ModalActions,
        this.step8ModalActions,
      ],
      error: new EventEmitter<string | MaterializeAction>(),
      processing: false,
    };
  }
}
