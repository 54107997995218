import { Component, EventEmitter, Input } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { OrgLocationScore } from '../../../models/OrganizationScore';

@Component({
  selector: 'score-by-location-modal',
  templateUrl: './score-by-location.component.html',
  styleUrls: ['../score-modal.css', '../../common-search.css'],
})
export class ScoreByLocationComponent {
  sortingActions = new EventEmitter<string | MaterializeAction>();
  private SUBSCRIPTIONS: Subscription;

  public score: OrgLocationScore;
  public totalPoints;

  @Input()
  public set modalActions(eventEmitter: EventEmitter<OrgLocationScore>) {
    if (isNullOrUndefined(eventEmitter)) {
      return;
    }

    if (this.SUBSCRIPTIONS) {
      this.SUBSCRIPTIONS.unsubscribe();
    }

    this.SUBSCRIPTIONS = eventEmitter.subscribe((next) => {
      if (isNullOrUndefined(next)) {
        return;
      }

      this.score = next;

      this.totalPoints = this.getTotalScore();

      this.openModal();
    });
  }

  openModal() {
    this.sortingActions.emit({ action: 'modal', params: ['open'] });
  }

  closeModal() {
    this.sortingActions.emit({ action: 'modal', params: ['close'] });
  }

  getTotalScore(): number {
    return (
      this.score.adminEditScore +
      this.score.directServiceScore +
      this.score.localStaffScore +
      this.score.refusedQuestionScore +
      this.score.updateScore
    );
  }
}
