<div class="card">
  <div class="card-content black-text">
    <div class="row">
      <div class="card-title center white-text cyan">
        {{ 'DashboardUserApprovalComponent-TABLE_TITLE' | translate }}

        <div class="left refresh">
          <a (click)="update()">
            <i class="material-icons loop">loop</i>
          </a>
        </div>
      </div>
    </div>

    <div class="row search">
      <div class="col s4 m3 right valign-wrapper">
        <div class="input-field search-input">
          <input
            [ngModel]="filterArg"
            (ngModelChange)="selectedPage = 0; filterArg = $event"
            class="validate"
            id="search"
            type="text"
          />
          <label class="grey-text" for="search">{{ 'UNIVERSAL-SEARCH' | translate }}</label>
        </div>
      </div>
    </div>

    <div class="card-text-wrapper grey lighten-3">
      <div *ngIf="!users && isRetrieving">
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      </div>

      <div *ngIf="users">
        <table class="grey lighten-3 table-wrapper striped sr-custom-table-row responsive-table">
          <thead>
            <tr>
              <th class="header-cell sr-link" (click)="setSortedField('firstName')">
                {{ 'UNIVERSAL-CONTACT_FIRST_NAME' | translate }}
                <i class="material-icons tiny">swap_vert</i>
              </th>

              <th class="header-cell sr-link" (click)="setSortedField('lastName')">
                {{ 'UNIVERSAL-CONTACT_LAST_NAME' | translate }}
                <i class="material-icons tiny">swap_vert</i>
              </th>

              <th class="header-cell sr-link" (click)="setSortedField('email')">
                {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}
                <i class="material-icons tiny">swap_vert</i>
              </th>

              <th class="header-cell sr-link" (click)="setSortedField('userTypeName')">
                {{ 'UserManagementCommon-USER_TYPE' | translate }}
                <i class="material-icons tiny">swap_vert</i>
              </th>

              <th class="header-cell sr-link" (click)="setSortedField('organizationName')">
                {{ 'UNIVERSAL-CONTACT_ORGANIZATION' | translate }}
                <i class="material-icons tiny">swap_vert</i>
              </th>

              <th class="header-cell sr-link" (click)="setSortedField('registerDate')">
                {{ 'DashboardUserApprovalComponent-REGISTERED_AT_LABEL' | translate }}
                <i class="material-icons tiny">swap_vert</i>
              </th>
              <th class="header-cell actions action-header-cell">{{ 'UNIVERSAL-TABLE_COLUMN_ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="noMsg" *ngIf="users.length == 0">
              <td colspan="7" class="noMsg">
                <div class="sr-bold center msg">
                  {{ 'DashboardUserApprovalComponent-NO_USERS_MESSAGE' | translate }}
                </div>
              </td>
            </tr>
            <tr
              class="sr-custom-table-row user-approval-table-row"
              *ngFor="
                let user of users
                  | genericSearchFilterPipe
                    : filterArg
                    : ['firstName', 'lastName', 'organizationName', 'email', 'registerDate', 'userTypeName']
                  | genericSortPipe: sortField:sortDescending
                  | paginate: { itemsPerPage: itemsPerPageArg, currentPage: selectedPage }
              "
            >
              <td *ngIf="user.isBeingDismissed" colspan="8" class="sr-base-table-row">
                <div class="progress">
                  <div class="indeterminate"></div>
                </div>
              </td>

              <td class="cell-value overflow" *ngIf="!user.isBeingDismissed">{{ user.firstName }}</td>
              <td class="cell-value overflow" *ngIf="!user.isBeingDismissed">{{ user.lastName }}</td>
              <td class="cell-value overflow" title="{{ user.email }}" *ngIf="!user.isBeingDismissed">
                {{ user.email }}
              </td>
              <td class="cell-value overflow" *ngIf="!user.isBeingDismissed">{{ user.userTypeName }}</td>
              <td class="cell-value overflow" title="{{ user.organizationName }}" *ngIf="!user.isBeingDismissed">
                {{ user.organizationName }}
              </td>
              <td class="cell-value overflow" *ngIf="!user.isBeingDismissed">
                {{ user.registerDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }}
              </td>
              <td class="cell-action-icon" *ngIf="!user.isBeingDismissed">
                <a (click)="approveUser(user)" class="waves-effect waves-light btn-floating cyan btn">
                  <i class="material-icons left">check</i>
                </a>
                <a (click)="rejectUser(user)" class="waves-effect waves-light btn-floating cyan btn">
                  <i class="material-icons left">clear</i>
                </a>
                <a (click)="deleteUser(user)" class="waves-effect waves-light btn-floating cyan btn">
                  <i class="material-icons left">delete</i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<pagination-controls (pageChange)="selectedPage = $event"></pagination-controls>
