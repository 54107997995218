import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { forkJoin, from, Observable, Subscription } from 'rxjs';
import { catchError, map, switchAll, switchMap } from 'rxjs/operators';
import { AppSettings } from '../../common/config';
import { CurrencyPickerService } from '../../header/currencyPicker/currency-picker.service';
import {
  Collection,
  ConvertedMoney,
  CurrencyCodeObject,
  CurrencyContext,
  Dictionary,
  MoneyConversionOutput,
} from '../../models';
import { CurrencyService } from '../../sharedServices/currencyService/currency.service';

@Component({
  selector: 'response-profile',
  templateUrl: './response-profile.component.html',
  styleUrls: ['./response-profile.component.css'],
})
export class ResponseProfileComponent implements OnDestroy, OnInit {
  title: string = 'NotificationComponent-TITLE';
  subscriptions: Dictionary<Subscription> = {};

  currency: CurrencyContext = {
    code: AppSettings.DEFAULT_CURRENCY,
    map: {},
  };
  money: Collection<ConvertedMoney>;

  constructor(
    private currencySvc: CurrencyService,
    private currencyPickerSvc: CurrencyPickerService,
    private notificationsSvc: NotificationsService,
    private translateSvc: TranslateService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    for (const sub in this.subscriptions) {
      if (this.subscriptions[sub]) {
        this.subscriptions[sub].unsubscribe();
      }
    }
  }

  /**
   * Registers an Subscription to:
   * 1) prevent duplicate execution.
   * 2) ensure unsubscription during component garbage collection.
   *
   * Takes a callback parameter to prevent the subscription's execution.
   *
   * @param category the categorical name for the subscription
   * @param subFn the callback that returns a subscription
   */
  protected addSubscription(category: string, subFn: () => Subscription): void {
    if (!this.subscriptions[category]) {
      this.subscriptions[category] = subFn();
    }
  }

  protected setCurrencies(code: string, formats: Dictionary<CurrencyCodeObject>): void {
    this.currency.code = code;
    this.currency.map = formats;
  }

  protected setupCurrencyContext(): Observable<[MoneyConversionOutput, Dictionary<CurrencyCodeObject>]> {
    const preferredCurrency$: Observable<string> = this.currencyPickerSvc.getPreferredCurrency();
    const currencies: Dictionary<CurrencyCodeObject> = {};

    return preferredCurrency$.pipe(
      switchMap(() => {
        const conversionOutput$: Observable<MoneyConversionOutput> = this.currencyPickerSvc.convertCollectionToPreferred(
          this.money
        );
        return conversionOutput$;
      }),
      map((output: MoneyConversionOutput) => {
        const codes: string[] = [output.currency];

        if (output.hasError) {
          this.notifyCurrencyError();
          for (const code of output.unconverted) {
            if (!codes.includes(code)) codes.push(code);
          }
        }
        const formats$: Observable<CurrencyCodeObject>[] = codes.map((code) =>
          this.currencySvc.getCurrencyObjForCode(code).pipe(
            catchError(() => {
              this.notifyCurrencyError;
              return from([null]);
            })
          )
        );

        return forkJoin(formats$).pipe(
          map((formats: CurrencyCodeObject[]) => {
            for (const format of formats) {
              if (format && format.code) {
                currencies[format.code] = format;
              } else {
                this.notifyCurrencyError();
              }
            }
            const result: [MoneyConversionOutput, Dictionary<CurrencyCodeObject>] = [output, currencies];
            return result;
          })
        );
      }),
      switchAll()
    );
  }

  private notifyCurrencyError(): void {
    this.notificationsSvc.error(
      this.translateSvc.instant(this.title),
      this.translateSvc.instant('SurveyCommon-ERROR-RETRIEVING-CURRENCY-DATA'),
      AppSettings.NOTIFICATIONS_ERROR_OPTIONS
    );
  }
}
