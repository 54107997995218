import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { handleErrors } from '../../../common/error';
import { ChangePasswordService } from './change-password.service';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { passwordMismatchValidator } from '../../../validators/validators';
import { emptySpaceValidator } from '../../../validators/emptySpaceValidator';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  providers: [],
})
export class ChangePasswordComponent {
  form: FormGroup;

  isSubmitting: boolean = false;
  hasSubmitted: boolean = false;
  loading: boolean = false;

  constructor(
    private changePasswordService: ChangePasswordService,
    private loaderService: SlimLoadingBarService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {
    this.form = new FormGroup(
      {
        oldPass: new FormControl('', [Validators.required, emptySpaceValidator()]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(45),
          emptySpaceValidator(),
        ]),
        passwordConfirmation: new FormControl('', Validators.required),
      },
      passwordMismatchValidator
    );
  }

  onSubmit(): void {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this.loaderService.start();
    this.loading = true;

    this.changePasswordService.changePassword(this.form.value.oldPass, this.form.value.password).subscribe(
      (success) => {
        this.isSubmitting = false;
        this.loaderService.complete();
        this.loading = false;

        this.hasSubmitted = true;
      },
      (error) => {
        this.isSubmitting = false;
        this.loaderService.complete();
        this.loading = false;

        handleErrors(error, this.translateService, this.notificationsService, 'ChangePasswordComponent-TITLE');
      }
    );
  }
}
