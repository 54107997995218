import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NominateComponent } from './nominate.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { RecaptchaModule } from 'ng-recaptcha';
import { MaterializeModule } from 'angular2-materialize';
import { LoaderModule } from '../common/loader/loader.module';

@NgModule({
  imports: [RouterModule.forChild([{ path: '', component: NominateComponent }])],
  exports: [RouterModule],
})
export class NominateRoutingModule {}

@NgModule({
  imports: [
    CommonModule,
    NominateRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule,
    SlimLoadingBarModule,
    RecaptchaModule,
    MaterializeModule,
    LoaderModule,
  ],
  declarations: [NominateComponent],
  providers: [],
})
export class NominateFeatureModule {}
