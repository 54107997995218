import { FormControl } from '@angular/forms';

interface ValidationResult {
  [key: string]: boolean;
}

export class NameValidator {
  public static nameFormat(control: FormControl): ValidationResult {
    if (control.value) {
      const NAME_REGEXP = /^[^\^~<>"\.,();:!@#$%&*_=+@|?`{}[\]/\\0-9]*$/;
      if (control.value !== '' && !NAME_REGEXP.test(control.value)) {
        return { nameFormat: true };
      }
    }
    return null;
  }
}
