import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserManagementService } from '../user_management.service';
import { User, userTypeToName, UserType } from '../../../../models/User';
import { OrganizationService } from '../../organizations/organization.service';
import { DashboardRedirectService } from '../../shared/dashboard-redirect.service';
import { Organization } from '../../../../models/Organization';
import { NameValidator } from '../../../../validators/nameValidator';
import { EmailValidator } from '../../../../validators/emailValidator';
import { LeadingTrailingWhiteSpaceValidator } from '../../../../validators/leadingTrailingWhiteSpaceValidator';
import { handleErrors } from '../../../../common/error';
import { GlobalLoaderFacade as SlimLoadingBarService } from '../../../../sharedServices/globalLoaderFacade/global-loader-facade.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../../login/login.service';
import { userRequiresSelectedOrganizationValidator } from '../user_management.component';
import { PhoneValidator } from '../../../../validators/phoneValidator';

@Component({
  selector: 'dashboardUserManagementEdit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css', '../../../common-edit.css'],
  providers: [],
})
export class DashboardUserManagementEditComponent implements OnInit {
  isSubmitting: boolean;

  editUserForm: FormGroup;

  organizations: Organization[];

  userTypeEnum: any = UserType;
  userTypeToName = userTypeToName;

  userId: number;
  userType: number;

  currentUser: User;
  @Input() GOB_COMPONENT;
  @Input() payload;
  @Output() action = new EventEmitter();

  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userManagementService: UserManagementService,
    private organizationService: OrganizationService,
    private translateService: TranslateService,
    private loaderService: SlimLoadingBarService,
    private notificationService: NotificationsService,
    private dashRedirectService: DashboardRedirectService,
    public authService: AuthenticationService
  ) {
    this.isSubmitting = false;
  }

  ngOnInit() {
    if (this.GOB_COMPONENT) {
      const id = this.payload;
      this.getUser(id);
    } else {
      this.route.params.subscribe((params) => {
        const id = +params['id'];
        this.getUser(id);
      });
    }
  }

  dispatchAction(event, data?) {
    this.action.emit({
      type: event,
      payload: data,
    });
  }

  getUser(id) {
    this.loaderService.start();
    this.loading = true;

    this.userManagementService
      .getUser(id)
      .pipe(this.dashRedirectService.redirectUnauthorized())
      .subscribe(
        (data) => {
          this.currentUser = data;
          this.userId = data.userId;
          this.userType = data.userType;
          this.editUserForm = new FormGroup(
            {
              email: new FormControl(
                {
                  value: data.email,
                  disabled: true,
                },
                [Validators.required, EmailValidator.mailFormat]
              ),
              firstName: new FormControl(data.firstName, [
                Validators.required,
                Validators.maxLength(32),
                NameValidator.nameFormat,
                LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
              ]),
              lastName: new FormControl(data.lastName, [
                Validators.required,
                Validators.maxLength(32),
                NameValidator.nameFormat,
                LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
              ]),
              userType: new FormControl(
                {
                  value: data.userType,
                  disabled: !this.authService.isSuper() || data.userType === this.userTypeEnum.SUPER,
                },
                Validators.required
              ),
              enabled: new FormControl(data.enabled, Validators.required),
              activated: new FormControl(data.activated, Validators.required),
              organizationId: new FormControl(data.organizationId),
              phoneNumber: new FormControl(data.phoneNumber, [
                Validators.required,
                Validators.maxLength(32),
                LeadingTrailingWhiteSpaceValidator.leadingTrailingWhiteSpaceFormat,
                PhoneValidator.phoneFormat,
              ]),
            },
            userRequiresSelectedOrganizationValidator
          );

          if (this.authService.isSuper()) {
            this.organizationService.getOrganizations().subscribe(
              (response) => {
                this.organizations = response;
              },
              (error) => {
                handleErrors(
                  error,
                  this.translateService,
                  this.notificationService,
                  'UserManagementCommon-TABLE_TITLE'
                );

                this.loaderService.complete();
                this.loading = false;
              }
            );
          }
          this.loaderService.complete();
          this.loading = false;
        },
        (error) => {
          handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');

          this.loaderService.complete();
          this.loading = false;
        }
      );
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.editUserForm.controls['phoneNumber'].setValue(
      this.editUserForm.controls['phoneNumber'].value.replace(/\s/g, '')
    );

    this.loaderService.start();
    this.loading = true;

    this.isSubmitting = true;

    const updatedUser: User = this.editUserForm.getRawValue();

    updatedUser.userId = this.userId;

    this.userManagementService.updateUser(updatedUser).subscribe(
      (response) => {
        this.isSubmitting = false;

        this.notificationService.success(
          this.translateService.instant('UserManagementCommon-TABLE_TITLE'),
          this.translateService.instant('DashboardUserManagementEditComponent-EDIT_USER_SUCCESS', {
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
          })
        );

        if (this.GOB_COMPONENT) {
          this.dispatchAction('general_view');
        } else {
          this.router.navigate(['dashboard/users']);
        }

        this.loaderService.complete();
        this.loading = false;
      },
      (error) => {
        this.isSubmitting = false;

        handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');

        this.loaderService.complete();
        this.loading = false;
      }
    );
  }

  onCancel() {
    if (this.GOB_COMPONENT) {
      this.dispatchAction('general_view');
    } else {
      if (confirm(this.translateService.instant('UNIVERSAL-CLOSE_WITHOUT_SAVING'))) {
        this.router.navigate(['dashboard/users']);
      }
    }
  }

  onResetUserPass() {
    const updatedUser: User = this.editUserForm.getRawValue();
    updatedUser.userId = this.userId;
    updatedUser.userType = this.userType;

    this.userManagementService.resetUserPassword(updatedUser).subscribe(
      (response) => {
        this.notificationService.success(
          this.translateService.instant('UserManagementCommon-TABLE_TITLE'),
          this.translateService.instant('DashboardUserManagementEditComponent-RESET_USER_PASS_SUCCESS', {
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
          })
        );
      },
      (error) => {
        handleErrors(error, this.translateService, this.notificationService, 'UserManagementCommon-TABLE_TITLE');
      }
    );
  }
}
