<div class="container">
  <app-loader [loading]="loading"></app-loader>
  <div class="col s12 md10 l10 offset-l1 card-panel hoverable registration-card card">
    <div class="row">
      <div class="input-field col s12 center">
        <h5 class="center login-form-text">
          {{ 'RegistrationComponent-CREATE_ORGANIZATION_ACCOUNT_HEADING' | translate }}
        </h5>
      </div>
    </div>

    <div class="sr-required-notif sr-padding-required-text">* {{ 'UNIVERSAL-REQUIRED_FIELD' | translate }}</div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            !registrationForm.controls['organizationName'].valid &&
            registrationForm.controls['organizationName'].touched
        }"
      >
        <i class="material-icons prefix">business</i>
        <input
          id="org_name"
          type="text"
          materialize="characterCounter"
          length="128"
          maxlength="128"
          [formControl]="registrationForm.controls['organizationName']"
        />
        <label for="org_name">
          {{ 'RegistrationComponent-ORGANIZATION_NAME_LABEL' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div class="org_name_info">
          <i>{{ 'RegistrationComponent-ORGANIZATION_NAME_ADDITIONAL_INFO' | translate }}</i>
        </div>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="
            !registrationForm.controls['organizationName'].valid &&
            registrationForm.controls['organizationName'].touched
          "
        >
          <div *ngIf="registrationForm.controls['organizationName'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['organizationName'].value.length > 0">
            <div *ngIf="registrationForm.controls['organizationName'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 128 } }}
            </div>
            <div *ngIf="registrationForm.controls['organizationName'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="registrationForm.controls['organizationName'].hasError('orgFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_ORGNAME' | translate }}
            </div>
            <div *ngIf="registrationForm.controls['organizationName'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spacing row margin s12">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !registrationForm.controls['summary'].valid && registrationForm.controls['summary'].touched
        }"
      >
        <i class="material-icons prefix">notes</i>
        <div class="rich-text-input-label summary_label">
          {{ 'RegistrationComponent-ORGANIZATION_SUMMARY_LABEL' | translate }} ({{
            'UNIVERSAL-FORMATTED_MAX_CHARACTERS' | translate: { number: 1200 }
          }})<span class="sr-required-mark"> *</span>
        </div>

        <div class="summary-field">
          <rich-text-editor [form]="registrationForm.controls['summary']" id="org_summary"></rich-text-editor>
        </div>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!registrationForm.controls['summary'].valid && registrationForm.controls['summary'].touched"
        >
          <div *ngIf="registrationForm.controls['summary'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['summary'].value.length > 0">
            <div *ngIf="registrationForm.controls['summary'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 1200 } }}
            </div>
            <div *ngIf="registrationForm.controls['summary'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="registrationForm.controls['summary'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !registrationForm.controls['govId'].valid && registrationForm.controls['govId'].touched
        }"
      >
        <i class="material-icons prefix">vpn_key</i>
        <input
          id="govId"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          name="govId"
          [formControl]="registrationForm.controls['govId']"
        />
        <label for="govId">
          {{ 'RegistrationComponent-GOVERNMENT_ID' | translate }}
        </label>
      </div>
    </div>

    <div class="margin row" *ngIf="countries">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            registrationForm.hasError('countryNotSelected') && registrationForm.controls['countryId'].touched
        }"
      >
        <i class="material-icons prefix">my_location</i>
        <select
          id="org_country"
          name="orgCountry"
          materialize="material_select"
          [formControl]="registrationForm.controls['countryId']"
        >
          <option selected></option>
          <option *ngFor="let country of countries" [value]="country.countryId" [selected]="false">
            {{ country.name }}
          </option>
        </select>
        <label>{{ 'RegistrationComponent-COUNTRY' | translate }}<span class="sr-required-mark"> *</span></label>
      </div>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !registrationForm.controls['website'].valid && registrationForm.controls['website'].touched
        }"
      >
        <i class="material-icons prefix">insert_link</i>
        <input
          id="website"
          type="text"
          materialize="characterCounter"
          length="256"
          maxlength="256"
          name="website"
          [formControl]="registrationForm.controls['website']"
        />
        <label for="website">
          {{ 'RegistrationComponent-WEBSITE' | translate }}
        </label>
        <div
          class="sr-error-area"
          *ngIf="!registrationForm.controls['website'].valid && registrationForm.controls['website'].touched"
        >
          {{ 'UNIVERSAL-FORM_ERRORS_INVALID_WEBSITE' | translate }}
        </div>
      </div>
    </div>

    <div class="row margin">
      <p>
        {{ 'RegistrationComponent-CONTACT_INSTRUCTIONS' | translate }}
      </p>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            !registrationForm.controls['firstName'].valid && registrationForm.controls['firstName'].touched
        }"
      >
        <i class="material-icons prefix">face</i>
        <input
          id="first_name"
          type="text"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="registrationForm.controls['firstName']"
        />
        <label for="first_name">
          {{ 'RegistrationComponent-FIRST_NAME_LABEL' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area"
          *ngIf="!registrationForm.controls['firstName'].valid && registrationForm.controls['firstName'].touched"
        >
          <div *ngIf="registrationForm.controls['firstName'].errors.required" class="sr-padding-required-text">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div class="sr-padding-required-text" *ngIf="registrationForm.controls['firstName'].value.length > 0">
            <div *ngIf="registrationForm.controls['firstName'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
            </div>
            <div *ngIf="registrationForm.controls['firstName'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="registrationForm.controls['firstName'].hasError('nameFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_NAME' | translate }}
            </div>
            <div *ngIf="registrationForm.controls['firstName'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !registrationForm.controls['lastName'].valid && registrationForm.controls['lastName'].touched
        }"
      >
        <i class="material-icons prefix">face</i>
        <input
          id="last_name"
          type="text"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="registrationForm.controls['lastName']"
        />
        <label for="last_name">
          {{ 'RegistrationComponent-LAST_NAME_LABEL' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area"
          *ngIf="!registrationForm.controls['lastName'].valid && registrationForm.controls['lastName'].touched"
        >
          <div *ngIf="registrationForm.controls['lastName'].errors.required" class="sr-padding-required-text">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div class="sr-padding-required-text" *ngIf="registrationForm.controls['lastName'].value.length > 0">
            <div *ngIf="registrationForm.controls['lastName'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
            </div>
            <div *ngIf="registrationForm.controls['lastName'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="registrationForm.controls['lastName'].hasError('nameFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_INVALID_LASTNAME' | translate }}
            </div>
            <div *ngIf="registrationForm.controls['lastName'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !registrationForm.controls['title'].valid && registrationForm.controls['title'].touched
        }"
      >
        <i class="material-icons prefix">person_outline</i>
        <input
          id="org_user_title"
          type="text"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="registrationForm.controls['title']"
        />
        <label for="org_user_title">
          {{ 'RegistrationComponent-ORGANIZATION_USER_TITLE_LABEL' | translate
          }}<span class="sr-required-mark"> *</span>
        </label>
        <div class="org_name_info">
          <i>{{ 'RegistrationComponent-ORGANIZATION_USER_TITLE_ADDITIONAL_INFO' | translate }}</i>
        </div>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!registrationForm.controls['title'].valid && registrationForm.controls['title'].touched"
        >
          <div *ngIf="registrationForm.controls['title'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['title'].value.length > 0">
            <div *ngIf="registrationForm.controls['title'].hasError('maxlength')">
              {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 120 } }}
            </div>
            <div *ngIf="registrationForm.controls['title'].hasError('leadingTrailingWhiteSpaceFormat')">
              {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
            </div>
            <div *ngIf="registrationForm.controls['title'].hasError('emptySpace')">
              {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            !registrationForm.controls['phoneNumber'].valid && registrationForm.controls['phoneNumber'].touched
        }"
      >
        <i class="material-icons prefix">call</i>
        <input
          id="phoneNumber"
          type="tel"
          materialize="characterCounter"
          length="32"
          maxlength="32"
          [formControl]="registrationForm.controls['phoneNumber']"
        />
        <label for="phoneNumber">
          {{ 'RegistrationComponent-PHONE_NUMBER_LABEL' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!registrationForm.controls['phoneNumber'].valid && registrationForm.controls['phoneNumber'].touched"
        >
          <div *ngIf="registrationForm.controls['phoneNumber'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['phoneNumber'].hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 32 } }}
          </div>
          <div *ngIf="registrationForm.controls['phoneNumber'].hasError('leadingTrailingWhiteSpaceFormat')">
            {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['phoneNumber'].hasError('emptySpace')">
            {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['phoneNumber'].hasError('phoneFormat')">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_PHONE' | translate }}
          </div>
        </div>
      </div>

      <p>
        {{ 'UNIVERSAL-INSTRUCTIONS_PHONE_FULL' | translate }}
      </p>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !registrationForm.controls['email'].valid && registrationForm.controls['email'].touched
        }"
      >
        <i class="material-icons prefix">email</i>
        <input
          id="email"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          [formControl]="registrationForm.controls['email']"
        />
        <label for="email"> {{ 'UNIVERSAL-CONTACT_EMAIL' | translate }}<span class="sr-required-mark"> *</span> </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!registrationForm.controls['email'].valid && registrationForm.controls['email'].touched"
        >
          <div *ngIf="registrationForm.controls['email'].errors.required">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['email'].errors.mailFormat">
            {{ 'UNIVERSAL-FORM_ERRORS_INVALID_EMAIL' | translate }}.
          </div>
          <div *ngIf="registrationForm.controls['email'].hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_CHARACTERS' | translate: { howMany: 64 } }}
          </div>
          <div *ngIf="registrationForm.controls['email'].hasError('leadingTrailingWhiteSpaceFormat')">
            {{ 'UNIVERSAL-FORM_ERRORS_LEADINGTRAILING_WHITESPACE' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['email'].hasError('emptySpace')">
            {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            registrationForm.hasError('emailMismatch') && registrationForm.controls['confirmationEmail'].dirty
        }"
      >
        <i class="material-icons prefix">email</i>
        <input
          id="confirmationEmail"
          type="text"
          materialize="characterCounter"
          length="64"
          maxlength="64"
          [formControl]="registrationForm.controls['confirmationEmail']"
        />
        <label for="confirmationEmail">
          {{ 'UNIVERSAL-CONTACT_EMAIL_CONFIRMATION' | translate }} <span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="registrationForm.hasError('emailMismatch') && registrationForm.controls['confirmationEmail'].dirty"
        >
          <div *ngIf="registrationForm.controls['confirmationEmail'].hasError('required')">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>
          <div *ngIf="registrationForm.hasError('emailMismatch')">
             {{ 'UNIVERSAL-FORM_ERRORS_EMAIL_MUST_MATCH' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error': !registrationForm.controls['password'].valid && registrationForm.controls['password'].touched
        }"
      >
        <i class="material-icons prefix">lock_open</i>
        <input
          id="password"
          type="password"
          materialize="characterCounter"
          length="45"
          maxlength="45"
          [formControl]="registrationForm.controls['password']"
        />
        <label for="password">
          {{ 'LoginComponent-PASSWORD_LABEL' | translate }}<span class="sr-required-mark"> *</span>
        </label>

        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="!registrationForm.controls['password'].valid && registrationForm.controls['password'].touched"
        >
          <div *ngIf="registrationForm.controls['password'].hasError('required')">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>

          <div *ngIf="registrationForm.controls['password'].hasError('minlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MIN_8_CHARACTERS' | translate }}
          </div>

          <div *ngIf="registrationForm.controls['password'].hasError('maxlength')">
            {{ 'UNIVERSAL-FORM_ERRORS_MAX_45_CHARACTERS' | translate }}
          </div>
          <div *ngIf="registrationForm.controls['password'].hasError('emptySpace')">
            {{ 'UNIVERSAL-FORM_ERRORS_EMPTY_SPACES' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row margin">
      <div
        class="input-field col s12"
        [ngClass]="{
          'sr-has-error':
            registrationForm.hasError('passwordMismatch') && registrationForm.controls['passwordConfirmation'].dirty
        }"
      >
        <i class="material-icons prefix">lock_open</i>

        <input
          id="passwordConfirmation"
          type="password"
          [formControl]="registrationForm.controls['passwordConfirmation']"
        />

        <label for="passwordConfirmation">
          {{ 'UNIVERSAL-CONFIRM_PASSWORD_LABEL' | translate }} <span class="sr-required-mark"> *</span>
        </label>

        <div
          class="sr-error-area sr-padding-required-text"
          *ngIf="
            registrationForm.hasError('passwordMismatch') && registrationForm.controls['passwordConfirmation'].dirty
          "
        >
          <div *ngIf="registrationForm.controls['passwordConfirmation'].hasError('required')">
            {{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}
          </div>

          <div *ngIf="registrationForm.hasError('passwordMismatch')">
            {{ 'UNIVERSAL-FORM_ERRORS_PASSWORDS_MUST_MATCH' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row margin">
      <div id="auth_checkbox" [ngClass]="{ 'sr-has-error': !registrationForm.controls['organizationAdmin'].value }">
        <input
          id="organizationAdmin"
          type="checkbox"
          class="form-control"
          [formControl]="registrationForm.controls['organizationAdmin']"
        />
        <label for="organizationAdmin">
          {{ 'RegistrationComponent-AUTHORIZED_REPRESENTATIVE' | translate }}<span class="sr-required-mark"> *</span>
        </label>
        <div
          class="sr-error-area"
          *ngIf="
            !registrationForm.controls['organizationAdmin'].value &&
            registrationForm.controls['organizationAdmin'].touched
          "
        >
          <div>{{ 'UNIVERSAL-FORM_ERRORS_REQUIRED' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="row margin">
      <p>
        {{ 'UNIVERSAL-FORM_PASS_CAPTCHA' | translate }}
      </p>
      <re-captcha
        (resolved)="onValidCaptchaResponse($event)"
        siteKey="{{ AppSettings.RECAPTCHA_SITE_KEY }}"
        (error)="onCaptchaExpire($event)"
        errorMode="handled"
      >
      </re-captcha>
    </div>

    <div class="row margin">
      <div class="input-field center">
        <button
          (click)="onSubmit()"
          class="btn waves-effect waves-light cyan"
          [disabled]="isSubmitting || !registrationForm.valid"
        >
          {{ 'RegistrationComponent-REGISTER_BUTTON' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
