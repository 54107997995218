<div class="card" *ngIf="noErrorResponse">
  <div class="card-content white-text">
    <div *ngIf="surveyDataInput">
      <div class="card-title center cyan">
        {{
          'DisasterSpecificSurveyComponent-CARD_TITLE'
            | translate: { orgName: surveyDataInput.organizationName, disasterName: surveyDataInput.disaster.name }
        }}
        <i *ngIf="allowClosing" class="material-icons prefix right sr-link close-icon" (click)="onCloseDSS()">
          close
        </i>
      </div>
    </div>

    <div class="divider"></div>

    <div *ngIf="!surveyDataInput">
      <div class="progress">
        <div class="indeterminate"></div>
      </div>
    </div>

    <ng-template [ngIf]="surveyDataInput">
      <p class="black-text">
        {{ 'SurveyCommon-CARD_INSTRUCTIONS' | translate }}
      </p>

      <p *ngIf="surveyDataInput.outdated" class="red-text">
        {{ 'UNIVERSAL-SURVEY_LABEL_SURVEY_OUTDATED_WARNING' | translate }}
      </p>

      <dss-step-list [survey-data]="surveyDataInput" (changeStep)="showStep($event)"> </dss-step-list>

      <p class="black-text" *ngIf="surveyDataInput.lastUpdatedUser && surveyDataInput.lastUpdatedDate">
        <ng-template [ngIf]="surveyDataInput.lastUpdatedUser.userType === UserType.SUPER">
          {{
            'UNIVERSAL-SURVEY_LAST_UPDATED_BY_SR_ADMIN'
              | translate
                : { when: surveyDataInput.lastUpdatedDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT }
          }}
        </ng-template>

        <ng-template [ngIf]="surveyDataInput.lastUpdatedUser.userType !== UserType.SUPER">
          {{
            'UNIVERSAL-SURVEY_LAST_UPDATED_AND_WHO'
              | translate
                : {
                    who: surveyDataInput.lastUpdatedUser.email,
                    when: surveyDataInput.lastUpdatedDate | date: AppSettings.DEFAULT_ANGULAR_DATE_AND_TIME_FORMAT
                  }
          }}
        </ng-template>
      </p>
    </ng-template>
  </div>
</div>

<!-- Modal's overlay -->
<div id="overlay" [style.display]="overlayDisplay" [class.overlay-visible]="overlayVisible"></div>

<ng-template [ngIf]="surveyDataInput">
  <!-- Modal step 1: Provide Lead Contact for Organization's Response to Disaster -->
  <div
    id="disaster-step1-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.steps[0]"
  >
    <dss-step1
      [totalSteps]="TOTAL_STEPS"
      [survey-data]="surveyDataInput"
      [isValid]="isValid"
      [organizationRespondingToDisaster]="!organizationRespondingToDisaster"
      (next)="onModalAction(onNext, [$event])"
      (prev)="onModalAction(onPrev, [$event])"
      (selectStep)="onModalAction(selectStep, [$event])"
      (submit)="onSubmit($event)"
      (cancel)="onCancel($event)"
      (error)="onModalAction(errorButtonClicked, [$event])"
    >
    </dss-step1>
  </div>

  <!-- Modal step 2: Please Specify Organization's Disaster Response Headquarters -->
  <div
    id="disaster-step2-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.steps[1]"
  >
    <dss-step2
      [totalSteps]="TOTAL_STEPS"
      [survey-data]="surveyDataInput"
      [isValid]="isValid"
      [organizationRespondingToDisaster]="!organizationRespondingToDisaster"
      (next)="onModalAction(onNext, [$event])"
      (prev)="onModalAction(onPrev, [$event])"
      (selectStep)="onModalAction(selectStep, [$event])"
      (submit)="onSubmit($event)"
      (cancel)="onCancel($event)"
      (error)="onModalAction(errorButtonClicked, [$event])"
    >
    </dss-step2>
  </div>

  <!-- Modal step 3: Edit Current Involvement -->
  <div
    id="disaster-step3-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.steps[2]"
  >
    <dss-step3
      [totalSteps]="TOTAL_STEPS"
      [survey-data]="surveyDataInput"
      [isValid]="isValid"
      [organizationRespondingToDisaster]="!organizationRespondingToDisaster"
      (next)="onModalAction(onNext, [$event])"
      (prev)="onModalAction(onPrev, [$event])"
      (selectStep)="onModalAction(selectStep, [$event])"
      (submit)="onSubmit($event)"
      (cancel)="onCancel($event)"
      (error)="onModalAction(errorButtonClicked, [$event])"
    >
    </dss-step3>
  </div>

  <!-- Modal step 4: Edit Donations & Financial Information -->
  <div
    id="disaster-step4-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.steps[3]"
  >
    <dss-step4
      [totalSteps]="TOTAL_STEPS"
      [survey-data]="surveyDataInput"
      [isValid]="isValid"
      [organizationRespondingToDisaster]="!organizationRespondingToDisaster"
      (next)="onModalAction(onNext, [$event])"
      (prev)="onModalAction(onPrev, [$event])"
      (selectStep)="onModalAction(selectStep, [$event])"
      (submit)="onSubmit($event)"
      (cancel)="onCancel($event)"
      (error)="onModalAction(errorButtonClicked, [$event])"
    >
    </dss-step4>
  </div>

  <!-- Modal step 5: Edit What services is [organization name] currently providing in response and/or recovery to [DISASTER NAME]? Please enter according to the proportion of the activities devoted to a service. The sum of all fields must total 100%, for instance 60% Shelter, 40% Health -->
  <div
    id="disaster-step5-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.steps[4]"
  >
    <dss-step5
      [totalSteps]="TOTAL_STEPS"
      [survey-data]="surveyDataInput"
      [isValid]="isValid"
      [organizationRespondingToDisaster]="!organizationRespondingToDisaster"
      (next)="onModalAction(onNext, [$event])"
      (prev)="onModalAction(onPrev, [$event])"
      (selectStep)="onModalAction(selectStep, [$event])"
      (submit)="onSubmit($event)"
      (cancel)="onCancel($event)"
      (error)="onModalAction(errorButtonClicked, [$event])"
    >
    </dss-step5>
  </div>

  <!-- Modal step 6: Edit Staff Count, Situation Reporting, and Affiliations -->
  <div
    id="disaster-step6-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.steps[5]"
  >
    <dss-step6
      [totalSteps]="TOTAL_STEPS"
      [survey-data]="surveyDataInput"
      [isValid]="isValid"
      [organizationRespondingToDisaster]="!organizationRespondingToDisaster"
      (next)="onModalAction(onNext, [$event])"
      (prev)="onModalAction(onPrev, [$event])"
      (selectStep)="onModalAction(selectStep, [$event])"
      (submit)="onSubmit($event)"
      (cancel)="onCancel($event)"
      (error)="onModalAction(errorButtonClicked, [$event])"
    >
    </dss-step6>
  </div>

  <!-- Modal step 7:  -->
  <div
    id="disaster-step7-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.steps[6]"
  >
    <dss-step7
      [totalSteps]="TOTAL_STEPS"
      [survey-data]="surveyDataInput"
      [countriesToBeShown]="organizationDisasterLocations"
      [isValid]="isValid"
      [triggerMapResize]="triggerMapResize"
      [organizationRespondingToDisaster]="!organizationRespondingToDisaster"
      (next)="onModalAction(onNext, [$event])"
      (prev)="onModalAction(onPrev, [$event])"
      (selectStep)="onModalAction(selectStep, [$event])"
      (submit)="onSubmit($event)"
      (cancel)="onCancel($event)"
      (error)="onModalAction(errorButtonClicked, [$event])"
    >
    </dss-step7>
  </div>

  <!-- Error Modal -->
  <div
    id="disaster-error-modal"
    class="modal modal-fixed-footer"
    materialize="modal"
    [materializeParams]="modalParams"
    [materializeActions]="modalActions.error"
  >
    <dss-error-list
      [disasterName]="surveyDataInput.disaster.name"
      [organizationName]="surveyDataInput.organizationName"
      [totalSteps]="TOTAL_STEPS"
      [errors]="errStepDetails"
      (next)="onModalAction(onNext, [$event])"
      (prev)="onModalAction(onPrev, [$event])"
      (selectStep)="onModalAction(selectStep, [$event])"
      (submit)="onSubmit($event)"
      (cancel)="onCancel($event)"
      (selectError)="onModalAction(onSelectQuestion, [$event])"
    >
    </dss-error-list>
  </div>
</ng-template>
